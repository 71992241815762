import React from "react";
import { Modal, Icon as CloseIconWrapper } from "../../../../../components";
import { Card, Container } from "../../../../Admin/Dashboard/styles";
import {
 Box,
 Button,
 FormLabel,
 Img,
 SimpleGrid,
 Text,
 useCheckbox,
 chakra,
 Flex,
} from "@chakra-ui/react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Field, getIn } from "formik";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "../../../../../assets/styles/config";
import DeleteIcon from "../../../../../assets/images/Delete.svg";
import { eventTypes } from "../../../../../utils/constants";

const ModalSubscription = ({
 selectedModal,
 value,
 containerRef,
 visible,
 setVisible,
 openDate,
 days,
 setFieldValue,
 values,
 setIsNewDate,
 setDisclosures,
 setOpenDate,
 partner,
 setDisclosures1,
 index,
 isNewDate,
 push,
 getCheckboxProps,
}) => {
 return (
  <div>
   {" "}
   {selectedModal === value.id && (
    <Modal
     {...{
      visible,
      setVisible,
      childrenContainerRef: containerRef,
     }}
     style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
     }}
     overlayLayer
     overlayLayerStyle={{
      backgroundColor: colors.black,
      opacity: 0.7,
     }}
     openDate={openDate}
    >
     <Container
      ref={containerRef}
      animate={{ scale: 1 }}
      initial={{ scale: 0.9 }}
     >
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{
        width: "460px",
        height: "100%",
        padding: "2rem",
       }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Box
         display="flex"
         alignItems="center"
         justifyContent="space-between"
         width="100%"
        >
         <Text
          fontFamily="Satoshi"
          color="#0F0931"
          fontSize={{
           base: "14px",
           md: "20px",
           lg: "24px",
          }}
          lineHeight="125%"
          fontWeight="700"
         >
          Custom recurrence
         </Text>
         <Box
          style={{
           cursor: "pointer",
          }}
          onClick={() => setVisible(false)}
         >
          <AiOutlineCloseCircle fontSize="30px" />
         </Box>
        </Box>
        {/**  <Box
                                                marginTop="20px"
                                                display="flex"
                                                alignItems="center"
                                                gap="14px"
                                                marginBottom="20px"
                                              >
                                                <FormLabel
                                                  fontFamily="Satoshi"
                                                  color="#0F0931"
                                                  fontSize="14px"
                                                  fontWeight="700"
                                                >
                                                  Repeat every
                                                </FormLabel>
                                                <Field
                                                  as={Input}
                                                  width="20%"
                                                  borderRadius="24px"
                                                  // border="0.705598px solid #DFDDDD"
                                                  p="10px"
                                                  type="number"
                                                  bg="#F1F5F9"
                                                  id={`experiences[${index}].custom_recurrence.repeat_every.no`}
                                                  name={`experiences[${index}].custom_recurrence.repeat_every.no`}
                                                />
                                                <Field
                                                  name={`experiences[${index}].custom_recurrence.repeat_every.every`}
                                                >
                                                  {({ field, form }) => (
                                                    <ChakraSelect
                                                      {...field}
                                                      width="30%"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `experiences[${index}].custom_recurrence.repeat_every.every`,
                                                          e.target.value
                                                        )
                                                      }
                                                      background="#F1F5F9"
                                                      border="1px solid #EBF7FF"
                                                      borderRadius="52px"
                                                      id={`experiences[${index}].custom_recurrence.repeat_every.every`}
                                                      name={`experiences[${index}].custom_recurrence.repeat_every.every`}
                                                    >
                                                      <option
                                                        value=""
                                                        selected
                                                        hidden
                                                      >
                                                        Select
                                                      </option>
                                                      <option value="week">
                                                        Week
                                                      </option>
                                                      <option value="week">
                                                        Month
                                                      </option>
                                                    </ChakraSelect>
                                                  )}
                                                </Field>
                                              </Box> */}
        <Box
         display="flex"
         flexDir="column"
         alignItems="flex-start"
         marginBottom="20px"
         marginTop="20px"
        >
         <FormLabel
          fontFamily="Satoshi"
          color="#0F0931"
          fontSize="16px"
          fontWeight="700"
         >
          Repeat on
         </FormLabel>
         <SimpleGrid
          columns={{
           base: 3,
           md: 5,
          }}
          gap="10px"
         >
          {days.map((item) => (
           <Field
            component={CustomCheckbox}
            {...getCheckboxProps({
             value: item.value,
             label: item.label,
             name: `experiences[${index}].custom_recurrence.repeat_on`,
            })}
            value={item.value}
            name={`experiences[${index}].custom_recurrence.repeat_on`}
           />
          ))}
         </SimpleGrid>
        </Box>
        <Box
         display="flex"
         flexDir="column"
         alignItems="flex-start"
         width="100%"
        >
         <FormLabel
          fontFamily="Satoshi"
          color="#0F0931"
          fontSize="16px"
          fontWeight="700"
         >
          Ends
         </FormLabel>
         <Box
          display="flex"
          flexDir="column"
          role="group"
          aria-labelledby="checkbox-group"
         >
          <label
           style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
           }}
          >
           <Field
            type="radio"
            //component={Radio}
            name={`experiences[${index}].custom_recurrence.end`}
            value="never"
           />
           <Text
            fontFamily="Satoshi"
            color="#1F2937"
            fontSize="14px"
            fontWeight="500"
           >
            Never
           </Text>
          </label>

          <label
           style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
           }}
          >
           <Field
            type="radio"
            name={`experiences[${index}].custom_recurrence.end`}
            value="on"
           />
           <Text
            fontFamily="Satoshi"
            color="#1F2937"
            fontSize="14px"
            fontWeight="500"
           >
            On
           </Text>

           {values?.experiences[index]?.custom_recurrence?.end === "on" && (
            <div onClick={() => {}}>
             <KeyboardDatePicker
              id="date-picker-dialog"
              label="Date picker dialog"
              inputVariant="outlined"
              format="dd MMMM yyyy"
              onOpen={() => setOpenDate(true)}
              value={getIn(
               values,
               `experiences[${index}].custom_recurrence.end_on`
              )}
              // onChange={() => {}}
              onChange={(value) => {
               setFieldValue(
                `experiences[${index}].custom_recurrence.end_on`,
                value
               );
              }}
              KeyboardButtonProps={{
               "aria-label": "change date",
              }}
              disablePast
             />
            </div>
           )}
          </label>
         </Box>
        </Box>
        <Button
         color={colors.primaryWhite}
         bg={colors.primaryPurple}
         borderRadius={28}
         fontWeight="700"
         fontSize="16px"
         marginTop="14px"
         p="24px 20px"
         width="100%"
         onClick={() => {
          setVisible(false);
          setFieldValue(`experiences[${index}].recurring`, "Custom");
          setOpenDate(false);
         }}
         type="submit"
         // disabled={
         //   values.experiences[index]
         //     .custom_recurrence
         //     .repeat_every.every ===
         //     "" &&
         //   values.experiences[index]
         //     .custom_recurrence
         //     .repeat_every.no === ""
         // }
         // pointerEvents={
         //   // values?.experiences[index]
         //   //   ?.custom_recurrence
         //   //   ?.repeat_every.every ===
         //   //   "" &&
         //   values?.experiences[index]
         //     ?.custom_recurrence
         //     ?.repeat_every.every === ""
         //     ? "none"
         //     : "auto"
         // }
        >
         Apply
        </Button>
       </Box>
      </Card>
     </Container>
    </Modal>
   )}
   {values?.experience === eventTypes.MULTIPLE_DATES_TIMES ? (
    <Box
     display="flex"
     marginTop="20px"
     justifyContent="flex-end"
     alignItems="end"
     width="100%"
    >
     <Box gap="10px" display="flex" alignItems="center">
      <Text
       color="#4A0DFF"
       fontFamily="Creato Display, Roboto, sans-serif;"
       fontWeight={400}
       fontSize="16px"
       cursor="pointer"
       onClick={() => {
        setIsNewDate(!isNewDate);
        setDisclosures((prevState) => ({
         ...prevState,
         [values?.experiences?.length]: [
          {
           isOpen: false,
           onToggle: () => {},
          },
         ],
        }));
        setDisclosures1((prevState) => [
         ...prevState,
         {
          isOpen: false,
          onToggle: () => {},
         },
        ]);
        push({
         id: index + 1,
         date: Date.now(),
         timeSlot: [
          {
           id: 0,
           start: "",
           end: "",
           amount: "",
           maxTickets: "",
          },
         ],
         recurring: "",
         custom_recurrence: {
          repeat_every: {
           no: "",
           every: "",
          },
          repeat_on: [],
          end: "",
          end_on: Date.now(),
         },
        });
       }}
      >
       {/* {values?.experience === "oneTime"
                                              ? "+ Add New Date"
                                              : "+ Add New Recurring"} */}
       + Add New Date
      </Text>
      {index > 0 && (
       <Img
        src={DeleteIcon}
        alt="delete"
        cursor="pointer"
        fontSize="10px"
        onClick={() => remove(index)}
       />
      )}
     </Box>
    </Box>
   ) : null}
  </div>
 );
};

export default ModalSubscription;

function CustomCheckbox(props) {
 const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
  useCheckbox(props);
 const { ...rest } = props;
 const { name, value: formikValue } = props.field;
 const { setFieldValue } = props.form;

 const handleChange = (event) => {
  const values = formikValue || [];
  let index = -1;
  for (let i = 0; i < values.length; i++) {
   if (values[i].toString() === rest.value.toString()) {
    index = i;
    break;
   }
  }
  if (index !== -1) {
   values.splice(index, 1);
  } else {
   values.push(rest.value);
  }
  setFieldValue(name, values);
 };

 return (
  <chakra.label
   border="1.5px solid #94A3B8"
   borderRadius="100px"
   padding="8px 16px"
   cursor="pointer"
   display="flex"
   alignItems="center"
   justifyContent="center"
   backgroundColor={
    formikValue.some(
     (subArr) => JSON.stringify(subArr) === JSON.stringify(rest.value)
    ) && "#632EFF"
   }
   {...htmlProps}
  >
   <input {...getInputProps()} hidden onChange={handleChange} />
   <Flex display="none" {...getCheckboxProps()}></Flex>
   <Text
    color={
     formikValue.some(
      (subArr) => JSON.stringify(subArr) === JSON.stringify(rest.value)
     )
      ? "white"
      : "gray.700"
    }
    {...getLabelProps()}
   >
    {props.label.toUpperCase()}
   </Text>
  </chakra.label>
 );
}
