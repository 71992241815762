export default Object.freeze({
  shoppingCart: require("../../assets/images/svg/shoppingCart.svg"),
  padlock: require("../../assets/images/svg/padlock.svg"),
  angleLeft: require("../../assets/images/svg/angleLeft.svg"),
  location: require("../../assets/images/svg/locationWhite.svg"),
  mail: require("../../assets/images/svg/mail.svg"),
  facebookIcon: require("../../assets/images/svg/facebook-icon.svg"),
  instagramIcon: require("../../assets/images/svg/instagram-icon.svg"),
  linkedinIcon: require("../../assets/images/svg/linkedin-icon.svg"),
  twitterIcon: require("../../assets/images/svg/twitter-icon.svg"),
  youtubeIcon: require("../../assets/images/svg/youtube-icon.svg"),
  blueArrowRight: require("../../assets/images/svg/blueArrowRight.svg"),
  search: require("../../assets/images/svg/search.svg"),
  close: require("../../assets/images/svg/close.svg"),
  locationBlackOn: require("../../assets/images/svg/locationBlackOn.svg"),
  globeBlack: require("../../assets/images/svg/globeBlack.svg"),
  phoneBlack: require("../../assets/images/svg/phoneBlack.svg"),
  deleteBlue: require("../../assets/images/svg/deleteBlue.svg"),
  naira: require("../../assets/images/svg/naira.svg"),
  locationMarkerBlue: require("../../assets/images/svg/locationMarkerBlue.svg"),
  yellowStarRating: require("../../assets/images/svg/yellowStarRating.svg"),
  fadedStarRating: require("../../assets/images/svg/fadedStarRating.svg"),
  getStarted: require("../../assets/images/svg/get-started.svg"),
});
