import React from 'react'
import ReactDOm from 'react-dom'
import PropType from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { Container, ToastContainer, Text } from './styles'

function Toast({ visible, setVisible, text, style, textStyle, children }) {
  const timerID = React.useRef()

  React.useEffect(() => {
    if (visible) {
      if (timerID.current) {
        clearTimeout(timerID.current)
      }
      timerID.current = setTimeout(() => {
        setVisible((preState) => ({ ...preState, state: false }))
      }, 5000)
    }
    return () => clearTimeout(timerID.current)
  }, [visible, setVisible])

  return ReactDOm.createPortal(
    <AnimatePresence>
      {visible ? (
        <Container initial={{ y: 100 }} animate={{ y: 0 }} exit={{ y: 100 }}>
          <ToastContainer style={style}>
            {children ? children : <Text style={textStyle}>{text}</Text>}
          </ToastContainer>
        </Container>
      ) : null}
    </AnimatePresence>,
    document.getElementById('portal')
  )
}

Toast.defaultProps = {
  visible: false,
  setVisible: () => null,
  text: '',
  style: {},
  textStyle: {},
  children: null,
}

Toast.propTypes = {
  visible: PropType.bool,
  setVisible: PropType.func,
  text: PropType.string,
  style: PropType.shape({}),
  textStyle: PropType.shape({}),
  children: PropType.oneOfType([
    PropType.func,
    PropType.node,
    PropType.arrayOf(PropType.node),
  ]),
}

export default React.memo(Toast)
