import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, fontWeight } from "../../../assets/styles/config";

export const Container = styled.div`
 max-width: 1540px;
 margin: auto;
`;

export const TitleSection = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 gap: 40px;
 padding-inline: 2rem;
 margin-top: 85px;
 margin-bottom: 40px;

 @media (min-width: 768px) {
  gap: 61px;
  margin-top: 130px;
  margin-bottom: 64px;
 }

 @media (min-width: 991px) {
  margin-top: 145px;
 }

 @media (min-width: 62em) {
  padding: 0 60px;
 }

 @media (min-width: 1080px) {
  padding: 0 4rem 3rem;
  padding-bottom: 0;
  margin-top: 150px;
 }
`;

export const Title = styled.h1`
 font-size: 25px;
 font-weight: ${fontWeight.bold};
 color: ${colors.darkBlue};
 text-transform: capitalize;
 letter-spacing: 0.03em;

 @media (min-width: 768px) {
  font-size: 33px;
  line-height: 45px;
 }

 @media (min-width: 1080px) {
  font-size: 48px;
  line-height: 48px;
 }
`;

export const Heading = styled.h1`
 display: flex;
 flex-direction: column;
 text-transform: capitalize;
 gap: 12.5px;

 ${(props) => props.css || ""}
`;

export const FaintBrownText = styled.p`
 font-style: normal;
 font-weight: 400;
 font-size: 17px;
 line-height: 25.8px;

 color: ${colors.faintBrown};

 @media (min-width: 768px) {
  font-size: 18px;
 }
 @media (min-width: 1080px) {
  font-size: 20px;
 }
`;

export const SplideSlideWrapper = styled.div`
  position: relative;
  padding: 9px 9px 14px;
  cursor: pointer;
  transition: ease-in 0.2s;
  background: #fff;
  border: 0.5px solid #bdbdbd;
  border-radius: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 540px) }{
  padding: 10px 12px 14px;

  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    transform: translateY(-15px);
  }

  @media (min-width: 768px) {
    padding: 16px 12px;
    border-radius: 23px;
  }

  .slide-img {
    width: 100%;
    object-fit: cover;
    height: 136px;
    border-radius: 12px;

    @media (min-width: 768px) {
      height: 156px;
    }

    @media (min-width: 1080px) {
      height: 190px;
    }
  }
`;

export const SlideWrapper = styled.div`
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
 margin-block: 2rem;
 margin-top: 46px;
 gap: 14px;
 padding-inline: 2rem;
 margin-bottom: 100px;

 @media (min-width: 768px) {
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 24px;
 }

 @media (min-width: 62em) {
  padding: 0 60px;
 }

 @media (min-width: 1080px) {
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding-inline: 4rem;
  margin-top: 50px;
 }

 @media (min-width: 1540px) {
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
 }
`;

export const ExperienceInfo = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 padding-top: 16px;
 padding-bottom: 2px;

 @media (min-width: 768px) {
 }
`;

export const ExperienceTitle = styled.p`
 font-size: 18px;
 font-weight: ${fontWeight.medium};
 color: ${colors.darkBlue};
 text-transform: capitalize;

 @media (min-width: 768px) {
  font-size: 20px;
 }

 @media (min-width: 1080px) {
  font-size: 24px;
  line-height: 28.2px;
 }
`;

export const ExperiencePartner = styled.p`
 display: flex;
 align-items: center;
 gap: 5px;
 color: #64748b;
 text-transform: capitalize;
 font-size: 13px;
 line-height: 16px;
 letter-spacing: 0.005em;
 text-align: left;
 margin-top: 5px;
 margin-bottom: 7px;

 @media (min-width: 768px) {
  align-items: center;
  font-size: 13px;
 }

 @media (min-width: 1080px) {
  font-size: 14px;
  line-height: 24px;
 }
`;

export const ExperienceAction = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-top: auto;
`;

export const BookNow = styled.button`
 color: #632eff;
 border-radius: 26px;
 border: 1px solid #632eff;
 font-size: 14px;
 font-weight: 500;
 line-height: 14.1px;
 transition: 0.5s ease;
 height: 38px;
 width: 144px;
 transition: 0.2s ease;
 margin-top: 12px;

 &:hover {
  width: 160px;
  opacity: 0.9;
 }
`;

export const ModalContainer = styled(motion.div)`
 max-width: 620px;
 z-index: 20;

 margin-inline: 24px;

 @media (min-width: 768px) {
 }
`;

export const ModalContent = styled(motion.div)`
 background-color: ${colors.primaryWhite};
 border-radius: 32px;
 padding: 1.25rem 1.25rem 3rem;
`;

export const ModalCloseBtn = styled.div`
 transition: 0.3s;
 cursor: pointer;

 svg {
  margin-left: auto;
  margin-bottom: 10px;
 }

 @media and (min-width: 1080px) {
  svg {
   margin-left: auto;
   margin-bottom: 35px;
  }
 }

 &:hover {
  opacity: 0.8;
 }
`;

export const WrapCollection = styled.div`
 img {
  width: 100%;
  border-radius: 24px;
  height: 220px;
 }

 @media (min-width: 768px) {
  img {
   height: 300px;
  }
 }

 @media (min-width: 1080px) {
  img {
   height: 350px;
  }
 }
`;

export const Subtitle = styled.p`
 font-weight: 500;
 font-size: 18px;
 margin-top: 30px;
 display: flex;
 align-items: center;
 gap: 5px;

 @media (min-width: 768px) {
  font-size: 24px;
 }

 @media (min-width: 1080px) {
  font-size: 32px;
  line-height: 34px;
 }

 svg {
  cursor: pointer;

  &:hover {
   opacity: 0.5;
   scale: 1.05;
  }
 }
`;

export const Detail = styled.p`
 display: flex;
 font-size: 14px;
 gap: 6px;
 cursor: pointer;
 transition: 0.2s ease;
 text-transform: capitalize;
 align-items: center;

 &:hover {
  color: ${colors.primaryPurple};
  opacity: 0.8;
  scale: 0.995;
 }

 @media (min-width: 768px) {
  font-size: 15px;
 }

 @media (min-width: 1080px) {
  font-size: 16px;
  gap: 1.1rem;
  line-height: 24px;
 }
`;
