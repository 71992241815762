import { colors } from "../../../assets/styles/config";

export const primaryDropdownText = {
 fontWeight: 700,
 fontSize: 12,
 lineHeight: "16px",
 letterSpacing: "0.15em",
 color: colors.babyBlue,
 fontFamily: "Satoshi",
 textTransform: "uppercase",
};

export const subLinksText = {
 fontFamily: "Satoshi",
 fontWeight: 400,
 fontSize: 15,
 lineHeight: "16px",
 color: colors.primaryWhite,
};

export const subLinksListItem = {
 transition: "ease .3s",
 listStyleType: "disc",
 textTransform: "capitalize",
 justifyContent: "flex-start",
 paddingBlock: "10px",
 width: "100%",
 display: "flex",
 fontSize: "16px",
 fontWeight: "400",
 lineHeight: "140%",
 cursor: "pointer",
 marginTop: "10px",
};

export const username = {
 fontFamily: "Satoshi",
 fontStyle: "normal",
 fontWeight: 500,
 fontSize: "16px",
 lineHeight: "16px",
 textTransform: "capitalize",
};

export const userRole = {
 fontFamily: "Satoshi",
 fontStyle: "normal",
 fontWeight: 500,
 fontSize: "14px",
 lineHeight: "14px",
 color: "#878498",
 textTransform: "capitalize",
};

export const routeTitle = {
 fontFamily: "Creato Display",
 fontStyle: "normal",
 fontWeight: 500,
 fontSize: { base: "16px", md: "24px" },
 lineHeight: { md: "29px" },
 color: colors.darkBlue,
 textTransform: "capitalize",
 letterSpacing: "1px",
 minWidth: "19ch",
};
