export const addExpBtn = {
 color: "#ffffff",
 bg: "#632EFF",
 borderRadius: "54px",
 fontWeight: 500,
 fontSize: 18,
 marginRight: "20px",
 p: "20px 30px",
};

export const addExpBtnMb = {
 display: { base: "flex", md: "none" },
 position: "absolute",
 bottom: 100,
 right: 0,
 width: 70,
 height: 70,
 borderRadius: "100%",
};
