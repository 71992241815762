import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { colors, fontWeight } from "../../assets/styles/config";
import { experienceBg } from "../../assets/strings/imageExports";

const arrowRightPulse = keyframes`
0%{
  margin-right: 5px;
 
}

25%{
  margin-right: 10px;


}

50%{
  margin-right: 15px;


}

75%{
  margin-right: 25px;


}


100%{
  margin-right: 35px;
 

}
`;

export const Wrapper = styled.div``;

export const Container = styled.div`
 padding: 2rem;
 max-width: 1540px;
 margin: auto;

 @media (min-width: 540px) {
  padding: 2rem;
 }

 @media (min-width: 1080px) {
  padding: 3rem 3rem;
 }
 padding-top: 0 !important;

 @media (min-width: 1540px) {
  padding: 0;
 }
`;

export const TitleSection = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 gap: 1rem;
 padding: 2rem;
 margin-top: 50px;

 @media (min-width: 1080px) {
  padding: 3rem 4rem;
  margin-top: 100px;
 }
`;

export const Title = styled.h1`
 font-size: 23px;
 font-weight: ${fontWeight.medium};
 color: ${colors.darkBlue};
 text-transform: capitalize;

 @media (min-width: 768px) {
  font-size: 28px;
  line-height: 45px;
 }

 @media (min-width: 1080px) {
  font-size: 32px;
  line-height: 48px;
 }
`;

export const BigImageTitle = styled.p`
 font-size: 40px;
 font-weight: 700;
 line-height: 40px;
 color: white;
 margin: 0;
 letter-spacing: 0.02em;
 padding-top: 50%;
 padding-left: 0.5em;
 text-transform: capitalize;

 @media (min-width: 460px) {
  padding-top: 200px;
 }

 @media (min-width: 768px) {
  font-size: 50px;
  line-height: 50px;
  padding-top: 300px;
  letter-spacing: 3px;
 }

 @media (min-width: 1080px) {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 0.03em;
  padding-left: 1em;
 }

 @media (min-width: 62em) {
  padding-inline: 60px;
 }

 @media (min-width: 1240px) {
  font-size: 70px;
 }

 @media (min-width: 1440px) {
  padding-top: 320px;
 }
`;

export const Heading = styled.h1`
 display: flex;
 flex-direction: column;
 gap: 1rem;
 margin-bottom: 30px;

 @media (min-width: 768px) {
  font-size: 33px;
  line-height: 45px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
 }

 ${(props) => props.css || ""}
`;

export const SlideWrapper = styled.div`
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
 margin-block: 2rem;
 margin-top: 46px;
 gap: 14px;

 @media (min-width: 768px) {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 24px;
 }

 @media (min-width: 1080px) {
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  margin-top: 50px;
 }

 @media (min-width: 1540px) {
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
 }
`;

export const SplideSlideWrapper = styled.div`
  position: relative;
  padding: 9px 9px 14px;
  cursor: pointer;
  transition: ease-in 0.2s;
  background: #fff;
  border: 0.5px solid #bdbdbd;
  border-radius: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 540px) }{
  padding: 10px 12px 14px;

  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    transform: translateY(-15px);
  }

  @media (min-width: 768px) {
    padding: 16px 12px;
    border-radius: 23px;
  }

  .slide-img {
    width: 100%;
    object-fit: cover;
    height: 136px;
    border-radius: 12px;

    @media (min-width: 768px) {
      height: 156px;
    }

    @media (min-width: 1080px) {
      height: 190px;
    }
  }
`;

// export const SlideImg = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   border-radius: 26px;
//   mix-blend-mode: multiply;
// `;

export const SlideText = styled.p`
 position: absolute;
 font-size: 16px;
 line-height: 24px;
 color: ${colors.primaryWhite};
 letter-spacing: -0.0041em;
 bottom: 50px;
 text-transform: capitalize;
 left: 20px;
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

 @media (min-width: 768px) {
  font-size: 18px;
 }

 @media (min-width: 1080px) {
  font-size: 20px;
 }
`;
export const SlideText2 = styled.p`
 position: absolute;
 font-size: 14px;
 line-height: 24px;
 font-weight: 600;
 color: ${colors.blue};
 // letter-spacing: -0.0041em;
 bottom: 20px;
 left: 20px;
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 text-transform: capitalize;

 @media (min-width: 768px) {
  font-size: 16px;
 }

 @media (min-width: 1080px) {
  font-size: 18px;
 }
`;

export const ExpandBtn = styled.button`
 display: flex;
 align-items: center;
 justify-content: center;
 text-align: center;
 font-weight: ${fontWeight.medium};
 color: ${colors.blue};
 font-size: 18px;
 line-height: 33px;
 text-transform: capitalize;
 transition: 0.2s ease;
 width: fit-content;

 &:hover {
  scale: 0.95;
  opacity: 0.8;
 }

 @media (min-width: 768px) {
  font-size: 20px;
  line-height: 33px;
 }

 @media (min-width: 1080px) {
  font-size: 22px;
  line-height: 33px;
 }

 ${(props) => props.css || ""}
`;

export const FaintBrownText = styled.p`
 font-style: normal;
 font-weight: 400;
 font-size: 20px;
 line-height: 22px;
 color: ${colors.faintBrown};

 @media (min-width: 768px) {
  font-size: 22px;
  line-height: 28px;
 }
 @media (min-width: 1080px) {
  font-size: 24px;
  line-height: 36px;
 }
`;

// color: ${(props) => (props.selected ? colors.primaryPurple : colors.brown)};
// border-bottom: ${(props) =>
//   props.selected ? `3px solid ${colors.primaryPurple}` : ''};

export const TagButtons = styled.div`
 display: flex;
 overflow: scroll;
 gap: 8px;
 height: 65px;

 @media (max-width: 840px) {
  max-width: 100%;
 }

 @media (min-width: 768px) {
  height: auto;

  ::-webkit-scrollbar {
   display: none;
  }
 }

 @media (min-width: 1080px) {
  gap: 16px;
 }
`;

export const TagButton = styled(motion.button)`
 font-size: 13px;
 line-height: 14px;
 letter-spacing: 0.05em;
 color: ${(props) => (props.selected ? colors.primaryWhite : "#252046")};
 background-color: ${(props) => (props.selected ? "#632EFF" : "#F1F5F9")};
 border: ${(props) =>
  props.selected ? "1px solid #632EFF" : "1px solid #E2E8F0"};
 transition: 0.3s;
 padding: 13.5px 16px;
 width: max-content;
 min-width: max-content;
 border-radius: 34px;
 scroll-behavior: smooth;
 max-height: 48px;

 &:hover {
  border: 1px solid #632eff;
  background-color: #f1ecff;
  color: #252046;
 }

 @media (min-width: 768px) {
  font-size: 14px;
  line-height: 14px;
 }

 @media (min-width: 1080px) {
  font-size: 15px;
  line-height: 16px;
 }

 @media (min-width: 1080px) {
  font-size: 16px;
 }

 @media (min-width: 3340px) {
  min-width: 87px;
 }

 ${(props) => props.css || ""}
`;

export const SearchLabel = styled.label`
 position: relative;

 .icon {
  position: absolute;
  left: 15px;
 }

 @media (min-width: 768px) {
  .icon {
   top: 0.5rem;
  }
 }
`;

export const SearchInput = styled.input`
 background: ${colors.skyBlue};
 border-radius: 10px;
 width: 100%;
 height: 38px;
 font-size: 14px;
 padding: 10px 12px 10px 40px;
 color: ${colors.brown};

 @media (min-width: 768px) {
  font-size: 15.5px;
  width: 480px;
  border-radius: 20px;
 }

 @media (min-width: 1080px) {
  font-size: 17px;
  height: 42px;
 }

 &:focus {
  outline: ${colors.activeBorder};
  background: ${colors.activeInputBg};
 }

 &::placeholder {
  color: ${colors.placeholderBrown};
 }

 &:-ms-input-placeholder {
  color: ${colors.placeholderBrown};
 }

 &::-ms-input-placeholder {
  color: ${colors.placeholderBrown};
 }
`;

export const NoCollections = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
 min-width: 300px;
 min-height: 300px;
 font-size: 18px;
 font-weight: ${fontWeight.medium};
 color: ${colors.brown};
 line-height: 24px;

 @media (min-width: 768px) {
  font-size: 22px;
  line-height: 28px;
 }
 @media (min-width: 1080px) {
  font-size: 24px;
  line-height: 30px;
 }
`;

export const ExperienceImg = styled.img`
 object-fit: cover;
 width: 100%;
 height: 100%;
`;

export const ExpImgWrapper = styled.div`
 background-image: url(${experienceBg});
 background-repeat: no-repeat;
 height: 350px;
 background-size: cover;
 position: relative;
 margin-bottom: 30px;

 @media (min-width: 768px) {
  height: 520px;
 }

 @media (min-width: 1080px) {
  width: 100vw;
  height: 601px;
 }

 @media (min-width: 1240px) {
  width: 100vw;
  height: 555px;
  margin-bottom: 30px;
 }

 @media (min-width: 1380px) {
  background-size: cover;
 }

 @media (min-width: 1440px) {
  background-size: cover;
  margin-bottom: 30px;
 }
`;

export const SortByTitle = styled.p`
 font-weight: 400;
 font-size: 14px;
 min-width: max-content;
 color: ${colors.brown};

 @media (min-width: 768px) {
  font-size: 15px;
  line-height: 28px;
 }

 @media (min-width: 1080px) {
  font-size: 16px;
  line-height: 30px;
 }
`;

export const SortByOption = styled.option`
 color: ${colors.brown} !important;
 // padding: 12px;
`;

export const SortBySelect = styled.div`
 .chakra-select {
  // color: ${colors.primaryPurple};

  padding: 16px;
  margin-right: 15px;
 }
`;

// New experience design

export const SlideImg = styled.img`
 width: 100%;
 object-fit: cover;
 height: 156px;
 border-radius: 12px;
 //   border-radius: 23px 23px 0 0;

 @media (min-width: 768px) {
  height: 176px;
 }

 @media (min-width: 1080px) {
  height: 255px;
 }
`;

export const ExperienceInfo = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 padding-top: 16px;
 padding-bottom: 2px;

 @media (min-width: 768px) {
 }
`;

export const ExperienceTitle = styled.p`
 font-size: 18px;
 font-weight: ${fontWeight.medium};
 color: ${colors.darkBlue};
 text-transform: capitalize;

 @media (min-width: 768px) {
  font-size: 20px;
 }

 @media (min-width: 1080px) {
  font-size: 24px;
  line-height: 28.2px;
 }
`;

export const ExperiencePartner = styled.p`
 display: flex;
 align-items: center;
 gap: 5px;
 color: #64748b;
 text-transform: capitalize;
 font-size: 13px;
 line-height: 16px;
 letter-spacing: 0.005em;
 text-align: left;
 margin-top: 5px;
 margin-bottom: 7px;

 @media (min-width: 768px) {
  align-items: center;
  font-size: 13px;
 }

 @media (min-width: 1080px) {
  font-size: 14px;
  line-height: 24px;
 }
`;

export const ExperienceAction = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-top: auto;
`;

export const BookNow = styled.button`
 color: #632eff;
 border-radius: 26px;
 border: 1px solid #632eff;
 font-size: 14px;
 font-weight: 500;
 line-height: 14.1px;
 transition: 0.5s ease;
 height: 38px;
 width: 124px;
 transition: 0.2s ease;

 &:hover {
  width: 150px;
  opacity: 0.9;
 }
`;

export const RatingText = styled.p`
 font-weight: 500;
 font-size: 16px;
 display: flex;
 align-items: center;
 color: ${(props) => (!props?.faded ? "#BDBDBD" : colors.blue)};
 // gap: 1px;

 img {
  scale: 0.8;
 }

 @media (min-width: 768px) {
  gap: 5px;
  font-size: 18px;

  img {
   scale: 0.9;
  }
 }

 @media (min-width: 1080px) {
  font-size: 20px;
  line-height: 30px;

  img {
   scale: 1;
  }
 }
`;

export const ExperiencePriceWrapper = styled.div`
 display: flex;
 justify-content: space-between;
 width: 78%;
 position: absolute;
 right: 20px;
 top: 15px;
 z-index: 1;

 @media (min-width: 540px) {
  top: 20px;
  right: 28px;
 }

 @media (min-width: 768px) {
  right: 23px;
  top: 36px;
 }

 @media (min-width: 1080px) {
  font-size: 16px;
  line-height: 24px;
 }
`;

export const ExperiencePrice = styled.p`
 background: ${colors.primaryWhite};
 padding: 5px 12px;
 color: #0f0931;
 font-weight: 500;
 border-radius: 19px;

 display: flex;
 align-items: center;
 font-size: 14px;
`;

// PAGINATION STYLES
export const PaginationBox = styled.div`
 width: 100%;
 display: grid;
 place-items: center;
 margin-bottom: 50px;
 margin-top: 90px;

 .pagination {
  display: flex;
  gap: 7px;
  list-style-type: none;

  li a {
   padding: 0.5rem 0.75rem;
   border-radius: 4px;
   font-weight: 700;
   background: #ffffff;
   border: 1px solid #dfe3e8;
  }

  .white {
   color: #dfe3e8;
  }

  .navDisabled a {
   background: #dfe3e8;
   color: #c4cdd5;
  }

  .active a {
   color: ${colors.primaryPurple};
   border: solid 1.5px ${colors.primaryPurple};
  }
 }

 @media (min-width: 768px) {
  .pagination {
   gap: 14px;

   li a {
    padding: 0.75rem 1rem;
    border-radius: 4px;
    font-weight: 700;
    background: #ffffff;
    border: 2px solid #dfe3e8;
   }

   .active a {
    border: solid 2px ${colors.primaryPurple};
   }
  }
 }

 @media (min-width: 1080px) {
  .pagination {
   gap: 20px;
  }
 }
`;

export const ModalImageContainer = styled.div`
 width: 80%;
 // height: 500px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background: white;
 // border-radius: 12px;
 padding: 2rem 5rem 4rem;
`;

export const ImageCloseBtn = styled.button`
 width: 100%;
 margin-bottom: 30px;
 transition: 1.5s;

 svg {
  margin-left: auto;
 }
`;

export const TagsArrow = styled(motion.div)`
 @media (min-width: 840px) {
  display: none;
 }

 text-align: center;
 font-weight: 900;
 font-size: 26px;
 color: ${colors.primaryPurple};
 // animation: ${arrowRightPulse} 1.5s infinite;
`;
