import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  HStack,
  Img,
  Text,
  Button,
  List,
  ListItem,
  SimpleGrid,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { colors } from "../../../../assets/styles/config";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import EditIcon from "../../../../assets/images/edit.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { bytesToSize } from "../../../../utils/index";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../../firebase";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import Cloud from "../../../../assets/images/cloud-plus.svg";
import {
  profileFormControlStyles,
  profileFormFieldStyles,
  profileFormLabelStyles,
} from "../styles";

const profileValidationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  // company: Yup.string()
  //   .min(2, "Too short!")
  //   .max(50, "Too Long!")
  //   .required("Company is required"),
  phoneNumber: Yup.string().required("Phone number is Required"),
  // address: Yup.string()
  //   .min(2, "Too short!")
  //   .max(50, "Too Long!")
  //   .required("Address name is required"),
});

const userValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

const Profile = () => {
  const { user, updateUser, updateAdministrator } = useContext(AuthContext);
  const [imageUrl, setImageUrl] = useState("");
  const [media, setMedia] = useState(null);
  //console.log("imageUrl", media);
  const navigate = useNavigate();
  // const { setFieldValue, setValues } = useFormik();

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setMedia(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      accept: "image/jpeg, image/png, video/gif, video/mp4",
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      maxSize: 10000000,
      minSize: 0,
    });

  const handleUpload = async (file, data) => {
    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (err) => console.log(err),
      async () => {
        // download url
        await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          const payload = {
            ...data,
            photoURL: url,
          };

          await updateAdministrator(payload).then(() => {
            window.location.reload();
          });
        });
      }
    );
  };

  return (
    <Box height="100%" padding="14px 20px" background="#F5F8FA">
      {/* <Text
        color="#6C6B6B"
        fontSize={{ base: "18px", md: "20px", lg: "24px" }}
        lineHeight="32px"
        fontWeight="600"
      >
        Dashboard
      </Text>
      <List>
        <ListItem color="#6C6B6B" listStyleType="disc" marginLeft="20px">
          My Profile
        </ListItem>
      </List> */}
      <Box
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={() => navigate(-1)}
      >
        <Img src={ArrowLeft} />
        <Text color="#252046" marginLeft="10px">
          Go Back
        </Text>
      </Box>
      <Box background="#FFFFFF" borderRadius="16px" mt="16px">
        <Box padding="20px 30px">
          <Text
            color="#334155"
            fontSize={{ base: "18px", md: "20px" }}
            lineHeight="32px"
            fontWeight="700"
            mb="20px"
            fontFamily="Creato Display, Roboto, sans-serif;"
          >
            Profile
          </Text>
          <Formik
            initialValues={{
              name: "",
              phoneNumber: "",
            }}
            onSubmit={async (values) => {
              toast.loading("loading");
              const data = {
                ...user,
                name: values?.name,
                phoneNumber: values?.phoneNumber,
              };
              if (media !== null) {
                await handleUpload(media[0], data);
              } else {
                await updateAdministrator(data);
              }
            }}
            validationSchema={profileValidationSchema}
          >
            {({ handleSubmit, errors, touched, setFieldValue }) => {
              useEffect(() => {
                async function setInitialValues() {
                  const fields = [
                    "name",
                    "company",
                    "phoneNumber",
                    "address",
                    "role",
                    "email",
                  ];
                  if (user) {
                    fields.forEach((field) => {
                      setFieldValue(field, user[field], false);
                    });
                    setImageUrl(user?.photoURL);
                  }
                }
                setInitialValues();
              }, [user]);
              return (
                <form onSubmit={handleSubmit}>
                  <Box w="478px">
                    <Box alignItems="center" marginBottom="30px">
                      <Box display="flex" alignItems="center" flexDir="column">
                        <Box
                          width="478px"
                          borderRadius="9px"
                          height="100%"
                          display="flex"
                          flexDirection="column"
                          background="#F1F5F9"
                          justifyContent="center"
                          alignItems="center"
                          cursor="pointer"
                          padding="20px"
                          {...getRootProps()}
                        >
                          <Box
                            marginBottom="20px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            <Text
                              color="#0F0931"
                              fontFamily="Creato Display, Roboto, sans-serif;"
                              fontSize="16px"
                              lineHeight="135%"
                            >
                              Upload profile Image
                            </Text>
                            <Text
                              width="60%"
                              textAlign="center"
                              color="#64748B"
                              fontFamily="Satoshi"
                              fontSize="12px"
                              lineHeight="135%"
                            >
                              JPG, .PNG images are allowed Maximum size of 10mb
                            </Text>
                          </Box>
                          <Box
                            width="125px"
                            height="125px"
                            borderRadius="100%"
                            background="#F8FBFF"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <input {...getInputProps()} />
                            {media ? (
                              <Img
                                src={media[0]?.preview}
                                alt="avatar"
                                width="100%"
                                height="125px"
                                cursor="pointer"
                                borderRadius="100%"
                                onClick={open}
                              />
                            ) : imageUrl ? (
                              <Img
                                src={imageUrl}
                                alt="avatar"
                                width="100%"
                                height="125px"
                                cursor="pointer"
                                borderRadius="100%"
                                onClick={open}
                              />
                            ) : (
                              <Img
                                src={Cloud}
                                alt="avatar"
                                width="32px"
                                height="32px"
                                cursor="pointer"
                                onClick={open}
                              />
                            )}
                            {/* <Img
                              src={EditIcon}
                              position="absolute"
                              top="0"
                              left="20"
                              cursor="pointer"
                              onClick={open}
                            /> */}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <FormControl
                      {...profileFormControlStyles}
                      isRequired
                      isInvalid={!!errors.name && touched.name}
                    >
                      <FormLabel {...profileFormLabelStyles}>Name</FormLabel>
                      <Field
                        as={Input}
                        placeholder="full name"
                        id="name"
                        name="name"
                        {...profileFormFieldStyles}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>

                      {/*  <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={5}>
                    <Input
                      borderRadius="24px"
                      border="none"
                      p="24px"
                      placeholder="James"
                      background="#EEF3F7"
                    />
                    <Input
                      borderRadius="24px"
                      border="none"
                      p="24px"
                      placeholder="James"
                      background="#EEF3F7"
                    />
            </SimpleGrid> */}
                    </FormControl>

                    <FormControl
                      {...profileFormControlStyles}
                      isRequired
                      isInvalid={!!errors.email && touched.email}
                    >
                      <FormLabel {...profileFormLabelStyles}>
                        Email Address
                      </FormLabel>
                      <Field
                        as={Input}
                        placeholder="example@gmail.com"
                        id="email"
                        name="email"
                        {...profileFormFieldStyles}
                        pointerEvents="none"
                      />
                    </FormControl>
                    {/* <FormControl
                      display="flex"
                      flexDir="column"
                      alignItems="flex-start"
                      mb="20px"
                      isRequired
                      isInvalid={!!errors.company && touched.company}
                    >
                      <FormLabel
                        fontFamily="Satoshi"
                        color="#0F0931"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight="21px"
                        fontWeight="700"
                      >
                        Company
                      </FormLabel>
                      <Field
                        as={Input}
                        borderRadius="66px"
                        border="0.705598px solid #DFDDDD"
                        p="20px"
                        placeholder="company"
                        bg="#F1F5F9"
                        id="company"
                        name="company"
                      />
                      <FormErrorMessage>{errors.company}</FormErrorMessage>
                    </FormControl> */}

                    <FormControl
                      {...profileFormControlStyles}
                      isRequired
                      isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                    >
                      <FormLabel {...profileFormLabelStyles}>
                        Phone number
                      </FormLabel>
                      <Field
                        as={Input}
                        placeholder="phone number"
                        id="phoneNumber"
                        name="phoneNumber"
                        {...profileFormFieldStyles}
                      />
                      <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isRequired
                      {...profileFormControlStyles}
                      isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                    >
                      <FormLabel {...profileFormLabelStyles}>Role</FormLabel>
                      <Field
                        as={Input}
                        id="role"
                        name="role"
                        readonly
                        {...profileFormFieldStyles}
                        pointerEvents="none"
                      />
                      <FormErrorMessage>{errors.role}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...profileFormControlStyles}
                      isInvalid={!!errors.address && touched.address}
                    >
                      <FormLabel {...profileFormLabelStyles}>
                        Date Joined
                      </FormLabel>
                      <Field
                        as={Input}
                        placeholder="10 - 03 - 2023"
                        id="dateJoined"
                        name="dateJoined"
                        {...profileFormFieldStyles}
                      />
                      <FormErrorMessage>{errors.dateJoined}</FormErrorMessage>
                    </FormControl>

                    <Box
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      display="flex"
                    >
                      <Text
                        color="#4A0DFF"
                        fontSize="16px"
                        fontWeight="400"
                        fontFamily="Creato Display, Roboto, sans-serif;"
                        cursor="pointer"
                        onClick={() => navigate("/admin/forget-password")}
                      >
                        Change password
                      </Text>
                    </Box>

                    {/* <Text
                      marginTop="30px"
                      color="#0F0931"
                      fontSize={{ base: "18px", md: "20px" }}
                      lineHeight="32px"
                      fontWeight="600"
                      fontFamily="Creato Display, Roboto, sans-serif;"
                      marginBottom="20px"
                    >
                      Sign In details
                    </Text>

                    <FormControl
                      display="flex"
                      flexDir="column"
                      alignItems="flex-start"
                      mb="20px"
                      isRequired
                      isInvalid={!!errors.email && touched.email}
                    >
                      <FormLabel {...profileFormLabelStyles}>
                        Email Address
                      </FormLabel>
                      <Field
                        as={Input}
                        placeholder="email"
                        id="email"
                        name="email"
                        {...profileFormFieldStyles}
                      />
                    </FormControl>

                    <FormControl
                      alignItems="flex-start"
                      marginBottom="30px"
                      display="flex"
                      flexDir="column"
                      isRequired
                      isInvalid={!!errors.password && touched.password}
                    >
                      <FormLabel {...profileFormLabelStyles}>
                        Password
                      </FormLabel>
                      <Field
                        as={Input}
                        width="100%"
                        id="password"
                        name="password"
                        {...profileFormFieldStyles}
                      />
                    </FormControl> */}

                    <Box
                      // display="flex"
                      // alignItems=""
                      // justifyContent="flex-end"
                      marginTop={10}
                      width="100%"
                    >
                      <Button
                        color="#ffffff"
                        bg="#632EFF"
                        borderRadius={28}
                        fontWeight={500}
                        fontSize={18}
                        p="25px"
                        type="submit"
                        width="100%"
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Formik>
          {/* <Box mt="20px" w="478px">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={async (values) => {}}
              validationSchema={userValidationSchema}
            >
              {({ handleSubmit, errors, touched, setFieldValue }) => {
                useEffect(() => {
                  async function setInitialValues() {
                    if (user) {
                      console.log("user?.email", user);
                      setFieldValue("email", user?.email, false);
                    }
                  }
                  setInitialValues();
                }, [user]);
                return (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="flex"
                      alignItems=""
                      justifyContent="flex-end"
                      marginTop={10}
                    >
                      <Button
                        color="#25AEFC"
                        bg="#EBF7FF"
                        borderRadius={28}
                        fontWeight={500}
                        fontSize={18}
                        p="20px"
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </Box> */}
        </Box>
      </Box>
      {/* <Box background="#FFFFFF" borderRadius="6px" mt="30px">
        <Box padding="30px" borderBottom="1px solid #B8B8B8">
       
        </Box>
      </Box> */}
    </Box>
  );
};

export default Profile;
