import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '../../assets/styles/config';

export const Container = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  padding-bottom: 10px;
  ${(props) => props?.css || ''}
`;

export const Dots = styled(motion.span)`
  display: block;
  width: ${(props) => (props?.size ? `${props?.size}px` : '9px')};
  height: ${(props) => (props?.size ? `${props?.size}px` : '9px')};
  background-color: ${(props) => props?.dotsColor || colors.black};
  border-radius: 4.5px;
  flex-shrink: 0;
`;
