import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  Box,
  HStack,
  Img,
  Text,
  Button,
  List,
  ListItem,
  SimpleGrid,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Image,
} from "@chakra-ui/react";
import { inputStyle, selectStyle } from "../../../Admin/Dashboard/styles";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { bytesToSize } from "../../../../utils/index";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import {
  getDatabase,
  set,
  ref as databaseRef,
  child,
  get,
  onValue,
  update,
} from "firebase/database";
import { db } from "../../../../firebase";
import Select from "react-select";
import dayjs from "dayjs";
import Cloud from "../../../../assets/images/cloud-plus.svg";
import toast from "react-hot-toast";

const ViewMember = () => {
  const { user, updatePartnerMember } = useContext(AuthContext);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [administrator, setAdministrator] = useState([]);
  const params = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const id = params?.id;
  const [partnerOption, setPartnerOption] = useState([]);
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState(null);
  const uid = user?.partnerUid ?? user?.uid;
  const rolesDB = databaseRef(db, `users/partner/${uid}/roles`);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEdit(true);
    }
  }, [location]);

  useEffect(() => {
    // setLoading(true);
    const unsubscribe = onValue(rolesDB, (snapshot) => {
      const roles = snapshot.val();
      setRole(
        roles?.map((item) => {
          return item?.role;
        })
      );
      setRoles(
        roles?.map((item) => {
          return {
            value: item?.role,
            label: item?.role,
          };
        })
      );
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const filterObjectPartner = (obj) =>
    Object.keys(obj).reduce(
      (acc, val) =>
        obj[val]["role"] === "PARTNER"
          ? {
              ...acc,
              [val]: obj[val],
            }
          : acc,
      {}
    );

  const fetchDatabase = async () => {
    setLoading(true);
    const dbRef = databaseRef(getDatabase());
    //get user experience
    const db = await get(child(dbRef, `users/partner/${uid}/members`))
      .then((snapshot) => {
        setLoading(false);
        if (snapshot.exists()) {
          const snap = snapshot.val();

          const myObj = Object.keys(snap).reduce(
            (acc, val) =>
              role?.includes(snap[val]["role"])
                ? {
                    ...acc,
                    [val]: snap[val],
                  }
                : acc,
            {}
          );

          var array = Object.keys(myObj)
            .map((key) => {
              return myObj[key];
            })
            .filter((item) => item?.uid === id);

          setAdministrator(array[0]);

          const myObjPartner = filterObjectPartner(snapshot.val());
          setPartnerOption(
            Object.keys(myObjPartner)
              .map((key) => {
                return {
                  label: myObjPartner[key].name,
                  value: myObjPartner[key].name,
                  status: myObjPartner[key].status,
                };
              })
              .filter((item) => item?.status === "APPROVED")
          );
        } else {
          //  console.log("No data available");
        }
      })
      .catch((error) => {
        // console.error(error);
        setLoading(false);
      });
    //get users order

    return db;
  };

  useEffect(() => {
    if (user) {
      fetchDatabase();
    }
  }, [user, role]);

  // const { setFieldValue, setValues } = useFormik();

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setMedia(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      accept: "image/jpeg, image/png, video/gif, video/mp4",
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      maxSize: 10000000,
      minSize: 0,
    });

  const handleUpload = async (file, data) => {
    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (err) => {},
      async () => {
        // download url
        await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          const payload = {
            ...data,
            photoURL: url,
          };

          await updatePartnerMember(payload).then((res) => {
            if (res) {
              fetchDatabase();
            }
          });
        });
      }
    );
  };

  return (
    <Box
      height="100%"
      padding={{ base: "0px 20px", md: "14px 20px" }}
      background="#F5F8FA"
    >
      <Box
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        cursor="pointer"
        onClick={() => navigate(-1)}
      >
        <Image src={ArrowLeft} />
        <Text color="#252046" marginLeft="10px">
          Go Back
        </Text>
      </Box>
      <Box background="#FFFFFF" borderRadius="16px" mt={{ md: "16px" }}>
        <Box padding="20px 30px">
          <Text
            color="#334155"
            fontSize={{ base: "18px", md: "20px" }}
            lineHeight="32px"
            fontWeight="700"
            mb="20px"
            fontFamily="Creato Display, Roboto, sans-serif;"
          >
            Profile
          </Text>
          <Formik
            initialValues={{
              id: "",
              name: "",
              email: "",
              assignedPartner: "",
              role: "",
              lastLogin: "",
              created_at: "",
            }}
            onSubmit={async (values) => {
              toast.loading("loading");

              if (media !== null) {
                // id: values?.id,
                await handleUpload(media[0], {
                  ...administrator,
                  name: values?.name,
                  email: administrator.email,
                  assignedPartner: values?.assignedPartner
                    ? values?.assignedPartner.map((item) => item.value)
                    : "",
                  role: values?.role.value,
                  phoneNumber: values?.phoneNumber,
                  updated_at: JSON.stringify(dayjs(Date.now())),
                });
              } else {
                await updatePartnerMember({
                  ...administrator,
                  name: values?.name,
                  email: administrator?.email,
                  assignedPartner: values?.assignedPartner
                    ? values?.assignedPartner.map((item) => item.value)
                    : "",
                  role: values?.role.value,
                  phoneNumber: values?.phoneNumber,
                  photoURL: administrator?.photoURL,
                  updated_at: JSON.stringify(dayjs(Date.now())),
                }).then((res) => {
                  if (res) {
                    fetchDatabase();
                  }
                });
              }
            }}
            //validationSchema={profileValidationSchema}
          >
            {({
              handleSubmit,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              values,
            }) => {
              useEffect(() => {
                async function setInitialValues() {
                  const fields = ["name", "email", "lastLogin", "phoneNumber"];
                  if (administrator) {
                    fields.forEach((field) => {
                      setFieldValue(field, administrator[field], false);
                    });

                    setFieldValue(
                      "role",
                      roles?.filter(
                        (item) => administrator?.role === item.value
                      )[0],
                      false
                    );

                    setImageUrl(administrator?.photoURL);

                    setFieldValue(
                      "created_at",
                      dayjs(
                        administrator?.created_at &&
                          JSON.parse(administrator?.created_at)
                      ).format("DD/MM/YYYY")
                    );

                    typeof administrator?.assignedPartner !== "string" &&
                      administrator?.assignedPartner?.map((item, index) => {
                        setFieldValue(
                          "assignedPartner",
                          partnerOption.filter((itm) =>
                            administrator?.assignedPartner.includes(itm.value)
                          ),
                          false
                        );
                      });
                  }
                }
                setInitialValues();
              }, [administrator, partnerOption, roles]);
              return (
                <form onSubmit={handleSubmit}>
                  <Box w={{ base: "100%", md: "478px" }}>
                    <Box alignItems="center" marginBottom="30px">
                      <Box display="flex" alignItems="center" flexDir="column">
                        <Box
                          w={{ base: "100%", md: "478px" }}
                          borderRadius="9px"
                          height="100%"
                          display="flex"
                          flexDirection="column"
                          background="#F1F5F9"
                          justifyContent="center"
                          alignItems="center"
                          cursor="pointer"
                          padding="20px"
                          {...getRootProps()}
                        >
                          <Box
                            marginBottom="20px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            <Text
                              color="#0F0931"
                              fontFamily="Creato Display, Roboto, sans-serif;"
                              fontSize="16px"
                              lineHeight="135%"
                            >
                              Upload profile Image
                            </Text>
                            <Text
                              width="60%"
                              textAlign="center"
                              color="#64748B"
                              fontFamily="Satoshi"
                              fontSize="12px"
                              lineHeight="135%"
                            >
                              JPG, .PNG images are allowed Maximum size of 10mb
                            </Text>
                          </Box>
                          <Box
                            width="125px"
                            height="125px"
                            borderRadius="100%"
                            background="#F8FBFF"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <input {...getInputProps()} />
                            {media ? (
                              <Img
                                src={media[0]?.preview}
                                alt="avatar"
                                width="100%"
                                height="125px"
                                cursor="pointer"
                                borderRadius="100%"
                                onClick={open}
                              />
                            ) : imageUrl ? (
                              <Img
                                src={imageUrl}
                                alt="avatar"
                                width="100%"
                                height="125px"
                                cursor="pointer"
                                borderRadius="100%"
                                onClick={open}
                              />
                            ) : (
                              <Img
                                src={Cloud}
                                alt="avatar"
                                width="32px"
                                height="32px"
                                cursor="pointer"
                                onClick={open}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <FormControl
                      display="flex"
                      flexDir="column"
                      alignItems="flex-start"
                      mb="20px"
                      isInvalid={!!errors.name && touched.name}
                    >
                      <FormLabel
                        fontFamily="Satoshi"
                        color="#0F0931"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight="21px"
                        fontWeight="700"
                      >
                        Name
                      </FormLabel>
                      <Field
                        as={Input}
                        {...inputStyle}
                        placeholder="name"
                        id="name"
                        name="name"
                        //  pointerEvents={isEdit ? "initial" : "none"}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      display="flex"
                      flexDir="column"
                      alignItems="flex-start"
                      mb="20px"
                      isInvalid={!!errors.email && touched.email}
                    >
                      <FormLabel
                        fontFamily="Satoshi"
                        color="#0F0931"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight="21px"
                        fontWeight="700"
                      >
                        Email
                      </FormLabel>
                      <Field
                        as={Input}
                        {...inputStyle}
                        placeholder="email"
                        id="email"
                        name="email"
                        pointerEvents="none"
                      />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      display="flex"
                      flexDir="column"
                      alignItems="flex-start"
                      mb="20px"
                      isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                    >
                      <FormLabel
                        fontFamily="Satoshi"
                        color="#0F0931"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight="21px"
                        fontWeight="700"
                      >
                        Phone
                      </FormLabel>
                      <Field
                        as={Input}
                        {...inputStyle}
                        placeholder="address"
                        bg="#F1F5F9"
                        id="phoneNumber"
                        name="phoneNumber"
                        // pointerEvents={isEdit ? "initial" : "none"}
                      />
                      <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      display="flex"
                      flexDir="column"
                      alignItems="flex-start"
                      mb="20px"
                      isInvalid={!!errors.role && touched.role}
                    >
                      <FormLabel
                        fontFamily="Satoshi"
                        color="#0F0931"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight="21px"
                        fontWeight="700"
                      >
                        Role
                      </FormLabel>
                      <Select
                        name="role"
                        id="role"
                        value={values.role}
                        onChange={(option) => setFieldValue("role", option)}
                        options={roles}
                        onBlur={handleBlur}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderRadius: "66px",
                            background: "#F1F5F9",
                            autoFocus: true,
                            "&:hover": {
                              background: "#F3F0FF",
                              border: "1px solid #AC98FE",
                            },
                            "&:focus": {
                              background: "#F3F0FF",
                            },
                            "&:focusVisible": {
                              border: "1px solid #AC98FE",
                            },
                          }),
                        }}
                      />

                      <FormErrorMessage>{errors.role}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      display="flex"
                      flexDir="column"
                      alignItems="flex-start"
                      mb="20px"
                      isInvalid={!!errors.created_at && touched.created_at}
                    >
                      <FormLabel
                        fontFamily="Satoshi"
                        color="#0F0931"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight="21px"
                        fontWeight="700"
                      >
                        Date Joined
                      </FormLabel>
                      <Field
                        as={Input}
                        {...inputStyle}
                        placeholder=""
                        bg="#F1F5F9"
                        id="created_at"
                        name="created_at"
                        pointerEvents="none"
                      />
                      <FormErrorMessage>{errors.created_at}</FormErrorMessage>
                    </FormControl>

                    <Button
                      color="#ffffff"
                      bg="#632EFF"
                      width="100%"
                      borderRadius={28}
                      fontWeight={500}
                      fontSize={18}
                      p="25px"
                      type="submit"
                      marginTop={10}
                    >
                      Update
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewMember;
