import { Flex, Link } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import React, { memo } from "react";
import { useRef } from "react";
import { colors } from "../../../assets/styles/config";
import { Icon } from "../../../components";
import Modal from "../../../components/Modal";
import { FaintBrownText } from "../styles";
import {
  AnchorTag,
  CloseBtn,
  CollectionsWrapper,
  Container,
  Detail,
  Heading,
  Subtitle,
  Title,
  WrapCollection,
} from "./styles";
import { MdOutlineLocationOn, MdOutlinePhone } from "react-icons/md";
import { BiLinkAlt } from "react-icons/bi";

const SingleCollection = ({ visible, setVisible, collection }) => {
  const containerRef = useRef(null);

  return (
    <Modal
      {...{
        visible,
        setVisible,
        childrenContainerRef: containerRef,
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      overlayLayer
      overlayLayerStyle={{
        backgroundColor: colors.black,
        opacity: 0.5,
      }}
    >
      <Container
        ref={containerRef}
        animate={{ scale: 1 }}
        initial={{ scale: 0.9 }}
      >
        {" "}
        <div>
          <Heading>
            <Title> {collection.title} </Title>
            <CloseBtn
              style={{ cursor: "pointer" }}
              onClick={() => setVisible(false)}
            >
              <Icon name="close" animateOnTap pointer />
            </CloseBtn>
          </Heading>
          <FaintBrownText>Collections</FaintBrownText>
          <CollectionsWrapper>
            {collection?.partners?.map((col) => (
              <WrapCollection>
                <img src={col.pic} alt={collection.title} />
                <Flex flexDirection="column" gap=".5rem">
                  <Subtitle>{col.partner}</Subtitle>
                  <Detail>
                    <div className="md-icons-2">
                      <MdOutlineLocationOn size="24" />{" "}
                    </div>
                    {col.location}
                  </Detail>
                  {col.phoneNumber && (
                    <Detail>
                      <MdOutlinePhone className="md-icons" />
                      <a href={`tel:0${col.phoneNumber}`}>0{col.phoneNumber}</a>
                    </Detail>
                  )}
                  {col.url && (
                    <Detail>
                      <BiLinkAlt className="md-icons" />
                      <a
                        href={`https://${col.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vist our page
                      </a>{" "}
                    </Detail>
                  )}
                  {/* <AnchorTag href=''>
                    Download the NAVI app for more info
                  </AnchorTag> */}
                </Flex>
              </WrapCollection>
            ))}
          </CollectionsWrapper>
        </div>{" "}
      </Container>
    </Modal>
  );
};

export default memo(SingleCollection);
