import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import {
 Button1,
 Button2,
 DeleteUserBox,
 DeleteUserText,
 FlexColumn,
 Heading5,
 RBEHeading,
 RBEHeadings,
 RBEInfo,
 RBESection,
 RBEWrapper,
} from "./styles";
import { onValue, ref, getDatabase, child, get } from "firebase/database";
import {
 Box,
 Button,
 Img,
 Input,
 InputGroup,
 Text,
 InputLeftElement,
} from "@chakra-ui/react";
import { Switch } from "@chakra-ui/react";
import { db } from "../../../firebase";
import { PhoneIcon } from "@chakra-ui/icons";
import ExportIcon from "../../../assets/images/svg/export-icon.svg";
import TrashIcon from "../../../assets/images/svg/trash-2-icon.svg";
import WarningLabel from "../../../assets/images/warning-icon.svg";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { LoadingIndicator, Modal } from "../../../components";
import { SearchIcon } from "../../../components/Icon/otherIcons";

const NavigatorManagement = () => {
 // STATES AND VARIABLES
 const tableRef = useRef(null);
 const containerRef = useRef(null);
 const navigatorsDb = ref(db, "users/user");
 const [showText, setShowText] = useState("Loading...");
 const [visible, setVisible] = useState(false);
 const [navigators, setNavigators] = useState([]);
 const [filteredNavigators, setFilteredNavigators] = useState([]);
 const [tickets, setTickets] = useState([]);
 // FUNCTIONS

 const showDeleteModal = () => {
  setVisible(true);
 };

 const deleteCurrentUser = (currentUser) => {
  setVisible(false);
 };

 const handleUserStatus = (e, currentUser) => {
  const newUsers = navigators.map((navigator) =>
   navigator.uid === currentUser.uid
    ? { ...currentUser, active: e.target.checked }
    : navigator
  );
  console.log(newUsers);
 };

 const filterNavigators = (e) => {
  let input = e.target.value.toLowerCase();
  let filteredUsers = navigators?.filter((navigator) =>
   navigator.name?.toLowerCase().includes(input)
  );
  setFilteredNavigators(filteredUsers);
 };

 const fetchTicketDatabase = async () => {
  const dbRef = ref(getDatabase());
  const db = await get(child(dbRef, `tickets`)).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();
    var array = Object.keys(myObj).map((key) => {
     return myObj[key];
    });
    setTickets(array);
   }
  });

  return db;
 };

 useEffect(() => {
  fetchTicketDatabase();
 }, []);

 // EFFECTS
 useEffect(() => {
  const unsubscribe = onValue(navigatorsDb, (snapshot) => {
   const data = snapshot?.val();
   const navigators =
    data && Object.values(data).filter((ig) => ig.role == "USER");
   setNavigators(navigators);
   setFilteredNavigators(navigators);
   setShowText("Sorry, we don't have any navigators by that name YET  :)");
  });
  return () => {
   unsubscribe();
  };
 }, []);

 if (filteredNavigators?.length < 1) {
  return (
   <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
    <LoadingIndicator />
   </Box>
  );
 }

 // RENDER
 return (
  <Box
   paddingInline={{ base: "15px", md: 0 }}
   margin={{ base: "10px", md: 0 }}
   paddingRight={{ md: "20px" }}
  >
   <RBEHeading>
    {" "}
    <Box display="flex" justifyContent="space-between">
     <InputGroup borderRadius="28px" width={{ base: "70%", md: "60%" }}>
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       borderRadius="28px"
       height={{ base: "50px", md: "48px" }}
       type="search"
       placeholder="Search"
       paddingLeft={"52px"}
       background="#F8FAFC"
       _focus={{
        background: "#F3F0FF",
       }}
       _focusVisible={{ border: "1px solid #AC98FE" }}
       onChange={(e) => filterNavigators(e)}
      />
     </InputGroup>
     <DownloadTableExcel
      filename="navigators table"
      sheet="navigators"
      currentTableRef={tableRef.current}
     >
      <Button
       background="#632EFF"
       borderRadius={{ base: "30px", md: "54px" }}
       padding={{ base: "12px 18px", md: "16px 60px" }}
       color="#fff"
       fontFamily="Creato Display"
       fontWeight={700}
       fontSize="14px"
       lineHeight="20px"
       letterSpacing="0.1px"
       height="48px"
      >
       <Img src={ExportIcon} />
       <Text display={{ base: "none", md: "initial" }}>Export </Text>
      </Button>
     </DownloadTableExcel>
    </Box>
   </RBEHeading>
   <Box overflowX={"auto"} class="custom-scrollbar" minHeight="100vh">
    {filteredNavigators?.length < 1 ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <>
      {filteredNavigators?.length > 0 ? (
       <RBEWrapper ref={tableRef}>
        <thead>
         <RBEHeadings>
          <td>Navi ID</td>
          <td>Name</td>
          <td>Email Address</td>
          <td>Phone Number</td>
          <td>Tickets Bought</td>
          <td>Date Joined</td>
          <td></td>
         </RBEHeadings>
        </thead>

        <RBESection>
         {filteredNavigators?.map((navigator) => {
          // const ordersLength =
          //   (navigator?.orders &&
          //     Object.keys(navigator?.orders)?.length) ||
          //   0;
          // console.log("navigator", navigator);
          const ordersLength = tickets?.filter(
           (item) => item?.user?.uid === navigator.uid
          )?.length;
          // var timestamp = dayjs(
          //   new Date(navigator?.timestamp?.replace(/['"]+/g, ""))
          // ).format("YYYY-MM-DD");
          // var timestampHr = dayjs(
          //   new Date(navigator?.timestamp?.replace(/['"]+/g, ""))
          // ).format("ha");
          var dateJoined = dayjs(navigator?.created_at).format("DD-MM-YYYY");
          return (
           <React.Fragment key={navigator.uid}>
            <RBEInfo>
             <td>{navigator.uid.slice(0, 6)}</td>
             <td>{navigator.name}</td>
             <td>{navigator.email}</td>
             <td>{navigator.phoneNumber}</td>
             <td>{ordersLength}</td>
             <td>{dateJoined}</td>
             {/* <td>
                      {timestamp}, {timestampHr}
                    </td> */}
             <td
              style={{
               display: "flex",
               alignItems: "center",
               gap: "0.75rem",
              }}
             >
              {" "}
              <Switch
               id="activate/deactivate"
               onChange={(e) => handleUserStatus(e, navigator)}
              />
              {/* <Button
               cursor="pointer"
               onClick={() => showDeleteModal(navigator)}
               background="transparent"
               padding="0px"
              >
               <Img src={TrashIcon} />
              </Button> */}
             </td>
            </RBEInfo>

            {/* DELETE MODAL */}
            <Modal
             {...{
              visible,
              setVisible,
              childrenContainerRef: containerRef,
             }}
             style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             }}
             overlayLayer
             overlayLayerStyle={{
              backgroundColor: "#000",
              opacity: 0.5,
             }}
            >
             <Box ref={containerRef} {...DeleteUserBox}>
              <Img src={WarningLabel} />
              <Box {...FlexColumn}>
               <Heading5>Delete User?</Heading5>
               <DeleteUserText>
                {" "}
                Are you sure you want to delete this user’s account?{" "}
               </DeleteUserText>
              </Box>
              <Box {...FlexColumn}>
               <Button1 onClick={() => deleteCurrentUser(navigator)}>
                Delete
               </Button1>
               <Button2 onClick={() => setVisible(false)}>Cancel</Button2>
              </Box>
             </Box>
            </Modal>
           </React.Fragment>
          );
         })}
        </RBESection>
       </RBEWrapper>
      ) : (
       <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop="30px"
       >
        <Text
         fontSize={{ base: "20px" }}
         lineHeight="125%"
         fontWeight="700"
         fontFamily="Satoshi"
         color="#334155"
        >
         No Navigators
        </Text>
       </Box>
      )}
     </>
    )}

    {/* {filteredNavigators?.length < 1 && (
          <Box
            fontSize={"20px"}
            fontWeight={500}
            width="100%"
            color="#64748B"
            height="200px"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            {showText}
          </Box>
        )} */}
   </Box>
  </Box>
 );
};

export default NavigatorManagement;
