import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, fontWeight } from "../../../assets/styles/config";

export const RBEHeading = styled.h3`
  font-family: "Creato Display";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 125%;
  /* identical to box height, or 25px */

  /* Slate 8 */

  color: #334155;
  margin-bottom: 16px;
`;

export const RBESection = styled.tbody``;

export const RBEWrapper = styled.table`
  background: #ffffff;
  /* Slate 3 */
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  padding-right: 20px;
`;

export const RBEHeadings = styled.tr`
  // display: flex;
  // justify-content: space-between;

  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  /* identical to box height, or 18px */

  color: #334155;

  td {
    padding: 20.5px 24px;
    border-bottom: 1px solid #e2e8f0;
    white-space: nowrap;
  }
`;

export const RBEInfo = styled.tr`
  font-family: "Satoshi";
  // display: flex;
  // justify-content: space-between;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  /* identical to box height, or 18px */

  color: #334155;

  td {
    padding: 17px 24px;
    border-bottom: 1px solid #e2e8f0;
    white-space: nowrap;
  }
`;

export const Heading5 = styled.h5`
  font-family: "Creato Display";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 134%;
  /* or 32px */

  letter-spacing: 0.1px;

  /* Brand color-Navy blue */

  color: #0f0931;
`;

export const DeleteUserText = styled.p`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  text-align: center;
  letter-spacing: 0.1px;

  color: #878498;
`;

export const Button1 = styled(motion.button)`
  background: #632eff;
  border-radius: 54px;
  color: white;
  width: 100%;
  padding-block: 19px;
  font-family: "Creato Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 1px;
  transition: 250ms ease;

  &:hover {
    opacity: 0.8;
    scale: 0.98;
  }
`;

export const Button2 = styled(motion.button)`
  border: 1px solid #252046;
  border-radius: 54px;
  color: #252046;
  width: 100%;
  padding-block: 19px;
  font-family: "Creato Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 1px;
  transition: 250ms ease;

  &:hover {
    opacity: 0.8;
    scale: 0.98;
  }
`;

export const DeleteUserBox = {
  backgroundColor: "white",
  padding: "32px 25px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "24px",
  gap: "31px",
  width: { base: "90%", md: "50%", lg: "35%" },
};

export const FlexColumn = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "11px",
  width: "100%",
};
