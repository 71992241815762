import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Collections from "./pages/Collections";
import Experiences from "./pages/Experiences";
import City from "./pages/City";
import Home from "./pages/Home/index";
import Deals from "./pages/Deals/index";
import AllDeals from "./pages/AllDeals/index";
import Deal from "./pages/Deal/index";
import { Footer, Loader } from "./components";
import Error from "./pages/404";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ForgetPassword from "./pages/SignIn/forgetpassword";
//import ResetPassword from "./pages/SignIn/resetPassword";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./components/ScrollToTop";
import { AuthContext } from "./context/AuthContext";
import Layout from "./components/Layout/index";

import SingleExperience from "./pages/Experiences/SingleExperience";
import Cart from "./pages/Cart";
import PartnerSignin from "./pages/Partner/index";
import PartnerForgetPassword from "./pages/Partner/forgetPassword";
import PartnerOnboarding from "./pages/Partner/Onbording";
//import PartnerResetPassword from "./pages/Partner/resetPassword";
import AdminSignin from "./pages/Admin/index";
import AdminForgetPassword from "./pages/Admin/forgetPassword";
import CreateAdmin from "./pages/Admin/createAdmin";
import PartnerDashboardLayout from "./pages/Partner/Dashboard/layout";
import PartnerDashboard from "./pages/Partner/Dashboard";
import PartnerRecentlyExperience from "./pages/Partner/Dashboard/recentExperience";
import PartnerDashboardExperiences from "./pages/Partner/Dashboard/Experience";
import PartnerDashboardExperience from "./pages/Partner/Dashboard/Experience/experience";
import PartnerAddExperience from "./pages/Partner/Dashboard/Experience/addExperience";
import PartnerProfile from "./pages/Partner/Dashboard/Profile";
import PartnerRecurringExperience from "./pages/Partner/Dashboard/Experience/recurring-experience";
import PartnerSupport from "./pages/Partner/Dashboard/Support/index";
import PartnerRedeemExperiences from "./pages/Partner/Dashboard/RedeemExperiences/index";
import PartnerManageDeals from "./pages/Partner/Dashboard/ManageDeals/index";
import PartnerAddDeal from "./pages/Partner/Dashboard/ManageDeals/addDeal";
import PartnerRoles from "./pages/Partner/Dashboard/Roles/index";
import PartnerAddRole from "./pages/Partner/Dashboard/Roles/addRoles";
import PartnerTeamMember from "./pages/Partner/Dashboard/Members/index";
import PartnerViewMember from "./pages/Partner/Dashboard/Members/view";
import PartnerBusinessProfile from "./pages/Partner/Dashboard/BusinessProfile/index";
import PartnerBank from "./pages/Partner/Dashboard/Bank/index";
import PartneBoughtExperiences from "./pages/Partner/Dashboard/Experience/boughtExperiences";

import AdminDashboard from "./pages/Admin/Dashboard";
import AdminDashboardLayout from "./pages/Admin/Dashboard/layout";
import AdminProfile from "./pages/Admin/Dashboard/Profile";
import AdminAdministrator from "./pages/Admin/Dashboard/Administrator";
import AdminRoles from "./pages/Admin/Dashboard/Administrator/role";
import AddAdminRoles from "./pages/Admin/Dashboard/Administrator/addRole";
import AdminCollection from "./pages/Admin/Dashboard/Collection";
import AdminAddCollection from "./pages/Admin/Dashboard/Collection/addCollection";
import AdminRegisterPartner from "./pages/Admin/Dashboard/Partner";
import AdminAddPartner from "./pages/Admin/Dashboard/Partner/addPartner";
import AdminExperience from "./pages/Admin/Dashboard/Partner/experience/index";
import AdminPartnerExperience from "./pages/Admin/Dashboard/Partner/experience/partner-experience";
import AdminRecurringPartnerExperiences from "./pages/Admin/Dashboard/Partner/experience/recurring-partner-experience";
import AdminBoughtExperiences from "./pages/Admin/Dashboard/Partner/experience/boughtExperiences";
import AdminSupport from "./pages/Admin/Support";
import AdminBank from "./pages/Admin/Dashboard/Bank";
import AdminAddbank from "./pages/Admin/Dashboard/Bank/addBank";

import AdminAddExperience from "./pages/Admin/Dashboard/Partner/experience/addExperience";
import ViewAdmin from "./pages/Admin/Dashboard/Administrator/view";
import Prices from "./pages/Prices";
import UserProfile from "./pages/UserProfile";
import Checkout from "./pages/Checkout";
import SystemConfig from "./pages/Admin/SystemConfig";
import Categories from "./pages/Admin/SystemConfig/Categories";
import Tags from "./pages/Admin/SystemConfig/Tags";
import Legal from "./pages/Admin/SystemConfig/Legal";
import Roles from "./pages/Admin/SystemConfig/Roles";
import NavigatorManagement from "./pages/Admin/NavigatorManagement";
import RedeemExperiences from "./pages/Admin/PartnerManagement/RedeemExperiences";
import ManageDeals from "./pages/Admin/PartnerManagement/ManageDeals";
import AddDeal from "./pages/Admin/PartnerManagement/ManageDeals/addDeal";
import PartnerHomePage from "./pages/PartnerHomePage/PartnerHomePage";
import SingleCollectionRoute from "./pages/Collections/SingleCollectionRoute/SingleCollectionRoute";
import Subscribed from "./pages/Subscribe";

function Router() {
 const location = useLocation();
 const [loading, setLoading] = useState(true);
 const { role, partner } = useContext(AuthContext);
 const [access_point, setAccessPoint] = useState([]);
 const locationJs = location.pathname;

 useEffect(() => {
  if (locationJs !== "/") {
   const metaChoice = locationJs.split("/")[1];
   const meta = metaChoice.charAt(0).toUpperCase() + metaChoice.slice(1);
   // console.log(meta);
   document.title = meta + " | NAVI";
  }
 }, [locationJs]);

 useEffect(() => {
  setAccessPoint(JSON.parse(localStorage.getItem("access_point")));
  if (location && role) {
   setLoading(false);
  }
 }, [location]);

 // if (gettingUser) {
 //   return <Loader />;
 // }

 useEffect(() => {
  const mode = new URLSearchParams(window.location.search).get("mode");
  const oobCode = new URLSearchParams(window.location.search).get("oobCode");

  if (mode && oobCode) {
   setTimeout(() => {
    window.location.replace("/signin");
   }, 4000);
  }
 }, []);

 return (
  <>
   <Toaster position="top-center" reverseOrder={false} />
   <Routes>
    {/* REGULAR ROUTES */}
    <Route path="/" element={<Home />} />
    <Route path="/city/:cityId" element={<City />} />
    <Route path="/experiences" element={<Experiences />} meta="Experiences" />
    <Route path="/experiences/:experienceId" element={<SingleExperience />} />
    <Route path="/checkout" element={<Checkout />} />
    <Route path="/cart" element={<Cart />} />
    <Route path="/deals" element={<Deals />} />
    <Route path="/all-deals" element={<AllDeals />} />
    <Route path="/deal/:id" element={<Deal />} />
    <Route path="/collections/:cityId" element={<Collections />} />
    <Route
     path="/collections/:cityId/:cityTitle"
     element={<SingleCollectionRoute />}
    />
    <Route path="/prices" element={<Prices />} />
    <Route path="/signup" element={<SignUp />} />
    {/* <Route path="/__/auth/action" element={<SignIn />} /> */}
    <Route path="/signin" element={<SignIn />} />
    <Route path="/forget-password" element={<ForgetPassword />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/partner" element={<PartnerHomePage />} />
    <Route path="/partner/signin" element={<PartnerSignin />} />
    <Route
     path="/partner/forget-password"
     element={<PartnerForgetPassword />}
    />
    <Route path="/partner/onboarding" element={<PartnerOnboarding />} />

    {/* {role === "USER" && (
          <Route path="/user-profile" element={<UserProfile />} />
        )} */}

    {/* PARTNER/SUPER-ADMIN ROUTES */}

    <Route path="partner" element={<PartnerDashboardLayout />}>
     <>
      {role === "PARTNER" && (
       <>
        <Route path="dashboard" element={<PartnerDashboard />} />
        <Route
         path="dashboard/:expId"
         element={<PartnerRecentlyExperience />}
        />
        <Route path="dashboard/profile" element={<PartnerProfile />} />
        <Route path="experience" element={<PartnerDashboardExperiences />} />
        <Route
         path="experience/:expId/:timeframesId/:timeSlotId"
         element={<PartnerDashboardExperience />}
        />
        <Route
         path="experience/recurring/:expId/:timeframesId/:timeSlotId"
         element={<PartnerRecurringExperience />}
        />
        <Route path="experience/add" element={<PartnerAddExperience />} />
        <Route
         path="experience/edit/:expId"
         element={<PartnerAddExperience />}
        />
        <Route
         path="experience/bought/:expId"
         element={<PartneBoughtExperiences />}
        />
        <Route path="support" element={<PartnerSupport />} />
        <Route
         path="redeem-experiences"
         element={<PartnerRedeemExperiences />}
        />
        {partner.subscribed ? (
         <>
          {" "}
          <Route path="manage-deals" element={<PartnerManageDeals />} />
          <Route path="manage-deals/add" element={<PartnerAddDeal />} />
          <Route path="manage-deals/edit/:expId" element={<PartnerAddDeal />} />
          <Route path="roles" element={<PartnerRoles />} />
          <Route path="roles/add" element={<PartnerAddRole />} />
          <Route path="roles/edit/:role" element={<PartnerAddRole />} />
          <Route path="members" element={<PartnerTeamMember />} />
          <Route path="members/view/:id" element={<PartnerViewMember />} />
         </>
        ) : (
         <Route path="*" element={<Subscribed />} />
        )}

        <Route path="business-profile" element={<PartnerBusinessProfile />} />
        <Route path="bank" element={<PartnerBank />} />
       </>
      )}
      {access_point?.includes("partner_dashboard") && (
       <>
        <Route path="dashboard" element={<PartnerDashboard />} />
        <Route path="dashboard/profile" element={<PartnerProfile />} />
       </>
      )}

      {access_point?.includes("partner_uploadExperiences") && (
       <>
        <Route path="experience" element={<PartnerDashboardExperiences />} />
        <Route
         path="experience/:expId/:timeframesId/:timeSlotId"
         element={<PartnerRecurringExperience />}
        />
        <Route path="experience/add" element={<PartnerAddExperience />} />
        <Route
         path="experience/edit/:expId"
         element={<PartnerAddExperience />}
        />
        <Route
         path="experience/bought/:expId"
         element={<PartneBoughtExperiences />}
        />
       </>
      )}

      {access_point?.includes("partner_support") && (
       <Route path="support" element={<PartnerSupport />} />
      )}

      {access_point?.includes("partner_redeemExperiences") && (
       <Route
        path="redeem-experiences"
        element={<PartnerRedeemExperiences />}
       />
      )}

      {access_point?.includes("partner_manageDeals") && (
       <>
        <Route path="manage-deals" element={<PartnerManageDeals />} />
        <Route path="manage-deals/add" element={<PartnerAddDeal />} />
        <Route path="manage-deals/edit/:expId" element={<PartnerAddDeal />} />
       </>
      )}

      {access_point?.includes("partner_roles") && (
       <>
        <Route path="roles" element={<PartnerRoles />} />
        <Route path="roles/add" element={<PartnerAddRole />} />
        <Route path="roles/edit/:role" element={<PartnerAddRole />} />
       </>
      )}

      {access_point?.includes("partner_teamMembers") && (
       <>
        <Route path="members" element={<PartnerTeamMember />} />
        <Route path="members/view/:id" element={<PartnerViewMember />} />
       </>
      )}

      {access_point?.includes("partner_businessProfile") && (
       <Route path="business-profile" element={<PartnerBusinessProfile />} />
      )}
      {access_point?.includes("partner_bank") && (
       <Route path="bank" element={<PartnerBank />} />
      )}
     </>
    </Route>

    {/* SUPER ADMIN ROUTES */}
    <Route path="/admin/signin" element={<AdminSignin />} />
    <Route path="/admin/forget-password" element={<AdminForgetPassword />} />
    <Route path="admin" element={<AdminDashboardLayout />}>
     {access_point?.includes("dashboard") && (
      <Route path="dashboard" element={<AdminDashboard />} />
     )}

     {access_point?.includes("navigator") && (
      <Route path="navigator-management" element={<NavigatorManagement />} />
     )}

     <Route path="dashboard/profile" element={<AdminProfile />} />

     {access_point?.includes("teamMembers") && (
      <>
       <Route path="administrator" element={<AdminAdministrator />} />
       <Route path="administrator/view/:id" element={<ViewAdmin />} />
       <Route path="administrator/edit/:id" element={<ViewAdmin />} />
      </>
     )}
     {access_point?.includes("roles") && (
      <>
       <Route path="roles/add" element={<AddAdminRoles />} />
       <Route path="roles" element={<AdminRoles />} />
       <Route path="roles/edit/:role" element={<AddAdminRoles />} />
      </>
     )}

     {access_point?.includes("collections") && (
      <>
       <Route path="collection" element={<AdminCollection />} />{" "}
       <Route path="collection/add" element={<AdminAddCollection />} />
       <Route
        path="collection/edit/:city/:id"
        element={<AdminAddCollection />}
       />
      </>
     )}

     <Route path="support" element={<AdminSupport />} />

     {access_point?.includes("partnerReg") && (
      <>
       <Route path="partner-registration" element={<AdminRegisterPartner />} />
       <Route path="partner-registration/add" element={<AdminAddPartner />} />
       <Route
        path="partner-registration/view/:id"
        element={<AdminAddPartner />}
       />
       <Route
        path="partner-registration/edit/:id"
        element={<AdminAddPartner />}
       />
      </>
     )}

     {access_point?.includes("partnerBank") && (
      <>
       <Route path="bank" element={<AdminBank />} />
       <Route path="add-bank" element={<AdminAddbank />} />
       <Route path="add-bank/edit/:id" element={<AdminAddbank />} />
      </>
     )}

     {access_point?.includes("partnerExp") && (
      <>
       <Route path="partner-experience" element={<AdminExperience />} />
       <Route
        path="partner-experience/:id"
        element={<AdminPartnerExperience />}
       />
       <Route
        path="partner-experience/add/:id"
        element={<AdminAddExperience />}
       />
       <Route
        path="partner-experience/add/registering/:registering"
        element={<AdminAddExperience />}
       />
       <Route
        path="partner-experience/edit/:id/:expId"
        element={<AdminAddExperience />}
       />
       <Route
        path="partner-experience/:id/:expId/:timeframesId/:timeSlotId"
        element={<AdminRecurringPartnerExperiences />}
       />
       <Route
        path="partner-experience/bought/:id/:expId"
        element={<AdminBoughtExperiences />}
       />
       {/* <Route
                path="partner-experience/edit/:id"
                element={<AdminAddExperience />}
              /> */}

       <Route path="redeem-experiences" element={<RedeemExperiences />} />
       <Route path="manage-deals" element={<ManageDeals />} />
       <Route path="manage-deals/add" element={<AddDeal />} />
       <Route
        path="manage-deals/edit/:partnerId/:expId"
        element={<AddDeal />}
       />
      </>
     )}
    </Route>
    <Route path="system-config" element={<SystemConfig />}>
     {access_point?.includes("categoriesTags") && (
      <>
       <Route path="categories" element={<Categories />} />
       <Route path="tags" element={<Tags />} />
      </>
     )}
     {access_point?.includes("legalDoc") && (
      <Route path="legal" element={<Legal />} />
     )}
     {/* <Route path="roles" element={<Roles />} /> */}
    </Route>

    {!loading && <Route path="*" element={<Error />} />}
   </Routes>
  </>
 );
}

export default Router;
