import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  HStack,
  Text,
  Img,
  Input,
  Button,
  List,
  Flex,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

const RecentExperience = () => {
  const params = useParams();
  const expId = params?.expId;
  // console.log("expId", expId);
  return <Box></Box>;
};

export default RecentExperience;
