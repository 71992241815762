import { Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { css } from "styled-components";
import { ticketPlaceholder } from "../../assets/strings/imageExports";
import { Icon } from "../../components";
import {
  Card,
  Container,
  CounterIcon,
  CounterText,
  FaintText,
  ImgContainer,
  ProductImg,
  ProductText,
  SingleProduct,
  SingleProductContainer,
  Title,
  Wrapper,
  ProductDetails,
  CheckoutBtn,
} from "./styles";
import { Footer, Header } from "../../components";
import SingleCard from "./SingleCard";

const Component = () => {
  // REACT
  const [showSingleProduct, setShowSingleProduct] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({});

  // FUNCTIONS
  const deleteItem = (id) => {
    // console.log(id);
  };

  // JSX
  const RenderCounter = ({
    subtract,
    subtractValue,
    add,
    addValue,
    count = 0,
  }) => (
    <Flex gap="1rem">
      <CounterIcon onClick={() => subtract(subtractValue - 1)}>-</CounterIcon>
      <CounterText>{count}</CounterText>
      <CounterIcon onClick={() => add(addValue + 1)}>+</CounterIcon>
    </Flex>
  );

  return (
    <Container>
      <Wrapper>
        <Box>
          <Title
            css={css`
              padding-top: 2rem;
            `}
          >
            My Cart
          </Title>
          <Card
            css={css`
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
            `}
          >
            <SingleProductContainer>
              <ImgContainer
                onClick={() => setShowSingleProduct(!showSingleProduct)}
              >
                <ProductImg src={ticketPlaceholder} alt="ticket" />
              </ImgContainer>
              <SingleProduct>
                <ProductDetails>
                  <ProductText>Meal Ticket</ProductText>
                  <FaintText>Short Description</FaintText>
                </ProductDetails>
                <RenderCounter />
                <Flex gap="2rem">
                  <ProductText
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Icon
                      name="naira"
                      css={css`
                        width: 20px;
                        margin-right: 3px;

                        @media screen and (min-width: 768px) {
                          width: 40px;
                          margin-right: -5px;
                        }
                      `}
                      animateOnTap
                    />
                    4000.00
                  </ProductText>

                  <button onClick={() => deleteItem("id")}>
                    <Icon name="deleteBlue" pointer />
                  </button>
                </Flex>
              </SingleProduct>
            </SingleProductContainer>
          </Card>{" "}
        </Box>
        <Box>
          <Title>Cart Summary</Title>
          <Card
            css={css`
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
            `}
          >
            <SingleProduct>
              <Text
                color="#4F4F4F"
                fontWeight={500}
                fontSize={{ base: 14, md: 15, lg: 16 }}
              >
                Subtotal
              </Text>
              <ProductText
                css={css`
                  display: flex;
                  align-items: center;
                  font-weight: 600;
                `}
              >
                <Icon
                  name="naira"
                  css={css`
                    margin-right: -5px;
                  `}
                  animateOnTap
                />
                4000.00
              </ProductText>
            </SingleProduct>
            <Flex
              alignItems="center"
              width="100%"
              justifyContent="center"
              marginTop="40px"
            >
              <CheckoutBtn>Check Out</CheckoutBtn>
            </Flex>
          </Card>{" "}
        </Box>
      </Wrapper>
      {showSingleProduct && (
        <SingleCard
          visible={showSingleProduct}
          setVisible={setShowSingleProduct}
          collection={selectedProduct}
        />
      )}
    </Container>
  );
};

const Cart = () => (
  <>
    <Header bgColor={"white"} color="dark" />
    <Component />

    <Footer />
  </>
);

export default Cart;
