import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, fontWeight } from "../../../assets/styles/config";

export const Wrapper = styled.div``;

export const Container = styled(motion.div)`
 padding: 20px;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 100%;

 @media screen and (min-width: 1080px) {
  padding: 3rem 4rem;
 }
`;

export const Card = styled(motion.div)`
 background-color: ${colors.primaryWhite};
 padding: 2rem;
 padding: 3rem 4rem;
 position: relative;
 border-radius: 28px;
 height: 500px;

 @media screen and (min-width: 1080px) {
  min-width: 300px;
  height: 500px;
  border-radius: 28px;
  padding: 3rem 4rem;
 }
`;

export const CloseBtn = styled.button`
 position: absolute;
 right: 20px;
 top: 20px;
`;

export const addExpBtn = {
 color: "#ffffff",
 bg: "#632EFF",
 borderRadius: "54px",
 fontWeight: 500,
 fontSize: 18,
 marginRight: "20px",
 p: "20px 30px",
};

export const addExpBtnMb = {
 display: { base: "flex", md: "none" },
 position: "absolute",
 bottom: 100,
 right: 0,
 width: 70,
 height: 70,
 borderRadius: "100%",
};

export const tabListStyles = {
 padding: { md: "5px" },
 borderRadius: "32px",
 background: { base: "#FFFFFF", md: "#F1F5F9" },
 width: { base: "100%", md: "500px" },
 justifyContent: "center",
};

export const tabStyles = {
 fontSize: { base: "14px", md: "14px" },
 paddingTop: "10px",
 paddingBottom: "10px",
 width: { base: "100%", md: "250px" },
 letterSpacing: "1px",
};

export const tabTHStyles = {
 fontFamily: "Satoshi",
 color: "#252046",
 fontSize: { base: "12px", md: "14px" },
 lineHeight: "125%",
 fontWeight: "700",
 padding: "14px 20px",
 textTransform: "capitalize",
};

export const tabTRStyles = {
 padding: "14px 20px",
 fontSize: { base: "14px" },
 lineHeight: "125%",
 fontWeight: "500",
 fontFamily: "Satoshi",
};
