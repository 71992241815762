import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, Button } from "@chakra-ui/react";
import { Header } from "../../components";
import Hero from "./Hero";
import { Footer } from "../../components";
import Loader from "../../components/Loader";
import { ref, onValue } from "firebase/database";
import { db } from "../../firebase";
import { deviceType, eventEmitter } from "../../utils";
import style from "./index.module.css";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Bounce } from "react-reveal";
import {
  CalfCardsWrapper,
  SingleCard,
  SingleCardImg,
  SingleCardText,
} from "./styles";
import SideImg from "../../assets/images/homepage-side-image.png";
import DoubleExp from "../../assets/images/double-exp-showcase.png";
import Testimonials from "./Testimonials";
import ActionsSlider from "./ActionsSlider";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import * as showcaseImages from "../../assets/strings/imageExports";

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [city, setCity] = useState([]);
  const cityExperienceDb = ref(db, "cityExperience/city");
  const page = "home";
  const hasInit = useRef(false);
  const [hasPreloaded, setHasPreloaded] = useState(false);

  const init = useCallback(async () => {
    // check if device is mobile
    const ismobile = deviceType() === "mobile";

    // import animations
    const App = (await import("../../animation/index")).App;

    // console.log("App======>", App);

    // initialize new animation
    new App({ page, ismobile, loading });

    // update ref
    hasInit.current = true;

    gsap.config({
      nullTargetWarn: false,
    });
  }, []);

  // EFFECTS
  useEffect(() => {
    document.title = "NAVI | Making Memorable Trips";
  }, []);

  useEffect(() => {
    !hasInit.current && init();

    // add event emmitter
    eventEmitter.once("preloading-completed", () => {
      setHasPreloaded(true);
    });
  }, []);

  useEffect(() => {
    hasPreloaded && eventEmitter.emit("content-mounted");
  }, [hasPreloaded]);

  const sortByPosition = (obj) => {
    const order = [],
      res = {};
    Object.keys(obj).forEach((key) => {
      return (order[obj[key]["key"] - 1] = key);
    });
    order.forEach((key) => {
      res[key] = obj[key];
    });
    return res;
  };

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onValue(cityExperienceDb, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        //  console.log("sort", data, sortByPosition(data));
        setCity(sortByPosition(data));
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div data-animation="scroll-container">
        <div data-animation="smooth-scroll">
          <Header bgColor="transparent" />
          <Hero />
          <SemiSection />
          <ActionsSlider />
          <MidSection />
          <CalfCards />
          <SideImageSection />
          <Testimonials />
          <Footer />
        </div>
      </div>
    </>
  );
};

const SemiSection = () => {
  return (
    <div className={style.semiSection}>
      <p className={style.heading}>Experience, enjoy and explore your city</p>
      <p className={style.subtext}>
        With NAVI, you can discover new and exciting things to do. As a
        Navigator you will have first-hand access to special, popular
        attractions, and the opportunity to create unforgettable memories.
      </p>
      <Link to="/experiences">
        <Button
          color="#fff"
          bg="#632EFF"
          borderRadius="54px"
          width={{ base: "200px", md: "244px" }}
          height={{ base: "46px", md: "54px" }}
          fontWeight={500}
          fontSize={{ base: 14, md: 16, lg: 18 }}
          display={"flex"}
          gap="8px"
          marginTop={{ base: "7px", md: "24px", lg: "28px" }}
          transition={".2s ease"}
          _hover={{
            border: "1.5px solid #fff",
          }}
        >
          View experiences
        </Button>
      </Link>
    </div>
  );
};

const MidSection = () => {
  return (
    <div className={style.midSection}>
      <p className={style.heading}>Discover your city!</p>
      <p className={style.midSubtext}>
        As a Navigator, you'll have access to an abundance of exciting and
        unique activities, all at your fingertips. Our quick links make it easy
        for you to discover new and exciting things to do, no matter where you
        are.
      </p>
      <Link to="/collections/lagos">
        <Button
          color="#fff"
          bg="#632EFF"
          borderRadius="54px"
          width={{ base: "200px", md: "244px" }}
          height={{ base: "46px", md: "54px" }}
          fontWeight={500}
          fontSize={{ base: 14, md: 16, lg: 18 }}
          display={"flex"}
          gap="8px"
          marginTop={{ base: "7px", md: "24px", lg: "28px" }}
          transition={".2s ease"}
          _hover={{
            border: "1.5px solid #fff",
          }}
        >
          <p>View Lagos guide</p>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="14" cy="14" r="14" fill="white" />
            <path
              d="M16.0253 19.6833C15.8669 19.6833 15.7086 19.625 15.5836 19.5C15.3419 19.2583 15.3419 18.8583 15.5836 18.6167L20.2003 14L15.5836 9.38332C15.3419 9.14166 15.3419 8.74166 15.5836 8.49999C15.8253 8.25832 16.2253 8.25832 16.4669 8.49999L21.5253 13.5583C21.7669 13.8 21.7669 14.2 21.5253 14.4417L16.4669 19.5C16.3419 19.625 16.1836 19.6833 16.0253 19.6833Z"
              fill="#632EFF"
            />
            <path
              d="M20.943 14.625H6.91797C6.5763 14.625 6.29297 14.3417 6.29297 14C6.29297 13.6583 6.5763 13.375 6.91797 13.375H20.943C21.2846 13.375 21.568 13.6583 21.568 14C21.568 14.3417 21.2846 14.625 20.943 14.625Z"
              fill="#632EFF"
            />
          </svg>
        </Button>
      </Link>
    </div>
  );
};

const CalfCards = () => {
  const showcaseCards = [
    {
      id: 9,
      coverImage: showcaseImages.museum,
      collectionName: "Museums",
    },
    {
      id: 0,
      coverImage: showcaseImages.restaurants,
      collectionName: "Restaurants",
    },
    {
      id: 1,
      coverImage: showcaseImages.zoos,
      collectionName: "Zoos",
    },
    {
      id: 2,
      coverImage: showcaseImages.parks,
      collectionName: "Parks",
    },
    {
      id: 3,
      coverImage: showcaseImages.servicedApartments,
      collectionName: "Serviced Apartments",
    },
    {
      id: 4,
      coverImage: showcaseImages.spas,
      collectionName: "Spas",
    },
    {
      id: 5,
      coverImage: showcaseImages.cinemas,
      collectionName: "Cinemas & Theatres",
    },
    {
      id: 6,
      coverImage: showcaseImages.bars,
      collectionName: "Bars",
    },
    {
      id: 7,
      coverImage: showcaseImages.cafes,
      collectionName: "Cafes",
    },
    {
      id: 16,
      coverImage: showcaseImages.amusementParks,
      collectionName: "Amusement Parks",
    },
    {
      id: 8,
      coverImage: showcaseImages.beaches,
      collectionName: "Beaches",
    },
    {
      id: 11,
      coverImage: showcaseImages.hotels,
      collectionName: "Hotels",
    },
    {
      id: 10,
      coverImage: showcaseImages.coworkingSpace,
      collectionName: "Co-working Spaces",
    },
    {
      id: 12,
      coverImage:
        "https://img.theculturetrip.com/wp-content/uploads/2022/08/lux-club.jpg",
      collectionName: "Clubs",
    },
    {
      id: 13,
      coverImage: showcaseImages.artGalleries,
      collectionName: "Art Galleries",
    },
  ];

  return (
    <CalfCardsWrapper>
      <Splide
        options={{
          perPage: 4,
          gap: "28px",
          pagination: false,
          arrows: false,
          autoplay: true,
          interval: 2000,
          rewind: true,
          breakpoints: {
            1080: {
              perPage: 3,
            },
            868: {
              perPage: 2,
            },
            600: {
              perPage: 1,
              pagination: true,
            },
          },
        }}
      >
        {showcaseCards.map((card) => {
          return (
            <SplideSlide key={card.id}>
              <SingleCard>
                <SingleCardImg src={card.coverImage} />
                <SingleCardText>{card.collectionName}</SingleCardText>
              </SingleCard>
            </SplideSlide>
          );
        })}
      </Splide>
    </CalfCardsWrapper>
  );
};

const SideImageSection = () => {
  return (
    <div className={style.sideImageWrapper}>
      <div className={style.sideImageSection}>
        <div>
          <img
            src={SideImg}
            alt="concert showcase on thenavi app"
            className={style.sideImg}
          />
          <img
            src={DoubleExp}
            alt="experiences showcase on thenavi app"
            className={style.doubleExp}
          />
        </div>
        <div className={style.sideImageInfo}>
          <p className={style.sideImageText}>
            <b>Our mobile app is launching soon!</b> <br />
            Be the first to know! Join the waitlist!
          </p>
          <a
            href="https://view.flodesk.com/pages/63035fbd65a1834dde5e59b3"
            target="_blank"
          >
            <Button
              color="#fff"
              bg="#632EFF"
              borderRadius="54px"
              width="244px"
              height="54px"
              fontWeight={500}
              fontSize={{ base: 14, md: 16, lg: 18 }}
              display={"flex"}
              gap="8px"
              marginTop="28px"
              transition={".2s ease"}
              _hover={{
                border: "1.5px solid #fff",
              }}
            >
              Join Waitlist
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
