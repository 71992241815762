import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 List,
 ListItem,
 SimpleGrid,
 Flex,
 Tabs,
 TabList,
 TabPanels,
 Tab,
 TabPanel,
 Image,
 Switch,
 Button,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import { Button as CustomButton } from "../../../../components";
import { AuthContext } from "../../../../context/AuthContext";
import { Link } from "react-router-dom";
import {
 getDatabase,
 ref as dbRef,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { storage, db } from "../../../../firebase";
import { NoCollections } from "../../../Experiences/styles";
import { colors } from "../../../../assets/styles/config";
import { Card, CloseBtn, Container } from "../../Dashboard/styles";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
} from "../../../../components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Warning from "../../../../assets/images/warning-icon.svg";
import toast from "react-hot-toast";
import { sendPushNotification } from "../../../../api/user";

const ManageDeals = () => {
 const navigate = useNavigate();
 const { user } = useContext(AuthContext);
 const [loading, setLoading] = useState(false);
 const [allPartner, setAllPartner] = useState([]);
 const [coupons, setCoupons] = useState([]);
 const [modalType, setModalType] = useState("");
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [selectedPartner, setSelectedPartner] = useState(null);
 const [selectedExp, setSelectedExp] = useState(null);

 const fetchDatabase = async () => {
  setLoading(true);
  //get user experience
  const db = await get(child(dbRef(getDatabase()), `users/partner`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     const myObj = snapshot.val();
     setAllPartner(
      Object.keys(myObj)
       .map((key) => {
        return myObj[key];
       })
       .filter((item) => item?.enabled && item.coupon)
     );
     setCoupons(
      Object.keys(myObj)
       .map((key) => {
        return myObj[key];
       })
       .filter((item) => item?.enabled && item.coupon)
       .map((item) => [...item.coupon])
     );
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });

  return db;
 };

 useEffect(() => {
  fetchDatabase();
 }, []);

 const onChangeHandle = async (e, item, partnerId) => {
  toast.loading("loading");
  const db = getDatabase();

  const filterPartner = allPartner?.filter(
   (item) => item?.uid === partnerId
  )[0];

  const updates = {};
  updates["users/partner/" + partnerId] = {
   ...filterPartner,
   coupon: filterPartner?.coupon.map((coup) => {
    if (coup.experiences?.id === item.experiences?.id) {
     return {
      ...item,
      active: !item?.active,
     };
    }
    return item;
   }),
  };

  await update(dbRef(db), updates)
   .then(async () => {
    toast.dismiss();
    toast.success("Successfully updated deal");
    fetchDatabase();
    if (user) {
     await sendPushNotification({
      title: "admin",
      body: `${user?.name} updated deal`,
      data: {
       name: user?.name,
       photoURL: user?.photoURL,
       push_time: Date.now(),
      },
     });
    }
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 const handleDelete = async () => {
  toast.loading("loading");
  const db = getDatabase();
  const updates = {};

  updates["users/partner/" + selectedPartner + "/coupon"] = allPartner
   ?.filter((item) => item?.uid === selectedPartner)[0]
   ?.coupon?.filter(
    (itm) => Number(itm?.experiences?.id) !== Number(selectedExp)
   );
  await update(dbRef(db), updates)
   .then(async () => {
    toast.dismiss();
    toast.success("Successfully deleted deal");
    setVisible(!visible);
    setModalType("");
    setSelectedPartner(null);
    setSelectedExp(null);
    fetchDatabase();
    if (user) {
     await sendPushNotification({
      title: "admin",
      body: `${user?.name} deleted deal`,
      data: {
       name: user?.name,
       photoURL: user?.photoURL,
       push_time: Date.now(),
      },
     });
    }
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 return (
  <Box height="100%" padding="20px" background="#F5F8FA">
   <Box
    borderRadius="6px"
    // mt="30px"
    minHeight="80vh"
    // padding="30px"
   >
    <Tabs variant="soft-rounded">
     <Flex
      background="#FFFFFF"
      alignItems="flex-start"
      flexDir={"row"}
      justifyContent="space-between"
      padding="20px"
      borderRadius="16px"
     >
      <TabList
       padding="5px"
       borderRadius="32px"
       background="#F1F5F9"
       //  width="270px"
       justifyContent="center"
       className="tabs"
      >
       <Tab width="110px">Coupons</Tab>
       <Tab width="110px">Gift cards</Tab>
      </TabList>

      <Link to="/admin/manage-deals/add">
       <CustomButton
        animateOnTap
        animateOnHover
        buttonColor="#252046"
        css={css`
         width: fit-content;
         padding: 0.5rem 2rem;
         height: auto;
         font-weight: 500;
         font-family: "Creato Display";
         display: flex;
         gap: 15px;

         @media screen and (max-width: 768px) {
          border-radius: 10px;
          display: none;
         }
        `}
       >
        <AddIcon /> Add Deals
       </CustomButton>

       <CustomButton
        animateOnTap
        animateOnHover
        buttonColor="#252046"
        css={css`
         height: auto;
         font-weight: 500;
         font-family: "Creato Display";
         border-radius: 100px;

         @media screen and (min-width: 768px) {
          display: none;
         }
        `}
        onClick={() => {
         setVisible(!visible);
         setModalType("add");
        }}
       >
        <AddIcon h="16px" w="16px" />
       </CustomButton>
      </Link>
     </Flex>

     <TabPanels>
      <TabPanel>
       {loading ? (
        <Box
         display="flex"
         alignItems="center"
         justifyContent="center"
         mt="40px"
        >
         <LoadingIndicator />
        </Box>
       ) : (
        <Box mt="30px">
         {allPartner?.length > 0 ? (
          <SimpleGrid
           columns={{ base: 1, md: 3 }}
           alignItems="center"
           spacing={10}
           width="100%"
          >
           {allPartner?.map((partner, index) =>
            partner?.coupon?.map((item, idx) => (
             <Box minWidth={{ md: "344px" }}>
              <Box position="relative">
               <Image
                src={partner?.coverURL}
                borderRadius="16px 16px 0px 0px"
                width="100%"
                height="181px"
               />
               <Text
                position="absolute"
                bottom="10px"
                left="10px"
                color="#ffffff"
                fontSize="16px"
                lineHeight="14px"
               >
                {partner?.businessName}
               </Text>
              </Box>
              <Box
               padding="20px 10px"
               background="#FFFFFF"
               borderBottomRadius="16px"
              >
               <List width="100%">
                <ListItem
                 display="flex"
                 alignItems="center"
                 justifyContent="space-between"
                 borderBottom="0.5px solid #E2E8F0"
                 padding="10px 0"
                >
                 <Text
                  background={item?.active ? "#ECFDF5" : "#f36740"}
                  padding="5px"
                  borderRadius="24px"
                  color={item?.active ? "#15803D" : "#eefff4"}
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="12px"
                 >
                  {item?.active ? "Active" : "Deactivated"}
                 </Text>
                 <Switch
                  colorScheme="green"
                  isChecked={item?.active}
                  onChange={(e) => {
                   onChangeHandle(e, item, partner.uid);
                  }}
                  //
                 />
                </ListItem>
                <ListItem
                 display="flex"
                 alignItems="center"
                 justifyContent="space-between"
                 borderBottom="0.5px solid #E2E8F0"
                 padding="10px 0"
                >
                 <Text
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="12px"
                  color="#64748B"
                 >
                  Coupon type
                 </Text>
                 <Text
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="14px"
                  color="#0F0931"
                 >
                  {item?.couponType}
                 </Text>
                </ListItem>
                <ListItem
                 display="flex"
                 alignItems="center"
                 justifyContent="space-between"
                 borderBottom="0.5px solid #E2E8F0"
                 padding="10px 0"
                >
                 <Text
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="12px"
                  color="#64748B"
                 >
                  Coupon code
                 </Text>
                 <Text
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="14px"
                  color="#0F0931"
                 >
                  {item?.couponCode}
                 </Text>
                </ListItem>
                <ListItem
                 display="flex"
                 alignItems="center"
                 justifyContent="space-between"
                 borderBottom="0.5px solid #E2E8F0"
                 padding="10px 0"
                >
                 <Text
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="12px"
                  color="#64748B"
                 >
                  Usage
                 </Text>
                 <Text
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="14px"
                  color="#0F0931"
                 >
                  {item?.currentUsage}
                 </Text>
                </ListItem>
                <ListItem
                 display="flex"
                 alignItems="center"
                 justifyContent="space-between"
                 padding="10px 0"
                >
                 <Text
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="12px"
                  color="#64748B"
                 >
                  Expires date
                 </Text>
                 <Text
                  fontFamily="Satoshi"
                  fontWeight="500"
                  fontSize="14px"
                  color="#0F0931"
                 >
                  {dayjs(item?.expiryDate)?.format("DD-MM-YYYY")}
                 </Text>
                </ListItem>
               </List>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="20px"
                marginTop="20px"
               >
                <Button
                 color={colors.primaryWhite}
                 bg={colors.primaryPurple}
                 borderRadius={28}
                 fontWeight={500}
                 fontSize={18}
                 p="24px 20px"
                 width="100%"
                 onClick={() =>
                  navigate(
                   `/admin/manage-deals/edit/${partner.uid}/${item?.experiences?.id}`
                  )
                 }
                 //   type="submit"
                >
                 View details
                </Button>
                <Button
                 color="#0F0931"
                 bg="transparent"
                 border="1px solid #252046"
                 borderRadius={28}
                 fontWeight={500}
                 fontSize={18}
                 p="24px 20px"
                 width="100%"
                 type="button"
                 onClick={() => {
                  setVisible(!visible);
                  setModalType("delete");
                  setSelectedPartner(partner.uid);
                  setSelectedExp(item?.experiences?.id);
                 }}
                >
                 Delete
                </Button>
               </Box>
              </Box>
             </Box>
            ))
           )}
          </SimpleGrid>
         ) : (
          <Box
           display="flex"
           justifyContent="center"
           alignItems="center"
           marginTop="30px"
          >
           <Text
            fontSize={{ base: "20px" }}
            lineHeight="125%"
            fontWeight="700"
            fontFamily="Satoshi"
            color="#334155"
           >
            No Deals, Add new Deals
           </Text>
          </Box>
         )}
        </Box>
       )}
      </TabPanel>
      <TabPanel>
       <p>Coming soon...</p>
      </TabPanel>
     </TabPanels>
    </Tabs>
   </Box>{" "}
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
     //   style={{ marginTop: modalType === "add" && "300px" }}
    >
     {modalType === "delete" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Deal
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you want to delete this deal?
        </Text>
        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setModalType("");
          }}
         >
          Close
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default ManageDeals;
