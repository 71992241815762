import React, { useEffect, useRef, useState } from "react";
import { BigImageTitle, Container, ExpImgWrapper, Wrapper } from "./styles";
import { colors } from "../../assets/styles/config";
import CollectionsList from "./CollectionsList";
import { Footer, Header } from "../../components";
import Loader from "../../components/Loader";

const Collections = () => {
  const [currentCity, setCurrentCity] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <Wrapper>
      {/* <Header bgColor={colors.primaryWhite} color="dark" /> */}
      <ExpImgWrapper>
        <Header bgColor={"transparent"} color="white" />
        <BigImageTitle>
          {currentCity && currentCity}
          <br /> Collections
        </BigImageTitle>
      </ExpImgWrapper>
      <Container>
        <CollectionsList
          currentCity={currentCity}
          setCurrentCity={setCurrentCity}
          setLoading={setLoading}
        />
      </Container>
      <Footer />
    </Wrapper>
  );
};

export default Collections;
