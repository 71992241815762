import React, { useContext, useState, useEffect, useRef } from "react";
import {
 Box,
 HStack,
 Text,
 SimpleGrid,
 Img,
 Input,
 List,
 Avatar,
 Flex,
 ListItem,
 Button,
} from "@chakra-ui/react";
// import NotificationIcon from '../../assets/images/Notification.svg';
import NotificationIcon from "../../assets/images/svg/notification.svg";
import ArrowLeft from "../../assets/images/arrow-left-dark.svg";
import Green from "../../assets/images/svg/green.svg";
import CustomAvatar from "../../assets/images/Avatar.png";
import styles from "./index.module.css";
import { AuthContext } from "../../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import BarImg from "../../assets/images/bar.svg";
import closeIcon from "../../assets/images/Close.svg";
import { Dropdown } from "../Icon/sidebarIcons";
import { routeTitle, username, userRole } from "./Sidebar/styles";
import { colors } from "../../assets/styles/config";
import { useLocation, useNavigate } from "react-router-dom";
import { firebaseCloudMessaging, app, cf } from "../../firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import { sendPushNotification } from "../../api/user";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {
 updateAdminDatabase,
 updatePartnerDatabase,
 updatePartnerMemberDatabase,
} from "../../api/user";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";

dayjs.extend(relativeTime);
dayjs.locale("en");
dayjs.extend(utc);
dayjs.extend(timezone);

const Navigation = ({ isMobile, handleOpenMenu, isOpen }) => {
 const { partner } = useContext(AuthContext);
 const [search, setSearch] = useState("");
 const location = useLocation();
 const navigate = useNavigate();
 const params = useParams();
 const expId = params?.expId;
 const [dropdown, setDropdown] = useState(false);
 const [dropdownNotif, setDropdownNotif] = useState(false);
 const [isShowingDropdown, setIsShowingDropdown] = useState(false);
 const { signout, user, role, isLoading, updateAdministrator } =
  useContext(AuthContext);
 const [mounted, setMounted] = useState(false);
 const [notification, setNotification] = useState([]);
 const [experiences, setExperience] = useState([]);
 const [canBack, setCanBack] = useState(false);

 const toggle = useRef(null);
 const toggleHandler = useRef(null);
 const toggleHandlerNotif = useRef(null);
 const messaging = getMessaging(app);

 useEffect(() => {
  if (expId) {
   const fetchExperience = async () => {
    const dbRef = ref(getDatabase());
    //get user experience
    const db = await get(child(dbRef, `cityExperience/experiences/`)).then(
     (snapshot) => {
      if (snapshot.exists()) {
       setExperience(snapshot.val()[expId]);
       setCanBack(true);
      }
     }
    );

    return db;
   };
   fetchExperience();
  } else {
   setExperience([]);
   setCanBack(false);
  }
 }, [expId]);

 function mapRevers(reverse) {
  let reversed = reverse.map(
   (num, index, reverse) => reverse[reverse.length - 1 - index]
  );
  return reversed;
 }

 var storeNotification = JSON.parse(
  localStorage.getItem("notifications") || "[]"
 );

 var storePartnerNotification = JSON.parse(
  localStorage.getItem("partner-notifications") || "[]"
 );

 useEffect(() => {
  if (role === "PARTNER" || user?.partnerUid !== undefined) {
   if (storePartnerNotification) {
    setNotification(mapRevers(storePartnerNotification));
   }
  } else if (storeNotification) {
   setNotification(mapRevers(storeNotification));
  }
 }, []);

 function TimeAgo(timestamp) {
  const timeAgo = dayjs(timestamp).fromNow();

  return <span>{timeAgo}</span>;
 }

 if (mounted) {
  // console.log("mounted", mounted);
  onMessage(messaging, (payload) => {
   setDropdownNotif(!dropdownNotif);
   // setNotification((prev) => [
   //   ...prev,
   //   {
   //     title: payload?.notification?.title,
   //     body: payload?.notification?.body,
   //     data: payload?.data?.body && JSON.parse(payload?.data?.body),
   //   },
   // ]);
   if (payload?.notification?.title === "admin") {
    var storeNotifications = JSON.parse(
     localStorage.getItem("notifications") || "[]"
    );
    storeNotifications.push({
     title: payload?.notification?.title,
     body: payload?.notification?.body,
     data: payload?.data?.body && JSON.parse(payload?.data?.body),
    });
    localStorage.setItem("notifications", JSON.stringify(storeNotifications));
    setNotification(mapRevers(storeNotifications));
   }
   if (payload?.notification?.title === "partner") {
    var storeNotifications = JSON.parse(
     localStorage.getItem("partner-notifications") || "[]"
    );
    storeNotifications.push({
     title: payload?.notification?.title,
     body: payload?.notification?.body,
     data: payload?.data?.body && JSON.parse(payload?.data?.body),
    });
    localStorage.setItem(
     "partner-notifications",
     JSON.stringify(storeNotifications)
    );
    setNotification(mapRevers(storeNotifications));
   }
  });
 }

 useEffect(() => {
  firebaseCloudMessaging.init();
  let token;
  const setToken = async () => {
   token = await firebaseCloudMessaging.tokenInlocalStorage();
   // if (token === null) {
   //   token = await firebaseCloudMessaging.init();
   // }

   let payload;
   if (role === "PARTNER") {
    if (user?.businessName && !user?.pushToken) {
     payload = {
      ...user,
      pushToken: token,
     };
     await updatePartnerDatabase(payload?.uid, payload);
    }
    if (user?.pushToken && token !== user?.pushToken) {
     payload = {
      ...user,
      pushToken: token,
     };
     await updatePartnerDatabase(payload?.uid, payload);
    }
   } else if (user?.partnerUid !== undefined) {
    if (user?.partner && !user?.pushToken) {
     payload = {
      ...user,
      pushToken: token,
     };
     await updatePartnerMemberDatabase(
      payload?.uid,
      payload.partnerUid,
      payload
     );
    }
    if (user?.pushToken && token !== user?.pushToken) {
     payload = {
      ...user,
      pushToken: token,
     };
     await updatePartnerMemberDatabase(
      payload?.uid,
      payload.partnerUid,
      payload
     );
    }
   } else {
    if (user?.name && !user?.pushToken) {
     payload = {
      ...user,
      pushToken: token,
     };
     await updateAdminDatabase(payload?.uid, payload);
    }
    if (user?.pushToken && token !== user?.pushToken) {
     payload = {
      ...user,
      pushToken: token,
     };
     await updateAdminDatabase(payload?.uid, payload);
    }
   }
   if (token) {
    setMounted(true);
    // not working
   }
  };
  const result = setToken();
 }, [user]);

 const determineHeader = (route) => {
  let header = "";
  if (location?.pathname?.includes("/admin/collection")) {
   header = "Collections";
  } else if (location?.pathname?.includes("/admin/administrator")) {
   header = "Administration";
  } else if (location?.pathname?.includes("/admin/partner-experience")) {
   header = "Partners experience";
  } else if (location?.pathname?.includes("/admin/partner-registration")) {
   header = "Partners Registration";
  } else if (location?.pathname?.includes("/admin/manage-deals")) {
   header = "Manage Deals";
  } else if (location?.pathname?.includes("/partner/experience/add")) {
   header = "Add new Experience";
  } else if (location?.pathname?.includes("/partner/experience")) {
   header = "Experience";
  } else if (location?.pathname?.includes("/partner/manage-deals")) {
   header = "Manage Deals";
  } else if (location?.pathname?.includes("/partner/members")) {
   header = "Team Members";
  } else if (location?.pathname?.includes("/partner/roles/add")) {
   header = "Add Role";
  } else if (location?.pathname?.includes("/admin/add-bank")) {
   header = "Settlement Bank";
  } else {
   header = determineHeading(location?.pathname);
  }
  return header;
 };

 useEffect(() => {
  if (location?.pathname?.includes("/partner/experience/add")) {
   setCanBack(true);
  } else if (location?.pathname?.includes("/partner/manage-deals/add")) {
   setCanBack(true);
  } else if (location?.pathname?.includes("/partner/members/view")) {
   setCanBack(true);
  } else if (location?.pathname?.includes("/partner/roles/edit")) {
   setCanBack(true);
  } else if (location?.pathname?.includes("/partner/experience/recurring")) {
   setCanBack(true);
  } else if (location?.pathname?.includes("/partner/experience/bought")) {
   setCanBack(true);
  } else if (location?.pathname?.includes("/partner/experience/edit")) {
   setCanBack(true);
  } else if (location?.pathname?.includes("/partner/roles/add")) {
   setCanBack(true);
  } else {
   setCanBack(false);
  }
 }, [location?.pathname]);

 const determineHeading = (pathname) => {
  const pathArray = pathname?.split("/").filter((p) => p);
  const desiredPathName = pathArray[pathArray.length - 1];
  if (desiredPathName.includes("-")) {
   return desiredPathName.split("-").join(" ");
  } else {
   return desiredPathName;
  }
 };

 const handleClickOutside = (event) => {
  // if (toggleHandler.current.contains(event.target) && !isShowingDropdown) {

  //   return;
  // }

  if (!toggleHandler?.current?.contains(event.target)) {
   setDropdown(false);
  }

  if (!toggleHandlerNotif?.current?.contains(event.target)) {
   setDropdownNotif(false);
  }
 };

 useEffect(() => {
  document.addEventListener("click", handleClickOutside);

  return () => {
   document.removeEventListener("click", handleClickOutside);
  };
 }, []);

 const handleOpenNotif = async () => {
  setDropdownNotif(!dropdownNotif);
  // const payload = {
  //   title: "new-member",
  //   body: "Seyi Ewebajo has joined the dashboard as a manager",
  //   data: JSON.stringify({ ...user, push_time: dayjs(Date.now()) }),
  // };

  // await sendPushNotification(payload);
  // const registrationTokens = [user?.pushToken];
 };

 return (
  <Box
   background="#FFFFFF"
   position="fixed"
   top="0"
   left="0"
   right="0"
   // boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
   borderBottom="1px solid #E2E8F0"
   zIndex="10"
  >
   <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    height="85px"
    padding={{ base: "0 10px 0 0", lg: "0 30px 0 0" }}
    margin="auto"
    width="100%"
   >
    <Box
     display="flex"
     alignItems="center"
     justifyContent="space-between"
     width="100%"
     position={isOpen ? "absolute" : "relative"}
     maxWidth={{ base: isOpen ? "250px" : "230px", md: "300px" }}
     height="100%"
     backgroundColor={isMobile ? "#FFFFFF" : "#0F0931"}
     padding={{ base: "30px 10px", lg: "30px" }}
     zIndex="350"
    >
     <Box
      display={{ base: "block", md: "none" }}
      marginLeft="20px"
      width="100%"
      cursor="pointer"
     >
      {!canBack ? (
       <Img src={BarImg} minWidth="20px" onClick={handleOpenMenu} />
      ) : (
       <Img
        src={ArrowLeft}
        minWidth="20px"
        onClick={() => {
         navigate(-1);
         setCanBack(false);
         setExperience([]);
        }}
       />
      )}
     </Box>
    </Box>
    <Box
     mr={{ md: "auto" }}
     ml={{ md: "35px" }}
     width={{ base: "600px", md: "100%" }}
     display="flex"
     alignItems={{ base: "center", md: "flex-start" }}
     justifyContent={{ base: "center", md: "flex-start" }}
     textAlign="center"
    >
     <Text {...routeTitle}>{experiences?.title ?? determineHeader()}</Text>
    </Box>

    <Box
     display="flex"
     alignItems="center"
     justifyContent="center"
     gap={{ md: "1.5rem" }}
     width="100%"
    >
     <Box
      position="relative"
      width="100%"
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-end"
      gap={7}
     >
      {role === "PARTNER" && !partner.subscribed && (
       <Button background={"#fad948"}>
        <Link
         to="/partner#subscribe"
         spy={true}
         smooth={true}
         offset={-70} // Adjust the offset based on your layout
         duration={500}
        >
         Subscribe
        </Link>
       </Button>
      )}

      <button
       class="button-animation-1"
       ref={toggleHandlerNotif}
       onClick={handleOpenNotif}
       style={{ position: "relative" }}
      >
       <Img src={NotificationIcon} alt="notification" />
       <Box></Box>
       {notification?.length > 0 && (
        <Img
         src={Green}
         alt="green"
         position="absolute"
         bottom="0"
         right="0"
         fontSize="50px"
        />
       )}
      </button>

      {dropdownNotif && notification?.length > 0 && (
       <Box
        position="absolute"
        background="#ffffff"
        boxShadow="0px 0px 21px 5px #ECF3FA"
        borderRadius="13px"
        padding="20px 0px"
        width="430px"
        left="-200px"
        maxHeight="496px"
        overflowY="scroll"
        top="40px"
        // right="20px"
        // width="100%"
        //   bottom="0"
       >
        <Flex
         width="100%"
         padding="0px 20px"
         justifyContent="space-between"
         marginBottom="20px"
        >
         <Text>Notifications</Text>
         <Text
          color="#632EFF"
          cursor="pointer"
          onClick={() => {
           setNotification([]);
           setDropdownNotif(!dropdownNotif);
           localStorage.removeItem("notifications");
           localStorage.removeItem("partner-notifications");
          }}
         >
          Mark all as read
         </Text>
        </Flex>
        <List>
         {notification?.length &&
          notification?.reverse().map((item, index) => (
           <ListItem
            padding="14px 20px"
            borderTop="1px solid #E2E8F0"
            alignItems="center"
            gap="10px"
            display="flex"
            cursor="pointer"
            pointerEvents={item?.data?.route ? "auto" : "none"}
            onClick={() => {
             navigate(item?.data?.route);
             var storeNotifications = JSON.parse(
              localStorage.getItem("notifications") || "[]"
             );
             var storepartnerNotifications = JSON.parse(
              localStorage.getItem("partner-notifications") || "[]"
             );
             if (storeNotifications) {
              storeNotifications.splice(index + 1, 1);
              localStorage.setItem(
               "notifications",
               JSON.stringify(storeNotifications)
              );
              setNotification(storeNotifications);
             } else {
              storepartnerNotifications.splice(index + 1, 1);
              localStorage.setItem(
               "partner-notifications",
               JSON.stringify(storepartnerNotifications)
              );
              setNotification(storepartnerNotifications);
             }
            }}
           >
            <Avatar name={item?.data?.name} src={item?.data?.photoURL} />
            <Box alignItems="flex-start">
             <Text lineHeight="125%" fontWeight="500" color="#252046">
              {item?.body}
             </Text>
             <Text color="#64748B" lineHeight="125%" fontWeight="400">
              {item?.data?.push_time && TimeAgo(item?.data?.push_time)}
             </Text>
            </Box>
           </ListItem>
          ))}
        </List>
       </Box>
      )}
     </Box>

     <Box
      // display="flex"
      alignItems="center"
      gap="10px"
      width="100%"
      display={{ base: "none", md: "flex" }}
     >
      <Box className={styles.search}>
       <Flex gap=".75rem" justifyContent={"center"} alignItems="center">
        <Avatar
         name={user?.name}
         src={user?.partnerPhotoURL ?? user?.photoURL}
        />
        <Flex flexDirection="column" gap={"0.5rem"}>
         <Text {...username} color={colors.darkGrey}>
          {role === "PARTNER" ? user?.businessName : user?.name}
         </Text>
        </Flex>
       </Flex>
      </Box>
      <button
       ref={toggleHandler}
       onClick={() => {
        setDropdown(!dropdown);
       }}
       style={{ cursor: "pointer" }}
       class="admin-nav-dropdown"
      >
       <Img
        src={Dropdown}
        transition=".2s ease"
        transform={dropdown ? "rotate(-180deg)" : "rotate(0deg)"}
       />
      </button>
     </Box>
     <Box display="flex" alignItems="center">
      <Box marginLeft="20px" position="relative" ref={toggle}>
       {
        <Box
         position="absolute"
         top="8"
         right="0"
         background="#FFFFFF"
         border="1.5px solid #E2E8F0"
         borderRadius="8px"
         width="252px"
         transition=".3s ease"
         transform={`translateX(${dropdown ? "0px" : "300px"}) scale(${
          dropdown ? "1" : "0"
         })`}
         // overflow='hidden'
        >
         <Box display="flex" flexDir="column" padding="20px" cursor="pointer">
          <Link
           to={
            role === "PARTNER" || user?.partnerUid
             ? "/partner/dashboard/profile"
             : "/admin/dashboard/profile"
           }
          >
           <Text
            color="#656565"
            fontSize={{ base: "10px", md: "12px", lg: "14px" }}
            lineHeight="140%"
            marginBottom="20px"
            transition=".2s ease"
            onClick={() => setDropdown(false)}
            _hover={{ opacity: "0.7" }}
           >
            My Profile
           </Text>
          </Link>

          <Text
           color="#656565"
           fontSize={{ base: "10px", md: "12px", lg: "14px" }}
           lineHeight="140%"
           transition=".2s ease"
           _hover={{ opacity: "0.7" }}
           onClick={() => {
            signout();
           }}
          >
           Sign Out
          </Text>
         </Box>
        </Box>
       }
      </Box>
     </Box>
    </Box>
   </Box>
  </Box>
 );
};

export default Navigation;
