import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Img1 from "../../assets/images/Navi-Logo-Inversion 1.svg";
import bgImg1 from "../../assets/images/Navi-Logo-Inversion 2.svg";
import bgImg2 from "../../assets/images/Navi-Logo-Inversion 3.svg";
import styles from "./index.module.css";
import { colors } from "../../assets/styles/config";
import Logo from "../../assets/images/Navi-Logo.png";
import { Link } from "react-router-dom";
import ShowImg from "../../assets/images/Show.svg";
import HideImg from "../../assets/images/Hide.svg";
import { AuthContext } from "../../context/AuthContext";
import Header from "../../components/Header/index";
import { toast } from "react-hot-toast";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password does not match!")
    .required("Password is required"),
});

const Admin = () => {
  const { addAdmin } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      {" "}
      <Header color="dark" p={{ base: "15px 10px", lg: "20px 60px" }} />
      <Box display={{ lg: "flex" }}>
        <Box
          w={{ base: "100%", lg: "50%" }}
          padding={{ base: 5, xl: 0 }}
          h="100%"
          overflowY="scroll"
          display="flex"
          flexDir={{ base: "column" }}
          alignItems={{ base: "flex-start", lg: "center" }}
          justifyContent="center"
        >
          <Img src={Logo} h="50px" display={{ base: "block", lg: "none" }} />
          <Box
            maxW="32rem"
            mt={{ base: "50px", lg: "120px" }}
            mx="auto"
            w="100%"
          >
            <Text
              color="#1D1D1D"
              fontSize={{ base: "28px", md: "30px", lg: "32px" }}
              lineHeight="140%"
              fontWeight="600"
              textAlign="center"
            >
              Create Admin
            </Text>
            <Box mt="20px" w="100%">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phoneNumber: "",
                  address: "",
                  password: "",
                }}
                onSubmit={async (values) => {
                  toast.loading("loading");
                  //console.log(response);
                  const payload = {
                    ...values,
                    role: "ADMIN",
                  };
                  await addAdmin(payload);
                }}
                validationSchema={validationSchema}
              >
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      w="100%"
                      alignItems="center"
                      mb="10px"
                      isRequired
                      isInvalid={!!errors.name && touched.name}
                    >
                      <FormLabel
                        color="#1D1D1D"
                        fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                        lineHeight="140%"
                        fontWeight="400"
                        mb="10px"
                      >
                        Name
                      </FormLabel>
                      <Field
                        as={Input}
                        borderRadius="10px"
                        border="0.705598px solid #DFDDDD"
                        p="10px"
                        type="text"
                        bg="#FEFEFF"
                        id="name"
                        name="name"
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      w="100%"
                      alignItems="center"
                      mb="10px"
                      isRequired
                      isInvalid={!!errors.email && touched.email}
                    >
                      <FormLabel
                        color="#1D1D1D"
                        fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                        lineHeight="140%"
                        fontWeight="400"
                        mb="10px"
                      >
                        Email
                      </FormLabel>
                      <Field
                        as={Input}
                        borderRadius="10px"
                        border="0.705598px solid #DFDDDD"
                        p="10px"
                        type="email"
                        bg="#FEFEFF"
                        id="email"
                        name="email"
                      />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      w="100%"
                      alignItems="center"
                      mb="10px"
                      isInvalid={!!errors.password && touched.password}
                      isRequired
                    >
                      <FormLabel
                        color="#1D1D1D"
                        fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                        lineHeight="140%"
                        fontWeight="400"
                        mb="10px"
                      >
                        Password
                      </FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          borderRadius="4px"
                          border="0.705598px solid #DFDDDD"
                          p="10px"
                          type={showPassword ? "text" : "password"}
                          bg="#FEFEFF"
                          id="password"
                          name="password"
                        />
                        <InputRightElement width="3rem">
                          <Box onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? (
                              <Img src={HideImg} alt="hide" />
                            ) : (
                              <Img src={ShowImg} alt="show" />
                            )}
                          </Box>
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      w="100%"
                      alignItems="center"
                      mb="10px"
                      isInvalid={
                        !!errors.confirmPassword && touched.confirmPassword
                      }
                      isRequired
                    >
                      <FormLabel
                        color="#1D1D1D"
                        fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                        lineHeight="140%"
                        fontWeight="400"
                        mb="10px"
                      >
                        Confirm Password
                      </FormLabel>

                      <InputGroup>
                        <Field
                          as={Input}
                          borderRadius="4px"
                          border="0.705598px solid #DFDDDD"
                          p="10px"
                          type={showConfirmPassword ? "text" : "password"}
                          bg="#FEFEFF"
                          id="confirmPassword"
                          name="confirmPassword"
                        />
                        <InputRightElement width="3rem">
                          <Box
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {showConfirmPassword ? (
                              <Img src={HideImg} alt="hide" />
                            ) : (
                              <Img src={ShowImg} alt="show" />
                            )}
                          </Box>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>

                    <Button
                      color="#ffffff"
                      bg={colors.blue}
                      borderRadius={28}
                      fontWeight={500}
                      fontSize={18}
                      marginTop={10}
                      p="24px 20px"
                      w="100%"
                      type="submit"
                    >
                      Create
                    </Button>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
        <Box
          className={styles.imgbg}
          display={{ base: "none", lg: "flex" }}
          position="fixed"
          right="0"
        >
          <Img src={Img1} alt="navi-icon" height="180px" />
          <Img
            src={bgImg1}
            alt="navi-icon"
            height="200px"
            position="absolute"
            top="0"
            right="0"
          />
          <Img
            src={bgImg2}
            alt="navi-icon"
            height="200px"
            position="absolute"
            bottom="0"
            left="0"
          />
        </Box>
      </Box>
    </>
  );
};

export default Admin;
