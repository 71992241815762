import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, fontWeight } from "../../assets/styles/config";

export const Container = styled(motion.div)`
  padding: 2rem;
  padding: 3rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1080px) {
    padding: 3rem 4rem;
  }
`;

export const Card = styled(motion.div)`
  background-color: ${colors.primaryWhite};
  padding: 2rem;
  padding: 3rem 4rem;
  position: relative;
  border-radius: 28px;
  height: 470px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1080px) {
    // height: 500px;
    // border-radius: 28px;
    // padding: 3rem 4rem;
  }
`;
