import {
 Box,
 Flex,
 Input,
 InputGroup,
 InputLeftElement,
 Text,
 Image,
 Button as ChakraBtn,
 Icon,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState, useContext } from "react";
import {
 Card,
 CloseBtn,
 Container,
 ModalContainer,
 SubTableRow,
 Table,
 TableRow,
 searchInputStyle,
 svgFlexStyles,
} from "../../../Admin/PartnerManagement/RedeemExperiences/styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { css } from "styled-components";
import { Button, EmptyState, MobileAddBtn } from "../../../../components";
import Lottie from "../../../../assets/images/redeem-experiences-lottie.svg";
import { Modal } from "../../../../components";
import { colors } from "../../../../assets/styles/config";
import QrScan from "react-qr-reader";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { db } from "../../../../firebase";
import { Icon as CloseIconWrapper } from "../../../../components";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { TbCurrencyNaira } from "react-icons/tb";
import { LoadingIndicator } from "../../../../components";
import ArrowRight from "../../../../assets/images/arrowleft2.svg";
import { addExpBtn, addExpBtnMb } from "../styles";
import { IoTicketOutline } from "react-icons/io5";

const RedeemExperiences = () => {
 const { user } = useContext(AuthContext);
 const [filteredRedeemedList, setFilteredRedeemedList] = useState(null);
 const [redeemedList, setRedeemedList] = useState(null);
 const [qrscan, setQrscan] = useState("No result");
 const [loading, setLoading] = useState(false);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);

 //   FUNCTIONS
 const fetchPartnerDatabase = async () => {
  // setLoading(true);
  const dbRef = ref(getDatabase());
  const db = await get(child(dbRef, `tickets`)).then((snapshot) => {
   if (snapshot.exists()) {
    const uid = user?.partnerUid ?? user?.uid;
    const myObj = snapshot.val();
    var array = Object.keys(myObj)
     .map((key) => {
      return myObj[key];
     })
     .filter((item) => item?.partnerId === uid);

    let filterArray = Object.keys(array).map((key) => {
     return array[key];
    });

    const groupedItems = {};

    for (const item of filterArray) {
     if (groupedItems.hasOwnProperty(item?.user.email)) {
      // groupedItems[item?.user.email].total += item.total;
      groupedItems[item?.user.email].count++;
      if (item.status === "redeemed") {
       groupedItems[item?.user.email].redeemedCount++;
      }
     } else {
      groupedItems[item?.user.email] = {
       ...item,
       count: 1,
       redeemedCount: item.status === "unredeemed" ? 0 : 1,
      };
     }
    }

    const result = Object.values(groupedItems);
    setRedeemedList(result);
    // setLoading(false);
   }
  });

  return db;
 };

 const truncateString = (string, maxLength) =>
  string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

 const fetchDatabase = async (id) => {
  const dbRef = ref(getDatabase());
  const db = await get(child(dbRef, `tickets`)).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();
    const uid = user?.partnerUid ?? user?.uid;
    return Object.keys(myObj)
     .map((key) => {
      return myObj[key];
     })
     .filter((item) => item?.partnerId === uid && item?.token === id)[0];
   }
  });

  return db;
 };

 const handleSearchInput = (searchValue) => {
  const hasSearchValue = redeemedList.filter((item) =>
   item?.user.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  setFilteredRedeemedList(hasSearchValue);
 };

 const handleScan = async (data) => {
  if (data) {
   setQrscan(data);
   const ticket = await fetchDatabase(data);
   const status = ticket?.status;

   if (status === "unredeemed") {
    update(ref(db, "tickets/" + data), {
     status: "redeemed",
    })
     .then(() => {
      toast.success(`ticker ${data} successfully redeemed`);
      setVisible(!visible);
      fetchPartnerDatabase();
     })
     .catch((error) => {
      //  console.error(error.message);
      toast.error(error.message);
      setVisible(!visible);
     })
     .finally(() => {
      setTimeout(() => {
       toast.dismiss();
      }, 3000);
     });
    window.location.reload();
   } else {
    toast.error(`ticker ${data} already redeemed`);
    setVisible(!visible);
    setTimeout(() => {
     toast.dismiss();
    }, 2000);
   }

   //  set(partnerRef, "redeemed");

   // alert("token redeemed");
  }
 };
 const handleError = (err) => {
  // console.error(err);
 };

 //   EFFECTS
 useEffect(() => {
  fetchPartnerDatabase();
 }, []);

 useEffect(() => {
  if (redeemedList) {
   setFilteredRedeemedList(redeemedList);
  }
 }, [redeemedList]);

 //   JSX
 const renderSearchAndAddCategoryArea = useMemo(
  () => (
   <>
    <Box
     display={{ base: "none", md: "flex" }}
     justifyContent="space-between"
     marginBottom={"54px"}
    >
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => handleSearchInput(e.target.value)}
      />
     </InputGroup>

     <Button
      animateOnTap
      animateOnHover
      buttonColor="#252046"
      css={css`
       width: fit-content;
       padding: 0.5rem 2rem;
       height: auto;
       font-weight: 500;
       font-family: "Creato Display";
       display: flex;
       gap: 15px;

       @media screen and (max-width: 768px) {
        border-radius: 10px;
       }
      `}
      onClick={() => {
       setVisible(!visible);
      }}
     >
      Redeem experience
     </Button>
    </Box>
   </>
  ),
  [redeemedList, filteredRedeemedList, handleSearchInput]
 );

 if (loading) {
  return (
   <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
    <LoadingIndicator />
   </Box>
  );
 }

 return (
  <Box
   background={{ md: colors.primaryWhite }}
   padding={{ base: "0px 20px", md: "2rem" }}
   borderRadius="10px"
   minHeight="82vh"
   position="relative"
  >
   {renderSearchAndAddCategoryArea}
   <MobileAddBtn
    onClick={() => setVisible(!visible)}
    Icon={<Icon as={IoTicketOutline} boxSize={7} />}
   />

   <>
    {filteredRedeemedList?.length > 0 ? (
     <>
      <Box display={{ base: "none", md: "block" }}>
       <Table>
        <thead>
         <TableRow>
          <td>Navi Id</td>
          <td>Name</td>
          <td>Email address</td>
          <td>Tickets bought</td>
          <td>Tickets Redeemed</td>
          <td>Amount</td>
         </TableRow>
        </thead>
        <tbody>
         {filteredRedeemedList?.map((ticket, index) => (
          <React.Fragment key={index}>
           <SubTableRow>
            <td>{truncateString(ticket?.user?.uid, 8) ?? "Guest"}</td>
            <td>{ticket.user.name}</td>
            <td>{ticket.user.email}</td>
            <td>{ticket.count}</td>
            <td>{ticket.redeemedCount}</td>
            <td style={{ display: "flex" }}>
             <TbCurrencyNaira />
             {ticket.total}
            </td>
           </SubTableRow>
          </React.Fragment>
         ))}
        </tbody>
       </Table>
      </Box>
      <Box display={{ base: "block", md: "none" }}>
       <Box
        overflowX={"auto"}
        background="#fff"
        width="100%"
        border="1px solid #e2e8f0"
        borderRadius="16px"
        marginTop="20px"
        padding="0px 10px"
       >
        {filteredRedeemedList?.map((ticket, index) => (
         <Box
          key={index}
          display="flex"
          alignItems="center"
          // justifyContent="space-between"
          padding="15px 10px"
          borderBottom={
           filteredRedeemedList?.length - 1 !== index && "1px solid #E2E8F0"
          }
          cursor="pointer"
         >
          <Box display="flex" flexDirection="column" alignItems="flex-start">
           <Text
            color={colors.darkBlue}
            fontWeight="500"
            lineHeight="20px"
            marginBottom="10px"
            fontSize="18px"
           >
            {ticket.user.name}
           </Text>
           <Box display="flex" alignItems="center" gap="5px" flexWrap="wrap">
            <Text
             color={colors.darkBlue}
             fontWeight="400"
             lineHeight="15px"
             fontSize="14px"
            >
             {ticket.user.email}
            </Text>{" "}
            |{" "}
            <Text
             color={colors.darkBlue}
             fontWeight="400"
             lineHeight="15px"
             fontSize="14px"
            >
             {ticket.count} Tickets bought
            </Text>{" "}
            |{" "}
            <Text
             color={colors.darkBlue}
             fontWeight="400"
             lineHeight="15px"
             fontSize="14px"
            >
             {ticket.redeemedCount} Tickets Redeemed
            </Text>{" "}
            |{" "}
            <Text
             color={colors.darkBlue}
             fontWeight="400"
             lineHeight="15px"
             display="flex"
             alignItems="center"
            >
             <TbCurrencyNaira />
             {ticket.total}
            </Text>
           </Box>
          </Box>
          {/* <Image src={ArrowRight} alt="arrow-left" /> */}
         </Box>
        ))}
       </Box>
      </Box>
     </>
    ) : (
     <EmptyState text={"Click the redeem button to redeem an experience"} />
    )}
   </>

   {/* REDEEM EXPERIENCE MODAL */}
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <ModalContainer
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
    >
     <Card
      style={{ height: "100%" }}
      animate={{ scale: 1 }}
      initial={{ scale: 0.9 }}
     >
      <div>
       {/* <CloseBtn
                style={{ cursor: "pointer" }}
                onClick={() => setVisible(false)}
              >
                <CloseIconWrapper name="close" animateOnTap pointer />
              </CloseBtn> */}
       {/* <Text
                textAlign={"center"}
                fontSize={20}
                fontWeight={600}
                pt="3"
                pb="5"
              >
                {" "}
                Redeem a ticket
              </Text> */}
       <Box w="100%">
        <QrScan
         delay={300}
         onError={handleError}
         onScan={handleScan}
         style={{ borderRadius: "28px", height: "100%", width: 320 }}
        />
       </Box>
      </div>
     </Card>
    </ModalContainer>
   </Modal>
  </Box>
 );
};

export default RedeemExperiences;
