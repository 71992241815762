import Description from "./Description";
import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Img,
  css,
  Grid,
  Box,
} from "@chakra-ui/react";
import { colors } from "../../../../assets/styles/config";
import {
  CityEnvelope,
  Heading,
  HideOnMd,
  HideOnSm,
  TabTitle,
  UsefulApp,
  UsefulAppLogo,
  UsefulAppsHeading,
} from "../styles";
import { useCityContext, getTabTitle } from "../../../../context";
import Weather from "./Weather";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { SliderArrowPath } from "../../../../assets/styles";

const HeroTabs = () => {
  const { usefulInfo, coverImage } = useCityContext();
  const usefulInfoTitles = Object.keys(usefulInfo) || {};
  const tabTitles = usefulInfoTitles.map((info) => getTabTitle(info));

  return (
    <>
      <CityEnvelope>
        {/* <HideOnMd>
          <Weather />
        </HideOnMd> */}

        <Description data={usefulInfo.description} />

        <Box height={{ md: "200px", lg: "auto" }}>
          {/* <HideOnSm>
            <Weather />
          </HideOnSm> */}
          <Box mt={"20px"} ml={{ lg: "40px" }}>
            <UsefulAppsHeading>Useful Apps</UsefulAppsHeading>
            <Flex gap={"1rem"} flexWrap="wrap">
              {/* <Splide
                aria-labelledby='Useful Apps'
                options={{
                  pagination: false,
                  rewind: true,
                  perPage: 4,
                  mediaQuery: 'min',
                  breakpoints: {
                    1080: {
                      destroy: true,
                    },
                  },
                  gap: '2.5rem',
                  classes: {
                    arrows:
                      'splide__arrows splide-navigation-arrows useful-apps',
                    arrow: 'splide__arrow splide-navigation-arrow ',
                    prev: 'splide__arrow--prev splide-nav-prev',
                    next: 'splide__arrow--next splide-nav-next',
                    list: 'splide__list city-hero-list',
                  },
                  arrowPath: SliderArrowPath,
                }}
              > */}
              {/* </Splide> */}
              {/* </SplideSlide> */}{" "}
              {usefulInfo?.usefulApps?.map((app, index) => (
                // <SplideSlide key={index}>
                <a href={app.url} target="_blank" rel="noopener noreferrer">
                  {" "}
                  <UsefulApp>
                    <UsefulAppLogo src={app.pic}></UsefulAppLogo>
                  </UsefulApp>{" "}
                </a>
              ))}
            </Flex>
          </Box>
        </Box>
      </CityEnvelope>
    </>
  );
};

export default HeroTabs;
