import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { colors, fontWeight } from "../../../../assets/styles/config";

export const Container = styled.section`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  minheight: 100vh;
  // height: 100%;
`;

export const SearchLabel = styled.label`
  position: relative;

  .icon {
    position: absolute;
    left: 15px;
  }

  @media screen and (min-width: 768px) {
    .icon {
      top: 0.2rem;
    }
  }
`;

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  padding: 24px;
  /* identical to box height, or 20px */

  /* Purple Highlights */

  color: #252046;
  border-bottom: 1px solid #e2e8f0;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 18px;
  }

  ${(props) => props.css || ""}
`;

export const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #e2e8f0;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  // font-size: 14px;

  color: #334155;
  ${(props) => props.css || ""}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const EditIcon = styled.button`
  padding: 0.15rem;
  background: #d0f1d7;
  border-radius: 6px;
  color: #1aab65;
  font-size: 18px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
    padding: 0.25rem;
  }

  @media screen and (min-width: 1080px) {
    font-size: 27px;
    padding: 0.35rem;
  }
`;

export const DeleteIcon = styled(motion.button)`
  font-size: 18px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 27px;
  }
`;

export const SwitchIcon = styled(motion.button)`
  padding: 0.5rem 0.35rem;
  //   font-size: 27px;
  background: #ebf7ff;
  border-radius: 6px;
  //   color: red;
`;

export const CategoriesTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchInput = styled.input`
  background: ${colors.skyBlue};
  border-radius: 10px;
  width: 100%;
  height: 38px;
  font-size: 14px;
  padding: 7px 12px 7px 40px;
  color: ${colors.brown};

  @media screen and (min-width: 768px) {
    // font-size: 15.5px;
    border-radius: 20px;
  }

  @media screen and (min-width: 835px) {
    width: 300px;
  }

  @media screen and (min-width: 1080px) {
    // font-size: 17px;
    height: 42px;
  }

  &:focus {
    outline: 1px solid ${colors.blue};
  }

  &::placeholder {
    color: ${colors.placeholderBrown};
  }

  &:-ms-input-placeholder {
    color: ${colors.placeholderBrown};
  }

  &::-ms-input-placeholder {
    color: ${colors.placeholderBrown};
  }
`;

export const Card = styled(motion.div)`
  background: white;
  border-radius: 24px;
  width: 90%;
  padding-inline: 36px;

  @media screen and (min-width: 470px) {
    width: 70%;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 1080px) {
    width: ${(props) => (props.state === "DELETE DOCUMENT?" ? "28%" : " 37%")};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Satoshi";
  font-weight: 700;
  font-size: 18px;
  color: #0f0931;
  padding-block: 36px 0;

  @media screen and (min-width: 768px) {
    font-size: 21px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 24px;
  }
  ${(props) => props.css || ""}
`;

export const CardInBetween = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding-block: 2rem;

  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* or 125% */

  text-align: center;
  letter-spacing: 0.1px;

  color: #878498;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
  ${(props) => props.css || ""}
`;

export const addInputFlexStyle = {
  flexDirection: "column",
  alignSelf: "start",
  gap: "8px",
  marginInline: "auto",
  paddingBlock: "2rem",
};

export const addInputTextStyle = {
  fontWeight: 700,
  fontFamily: "Satoshi",
  color: "#0F0931",
  marginBottom: "0px",
};

export const addInputStyle = {
  fontFamily: "Satoshi",
  placeholder: "Terms & Conditions",
  height: "3.7em",
  fontSize: "14px",
  autoFocus: true,
  background: "#F1F5F9",
  borderRadius: "66px",
  _placeholder: {
    fontFamily: "Satoshi",
    fontWeight: 500,
    fontSize: "14px",
    color: "#475569",
    opacity: "0.45",
  },
  _focus: {
    background: "#F3F0FF",
  },
  _focusVisible: { border: "1px solid #AC98FE" },
};

export const searchInputStyle = {
  borderRadius: "28px",
  height: "48px",
  type: "search",
  placeholder: "Search",
  paddingLeft: "52px",
  background: "#F8FAFC",
  name: "search categories",
  fontFamily: "Satoshi",
  placeholder: "Terms & Conditions",
  height: "3.7em",
  fontSize: "14px",
  autoFocus: true,
  _placeholder: {
    fontFamily: "Satoshi",
    fontWeight: 500,
    fontSize: "14px",
    color: "#475569",
    opacity: "0.45",
  },
  _focus: {
    background: "#F3F0FF",
  },
  _focusVisible: { border: "1px solid #AC98FE" },
};

export const docNameStyles = {
  background: "#F8FBFF",
  width: "80%",
  height: "129px",
  border: "1px dashed #D5DCE3",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const docFormLabelStyles = {
  htmlFor: "document",
  height: "231px",
  color: "#979797",
  textAlign: "center",
  cursor: "pointer",
  background: "#F1F5F9",
  borderRadius: "9px",
  // height:"3.7em"
  fontSize: "14px",
  alignItems: "center",
  justifyContent: "space-between",
  paddingInline: "20px",
  flexDirection: "column",
  padding: "16px",
  display: "flex",
  _hover: { scale: 0.97 },
  marginTop: "23px",
  marginInlineEnd: "0px",
  marginBottom: "0px",
};

export const docFormTextStyles = {
  fontFamily: "Creato Display",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: " 14px",
  lineHeight: "135%",
  color: "#0F0931",
};

export const docFormTextStyles2 = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "400",
};
