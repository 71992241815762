import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import {
  TestimonialCard,
  TestimonialHeading,
  TestimonialImg,
  TestimonialInfo,
  TestimonialSlides,
  TestimonialWrapper,
} from "./styles";
import { Avatar } from "@chakra-ui/react";

const testimonialList = [
  {
    id: 1,
    testimony:
      "For the longest time, it’s been hard to find consistent data about experiences in Lagos. Navi has literally changed that. With Navi I don’t even bother looking for experiences again.",
    author: "Demilade Phillips",
    img: "",
  },
  {
    id: 2,
    testimony:
      "I’ve been following Navi since its early days and honestly I’m so in love with everything Navi stands for and all that Navi is doing. Navi is my go to guide whenever I’m trying to figure out what to do and where to go in Lagos and I absolutely love their recommendations!",
    author: "Ekenam Ofili",
    img: "",
  },
  {
    id: 3,
    testimony:
      "The Navigator community is also really cool! I had an opportunity to meet so many Navigators and the Navi team at the game night event at Johnny Rockets and it was such a blast! Definitely one for the books!",
    author: "Deinma Sekibo",
    img: "",
  },
  {
    id: 4,
    testimony:
      "From suggesting date night spots to places where you can play recreational sports and so much more! You name it and Navi got you covered 😊",
    author: "Kachi Irondi",
    img: "",
  },
  {
    id: 5,
    testimony:
      "Finding unique and authentic experiences has never been easier! NAVI has completely transformed the way I travel. NAVI's easy interface makes it a breeze to find the perfect experience. Highly recommended!",
    author: "Sarah Lobuke",
    img: "",
  },
  {
    id: 6,
    testimony:
      "As a solo traveler, safety and convenience are always top priorities for me. NAVI has exceeded my expectations in both areas.",
    author: "Mark S",
    img: "",
  },
  {
    id: 7,
    testimony:
      "If you want to truly immerse yourself in a destination, this app is a game-changer! It connects you with knowledgeable and enthusiastic locals who offer unique experiences you won't find anywhere else",
    author: "Jumoke",
    img: "",
  },
  {
    id: 8,
    testimony:
      "NAVI has become my go-to resource for discovering local experiences that go beyond the typical tourist attractions. It's curated selection and personalized recommendations have introduced me to amazing hosts & businesses who are passionate about sharing their expertise.",
    author: "Alex M",
    img: "",
  },
];

const Testimonials = () => {
  return (
    <TestimonialWrapper>
      <TestimonialHeading>What people are saying</TestimonialHeading>
      <TestimonialSlides>
        <Splide
          extensions={{
            AutoScroll,
          }}
          options={{
            type: "loop",
            drag: "free",
            perPage: 4,
            gap: "27px",
            arrows: false,
            pauseOnHover: true,
            pagination: false,
            autoScroll: {
              speed: 1,
            },
            breakpoints: {
              1080: {
                perPage: 3,
              },
              868: {
                perPage: 2,
              },
              600: {
                perPage: 1,
              },
            },
          }}
        >
          {testimonialList.map((testimony) => (
            <SplideSlide>
              <TestimonialCard>
                <TestimonialImg>
                  <Avatar />
                  {/* <img
                    src={
                      testimony.img ||
                      "https://static.generated.photos/vue-static/face-generator/landing/wall/20.jpg"
                    }
                    alt=""
                  /> */}
                </TestimonialImg>
                <TestimonialInfo>
                  <h5>{testimony.author}</h5>
                  <p>{testimony.testimony}</p>
                </TestimonialInfo>
              </TestimonialCard>
            </SplideSlide>
          ))}
        </Splide>
        {/* <Splide
          extensions={{
            AutoScroll,
          }}
          options={{
            type: "loop",
            drag: "free",
            perPage: 4,
            gap: "27px",
            arrows: false,
            pauseOnHover: true,
            direction: "rtl",
            pagination: false,
            autoScroll: {
              speed: 1,
            },
          }}
        >
          {testimonialList.map((testimony) => (
            <SplideSlide>
              <TestimonialCard>
                <TestimonialInfo>
                  <h5>{testimony.author}</h5>
                  <p>{testimony.testimony}</p>
                </TestimonialInfo>
                <TestimonialImg>
                  <img
                    src={
                      testimony.img ||
                      "https://static.generated.photos/vue-static/face-generator/landing/wall/20.jpg"
                    }
                    alt=""
                  />
                </TestimonialImg>
              </TestimonialCard>
            </SplideSlide>
          ))}
        </Splide> */}
      </TestimonialSlides>
    </TestimonialWrapper>
  );
};

export default Testimonials;
