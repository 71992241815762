import { createContext, useContext, useReducer } from 'react';
export const actionTypes = { UPDATE_CART: 'UPDATE_CART' };

const reducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.UPDATE_CART:
      return { ...state, ...payload };

    default:
      return state;
  }
};

const CartContext = createContext();
CartContext.displayName = 'CartContext';
const initialState = {
  cart: [],
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CartContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  return useContext(CartContext);
};

export { CartContext, CartProvider };
