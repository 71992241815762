import React, { useState } from "react";
import { ContinueBtn } from "./styles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import {
 Box,
 Text,
 SimpleGrid,
 Input,
 Button,
 FormControl,
 FormLabel,
 FormErrorMessage,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Card, JustifyBetween } from "../styles";
import { css } from "styled-components";

const GuestModal = ({ setUser }) => {
 const validationSchema = Yup.object({
  firstName: Yup.string()
   .min(2, "Your first name is too short.")
   .max(50, "This should be your first name only.")
   .required("Required."),
  lastName: Yup.string()
   .min(2, "Last name should be more than 1 character")
   .max(50, "This should be your last name only")
   .required("Required."),
  phoneNumber: Yup.string()
   .required("Required.")
   .min(9, "At least 10 digits required")
   .max(11, "11 digits exceeded"),
  email: Yup.string().email("Invalid email address.").required("Required."),
 });
 const [checked, setChecked] = useState(false);

 return (
  <Card
   css={css`
    width: 50%;
    padding-bottom: 3rem;
    height: ${!checked ? "250px" : ""};

    @media screen and (max-width: 1080px) {
     width: 80%;
    }

    @media screen and (max-width: 768px) {
     width: 100%;
    }
   `}
  >
   <JustifyBetween
    css={css`
     border-bottom: none;
     padding: 0.5rem 0;
    `}
   >
    <Text fontWeight={500} letterSpacing={"0.03em"}>
     Already have an account?
    </Text>
    <Button
     padding={"1rem 3rem"}
     backgroundColor={"#632EFF"}
     borderRadius="54px"
     color={"white"}
    >
     <Link to="/signin">Log In</Link>
    </Button>
   </JustifyBetween>{" "}
   <JustifyBetween
    css={css`
     border-bottom: none;
     padding: 0.5rem 0;
    `}
   >
    <Text fontWeight={500} letterSpacing={"0.03em"}>
     Don't have an account?
    </Text>
    <Button
     padding={"1rem 2.5rem"}
     border={"1px solid #632EFF"}
     backgroundColor={"white"}
     borderRadius="28px"
     color={"#632EFF"}
    >
     <Link to="/signup">Sign Up</Link>
    </Button>
   </JustifyBetween>{" "}
   <Text color="#6C6B6B" fontSize={{ base: 14, md: 15, lg: 16 }}>
    or
   </Text>
   <Text
    color="#6C6B6B"
    fontSize={{ base: 14, md: 15, lg: 16 }}
    display="flex"
    alignItems="center"
    gap="10px"
    mt={5}
   >
    <input
     class="guest-marker"
     type={"checkbox"}
     onChange={(e) => setChecked(e.target.checked)}
     style={{ cursor: "pointer" }}
    ></input>
    Check out as a Guest
   </Text>
   {checked ? (
    <div>
     {/* <Text color="#6C6B6B" fontSize={{ base: 14, md: 15, lg: 16 }} mt="50px">
          01
        </Text> */}
     <Text
      fontSize={{ base: 18, md: 22, lg: 24 }}
      fontWeight={500}
      mt="50px"
      pt="3"
      pb="2"
     >
      {" "}
      Enter your details to checkout{" "}
     </Text>
     <Box mt="20px" w="100%">
      <Formik
       initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        agreedTerms: false,
       }}
       onSubmit={(values) => {
        setUser({
         ...values,
         name: values.firstName + " " + values.lastName,
         role: "GUEST",
        });
       }}
       validationSchema={validationSchema}
      >
       {({ handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
         <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          w="100%"
          alignItems="start"
          gap="20px"
          mb="20px"
         >
          <FormControl
           isInvalid={!!errors.firstName && touched.firstName}
           isRequired
          >
           <FormLabel
            color="#1D1D1D"
            fontSize={{ base: "14px", md: "15px", lg: "16px" }}
            lineHeight="140%"
            fontWeight="700"
            mb="10px"
            fontFamily="Satoshi"
           >
            First name
           </FormLabel>
           <Field
            height="54px"
            as={Input}
            borderRadius="66px"
            border="0.705598px solid #DFDDDD"
            p="10px"
            type="text"
            bg="#F1F5F9"
            id="firstName"
            name="firstName"
           />
           <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl
           isInvalid={!!errors.lastName && touched.lastName}
           isRequired
          >
           <FormLabel
            color="#1D1D1D"
            fontSize={{ base: "14px", md: "15px", lg: "16px" }}
            lineHeight="140%"
            fontFamily="Satoshi"
            fontWeight="700"
            mb="10px"
           >
            Last Name
           </FormLabel>
           <Field
            height="54px"
            as={Input}
            borderRadius="66px"
            border="0.705598px solid #DFDDDD"
            p="10px"
            bg="#F1F5F9"
            type="text"
            id="lastName"
            name="lastName"
           />
          </FormControl>
          <FormErrorMessage>{errors.lastName}</FormErrorMessage>
         </SimpleGrid>

         <FormControl
          isInvalid={!!errors.email && touched.email}
          isRequired
          mb="20px"
         >
          <FormLabel
           color="#1D1D1D"
           fontSize={{ base: "14px", md: "15px", lg: "16px" }}
           lineHeight="140%"
           fontFamily="Satoshi"
           fontWeight="700"
           mb="10px"
          >
           Email
          </FormLabel>
          <Field
           height="54px"
           as={Input}
           borderRadius="66px"
           border="0.705598px solid #DFDDDD"
           p="10px"
           type="email"
           bg="#F1F5F9"
           id="email"
           name="email"
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
         </FormControl>

         <FormControl
          isInvalid={!!errors.phoneNumber && touched.phoneNumber}
          isRequired
         >
          <FormLabel
           color="#1D1D1D"
           fontSize={{ base: "14px", md: "15px", lg: "16px" }}
           lineHeight="140%"
           fontFamily="Satoshi"
           fontWeight="700"
           mb="10px"
          >
           Phone Number
          </FormLabel>
          <Field
           height="54px"
           as={Input}
           borderRadius="66px"
           border="0.705598px solid #DFDDDD"
           p="10px"
           type="number"
           bg="#F1F5F9"
           id="phoneNumber"
           name="phoneNumber"
          />
          <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
         </FormControl>
         <ContinueBtn
          whileHover={{
           scale: 1.05,
           opacity: 0.65,
          }}
          whileTap={{ scale: 0.98 }}
          type="submit"
         >
          Continue
         </ContinueBtn>

         <Text
          textAlign="center"
          fontSize={{ base: "10px", md: "11px", lg: "12px" }}
          lineHeight="140%"
          fontFamily="Satoshi"
          fontWeight="700"
          mt="20px"
         ></Text>
        </form>
       )}
      </Formik>
     </Box>
    </div>
   ) : null}{" "}
  </Card>
 );
};

GuestModal.defaultProps = {
 setUser: () => null,
};

GuestModal.propTypes = {
 setUser: PropTypes.func,
};

export default GuestModal;
