import React, {
 useState,
 useEffect,
 useContext,
 useRef,
 useCallback,
} from "react";
import {
 Box,
 Img,
 Text,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Checkbox,
 Select as ChakraSelect,
 Image,
 Flex,
 Textarea,
} from "@chakra-ui/react";
import { inputStyle, selectStyle } from "../styles";
import { css } from "styled-components";
import { Button } from "../../../../components";
import SearchIcon from "../../../../assets/images/Search.svg";
import DelectIcon from "../../../../assets/images/Delete.svg";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import Cloud from "../../../../assets/images/cloud-plus.svg";
import AddIcon from "../../../../assets/images/Add.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import DropZone, { useDropzone } from "react-dropzone";
import { bytesToSize, flatten } from "../../../../utils/index";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import {
 getDatabase,
 ref as dbRef,
 set,
 onValue,
 update,
 remove,
 get,
 child,
} from "firebase/database";
import { storage, db } from "../../../../firebase";
import styles from "./index.module.css";
import Select from "react-select";
import { getCurrentUser } from "../../../../api/user";
//import { TimePicker, DatePicker } from "formik-material-ui-pickers";
import toast from "react-hot-toast";
import { FaTrash } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { sendPushNotification } from "../../../../api/user";

const validationSchema = Yup.object({});

const AddCollection = () => {
 const { user } = useContext(AuthContext);
 // console.log("user", user);
 const [media, setMedia] = useState(null);
 const [mediaPartner, setMediaPartner] = useState([]);
 const [editableMedia, setEditableMedia] = useState("");
 const [editableMediaPartner, setEditableMediaPartner] = useState([]);
 const [isEdit, setIsEdit] = useState(false);
 const params = useParams();
 const navigate = useNavigate();
 const id = params?.id;
 const cityParam = params?.city;
 const [city, setCity] = useState([]);
 const [allCollection, setAllCollection] = useState([]);
 const [collection, setCollection] = useState([]);
 const categoryDb = dbRef(db, "cityExperience/categories");
 const cityExperienceDb = dbRef(db, "cityExperience/city");
 const partnerDb = dbRef(db, "users/partner");
 const [category, setCategory] = useState([]);
 const [partner, setPartner] = useState([]);
 const [partnerOption, setPartnerOption] = useState([]);
 const [partnerSearch, setPartnerSearch] = useState(true);

 useEffect(() => {
  if (location.pathname.includes("edit")) {
   setIsEdit(true);
  }
 }, []);

 useEffect(() => {
  const unsubscribe = onValue(categoryDb, (snapshot) => {
   const data = snapshot.val();
   data.shift();
   setCategory(
    data.map((item) => ({
     value: item,
     label: item,
    }))
   );
  });

  return () => unsubscribe();
 }, []);

 useEffect(() => {
  const unsubscribe = onValue(partnerDb, (snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();
    var array = Object.keys(myObj).map((key) => {
     return myObj[key];
    });

    setPartner(array?.filter((item) => item?.enabled));
    setPartnerOption(
     array
      ?.filter((item) => item?.enabled)
      ?.map((item) => ({
       value: item?.uid,
       label: item?.businessName,
      }))
    );
   }
  });

  return () => unsubscribe();
 }, []);

 //  console.log("category===", category);

 // const tagsOptions = [
 //   { value: "Nightlife", label: "Nightlife" },
 //   { value: "Dining", label: "Dining" },
 //   { value: "Art", label: "Art" },
 //   { value: "Entertainment", label: "Entertainment" },
 //   { value: "Outdoor", label: "Outdoor" },
 //   { value: "Food", label: "Food" },
 // ];

 useEffect(() => {
  const unsubscribe = onValue(cityExperienceDb, (snapshot) => {
   const data = snapshot.val();
   // console.log("city", Object.keys(data));
   setCity(Object.keys(data));
   if (isEdit) {
    setCollection(data?.[cityParam]?.collections[id]);
   }
  });

  return () => unsubscribe();
 }, [isEdit]);

 useEffect(() => {
  const unsubscribe = onValue(
   dbRef(db, `cityExperience/city/lagos/collections`),
   (snapshot) => {
    const data = snapshot.val();
    setAllCollection(data);
   }
  );
  return () => unsubscribe();
 }, []);

 const {
  getRootProps: getRootCollectionProps,
  getInputProps: getInputCollectionProps,
  isDragActive: isDragCollectionActive,
  open: openCollection,
  fileRejections: fileCollectionRejections,
 } = useDropzone({
  onDrop: (acceptedFiles) => {
   setMedia(
    acceptedFiles.map((file) =>
     Object.assign(file, {
      preview: URL.createObjectURL(file),
     })
    )
   );
  },
  accept: "image/jpeg, image/png, video/gif, video/mp4",
  // Disable click and keydown behavior
  noClick: true,
  noKeyboard: true,
  maxSize: 10000000,
  minSize: 0,
 });

 const handleMediaUpload = async (file) => {
  //  toast.loading("loading");
  const storageRef = ref(storage, `/files/${file.name}`);
  await uploadBytesResumable(storageRef, file);
  return await getDownloadURL(storageRef);
 };

 const [savedIndex, setSavedIndex] = useState("");

 const onDrop = (acceptedFiles, index) => {
  if (isEdit) {
   setSavedIndex((id) => [...id, index]);
  }

  setMediaPartner({
   ...mediaPartner,
   [index]: acceptedFiles?.map((file) =>
    Object.assign(file, {
     preview: URL.createObjectURL(file),
    })
   )[0],
  });
  // console.log("res", res, selectedMedia);
 };

 const handleSelect = (e, setFieldValue) => {
  setFieldValue("city", e.target.value);
  const city = e.target.value;
  onValue(dbRef(db, `cityExperience/city/${city}/collections`), (snapshot) => {
   const data = snapshot.val();
   setAllCollection(data);
   // console.log("city", Object.keys(data));
  });
 };

 const handleDeleteCollection = async () => {
  toast.loading("loading");
  const db = getDatabase();
  const updates = {};
  allCollection.splice(id, 1);
  updates[`cityExperience/city/${cityParam}/collections`] = allCollection;
  await update(dbRef(db), updates)
   .then(async () => {
    toast.dismiss();
    navigate("/admin/collection");
    toast.success("Successfully deleted collection");

    if (user) {
     await sendPushNotification({
      title: "admin",
      body: `${user?.name} deleted a collection`,
      data: user,
     });
    }
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 return (
  <Box height="100%" padding="14px 20px" background="#F5F8FA">
   <Box
    // position="absolute"
    // left="30px"
    // top="5%"
    display="flex"
    alignItems="center"
    cursor="pointer"
    onClick={() => navigate(-1)}
   >
    <Image src={ArrowLeft} />
    <Text color="#252046" marginLeft="10px">
     Go Back
    </Text>
   </Box>
   {/* <List>
        <ListItem color="#6C6B6B" listStyleType="disc" marginLeft="20px">
          {isEdit ? "Edit Collection" : "Add New Collection"}
        </ListItem>
      </List> */}

   <Formik
    initialValues={{
     city: "lagos",
     title: "",
     tags: [],
     partner: [
      {
       partner_name: "",
       //  partner_email: "",
       partner_phone: "",
       partner_location: "",
       partner_url: "",
       location_url: "",
       collection_desc: "",
       social_media_url: "",
       social_media_type: "",
       available: true,
      },
     ],
    }}
    onSubmit={async (values) => {
     const length = allCollection?.length
      ? parseFloat(allCollection?.length)
      : 0;

     if (!isEdit) {
      if (!media) {
       toast.error("Please upload cover image");
      } else if (values.tags?.length === 0) {
       toast.error("Select a tag");
      } else {
       toast.loading("loading");
       let partnerImages = [];
       const coverImage = await handleMediaUpload(media[0]);
       var keys = Object.keys(mediaPartner);
       for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        const img = await handleMediaUpload(mediaPartner[key]);
        partnerImages.push(img);
       }
       const payload = {
        city: values.city,
        title: values.title,
        featured: true,
        tag: ["All"].concat(values.tags?.map((item) => item.value)),
        partners:
         partnerImages &&
         values.partner.map((partner, index) => ({
          location: partner.partner_location,
          naviPartner: partner.available ? true : false,
          partner: partner.partner_name?.label
           ? partner.partner_name?.label
           : partner.partner_name,
          phoneNumber: partner.partner_phone,
          pic: partnerImages[index] ? partnerImages[index] : "",
          url: partner.partner_url,
          location_url: partner.location_url || "",
          collection_desc: partner.collection_desc || "",
          social_media_url: partner.social_media_url || "",
          social_media_type: partner.social_media_type || "",
         })),
        img: coverImage,
       };

       set(
        dbRef(db, `cityExperience/city/${values.city}/collections/` + length),
        {
         ...payload,
        }
       )
        .then(async (res) => {
         toast.dismiss();
         navigate("/admin/collection");
         toast.success("Successfully saved collection");
         if (user) {
          await sendPushNotification({
           title: "admin",
           body: `${user?.name} added a collection`,
           data: {
            // ...user,
            route: "/admin/collection",
            name: user?.name,
            photoURL: user?.photoURL,
            push_time: Date.now(),
           },
          });
         }

         //
        })
        .catch((err) => {
         toast.dismiss();
         toast.error(err);
        })
        .finally(() => {
         setTimeout(() => {
          toast.dismiss();
         }, 3000);
        });

       // console.log("coverImage", payload);
      }
     } else {
      toast.loading("loading");

      if (media || Object.keys(mediaPartner).length > 0) {
       const coverImage = media
        ? await handleMediaUpload(media[0])
        : editableMedia;

       let partnerImages = [];
       var keys = Object.keys(mediaPartner);
       for (var i = 0; i < keys.length; i++) {
        var key = keys[i];

        const img = await handleMediaUpload(mediaPartner[key]);
        partnerImages.push(img);
       }
       // console.log("partnerImages", partnerImages);

       for (var i = 0; i < savedIndex.length; i++) {
        editableMediaPartner.splice(savedIndex[i], 1, partnerImages[i]);
       }

       const payload = {
        city: values.city,
        title: values.title,
        featured: true,
        tag: ["All"].concat(values.tags?.map((item) => item.value)),
        partners: values.partner.map((partner, index) => ({
         location: partner.partner_location,
         naviPartner: partner.available ? true : false,
         partner: partner.partner_name?.label
          ? partner.partner_name?.label
          : partner.partner_name,
         phoneNumber: partner.partner_phone,
         pic: editableMediaPartner[index] ? editableMediaPartner[index] : "",
         url: partner.partner_url,
         location_url: partner.location_url || "",
         collection_desc: partner.collection_desc || "",
         social_media_url: partner.social_media_url || "",
         social_media_type: partner.social_media_type || "",
        })),
        img: coverImage,
       };

       const db = getDatabase();
       const updates = {};

       updates[`cityExperience/city/${cityParam}/collections/` + id] = payload;
       return await update(dbRef(db), updates)
        .then(async () => {
         toast.dismiss();
         toast.success("Successfully updated collection");

         if (user) {
          await sendPushNotification({
           title: "admin",
           body: `${user?.name} updated a collection`,
           data: {
            // ...user,
            route: "/admin/collection",
            name: user?.name,
            photoURL: user?.photoURL,
            push_time: Date.now(),
           },
          });
         }

         return true;
        })
        .catch((error) => {
         toast.dismiss();
         toast.error(err);
        })
        .finally(() => {
         setTimeout(() => {
          toast.dismiss();
         }, 3000);
        });
      } else {
       const payload = {
        city: values.city,
        title: values.title,
        featured: true,
        tag: ["All"].concat(values.tags?.map((item) => item.value)),
        partners: values.partner.map((partner, index) => ({
         location: partner.partner_location,
         naviPartner: partner.available ? true : false,
         partner: partner.partner_name?.label
          ? partner.partner_name?.label
          : partner.partner_name,
         phoneNumber: partner.partner_phone,
         pic: editableMediaPartner[index],
         url: partner.partner_url,
         location_url: partner.location_url || "",
         collection_desc: partner.collection_desc || "",
         social_media_url: partner.social_media_url || "",
         social_media_type: partner.social_media_type || "",
        })),
        img: editableMedia,
       };

       const db = getDatabase();
       const updates = {};

       updates[`cityExperience/city/${cityParam}/collections/` + id] = payload;
       return await update(dbRef(db), updates)
        .then(async () => {
         toast.dismiss();
         toast.success("Successfully updated collection");

         if (user) {
          await sendPushNotification({
           title: "admin",
           body: `${user?.name} updated a collection`,
           data: {
            // ...user,
            route: "/admin/collection",
            name: user?.name,
            photoURL: user?.photoURL,
            push_time: Date.now(),
           },
          });
         }
         return true;
        })
        .catch((error) => {
         toast.dismiss();
         toast.error(err);
        })
        .finally(() => {
         setTimeout(() => {
          toast.dismiss();
         }, 3000);
        });
      }
     }
    }}
    //  validationSchema={validationSchema}
   >
    {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => {
     const handleSelectPartner = (setFieldValue, index, option) => {
      setFieldValue(`partner.${index}.partner_name`, option);
      const filterPartner = partner?.filter(
       (item) => item?.uid === option?.value
      )[0];

      setFieldValue(
       `partner[${index}]['partner_phone']`,
       filterPartner?.phoneNumber
      );
      setFieldValue(
       `partner[${index}]['partner_location']`,
       filterPartner?.businessAddress
      );
      setFieldValue(
       `partner[${index}]['partner_url']`,
       filterPartner?.link && JSON.parse(filterPartner?.link)?.website
      );
     };

     useEffect(() => {
      if (isEdit && collection) {
       //  console.log("title", collection?.title);
       setFieldValue("title", collection?.title, false);
       collection?.tag?.map((item, index) => {
        setFieldValue(
         "tags",
         category?.filter((itm) => collection?.tag?.includes(itm.value)),
         false
        );
       });
       if (collection?.city) {
        setFieldValue("city", collection?.city, false);
        onValue(
         dbRef(db, `cityExperience/city/${collection?.city}/collections`),
         (snapshot) => {
          const data = snapshot.val();
          setAllCollection(data);
         }
        );
       }

       setEditableMedia(collection?.img);

       let arrayPic = [];

       collection?.partners?.map((item) => {
        if (item.pic !== "") {
         arrayPic.push(item.pic);
         setEditableMediaPartner(arrayPic);
        }
       });

       collection?.partners?.map((item, index) => {
        setFieldValue(
         `partner[${index}]['partner_name']`,
         collection?.partners[index]?.partner
        );
        setFieldValue(
         `partner[${index}]['partner_phone']`,
         collection?.partners[index]?.phoneNumber
        );
        setFieldValue(
         `partner[${index}]['partner_location']`,
         collection?.partners[index]?.location
        );
        setFieldValue(
         `partner[${index}]['partner_url']`,
         collection?.partners[index]?.url
        );
        setFieldValue(
         `partner[${index}]['location_url']`,
         collection?.partners[index]?.location_url
        );
        setFieldValue(
         `partner[${index}]['collection_desc']`,
         collection?.partners[index]?.collection_desc
        );
        setFieldValue(
         `partner[${index}]['social_media_url']`,
         collection?.partners[index]?.social_media_url
        );
        setFieldValue(
         `partner[${index}]['social_media_type']`,
         collection?.partners[index]?.social_media_type
        );
       });
       setPartnerSearch(false);
      }
     }, [collection, isEdit, category]);

     return (
      <form onSubmit={handleSubmit}>
       <Box
        background="#FFFFFF"
        borderRadius="6px"
        padding={{ base: "20px", md: "30px" }}
        width="100%"
        mt="16px"
        marginX="auto"
       >
        <Box mt="20px">
         <Box
          mb="30px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
         >
          <FormControl display="flex" alignItems="center">
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "15px", md: "14px" }}
            lineHeight="21px"
            fontWeight={{ base: 500, md: "700" }}
            margin={"0 12px 0 0 "}
           >
            Location:
           </FormLabel>
           <ChakraSelect
            width={{ base: "105px", md: "20%" }}
            onChange={(e) => handleSelect(e, setFieldValue)}
            {...selectStyle}
            id="city"
            name="city"
           >
            <option value="lagos">Lagos</option>
            {/* {city?.length &&
                          city?.map((item, idx) => (
                            <option
                              value={item}
                              key={idx}
                              selected={collection?.city === item}
                            >
                              {item}
                            </option>
                          ))} */}
           </ChakraSelect>
          </FormControl>
         </Box>
         <Box display="flex" alignItems="flex-start" mb="30px">
          <Box display="flex" alignItems="center" flexDir="column">
           <Box
            //  border={media ? "none" : "1px dashed #25AEFC"}
            width={{ md: "478px" }}
            borderRadius="9px"
            height="100%"
            display="flex"
            flexDirection="column"
            background="#F1F5F9"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            padding="20px"
            {...getRootCollectionProps()}
           >
            <Box
             marginBottom="20px"
             display="flex"
             alignItems="center"
             justifyContent="center"
             flexDirection="column"
            >
             <Text
              color="#0F0931"
              fontFamily="Creato Display, Roboto, sans-serif;"
              fontSize="16px"
              lineHeight="135%"
             >
              Upload Cover Image
             </Text>
             <Text
              width="60%"
              textAlign="center"
              color="#64748B"
              fontFamily="Satoshi"
              fontSize="12px"
              lineHeight="135%"
             >
              JPG, .PNG images are allowed Maximum size of 10mb
             </Text>
            </Box>
            <Box
             border={media ? "none" : "1px dashed #D5DCE3"}
             width="100%"
             height="153px"
             borderRadius="5px"
             background="#F8FBFF"
             onClick={openCollection}
            >
             <input {...getInputCollectionProps()} />
             {!isDragCollectionActive && (
              <Box display="flex" alignItems="center" justifyContent="center">
               {media ? (
                <Img
                 src={media && media[0]?.preview}
                 alt="banner-img"
                 objectFit="cover"
                 width="100%"
                 borderRadius="5px"
                 height="153px"
                 onClick={openCollection}
                 textAlign="center"
                />
               ) : (
                <>
                 {isEdit ? (
                  <Img
                   src={editableMedia}
                   alt="banner-img"
                   objectFit="cover"
                   width="100%"
                   borderRadius="5px"
                   height="153px"
                   onClick={openCollection}
                  />
                 ) : (
                  <Img src={Cloud} onClick={openCollection} marginTop="50px" />
                 )}
                </>
               )}
              </Box>
             )}
            </Box>
           </Box>
           {media && (
            <div className={styles.files}>
             {media?.map((file) => (
              // @ts-ignore
              <p key={file.path}>
               {file.name.substring(0, 6)}... ({bytesToSize(file.size)}),
              </p>
             ))}
            </div>
           )}

           {/* <Text
                        color="#979797"
                         fontSize={{ base: "15px", md: "14px" }}
                        cursor="pointer"
                        mt="10px"
                        onClick={openCollection}
                        textAlign="center"
                      >
                        Add Collection cover image
                      </Text> */}
          </Box>
         </Box>

         <FormControl
          w={{ md: "478px" }}
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          mb="40px"
          isRequired
          isInvalid={!!errors.title && touched.title}
         >
          <FormLabel
           fontFamily="Satoshi"
           color="#0F0931"
           fontSize={{ base: "15px", md: "14px" }}
           lineHeight="21px"
           fontWeight={{ base: 500, md: "700" }}
          >
           Collection Title
          </FormLabel>
          <Field
           as={Input}
           {...inputStyle}
           type="text"
           placeholder="Title"
           id="title"
           name="title"
          />
          <FormErrorMessage>{errors.title}</FormErrorMessage>
         </FormControl>

         <FormControl
          w={{ md: "478px" }}
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          mb="20px"
          isRequired
          isInvalid={!!errors.tags && touched.tags}
         >
          <FormLabel
           fontFamily="Satoshi"
           color="#0F0931"
           fontSize={{ base: "15px", md: "14px" }}
           lineHeight="21px"
           fontWeight={{ base: 500, md: "700" }}
          >
           Tags
          </FormLabel>
          <Select
           isMulti
           name="tags"
           id="tags"
           value={values.tags}
           onChange={(option) => setFieldValue("tags", option)}
           options={category}
           onBlur={handleBlur}
           styles={{
            control: (provided, state) => ({
             ...provided,
             borderRadius: "66px",
             background: "#F1F5F9",
             autoFocus: true,
             borderColor: "inherit",
             "&:hover": {
              background: "#F3F0FF",
              border: "1px solid #AC98FE",
             },
             "&:focus": {
              background: "#F3F0FF",
             },
             "&:focusVisible": {
              border: "1px solid #AC98FE",
             },
            }),
           }}
           // defaultValue={experiences[0]?.experienceArray}
          />
          <FormErrorMessage>{errors.tags}</FormErrorMessage>
         </FormControl>
         <Box marginTop="30px" w={{ md: "478px" }}>
          <Text
           color="#0F0931"
           fontFamily="Creato Display, Roboto, sans-serif;"
           fontSize={{ base: "15px", md: "14px" }}
           lineHeight="135%"
           fontWeight={{ base: 500, md: "700" }}
           marginBottom="20px"
          >
           Partners
          </Text>
          <FieldArray
           name="partner"
           render={({ insert, remove, push }) => (
            <>
             {values.partner.length > 0 &&
              values.partner.map((value, index) => (
               <Box marginBottom="20px">
                <DropZone
                 onDrop={(acceptedFiles) => onDrop(acceptedFiles, index)}
                 accept="image/jpeg, image/png, video/gif, video/mp4"
                 noClick={true}
                 noKeyboard={true}
                 maxSize={10000000}
                 minSize={0}
                >
                 {({
                  getRootProps: getRootPartnerProps,
                  getInputProps: getInputPartnerProps,
                  isDragActive: isDragPartnerActive,
                  open: openPartner,
                 }) => (
                  <Box display="flex" alignItems="flex-start" mb="20px">
                   <Box display="flex" alignItems="center" flexDir="column">
                    {/* <Box
                                          border={
                                            mediaPartner[index]
                                              ? "none"
                                              : "1px dashed #25AEFC"
                                          }
                                          width="270px"
                                          borderRadius="20px"
                                          height="145px"
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          cursor="pointer"
                                          {...getRootPartnerProps()}
                                        >
                                          <input {...getInputPartnerProps()} />
                                          {!isDragPartnerActive && (
                                            <>
                                              {mediaPartner[index] ? (
                                                <Img
                                                  src={
                                                    mediaPartner &&
                                                    mediaPartner[index]?.preview
                                                  }
                                                  alt="banner-img"
                                                  objectFit="cover"
                                                  width="270px"
                                                  borderRadius="20px"
                                                  height="145px"
                                                  onClick={openPartner}
                                                />
                                              ) : (
                                                <>
                                                  {isEdit ? (
                                                    <Img
                                                      src={
                                                        editableMediaPartner &&
                                                        editableMediaPartner[
                                                          index
                                                        ]
                                                      }
                                                      alt="banner-img"
                                                      objectFit="cover"
                                                      width="270px"
                                                      borderRadius="20px"
                                                      height="145px"
                                                      onClick={openPartner}
                                                    />
                                                  ) : (
                                                    <Text
                                                      color="#25AEFC"
                                                      fontSize={{
                                                        base: "60px",
                                                        md: "64px",
                                                      }}
                                                      onClick={openPartner}
                                                    >
                                                      +
                                                    </Text>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </Box> */}
                    <Box
                     //  border={media ? "none" : "1px dashed #25AEFC"}
                     w={{ md: "478px" }}
                     borderRadius="9px"
                     height="100%"
                     display="flex"
                     flexDirection="column"
                     background="#F1F5F9"
                     justifyContent="center"
                     alignItems="center"
                     cursor="pointer"
                     padding="20px"
                     {...getRootPartnerProps()}
                    >
                     <Box
                      marginBottom="20px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                     >
                      <Text
                       color="#0F0931"
                       fontFamily="Creato Display, Roboto, sans-serif;"
                       fontSize="16px"
                       lineHeight="135%"
                      >
                       Upload Cover Image
                      </Text>
                      <Text
                       width="60%"
                       textAlign="center"
                       color="#64748B"
                       fontFamily="Satoshi"
                       fontSize="12px"
                       lineHeight="135%"
                      >
                       JPG, .PNG images are allowed Maximum size of 10mb
                      </Text>
                     </Box>
                     <Box
                      border={
                       mediaPartner[index] ? "none" : "1px dashed #D5DCE3"
                      }
                      width="100%"
                      height="153px"
                      borderRadius="5px"
                      background="#F8FBFF"
                      onClick={openPartner}
                     >
                      <input {...getInputPartnerProps()} />
                      {!isDragCollectionActive && (
                       <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                       >
                        {mediaPartner[index] ? (
                         <Img
                          src={mediaPartner && mediaPartner[index]?.preview}
                          alt="banner-img"
                          objectFit="cover"
                          width="100%"
                          borderRadius="5px"
                          height="153px"
                          // onClick={openPartner}
                          textAlign="center"
                         />
                        ) : (
                         <>
                          {isEdit ? (
                           editableMediaPartner?.length > 0 ? (
                            <Img
                             src={editableMediaPartner[index]}
                             alt="banner-img"
                             objectFit="cover"
                             width="100%"
                             borderRadius="5px"
                             height="153px"
                             //  onClick={openPartner}
                            />
                           ) : (
                            <Img
                             src={Cloud}
                             marginTop="50px"
                             //  onClick={openPartner}
                            />
                           )
                          ) : (
                           <Img
                            src={Cloud}
                            // onClick={openPartner}
                            marginTop="50px"
                           />
                          )}
                         </>
                        )}
                       </Box>
                      )}
                     </Box>
                    </Box>
                    {mediaPartner[index] && (
                     <div className={styles.files}>
                      <p key={mediaPartner[index].path}>
                       {mediaPartner[index].name.substring(0, 6)}
                       ... ({bytesToSize(mediaPartner[index].size)}
                       ),
                      </p>
                     </div>
                    )}

                    {/* <Text
                                          color="#979797"
                                          fontSize={{
                                            base: "12px",
                                            md: "14px",
                                          }}
                                          cursor="pointer"
                                          mt="10px"
                                          // onClick={openPartner}
                                          textAlign="center"
                                        >
                                          Add Partner image
                                        </Text> */}
                   </Box>
                  </Box>
                 )}
                </DropZone>

                <FormControl
                 w="100%"
                 mb="10px"
                 isRequired
                 isInvalid={!!errors.partner_name && touched.partner_name}
                >
                 <FormLabel
                  fontFamily="Satoshi"
                  color="#0F0931"
                  fontSize={{ base: "15px", md: "14px" }}
                  lineHeight="21px"
                  fontWeight={{ base: 500, md: "700" }}
                 >
                  Partner
                 </FormLabel>
                 {!partnerSearch ? (
                  <Field
                   as={Input}
                   {...inputStyle}
                   type="text"
                   placeholder="Add Partner"
                   id="partner_name"
                   name={`partner.${index}.partner_name`}
                  />
                 ) : (
                  <Select
                   name={`partner.${index}.partner_name`}
                   id={`partner.${index}.partner_name`}
                   value={getIn(values, `partner.${index}.partner_name`)}
                   onChange={(option) =>
                    handleSelectPartner(setFieldValue, index, option)
                   }
                   options={partnerOption}
                   onBlur={handleBlur}
                   styles={{
                    control: (provided, state) => ({
                     ...provided,
                     borderRadius: "66px",
                     background: "#F1F5F9",
                     autoFocus: true,
                     borderColor: "inherit",
                     "&:hover": {
                      background: "#F3F0FF",
                      border: "1px solid #AC98FE",
                     },
                     "&:focus": {
                      background: "#F3F0FF",
                     },
                     "&:focusVisible": {
                      border: "1px solid #AC98FE",
                     },
                    }),
                   }}
                   // defaultValue={experiences[0]?.experienceArray}
                  />
                 )}
                 {partnerSearch ? (
                  <Text
                   marginTop="10px"
                   cursor="pointer"
                   display="flex"
                   alignItems="flex-end"
                   justifyContent="flex-end"
                   width="100%"
                   fontFamily="Creato Display, Roboto, sans-serif;"
                   fontWeight={400}
                   fontSize="16px"
                   onClick={() => setPartnerSearch(false)}
                  >
                   + Add Business
                  </Text>
                 ) : (
                  isEdit && (
                   <Box
                    marginTop="10px"
                    cursor="pointer"
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    width="100%"
                   >
                    <Image src={SearchIcon} alt="search" />
                    <Text
                     fontFamily="Creato Display, Roboto, sans-serif;"
                     fontWeight={400}
                     fontSize="16px"
                     onClick={() => setPartnerSearch(true)}
                    >
                     Search Partner
                    </Text>
                   </Box>
                  )
                 )}
                 <FormErrorMessage>{errors.partner_name}</FormErrorMessage>
                </FormControl>
                {/*<FormControl
                                  display="grid"
                                  gridTemplateColumns="200px 1fr"
                                  alignItems="center"
                                  w="100%"
                                  mb="20px"
                                  isRequired
                                  isInvalid={
                                    !!errors.partner_email &&
                                    touched.partner_email
                                  }
                                >
                                  <FormLabel
                                    color="#979797"
                                    fontSize={{
                                      base: "12px",
                                      md: "18px",
                                      lg: "20px",
                                    }}
                                    lineHeight="28px"
                                    fontWeight="400"
                                  >
                                    Partner Email
                                  </FormLabel>
                                  <Field
                                    as={Input}
                                    borderRadius="24px"
                                    border="0.705598px solid #DFDDDD"
                                    p="10px"
                                    type="text"
                                    bg={colors.skyBlue}
                                    placeholder="Add Email"
                                    id="partner_email"
                                    name={`partner.${index}.partner_email`}
                                  />
                                  <FormErrorMessage>
                                    {errors.partner_email}
                                  </FormErrorMessage>
                                  </FormControl> */}
                <FormControl
                 w="100%"
                 mb="20px"
                 isRequired
                 isInvalid={!!errors.partner_phone && touched.partner_phone}
                >
                 <FormLabel
                  fontFamily="Satoshi"
                  color="#0F0931"
                  fontSize={{ base: "15px", md: "14px" }}
                  lineHeight="21px"
                  fontWeight={{ base: 500, md: "700" }}
                 >
                  Partner Phone
                 </FormLabel>
                 <Field
                  as={Input}
                  {...inputStyle}
                  type="number"
                  placeholder="Add contact number"
                  id="partner_phone"
                  name={`partner.${index}.partner_phone`}
                 />
                 <FormErrorMessage>{errors.partner_phone}</FormErrorMessage>
                </FormControl>
                <FormControl
                 w="100%"
                 mb="20px"
                 isRequired
                 isInvalid={
                  !!errors.partner_location && touched.partner_location
                 }
                >
                 <FormLabel
                  fontFamily="Satoshi"
                  color="#0F0931"
                  fontSize={{ base: "15px", md: "14px" }}
                  lineHeight="21px"
                  fontWeight={{ base: 500, md: "700" }}
                 >
                  Partner Location
                 </FormLabel>
                 <Field
                  as={Input}
                  {...inputStyle}
                  type="text"
                  placeholder="Add location"
                  id="partner_location"
                  name={`partner.${index}.partner_location`}
                 />
                 <FormErrorMessage>{errors.partner_location}</FormErrorMessage>
                </FormControl>
                <FormControl w="100%" mb="20px">
                 <FormLabel
                  fontFamily="Satoshi"
                  color="#0F0931"
                  fontSize={{ base: "15px", md: "14px" }}
                  lineHeight="21px"
                  fontWeight={{ base: 500, md: "700" }}
                 >
                  Google Location URL
                 </FormLabel>
                 <Field
                  as={Input}
                  {...inputStyle}
                  type="text"
                  placeholder="Add location Url"
                  id="location_url"
                  name={`partner.${index}.location_url`}
                 />
                 <FormErrorMessage>{errors.location_url}</FormErrorMessage>
                </FormControl>
                <FormControl w="100%" mb="20px">
                 <FormLabel
                  fontFamily="Satoshi"
                  color="#0F0931"
                  fontSize={{ base: "15px", md: "14px" }}
                  lineHeight="21px"
                  fontWeight={{ base: 500, md: "700" }}
                 >
                  Website URL
                 </FormLabel>
                 <Field
                  as={Input}
                  {...inputStyle}
                  type="text"
                  placeholder="Add website Url"
                  id="partner_url"
                  name={`partner.${index}.partner_url`}
                 />
                 <FormErrorMessage>{errors.partner_url}</FormErrorMessage>
                </FormControl>
                <FormControl w="100%" mb="20px" isRequired>
                 <FormLabel
                  fontFamily="Satoshi"
                  color="#0F0931"
                  fontSize={{ base: "15px", md: "14px" }}
                  lineHeight="21px"
                  fontWeight={{ base: 500, md: "700" }}
                 >
                  Collection Description
                 </FormLabel>
                 <Field
                  as={Textarea}
                  maxlength="250"
                  {...inputStyle}
                  type="text"
                  placeholder="Add collection description"
                  id="collection_desc"
                  name={`partner.${index}.collection_desc`}
                 />
                 <FormErrorMessage>{errors.collection_desc}</FormErrorMessage>
                </FormControl>
                <FormControl w="100%" mb="20px">
                 <FormLabel
                  fontFamily="Satoshi"
                  color="#0F0931"
                  fontSize={{ base: "15px", md: "14px" }}
                  lineHeight="21px"
                  fontWeight={{ base: 500, md: "700" }}
                  display={"flex"}
                  justifyContent={"space-between"}
                 >
                  Social Media URL
                 </FormLabel>
                 <Field
                  as={Input}
                  {...inputStyle}
                  type="text"
                  placeholder="Add Social Media Url"
                  id="social_media_url"
                  name={`partner.${index}.social_media_url`}
                 />
                 <FormErrorMessage>{errors.social_media_url}</FormErrorMessage>
                </FormControl>
                {/* <FormControl mb="6" fontWeight={400}>
                 <FormLabel
                  fontFamily="Satoshi"
                  color="#0F0931"
                  fontSize={{ base: "15px", md: "14px" }}
                  lineHeight="21px"
                  fontWeight={{ base: 500, md: "700" }}
                 >
                  Which social media URL did you include?
                 </FormLabel>
                 Facebook{" "}
                 <input
                  type="radio"
                  id="social_media_type"
                  name={`partner.${index}.social_media_type`}
                  value={"facebook"}
                 />{" "}
                 Twitter{" "}
                 <input
                  type="radio"
                  id="social_media_type"
                  name={`partner.${index}.social_media_type`}
                  value={"twitter"}
                 />{" "}
                 Instagram{" "}
                 <input
                  type="radio"
                  id="social_media_type"
                  name={`partner.${index}.social_media_type`}
                  value={"instagram"}
                 />{" "}
                 Tiktok{" "}
                 <input
                  type="radio"
                  id="social_media_type"
                  name={`partner.${index}.social_media_type`}
                  value="tiktok"
                 />
                </FormControl> */}
                <Box
                 display="flex"
                 alignItems="center"
                 justifyContent="space-between"
                 mb="20px"
                 width="100%"
                >
                 <Box>
                  <Checkbox
                   isChecked={getIn(values, `partner[${index}]['available']`)}
                   onChange={(e) =>
                    setFieldValue(
                     `partner[${index}]['available']`,
                     e.target.checked
                    )
                   }
                   colorScheme="purple"
                   color="#252046"
                   fontSize="18px"
                   fontFamily="Satoshi"
                   lineHeight="125%"
                   fontWeight={500}
                   id="available"
                   name={`partner.${index}.available`}
                  >
                   Available on NAVI
                  </Checkbox>
                 </Box>
                 {values.partner.length > 1 && (
                  <Box
                   display="flex"
                   // width="100%"
                   alignItems="center"
                   cursor="pointer"
                   onClick={() => remove(index)}
                   // justifyItems="center"
                  >
                   <Text marginRight="5px">Delete Partner</Text>
                   <Img
                    src={DelectIcon}
                    alt="delete"
                    fontSize="20px"
                    // marginLeft="20px"
                   />
                  </Box>
                 )}
                </Box>
                <Box
                 display="flex"
                 justifyContent="flex-end"
                 alignItems="center"
                >
                 <Text
                  color="#4A0DFF"
                  fontFamily="Creato Display, Roboto, sans-serif;"
                  // bg={colors.blue}
                  // borderRadius={28}
                  fontWeight={400}
                  fontSize="16px"
                  cursor="pointer"
                  //  p="20px 30px"
                  //type="submit"
                  onClick={() =>
                   push({
                    partner_name: "",
                    partner_email: "",
                    partner_phone: "",
                    partner_location: "",
                    partner_url: "",
                    location_url: "",
                    collection_desc: "",
                    social_media_url: "",
                    social_media_type: "",
                   })
                  }
                 >
                  + Add New Partner
                 </Text>
                </Box>
               </Box>
              ))}
            </>
           )}
          />
          <Box
           justifyContent="space-betweeen"
           alignItems="center"
           marginTop="20px"
           display={{ base: "none", md: "flex" }}
           gap="20px"
          >
           {" "}
           {isEdit && (
            // <Box onClick={handleDeleteCollection} cursor="pointer">
            //   <FaTrash color="red" size="30px" />
            //   {/*<Text>Delete Experience</Text> */}
            // </Box>
            <Button
             onClick={handleDeleteCollection}
             animateOnTap
             animateOnHover
             buttonColor="transparent"
             css={css`
              width: fit-content;
              padding: 0.5rem 2rem;
              height: auto;
              font-weight: 500;
              font-family: "Creato Display";
              display: flex;
              gap: 15px;
              border: 1px solid #252046;
              color: #252046;

              @media screen and (max-width: 768px) {
               border-radius: 10px;
              }
             `}
             type="button"
            >
             Delete collection
            </Button>
           )}
           <Button
            animateOnTap
            animateOnHover
            buttonColor="#632EFF"
            css={css`
             width: fit-content;
             padding: 0.5rem 2rem;
             height: auto;
             font-weight: 500;
             font-family: "Creato Display";
             display: flex;
             gap: 15px;

             @media screen and (max-width: 768px) {
              border-radius: 10px;
             }
            `}
            type="submit"
           >
            {isEdit ? "Update Collection" : "Add Collection"}
           </Button>
          </Box>
         </Box>
        </Box>
       </Box>
      </form>
     );
    }}
   </Formik>
  </Box>
 );
};

export default AddCollection;
