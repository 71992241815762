import React, { useContext, useState, useEffect } from "react";
import {
 Box,
 HStack,
 Text,
 SimpleGrid,
 Img,
 Input,
 Checkbox,
 Button,
 FormControl,
 FormLabel,
 FormErrorMessage,
 InputRightElement,
 InputGroup,
 RadioGroup,
 Radio,
 Select,
 Flex,
 Textarea,
} from "@chakra-ui/react";
import { colors } from "../../../assets/styles/config";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { inputStyle } from "../../Admin/Dashboard/styles";

const validationSchema = Yup.object({
 businessName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("Business name is required"),
 businessAddress: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("Business address is required"),
 city: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("city is required"),
 businessPhoneNumber: Yup.string()
  .matches(
   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
   "Invalid phone number"
  )
  .required("Business phone number is Required"),
 businessEmail: Yup.string()
  .email("Invalid email address")
  .required("Business email is required"),
});

const BusinessDetails = ({ onSwitchStage, data }) => {
 return (
  <Box width="100%" height="100%">
   <Box
    borderBottom="1px solid #E2E8F0"
    py="20px"
    marginTop="20px"
    display={{ base: "none", md: "block" }}
   >
    <Text
     fontFamily="Creato Display, Roboto, sans-serif;"
     color={colors.darkBlue}
     fontWeight="400"
     lineHeight="135%"
     fontSize="24px"
    >
     3. Business Details
    </Text>
   </Box>
   <Box
    py="20px"
    marginTop="20px"
    display={{ base: "flex", md: "none" }}
    flexDir="column"
    alignItems="flex-start"
   >
    <Text color="#878498" fontFamily="Creato Display, Roboto, sans-serif;">
     Step 3 of 5
    </Text>
    <Text
     fontFamily="Creato Display, Roboto, sans-serif;"
     color={colors.darkBlue}
     fontWeight="400"
     lineHeight="135%"
     fontSize="24px"
     marginTop="20px"
    >
     Business Details
    </Text>
    <Text
     fontFamily="Satoshi"
     color={colors.darkBlue}
     fontWeight="500"
     lineHeight="20px"
     fontSize="16px"
     marginTop="10px"
    >
     Please provide your Business details
    </Text>
   </Box>
   <Box mt="30px">
    <Formik
     initialValues={{
      businessName: "",
      businessEmail: "",
      businessPhoneNumber: "",
      businessAddress: "",
      city: "",
      bio: "",
      instagram: "",
      twitter: "",
      facebook: "",
      tiktok: "",
      linkedin: "",
      website: "",
     }}
     onSubmit={async (values) => {
      // console.log(values);
      onSwitchStage(values);
     }}
     validationSchema={validationSchema}
    >
     {({ handleSubmit, errors, touched, values, setFieldValue }) => (
      <form onSubmit={handleSubmit}>
       <Flex
        gap="30px"
        marginBottom="30px"
        flexDir={{ base: "column", md: "row" }}
       >
        <FormControl
         w="100%"
         alignItems="center"
         mb="10px"
         isRequired
         isInvalid={!!errors.businessName && touched.businessName}
        >
         <FormLabel
          color={colors.darkBlue}
          fontFamily="Satoshi"
          fontWeight="700"
          fontSize="14px"
          lineHeight="21px"
          mb="10px"
         >
          Business name
         </FormLabel>
         <Field
          as={Input}
          {...inputStyle}
          type="text"
          id="businessName"
          name="businessName"
          placeholder="What's the name of your business?"
         />
         <FormErrorMessage>{errors.businessName}</FormErrorMessage>
        </FormControl>
        <FormControl
         w="100%"
         alignItems="center"
         mb="10px"
         isRequired
         isInvalid={!!errors.businessEmail && touched.businessEmail}
        >
         <FormLabel
          color={colors.darkBlue}
          fontFamily="Satoshi"
          fontWeight="700"
          fontSize="14px"
          lineHeight="21px"
          mb="10px"
         >
          Official email address
         </FormLabel>
         <Field
          as={Input}
          {...inputStyle}
          type="email"
          id="businessEmail"
          name="businessEmail"
          placeholder="Enter the business' email address."
         />
         <FormErrorMessage>{errors.businessEmail}</FormErrorMessage>
        </FormControl>
       </Flex>
       <Flex
        gap="30px"
        marginBottom="30px"
        flexDir={{ base: "column", md: "row" }}
       >
        <FormControl
         w="100%"
         alignItems="center"
         mb="10px"
         isRequired
         isInvalid={!!errors.businessPhoneNumber && touched.businessPhoneNumber}
        >
         <FormLabel
          color={colors.darkBlue}
          fontFamily="Satoshi"
          fontWeight="700"
          fontSize="14px"
          lineHeight="21px"
          mb="10px"
         >
          Official phone number
         </FormLabel>
         <Field
          as={Input}
          {...inputStyle}
          type="number"
          id="businessPhoneNumber"
          name="businessPhoneNumber"
          placeholder="Enter the business' phone number."
         />
         <FormErrorMessage>{errors.businessPhoneNumber}</FormErrorMessage>
        </FormControl>
        <FormControl
         w="100%"
         alignItems="center"
         mb="10px"
         isRequired
         isInvalid={!!errors.businessAddress && touched.businessAddress}
        >
         <FormLabel
          color={colors.darkBlue}
          fontFamily="Satoshi"
          fontWeight="700"
          fontSize="14px"
          lineHeight="21px"
          mb="10px"
         >
          Business Address
         </FormLabel>
         <Field
          as={Input}
          {...inputStyle}
          type="text"
          id="businessAddress"
          name="businessAddress"
          placeholder="Where is your business located?"
         />
         <FormErrorMessage>{errors.businessAddress}</FormErrorMessage>
        </FormControl>
       </Flex>
       <Flex
        gap="30px"
        marginBottom="30px"
        flexDir={{ base: "column", md: "row" }}
       >
        <FormControl
         w="100%"
         alignItems="center"
         mb="10px"
         isRequired
         isInvalid={!!errors.bio && touched.bio}
        >
         <FormLabel
          color={colors.darkBlue}
          fontFamily="Satoshi"
          fontWeight="700"
          fontSize="14px"
          lineHeight="21px"
          mb="10px"
         >
          Bio
         </FormLabel>
         <Field
          as={Textarea}
          {...inputStyle}
          borderRadius="16px"
          height="100px"
          type="text"
          id="bio"
          name="bio"
          placeholder="Write a brief description of your business..."
         />
         <FormErrorMessage>{errors.bio}</FormErrorMessage>
        </FormControl>
        <FormControl
         w="100%"
         alignItems="center"
         mb="10px"
         isRequired
         isInvalid={!!errors.city && touched.city}
        >
         <FormLabel
          color={colors.darkBlue}
          fontFamily="Satoshi"
          fontWeight="700"
          fontSize="14px"
          lineHeight="21px"
          mb="10px"
         >
          City
         </FormLabel>
         <Field
          as={Input}
          {...inputStyle}
          type="text"
          id="city"
          name="city"
          placeholder="What state or city are you in?"
         />
         <FormErrorMessage>{errors.city}</FormErrorMessage>
        </FormControl>
       </Flex>

       <Box marginTop="30px">
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color={colors.darkBlue}
         fontWeight="400"
         lineHeight="135%"
         fontSize="24px"
         marginBottom="20px"
        >
         Social Links
        </Text>
        <Flex
         gap="30px"
         marginBottom="30px"
         flexDir={{ base: "column", md: "row" }}
        >
         <FormControl
          w="100%"
          alignItems="center"
          mb="10px"
          isInvalid={!!errors.website && touched.website}
         >
          <FormLabel
           color={colors.darkBlue}
           fontFamily="Satoshi"
           fontWeight="700"
           fontSize="14px"
           lineHeight="21px"
           mb="10px"
          >
           Website
          </FormLabel>
          <Field
           as={Input}
           {...inputStyle}
           type="text"
           id="website"
           name="website"
           placeholder="eg: https://www.example.com"
          />
          <FormErrorMessage>{errors.website}</FormErrorMessage>
         </FormControl>
         <FormControl
          w="100%"
          alignItems="center"
          mb="10px"
          isInvalid={!!errors.linkedin && touched.linkedin}
         >
          <FormLabel
           color={colors.darkBlue}
           fontFamily="Satoshi"
           fontWeight="700"
           fontSize="14px"
           lineHeight="21px"
           mb="10px"
          >
           Linkedin
          </FormLabel>
          <Field
           as={Input}
           {...inputStyle}
           type="text"
           id="linkedin"
           name="linkedin"
           placeholder="eg: https://www.linkedin.com/in/janedoe"
          />
          <FormErrorMessage>{errors.linkedin}</FormErrorMessage>
         </FormControl>
        </Flex>
        <Flex
         gap="30px"
         marginBottom="30px"
         flexDir={{ base: "column", md: "row" }}
        >
         <FormControl
          w="100%"
          alignItems="center"
          mb="10px"
          isInvalid={!!errors.instagram && touched.instagram}
         >
          <FormLabel
           color={colors.darkBlue}
           fontFamily="Satoshi"
           fontWeight="700"
           fontSize="14px"
           lineHeight="21px"
           mb="10px"
          >
           Instagram
          </FormLabel>
          <Field
           as={Input}
           {...inputStyle}
           type="text"
           id="instagram"
           name="instagram"
           placeholder="eg: https://www.instagram.com/example_user"
          />
          <FormErrorMessage>{errors.instagram}</FormErrorMessage>
         </FormControl>
         <FormControl
          w="100%"
          alignItems="center"
          mb="10px"
          isInvalid={!!errors.twitter && touched.twitter}
         >
          <FormLabel
           color={colors.darkBlue}
           fontFamily="Satoshi"
           fontWeight="700"
           fontSize="14px"
           lineHeight="21px"
           mb="10px"
          >
           Twitter
          </FormLabel>
          <Field
           as={Input}
           {...inputStyle}
           type="text"
           id="twitter"
           name="twitter"
           placeholder="eg: https://twitter.com/ExampleHandle"
          />
          <FormErrorMessage>{errors.twitter}</FormErrorMessage>
         </FormControl>
        </Flex>
        <Flex
         gap="30px"
         marginBottom="30px"
         flexDir={{ base: "column", md: "row" }}
        >
         <FormControl
          w="100%"
          alignItems="center"
          mb="10px"
          isInvalid={!!errors.facebook && touched.facebook}
         >
          <FormLabel
           color={colors.darkBlue}
           fontFamily="Satoshi"
           fontWeight="700"
           fontSize="14px"
           lineHeight="21px"
           mb="10px"
          >
           Facebook
          </FormLabel>
          <Field
           as={Input}
           {...inputStyle}
           type="text"
           id="facebook"
           name="facebook"
           placeholder="eg: https://www.facebook.com/examplepage"
          />
          <FormErrorMessage>{errors.facebook}</FormErrorMessage>
         </FormControl>
         <FormControl
          w="100%"
          alignItems="center"
          mb="10px"
          isInvalid={!!errors.tiktok && touched.tiktok}
         >
          <FormLabel
           color={colors.darkBlue}
           fontFamily="Satoshi"
           fontWeight="700"
           fontSize="14px"
           lineHeight="21px"
           mb="10px"
          >
           Tiktok
          </FormLabel>
          <Field
           as={Input}
           {...inputStyle}
           type="text"
           id="tiktok"
           name="tiktok"
           placeholder="eg: https://www.tiktok.com/@example_tiktok"
          />
          <FormErrorMessage>{errors.tiktok}</FormErrorMessage>
         </FormControl>
        </Flex>
       </Box>

       <Button
        color={colors.primaryWhite}
        bg={colors.primaryPurple}
        borderRadius={28}
        fontWeight={500}
        fontSize={18}
        marginTop={10}
        p="24px 20px"
        width={{ base: "100%", md: "25%" }}
        type="submit"
       >
        Next
       </Button>
      </form>
     )}
    </Formik>
   </Box>
  </Box>
 );
};

export default BusinessDetails;
