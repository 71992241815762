import {
 Box,
 Flex,
 Icon,
 Input,
 InputGroup,
 InputLeftElement,
 TableContainer,
 Text,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import {
 Card,
 CloseBtn,
 Container,
 ModalContainer,
 SubTableRow,
 Table,
 TableRow,
 searchInputStyle,
 svgFlexStyles,
} from "./styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { css } from "styled-components";
import { Button } from "../../../../components";
import Lottie from "../../../../assets/images/redeem-experiences-lottie.svg";
import { Modal } from "../../../../components";
import { colors } from "../../../../assets/styles/config";
import QrScan from "react-qr-reader";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { db } from "../../../../firebase";
import { Icon as CloseIconWrapper } from "../../../../components";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { TbCurrencyNaira } from "react-icons/tb";
import { LoadingIndicator } from "../../../../components";
import { IoTicketOutline } from "react-icons/io5";

const RedeemExperiences = () => {
 const [filteredRedeemedList, setFilteredRedeemedList] = useState(null);
 const [redeemedList, setRedeemedList] = useState(null);
 const [qrscan, setQrscan] = useState("No result");
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [loading, setLoading] = useState(false);

 const fetchPartnerDatabase = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  const db = await get(child(dbRef, `tickets`)).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();
    var array = Object.keys(myObj).map((key) => {
     return myObj[key];
    });

    let filterArray = Object.keys(array).map((key) => {
     return array[key];
    });

    const groupedItems = {};

    for (const item of filterArray) {
     if (groupedItems.hasOwnProperty(item?.user.uid)) {
      // groupedItems[item?.user.uid].total += item.total;
      groupedItems[item?.user.uid].count++;
      if (item.status === "redeemed") {
       groupedItems[item?.user.uid].redeemedCount++;
      }
     } else {
      groupedItems[item?.user.uid] = {
       ...item,
       count: 1,
       redeemedCount: item.status === "unredeemed" ? 0 : 1,
      };
     }
    }

    const result = Object.values(groupedItems);

    setRedeemedList(result);
    setLoading(false);
   }
  });

  return db;
 };

 useEffect(() => {
  fetchPartnerDatabase();
 }, []);

 useEffect(() => {
  if (redeemedList) {
   setFilteredRedeemedList(redeemedList);
  }
 }, [redeemedList]);

 const truncateString = (string, maxLength) =>
  string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

 //   FUNCTIONS
 const fetchDatabase = async (id) => {
  const dbRef = ref(getDatabase());
  const db = await get(child(dbRef, `tickets`)).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();
    return Object.keys(myObj)
     .map((key) => {
      return myObj[key];
     })
     .filter((item) => item?.token === id)[0];
   }
  });

  return db;
 };

 const handleSearchInput = (searchValue) => {
  const hasSearchValue = redeemedList.filter((item) =>
   item.toLowerCase().includes(searchValue.toLowerCase())
  );
  setFilteredRedeemedList(hasSearchValue);
 };

 const handleScan = async (data) => {
  if (data) {
   setQrscan(data);
   const ticket = await fetchDatabase(data);
   const status = ticket?.status;

   if (status === "unredeemed") {
    update(ref(db, "ticket/" + data), {
     status: "redeemed",
    })
     .then(() => {
      toast.success(`ticker ${data} successfully redeemed`);
      setVisible(!visible);
      fetchPartnerDatabase();
     })
     .catch((error) => {
      //  console.error(error.message);
      toast.error(error.message);
      setVisible(!visible);
     })
     .finally(() => {
      setTimeout(() => {
       toast.dismiss();
      }, 3000);
     });
   } else {
    toast.error(`ticker ${data} already redeemed`);
    setVisible(!visible);
    setTimeout(() => {
     toast.dismiss();
    }, 2000);
   }

   //  set(partnerRef, "redeemed");

   // alert("token redeemed");
  }
 };
 const handleError = (err) => {
  // console.error(err);
 };

 //   JSX
 const renderSearchAndAddCategoryArea = useMemo(
  () => (
   <>
    <Box display="flex" justifyContent="space-between" marginBottom={"54px"}>
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => handleSearchInput(e.target.value)}
      />
     </InputGroup>

     <Button
      animateOnTap
      animateOnHover
      buttonColor="#252046"
      css={css`
       width: fit-content;
       padding: 0.5rem 2rem;
       height: auto;
       font-weight: 500;
       font-family: "Creato Display";
       display: flex;
       gap: 15px;

       @media screen and (max-width: 768px) {
        border-radius: 10px;
        display: none;
       }
      `}
      onClick={() => {
       setVisible(!visible);
      }}
     >
      Redeem experience
     </Button>

     <Button
      animateOnTap
      animateOnHover
      buttonColor="#252046"
      css={css`
       width: fit-content;
       font-weight: 500;
       font-family: "Creato Display";
       display: none;
       gap: 15px;

       @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        border-radius: 100px;
       }
      `}
      onClick={() => {
       setVisible(!visible);
      }}
     >
      <Icon as={IoTicketOutline} boxSize={7} />
     </Button>
    </Box>
   </>
  ),
  [redeemedList, filteredRedeemedList, handleSearchInput]
 );

 return (
  <Container>
   {renderSearchAndAddCategoryArea}
   {loading ? (
    <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
     <LoadingIndicator />
    </Box>
   ) : (
    <>
     {filteredRedeemedList?.length > 0 ? (
      <>
       <TableContainer
        border="1px solid #E2E8F0"
        borderRadius="16px 16px 0px 0px"
       >
        <Table variant="simple">
         <thead>
          <TableRow>
           <td>Navi Id</td>
           <td>Name</td>
           <td>Email address</td>
           <td>Tickets bought</td>
           <td>Tickets Redeemed</td>
           <td>Amount</td>
          </TableRow>
         </thead>
         <tbody>
          {filteredRedeemedList?.map((ticket, index) => (
           <React.Fragment key={index}>
            <SubTableRow>
             <td>{truncateString(ticket.user.uid, 8) ?? "Guest"}</td>
             <td>{ticket.user.name}</td>
             <td>{ticket.user.email}</td>
             <td>{ticket.count}</td>
             <td>{ticket.redeemedCount}</td>
             <td style={{ display: "flex" }}>
              <TbCurrencyNaira />
              {ticket.total}
             </td>
            </SubTableRow>
           </React.Fragment>
          ))}
         </tbody>
        </Table>
       </TableContainer>
      </>
     ) : (
      <Flex {...svgFlexStyles}>
       <img
        src={Lottie}
        alt=""
        style={{ marginTop: "-40px", marginBottom: "-40px" }}
       />
       <Text>
        Start by redeeming an experience. Redeemed <br></br> experiences show up
        here
       </Text>
      </Flex>
     )}
    </>
   )}

   {/* REDEEM EXPERIENCE MODAL */}
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <ModalContainer
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
    >
     <Card animate={{ scale: 1 }} initial={{ scale: 0.9 }}>
      <div>
       <CloseBtn
        style={{ cursor: "pointer" }}
        onClick={() => setVisible(false)}
       >
        <CloseIconWrapper name="close" animateOnTap pointer />
       </CloseBtn>
       <Text textAlign={"center"} fontSize={20} fontWeight={600} pt="3" pb="5">
        {" "}
        Redeem a ticket
       </Text>
       <Box w="100%">
        <QrScan
         delay={300}
         onError={handleError}
         onScan={handleScan}
         style={{ height: 240, width: 320 }}
        />
       </Box>
      </div>
     </Card>
    </ModalContainer>
   </Modal>
  </Container>
 );
};

export default RedeemExperiences;
