import { DescriptionText, SubTitle, Wrapper } from '../styles';
import { css } from 'styled-components';
const Description = ({ data }) => {
  return (
    <Wrapper
      css={css`
        display: grid;
        gap: 1.25rem;
        padding-inline: 0;
        padding-block: 2rem;
      `}
    >
      <SubTitle
        css={css`
          @media screen and (min-width: 1080px) {
            display: none;
          }
        `}
      >
        Description
      </SubTitle>

      {data?.map((text, index) => (
        <DescriptionText key={index}>{text}</DescriptionText>
      ))}
    </Wrapper>
  );
};

export default Description;
