import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  InputGroup,
  Image,
  Input,
  InputLeftElement,
  Text,
  useDisclosure,
  Popover,
  PopoverContent,
  List,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ListItem,
  Button,
} from "@chakra-ui/react";
import { Transition } from "@headlessui/react";
import { storedb } from "../../../../firebase";
import {
  doc,
  onSnapshot,
  serverTimestamp,
  Timestamp,
  updateDoc,
  deleteDoc,
  deleteField,
} from "firebase/firestore";
import { ChatContext } from "../../../../context/ChatContext";
import Warning from "../../../../assets/images/warning-icon.svg";
import OptionIcon from "../../../../assets/images/option.svg";
import { LoadingIndicator, Modal } from "../../../../components";
import { Card, Container } from "../../../Admin/Dashboard/styles";
import { colors } from "../../../../assets/styles/config";
import toast from "react-hot-toast";
import Search from "./search";

const Chats = ({ currentUser, chats, setOpenChat }) => {
  const { dispatch, data } = useContext(ChatContext);
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const containerRef = useRef(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedDeleteChat, setSelectedDeleteChat] = useState(null);

  // console.log("currentUser", currentUser);

  const handleItemClick = (itemId) => {
    if (selectedItem === itemId) {
      onToggle();
    } else {
      setSelectedItem(itemId);
      onToggle(true);
    }
  };

  // const getChats = () => {
  //   setLoading(true);
  //   const unsub = onSnapshot(
  //     doc(storedb, "userChats", currentUser.uid),
  //     (doc) => {
  //       if (doc.data()) {
  //         setChats(doc.data());
  //         // dispatch({
  //         //   type: "CHANGE_USER",
  //         //   payload: (Object.entries(doc.data())?.sort(
  //         //     (a, b) => b[1].date - a[1].date
  //         //   )[0] || [])[1]?.userInfo,
  //         // });
  //       }
  //       setLoading(false);
  //     }
  //   );

  //   return () => {
  //     unsub();
  //   };
  // };

  // console.log("chats", Object.keys(chats)?.length);

  // useEffect(() => {
  //   currentUser.uid && getChats();
  // }, [currentUser.uid]);
  //   console.log(
  //     "Object.entries(chats)",
  //     Object.entries(chats)?.sort((a, b) => b[1].date - a[1].date)
  //   );

  const handleSelect = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  useEffect(() => {
    if (chats?.length > 0) {
      handleSelect(
        chats
          ?.sort((a, b) => b.date - a.date)
          ?.filter((item) => item?.status === "Pending")[0]?.userInfo
      );
      setSelectedChatId(
        chats
          ?.sort((a, b) => b.date - a.date)
          ?.filter((item) => item?.status === "Pending")[0]?.userInfo?.uid
      );
    }
  }, []);

  console.log("selectedChatId", selectedChatId);

  const handleResolve = async (chat) => {
    toast.loading("loading");

    await updateDoc(doc(storedb, "userChats", currentUser.uid), {
      [chat[0]]: {
        userInfo: chat[1].userInfo,
        lastMessage: chat[1]?.lastMessage ? chat[1]?.lastMessage : "",
        date: chat[1]?.date,
        status: "Resolved",
      },
    })
      .then(async () => {
        await updateDoc(doc(storedb, "userChats", chat[1].userInfo.uid), {
          [chat[0]]: {
            userInfo: {
              uid: currentUser.uid,
              displayName: currentUser.name,
              photoURL: currentUser.photoURL,
              email: currentUser?.email,
            },
            lastMessage: chat[1]?.lastMessage ? chat[1]?.lastMessage : "",
            date: chat[1]?.date,
            status: "Resolved",
          },
        }).then(() => {
          getChats();
          onToggle();
          toast.dismiss();
          toast.success("Successfully resolved chat");
        });
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          toast.dismiss();
        }, 3000);
      });
  };

  const handleDelete = async () => {
    toast.loading("loading");
    const combinedId =
      currentUser.uid > selectedDeleteChat.userInfo.uid
        ? currentUser.uid + selectedDeleteChat.userInfo.uid
        : selectedDeleteChat.userInfo.uid + currentUser.uid;

    await deleteDoc(doc(storedb, "chats", combinedId))
      .then(async () => {
        await updateDoc(doc(storedb, "userChats", currentUser.uid), {
          [combinedId]: deleteField(),
        }).then(async () => {
          await updateDoc(
            doc(storedb, "userChats", selectedDeleteChat.userInfo.uid),
            {
              [combinedId]: deleteField(),
            }
          ).then(() => {
            setVisible(!visible);
            toast.dismiss();
            toast.success("Successfully deleted chat");
            dispatch({
              type: "CHANGE_USER",
              payload: (Object.entries(chats)?.sort(
                (a, b) => b[1].date - a[1].date
              )[0] || [])[1]?.userInfo,
            });
          });
        });
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          toast.dismiss();
        }, 3000);
      });
  };

  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="40px"
        >
          <LoadingIndicator />
        </Box>
      ) : (
        <Box display="flex" flexDir="column" width="100%">
          <Text
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "16px", md: "18px" }}
            lineHeight="21px"
            fontWeight="700"
            textAlign="center"
          >
            Chats
          </Text>
          {chats?.length > 0 ? (
            chats
              ?.sort((a, b) => b.date - a.date)
              ?.filter((item) => item?.status === "Pending")
              .map((chat, index) => {
                const combined =
                  currentUser?.uid > selectedChatId
                    ? currentUser?.uid + selectedChatId
                    : selectedChatId + currentUser?.uid;
                console.log("combined", combined);
                return (
                  <Transition
                    appear
                    as="div"
                    show={true}
                    key={index}
                    style={{ transitionDelay: `${100 * index}ms` }}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 translate-y-10"
                    enterTo="transform opacity-100 translate-y-0"
                  >
                    <Box
                      marginTop="20px"
                      key={index}
                      padding="10px"
                      borderRadius="10px"
                      background={
                        data?.chatId === combined ? "#F1F5F9" : "transparent"
                      }
                      display="flex"
                      alignItems="center"
                      gap="10px"
                      marginBottom="10px"
                      width="100%"
                      cursor="pointer"
                      _hover={{
                        background: "#F1F5F9",
                      }}
                      onClick={() => {
                        setOpenChat(true);
                        setSelectedChatId(chat.userInfo.uid);
                        handleSelect(chat.userInfo);
                      }}
                      //   onClick={() => setCurrentChat(item)}
                    >
                      <Image
                        src={chat?.userInfo?.photoURL}
                        width="40px"
                        height="40px"
                        borderRadius="50%"
                      />
                      <Box
                        display="flex"
                        flexDir="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Box flexDir="column">
                          <Text
                            color="#0F0931"
                            fontSize="14px"
                            lineHeight="19px"
                            fontWeight="500"
                            marginBottom="10px"
                          >
                            {chat?.userInfo?.displayName}
                          </Text>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Text
                              color="#334155"
                              fontSize="12px"
                              lineHeight="16px"
                              fontWeight="400"
                            >
                              {chat?.lastMessage}
                            </Text>
                            {/* <Text
                            color="#334155"
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight="400"
                          >
                            {item.time}
                          </Text> */}
                          </Box>
                        </Box>
                        <Box marginRight="20px" position="relative">
                          <Image
                            src={OptionIcon}
                            alt="option"
                            transform="rotate(-90deg)"
                            onClick={() => handleItemClick(index)}
                          />
                          {selectedItem === index && (
                            <Popover
                              isOpen={isOpen}
                              onClose={onClose}
                              placement="top"
                            >
                              <PopoverContent
                                boxShadow="0px 4px 24px #D4DEEC"
                                border="12px"
                                zIndex="20"
                                cursor="pointer"
                                borderRadius="12px"
                                padding="10px 0px"
                                width="200px"
                              >
                                <List>
                                  {/* <ListItem
                                  borderBottom="1px solid #E2E8F0"
                                  padding="10px 20px"
                                  onClick={() => handleResolve(chat)}
                                  _hover={{
                                    background: "#F1F5F9",
                                  }}
                                >
                                  Resolve
                                </ListItem> */}
                                  <ListItem
                                    // borderBottom="1px solid #E2E8F0"
                                    padding="10px 20px"
                                    _hover={{
                                      background: "#F1F5F9",
                                    }}
                                    onClick={() => {
                                      setVisible(!visible);
                                      setSelectedDeleteChat(chat);
                                    }}
                                  >
                                    Delete
                                  </ListItem>
                                </List>
                              </PopoverContent>
                            </Popover>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Transition>
                );
              })
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="30px"
            >
              <Text
                fontSize={{ base: "14px" }}
                lineHeight="125%"
                fontWeight="500"
                fontFamily="Satoshi"
                color="#334155"
              >
                No Chat yet
              </Text>
            </Box>
          )}
        </Box>
      )}
      <Modal
        {...{
          visible,
          setVisible,
          childrenContainerRef: containerRef,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "auto",
        }}
        overlayLayer
        overlayLayerStyle={{
          backgroundColor: colors.black,
          opacity: 0.7,
        }}
      >
        <Container
          ref={containerRef}
          animate={{ scale: 1 }}
          initial={{ scale: 0.9 }}
        >
          <Card
            animate={{ scale: 1 }}
            initial={{ scale: 0.9 }}
            style={{
              width: "358px",
              height: "100%",
              padding: "2rem",
            }}
          >
            <Box
              display="flex"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Image src={Warning} />
              <Text
                fontFamily="Creato Display, Roboto, sans-serif;"
                color="#0F0931"
                fontSize={{ base: "14px", md: "24px" }}
                lineHeight="134%"
                fontWeight="700"
                marginTop="30px"
                textAlign="center"
              >
                Delete Chat
              </Text>
              <Text
                fontFamily="Satoshi"
                color="#878498"
                fontWeight="500"
                marginTop="20px"
                textAlign="center"
              >
                Are you sure you want to delete this chat?
              </Text>
              <Box
                mt="30px"
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap="20px"
                width="100%"
              >
                <Button
                  color="#ffffff"
                  bg={colors.primaryPurple}
                  borderRadius="54px"
                  fontWeight={500}
                  fontSize={18}
                  p="25px 20px"
                  width="100%"
                  onClick={() => handleDelete()}
                >
                  Yes, Delete
                </Button>
                <Button
                  border="1px solid #252046"
                  bg="transparent"
                  borderRadius="54px"
                  fontWeight={500}
                  fontSize={18}
                  p="25px 20px"
                  width="100%"
                  type="submit"
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Card>
        </Container>
      </Modal>
    </Box>
  );
};

export default Chats;
