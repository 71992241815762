import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  convertToRaw,
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./textEditor.css";

export const TextEditor = ({ value, setFieldValue, placeholder, isEdit }) => {
  // console.log("value", value);

  const prepareDraft = (value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  function toHtml(es) {
    return draftToHtml(convertToRaw(es.getCurrentContent()));
  }

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  /* useEffect(() => {
    if (value && isEdit) {
      const contentBlocks = convertFromHTML(value);

      const contentState = ContentState.createFromBlockArray(
        contentBlocks.contentBlocks,
        contentBlocks.entityMap
      );

      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [value, isEdit]); */

  useEffect(() => {
    if (value && isEdit) {
      if (toHtml(editorState) === value) return; //added

      setEditorState(
        EditorState.push(
          editorState,
          ContentState.createFromBlockArray(htmlToDraft(value || ""))
        )
      );
    }
  }, [value, isEdit]);

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
  };
  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="custom-wrapper"
        editorClassName="custom-editor"
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
      />
    </div>
  );
};
