import { Box, Img } from "@chakra-ui/react";
import { Splide } from "@splidejs/react-splide";
import React from "react";
import { bytesToSize } from "../../../../../../utils";

const Media = ({ media, open }) => {
 return (
  <>
   <Box position="relative">
    <Splide
     className={{
      gridAutoFlow: "1fr",
      justifyItems: "center",
     }}
     aria-labelledby="Blog"
     options={{
      rewind: true,
      perPage: 1,
      // gap: "2rem",
      classes: {
       arrows: "splide__arrows splide-navigation-arrows",
       arrow: "splide__arrow splide__arrow__img splide-navigation-arrow",
       prev: "splide__arrow--prev splide-img-prev",
       next: "splide__arrow--next splide-img-next",
      },
      arrowPath: SliderArrowPath,
     }}
    >
     {media?.map((item, index) => (
      <SplideSlide key={index}>
       <Box display="flex" alignItems="center" flexDir="column">
        <Box
         width="100%"
         height="100%"
         background="#F8FBFF"
         display="flex"
         justifyContent="center"
         alignItems="center"
         border="none"
        >
         <Img
          src={item?.preview}
          alt="avatar"
          width="100%"
          height="153px"
          cursor="pointer"
          onClick={open}
         />
        </Box>

        <div>
         <p key={item.path}>
          {item.name.substring(0, 6)}
          ... ({bytesToSize(item.size)}),
         </p>
        </div>
       </Box>
      </SplideSlide>
     ))}
    </Splide>
   </Box>
  </>
 );
};

export default Media;
