import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 List,
 ListItem,
 SimpleGrid,
 Flex,
 Input,
 InputGroup,
 InputLeftElement,
 Button,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";

import { searchInputStyle } from "../styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import styles from "./index.module.css";
import { colors } from "../../../../assets/styles/config";
import FilterIcon from "../../../../assets/images/filter.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { NoCollections } from "../../../Experiences/styles";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
 EmptyState,
} from "../../../../components";
import { db } from "../../../../firebase";
import {
 SplideSlideWrapper,
 SlideImg,
 SlideText,
} from "../../../Collections/styles";

const AdminCollection = () => {
 const { user, addAdmin, updateAdministrator, deleteAdministrator } =
  useContext(AuthContext);
 const [loading, setLoading] = useState(false);
 const tableRef = useRef(null);
 const [visible, setVisible] = useState(false);
 const [search, setSearch] = useState("");
 const [collections, setCollections] = useState([]);
 const [filterCollection, setFilterCollection] = useState([]);
 const cityId = "lagos";

 // OTHER VARIABLES
 const cityExperienceDb = ref(db, "cityExperience/city");

 // EFFECTS
 useEffect(() => {
  const unsubscribe = onValue(cityExperienceDb, (snapshot) => {
   const data = snapshot.val();

   setCollections(data[cityId]?.collections);
  });

  return () => unsubscribe();
 }, [cityId]);

 useEffect(() => {
  setFilterCollection(collections);
 }, [collections]);

 const searchFilter = (text) => {
  if (text) {
   setSearch(text);
   const newData = collections.filter((item) => {
    const itemData = item.title ? item.title.toUpperCase() : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setFilterCollection(newData);
  } else {
   setSearch(text);
   setFilterCollection(collections);
  }
 };

 return (
  <Box height="100%" padding="20px" paddingTop={"10px"} background="#F5F8FA">
   <Flex
    alignItems="flex-start"
    flexDir={"row"}
    justifyContent="space-between"
    marginBottom={"20px"}
   >
    <InputGroup borderRadius="28px" width={{ base: "70%", md: "60%" }}>
     <InputLeftElement
      pointerEvents="none"
      children={<SearchIcon color="gray.300" />}
      paddingBlock="24px"
      paddingLeft={"25px"}
     />
     <Input
      {...searchInputStyle}
      onChange={(e) => searchFilter(e.target.value)}
     />
    </InputGroup>

    <Box display="flex" alignItems="center">
     {/* <Button
              color={colors.blue}
              bg={colors.skyBlue}
              borderRadius={28}
              fontWeight={500}
              fontSize={18}
              p="20px 30px"
              marginRight="20px"
            >
              <Img src={FilterIcon} />
              Filter
            </Button> */}

     <Link to="/admin/collection/add">
      <Button
       background="#632EFF"
       borderRadius={{ base: "30px", md: "54px" }}
       padding={{ base: "12px 18px", md: "16px 60px" }}
       color="#fff"
       fontFamily="Creato Display"
       fontWeight={{ base: 500, md: 700 }}
       fontSize={{ base: "14px", md: 15 }}
       lineHeight="20px"
       letterSpacing="0.1px"
       height="48px"
       display="flex"
       gap="15px"
      >
       <AddIcon h="16px" w="16px" />
       <Text display={{ base: "none", md: "inline-block" }}>
        Add Collection
       </Text>
      </Button>
     </Link>
    </Box>
   </Flex>

   <Box
    background="#FFFFFF"
    borderRadius="6px"
    // mt="30px"
    minHeight="80vh"
    padding="20px"
   >
    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center">
      <LoadingIndicator />
     </Box>
    ) : filterCollection?.length > 0 ? (
     <Box>
      <SimpleGrid
       columns={{ base: 1, md: 3 }}
       alignItems="center"
       spacing={10}
       width="100%"
      >
       {filterCollection?.map((collection, index) => (
        <Link to={`/admin/collection/edit/${collection.city}/${index}`}>
         <SplideSlideWrapper
          key={index}
          style={{ width: "100%", height: "200px" }}
         >
          <SlideImg src={collection.img} alt={collection.title} />
          <SlideText>{collection.title}</SlideText>
         </SplideSlideWrapper>
        </Link>
       ))}
      </SimpleGrid>
     </Box>
    ) : (
     <EmptyState text={"Uploaded collections will appear here"} />
    )}
   </Box>
  </Box>
 );
};

export default AdminCollection;
