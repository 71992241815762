import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Text,
  Button,
  Image,
  Select as ChakraSelect,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
} from "@chakra-ui/react";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import Support1 from "../../../assets/images/support1.png";
import Support2 from "../../../assets/images/support2.png";
import Support3 from "../../../assets/images/support3.png";
import Support4 from "../../../assets/images/support4.png";
import Support5 from "../../../assets/images/support5.png";
import EmojiIcon from "../../../assets/images/svg/emoji_emotions.svg";
import AttachIcon from "../../../assets/images/svg/attach_file.svg";
import Picker from "emoji-picker-react";
import { AiFillFile } from "react-icons/ai";
import { useDropzone } from "react-dropzone";
import { bytesToSize } from "../../../utils/index";
import { Transition } from "@headlessui/react";
import { Formik, Field, useFormik } from "formik";
import { storedb, storage } from "../../../firebase";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { v4 as uuid } from "uuid";
import Search from "./search";
import Chats from "./charts";
import Messages from "./messages";
import {
  sendPartnerPushNotification,
  sendPushNotification,
} from "../../../api/user";

const Supports = () => {
  const { user } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const [openEmoji, setOpenEmoji] = useState(false);
  const toggleHandler = useRef(null);
  const [files, setFiles] = useState(null);
  const [message, setMessage] = useState("");

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      accept:
        "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      noClick: true,
      noKeyboard: true,
      maxSize: 10000000,
      minSize: 0,
    });

  const handleClickOutside = (event) => {
    if (!toggleHandler?.current?.contains(event.target)) {
      setOpenEmoji(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleMediaUpload = async (file) => {
    const storageRef = ref(storage, `/files/${file.name}`);
    await uploadBytesResumable(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  return (
    <Box
      // height="100%"
      padding="14px 20px"
      display="grid"
      gridTemplateColumns={data?.user?.displayName ? "400px 1fr" : "1fr"}
      gridTemplateRows="1fr"
      alignItems="flex-start"
      gap="20px"
      background="#F5F8FA"
      // position="relative"
    >
      <Box
        background="#FFFFFF"
        borderRadius="16px"
        padding="20px 10px"
        width="100%"
        // position="fixed"
        height="calc(100vh - 140px)"
        top="20px"
        position="sticky"
      >
        <Search currentUser={user} />
        <Chats currentUser={user} />
      </Box>
      {data?.user?.displayName && (
        <Box
          background="#FFFFFF"
          borderRadius="16px"
          width="100%"
          // height="100vh"
          // height="calc(100vh - 140px)"
        >
          <Box
            padding="30px"
            alignItems="flex-start"
            display="flex"
            borderBottom="1px solid #EBEEF2"
            gap="20px"
          >
            <Image
              src={data?.user?.photoURL}
              width="40px"
              height="40px"
              borderRadius="50%"
            />
            <Box>
              <Text
                color="#0F0931"
                fontSize="14px"
                lineHeight="19px"
                fontWeight="500"
                marginBottom="10px"
              >
                {data?.user?.displayName}
              </Text>
              <Text
                color="#334155"
                fontSize="12px"
                lineHeight="16px"
                fontWeight="400"
              >
                {data?.user?.email}
              </Text>
            </Box>
          </Box>
          <Box padding="20px 30px">
            <Formik
              initialValues={{
                message: "",
              }}
              // onSubmit={async (values) => {
              //   submit(values);
              // }}
            >
              {({
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                values,
                resetForm,
              }) => {
                const onEmojiClick = (event, emojiObject) => {
                  const textAreaElement = document.getElementById("message");
                  setFieldValue(
                    "message",
                    values.message.substr(0, textAreaElement.selectionStart) +
                      emojiObject.emoji +
                      values.message.substr(textAreaElement.selectionEnd)
                  );
                };

                const submit = async () => {
                  setOpenEmoji(false);

                  let fileUrl = [];
                  var keys = files && Object.keys(files);
                  for (var i = 0; i < keys?.length; i++) {
                    var key = keys[i];
                    const img = files && (await handleMediaUpload(files[key]));
                    fileUrl.push(img);
                  }

                  await updateDoc(doc(storedb, "chats", data.chatId), {
                    messages: arrayUnion({
                      id: uuid(),
                      text: values.message,
                      senderId: user.uid,
                      date: Timestamp.now(),
                      file: fileUrl ? fileUrl : "",
                    }),
                  });

                  await updateDoc(doc(storedb, "userChats", user.uid), {
                    [data.chatId]: {
                      userInfo: {
                        uid: data.user.uid,
                        displayName: data.user.displayName,
                        photoURL: data.user.photoURL,
                        email: data.user?.email,
                      },
                      lastMessage: values.message,
                      date: serverTimestamp(),
                      status: "Pending",
                    },
                  });

                  await updateDoc(doc(storedb, "userChats", data.user.uid), {
                    [data.chatId]: {
                      userInfo: {
                        uid: user.uid,
                        displayName: user.name,
                        photoURL: user.photoURL,
                        email: user?.email,
                      },
                      lastMessage: values.message,
                      date: serverTimestamp(),
                      status: "Pending",
                      senderId: user.uid,
                    },
                  });
                  if (user) {
                    await sendPartnerPushNotification({
                      title: "partner",
                      body: `${user.name} sent a support message`,
                      data: {
                        uid: data.user.uid,
                        route: `/partner/support`,
                        name: user.name,
                        photoURL: user.photoURL,
                        push_time: Date.now(),
                      },
                    });
                  }

                  resetForm();
                };
                return (
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      width: "100%",
                      marginTop: "30px",
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      overflowY="scroll"
                      width="100%"
                      height="30vh"
                      sx={{
                        "::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      <Messages currentUser={user} />
                      {/* {currentChat.message?.map((item, index) => (
                            <Transition
                              appear
                              as="div"
                              show={true}
                              key={index}
                              style={{ transitionDelay: `${100 * index}ms` }}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 translate-y-10"
                              enterTo="transform opacity-100 translate-y-0"
                            >
                              <Box
                                width="100%"
                                key={index}
                                display="flex"
                                // alignItems={
                                //   user?.uid === item.id ? "flex-end" : "flex-start"
                                // }
                                gap="10px"
                                flexDirection={
                                  user?.uid === item.id ? "row-reverse" : "row"
                                }
                                marginBottom="20px"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDir="column"
                                >
                                  <Image
                                    src={item.img}
                                    width="40px"
                                    height="40px"
                                    cursor="pointer"
                                  />
                                  <Text
                                    color="#64748B"
                                    fontSize="12px"
                                    lineHeight="16px"
                                    marginTop="10px"
                                  >
                                    {item.time}
                                  </Text>
                                </Box>

                                <Box
                                  display="flex"
                                  alignItems={
                                    user?.uid === item.id
                                      ? "flex-end"
                                      : "flex-start"
                                  }
                                  gap="5px"
                                  flexDirection="column"
                                >
                                  <Text
                                    color="#334155"
                                    fontSize="12px"
                                    lineHeight="16px"
                                    fontWeight="500"
                                    marginBottom="10px"
                                  >
                                    {item.name}
                                  </Text>
                                  <Box
                                    borderRadius={
                                      user?.uid === item.id
                                        ? "16px 16px 0px 16px"
                                        : "16px 16px 16px 0px"
                                    }
                                    background={
                                      user?.uid === item?.id
                                        ? "#EBF7FF"
                                        : "#F3F4F7"
                                    }
                                    padding="8px"
                                    width="300px"
                                  >
                                    <Text
                                      color="#334155"
                                      fontSize="12px"
                                      fontWeight="500"
                                    >
                                      {item.msg}
                                    </Text>
                                  </Box>
                                </Box>
                              </Box>
                            </Transition>
                          ))} */}
                    </Box>
                    <Box
                      // {...getRootProps()}
                      marginTop="30px"
                      ref={toggleHandler}
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Box
                        {...getRootProps()}
                        background="#F1F5F9"
                        border="1px solid #EBEEF2"
                        borderRadius="12px"
                        padding="18px 16px"
                        width="100%"
                        position="relative"
                      >
                        {openEmoji && (
                          <Box
                            position="absolute"
                            bottom="150px"
                            left="0px"
                            zIndex="300"
                          >
                            <Picker onEmojiClick={onEmojiClick} />
                          </Box>
                        )}
                        <input {...getInputProps()} />
                        <Field
                          as={Textarea}
                          placeholder="Type message"
                          background="transparent"
                          height="30px"
                          width="100%"
                          border="none"
                          outline="none"
                          _focusVisible={{
                            border: "none",
                            outline: "none",
                          }}
                          id="message"
                          name="message"
                        />
                        <Box
                          marginTop="10px"
                          display="flex"
                          width="100%"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" gap="20px">
                            <Image
                              src={EmojiIcon}
                              cursor="pointer"
                              onClick={() => setOpenEmoji(!openEmoji)}
                            />
                            <Image
                              src={AttachIcon}
                              cursor="pointer"
                              onClick={open}
                            />
                            {files?.map((file) => (
                              <a href={file.preview} target="_blank">
                                <Box display="flex" alignItems="center">
                                  <AiFillFile />{" "}
                                  <Text
                                    textAlign="center"
                                    color="#64748B"
                                    fontFamily="Satoshi"
                                    fontSize="16px"
                                    lineHeight="135%"
                                    marginLeft="10px"
                                    key={file.path}
                                  >
                                    {file.name.substring(0, 6)}... (
                                    {bytesToSize(file.size)}),
                                  </Text>
                                </Box>
                              </a>
                            ))}
                          </Box>
                          <Button
                            background="#632EFF"
                            borderRadius="54px"
                            padding="10px 30px"
                            color="#ffffff"
                            type="submit"
                            onClick={submit}
                          >
                            Send
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Supports;
