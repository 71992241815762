import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { colors, fontWeight } from "../../../../assets/styles/config";

export const Container = styled.section`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  min-height: 82vh;
`;

export const ModalContainer = styled(motion.div)`
  padding: 2rem;
  padding: 3rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1080px) {
    padding: 3rem 4rem;
  }
`;

export const Table = styled.table`
  background: #ffffff;
  /* Slate 3 */
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  padding-right: 20px;
`;

export const TableRow = styled.tr`
  font-family: "Satoshi" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  /* identical to box height, or 18px */

  color: #334155;

  td {
    padding: 20.5px 24px;
    border-bottom: 1px solid #e2e8f0;
    white-space: nowrap;
  }
`;

export const SubTableRow = styled.tr`
  font-family: "Satoshi";
  // display: flex;
  // justify-content: space-between;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  /* identical to box height, or 18px */

  color: #334155;

  td {
    font-family: "Satoshi";
    padding: 17px 24px;
    border-bottom: 1px solid #e2e8f0;
    white-space: nowrap;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const Card = styled(motion.div)`
  background-color: ${colors.primaryWhite};
  padding: 2rem;
  position: relative;
  border-radius: 28px;
  height: 450px;

  @media screen and (min-width: 1080px) {
    min-width: 300px;
    height: 500px;
    border-radius: 28px;
    padding: 3rem 4rem;
  }
`;

export const searchInputStyle = {
  borderRadius: "28px",
  height: "48px",
  type: "search",
  placeholder: "Search",
  paddingLeft: "52px",
  background: "#F8FAFC",
  name: "search categories",
  fontFamily: "Satoshi",
  height: "3.7em",
  fontSize: "14px",
  autoFocus: true,
  _placeholder: {
    fontFamily: "Satoshi",
    fontWeight: 500,
    fontSize: "16px",
    color: "#64748B",
    // opacity: "0.45",
  },
  _focus: {
    background: "#F3F0FF",
  },
  _focusVisible: { border: "1px solid #AC98FE" },
};

export const svgFlexStyles = {
  flexDir: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  fontWeight: "500",
  color: "#0F0931",
  fontFamily: "Satoshi",
};
