import { css } from '@emotion/react';
import styled from 'styled-components';
import { colors, fontWeight } from '../../../assets/styles/config';

export const Card = styled.div`
  position: relative;
  background-color: ${colors.primaryWhite};
  padding: 2rem;
  box-shadow: 7px 18px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  ${(props) => props?.css || ''}
`;

export const Title = styled.p`
  font-weight: ${fontWeight.semiBold};
  font-size: 16.5px;
  padding: 20px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.4);
  background-color: ${colors.primaryWhite};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }

  ${(props) => props?.css || ''}
`;

export const ProductText = styled.p`
  font-weight: ${fontWeight.medium};
  font-size: 16.5px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  ${(props) => props?.css || ''}
`;

export const SingleProductContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 3rem;

  @media screen and (min-width: 768px) {
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
`;

export const CounterIcon = styled.button`
  background: ${colors.blue};
  font-weight: ${fontWeight.black};
  border-radius: 50%;
  font-size: 20px;
  width: 24px;
  height: 24px;
  font-weight: ${fontWeight.medium};
  color: ${colors.primaryWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.2s;

  &:hover {
    scale: 1.15;
  }

  &:active {
    scale: 0.9;
  }

  @media screen and (min-width: 768px) {
    font-size: 22px;
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 26px;
    line-height: 38px;
    width: 34px;
    height: 34px;
  }
`;

export const CounterText = styled.p`
  font-weight: ${fontWeight.medium};
  color: ${colors.brown};
  font-size: 20px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ProductImgContainer = styled.div`
  background: ${colors.skyBlue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    width: 470px;
    height: 470px;
  }

  //   @media screen and (min-width: 1080px) {
  //     width: 621px;
  //     height: 621px;
  //   }
`;

export const ProductImg = styled.img`
  width: 60%;
  height: 100%;
  object-fit: scale-down;
`;

export const SingleProductWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: solid 1px rgba(0, 0, 0, 0.4);
  padding: 0.5rem 0;

  @media screen and (min-width: 768px) {
    min-height: 107px;
    margin-bottom: 30px;
  }
`;

export const CloseBtn = styled.button`
  font-size: 22px;
  top: 40px;
  right: 50px;
  position: absolute;
`;
