import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Button,
  Flex,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import bg from "../../assets/images/blog-bg.png";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { dealsDeal, options, offer1, offer2 } from "../../mocks/index";
import circle from "../../assets/images/deals/circle.svg";
import circle1 from "../../assets/images/deals/circle1.svg";
import { Header, Footer, Loader } from "../../components";
import Navbar from "../Home/Navbar";
import { TitleSection } from "../Experiences/styles";
import { colors } from "../../assets/styles/config";

const AllDeals = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterDeals, setFilterDeals] = useState(dealsDeal);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const searchFilter = (text) => {
    if (text) {
      setSearch(text);
      const newData = dealsDeal.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const input = text.toUpperCase();
        return itemData.indexOf(input) > -1;
      });
      setFilterDeals(newData);
    } else {
      setSearch(text);
      setFilterDeals(dealsDeal);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header bgColor="transparent" color="dark" />
      <Box
        mt="80px"
        p={{ base: "2rem", md: "4rem" }}
        bgSize="cover"
        bgRepeat="no-repeat"
      >
        <TitleSection style={{ margin: "0", padding: "0" }}>
          <Flex textAlign={"center"} gap={"0.75rem"}>
            <Link to="/">
              <Text
                color={colors.darkBlue}
                fontWeight="400"
                fontSize={{ base: 15, md: 16, lg: 18 }}
              >
                {"Home >"}
              </Text>
            </Link>

            <Text
              color={colors.faintBrown}
              fontWeight="400"
              fontSize={{ base: 15, md: 16, lg: 18 }}
            >
              {"All Deals >"}
            </Text>
          </Flex>
        </TitleSection>
        <Box
          display="flex"
          w="100%"
          alignItems="center"
          justifyContent="space-between"
          flexDir={{ base: "column", lg: "row" }}
        >
          <Text
            color="#0F0931"
            fontSize={{ base: "25px", md: "33px", lg: "50px" }}
            lineHeight="59px"
            fontWeight="600"
          >
            All Deals
          </Text>
          <Box className={styles.search}>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => searchFilter(e.target.value)}
            />
          </Box>
        </Box>

        <Box
          display="grid"
          gridTemplateColumns={{ base: "1fr", lg: "200px 1fr" }}
          gridGap="30px"
          alignItems="flex-start"
          marginTop="60px"
        >
          <Box display="flex" flexDir="column">
            <HStack
              display="flex"
              flexDir="column"
              alignItems="flex-start"
              borderBottom="1px solid #BDBDBD"
            >
              <h2 className={styles.offer}>Offer Type</h2>
              {offer1.map((item, index) => {
                return (
                  <label className={styles.checkmark} key={index}>
                    <div className={styles.text}>
                      <p>{item.offer}</p>
                      <span>({item.amount})</span>
                    </div>

                    <div className={styles.checkmarkDiv}>
                      <input type="checkbox" name="offer" value={item} />
                      <span></span>
                    </div>
                  </label>
                );
              })}
            </HStack>
            <HStack
              display="flex"
              flexDir="column"
              alignItems="flex-start"
              borderBottom="1px solid #BDBDBD"
              marginTop="20px"
            >
              <h2 className={styles.offer}>Offer Type</h2>
              {offer2.map((item, index) => {
                return (
                  <label className={styles.checkmark} key={index}>
                    <div className={styles.text}>
                      <p>{item.offer}</p>
                      <span>({item.amount})</span>
                    </div>

                    <div className={styles.checkmarkDiv}>
                      <input type="checkbox" name="offer" value={item} />
                      <span></span>
                    </div>
                  </label>
                );
              })}
            </HStack>
            <Box mt="30px" display="flex" flexDir="column" width="100%">
              <Button
                color="#ffffff"
                bg="#25AEFC"
                borderRadius={28}
                fontWeight={500}
                fontSize={18}
              >
                Apply
              </Button>
              <Button
                color="#25AEFC"
                bg="transparent"
                border="1px solid #25AEFC"
                borderRadius={28}
                fontWeight={500}
                fontSize={18}
                mt="10px"
              >
                Reset
              </Button>
            </Box>
          </Box>
          <Box position="relative">
            <Img src={circle} alt="circle" position="absolute" />
            <Img src={circle1} alt="circle" position="absolute" right="40" />
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
              spacing={5}
              zIndex="20"
              justifyItems="center"
              gridAutoFlow="1fr"
            >
              {filterDeals.map((item, i) => {
                return (
                  <Box
                    key={i}
                    w="100%"
                    _hover={{
                      boxShadow: "xl",
                      transform: "translateY(-5px)",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    borderRadius={"24px"}
                    position="relative"
                    display="grid"
                    gridTemplateRows="3"
                    gap="10px"
                    p={"20px"}
                    bgColor="#FFFFFF"
                    boxShadow="0px 9px 46px rgba(0, 0, 0, 0.12)"
                    cursor="pointer"
                    onClick={() => {
                      navigate(`/deal/${i}`);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Img src={item.img} className={styles.images} />

                    <Text
                      color="#000000"
                      fontSize={{ base: "20px", md: "26px", lg: "28px" }}
                      lineHeight="38px"
                      fontWeight="600"
                    >
                      {item.title}
                    </Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      spacing="10px"
                    >
                      {options.map((item, index) => {
                        return (
                          <Box
                            key={index}
                            p="5px"
                            border="1px solid #25AEFC"
                            borderRadius="21px"
                            mr="10px"
                            mb="10px"
                          >
                            <Text
                              fontSize="12px"
                              lineHeight="20px"
                              color="#25AEFC"
                            >
                              {item}
                            </Text>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default AllDeals;
