import { css } from "@emotion/react";
import styled from "styled-components";
import { colors, fontWeight } from "../../assets/styles/config";

export const Container = styled.div`
  padding: 1rem;

  @media screen and (min-width: 1080px) {
    padding: 3rem 4rem;
  }
  padding-top: 0 !important;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 50px;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding-inline: 2rem;
  margin-top: 85px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 74px;
    gap: 61px;
    margin-top: 90px;
  }

  @media (min-width: 991px) {
    margin-top: 110px;
  }

  @media (min-width: 1080px) {
    padding: 3rem 4rem;
    padding-bottom: 0;
    margin-top: 120px;
  }
`;

export const Title = styled.h1`
  font-size: 25px;
  font-weight: ${fontWeight.bold};
  color: ${colors.darkBlue};
  text-transform: capitalize;
  letter-spacing: 0.03em;

  @media (min-width: 768px) {
    font-size: 33px;
    line-height: 45px;
  }

  @media (min-width: 1080px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

export const Heading = styled.h1`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  gap: 18.5px;

  ${(props) => props.css || ""}
`;

export const FaintBrownImg = styled.img`
  height: 35px;
  width: 35px;
  border-radius: 25px;
  object-fit: cover;
`;

export const FaintBrownText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25.8px;

  color: ${colors.faintBrown};

  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1080px) {
    font-size: 20px;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  // box-shadow: 8px 3px 22px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  padding: 1.5rem;

  .guest-marker {
    accent-color: #632eff;
  }

  @media (min-width: 1080px) {
    padding: 2rem;
  }

  ${(props) => props.css || ""}
`;

export const JustifyBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 2rem 1rem 1rem;
  border-bottom: 1px solid #e2e8f0;
  ${(props) => props.css || ""}
`;

export const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 2rem;
  font-size: 18px;
  padding: 3rem 0;
  font-weight: 500;

  a {
    color: ${colors.blue};
    padding: 0.5rem 0;
  }

  @media screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 22px;
    line-height: 36px;
  }
`;

export const addInputStyle = {
  fontFamily: "Poppins",
  placeholder: "Apply coupon code",
  height: "54px",
  fontSize: "14px",
  background: "#F1F5F9",
  borderRadius: "31px",
  maxWidth: { base: "100%", md: "218px" },
  outline: "none",
  border: "none",
  _placeholder: {
    fontFamily: "Satoshi",
    fontSize: "14px",
    color: "#BDBDBD",
    textAlign: "center",
    // opacity: "0.9",
  },
  _focus: {
    background: "#F3F0FF",
  },
  _focusVisible: { border: "1px solid #AC98FE" },
};
