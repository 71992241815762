import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { colors, fontWeight } from "../../assets/styles/config";

const pulse = keyframes`
  0% {
    -webkit-transform: scale(0.98);
          box-shadow: 10px 0 30px 0 rgba(85, 73, 151, 0.78);

  }

  50% {
    -webkit-transform: scale(1);
          box-shadow: 0 0 50px 10px rgba(255, 255, 255, 0);

  }

  100% {
    -webkit-transform: scale(0.98);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);

  }
`;

const sploosh = keyframes`
 0% {
          box-shadow: 0 0 0 0 white;
        }
        70% {
          box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
`;

export const HeroWrapper = styled.div`
 background-color: #0f0931;
 color: white;
 padding-top: 161px;
 letter-spacing: 1.2px;
 padding-inline: 25px;
 padding-bottom: 100px;

 @media (max-width: 1080px) {
  padding-inline: 15px;
 }

 @media (max-width: 768px) {
  padding-inline: 12px;
 }

 @media (min-width: 768px) {
  padding-top: 201px;
  height: 1200px;
 }

 @media (min-width: 1080px) {
  padding-bottom: 0px;
  height: 1600px;
 }
`;

export const HeroGrid = styled.div`
 display: grid;
 scale: 0.26;
 height: 300px;

 @media (min-width: 768px) {
  scale: 0.5;
  justify-content: center;
  margin-top: -180px;
  align-items: center;
  height: auto;
 }

 @media (min-width: 900px) {
  scale: 0.65;
  justify-content: center;
  align-items: center;
  margin-top: -80px;
 }

 @media (min-width: 1080px) {
  margin-top: -60px;
  scale: 0.75;
 }

 @media (min-width: 1240px) {
  scale: 1;
  margin-top: 0;
 }

 @media (max-width: 768px) {
  margin-left: -210px;
  margin-top: -70px;
 }
`;

export const HeroHeading = styled.h1`
 font-weight: 700;
 font-size: 27px;
 text-align: center;
 line-height: 30.2px;
 letter-spacing: 1.5px;
 margin: 0px auto;
 max-width: 1073px;
 padding-inline: 25px;

 @media (min-width: 768px) {
  line-height: 52px;
  font-size: 55px;
 }

 @media (min-width: 1080px) {
  font-size: 70px;
  line-height: 88.2px;
 }
`;

export const HeroSubTitle = styled.p`
 font-size: 16px;
 text-align: center;
 margin: 0px auto;
 max-width: 922px;
 margin-top: 15px;
 color: #eef3f7;
 padding-inline: 25px;
 margin-bottom: 29px;

 @media (min-width: 768px) {
  font-size: 18px;
  margin-top: 8px;
 }

 @media (min-width: 1080px) {
  font-size: 20px;
  margin-top: 8px;
 }
`;

export const HeroImgPulseWrapper = styled.div`
 position: relative;
 display: grid;
 place-items: center;
 margin: 37px auto 0;
 // grid-template-columns: repeat(4, 1fr);
 // grid-template-rows: 150px 1fr;

 .image-layer {
  grid-area: 1/1/2/4;
  max-width: 72.5%;
 }

 .layer {
  grid-area: 1 / 1 / 2 / 2;
  border-radius: 20px;
  border-top-left-radius: 43px;
  border-top-right-radius: 43px;
 }
`;

export const HeroImg = styled.img`
 // width: 100%;
`;

export const MidHeading = styled.h1`
 font-size: 25px;
 font-weight: 700;
 line-height: 30px;
 letter-spacing: 0.01em;
 text-align: center;
 color: #0f0931;
 margin-top: 84px;
 margin-bottom: 15px;
 max-width: 1127px;
 margin-inline: auto;
 padding-inline: 25px;

 @media (min-width: 768px) {
  line-height: 36px;
  padding-inline: 25px;
  margin-top: 134px;
  font-size: 32px;
 }

 @media (min-width: 1080px) {
  line-height: 71px;
  font-size: 56px;
  padding-inline: 0;
 }
`;

export const MidSubHeading = styled.p`
 margin-inline: auto;
 color: #64748b;
 font-size: 16px;
 line-height: 20px;
 text-align: center;
 max-width: 975px;
 margin-bottom: 84px;
 padding-inline: 25px;

 @media (min-width: 768px) {
  font-size: 20px;
  line-height: 25px;
  padding-inline: 15px;
 }

 @media (min-width: 1080px) {
  line-height: 30px;
  font-size: 24px;
  margin-bottom: 134px;
  padding-inline: 0;
 }
`;

export const MidShowcaseWrapper = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 98px;

 @media (min-width: 768px) {
  gap: 121px;
 }
`;

export const MidShowcaseInfoWrapper = styled.div`
 display: flex;
 gap: 61px;
 padding: 0 143px;

 @media (min-width: 768px) and (max-width: 1240px) {
  padding: 0 100px;
  gap: 48px;
 }

 @media (max-width: 768px) {
  gap: 28px;
  padding: 0 35px;
  flex-direction: column;

  &:nth-of-type(2) {
   flex-direction: column-reverse;
  }
 }

 @media (min-width: 640px) and (max-width: 769px) {
  img {
   height: 300px;
   object-fit: contain;
   width: 100%;
  }
 }

 @media (min-width: 769px) and (max-width: 1080px) {
  img {
   min-width: 243px;
   height: 100%;
   object-fit: cover;
   border-radius: 21px;
  }
 }
`;

export const MidShowcaseTextWrapper = styled.div`
 display: flex;
 flex-direction: column;
 gap: 10px;
 // align-items: center;
 justify-content: center;

 @media (min-width: 768px) {
 }

 @media (min-width: 1080px) {
  gap: 16px;
  max-width: 469px;
 }
`;

export const MidShowcaseHeading = styled.h2`
 font-size: 18px;
 font-weight: 700;
 line-height: 30px;
 letter-spacing: 0.1em;
 // text-align: left;
 color: #0f0931;
 text-transform: uppercase;
 text-align: center;

 @media (min-width: 768px) {
  text-transform: initial;
  letter-spacing: 0.05em;
  text-align: left;
 }

 @media (min-width: 1240px) {
  line-height: 40px;
  font-size: 32px;
 }
`;

export const MidShowcaseText = styled.p`
 font-size: 16px;
 font-weight: 400;
 line-height: 22px;
 letter-spacing: 0.015em;
 color: #64748b;
 text-align: center;

 @media (min-width: 768px) {
  text-align: left;
 }

 @media (min-width: 1080px) {
  line-height: 24px;
  font-size: 18px;
 }

 @media (min-width: 1240px) {
  line-height: 26px;
  font-size: 18px;
 }
 ${(props) => props.css || ""}
`;

export const CalfHeading = styled.h1`
 font-size: 28px;
 font-weight: 700;
 line-height: 35.48px;
 letter-spacing: 0.01em;
 color: #0f0931;
 max-width: 850px;
 margin-bottom: 14px;
 margin-top: 79px;
 margin-left: 5%;
 padding-inline: 15px;

 @media (min-width: 768px) {
  font-size: 40px;
  margin-bottom: 16px;
  margin-top: 100px;
 }

 @media (min-width: 1080px) {
  font-size: 44px;
  line-height: 60.48px;
  margin-bottom: 24px;
  margin-bottom: 18px;
  margin-top: 139px;
 }

 @media (min-width: 1240px) {
  margin-top: 179px;
  max-width: 1127px;
  font-size: 48px;
 }
`;

export const CalfSubHeading = styled.p`
 color: #64748b;
 font-size: 17px;
 line-height: 26px;
 max-width: 850px;
 margin-bottom: 55px;
 margin-left: 5%;
 padding-inline: 15px;

 @media (min-width: 768px) {
  font-size: 22px;
  line-height: 28px;
 }

 @media (min-width: 1080px) {
  max-width: 900px;
 }

 @media (min-width: 1240px) {
  font-size: 24px;
  max-width: 975px;
  line-height: 30px;
 }
`;

export const CalfCardsWrapper = styled.div`
 padding: 0 32px 32px;
 margin-bottom: 33px;

 .splide__arrow svg {
  margin-top: 0 !important;
 }

 @media (min-width: 768px) {
  padding: 52px 0;
 }
`;

export const SingleCard = styled.div`
 flex-basis: 334px;
 border-radius: 16px;
 position: relative;
`;

export const SingleCardImg = styled.img`
 border-radius: 16px;
 height: 387px;
 width: 100%;
 object-fit: cover;
`;

export const SingleCardText = styled.div`
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 121px;
 background-color: rgba(0, 0, 0, 0.5);
 color: white;
 font-family: "Ubuntu", sans-serif;
 font-weight: 500;
 font-size: 32px;
 padding-top: 21px;
 padding-left: 17px;
 border-bottom-left-radius: 16px;
 border-bottom-right-radius: 16px;
 line-height: 32px;

 @media (min-width: 768px) {
  line-height: 37px;
 }
`;

export const BottomLikeHeroWrapper = styled.div`
 padding: 60px 0 90px;
 letter-spacing: 1.2px;
 margin: 0 auto;
 width: 90%;

 @media (min-width: 768px) {
  padding: 90px 0;
 }

 @media (min-width: 800px) {
  padding: 126px 0;
  height: 1511px;
 }

 @media (min-width: 1240px) {
  width: 1137px;
 }
`;

export const BottomHeading = styled.h1`
 font-weight: 700;
 font-size: 28px;
 text-align: center;
 letter-spacing: 1.5px;
 line-height: 32px;
 margin-bottom: 24px;
 padding-inline: 5px;
 // margin: 0px auto;
 // max-width: 1073px;

 @media (min-width: 768px) {
  line-height: 50px;
  font-size: 44px;
 }

 @media (min-width: 1080px) {
  line-height: 60.48px;
  font-size: 48px;
 }
`;

export const BottomSubTitle = styled.p`
 font-size: 16px;
 text-align: center;
 margin: 0px auto;
 max-width: 922px;
 margin-top: 8px;
 color: #64748b;
 margin-bottom: 29px;

 @media (min-width: 768px) {
  font-size: 18.5px;
 }

 @media (min-width: 1080px) {
  font-size: 20px;
 }
`;

export const BottomCards = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 margin-top: 68px;
 gap: 27px;

 @media (min-width: 800px) {
  flex-direction: row;
  height: fit-content;
 }
`;

export const BottomCard = styled.div`
 display: flex;
 flex-direction: column;
 border: 0.5px solid #632eff;
 padding: 37px 17.5px;
 border-radius: 20px;

 @media (min-width: 568px) {
  height: 693px;
  width: 420px;
 }

 @media (min-width: 800px) and (max-width: 1080px) {
  height: 750px;
 }

 @media (min-width: 1240px) {
  width: 500px;
 }
`;

export const BottomCardPricing = styled.div`
 height: 163px;
 border-bottom: 1px solid #e2e8f0;
 padding: 13px 24px;

 span {
  font-family: Creato Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #64748b;
 }

 p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #0f0931;
 }

 p.small {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15em;
  color: #64748b;
  text-transform: uppercase;
  margin-bottom: 5px;
 }

 p.small small {
  font-size: 15px;
  text-transform: initial;
  display: inline-block;
  color: ${colors.primaryPurple};
 }

 h3 {
  font-size: 46px;
  font-weight: 700;
  line-height: 58px;
  margin-top: 20px;
  color: #632eff;
  display: flex;
  gap: 7px;
  align-items: center;

  @media (min-width: 768px) {
   margin-top: 37px;
  }

  @media (min-width: 800px) and (max-width: 1080px) {
   margin-top: 20px;
  }
 }

 ${(props) => props.css || ""}
`;

export const BottomCardInfo = styled.div`
 display: flex;
 justify-content: space-between;
 padding: 30px 31px;
 flex-direction: column;

 h3 {
  color: #0f0931;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
 }

 @media (min-width: 1080px) {
  h3 {
   font-size: 24px;
  }
 }
`;

export const BottomCardInfoList = styled.ul`
 display: flex;
 flex-direction: column;
 gap: 16px;
 margin-top: 15px;
 // margin-top: 32px;

 li {
  display: flex;
  gap: 12px;
 }

 svg {
  min-width: 25px;
 }
`;
