import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 List,
 ListItem,
 SimpleGrid,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Switch,
 InputRightElement,
 InputGroup,
 Image,
 Input,
 InputLeftElement,
 Button,
} from "@chakra-ui/react";
import { searchInputStyle } from "../styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import { Button as CustomButton } from "../../../../components";
import styles from "./index.module.css";
import { colors } from "../../../../assets/styles/config";
import { AuthContext } from "../../../../context/AuthContext";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
} from "../../../../components";
import { Card, CloseBtn, Container } from "../styles";
import { db } from "../../../../firebase";
import OptionIcon from "../../../../assets/images/option.svg";
import Warning from "../../../../assets/images/warning-icon.svg";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { async } from "@firebase/util";
import { sendPushNotification } from "../../../../api/user";

const AdministratorRole = () => {
 const { user, addAdmin, updateAdministrator, deleteAdministrator } =
  useContext(AuthContext);
 const navigate = useNavigate();
 const toggle = useRef(null);
 const rolesDB = ref(db, "cityExperience/roles");
 const [loading, setLoading] = useState(false);
 const [loadingMembers, setLoadingMembers] = useState(false);
 const tableRef = useRef(null);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [isChecked, setisChecked] = useState(true);
 const [checkSelect, setCheckSelect] = useState("");
 const [modalType, setModalType] = useState("");
 const [search, setSearch] = useState("");
 const [selected, setSelected] = useState(null);
 const [roles, setRoles] = useState(null);
 const [selectedRole, setSelectedRole] = useState(null);
 const [team, setTeam] = useState([]);

 const filterObject = (obj, role) =>
  Object.keys(obj).reduce(
   (acc, val) =>
    obj[val]["role"] === role
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 const fetchMembers = async (role) => {
  setLoadingMembers(true);
  const db = await get(child(ref(getDatabase()), `users/admin`))
   .then((snapshot) => {
    setLoadingMembers(false);
    if (snapshot.exists()) {
     const myObj = filterObject(snapshot.val(), role);

     setTeam(
      Object.keys(myObj).map((key) => {
       return myObj[key];
      })
     );
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoadingMembers(false);
   });
  return db;
 };

 const fetchTeamMates = async (role) => {
  return await get(child(ref(getDatabase()), `users/admin`)).then(
   (snapshot) => {
    if (snapshot.exists()) {
     const myObj = filterObject(snapshot.val(), role);

     return Object.keys(myObj).map((key) => {
      return myObj[key];
     })?.length;
    }
   }
  );
 };

 const handleClickOutside = (event) => {
  //console.log("toggle===", toggle.current, event.target);
  // if (!toggle.current.contains(event.target)) {
  //   //   console.log("event===", event);
  //   // setSelected(null);
  // }
 };

 useEffect(() => {
  document.addEventListener("click", handleClickOutside);

  return () => {
   document.removeEventListener("click", handleClickOutside);
  };
 }, []);
 //   const roles = [
 //     {
 //       roleName: "Manager",
 //       type: "Default",
 //       createdBy: "Super Admin",
 //       teamMates: "1",
 //     },
 //     {
 //       roleName: "Admin",
 //       type: "Default",
 //       createdBy: "Admin",
 //       teamMates: "3",
 //     },
 //     {
 //       roleName: "Creator",
 //       type: "Default",
 //       createdBy: "Manager",
 //       teamMates: "3",
 //     },
 //     {
 //       roleName: "Manager",
 //       type: "Default",
 //       createdBy: "Super Admin",
 //       teamMates: "1",
 //     },
 //   ];

 function mapRevers(reverse) {
  let reversed = reverse.map(
   (num, index, reverse) => reverse[reverse.length - 1 - index]
  );
  return reversed;
 }

 useEffect(() => {
  setLoading(true);
  const unsubscribe = onValue(rolesDB, async (snapshot) => {
   const dbRoles = snapshot.val();
   for (let i in dbRoles) {
    const member = await fetchTeamMates(dbRoles[i]?.role);
    dbRoles[i]["member"] = member;
    setRoles(dbRoles);
   }
   setRoles(dbRoles);
   if (dbRoles?.length > 0) {
    setLoading(false);
   }
  });
  return () => {
   unsubscribe();
  };
 }, []);

 // useEffect(() => {
 //   async function fetch() {
 //     if (roles) {
 //       for (let i in roles) {
 //         const member = await fetchTeamMates(roles[i]?.role);
 //         roles[i]["member"] = member;
 //         setRoles(roles);
 //       }
 //     }
 //   }

 //   fetch();
 // }, [roles]);

 //   const team = [
 //     "Nishita Devanad",
 //     "Fernando Sucre",
 //     "Fernando Sucre",
 //     "Temitope",
 //     "Doris Ngaju",
 //   ];

 const searchFilter = (text) => {
  setSearch(text);
 };

 const handleDelete = async () => {
  toast.loading("loading");
  const db = getDatabase();
  const updates = {};
  updates["cityExperience/roles"] = roles?.filter(
   (item) => item.role !== selectedRole
  );

  await update(ref(db), updates)
   .then(async () => {
    toast.dismiss();
    toast.success("Successfully deleted experience");

    if (user) {
     await sendPushNotification({
      title: "admin",
      body: `${user?.name} deleted ${selectedRole?.toLowerCase()} role`,
      data: {
       name: user?.name,
       photoURL: user?.photoURL,
       push_time: Date.now(),
      },
     });
    }
    setVisible(!visible);
    setModalType("");
    setSelectedRole(null);
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 return (
  <Box height="100%" padding="20px" background="#F5F8FA">
   <Box
    background="#FFFFFF"
    borderRadius="6px"
    // mt="30px"
    minHeight="80vh"
    padding={{ base: "20px", md: "30px" }}
   >
    {/* <Text
          color="#334155"
          fontSize={{ base: "18px", md: "20px" }}
          lineHeight="24px"
          fontWeight="700"
          marginBottom="20px"
        >
          
        </Text> */}
    <Flex
     alignItems="flex-start"
     flexDir={"row"}
     justifyContent="space-between"
    >
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>

     <Box display={{ base: "none", md: "flex" }} alignItems="center">
      <CustomButton
       animateOnTap
       animateOnHover
       buttonColor="#632EFF"
       css={css`
        width: fit-content;
        padding: 0.5rem 2rem;
        height: auto;
        font-weight: 500;
        font-family: "Creato Display";
        display: flex;
        gap: 15px;

        @media screen and (max-width: 768px) {
         border-radius: 10px;
        }
       `}
       onClick={() => {
        navigate("/admin/roles/add");
        // setVisible(!visible);
        // setModalType("add");
       }}
      >
       <AddIcon h="16px" w="16px" /> Add new role
      </CustomButton>
     </Box>
     <Box display={{ base: "flex", md: "none" }} alignItems="center">
      <CustomButton
       animateOnTap
       animateOnHover
       buttonColor="#632EFF"
       css={css`
        height: auto;
        font-weight: 500;
        font-family: "Creato Display";
        border-radius: 100px;
       `}
       onClick={() => {
        navigate("/admin/roles/add");
       }}
      >
       <AddIcon h="16px" w="16px" />
      </CustomButton>
     </Box>
    </Flex>
    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt="30px">
      <TableContainer
       border="1px solid #E2E8F0"
       borderRadius="16px 16px 0px 0px"
       //  overflowX="initial"
       //  overflowY="visible"
      >
       <Table variant="simple" ref={tableRef}>
        <Thead>
         <Tr>
          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          >
           Role Name
          </Th>
          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          >
           Type
          </Th>

          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          >
           Created by
          </Th>
          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          >
           Team mates
          </Th>

          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
          ></Th>
         </Tr>
        </Thead>
        <Tbody>
         {roles &&
          mapRevers(roles)?.map((item, index) => (
           <Tr key={index} overflowX="initial">
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color="#334155"
             cursor="pointer"
            >
             {item?.role}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color="#334155"
            >
             {item?.type}
            </Td>

            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color="#334155"
            >
             {item?.createdBy}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color="#334155"
            >
             {item?.member}
            </Td>

            <Td
             padding="14px 20px"
             display="flex"
             alignItems="center"
             gap="20px"
             position="relative"
             ref={toggle}
            >
             <Box
              cursor="pointer"
              onClick={() => {
               setSelected(selected === index ? null : index);
              }}
             >
              <Image src={OptionIcon} />
             </Box>
             {selected === index && (
              <List
               position="absolute"
               top="3rem"
               right="50px"
               padding="10px 20px"
               background="#FFFFFF"
               boxShadow="0px 4px 24px #D4DEEC"
               border="12px"
               zIndex="20"
               cursor="pointer"
               borderRadius="12px"
               width="200px"
              >
               <ListItem
                borderBottom="1px solid #E2E8F0"
                padding="10px 0px"
                onClick={() => navigate(`/admin/roles/edit/${item.role}`)}
               >
                Edit Roles
               </ListItem>
               <ListItem
                borderBottom="1px solid #E2E8F0"
                padding="10px 0px"
                onClick={() => {
                 setVisible(!visible);
                 setModalType("view");
                 setSelected(null);
                 fetchMembers(item.role);
                }}
               >
                View Team Mates
               </ListItem>
               <ListItem
                //   borderBottom="1px solid #E2E8F0"
                padding="10px 0px"
                onClick={() => {
                 setVisible(!visible);
                 setModalType("delete");
                 setSelected(null);
                 setSelectedRole(item.role);
                }}
               >
                Delete Role
               </ListItem>
              </List>
             )}
            </Td>
           </Tr>
          ))}
        </Tbody>
       </Table>
      </TableContainer>
     </Box>
    )}
   </Box>
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
     //   style={{ marginTop: modalType === "add" && "300px" }}
    >
     {modalType === "view" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "456px", height: "100%", padding: "2rem" }}
      >
       <Box
        // borderBottom="1px solid #B8B8B8"

        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
       >
        <Text
         fontFamily="Satoshi"
         color="#0F0931"
         fontSize={{ base: "14px", md: "20px", lg: "32px" }}
         lineHeight="125%"
         fontWeight="700"
        >
         Team Mates
        </Text>
        <Box style={{ cursor: "pointer" }} onClick={() => setVisible(false)}>
         <AiOutlineCloseCircle fontSize="30px" />
        </Box>
       </Box>
       <Text
        fontFamily="Satoshi"
        color="#252046"
        fontSize="16px"
        fontWeight="700"
        marginY="20px"
       >
        {team?.length} Team Mates
       </Text>
       <List>
        {team?.map((item, index) => (
         <ListItem
          key={index}
          borderBottom="1px solid #E2E8F0"
          padding="10px 0px"
         >
          {item?.name}
         </ListItem>
        ))}
       </List>
      </Card>
     )}
     {modalType === "delete" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Role?
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you want to delete this role?
        </Text>
        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setModalType("");
           setSelectedRole(null);
          }}
         >
          Close
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default AdministratorRole;
