import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, fontWeight } from "../../assets/styles/config";
import { collectionBg } from "../../assets/strings/imageExports";

export const Wrapper = styled.div``;

export const Container = styled.div`
 padding: 1rem;
 padding-top: 0;

 @media (min-width: 768px) {
  padding: 0 2.5rem 2rem;
 }

 @media (min-width: 62em) {
  padding-inline: 60px;
 }

 @media (min-width: 1080px) {
  padding: 0 4rem 3rem;
  // margin-top: 100px;
 }
`;

export const ExpImgWrapper = styled.div`
 background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
  url(${collectionBg}) no-repeat;

 height: 350px;
 background-size: cover;
 position: relative;

 @media (min-width: 568px) {
  background-position: 0 -100px;
 }

 @media (min-width: 574px) {
  background-position: 0 -20px;
 }

 @media (min-width: 660px) {
  background-position: 0 -80px;
 }

 @media (min-width: 768px) {
  height: 520px;
  background-position: 0 0px;
 }

 @media (min-width: 930px) {
  background-position: 0 -100px;
 }

 @media (min-width: 1080px) {
  width: 100vw;
  height: 601px;
  background-position: 0 -120px;
 }

 @media (min-width: 1240px) {
  width: 100vw;
  height: 555px;
 }

 @media (min-width: 1340px) {
  background-position: 0 -300px;
 }
`;

export const BigImageTitle = styled.p`
 font-size: 40px;
 font-weight: 700;
 line-height: 50px;
 color: white;
 margin: 0;
 letter-spacing: 0.02em;
 padding-top: 50%;
 padding-left: 0.5em;
 text-transform: capitalize;

 @media (min-width: 460px) {
  padding-top: 200px;
 }

 @media (min-width: 768px) {
  font-size: 50px;
  line-height: 58px;
  padding-top: 300px;
  letter-spacing: 3px;
 }

 @media (min-width: 1080px) {
  font-size: 60px;
  line-height: 68px;
  letter-spacing: 0.03em;
  padding-left: 1em;
 }

 @media (min-width: 62em) {
  padding-inline: 60px;
 }

 @media (min-width: 1240px) {
  font-size: 70px;
  line-height: 91.6px;
 }

 @media (min-width: 1440px) {
  padding-top: 320px;
 }
`;

export const TitleSection = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 gap: 1rem;
`;

export const Title = styled.h1`
 font-size: 25px;
 font-weight: ${fontWeight.semiBold};
 color: ${colors.darkBlue};
 text-transform: capitalize;

 @media (min-width: 768px) {
  font-size: 33px;
  line-height: 45px;
 }

 @media (min-width: 1080px) {
  font-size: 50px;
  line-height: 75px;
 }
`;

export const Heading = styled.h1`
 display: flex;
 flex-direction: column;
 gap: 1rem;

 @media (min-width: 768px) {
  font-size: 33px;
  line-height: 45px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
 }

 ${(props) => props.css || ""}
`;

export const SlideWrapper = styled.div`
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
 margin-block: 2rem;
 margin-top: 46px;
 gap: 14px;

 @media (min-width: 768px) {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 24px;
 }

 @media (min-width: 1080px) {
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  margin-top: 50px;
 }

 @media (min-width: 1540px) {
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
 }
`;

export const SplideSlideWrapper = styled.div`
 position: relative;
 // width: 355px;
 height: 150px;
 cursor: pointer;
 transition: ease-in 0.2s;
 border-radius: 20px;
 // flex: 1 1 355px;
 border: 0.5px solid rgb(189, 189, 189);
 padding: 0.5rem;

 .img-bg {
  background: rgba(0, 0, 0, 0.302);
  border-radius: 17px;
  height: 100%;
  width: 100%;
 }

 @media (min-width: 1080px) {
  &:hover {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
   transform: translateY(-15px);
  }
 }

 @media (min-width: 768px) and (max-width: 992px) {
  // width: 250px;
  height: 150px;
 }
`;

export const SlideImg = styled.img`
 width: 100%;
 height: 100%;
 object-fit: cover;
 border-radius: 17px;
 mix-blend-mode: multiply;
`;

export const SlideText = styled.p`
 position: absolute;
 font-size: 16px;
 line-height: 24px;
 color: ${colors.primaryWhite};
 letter-spacing: -0.0041em;
 bottom: 20px;
 left: 20px;
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 text-transform: capitalize;

 @media (min-width: 768px) {
  font-size: 18px;
 }

 @media {
  font-size: 20px;
 }
`;

export const ExpandBtn = styled.button`
 display: flex;
 align-items: center;
 justify-content: center;
 text-align: center;
 font-weight: ${fontWeight.medium};
 color: ${colors.blue};
 font-size: 18px;
 line-height: 33px;
 text-transform: capitalize;
 transition: 0.2s ease;
 width: fit-content;

 &:hover {
  scale: 0.95;
  opacity: 0.8;
 }

 @media (min-width: 768px) {
  font-size: 20px;
  line-height: 33px;
 }

 @media (min-width: 1080px) {
  font-size: 22px;
  line-height: 33px;
 }

 ${(props) => props.css || ""}
`;

export const FaintBrownText = styled.p`
 font-style: normal;
 font-weight: 400;
 font-size: 20px;
 line-height: 22px;
 color: ${colors.faintBrown};

 @media (min-width: 768px) {
  font-size: 22px;
  line-height: 28px;
 }
 @media (min-width: 1080px) {
  font-size: 24px;
  line-height: 36px;
 }
`;

export const TagButtons = styled.div`
 display: flex;
 gap: 8px;
 height: 65px;

 @media (max-width: 840px) {
  max-width: 100%;
 }

 @media (min-width: 768px) {
  height: auto;

  ::-webkit-scrollbar {
   display: none;
  }
 }

 @media (min-width: 1080px) {
  gap: 16px;
 }

 @media (max-width: 768px) {
  overflow: scroll;
 }
`;

export const TagButton = styled(motion.button)`
 font-size: 13px;
 line-height: 14px;
 letter-spacing: 0.05em;
 color: ${(props) => (props.selected ? colors.primaryWhite : "#252046")};
 background-color: ${(props) => (props.selected ? "#632EFF" : "#F1F5F9")};
 border: ${(props) =>
  props.selected ? "1px solid #632EFF" : "1px solid #E2E8F0"};
 transition: 0.3s;
 padding: 13.5px 16px;
 width: max-content;
 min-width: max-content;
 border-radius: 34px;
 scroll-behavior: smooth;
 max-height: 48px;

 &:hover {
  border: 1px solid #632eff;
  background-color: #f1ecff;
  color: #252046;
 }

 @media (min-width: 768px) {
  font-size: 14px;
  line-height: 14px;
 }

 @media (min-width: 1080px) {
  font-size: 15px;
  line-height: 16px;
 }

 @media (min-width: 1080px) {
  font-size: 16px;
 }

 @media (min-width: 3340px) {
  min-width: 87px;
 }

 ${(props) => props.css || ""}
`;

export const SearchLabel = styled.label`
 position: relative;
 display: flex;
 align-items: center;

 .icon {
  position: absolute;
  left: 15px;
  margin-top: 14px;
 }

 @media (min-width: 768px) {
  .icon {
   top: 0.5rem;
  }
 }

 @media (max-width: 500px) {
  width: 100%;
 }
`;

export const SearchInput = styled.input`
 background: ${colors.skyBlue};
 border-radius: 10px;
 width: 100%;
 height: 38px;
 font-size: 14px;
 padding: 10px 12px 10px 40px;
 color: ${colors.brown};
 margin-top: 20px;

 @media (min-width: 768px) {
  font-size: 15.5px;
  width: 280px;
  border-radius: 20px;
 }

 @media (min-width: 1080px) {
  font-size: 17px;
  height: 42px;
 }

 &:focus {
  outline: ${colors.activeBorder};
  background: ${colors.activeInputBg};
 }

 &::placeholder {
  color: ${colors.placeholderBrown};
 }

 &:-ms-input-placeholder {
  color: ${colors.placeholderBrown};
 }

 &::-ms-input-placeholder {
  color: ${colors.placeholderBrown};
 }
`;

export const NoCollections = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
 min-width: 300px;
 min-height: 300px;
 font-size: 18px;
 font-weight: ${fontWeight.medium};
 color: ${colors.brown};
 line-height: 24px;

 @media (min-width: 768px) {
  font-size: 22px;
  line-height: 28px;
 }
 @media (min-width: 1080px) {
  font-size: 24px;
  line-height: 30px;
 }
`;
