import React from 'react';
import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Img,
  HStack,
} from '@chakra-ui/react';
import { smallLogo as Logo } from '../../../assets/strings/imageExports';
import { AccordionAnswer, AccordionQuestion, Heading } from './styles';
import { faq } from '../../../assets/strings/data';

const FAQ = () => {
  return (
    <Box
      mt={{ base: '70px', md: '100px', lg: '140px' }}
      p={{ base: '0 2rem 2rem', lg: '0 4rem 4rem' }}
    >
      <Heading>Frequently Asked Questions</Heading>
      <Accordion w='100%' m='20px 0' allowToggle>
        {faq.map((faq, index) => {
          return (
            <AccordionItem
              m='20px 0'
              bg='#ffffff'
              boxShadow='0px 4px 32px rgba(0, 0, 0, 0.1);
              
            '
              borderRadius={{ base: 6, md: 10 }}
              key={index}
            >
              <h2>
                <AccordionButton
                  p={{ base: 3, md: 5 }}
                  _hover={{ bg: '#EBF7FF' }}
                  // borderTopRadius={10}
                >
                  <HStack
                    spacing={{ base: 4, md: 6 }}
                    flex='1'
                    textAlign='left'
                  >
                    <Img src={Logo} objectFit='contain' />
                    <AccordionQuestion>{faq.title}</AccordionQuestion>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <AccordionAnswer>{faq.content}</AccordionAnswer>{' '}
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Box>
  );
};

export default FAQ;
