import { Box, Image, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import {
  RBEHeading,
  RBEHeadings,
  RBEInfo,
  RBESection,
  RBEWrapper,
} from "./styles";
import ArrowLeft from "../../../assets/images/arrowleft2.svg";
import { colors } from "../../../assets/styles/config";

const RecentlyBoughtExperiences = ({ experiences }) => {
  return (
    <div>
      <Box
        overflowX={"auto"}
        background="#fff"
        width="100%"
        border="1px solid #e2e8f0"
        borderRadius="16px"
        display={{ base: "none", md: "block" }}
      >
        <RBEHeading>Recently bought experiences</RBEHeading>
        <RBEWrapper>
          <thead>
            <RBEHeadings>
              <td>Date</td>
              <td>Experience Bought</td>
              <td>Amount</td>
              <td>Experience Date</td>
            </RBEHeadings>
          </thead>
          <RBESection>
            {experiences?.slice(0, 8).map((experience) => {
              // var timestamp = dayjs(
              //   new Date(experience?.timestamp.replace(/['"]+/g, ""))
              // ).format("YYYY-MM-DD");
              // var timestampHr = dayjs(
              //   new Date(experience?.timestamp.replace(/['"]+/g, ""))
              // ).format("ha");
              return (
                <>
                  <RBEInfo>
                    <td>
                      {dayjs(experience.transationDate).format("MMMM D YYYY")}
                    </td>
                    <td>{experience.experienceName}</td>
                    <td>{`NGN ${experience.total}`}</td>
                    <td>{experience.timeChosen}</td>
                  </RBEInfo>
                </>
              );
            })}
          </RBESection>
        </RBEWrapper>
      </Box>

      <Box display={{ base: "block", md: "none" }}>
        <RBEHeading>Recently bought experiences</RBEHeading>
        <Box
          overflowX={"auto"}
          background="#fff"
          width="100%"
          border="1px solid #e2e8f0"
          borderRadius="16px"
          marginTop="20px"
          padding="0px 10px"
        >
          {experiences.slice(0, 8)?.map((experience, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding="15px 10px"
              borderBottom={
                experiences.slice(0, 8)?.length - 1 !== index &&
                "1px solid #E2E8F0"
              }
              cursor="pointer"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Text
                  color={colors.darkBlue}
                  fontWeight="500"
                  lineHeight="20px"
                  marginBottom="10px"
                >
                  {experience.title}
                </Text>
                <Box display="flex" alignItems="center" gap="10px">
                  <Text
                    color={colors.darkBlue}
                    fontWeight="400"
                    lineHeight="15px"
                  >
                    {experience.date}
                  </Text>{" "}
                  |{" "}
                  <Text
                    color={colors.darkBlue}
                    fontWeight="400"
                    lineHeight="15px"
                  >{`NGN ${experience.total}`}</Text>
                </Box>
              </Box>
              <Image src={ArrowLeft} alt="arrow-left" />
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default RecentlyBoughtExperiences;
