import React, { useEffect, useRef } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  List,
  ListItem,
  ListIcon,
  Flex,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import deal from "../../assets/images/deal.png";
import call from "../../assets/images/Call.svg";
import location from "../../assets/images/Location.svg";
import www from "../../assets/images/iconoir_www.svg";
import check from "../../assets/images/tabler_discount-check.svg";
import tag from "../../assets/images/tag.svg";
import delivery from "../../assets/images/carbon_delivery.svg";
import styles from "./index.module.css";
import Navbar from "../Home/Navbar";
import { dealsDeal } from "../../mocks/index";
import { Footer, Button } from "../../components";
import { Header } from "../../components";
import { MdCheckCircle } from "react-icons/md";
import { css } from "styled-components";

const Prices = () => {
  const priceItem = [
    {
      card: check,
      title: "Standard Card",
      price: "20.00",
      bg: "linear-gradient(179.3deg, #0F0931 21.1%, #632EFF 99.4%)",
      bg1: "#F1EDFD",
      color: "linear-gradient(180deg, #632EFF 29.53%, #FF8BFF 100%)",
      content: [
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
      ],
    },
    {
      card: tag,
      title: "Standard Card",
      price: "20.00",
      bg: "linear-gradient(164.13deg, #622EFD 19.87%, #FF8BFF 111.5%)",
      color: "linear-gradient(180deg, #632FFE 33.61%, #FF8BFF 100%)",
      bg1: "#FFEBFF",
      content: [
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
      ],
    },
    {
      card: delivery,
      title: "Standard Card",
      price: "20.00",
      bg: "linear-gradient(180deg, #632EFF 28.65%, #25AEFC 79.64%)",
      color: "linear-gradient(180deg, #632FFE 44.79%, #25AEFC 100%)",
      bg1: "#EBF7FF",
      content: [
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
        "Lorem Ipsum blah blah",
      ],
    },
  ];

  return (
    <>
      <Header bgColor={"#FFFFFF"} color="dark" />

      <Box
        mt="70px"
        p={{ base: "2rem", md: "4rem" }}
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box mt="30px" display="flex" flexDir="column">
          <Text
            color="#000000"
            fontSize={{ base: "20px", md: "30px", lg: "38px" }}
            lineHeight="28px"
            fontWeight="600"
            textAlign="center"
          >
            Choose Your Plan
          </Text>
          <Text
            color="#000000"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="24px"
            fontWeight="400"
            textAlign="center"
            mt="10px"
          >
            Choose a plan to enjoy our Deals and Experiences
          </Text>
        </Box>
        <SimpleGrid
          mt={{ base: "50px", md: "100px" }}
          columns={{ base: 1, md: 2, xl: 3 }}
          spacing={{ base: 10 }}
          alignItems="start"
          padding={{ base: "0", xl: "0 100px" }}
          width="100%"
        >
          {priceItem.map((item, id) => (
            <Box
              key={id}
              background={item.bg}
              borderRadius="20px"
              padding="20px"
              width={{ md: "100%", lg: "354px" }}
              height={{ base: "390px", md: "439px" }}
              pos="relative"
              className={styles.price}
              mt={{ base: "40px", xl: "0px" }}
            >
              <Box
                pos="absolute"
                top="-50"
                left="50%"
                transform="translateX(-50%)"
                display="flex"
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                padding="20px"
                width="210px"
                borderRadius="22px"
                background={item.bg1}
              >
                <Text
                  fontSize={{ base: "20px", md: "22px" }}
                  lineHeight="36px"
                  fontWeight="500"
                >
                  {item.title}
                </Text>
                <Text
                  color={item.bg}
                  fontSize={{ base: "20px", md: "22px", lg: "24px" }}
                  lineHeight="36px"
                  fontWeight="600"
                >
                  ${item.price}
                </Text>
              </Box>

              <Box
                mt={{ base: "60px", md: "100px" }}
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                width="100%"
              >
                <List>
                  {item.content.map((itm, idx) => (
                    <ListItem key={idx} color="#FFFFFF" mb="10px">
                      <ListIcon as={MdCheckCircle} color="white" />
                      {itm}
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Flex
                mt="2rem"
                justifyContent={"center"}
                w="100%"
                alignItems={"center"}
                padding="0 2rem"
              >
                <Button
                  title={"Buy Now"}
                  css={css`
                    border: 2px solid #ffffff;
                    background-color: transparent;
                    @media screen and (max-width: 768px) {
                      border-radius: 12px;
                    }
                  `}
                />
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Box>

      <Footer />
    </>
  );
};

export default Prices;
