import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Subscribed = () => {
 return (
  <div
   style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "35px",
    fontWeight: "500",
    height: "100vh",
   }}
  >
   <p
    style={{
     fontSize: "85px",
    }}
   >
    Subscribe today!
   </p>
   To access more features ⭐️.
   <motion.button
    style={{
     background: "#632EFF",
     color: "white",
     padding: ".5rem 2rem",
     fontSize: "20px",
     borderRadius: "6px",
     marginBlock: 12,
    }}
    whileHover={{
     opacity: "0.75",
     boxShadow: "0px 0px 4px gray",
    }}
    whileTap={{ scale: 0.95 }}
   >
    <a href="mailto:hello@thenaviapp.com">Contact Us</a>
   </motion.button>
  </div>
 );
};

export default Subscribed;
