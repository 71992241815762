import React, { useEffect, useMemo, useRef, useState } from "react";
import { db } from "../../../../firebase";
import { onValue, ref } from "firebase/database";
import { Button, Icon, Loader, Modal, Toast } from "../../../../components";
import {
 Container,
 SearchInput,
 SearchLabel,
 CategoryHeader,
 CategoriesTable,
 JustifyBetween,
 ActionsWrapper,
 EditIcon,
 DeleteIcon,
 SwitchIcon,
 Card,
 CardHeader,
 CardInBetween,
 Wrapper,
 addInputStyle,
 addInputFlexStyle,
 addInputTextStyle,
 searchInputStyle,
} from "./styles";
import {
 Box,
 CloseButton,
 Flex,
 IconButton,
 Img,
 Input,
 InputGroup,
 InputLeftElement,
 Switch,
 Text,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { colors } from "../../../../assets/styles/config";
import { set } from "firebase/database";
import { AnimatePresence } from "framer-motion";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { AddIcon } from "@chakra-ui/icons";
import TrashIcon from "../../../../assets/images/svg/trash-2-icon.svg";
import { AiOutlineEdit } from "react-icons/ai";
import CloseIconOutline from "../../../../assets/images/svg/close-outline-icon.svg";
import UnnecessaryWarningImage from "../../../../assets/images/warning-icon.svg";

// CONSTANTS
const ACTIONS = {
 ADD_TAG: "ADD TAG",
 EDIT_TAG: "EDIT TAG",
 DELETE_TAG: "DELETE TAG?",
};
const DEFAULT_STATE = { status: false, action: null };
const TOAST_STATE = {
 state: false,
 text: "",
 bgColor: "lightgreen",
 textColor: colors.primaryWhite,
};
const tagsDB = ref(db, "cityExperience/tags");

const Tags = () => {
 const [filteredTagList, setFilteredTagList] = useState([]);
 const [tagList, setTagList] = useState([]);
 const [toast, setToast] = React.useState(TOAST_STATE);
 const [state, setState] = useState(DEFAULT_STATE);
 const [loading, setLoading] = useState(false);
 const [visible, setVisible] = useState(false);
 const [chosenTag, setChosenTag] = useState("");
 const [value, setValue] = React.useState("");
 const containerRef = useRef(null);

 //   FUNCTIONS
 const handleChange = (event) => setValue(event.target.value);

 const handleSearchInput = (searchValue) => {
  const hasSearchValue = tagList.filter((item) =>
   item.toLowerCase().includes(searchValue.toLowerCase())
  );
  setFilteredTagList(hasSearchValue);
 };

 const reset = () => {
  setVisible(false);
  setState(DEFAULT_STATE);
  setChosenTag("");
  setValue("");
 };

 const setActionType = (type, tag) => {
  setState({ ...state, action: type });
  setVisible(true);
  setChosenTag(tag);
 };

 const deleteTag = (item) => {
  const newList = tagList.filter(
   (tag) => tag.toLowerCase() !== item.toLowerCase()
  );
  reset();
  setTagList(newList);
  setToast({
   ...toast,
   state: true,
   text: "Item Deleted Successfully",
   bgColor: "lightgreen",
   textColor: colors.primaryWhite,
  });
  updateFirebaseDb(newList);
 };

 const createTag = (item) => {
  const itemExists = tagList.find(
   (tag) => tag.toLowerCase() === item.toLowerCase()
  );
  if (!item) {
   setToast({
    ...toast,
    state: true,
    text: "Input is empty",
    bgColor: colors.pastelRed,
    textColor: colors.primaryWhite,
   });
   return;
  }
  if (itemExists) {
   setToast({
    ...toast,
    state: true,
    text: "Tag already exists",
    bgColor: colors.pastelRed,
    textColor: colors.primaryWhite,
   });
   return;
  }
  const newItem =
   item.slice(0, 1).toUpperCase() + item.slice(1, item.length).toLowerCase();
  const newList = [...tagList, newItem].sort();
  reset();
  setTagList(newList);
  setToast({
   ...toast,
   state: true,
   text: "Item Added Successfully",
   bgColor: "lightgreen",
   textColor: colors.primaryWhite,
  });
  updateFirebaseDb(newList);
 };

 const updateTag = () => {
  if (!value) {
   setToast({
    ...toast,
    state: true,
    text: "Input is empty",
    bgColor: colors.pastelRed,
    textColor: colors.primaryWhite,
   });
   return;
  }
  const newItem =
   value.slice(0, 1).toUpperCase() + value.slice(1, value.length).toLowerCase();
  const newList = tagList.map((item) =>
   item.toLowerCase() === chosenTag.toLowerCase() ? newItem : item
  );
  setTagList(newList);
  reset();
  setToast({
   ...toast,
   state: true,
   text: "Item Updated Successfully",
   bgColor: "lightgreen",
   textColor: colors.primaryWhite,
  });
  updateFirebaseDb(newList);
 };

 const updateFirebaseDb = (newList) => {
  set(tagsDB, newList);
 };

 //   EFFECTS
 useEffect(() => {
  setLoading(true);
  const unsubscribe = onValue(tagsDB, (snapshot) => {
   const tags = snapshot.val();
   setTagList(tags);
   if (tags?.length > 0) {
    setLoading(false);
   }
  });
  return () => {
   unsubscribe();
  };
 }, []);

 useEffect(() => {
  setFilteredTagList(tagList);
 }, [tagList]);

 //   JSX
 const renderSearchAndAddTagArea = useMemo(
  () => (
   <>
    <Box display="flex" justifyContent="space-between" marginBottom={"54px"}>
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => handleSearchInput(e.target.value)}
      />
     </InputGroup>

     <Button
      animateOnTap
      animateOnHover
      buttonColor="#252046"
      css={css`
       width: fit-content;
       padding: 0.5rem 2rem;
       font-weight: 500;
       font-family: "Creato Display";
       display: flex;
       gap: 15px;

       @media screen and (max-width: 768px) {
        border-radius: 100px;
       }
      `}
      onClick={() => setActionType(ACTIONS.ADD_TAG, "")}
     >
      <AddIcon />
      <Text display={{ base: "none", md: "inline" }}>Add Tag</Text>
     </Button>
    </Box>
   </>
  ),
  [tagList, filteredTagList, handleSearchInput]
 );

 const renderTags = useMemo(
  () => (
   <CategoriesTable>
    {filteredTagList?.length > 0 &&
     filteredTagList?.map((tag) => (
      <JustifyBetween key={tag}>
       <p>{tag}</p>
       <ActionsWrapper>
        <EditIcon
         whileTap={{ scale: 0.7 }}
         whileHover={{ scale: 1.09 }}
         onClick={() => {
          setValue(tag);
          setActionType(ACTIONS.EDIT_TAG, tag);
         }}
        >
         <AiOutlineEdit />
        </EditIcon>

        {/* <SwitchIcon>
                  <Switch size={{ base: 'sm', lg: 'md' }} isChecked />
                </SwitchIcon> */}
        <DeleteIcon
         whileTap={{ scale: 0.7 }}
         whileHover={{ scale: 1.09 }}
         onClick={() => {
          setActionType(ACTIONS.DELETE_TAG, tag);
         }}
        >
         <Img src={TrashIcon} />
        </DeleteIcon>
       </ActionsWrapper>{" "}
      </JustifyBetween>
     ))}
   </CategoriesTable>
  ),
  [loading, filteredTagList]
 );

 const renderAddEditInput = useMemo(
  () => (
   <Flex {...addInputFlexStyle}>
    <Text {...addInputTextStyle}>Name</Text>
    <Input {...addInputStyle} value={value} onChange={handleChange} />
   </Flex>
  ),
  [value, setValue, handleChange]
 );

 const renderModal = useMemo(
  () => (
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.5,
    }}
   >
    <AnimatePresence>
     <Card
      initial={{
       scale: 0,
       translateX: 350,
       translateY: 350,
      }}
      animate={{ scale: 1, translateX: 0, translateY: 0 }}
      exit={{ opacity: 0, scale: 0, translateX: 350, translateY: 350 }}
      transition={{
       type: "spring",
       stiffness: 160,
       damping: 20,
      }}
      ref={containerRef}
     >
      {state.action === ACTIONS.DELETE_TAG ? (
       <>
        <CardHeader
         css={css`
          align-items: center;
          flex-direction: column;
          gap: 31px;
         `}
        >
         <img src={UnnecessaryWarningImage}></img>
         Delete Tag?
        </CardHeader>
        <CardInBetween
         css={css`
          padding-block: 11px 31px;
          max-width: 237px;
          margin: auto;
         `}
        >
         Are you sure you want to delete this tag?
        </CardInBetween>
        {/* THE BUTTONS */}
        <Flex
         justifyContent={"center"}
         gap="9px"
         alignItems="center"
         paddingBottom="32px"
         flexDirection="column-reverse"
        >
         <Button
          animateOnTap
          animateOnHover
          css={css`
           background: white;
           color: #252046;
           font-family: "Creato Display";
           font-style: normal;
           font-weight: 700;
           height: auto;
           border: 1px solid #252046;
          `}
          onClick={reset}
         >
          Cancel
         </Button>
         <Button
          animateOnTap
          animateOnHover
          css={css`
           background: ${colors.primaryPurple};
           font-weight: 500;
           transition: 250ms;
           margin-bottom: 0px;
           height: auto;

           &:hover {
            opacity: 0.8;
           }
          `}
          onClick={() => {
           deleteTag(chosenTag);
          }}
         >
          Yes, Delete
         </Button>
        </Flex>
       </>
      ) : (
       <>
        {/* THE HEADER */}
        <CardHeader>
         {state?.action?.charAt(0) + state?.action?.substring(1)?.toLowerCase()}{" "}
         <img
          src={CloseIconOutline}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={reset}
         />
        </CardHeader>
        {/* THE IN-BETWEEN */}

        {state.action === ACTIONS.DELETE_TAG ? (
         <CardInBetween>
          {" "}
          {"Are you sure you would like to delete this tag?"}{" "}
         </CardInBetween>
        ) : (
         renderAddEditInput
        )}

        {/* THE BUTTONS */}
        <Flex
         justifyContent={"center"}
         gap="20px"
         alignItems="center"
         // paddingTop="20px"
         paddingBottom="40px"
        >
         {/* <Button
                css={css`
                  background: #eef3f7;
                  width: fit-content;
                  height: auto;
                  color: ${colors.faintAsh};
                `}
                onClick={reset}
              >
                {state.action === ACTIONS.DELETE_TAG ? "Cancel" : "Discard"}
              </Button> */}
         <Button
          animateOnTap
          animateOnHover
          css={css`
           background: ${state.action === ACTIONS.DELETE_CATEGORY
            ? "red"
            : colors.primaryPurple};
           font-weight: 500;
          `}
          onClick={() => {
           state.action === ACTIONS.DELETE_TAG
            ? deleteTag(chosenTag)
            : state.action === ACTIONS.ADD_TAG
            ? createTag(value)
            : updateTag();
          }}
         >
          {state.action === ACTIONS.DELETE_TAG
           ? "Delete"
           : state.action === ACTIONS.EDIT_TAG
           ? "Edit"
           : "Add tag"}
         </Button>
        </Flex>
       </>
      )}
     </Card>
    </AnimatePresence>
   </Modal>
  ),
  [visible, setVisible, renderAddEditInput]
 );

 const renderToast = React.useMemo(
  () => (
   <Toast
    visible={toast.state}
    setVisible={setToast}
    text={toast.text}
    textStyle={{ color: toast.textColor }}
    style={{ backgroundColor: toast.bgColor }}
   />
  ),
  [toast]
 );

 if (loading) {
  return <Loader />;
 }

 return (
  <Container>
   {renderSearchAndAddTagArea}
   <Wrapper>
    <CategoryHeader>
     <p>Tag Name</p>
     <p>Actions</p>
    </CategoryHeader>
    {renderTags}
   </Wrapper>
   {renderModal}
   {renderToast}
  </Container>
 );
};

export default Tags;
