import React, { useState } from 'react';
import { ExpandBtn, FaintBrownText } from '../styles';
import {
  Container,
  Header,
  Heading,
  SlideDivHack,
  SlideImg,
  SlideText,
  SlideWrapper,
  SplideSlideWrapper,
} from './styles';
import Icon from '../../../components/Icon';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useCityContext } from '../../../context';
// Default theme
import '@splidejs/react-splide/css';
import { SliderArrowPath } from '../../../assets/styles';
import { Link } from 'react-router-dom';
import SingleCollection from '../../Collections/SingleCollection';
import { css } from 'styled-components';
// import { SlideDivHack } from '../Blog/styles';

const Collections = () => {
  const [showSingleCollection, setShowSingleCollection] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});
  const { currentCity, collections } = useCityContext();
  const slideCollections = collections?.filter(
    (collection) => collection.featured
  );

  return (
    <Container>
      <div>
        <Header>
          <Heading>Collections</Heading>
          <Link to={`/collections/${currentCity}`}>
            <ExpandBtn>
              View all <Icon name='blueArrowRight' />{' '}
            </ExpandBtn>{' '}
          </Link>
        </Header>
        <FaintBrownText
        // Remove this for collections hover-effect
        // css={css`
        //   margin-bottom: -64px;
        // `}
        >
          Featured
        </FaintBrownText>
      </div>
      <SlideWrapper>
        <Splide
          aria-labelledby='Collections'
          options={{
            pagination: false,
            rewind: true,
            perPage: 4,
            breakpoints: {
              640: {
                perPage: 1,
              },

              1080: {
                perPage: 3,
              },
              1660: {
                perPage: 4,
              },
            },
            gap: '1.5rem',
            classes: {
              arrows: 'splide__arrows splide-navigation-arrows',
              arrow: 'splide__arrow splide-navigation-arrow city-collections',
              prev: 'splide__arrow--prev splide-nav-prev',
              next: 'splide__arrow--next splide-nav-next',
            },
            arrowPath: SliderArrowPath,
          }}
        >
          {slideCollections?.map((collection, index) => (
            <SplideSlide key={index}>
              {/* <SlideDivHack /> */}

              <SplideSlideWrapper
                onClick={() => {
                  setShowSingleCollection(true);
                  setSelectedCollection(collection);
                }}
              >
                <SlideImg src={collection.img} alt={collection.title} />
                <SlideText>{collection.title}</SlideText>
              </SplideSlideWrapper>
              <SlideDivHack />
            </SplideSlide>
          ))}
        </Splide>
      </SlideWrapper>
      {showSingleCollection && (
        <SingleCollection
          visible={showSingleCollection}
          setVisible={setShowSingleCollection}
          collection={selectedCollection}
        />
      )}
    </Container>
  );
};

export default Collections;
