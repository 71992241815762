import React, { useState, useContext } from "react";
import {
 Box,
 HStack,
 Text,
 SimpleGrid,
 Img,
 Input,
 Checkbox,
 Button,
 FormControl,
 FormLabel,
 FormErrorMessage,
 InputRightElement,
 InputGroup,
 Icon,
 Image,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Img1 from "../../assets/images/signup-phones.png";
import bgImg from "../../assets/images/Navi-Logo-Inversion.svg";
import styles from "./index.module.css";
import { colors } from "../../assets/styles/config";
import Logo from "../../assets/images/navi-logo-white.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import ShowImg from "../../assets/images/Show.svg";
import { cf } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import HideImg from "../../assets/images/Hide.svg";
import { AuthContext } from "../../context/AuthContext";
import Header from "../../components/Header/index";
import NaviBg from "../../assets/images/lagos.jpeg";
import ArrowLeft from "../../assets/images/arrow-left-purple.svg";
import { inputStyle } from "../Admin/Dashboard/styles";
import dayjs from "dayjs";

const validationSchema = Yup.object({
 firstName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("First name is required"),
 lastName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("First name is required"),
 phoneNumber: Yup.string().required("Required"),
 email: Yup.string()
  .email("Invalid email address")
  .required("Email is required"),
 password: Yup.string()
  .min(8, "Password must be at least 8 characters")
  .required("Password is required"),
 confirmPassword: Yup.string()
  .oneOf([Yup.ref("password"), null], "Password does not match!")
  .required("Password is required"),
 agreedTerms: Yup.boolean()
  .oneOf([true], "Must agree to terms!")
  .required("Required"),
});

const SignUp = () => {
 const navigate = useNavigate();
 const location = useLocation();
 const images = [Img1, Img1, Img1];
 const [showPassword, setShowPassword] = useState(false);
 const [showConfirmPassword, setShowConfirmPassword] = useState(false);
 const { signup } = useContext(AuthContext);

 return (
  <>
   {/* <Header color="dark" p={{ base: "15px 10px", lg: "20px 60px" }} /> */}

   <Box
    position="relative"
    overflowX={"hidden"}
    width="100%"
    height="100vh"
    backgroundColor="#F3F2F9"
   >
    {/* <Box backgroundColor="#F3F2F9">
          <Image
            src={NaviBg}
            objectFit="cover"
            width="100%"
            // minHeight="100vh"
            // zIndex="-1"
            mixBlendMode="multiply"
          />
        </Box> */}
    <Box
     position="fixed"
     left="30px"
     top={{ base: "5%", md: "10%" }}
     display="flex"
     alignItems="center"
     cursor="pointer"
     onClick={() => history.back()}
    >
     <Image src={ArrowLeft} />
     <Text color="#000" marginLeft="10px" onClick={() => history.back()}>
      Go Back
     </Text>
    </Box>
    <Box
     // position="absolute"
     // left="0"
     // right="0"
     // top="50px"
     // zIndex="100"
     display="flex"
     justifyContent="center"
     alignItems="center"
     flexDirection="column"
     paddingInline={"25px"}
     paddingBottom={"25px"}
    >
     <Img src={Logo} h="60px" display={{ base: "block" }} marginBottom="20px" />
     <Box
      maxW="32rem"
      // mt={{ base: "50px", lg: "160px" }}
      mx="auto"
      w="100%"
      // w={{ base: "100%" }}
      padding={{ base: 5, xl: 10 }}
      h="100%"
      display="flex"
      flexDir={{ base: "column" }}
      alignItems={{ base: "flex-start", lg: "center" }}
      justifyContent="center"
      backgroundColor="#ffffff"
      borderRadius="2xl"
     >
      <Box w="100%">
       <Text
        color="#0F0931"
        fontSize={{ base: "24px" }}
        lineHeight="20px"
        fontWeight="700"
        textAlign="center"
        marginBottom="10px"
        fontFamily="Creato Display, Roboto, sans-serif;"
       >
        Hello Navigator!
       </Text>
       <Box mt="20px" w="100%">
        <Formik
         initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
          rememberMe: false,
          agreedTerms: false,
         }}
         onSubmit={async (values) => {
          await signup({
           ...values,
           role: "USER",
           created_at: JSON.stringify(dayjs(Date.now())),
          });
         }}
         validationSchema={validationSchema}
        >
         {({ handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit}>
           <SimpleGrid
            columns={{ base: 2 }}
            w="100%"
            alignItems="start"
            gap="20px"
            mb="20px"
           >
            <FormControl
             isInvalid={!!errors.firstName && touched.firstName}
             isRequired
            >
             <FormLabel
              color="#1D1D1D"
              fontSize={{ base: "14px", md: "15px", lg: "16px" }}
              lineHeight="140%"
              fontWeight="400"
              mb="10px"
             >
              First name
             </FormLabel>
             <Field
              as={Input}
              {...inputStyle}
              type="text"
              id="firstName"
              name="firstName"
             />
             <FormErrorMessage>{errors.firstName}</FormErrorMessage>
            </FormControl>
            <FormControl
             isInvalid={!!errors.lastName && touched.lastName}
             isRequired
            >
             <FormLabel
              color="#1D1D1D"
              fontSize={{ base: "14px", md: "15px", lg: "16px" }}
              lineHeight="140%"
              fontWeight="400"
              mb="10px"
             >
              Last Name
             </FormLabel>
             <Field
              as={Input}
              {...inputStyle}
              type="text"
              id="lastName"
              name="lastName"
             />
            </FormControl>
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
           </SimpleGrid>
           <SimpleGrid
            columns={{ base: 2 }}
            w="100%"
            alignItems="start"
            gap="20px"
            mb="20px"
           >
            <FormControl isInvalid={!!errors.email && touched.email} isRequired>
             <FormLabel
              color="#1D1D1D"
              fontSize={{ base: "14px", md: "15px", lg: "16px" }}
              lineHeight="140%"
              fontWeight="400"
              mb="10px"
             >
              Email
             </FormLabel>
             <Field
              as={Input}
              {...inputStyle}
              type="email"
              id="email"
              name="email"
             />
             <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl
             isInvalid={!!errors.phoneNumber && touched.phoneNumber}
             isRequired
            >
             <FormLabel
              color="#1D1D1D"
              fontSize={{ base: "14px", md: "15px", lg: "16px" }}
              lineHeight="140%"
              fontWeight="400"
              mb="10px"
             >
              Phone Number
             </FormLabel>
             <Field
              as={Input}
              {...inputStyle}
              type="number"
              id="phoneNumber"
              name="phoneNumber"
             />
             <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
            </FormControl>
           </SimpleGrid>
           <SimpleGrid
            columns={{ base: 2 }}
            w="100%"
            alignItems="start"
            gap="20px"
            mb="20px"
           >
            <FormControl
             isInvalid={!!errors.password && touched.password}
             isRequired
            >
             <FormLabel
              color="#1D1D1D"
              fontSize={{ base: "14px", md: "15px", lg: "16px" }}
              lineHeight="140%"
              fontWeight="400"
              mb="10px"
             >
              Password
             </FormLabel>
             <InputGroup>
              <Field
               as={Input}
               {...inputStyle}
               type={showPassword ? "text" : "password"}
               id="password"
               name="password"
              />
              <InputRightElement width="3rem">
               <Box onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                 <Img src={HideImg} alt="hide" />
                ) : (
                 <Img src={ShowImg} alt="show" />
                )}
               </Box>
              </InputRightElement>
             </InputGroup>

             <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl
             isInvalid={!!errors.confirmPassword && touched.confirmPassword}
             isRequired
            >
             <FormLabel
              color="#1D1D1D"
              fontSize={{ base: "14px", md: "15px", lg: "16px" }}
              lineHeight="140%"
              fontWeight="400"
              mb="10px"
             >
              Confirm Password
             </FormLabel>

             <InputGroup>
              <Field
               as={Input}
               {...inputStyle}
               type={showConfirmPassword ? "text" : "password"}
               id="confirmPassword"
               name="confirmPassword"
              />
              <InputRightElement width="3rem">
               <Box
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
               >
                {showConfirmPassword ? (
                 <Img src={HideImg} alt="hide" />
                ) : (
                 <Img src={ShowImg} alt="show" />
                )}
               </Box>
              </InputRightElement>
             </InputGroup>
             <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
            </FormControl>
           </SimpleGrid>
           <Box mt="20px">
            <FormControl>
             <Field as={Checkbox} id="rememberMe" name="rememberMe">
              Remember me
             </Field>
            </FormControl>
            <FormControl
             mt="10px"
             isInvalid={!!errors.agreedTerms && touched.agreedTerms}
            >
             <Field as={Checkbox} id="agreedTerms" name="agreedTerms">
              I agree to all the{" "}
              <span style={{ color: colors.primaryPurple }}>Terms</span> and{" "}
              <span style={{ color: colors.primaryPurple }}>
               Privacy policy
              </span>
             </Field>
             <FormErrorMessage>{errors.agreedTerms}</FormErrorMessage>
            </FormControl>
           </Box>
           <Button
            color={colors.primaryWhite}
            bg={colors.primaryPurple}
            borderRadius={28}
            fontWeight={500}
            fontSize={18}
            marginTop={10}
            p="24px 20px"
            w="100%"
            type="submit"
           >
            Sign Up
           </Button>
           <Text
            textAlign="center"
            fontSize="14px"
            lineHeight="140%"
            fontWeight="400"
            mt="20px"
           >
            Already have an account?{" "}
            <span
             onClick={() => navigate("/signin", { state: { from: location } })}
             style={{
              color: colors.primaryPurple,
              cursor: "pointer",
             }}
            >
             Log In
            </span>
           </Text>
          </form>
         )}
        </Formik>
       </Box>
      </Box>
     </Box>
    </Box>
   </Box>
  </>
 );
};

export default SignUp;
