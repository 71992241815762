import {
 createUserWithEmailAndPassword,
 updateProfile,
 signInWithEmailAndPassword,
 sendEmailVerification,
 updatePassword,
 sendPasswordResetEmail,
 signOut,
 getAuth,
} from "firebase/auth";
import { auth, storage, cf } from "../firebase";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
 remove,
} from "firebase/database";
import dayjs from "dayjs";

//for cloud functions
import { httpsCallable } from "firebase/functions";
import { handleFirebaseError } from "../utils";

//partner application email cloud function
const application = httpsCallable(cf, "applicationPartners");

const processingPartnerEmail = httpsCallable(cf, "processingRegistration");

//create user function
const createUser = httpsCallable(cf, "createUser");

//remove user function
const removeUser = httpsCallable(cf, "removeUser");

//send push notification
const pushNotification = httpsCallable(cf, "sendPushNotification");

const welcomeTeamMembers = httpsCallable(cf, "welcomeTeamMembers");

const welcomePartnerTeamMembers = httpsCallable(
 cf,
 "welcomePartnerTeamMembers"
);

//get current user
export const getCurrentUser = () => {
 return auth.currentUser;
};

//send email verification
export const sendVerification = () => sendEmailVerification(auth.currentUser);

//sign Up
export const signUp = async (data) => {
 return await createUserWithEmailAndPassword(auth, data.email, data.password)
  .then(async (userCredentials) => {
   if (userCredentials) {
    // await updateProfile(auth.currentUser, {});
    const db = getDatabase();
    set(ref(db, "users/user/" + userCredentials.user.uid), {
     uid: userCredentials.user.uid,
     email: userCredentials.user.email,
     name: `${data.firstName} ${data.lastName}`,
     phoneNumber: data.phoneNumber,
     role: data.role,
     created_at: data.created_at,
    });
    // localStorage.setItem("token", userCredentials?.user.accessToken);
    sendVerification();
    return userCredentials;
   }
  })
  .catch(function (error) {
   if (error.code) {
    throw new Error(handleFirebaseError(error));
   } else throw new Error(error?.message);
  });
};

//sign in
export const signIn = async (data, isUser = false) => {
 return await signInWithEmailAndPassword(auth, data.email, data.password)
  .then((userCredentials) => {
   // localStorage.setItem("token", userCredentials?.user.accessToken);

   if (isUser && !userCredentials.user.emailVerified) {
    throw new Error("email not verified");
   }

   return userCredentials;
  })
  .catch(function (error) {
   // console.log("error", error.message);
   if (error.code) {
    throw new Error(handleFirebaseError(error));
   } else throw new Error(error?.message);
  });
};

//signOut
export const logOut = () => signOut(auth);

//send password reset email
export const sendPasswordReset = async (email) => {
 return await sendPasswordResetEmail(auth, email)
  .then(() => {
   return true;
  })
  .catch((err) => {
   throw new Error(handleFirebaseError(error));
  });
};

//Onboard
export const onboard = async (data) => {
 // console.log("data", data);
 return await createUser({
  email: data.email,
  password: data.password,
  disabled: true,
 })
  .then(async ({ data: user }) => {
   if (user?.uid) {
    const db = getDatabase();
    set(ref(db, "users/partner/" + user.uid), {
     uid: user.uid,
     email: data.email,
     // name: data.name,
     firstName: data.firstName,
     lastName: data.lastName,
     bio: data.bio ? data.bio : "",
     phoneNumber: data.phoneNumber,
     address: data.address ? data.address : "",
     registeringAs: data.registeringAs,
     businessName: data.businessName,
     businessEmail: data.businessEmail,
     businessPhoneNumber: data.businessPhoneNumber,
     city: data.city,
     businessOpen: data.businessOpen,
     hours: data.hours,
     isSameTime: data.isSameTime,
     establishmentType: data.establishmentType,
     role: data.role,
     photoURL: data.photoURL ? data.photoURL : "",
     coverURL: data.coverURL ? data.coverURL : "",
     fileURL: data.fileURL ? data.fileURL : "",
     service: data.service ? data.service : "",
     tags: data.tags ? data.tags : "",
     category: data.category ? data.category : "",
     link: data.link ? data.link : "",
     status: data?.status,
     subscribed: false,
     enabled: data.enabled,
     created_at: data.created_at,
    });

    // const dbRef = ref(getDatabase());

    // const adminEmail = await get(child(dbRef, `users/admin`)).then(
    //   (snapshot) => {
    //     if (snapshot.exists()) {
    //       let myObj = snapshot.val();

    //       var array = Object.keys(myObj).map((key) => {
    //         return myObj[key];
    //       });

    //       return array?.map((item) => item?.email);
    //     }
    //   }
    // );

    processingPartnerEmail({
     email: data.email,
     name: `${data.firstName} ${data.lastName}`,
    });

    application({
     // emails: adminEmail,
     email: data.email,
     name: `${data.firstName} ${data.lastName}`,
     businessName: data.businessName,
     phoneNumber: data.phoneNumber,
     address: data.address,
    });

    // sendVerification();
    return user;
   } else {
    throw new Error(user?.error);
   }
  })
  .catch(function (error) {
   // console.log("error", error?.message);
   if (error.code) {
    throw new Error(handleFirebaseError(error));
   } else throw new Error(error?.message);
  });
};

//partner reset password
export const resetPass = async (password) => {
 return await updatePassword(auth.currentUser, password);
};

//create admin
export const createAdmin = async (data) => {
 return createUser({ email: data.email, password: data.password })
  .then(({ data: user }) => {
   if (user?.uid) {
    const db = getDatabase();
    set(ref(db, "users/admin/" + user.uid), {
     name: data.name,
     email: data.email,
     phoneNumber: data.phoneNumber,
     role: data.role,
     assignedPartner: data.assignedPartner,
     photoURL: data.photoURL,
     enabled: data.enabled,
     created_at: data.created_at,
     uid: user.uid,
    });

    welcomeTeamMembers({
     email: data.email,
     name: data.name,
     password: data.password,
     role: data.role,
    }).then((result) => {
     // Read result of the Cloud Function.
     /** @type {any} */
    });
    // sendVerification();

    return user;
   } else {
    throw new Error(user?.error);
   }
  })
  .catch(function (error) {
   if (error.code) {
    throw new Error(handleFirebaseError(error));
   } else throw new Error(error?.message);
  });
};

export const createPartnerMember = async (data) => {
 return createUser({ email: data.email, password: data.password })
  .then(({ data: user }) => {
   if (user?.uid) {
    const db = getDatabase();
    set(ref(db, `users/partner/${data.partnerUid}/members/` + user.uid), {
     name: data.name,
     email: data.email,
     phoneNumber: data.phoneNumber,
     role: data.role,
     assignedPartner: data.assignedPartner,
     photoURL: data.photoURL,
     enabled: data.enabled,
     created_at: data.created_at,
     uid: user.uid,
     partnerUid: data.partnerUid,
     partner: data.partnerName,
    });

    welcomePartnerTeamMembers({
     email: data.email,
     name: data.name,
     password: data.password,
     role: data.role,
     partner: data.partnerName,
    }).then((result) => {
     // Read result of the Cloud Function.
     /** @type {any} */
    });
    // sendVerification();
    return user;
   } else {
    throw new Error(user?.error);
   }
  })
  .catch(function (error) {
   if (error.code) {
    throw new Error(handleFirebaseError(error));
   } else throw new Error(error?.message);
  });
};

//remove user
export const removeAdmin = async (uid) => {
 return removeUser(uid)
  .then(async (res) => {
   const db = getDatabase();
   return remove(ref(db, "users/admin/" + uid))
    .then(() => {
     return true;
    })
    .catch((error) => {
     console.error(error.message);
    });
  })
  .catch(function (error) {
   if (error.code) {
    throw new Error(handleFirebaseError(error));
   } else throw new Error(error?.message);
  });
};

export const removePartner = async (uid) => {
 return removeUser(uid)
  .then(async (res) => {
   const db = getDatabase();
   return remove(ref(db, "users/partner/" + uid))
    .then(() => {
     return true;
    })
    .catch((error) => {
     console.error(error.message);
    });
  })
  .catch(function (error) {
   if (error.code) {
    throw new Error(handleFirebaseError(error));
   } else throw new Error(error?.message);
  });
};

//remove user
export const removePartnerMember = async (partnerUid, uid) => {
 return removeUser(uid)
  .then(async (res) => {
   const db = getDatabase();
   return remove(ref(db, `users/partner/${partnerUid}/members/` + uid))
    .then(() => {
     return true;
    })
    .catch((error) => {
     console.error(error.message);
    });
  })
  .catch(function (error) {
   if (error.code) {
    throw new Error(handleFirebaseError(error));
   } else throw new Error(error?.message);
  });
};

/*createUserWithEmailAndPassword(auth, data.email, data.password)
    .then(async (userCredentials) => {
      if (userCredentials) {
        const db = getDatabase();
        set(ref(db, "users/" + userCredentials.user.uid), {
          name: data.name,
          phoneNumber: data.phoneNumber,
          address: data.address,
          role: data.role,
        });

        return userCredentials;
      }
    })
    .catch(function (error) {
      throw new Error(error.message);
    }); */

//fetch database

export const fetchDatabase = async (userId) => {
 // const dbRef = ref(getDatabase());
 // const users = await get(child(dbRef, `users`)).then((snapshot) => {
 //   if (snapshot.exists()) {
 //     return snapshot.val();
 //   }
 // });

 // console.log("userId", userId);
 // let user;
 // return Object.keys(users).forEach(function (key) {
 //   var entry = users[key];
 //   console.log("entr===", entry[userId]);
 //   if (entry[userId]) {
 //     console.log("entr===", entry[userId]);
 //     return entry[userId];
 //   }
 //   console.log("entr===", userId);
 //   Object.keys(entry)?.forEach(function (k) {
 //     if (entry[k]?.members) {
 //       console.log("entr===", entry[k]?.members);
 //       return entry[k]?.members[userId];
 //     }
 //   });
 // });

 try {
  const dbRef = ref(getDatabase());
  const snapshot = await get(child(dbRef, "users"));

  // if (snapshot.exists()) {
  //   const users = snapshot.val();

  //   console.log("users", users);

  //   for (const key in users) {
  //     const entry = users[key];

  //     if (entry[userId]) {
  //       return entry[userId];
  //     }

  //     for (const k in entry) {
  //       if (entry[k]?.members) {
  //         return entry[k]?.members[userId];
  //       }
  //     }
  //   }
  // }

  const users = snapshot.val();
  // console.log("users", users, userId);
  if (users) {
   if (users?.admin && users?.admin[userId]) {
    return users?.admin[userId];
   } else if (users?.partner && users?.partner[userId]) {
    return users?.partner[userId];
   } else if (users?.user && users?.user[userId]) {
    return users?.user[userId];
   } else {
    // Check the partner members
    for (const partnerKey in users?.partner) {
     // console.log("partnerKey", partnerKey);
     if (users?.partner[partnerKey].members !== undefined) {
      const member = users?.partner[partnerKey].members[userId];
      // console.log("member", member);
      if (member) {
       return member;
      }
     }
    }
   }
  } else return null;
 } catch (error) {
  // Handle any errors that occur during fetching or processing data
  console.error("Error fetching database:", error);
 }

 // Return a default value or handle the case when the user is not found
 return null;

 // return user;

 // if (users) {
 //   const ress = filterObject(users, userId);
 //   console.log(
 //     "users",
 //     users,
 //     userId,
 //     ress
 //     // Object.keys(ress).reduce(
 //     //   (acc, val) =>
 //     //     ress[val]["uid"] === userId
 //     //       ? {
 //     //           ...acc,
 //     //           [val]: ress[val],
 //     //         }
 //     //       : acc,
 //     //   {}
 //     // )
 //   );
 // }

 // const db = await get(child(dbRef, `users/${userId}`))
 //   .then((snapshot) => {
 //     if (snapshot.exists()) {
 //       return snapshot.val();
 //     } else {
 //       console.log("No data available");
 //     }
 //   })
 //   .catch((error) => {
 //     console.error(error);
 //   });
 // return db;
};

//update databse
export const updateDatabase = async (userId, data) => {
 const db = getDatabase();
 const updates = {};
 updates["users/user/" + userId] = data;
 return await update(ref(db), updates)
  .then(() => {
   return true;
  })
  .catch((error) => {
   console.error(error.message);
  });
};

export const updateAdminDatabase = async (userId, data) => {
 const db = getDatabase();
 const updates = {};
 updates["users/admin/" + userId] = data;
 return await update(ref(db), updates)
  .then(() => {
   return true;
  })
  .catch((error) => {
   console.error(error.message);
  });
};

export const updatePartnerDatabase = async (userId, data) => {
 const db = getDatabase();
 const updates = {};
 updates["users/partner/" + userId] = data;
 return await update(ref(db), updates)
  .then(() => {
   return true;
  })
  .catch((error) => {
   console.error(error.message);
  });
};

export const updatePartnerMemberDatabase = async (userId, partnerUid, data) => {
 const db = getDatabase();
 const updates = {};
 updates[`users/partner/${partnerUid}/members/` + userId] = data;
 return await update(ref(db), updates)
  .then(() => {
   return true;
  })
  .catch((error) => {
   console.error(error.message);
  });
};

//fetch role perm
export const fetchRolePermission = async () => {
 const dbRef = ref(getDatabase());
 const db = await get(child(dbRef, `cityExperience/roles`))
  .then((snapshot) => {
   if (snapshot.exists()) {
    return snapshot.val();
   } else {
    // console.log("No data available");
   }
  })
  .catch((error) => {
   console.error(error);
  });
 return db;
};

//fetch partner role perm
export const fetchPartnerRolePermission = async (partnerUid) => {
 const dbRef = ref(getDatabase());
 const db = await get(child(dbRef, `users/partner/${partnerUid}/roles`))
  .then((snapshot) => {
   if (snapshot.exists()) {
    return snapshot.val();
   } else {
    // console.log("No data available");
   }
  })
  .catch((error) => {
   console.error(error);
  });
 return db;
};

export const sendPushNotification = async (data) => {
 const dbRef = ref(getDatabase());
 const partnersToken = await get(child(dbRef, `users/admin`)).then(
  (snapshot) => {
   if (snapshot.exists()) {
    let myObj = snapshot.val();

    var array = Object.keys(myObj)
     .map((key) => {
      return myObj[key];
     })
     ?.filter((item) => item?.pushToken);
    return array?.map((item) => item?.pushToken);
   }
  }
 );

 const { title, body, data: pushData } = data;
 pushData["push_time"] = dayjs(Date.now());

 const payload = {
  FCMToken: partnersToken,
  title: title,
  body: body,
  data: JSON.stringify(pushData),
 };

 await pushNotification(payload)
  .then((res) => {
   // console.log("res", res);
  })
  .catch(function (error) {
   throw new Error(error.message);
  });
};

export const sendPartnerPushNotification = async (data) => {
 const dbRef = ref(getDatabase());
 const partnersToken = await get(child(dbRef, `users/partner`)).then(
  (snapshot) => {
   if (snapshot.exists()) {
    let myObj = snapshot.val();
    let partners = Object.keys(myObj).map((key) => {
     return myObj[key];
    });

    var tokenArray = partners
     ?.filter((item) => item?.uid === data?.data.uid)
     ?.filter((item) => item?.pushToken);

    let memberTokens = [];
    for (const partnerKey in myObj) {
     if (
      myObj[partnerKey]?.uid === data?.data.uid &&
      myObj[partnerKey].members !== undefined
     ) {
      for (const memberKey in myObj[partnerKey].members) {
       if (myObj[partnerKey].members[memberKey]?.pushToken !== undefined) {
        memberTokens.push(myObj[partnerKey].members[memberKey]?.pushToken);
       }
      }
     }
    }

    const tokens = tokenArray
     ?.map((item) => item?.pushToken)
     .concat(memberTokens);
    return [...new Set(tokens)];
   }
  }
 );

 const { title, body, data: pushData } = data;
 pushData["push_time"] = dayjs(Date.now());

 const payload = {
  FCMToken: partnersToken,
  title: title,
  body: body,
  data: JSON.stringify(pushData),
 };

 await pushNotification(payload)
  .then((res) => {
   // console.log("res", res);
  })
  .catch(function (error) {
   throw new Error(error.message);
  });
};
