import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '../../assets/styles/config';

export const Container = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  z-index: 10000;
`;
export const ToastContainer = styled(motion.div)`
  min-height: 35px;
  min-width: 100px;
  border-radius: 20px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primaryGreen};
`;

export const Text = styled.span`
  display: block;

  font-size: 14px;
  color: ${colors.primaryWhite};
  line-height: 20px;
  user-select: none;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;
