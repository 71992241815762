import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Footer, Header } from "../../components";
import Loader from "../../components/Loader";
import { useCityContext } from "../../context";
import { db } from "../../firebase";
import ExperiencesList from "./ExperiencesList";
import {
 BigImageTitle,
 Container,
 ExperienceImg,
 ExpImgWrapper,
 Wrapper,
} from "./styles";
import Navbar from "../Home/Navbar";
import { experienceBg } from "../../assets/strings/imageExports";
import FeaturedExperiences from "./FeaturedExperiences/FeaturedExperiences";
import { Text } from "@chakra-ui/react";

const Experiences = () => {
 const [loading, setLoading] = useState(true);
 const [experiences, setExperiences] = useState([]);
 const [allExperiences, setAllExperiences] = useState([]);
 const [featuredExperiences, setFeaturedExperiences] = useState([]);
 const [cityChoices, setCityChoices] = useState([]);
 const { dispatch } = useCityContext();
 const experienceDb = ref(db, "cityExperience/experiences");

 // EFFECTS
 useEffect(() => {
  document.title = "Experiences | NAVI";
 }, []);

 useEffect(() => {
  setLoading(true);
  const unsubscribe = onValue(experienceDb, (snapshot) => {
   const dbData = snapshot.val();
   const data = dbData && Object.values(dbData);
   // console.log("data==", data);
   const dataWithLeastPrice = data?.map((experience, experienceIndex) => {
    let leastPrice;
    let prevDateArr = [];
    let prevDate;
    if (experience.type === "oneTime") {
     let arr = [];
     JSON.parse(experience.timeframes).forEach((item, i) => {
      prevDateArr.push(new Date(item["date"]) < Date.now()); // this checks if the date in the experience has passed and pushes true or false to prevDateArr. If it is true, the day has passed.
      item.timeSlot.forEach((itm, i) => {
       arr.push(itm);
      });
     }); // Each timeSlot has an amount in it
     if (prevDateArr.includes(false)) {
      prevDate = false;
     } else {
      prevDate = true;
     }
     const prices = arr.map((item) => +item.amount); // A collection of all the prices in the different timeSlots
     leastPrice = Math.min(...prices); // The leastPrice
    } else {
     let arr = [];
     JSON.parse(experience.timeframes).forEach((item) =>
      item.timeSlot.forEach((itm) => {
       arr.push(itm);
      })
     );

     const prices = arr.map((item) => item.amount);
     leastPrice = Math.min(...prices);
    }

    return { ...experience, leastPrice, prevDate };
   });
   const dataNotWithPrevDate = dataWithLeastPrice?.filter(
    (exp) => !exp.prevDate
   );
   setExperiences(dataNotWithPrevDate);
   setAllExperiences(dataNotWithPrevDate);
   if (dataNotWithPrevDate?.length > 0) {
    setLoading(false);
   } else {
    setTimeout(() => {
     setLoading(false);
    }, 2000);
   }
  });

  return () => unsubscribe();
 }, []);

 useEffect(() => {
  const newFeaturedExps = experiences?.filter((exp) => exp.featured);
  const choices = Array.from(new Set(experiences?.map((exp) => exp.city)));
  setFeaturedExperiences(newFeaturedExps);
  setCityChoices(choices);
 }, [experiences]);

 // FUNCTIONS
 const changeCityExperiences = (city) => {
  const featuredExps = experiences.filter((exp) => exp.feature);
  const newFeaturedExps = featuredExps.filter(
   (exp) => exp.city.toLowerCase() === city.toLowerCase()
  );
  const newAllExps = experiences.filter(
   (exp) => exp.city.toLowerCase() === city.toLowerCase()
  );
  setAllExperiences(newAllExps);
  setFeaturedExperiences(newFeaturedExps);
 };

 // JSX
 if (loading) {
  return <Loader />;
 }
 return (
  <>
   <ExpImgWrapper>
    <Header bgColor={"transparent"} color="white" />
    <BigImageTitle>
     NAVI
     <br /> Experiences
    </BigImageTitle>
   </ExpImgWrapper>
   {/* <FeaturedExperiences
        experiences={featuredExperiences}
        cityChoices={cityChoices}
        changeCityExperiences={changeCityExperiences}
      /> */}

   <ExperiencesList experiences={allExperiences} setLoading={setLoading} />

   <Footer />
  </>
 );
};

export default Experiences;
