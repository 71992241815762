import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, HStack, Img, Text, Button, Flex } from "@chakra-ui/react";
import { colors } from "../../assets/styles/config";
import Logo from "../../assets/images/Navi-Logo.png";
import LogoLight from "../../assets/images/logo-light.png";
import {
  BlueButton,
  CartAmount,
  navCartIconCss,
  NavOption,
  NavOptions,
  Regular,
  MobileMenu,
  Container,
} from "./styles";
import Icon from "../Icon";
import { css } from "styled-components";
import { useLocation, useNavigate, Link } from "react-router-dom";
import BarImg from "../../assets/images/bar.svg";
import BarLightImg from "../../assets/images/bar-light.svg";
import userImg from "../../assets/images/user.svg";
import { AuthContext } from "../../context/AuthContext";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./index.module.css";
import user from "../../assets/images/user.svg";
import closeIcon from "../../assets/images/Close.svg";
import Dropdown from "../../assets/images/dropdown.svg";
import DropdownBlack from "../../assets/images/dropdownBlack.svg";
import DropdownBlue from "../../assets/images/dropdownblue.svg";
import { db } from "../../firebase";
import { ref, onValue } from "firebase/database";

const Navbar = ({ bgColor, color, ...props }) => {
  const { role, signout } = useContext(AuthContext);
  const [dropdown, setdropdown] = useState(false);
  const [mobileMenu, showMobileMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toggle = useRef(null);
  const headerRef = useRef(null);
  const toggleCity = useRef(null);
  const togglePartner = useRef(null);
  const [city, setCity] = useState([]);
  const [openCity, setOpenCity] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const cityExperienceDb = ref(db, "cityExperience/city");

  useEffect(() => {
    headerRef?.current?.scrollIntoView();
    setLoading(true);
    const unsubscribe = onValue(cityExperienceDb, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCity(data);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const resize = window.innerWidth > 768;
    if (resize) {
      setIsMobile(false);
    } else setIsMobile(true);

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        setIsMobile(false);
      } else setIsMobile(true);
    });
  }, []);

  const handleClickOutside = (event) => {
    // if (!toggle?.current.contains(event.target)) {
    //   setdropdown(false);
    // }
    // if (!toggleCity?.current.contains(event.target)) {
    //   setOpenCity(false);
    // }
    // if (!togglePartner?.current.contains(event.target)) {
    //   setOpenPartner(false);
    // }
  };

  const showMenu = () => {
    showMobileMenu(!mobileMenu);
  };

  const navLinks = [
    {
      title: "Experiences",
      link: "/experiences",
    },
    {
      title: "Businesses",
      link: "/partner",
    },
  ];

  return (
    <Container>
      <Box
        height={3}
        width={40}
        background={"transparent"}
        ref={headerRef}
        position={"absolute"}
        zIndex={"overlay"}
        top={0}
        left={0}
      ></Box>
      <Box
        bg={bgColor || colors.skyBlue}
        p={{ base: "15px 20px", lg: "35px 60px" }}
        width="100%"
        position="absolute"
        top={0}
        zIndex={2}
        // maxWidth="1540px"
        marginInline="auto"
        {...props}
      >
        <HStack justify="space-between" position="relative">
          <Link to="/">
            {" "}
            <Img
              src={color === "dark" ? Logo : LogoLight}
              objectFit="contain"
              w={["100px 180px"]}
              h={["35px", "58px"]}
            />{" "}
          </Link>
          {!isMobile ? (
            <NavOptions spacing={10}>
              <Link to="/">
                <NavOption>
                  <Text
                    color={color === "dark" ? "#0F0931" : "#FFFFFF"}
                    cursor={"pointer"}
                    fontWeight={500}
                    fontSize={{ base: "14px", lg: "18px" }}
                    letterSpacing={"0.05em"}
                    paddingBottom="5px"
                    borderBottom={
                      location.pathname === "/" ? "2px solid #fff" : ""
                    }
                    lineHeight="20px"
                    _hover={{
                      transition: "all .5s",
                      opacity: "0.5",
                    }}
                  >
                    Home
                  </Text>
                </NavOption>
              </Link>

              <Link to="/experiences">
                <NavOption>
                  <Text
                    color={color === "dark" ? "#0F0931" : "#FFFFFF"}
                    borderBottom={
                      location.pathname.includes("/experiences")
                        ? "2px solid #fff"
                        : ""
                    }
                    cursor={"pointer"}
                    letterSpacing={"0.05em"}
                    fontWeight={500}
                    fontSize={{ base: "14px", lg: "18px" }}
                    lineHeight="20px"
                    paddingBottom="5px"
                    _hover={{
                      transition: "all .5s",
                      opacity: "0.5",
                    }}
                  >
                    Experiences
                  </Text>
                </NavOption>
              </Link>

              <Box
                position="relative"
                ref={toggleCity}
                onMouseLeave={() => setOpenCity(false)}
                paddingBlock={5}
              >
                <Box
                  // onClick={() => setOpenCity(!openCity)}
                  cursor="pointer"
                  display="flex"
                  alignItems="center"
                  _hover={{
                    transition: "all .5s",
                    opacity: "0.5",
                  }}
                  onMouseOver={() => setOpenCity(true)}
                  borderBottom={
                    location.pathname.includes("/collections")
                      ? color === "dark"
                        ? "2px solid #0F0931"
                        : "2px solid #fff"
                      : ""
                  }
                >
                  <Text
                    color={
                      location.pathname.includes("city")
                        ? "#632EFF"
                        : color === "dark"
                        ? "#0F0931"
                        : "#FFFFFF"
                    }
                    cursor={"pointer"}
                    fontWeight={500}
                    fontSize={{ base: "14px", lg: "18px" }}
                    lineHeight="20px"
                    paddingBottom="5px"
                    letterSpacing={"0.05em"}
                    mr="10px"
                  >
                    City Guides
                  </Text>
                  <Img
                    src={
                      location.pathname.includes("city")
                        ? DropdownBlue
                        : color === "dark"
                        ? DropdownBlack
                        : Dropdown
                    }
                    alt="drop"
                    style={{ width: "14px", marginTop: "-2px" }}
                    transform={openCity && "rotate(180deg)"}
                    transition="all .5s"
                  />
                </Box>

                {openCity && (
                  <Box
                    position="absolute"
                    top="26px"
                    //  padding="20px 20px 10px"
                  >
                    <Box
                      position="absolute"
                      top="26px"
                      left="-12px"
                      background={
                        location.pathname.includes("city")
                          ? "#FFFFFF"
                          : color === "dark"
                          ? "#632EFF"
                          : "#FFFFFF"
                      }
                      padding="20px 20px 10px"
                      borderRadius="15px"
                      width="150px"
                      cursor="pointer"
                      display="flex"
                      flexDir="column"
                      alignItems="center"
                    >
                      {!loading &&
                        city &&
                        Object.keys(city)
                          .filter((item) => city[item].enabled === true)
                          .map((item, id) => (
                            <Text
                              key={id}
                              color={
                                location.pathname.includes("city")
                                  ? "#0F0931"
                                  : color === "dark"
                                  ? "#FFFFFF"
                                  : "#0F0931"
                              }
                              letterSpacing={"0.05em"}
                              cursor={"pointer"}
                              fontSize={{ base: "14px", lg: "16px" }}
                              lineHeight="20px"
                              marginBottom="14px"
                              paddingBottom="5px"
                              onClick={
                                () => navigate(`/collections/${item}`)
                                // navigate(`/city/${item}`)
                              }
                              textTransform="capitalize"
                              _hover={{
                                color: color === "dark" ? "#fff" : "#632EFF",
                                transform: "scale(1.05)",
                                fontWeight: 500,
                              }}
                            >
                              {item}
                            </Text>
                          ))}
                    </Box>
                  </Box>
                )}
              </Box>

              <Link to="/partner">
                <NavOption>
                  <Text
                    color={color === "dark" ? "#0F0931" : "#FFFFFF"}
                    borderBottom={
                      location.pathname.includes("/partner")
                        ? "2px solid #fff"
                        : ""
                    }
                    cursor={"pointer"}
                    letterSpacing={"0.05em"}
                    fontWeight={500}
                    fontSize={{ base: "14px", lg: "18px" }}
                    lineHeight="20px"
                    paddingBottom="5px"
                    _hover={{
                      transition: "all .5s",
                      opacity: "0.5",
                    }}
                  >
                    Businesses
                  </Text>
                </NavOption>
              </Link>

              {/* {navLinks.map((item, index) => {
                return (
                  
                );
              })} */}

              {role ? (
                <Box position="relative" ref={toggle}>
                  <Box cursor="pointer" onClick={() => setdropdown(!dropdown)}>
                    {color === "dark" ? (
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22"
                          cy="22"
                          r="21"
                          stroke="#0F0931"
                          stroke-width="2"
                        />
                        <path
                          d="M26 17C26 18.0609 25.5786 19.0783 24.8284 19.8284C24.0783 20.5786 23.0609 21 22 21C20.9391 21 19.9217 20.5786 19.1716 19.8284C18.4214 19.0783 18 18.0609 18 17C18 15.9391 18.4214 14.9217 19.1716 14.1716C19.9217 13.4214 20.9391 13 22 13C23.0609 13 24.0783 13.4214 24.8284 14.1716C25.5786 14.9217 26 15.9391 26 17V17ZM22 24C20.1435 24 18.363 24.7375 17.0503 26.0503C15.7375 27.363 15 29.1435 15 31H29C29 29.1435 28.2625 27.363 26.9497 26.0503C25.637 24.7375 23.8565 24 22 24V24Z"
                          stroke="#0F0931"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22"
                          cy="22"
                          r="21"
                          stroke="#fff"
                          stroke-width="2"
                        />
                        <path
                          d="M26 17C26 18.0609 25.5786 19.0783 24.8284 19.8284C24.0783 20.5786 23.0609 21 22 21C20.9391 21 19.9217 20.5786 19.1716 19.8284C18.4214 19.0783 18 18.0609 18 17C18 15.9391 18.4214 14.9217 19.1716 14.1716C19.9217 13.4214 20.9391 13 22 13C23.0609 13 24.0783 13.4214 24.8284 14.1716C25.5786 14.9217 26 15.9391 26 17V17ZM22 24C20.1435 24 18.363 24.7375 17.0503 26.0503C15.7375 27.363 15 29.1435 15 31H29C29 29.1435 28.2625 27.363 26.9497 26.0503C25.637 24.7375 23.8565 24 22 24V24Z"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </Box>
                  <AnimatePresence>
                    {dropdown && (
                      <motion.ul
                        initial={{ x: 200 }}
                        animate={{ x: 30 }}
                        exit={{ x: 200 }}
                        transition={{ delay: 0.2, type: "spring" }}
                        className={styles.dropdown}
                      >
                        <li onClick={() => signout()}>
                          <span>Logout</span>
                        </li>
                        {role === "USER" ? (
                          <Link to="/user-profile">
                            <li>
                              <span>User Profile</span>
                            </li>
                          </Link>
                        ) : null}
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </Box>
              ) : (
                <Box
                  position="relative"
                  ref={togglePartner}
                  onMouseLeave={() => setOpenSignIn(false)}
                  paddingBlock={5}
                >
                  <Box
                    // onClick={() => setOpenCity(!openCity)}
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    borderRadius={"28px"}
                    _hover={{
                      transition: "all .5s",
                      opacity: "0.5",
                    }}
                    paddingBottom="5px"
                    background={"#632EFF"}
                    padding="12px"
                    onMouseOver={() => setOpenSignIn(true)}
                  >
                    <Text
                      color={"#FFFFFF"}
                      cursor={"pointer"}
                      fontWeight={500}
                      fontSize={{ base: "14px", lg: "18px" }}
                      lineHeight="20px"
                      letterSpacing={"0.05em"}
                      textAlign={"center"}
                      marginInline="4px"
                    >
                      Sign In
                    </Text>
                    <Img
                      src={Dropdown}
                      alt="drop"
                      style={{ width: "20px" }}
                      transform={openSignIn && "rotate(180deg)"}
                      transition="all .5s"
                    />
                  </Box>

                  {openSignIn && (
                    <Box position="absolute" top="26px">
                      <Box
                        position="absolute"
                        top="45px"
                        left="-12px"
                        background={
                          location.pathname.includes("city")
                            ? "#FFFFFF"
                            : color === "dark"
                            ? "#632EFF"
                            : "#FFFFFF"
                        }
                        letterSpacing={"0.05em"}
                        padding="15px 25px"
                        borderRadius="12px"
                        cursor="pointer"
                        display="flex"
                        flexDir="column"
                        width="max-content"
                      >
                        <Text
                          color={
                            location.pathname.includes("signin")
                              ? "#0F0931"
                              : color === "dark"
                              ? "#FFFFFF"
                              : "#0F0931"
                          }
                          cursor={"pointer"}
                          fontSize={{ base: "14px", lg: "16px" }}
                          lineHeight="20px"
                          transition=".2s"
                          marginBottom="14px"
                          onClick={() => navigate("/signin")}
                          _hover={{
                            color: color === "dark" ? "#fff" : "#632EFF",
                            transform: "scale(1.05)",
                            fontWeight: 500,
                          }}
                        >
                          As a navigator
                        </Text>
                        <Text
                          color={
                            location.pathname.includes("/partner")
                              ? "#0F0931"
                              : color === "dark"
                              ? "#FFFFFF"
                              : "#0F0931"
                          }
                          cursor={"pointer"}
                          fontSize={{ base: "14px", lg: "16px" }}
                          lineHeight="20px"
                          marginBottom="7px"
                          transition=".2s"
                          onClick={() => navigate("/partner/signin")}
                          _hover={{
                            color: color === "dark" ? "#fff" : "#632EFF",
                            fontWeight: 500,
                            transform: "scale(1.05)",
                          }}
                        >
                          As a business
                        </Text>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </NavOptions>
          ) : (
            <Flex gap="1.25rem">
              <img
                src={color !== "dark" ? BarLightImg : BarImg}
                alt="bar"
                style={{ cursor: "pointer" }}
                onClick={showMenu}
              />
            </Flex>
          )}

          <MobileMenu
            style={{
              right: mobileMenu ? "0" : "-300px",
              opacity: mobileMenu ? "1" : "0",
            }}
          >
            <Box display="flex" justifyContent="space-between">
              {role && (
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="22"
                    cy="22"
                    r="21"
                    stroke="#0F0931"
                    stroke-width="2"
                  />
                  <path
                    d="M26 17C26 18.0609 25.5786 19.0783 24.8284 19.8284C24.0783 20.5786 23.0609 21 22 21C20.9391 21 19.9217 20.5786 19.1716 19.8284C18.4214 19.0783 18 18.0609 18 17C18 15.9391 18.4214 14.9217 19.1716 14.1716C19.9217 13.4214 20.9391 13 22 13C23.0609 13 24.0783 13.4214 24.8284 14.1716C25.5786 14.9217 26 15.9391 26 17V17ZM22 24C20.1435 24 18.363 24.7375 17.0503 26.0503C15.7375 27.363 15 29.1435 15 31H29C29 29.1435 28.2625 27.363 26.9497 26.0503C25.637 24.7375 23.8565 24 22 24V24Z"
                    stroke="#0F0931"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              <Img
                src={closeIcon}
                alt="close"
                cursor="pointer"
                onClick={showMenu}
              />
            </Box>
            <Box display="flex" flexDir="column" mt="30px">
              <Box position="relative" marginBottom="30px">
                <Link to="/">
                  <Text
                    color={location.pathname === "/" ? "#632EFF" : "#0F0931"}
                    cursor={"pointer"}
                    fontWeight={500}
                    fontSize={{ base: "14px", lg: "18px" }}
                    letterSpacing={"0.05em"}
                    lineHeight="20px"
                    _hover={{
                      //  transition: "all .5s",
                      opacity: "0.5",
                    }}
                  >
                    Home
                  </Text>
                </Link>
              </Box>

              <Link to="/experiences">
                <Box display="flex" alignItems="center" marginBottom="30px">
                  <Text
                    color={
                      location.pathname === "/experiences"
                        ? "#632EFF"
                        : "#0F0931"
                    }
                    letterSpacing={"0.05em"}
                    cursor={"pointer"}
                    fontWeight={500}
                    fontSize={{ base: "14px", lg: "18px" }}
                    lineHeight="20px"
                    _hover={{
                      transition: "all .5s",
                      opacity: "0.5",
                    }}
                  >
                    Experiences
                  </Text>
                </Box>
              </Link>

              <Box position="relative" marginBottom="30px">
                <Box
                  onClick={() => setOpenCity(!openCity)}
                  cursor="pointer"
                  display="flex"
                  alignItems="center"
                  _hover={{
                    transition: "all .5s",
                    opacity: "0.5",
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes("/collections")
                        ? "#632EFF"
                        : "#0F0931"
                    }
                    cursor={"pointer"}
                    letterSpacing={"0.05em"}
                    fontWeight={500}
                    fontSize={{ base: "14px", lg: "16px" }}
                    lineHeight="20px"
                    mr="10px"
                  >
                    City Guide
                  </Text>
                  <Img
                    src={DropdownBlack}
                    alt="drop"
                    style={{ width: "14px" }}
                    transform={openCity && "rotate(180deg)"}
                    transition="all .5s"
                  />
                </Box>

                {openCity && (
                  <Box>
                    {!loading &&
                      city &&
                      Object.keys(city)
                        .filter((item) => city[item].enabled === true)
                        .map((item, id) => (
                          <Text
                            key={id}
                            color="#0F0931"
                            cursor={"pointer"}
                            letterSpacing={"0.05em"}
                            fontSize={{ base: "14px", lg: "18px" }}
                            lineHeight="20px"
                            marginTop="14px"
                            opacity={city[item].enabled ? 1 : 0.3}
                            onClick={() => {
                              // city[item].enabled && navigate(`/city/${item}`);
                              city[item].enabled &&
                                navigate(`/collections/${item}`);
                            }}
                            textTransform="capitalize"
                            _hover={{
                              color: color === "dark" ? "#fff" : "#632EFF",
                              transform: "scale(1.05)",
                              fontWeight: 500,
                            }}
                          >
                            {item}
                          </Text>
                        ))}
                  </Box>
                )}
              </Box>

              <Link to="/partner">
                <Box display="flex" alignItems="center" marginBottom="30px">
                  <Text
                    color={
                      location.pathname === "/partner" ? "#632EFF" : "#0F0931"
                    }
                    letterSpacing={"0.05em"}
                    cursor={"pointer"}
                    fontWeight={500}
                    fontSize={{ base: "14px", lg: "18px" }}
                    lineHeight="20px"
                    _hover={{
                      transition: "all .5s",
                      opacity: "0.5",
                    }}
                  >
                    Businesses
                  </Text>
                </Box>
              </Link>
              {/* {navLinks.map((item, index) => {
                return (
                  <>
                   
                  </>
                );
              })} */}

              {role ? (
                <Box>
                  <Button
                    color="#ffffff"
                    bg="#632EFF"
                    borderRadius="28px"
                    fontWeight={500}
                    fontSize={18}
                    marginTop="30px"
                    onClick={() => signout()}
                  >
                    Logout
                  </Button>
                </Box>
              ) : (
                <Box position="relative" marginBottom="30px">
                  <Box
                    onClick={() => setOpenSignIn(!openSignIn)}
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    _hover={{
                      transition: "all .5s",
                      opacity: "0.5",
                    }}
                  >
                    <Text
                      color={
                        location.pathname.includes("signin")
                          ? "#632EFF"
                          : "#0F0931"
                      }
                      cursor={"pointer"}
                      letterSpacing={"0.05em"}
                      fontWeight={500}
                      fontSize={{ base: "14px", lg: "18px" }}
                      lineHeight="20px"
                      mr="10px"
                    >
                      Sign In
                    </Text>
                    <Img
                      src={DropdownBlack}
                      alt="drop"
                      style={{ width: "14px" }}
                      transform={openSignIn && "rotate(180deg)"}
                      transition="all .5s"
                    />
                  </Box>

                  {openSignIn && (
                    <Box marginTop="14px">
                      <Text
                        color="#0F0931"
                        cursor={"pointer"}
                        fontSize={{ base: "14px", lg: "16px" }}
                        lineHeight="20px"
                        letterSpacing={"0.05em"}
                        marginBottom="14px"
                        onClick={() => navigate("/signin")}
                        _hover={{
                          color: "#632EFF",
                          // transform: "scale(1.05)",
                        }}
                      >
                        As a navigator
                      </Text>
                      <Text
                        color="#0F0931"
                        cursor={"pointer"}
                        fontSize={{ base: "14px", lg: "16px" }}
                        letterSpacing={"0.05em"}
                        lineHeight="20px"
                        onClick={() => navigate("/partner/signin")}
                        // textTransform="capitalize"
                        _hover={{
                          color: "#632EFF",
                          // transform: "scale(1.05)",
                        }}
                      >
                        As a business
                      </Text>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </MobileMenu>
        </HStack>
      </Box>
    </Container>
  );
};

export default Navbar;
