import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 Button,
 List,
 ListItem,
 SimpleGrid,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Switch,
 InputRightElement,
 InputLeftElement,
 InputGroup,
 Image,
 RadioGroup,
 Radio,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import { Button as CustomButton } from "../../../../components";
import { searchInputStyle, inputStyle } from "../styles";
import { colors } from "../../../../assets/styles/config";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { AuthContext } from "../../../../context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoadingIndicator, Modal } from "../../../../components";
import { Card, Container } from "../styles";
import TrashIcon from "../../../../assets/images/trash-2.svg";
import Warning from "../../../../assets/images/warning-icon.svg";

const Bank = () => {
 const { user, updatePartner } = useContext(AuthContext);
 const [loading, setLoading] = useState(false);
 const navigate = useNavigate();
 const [accounts, setAccount] = useState([]);
 const [filterAccount, setFilterAccount] = useState([]);
 const [modalType, setModalType] = useState("");
 const [visible, setVisible] = useState(false);
 const [selected, setSelected] = useState(null);
 const containerRef = useRef(null);
 const paystackUrl = "https://api.paystack.co";
 const secretKey = process.env.REACT_APP_PAYSTACK_SECRET_KEY;
 //  PRODUCTION
 //  const secretKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;

 const searchFilter = (text) => {
  if (text) {
   const newData = accounts.filter((item) => {
    const itemData = item.business_name
     ? item.business_name.toUpperCase()
     : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setFilterAccount(newData);
  } else {
   setFilterAccount(accounts);
  }
 };

 const fetchAccount = async () => {
  setLoading(true);
  const paystackResponse = await axios.get(`${paystackUrl}/subaccount`, {
   headers: {
    Authorization: `Bearer ${secretKey}`,
   },
  });

  if (paystackResponse?.data?.status === true) {
   setAccount(paystackResponse?.data?.data);
   setLoading(false);
  } else {
   setLoading(false);
  }
 };

 useEffect(() => {
  setFilterAccount(accounts);
 }, [accounts]);

 useEffect(() => {
  fetchAccount();
 }, [user]);

 const handleDelete = () => {};
 return (
  <Box height="100%" padding="20px" background="#F5F8FA">
   <Box background="#FFFFFF" borderRadius="6px" minHeight="80vh" padding="30px">
    <Flex
     alignItems="flex-start"
     flexDir={"row"}
     justifyContent="space-between"
    >
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>

     <Box display={{ base: "none", md: "flex" }} alignItems="center">
      <CustomButton
       animateOnTap
       animateOnHover
       buttonColor="#632EFF"
       css={css`
        width: fit-content;
        padding: 0.5rem 2rem;
        height: auto;
        font-weight: 500;
        font-family: "Creato Display";
        display: flex;
        gap: 15px;

        @media screen and (max-width: 768px) {
         border-radius: 10px;
        }
       `}
       onClick={() => {
        navigate("/admin/add-bank");
       }}
      >
       <AddIcon h="16px" w="16px" /> Add New Bank
      </CustomButton>
     </Box>
     <Box display={{ base: "flex", md: "none" }} alignItems="center">
      <CustomButton
       animateOnTap
       animateOnHover
       buttonColor="#632EFF"
       css={css`
        height: auto;
        font-weight: 500;
        font-family: "Creato Display";
        border-radius: 100px;
       `}
       onClick={() => {
        navigate("/admin/roles/add");
       }}
      >
       <AddIcon h="16px" w="16px" />
      </CustomButton>
     </Box>
    </Flex>
    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt="30px">
      {accounts?.length > 0 ? (
       <TableContainer
        border="1px solid #E2E8F0"
        borderRadius="16px 16px 0px 0px"
       >
        <Table variant="simple">
         <Thead>
          <Tr>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Partner name
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Bank name
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Account Number
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Commission value
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           ></Th>
          </Tr>
         </Thead>
         <Tbody>
          {filterAccount?.map((item, index) => (
           <Tr
            key={index}
            cursor="pointer"
            _hover={{
             background: "#F1F5F9",
            }}
           >
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color="#334155"
             onClick={() => {
              navigate(`/admin/add-bank/edit/${item.id}`);
             }}
            >
             {item?.business_name}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color="#334155"
             onClick={() => {
              navigate(`/admin/add-bank/edit/${item.id}`);
             }}
            >
             {item?.settlement_bank}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color="#334155"
             onClick={() => {
              navigate(`/admin/add-bank/edit/${item.id}`);
             }}
            >
             {item?.account_number}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color="#334155"
             onClick={() => {
              navigate(`/admin/add-bank/edit/${item.id}`);
             }}
            >
             {parseFloat(item?.percentage_charge)}%
            </Td>
            <Td
             padding="14px 20px"
             display="flex"
             alignItems="center"
             //   gap="20px"
            >
             <Box
              cursor="pointer"
              onClick={() => {
               setVisible(!visible);
               setModalType("delete");
               setSelected(item.id);
              }}
             >
              <Image src={TrashIcon} />
             </Box>
            </Td>
           </Tr>
          ))}
         </Tbody>
        </Table>
       </TableContainer>
      ) : (
       <Box display="flex" justifyContent="center" alignItems="center">
        <Text
         fontSize={{ base: "20px" }}
         lineHeight="125%"
         fontWeight="700"
         fontFamily="Satoshi"
         color="#334155"
        >
         No Partner Settlement Bank
        </Text>
       </Box>
      )}
     </Box>
    )}
   </Box>
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
    >
     {modalType === "delete" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Settlement Bank
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you want to delete this bank settlement?
        </Text>
        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setModalType("");
          }}
         >
          Close
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default Bank;
