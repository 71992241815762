import React, {
 useState,
 useEffect,
 useContext,
 useRef,
 useCallback,
} from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Select as ChakraSelect,
 Image,
 RadioGroup,
 Radio,
 InputGroup,
 InputRightElement,
 useDisclosure,
 Popover,
 PopoverContent,
 Button,
} from "@chakra-ui/react";
import { colors } from "../../../../assets/styles/config";
import { inputStyle, selectStyle } from "../../../Admin/Dashboard/styles";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import Cloud from "../../../../assets/images/cloud-plus.svg";
import AddIcon from "../../../../assets/images/Add.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import DropZone, { useDropzone } from "react-dropzone";
import { bytesToSize, flatten } from "../../../../utils/index";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import {
 getDatabase,
 ref as dbRef,
 set,
 child,
 get,
 onValue,
 update,
 remove,
} from "firebase/database";
import { storage, db } from "../../../../firebase";
import Select from "react-select";
//import { TimePicker, DatePicker } from "formik-material-ui-pickers";
import toast from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
 MuiPickersUtilsProvider,
 KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
 sendPartnerPushNotification,
 sendPushNotification,
} from "../../../../api/user";

const validationSchema = Yup.object({});

const AddDeal = () => {
 const { user } = useContext(AuthContext);
 const [isEdit, setIsEdit] = useState(false);
 const params = useParams();
 const navigate = useNavigate();
 const expId = params?.expId;
 const [partnerOption, setPartnerOption] = useState([]);
 const [loading, setLoading] = useState(false);
 const { isOpen, onToggle, onClose } = useDisclosure();
 const [allPartner, setAllPartner] = useState([]);
 const [partner, setPartner] = useState({});
 const [experiences, setExperiences] = useState([]);
 //"Buy one get one free"
 const uid = user?.partnerUid ?? user?.uid;

 const couponType = ["Percentage off"];
 const limit = ["1 - 10", "11 - 20", "30 - 50", "50 and above"];

 const fetchPartner = async () => {
  const db = await get(child(dbRef(getDatabase()), `users/partner`)).then(
   (snapshot) => {
    if (snapshot.exists()) {
     const myObj = snapshot.val();

     setPartner(
      Object.keys(myObj)
       .map((key) => {
        return myObj[key];
       })
       ?.filter((item) => item?.uid === uid)[0]
     );
    }
   }
  );

  return db;
 };

 const fetchExperience = async () => {
  //get user experience
  const db = await get(
   child(dbRef(getDatabase()), `cityExperience/experiences/`)
  ).then((snapshot) => {
   setLoading(false);
   if (snapshot.exists()) {
    let objExp = snapshot.val();
    const uid = user?.partnerUid ?? user?.uid;
    setExperiences(
     Object.keys(objExp)
      .map((key) => {
       return objExp[key];
      })
      ?.filter((item) => item?.partnerUid === uid)
      ?.map((item) => {
       return {
        value: item?.id,
        label: item?.title,
       };
      })
    );
   }
  });

  return db;
 };

 useEffect(() => {
  fetchPartner();
  fetchExperience();
 }, [user, isEdit]);

 useEffect(() => {
  if (location.pathname.includes("edit")) {
   setIsEdit(true);
  }
 }, []);

 return (
  <Box
   height="100%"
   padding={{ base: "0px 20px", md: "14px 20px" }}
   background="#F5F8FA"
  >
   <Box
    display={{ base: "none", md: "flex" }}
    alignItems="center"
    cursor="pointer"
    onClick={() => navigate(-1)}
   >
    <Image src={ArrowLeft} />
    <Text color="#252046" marginLeft="10px">
     Go Back
    </Text>
   </Box>

   <Formik
    initialValues={{
     couponType: "Percentage off",
     partner: "",
     experiences: "",
     couponName: "",
     couponCode: "",
     percentageOff: "",
     // userLimit: "",
     currentUsage: "",
     expiryDate: Date.now(),
    }}
    onSubmit={async (values) => {
     const coupon = partner?.coupon?.filter(
      (item) => Number(item?.experiences?.id) === Number(expId)
     )[0];
     const db = getDatabase();
     // const currentUsage = determinglimit(values?.userLimit);

     if (!isEdit && !values.experiences.value) {
      toast.error("Select an experience for the coupon");
      setTimeout(() => {
       toast.dismiss();
      }, 3000);
      return;
     }
     toast.loading("loading");

     const payload = {
      ...values,
      // expiryDate: JSON.stringify(values?.expiryDate),
      active: isEdit ? coupon?.active : true,
      partner: {
       values: partner?.uid,
       label: partner?.businessName,
      },
      experiences: isEdit
       ? coupon?.experiences
       : {
          title: values?.experiences?.label,
          id: values?.experiences?.value,
         },
      // currentUsage,
     };

     const isExist = partner?.coupon
      ? partner?.coupon.some(
         (item) => item.experiences?.id === payload.experiences?.id
        )
      : false;

     // console.log("isExist", isExist);
     if (!isEdit) {
      if (!isExist) {
       const updates = {};

       updates["users/partner/" + partner?.uid] = {
        ...partner,
        coupon: partner?.coupon ? [...partner?.coupon, payload] : [payload],
       };

       await update(dbRef(db), updates)
        .then(async (res) => {
         if (user) {
          let name = user?.firstName
           ? `${user?.firstName} ${user?.lastName}`
           : user?.name;
          await sendPartnerPushNotification({
           title: "partner",
           body: `${name} added a new coupon`,
           data: {
            uid: user?.partnerUid ?? user?.uid,
            route: `/partner/manage-deals`,
            name: name,
            photoURL: user?.partnerPhotoURL ?? user?.photoURL,
            push_time: Date.now(),
           },
          });
         }
         toast.dismiss();
         toast.success("Successfully added coupon");
         navigate(`/partner/manage-deals`);
        })
        .catch((err) => {
         toast.dismiss();
         toast.error(err);
        })
        .finally(() => {
         setTimeout(() => {
          toast.dismiss();
         }, 3000);
        });
      } else {
       toast.dismiss();
       toast.error("Coupon already exist for this experience");

       setTimeout(() => {
        toast.dismiss();
       }, 3000);
      }
     } else {
      const updates = {};
      updates["users/partner/" + partner?.uid] = {
       ...partner,
       coupon: partner?.coupon.map((item) => {
        if (item.experiences?.id === payload.experiences?.id) {
         return payload;
        }
        return item;
       }),
      };

      await update(dbRef(db), updates)
       .then(async (res) => {
        toast.dismiss();
        if (user) {
         let name = user?.firstName
          ? `${user?.firstName} ${user?.lastName}`
          : user?.name;
         await sendPartnerPushNotification({
          title: "partner",
          body: `${name} updated ${payload.couponName} coupon`,
          data: {
           uid: user?.partnerUid ?? user?.uid,
           route: `/partner/manage-deals/edit/${payload.experiences?.id}`,
           name: name,
           photoURL: user?.partnerPhotoURL ?? user?.photoURL,
           push_time: Date.now(),
          },
         });
        }

        toast.success("Successfully updated coupon");
        navigate(`/partner/manage-deals`);
       })
       .catch((err) => {
        toast.dismiss();
        toast.error(err);
       })
       .finally(() => {
        setTimeout(() => {
         toast.dismiss();
        }, 3000);
       });
     }
    }}
    //  validationSchema={validationSchema}
   >
    {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => {
     useEffect(() => {
      if (isEdit && partner) {
       const coupon = partner?.coupon?.filter(
        (item) => Number(item?.experiences?.id) === Number(expId)
       )[0];

       setFieldValue("couponType", coupon?.couponType, false);
       setFieldValue("experiences", coupon?.experiences?.title, false);
       setFieldValue("couponName", coupon?.couponName, false);
       setFieldValue("couponCode", coupon?.couponCode, false);
       setFieldValue("percentageOff", coupon?.percentageOff, false);
       setFieldValue("currentUsage", coupon?.currentUsage, false);
       setFieldValue("expiryDate", coupon?.expiryDate, false);
      }
     }, [isEdit, partner]);
     return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
       <form onSubmit={handleSubmit}>
        <Box
         background="#FFFFFF"
         borderRadius="6px"
         padding={{ base: "10px", md: "30px" }}
         width="100%"
         mt="16px"
        >
         <Box mt="20px" mb={{ base: "20px", md: "0px" }}>
          <FormControl
           display="flex"
           alignItems="flex-start"
           flexDirection="column"
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Coupon type
           </FormLabel>
           <Field name="couponType">
            {({ field, form }) => {
             const { onChange, ...rest } = field;
             return (
              <FormControl
               id="couponType"
               backgroundColor="#F1F5F9"
               borderRadius="24px"
               padding="16px 24px"
               width={{ md: "50%" }}
               marginBottom="20px"
              >
               <RadioGroup {...rest} id="couponType" display="flex" gap="20px">
                {couponType.map((value) => (
                 <Radio onChange={onChange} value={value} colorScheme="purple">
                  {value}
                 </Radio>
                ))}
               </RadioGroup>
              </FormControl>
             );
            }}
           </Field>
          </FormControl>

          {/* <FormControl
                      display="flex"
                      w={{base: "100%", md: "478px"}}
                      flexDir="column"
                      alignItems="flex-start"
                      mb="20px"
                      isRequired
                    >
                      <FormLabel
                        fontFamily="Satoshi"
                        color="#0F0931"
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight="21px"
                        fontWeight="700"
                      >
                        Partner
                      </FormLabel>

                      <Field
                        as={Input}
                        {...inputStyle}
                        type="text"
                        placeholder=""
                        id="partner"
                        name="partner"
                        pointerEvents="none"
                      />
                    </FormControl> */}

          {isEdit ? (
           <FormControl
            display="flex"
            w={{ base: "100%", md: "478px" }}
            flexDir="column"
            alignItems="flex-start"
            mb="20px"
            isRequired
           >
            <FormLabel
             fontFamily="Satoshi"
             color="#0F0931"
             fontSize={{ base: "12px", md: "14px" }}
             lineHeight="21px"
             fontWeight="700"
            >
             Experience
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             placeholder=""
             id="experiences"
             name="experiences"
             pointerEvents="none"
            />
           </FormControl>
          ) : (
           experiences?.length > 0 && (
            <FormControl
             display="flex"
             w={{ base: "100%", md: "478px" }}
             flexDir="column"
             alignItems="flex-start"
             mb="20px"
             isRequired
            >
             <FormLabel
              fontFamily="Satoshi"
              color="#0F0931"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="21px"
              fontWeight="700"
             >
              Select experience to give a percentage off
             </FormLabel>

             <Select
              name="experiences"
              id="experiences"
              value={values.experiences}
              onChange={(option) => setFieldValue("experiences", option)}
              options={experiences}
              onBlur={handleBlur}
              styles={{
               control: (provided, state) => ({
                ...provided,
                // width: "50%",
                borderRadius: "66px",
                background: "#F1F5F9",
                autoFocus: true,
                "&:hover": {
                 background: "#F3F0FF",
                 border: "1px solid #AC98FE",
                },
                "&:focus": {
                 background: "#F3F0FF",
                },
                "&:focusVisible": {
                 border: "1px solid #AC98FE",
                },
               }),
              }}
             />
            </FormControl>
           )
          )}

          <FormControl
           w={{ base: "100%", md: "478px" }}
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="20px"
           isRequired
           isInvalid={!!errors.couponName && touched.couponName}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Coupon name
           </FormLabel>
           <Field
            as={Input}
            {...inputStyle}
            type="text"
            placeholder=""
            id="couponName"
            name="couponName"
           />
           <FormErrorMessage>{errors.couponName}</FormErrorMessage>
          </FormControl>

          <FormControl
           w={{ base: "100%", md: "478px" }}
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="20px"
           isRequired
           isInvalid={!!errors.couponCode && touched.couponCode}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Coupon code
           </FormLabel>
           <Field
            as={Input}
            {...inputStyle}
            type="text"
            placeholder=""
            id="couponCode"
            name="couponCode"
           />

           <FormErrorMessage>{errors.couponCode}</FormErrorMessage>
          </FormControl>
          {values?.couponType === "Percentage off" && (
           <FormControl
            w={{ base: "100%", md: "478px" }}
            display="flex"
            flexDir="column"
            alignItems="flex-start"
            mb="20px"
            isRequired
            isInvalid={!!errors.percentageOff && touched.percentageOff}
           >
            <FormLabel
             fontFamily="Satoshi"
             color="#0F0931"
             fontSize={{ base: "12px", md: "14px" }}
             lineHeight="21px"
             fontWeight="700"
            >
             Percentage off
            </FormLabel>
            <Field
             component={PercentageInput}
             {...inputStyle}
             type="number"
             placeholder=""
             id="percentageOff"
             name="percentageOff"
            />

            <FormErrorMessage>{errors.percentageOff}</FormErrorMessage>
           </FormControl>
          )}

          <FormControl
           w={{ base: "100%", md: "478px" }}
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="20px"
           isRequired
           isInvalid={!!errors.currentUsage && touched.currentUsage}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            User limit
           </FormLabel>
           <Box position="relative" width="100%">
            <Field
             as={Input}
             {...inputStyle}
             type="number"
             id="currentUsage"
             name="currentUsage"
            />
            {/* <Box
                          background="#F1F5F9"
                          width="100%"
                          borderRadius="66px"
                          padding="10px 24px"
                          onClick={onToggle}
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          _hover={{
                            background: "#F3F0FF",
                            border: "1px solid #AC98FE",
                          }}
                          _focus={{
                            background: "#F3F0FF",
                          }}
                          _focusVisible={{
                            background: "#F3F0FF",
                          }}
                        >
                          <Text>{values?.userLimit}</Text>
                          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </Box>
                        <Popover
                          isOpen={isOpen}
                          onClose={onClose}
                          placement="top-end"
                        >
                          <PopoverContent
                            bg="#FFFFFF"
                            borderRadius="20px"
                            boxShadow="0px 4px 24px #D4DEEC"
                            // position="absolute"
                            padding="10px 20px"
                            outline="none"
                            width="40%"
                            display="flex"
                            alignItems="center"
                            gap="20px"
                            flexDir="column"
                          >
                            {limit?.map((item, index) => (
                              <Text
                                key={index}
                                width="100%"
                                textAlign="start"
                                padding="10px 0px"
                                borderBottom="1px solid #E2E8F0"
                                _last={{
                                  borderBottom: "none",
                                }}
                                fontWeight="500"
                                fontFamily="Satoshi"
                                cursor="pointer"
                                onClick={() => {
                                  setFieldValue("userLimit", item);
                                  onToggle();
                                }}
                              >
                                {item}
                              </Text>
                            ))}
                          </PopoverContent>
                        </Popover> */}
           </Box>

           <FormErrorMessage>{errors.couponCode}</FormErrorMessage>
          </FormControl>

          <FormControl
           w={{ base: "100%", md: "478px" }}
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="20px"
           isRequired
           isInvalid={!!errors.percentageOff && touched.percentageOff}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Exiry date
           </FormLabel>
           <KeyboardDatePicker
            id="date-picker-dialog"
            label="Date picker dialog"
            inputVariant="outlined"
            format="dd MMMM yyyy"
            value={getIn(values, `expiryDate`)}
            onChange={(value) => {
             setFieldValue(`expiryDate`, value);
            }}
            KeyboardButtonProps={{
             "aria-label": "change date",
            }}
            disablePast
           />

           <FormErrorMessage>{errors.percentageOff}</FormErrorMessage>
          </FormControl>

          <Button
           color={colors.primaryWhite}
           bg={colors.primaryPurple}
           borderRadius={28}
           fontWeight={500}
           fontSize={18}
           p="24px 20px"
           width={{ base: "100%", md: "20%" }}
           marginTop="30px"
           type="submit"
          >
           {isEdit ? "Update Coupon" : "Add coupon"}
          </Button>
         </Box>
        </Box>
       </form>
      </MuiPickersUtilsProvider>
     );
    }}
   </Formik>
  </Box>
 );
};

export default AddDeal;

function PercentageInput({ field, form, ...props }) {
 const { name, value } = field;
 const handleChange = (e) => {
  const newValue = e.target.value;
  form.setFieldValue(name, newValue);
 };

 return (
  <InputGroup>
   <Field name={name}>
    {({ field }) => (
     <Input
      {...props}
      {...field}
      type="number"
      placeholder="Enter percentage"
      value={value}
      onChange={handleChange}
     />
    )}
   </Field>
   <InputRightElement children={<Text>%</Text>} />
  </InputGroup>
 );
}
