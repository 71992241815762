import * as Yup from "yup";

export const validationSchema = Yup.object({
 firstName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("first name is required"),
 lastName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("last name is required"),
 phoneNumber: Yup.string()
  .matches(
   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
   "Invalid phone number"
  )
  .required("Phone number is Required"),
 email: Yup.string()
  .email("Invalid email address")
  .required("Email is required"),

 businessName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("Business name is required"),
 businessAddress: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("Business address is required"),
 city: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("city is required"),
 businessPhoneNumber: Yup.string()
  .matches(
   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
   "Invalid phone number"
  )
  .required("Business phone number is Required"),
 businessEmail: Yup.string()
  .email("Invalid email address")
  .required("Business email is required"),
});

export const initialValues = {
 businessOpen: "",
 registeringAs: "business",
 establishmentType: "",
 firstName: "",
 lastName: "",
 email: "",
 bio: "",
 phoneNumber: "",
 category: "",
 tags: "",
 businessName: "",
 businessEmail: "",
 businessPhoneNumber: "",
 businessAddress: "",
 city: "",
 instagram: "",
 twitter: "",
 facebook: "",
 tiktok: "",
 linkedin: "",
 website: "",
 day: {
  monday: {
   start: "",
   end: "",
  },
  tuesday: {
   start: "",
   end: "",
  },
  wednesday: {
   start: "",
   end: "",
  },
  thursday: {
   start: "",
   end: "",
  },
  friday: {
   start: "",
   end: "",
  },
  saturday: {
   start: "",
   end: "",
  },
  sunday: {
   start: "",
   end: "",
  },
 },
};
