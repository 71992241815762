import React, { useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Navbar from "../../components/Header";
import PartnersDashboardShowcase from "../../assets/images/partners-dashboard-showcase.png";
import PartnersTrustedBy from "../../assets/images/partners-trusted-by.png";
import ShowcaseOne from "../../assets/images/showcase-one.png";
import ShowcaseTwo from "../../assets/images/showcase-two.png";
import ShowcaseThree from "../../assets/images/showcase-three.png";
import {
 BottomCard,
 BottomCardInfo,
 BottomCardInfoList,
 BottomCardPricing,
 BottomCards,
 BottomHeading,
 BottomLikeHeroWrapper,
 BottomSubTitle,
 CalfCardsWrapper,
 CalfHeading,
 CalfSubHeading,
 FirstPulse,
 HeroGrid,
 HeroHeading,
 HeroImg,
 HeroImgPulseWrapper,
 HeroSubTitle,
 HeroWrapper,
 MidHeading,
 MidShowcaseHeading,
 MidShowcaseInfoWrapper,
 MidShowcaseText,
 MidShowcaseTextWrapper,
 MidShowcaseWrapper,
 MidSubHeading,
 SingleCard,
 SingleCardImg,
 SingleCardText,
} from "./styles";
import { Button, Footer, Loader } from "../../components";
import { css } from "styled-components";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Bounce, Fade, Flip, Slide, Zoom } from "react-reveal";
import Pulse from "react-reveal/Pulse";
import Flash from "react-reveal/Flash";
import Jump from "react-reveal/Jump";
import Reveal from "react-reveal/Reveal";
import * as showcaseImages from "../../assets/strings/imageExports";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import { colors } from "../../assets/styles/config";

const showcaseCards = [
 {
  id: 9,
  coverImage: showcaseImages.museum,
  collectionName: "Museums",
 },
 {
  id: 0,
  coverImage: showcaseImages.restaurants,
  collectionName: "Restaurants",
 },
 {
  id: 1,
  coverImage: showcaseImages.zoos,
  collectionName: "Zoos",
 },
 {
  id: 2,
  coverImage: showcaseImages.parks,
  collectionName: "Parks",
 },
 {
  id: 3,
  coverImage: showcaseImages.servicedApartments,
  collectionName: "Serviced Apartments",
 },
 {
  id: 4,
  coverImage: showcaseImages.spas,
  collectionName: "Spas",
 },
 {
  id: 5,
  coverImage: showcaseImages.cinemas,
  collectionName: "Cinemas & Theatres",
 },
 {
  id: 6,
  coverImage: showcaseImages.bars,
  collectionName: "Bars",
 },
 {
  id: 7,
  coverImage: showcaseImages.cafes,
  collectionName: "Cafes",
 },
 {
  id: 16,
  coverImage: showcaseImages.amusementParks,
  collectionName: "Amusement Parks",
 },
 {
  id: 8,
  coverImage: showcaseImages.beaches,
  collectionName: "Beaches",
 },
 {
  id: 11,
  coverImage: showcaseImages.hotels,
  collectionName: "Hotels",
 },
 {
  id: 10,
  coverImage: showcaseImages.coworkingSpace,
  collectionName: "Co-working Spaces",
 },
 {
  id: 12,
  coverImage:
   "https://img.theculturetrip.com/wp-content/uploads/2022/08/lux-club.jpg",
  collectionName: "Clubs",
 },
 {
  id: 13,
  coverImage: showcaseImages.artGalleries,
  collectionName: "Art Galleries",
 },
];

const PartnerHomePage = () => {
 const navigate = useNavigate();
 const { role, partner, isLoading } = useAuthContext(AuthContext);
 const isPartner = role === "PARTNER";
 const isPartnerSubscribed = isPartner && partner.subscribed;
 console.log(partner);
 if (isLoading) {
  return <Loader />;
 }

 return (
  <div>
   <Navbar bgColor={"#0F0931"} color="light" />
   <Hero isPartner={isPartner} navigate={navigate} />
   <MidSection />
   <MidShowcase />
   <Box
    // maxWidth={"1540px"}
    marginInline={"auto"}
   >
    <CalfSection />
    <CalfCards />
    {isPartner && isPartnerSubscribed ? null : (
     <BottomLikeHero
      isPartnerSubscribed={isPartnerSubscribed}
      navigate={navigate}
      isPartner={isPartner}
     />
    )}
   </Box>
   <Footer />
  </div>
 );
};

const Hero = ({ navigate, isPartner }) => {
 return (
  <HeroWrapper>
   {" "}
   <div>
    <Fade bottom>
     <HeroHeading>
      Gain visibility and <br />
      manage your experiences better
     </HeroHeading>

     <HeroSubTitle>
      Whether you are a business or an individual offering unique experiences,
      our platform is designed to help you showcase and sell your offerings to a
      wider audience.
     </HeroSubTitle>

     <Button
      title="Get Started"
      animateOnHover
      animateOnTap
      css={css`
       width: 202px;
       min-height: 56px;
       background-color: #632eff;
       margin: 0px auto;
       border-radius: 54px;
       transition: 0.2s;
       display: ${isPartner ? "none" : ""};

       &:hover {
        background-color: hsl(249, 70%, 47%);
       }

       &:hover .icon {
        transition: 0.2s;
       }

       &:hover .icon {
        margin-left: 10px;
        scale: 1.1;
       }

       @media (min-width: 1080px) {
        width: 272px;
        min-height: 66px;
       }
      `}
      titleCSS={css`
       font-size: 14px;
       letter-spacing: 1.2px;
       font-weight: 700;
       margin-right: 8px;

       @media (min-width: 768px) {
        font-size: 16px;
       }

       @media (min-width: 1080px) {
        font-size: 18px;
       }
      `}
      iconName={"getStarted"}
      iconPosition={"right"}
      iconSize={23}
      onClick={() => navigate("/partner/onboarding")}
     />

     <Button
      title="Upload Experience"
      animateOnHover
      animateOnTap
      css={css`
       width: 202px;
       min-height: 56px;
       background-color: #632eff;
       margin: 0px auto;
       border-radius: 54px;
       transition: 0.2s;
       display: ${!isPartner ? "none" : ""};

       &:hover {
        background-color: hsl(249, 70%, 47%);
       }

       &:hover .icon {
        transition: 0.2s;
       }

       &:hover .icon {
        margin-left: 10px;
        scale: 1.1;
       }

       @media (min-width: 1080px) {
        width: 272px;
        min-height: 66px;
       }
      `}
      titleCSS={css`
       font-size: 14px;
       letter-spacing: 1.2px;
       font-weight: 700;
       margin-right: 8px;

       @media (min-width: 768px) {
        font-size: 16px;
       }

       @media (min-width: 1080px) {
        font-size: 18px;
       }
      `}
      iconName={"getStarted"}
      iconPosition={"right"}
      iconSize={23}
      onClick={() => navigate("/partner/experience/add")}
     />
    </Fade>
   </div>
   <HeroGrid>
    <HeroImgPulseWrapper>
     {/* Inside Pulse 3 */}

     <div className="layer outermost-layer">
      <svg
       width="1372"
       height="891"
       viewBox="0 0 1372 891"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M37.9252 38.3686C38.8799 17.1841 56.3329 0.5 77.5389 0.5H1288.73C1309.83 0.5 1327.23 17.0218 1328.33 38.0923L1370.53 848.785C1371.71 871.464 1353.64 890.5 1330.93 890.5H41.0041C18.4032 890.5 0.373034 871.639 1.39054 849.061L37.9252 38.3686Z"
        fill="#554997"
        fill-opacity="0.08"
        stroke="url(#paint0_linear_3659_49156)"
       />
       <defs>
        <linearGradient
         id="paint0_linear_3659_49156"
         x1="109.5"
         y1="-186"
         x2="1419"
         y2="1015"
         gradientUnits="userSpaceOnUse"
        >
         <stop offset="0.039238" stop-color="#554997" stop-opacity="0.78" />
         <stop offset="0.521493" stop-color="#AEA4E3" stop-opacity="0.31" />
         <stop offset="0.911709" stop-color="#554997" stop-opacity="0.78" />
        </linearGradient>
       </defs>
      </svg>
     </div>

     {/* Inside Pulse 2 */}

     <div className="layer middle-layer">
      <svg
       width="1250"
       height="812"
       viewBox="0 0 1250 812"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M34.5075 34.9579C35.3762 15.6814 51.2572 0.5 70.5532 0.5H1174.01C1193.21 0.5 1209.05 15.5337 1210.05 34.7065L1248.5 773.292C1249.57 793.929 1233.13 811.25 1212.46 811.25H37.2681C16.7027 811.25 0.296479 794.088 1.22234 773.543L34.5075 34.9579Z"
        fill="#554997"
        fill-opacity="0.1"
        stroke="url(#paint0_linear_3659_49155)"
       />
       <defs>
        <linearGradient
         id="paint0_linear_3659_49155"
         x1="99.6716"
         y1="-169.456"
         x2="1292.7"
         y2="924.721"
         gradientUnits="userSpaceOnUse"
        >
         <stop offset="0.039238" stop-color="#554997" stop-opacity="0.78" />
         <stop offset="0.521493" stop-color="#AEA4E3" stop-opacity="0.31" />
         <stop offset="0.911709" stop-color="#554997" stop-opacity="0.78" />
        </linearGradient>
       </defs>
      </svg>
     </div>

     {/* Inside Pulse 1 */}

     <div className="layer innermost-layer">
      <svg
       width="1132"
       height="736"
       viewBox="0 0 1132 736"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M31.208 31.6653C31.9937 14.2308 46.3572 0.5 63.8094 0.5H1063.27C1080.63 0.5 1094.96 14.0972 1095.86 31.4379L1130.69 700.412C1131.66 719.077 1116.79 734.743 1098.1 734.743H33.6613C15.0611 734.743 0.222573 719.221 1.05996 700.64L31.208 31.6653Z"
        fill="#554997"
        fill-opacity="0.15"
        stroke="url(#paint0_linear_3659_49154)"
       />
       <defs>
        <linearGradient
         id="paint0_linear_3659_49154"
         x1="90.1834"
         y1="-153.485"
         x2="1170.77"
         y2="837.567"
         gradientUnits="userSpaceOnUse"
        >
         <stop offset="0.039238" stop-color="#554997" stop-opacity="0.78" />
         <stop offset="0.521493" stop-color="#AEA4E3" stop-opacity="0.31" />
         <stop offset="0.911709" stop-color="#554997" stop-opacity="0.78" />
        </linearGradient>
       </defs>
      </svg>
     </div>

     <HeroImg
      src={PartnersDashboardShowcase}
      alt="Partner's Dashboard Showcase"
      className="layer image-layer"
     />

     {/* Sploosh
          <div class="pulse"></div> */}
    </HeroImgPulseWrapper>
   </HeroGrid>
   {/* <Flex
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        gap="14px"
        paddingTop="66px"
      >
        <Text fontSize={20} color={"#64748B"}>
          Trusted By:
        </Text>
        <img src={PartnersTrustedBy} alt="" />
      </Flex> */}
  </HeroWrapper>
 );
};

const MidSection = () => {
 return (
  <Fade top>
   <MidHeading>
    Navi exposes you to local and global travelers and boosts your visibility.
   </MidHeading>
   <MidSubHeading>
    Our user-friendly interface makes it simple for businesses and individuals
    to set up their profiles, upload enticing descriptions, eye-catching images,
    and all the essential details that customers need to make an informed
    decision.
   </MidSubHeading>
  </Fade>
 );
};

const MidShowcase = () => {
 return (
  <MidShowcaseWrapper>
   <MidShowcaseInfoWrapper>
    <Fade left>
     <div>
      <img src={ShowcaseOne} alt="showcase-image-one" />
     </div>
    </Fade>
    <MidShowcaseTextWrapper>
     <Fade right>
      <MidShowcaseHeading>
       Streamlined ticketing and payment process
      </MidShowcaseHeading>
      <MidShowcaseText
       css={css`
        @media (max-width: 1080px) {
         display: none;
        }
       `}
      >
       Managing ticket sales and payments can be time-consuming, but not with
       our platform. We handle the entire ticketing process, from secure online
       bookings to automated confirmations and reliable payment processing,
       allowing you to focus on delivering exceptional experiences. You can also
       offer discounted deals on experiences by creating coupon codes for
       special NAVI-gators.
      </MidShowcaseText>
      <MidShowcaseText
       css={css`
        @media (min-width: 1080px) {
         display: none;
        }
       `}
      >
       Managing ticket sales and payments can be time-consuming, but not with
       our platform. Secure online bookings with automated confirmations and
       reliable payment processing, allowing you to focus on delivering
       exceptional experiences.
      </MidShowcaseText>
     </Fade>
    </MidShowcaseTextWrapper>
   </MidShowcaseInfoWrapper>
   <MidShowcaseInfoWrapper>
    <MidShowcaseTextWrapper>
     <Fade left>
      <MidShowcaseHeading>Flexible Listing Options</MidShowcaseHeading>
      <MidShowcaseText>
       Whether you're offering dining packages, staycation deals, intimate
       workshops, or tours, our platform accommodates all types of experiences
       and events. Showcase your offerings in the most compelling way possible
       and let customers book with ease.
      </MidShowcaseText>
     </Fade>
    </MidShowcaseTextWrapper>
    <Fade right>
     <div>
      <img src={ShowcaseTwo} alt="showcase-image-two" />
     </div>
    </Fade>
   </MidShowcaseInfoWrapper>
   <MidShowcaseInfoWrapper>
    <Fade left>
     <div>
      <img src={ShowcaseThree} alt="showcase-image-three" />
     </div>
    </Fade>

    <MidShowcaseTextWrapper>
     <Fade right>
      <MidShowcaseHeading>Flexible Booking Options</MidShowcaseHeading>
      <MidShowcaseText>
       We understand the importance of catering to different schedules and
       preferences. Our platform allows attendees to book for multiple dates and
       times, giving them the flexibility to choose what works best for them.
       Simplify the booking process and attract a wider audience
      </MidShowcaseText>
     </Fade>
    </MidShowcaseTextWrapper>
   </MidShowcaseInfoWrapper>
   <Text marginTop={"-26px"} textAlign={"center"}>
    To see more features,{" "}
    <a
     href="mailto:hello@thenaviapp.com"
     style={{ color: "#632EFF", textDecoration: "underline" }}
    >
     Book a Demo
    </a>
   </Text>
  </MidShowcaseWrapper>
 );
};

const CalfSection = () => {
 return (
  <Slide left>
   <CalfHeading>
    Built to drive success in today’s ambitious hospitality & events businesses
    visibility.
   </CalfHeading>
   <CalfSubHeading>
    At NAVI, we believe that exploring a city should be more than just visiting
    popular attractions. We are on a mission to revolutionize the way people
    discover and book experiences, restaurants, and hotels, all on one
    comprehensive platform.
   </CalfSubHeading>
  </Slide>
 );
};

const CalfCards = () => {
 return (
  <Reveal effect="fadeInUp">
   <CalfCardsWrapper>
    <Splide
     options={{
      perPage: 4,
      gap: "28px",
      pagination: false,
      arrows: false,
      autoplay: true,
      interval: 2000,
      rewind: true,

      breakpoints: {
       1080: {
        perPage: 3,
       },
       868: {
        perPage: 2,
       },
       600: {
        perPage: 1,
        pagination: true,
       },
      },
     }}
    >
     {showcaseCards.map((card) => {
      return (
       <SplideSlide key={card.id}>
        <SingleCard>
         <SingleCardImg src={card.coverImage} />
         <SingleCardText>{card.collectionName}</SingleCardText>
        </SingleCard>
       </SplideSlide>
      );
     })}
    </Splide>
   </CalfCardsWrapper>
  </Reveal>
 );
};

const BottomLikeHero = ({ navigate, isPartnerSubscribed, isPartner }) => {
 const location = window.location;
 useEffect(() => {
  console.log("location: " + location.hash);
  if (location.hash.includes("subscribe")) {
   scrollToSubscribe();
   console.log("scrolling");
  }
 }, [location]);

 const scrollToSubscribe = () => {
  const subscribeSection = document.getElementById("subscribe");
  if (subscribeSection) {
   subscribeSection.scrollIntoView({
    behavior: "smooth",
    block: "center",
   });
  }
 };
 return (
  <Box backgroundColor="#f6fcff" id="subscribe">
   <BottomLikeHeroWrapper>
    <div>
     <Fade bottom>
      <BottomHeading>
       {!isPartner
        ? "Join our platform "
        : !isPartnerSubscribed
        ? "Subscribe "
        : "Join our platform "}
       today and unlock the full potential of your tourism experiences and
       events.
      </BottomHeading>

      {/* <BottomSubTitle>
              Whether you are a business with a physical location, such as a
              restaurant, or resort, or an individual offering unique
              experiences like guided tours or activities. we provide the tools
              and exposure you need to thrive in the ever-evolving world of
              African tourism.
            </BottomSubTitle> */}

      {!isPartnerSubscribed && isPartner ? (
       <a href="mailto:hello@thenaviapp.com">
        {" "}
        <Button
         title="Contact Us"
         animateOnHover
         animateOnTap
         css={css`
          width: 302px;
          min-height: 56px;
          background-color: #632eff;
          margin: 0px auto;
          border-radius: 54px;
          transition: 0.2s;
         `}
         titleCSS={css`
          margin-right: 8px;
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 1.18px;
          text-align: left;
          color: #fff;
         `}
         iconPosition={"right"}
         iconSize={27}
        />
       </a>
      ) : (
       <Button
        title="Get Started"
        animateOnHover
        animateOnTap
        css={css`
         width: 202px;
         min-height: 56px;
         background-color: #632eff;
         margin: 0px auto;
         border-radius: 54px;
         transition: 0.2s;

         &:hover {
          background-color: hsl(249, 70%, 47%);
         }

         &:hover .icon {
          transition: 0.2s;
         }

         &:hover .icon {
          margin-left: 10px;
          scale: 1.1;
         }

         @media (min-width: 1080px) {
          width: 272px;
          min-height: 66px;
         }
        `}
        titleCSS={css`
         font-size: 14px;
         letter-spacing: 1.2px;
         font-weight: 700;
         margin-right: 8px;

         @media (min-width: 768px) {
          font-size: 16px;
         }

         @media (min-width: 1080px) {
          font-size: 18px;
         }
        `}
        iconName={"getStarted"}
        iconPosition={"right"}
        iconSize={23}
        onClick={() => navigate("/partner/onboarding")}
       />
      )}
     </Fade>
    </div>
    <BottomCards>
     <Fade left>
      <BottomCard>
       <BottomCardPricing>
        <p className="small">
         FREE PLAN{" "}
         <small>
          {!isPartnerSubscribed && isPartner ? "( Your current plan )" : null}
         </small>
        </p>
        <p>Access our basic features to get started</p>
        <h3>
         <svg
          width="33"
          height="36"
          viewBox="0 0 33 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path
           d="M0.5 12.0769H4.44872V0.230713H8.39744L15.1497 12.0769H24.1923V0.230713H28.141V12.0769H32.0897V16.0256H28.141V19.9743H32.0897V23.923H28.141V35.7692H24.1923L17.4203 23.923H8.39744V35.7692H4.44872V23.923H0.5V19.9743H4.44872V16.0256H0.5V12.0769ZM8.39744 12.0769H10.6285L8.39744 8.18738V12.0769ZM8.39744 16.0256V19.9743H15.1497L12.899 16.0256H8.39744ZM24.1923 27.8717V23.923H21.9218L24.1923 27.8717ZM17.4005 16.0256L19.671 19.9743H24.1923V16.0256H17.4005Z"
           fill="#632EFF"
          />
         </svg>
         0.00
        </h3>
       </BottomCardPricing>
       <BottomCardInfo>
        <h3>Get Access to:</h3>
        <BottomCardInfoList>
         <li>
          <Tick />
          5% fee for every paid experiences
         </li>
         <li>
          <Tick />
          Upload unlimited one-time experiences or special offers
         </li>
         <li>
          <Tick />
          Admin Dashboard
         </li>
         <li>
          <Tick />
          Upload 1 image per experience
         </li>
        </BottomCardInfoList>
       </BottomCardInfo>
       <Box paddingInline={"31px"} marginTop={"auto"}>
        {!isPartnerSubscribed && isPartner ? (
         <Text
          textAlign={"center"}
          mt={"-40px"}
          color={colors.primaryPurple}
          fontWeight={"bold"}
          fontSize={"lg"}
         >
          Your current plan
         </Text>
        ) : (
         <Button
          title="Get Started, It's Free!"
          animateOnHover
          animateOnTap
          css={css`
           background-color: transparent;
           margin: 0px auto;
           height: 65px;
           border-radius: 54px;
           transition: 0.2s;
           border: 1px solid #632eff;
           // margin-top: 157px;
          `}
          titleCSS={css`
           margin-right: 8px;
           font-size: 15px;
           font-weight: 600;
           line-height: 20px;
           letter-spacing: 1.18px;
           text-align: left;
           color: #632eff;
          `}
          iconPosition={"right"}
          iconSize={27}
          onClick={() => navigate("/partner/onboarding")}
         />
        )}
       </Box>
      </BottomCard>
     </Fade>
     <Fade right>
      <BottomCard>
       <BottomCardPricing
        css={css`
         h3 {
          margin-top: 0;
         }
        `}
       >
        <p className="small">PAID PLAN</p>
        <p>Access to all features to boost sales and visibility</p>
        <h3>
         <svg
          width="33"
          height="36"
          viewBox="0 0 33 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path
           d="M0.5 12.0769H4.44872V0.230713H8.39744L15.1497 12.0769H24.1923V0.230713H28.141V12.0769H32.0897V16.0256H28.141V19.9743H32.0897V23.923H28.141V35.7692H24.1923L17.4203 23.923H8.39744V35.7692H4.44872V23.923H0.5V19.9743H4.44872V16.0256H0.5V12.0769ZM8.39744 12.0769H10.6285L8.39744 8.18738V12.0769ZM8.39744 16.0256V19.9743H15.1497L12.899 16.0256H8.39744ZM24.1923 27.8717V23.923H21.9218L24.1923 27.8717ZM17.4005 16.0256L19.671 19.9743H24.1923V16.0256H17.4005Z"
           fill="#632EFF"
          />
         </svg>
         30,000
        </h3>
        <span>Per month</span>
       </BottomCardPricing>
       <BottomCardInfo>
        <h3>Get Access to:</h3>

        <BottomCardInfoList>
         <li>
          <Tick />
          Everything on the free plan plus
         </li>
         <li>
          <Tick />
          2% fee per every paid experience ticket
         </li>
         <li>
          <Tick />
          Recurring events
         </li>
         <li>
          <Tick />
          One-time experiences with multiple time slots
         </li>
         <li>
          <Tick />
          Upload multiple images per experience (up to 5)
         </li>
         <li>
          <Tick />
          Discount codes
         </li>
         <li>
          <Tick />
          Add team members
         </li>
         <li>
          <Tick />
          Export data
         </li>
        </BottomCardInfoList>
       </BottomCardInfo>
       <Box paddingInline={"31px"} marginTop={"auto"}>
        <a href="mailto:hello@thenaviapp.com">
         {" "}
         <Button
          title="Contact Us"
          animateOnHover
          animateOnTap
          css={css`
           background-color: #632eff;
           margin: 0px auto;
           height: 65px;
           border-radius: 54px;
           transition: 0.2s;
           border: 1px solid #632eff;
          `}
          titleCSS={css`
           margin-right: 8px;
           font-size: 15px;
           font-weight: 600;
           line-height: 20px;
           letter-spacing: 1.18px;
           text-align: left;
           color: #fff;
          `}
          iconPosition={"right"}
          iconSize={27}
         />
        </a>
       </Box>
      </BottomCard>
     </Fade>
    </BottomCards>
   </BottomLikeHeroWrapper>
  </Box>
 );
};

const Tick = () => {
 return (
  <>
   <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   >
    <path
     d="M12.5 22.75C6.57 22.75 1.75 17.93 1.75 12C1.75 6.07 6.57 1.25 12.5 1.25C18.43 1.25 23.25 6.07 23.25 12C23.25 17.93 18.43 22.75 12.5 22.75ZM12.5 2.75C7.4 2.75 3.25 6.9 3.25 12C3.25 17.1 7.4 21.25 12.5 21.25C17.6 21.25 21.75 17.1 21.75 12C21.75 6.9 17.6 2.75 12.5 2.75Z"
     fill="#632EFF"
    />
    <path
     d="M11.0814 15.5801C10.8814 15.5801 10.6914 15.5001 10.5514 15.3601L7.72141 12.5301C7.43141 12.2401 7.43141 11.7601 7.72141 11.4701C8.01141 11.1801 8.49141 11.1801 8.78141 11.4701L11.0814 13.7701L16.2214 8.6301C16.5114 8.3401 16.9914 8.3401 17.2814 8.6301C17.5714 8.9201 17.5714 9.4001 17.2814 9.6901L11.6114 15.3601C11.4714 15.5001 11.2814 15.5801 11.0814 15.5801Z"
     fill="#632EFF"
    />
   </svg>
  </>
 );
};

export default PartnerHomePage;
