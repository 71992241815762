import React from "react";
import { Box, HStack, Text, SimpleGrid, Img, Button } from "@chakra-ui/react";
import dealBg from "../../assets/images/dealsBg.svg";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Box mt="90px" bgSize="cover" bgRepeat="no-repeat">
      <SimpleGrid
        padding={{ base: "2rem", md: "4rem" }}
        mt={{ lg: "20px" }}
        columns={{ base: 1, lg: 2 }}
        spacing={16}
        alignItems={{ base: "flex-start", lg: "center" }}
      >
        <Img src={dealBg} height={{ lg: "550px" }} />
        <Box pos="relative">
          <Text
            color="#0F0931"
            fontSize={{ base: "25px", md: "33px", lg: "50px" }}
            lineHeight="59px"
            pt="15px"
            fontWeight="600"
          >
            Get Deals Always
          </Text>
          <Text
            color="#0F0931"
            fontSize={{ base: "15px", md: "16px", lg: "18px" }}
            lineHeight="150%"
            fontWeight="400"
            p="10px 0"
            textAlign="justify"
            width={{ lg: "80%" }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra, per
            inceptos himenaeos. Curabitur tempus urna at turpis condimentum
            lobortis. Ut commodo efficitur neque.Check out our amazing deals by
            partnering with us.
          </Text>
          <Link to="/prices">
            <Button
              color="#ffffff"
              bg="#25AEFC"
              borderRadius={28}
              fontWeight={500}
              fontSize={18}
              marginTop={10}
            >
              Buy Now
            </Button>
          </Link>

          <Box
            w="64px"
            h="64px"
            bgColor="#CBBFEE"
            borderRadius="100%"
            pos="absolute"
            right={{ base: "-10", lg: "60" }}
            top={{ base: "-200", lg: "-50" }}
          ></Box>
          <Box
            w="64px"
            h="64px"
            bgColor="#D3EDFF"
            borderRadius="100%"
            pos="absolute"
            left="60"
            bottom={{ base: "330", lg: "-40" }}
          ></Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Hero;
