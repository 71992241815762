import React, { useEffect, useMemo, useRef, useState } from "react";
import { db } from "../../../../firebase";
import { onValue, ref } from "firebase/database";
import { Button, Icon, Loader, Modal, Toast } from "../../../../components";
import {
 Container,
 SearchInput,
 SearchLabel,
 CategoryHeader,
 CategoriesTable,
 JustifyBetween,
 ActionsWrapper,
 EditIcon,
 DeleteIcon,
 SwitchIcon,
 Card,
 CardHeader,
 CardInBetween,
 Wrapper,
 addInputStyle,
 addInputFlexStyle,
 addInputTextStyle,
 searchInputStyle,
} from "./styles";
import {
 Box,
 CloseButton,
 Flex,
 IconButton,
 Img,
 Input,
 InputGroup,
 InputLeftElement,
 Switch,
 Text,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { AiOutlineEdit } from "react-icons/ai";
import { colors } from "../../../../assets/styles/config";
import { set } from "firebase/database";
import { AnimatePresence } from "framer-motion";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { AddIcon } from "@chakra-ui/icons";
import TrashIcon from "../../../../assets/images/svg/trash-2-icon.svg";
import CloseIconOutline from "../../../../assets/images/svg/close-outline-icon.svg";
import UnnecessaryWarningImage from "../../../../assets/images/warning-icon.svg";

// CONSTANTS
const ACTIONS = {
 ADD_CATEGORY: "ADD CATEGORY",
 EDIT_CATEGORY: "EDIT CATEGORY",
 DELETE_CATEGORY: "DELETE CATEGORY?",
};
const DEFAULT_STATE = { status: false, action: null };
const TOAST_STATE = {
 state: false,
 text: "",
 bgColor: "lightgreen",
 textColor: colors.primaryWhite,
};
const categoriesDB = ref(db, "cityExperience/categories");

const Categories = () => {
 const [filteredCategoryList, setFilteredCategoryList] = useState([]);
 const [categoryList, setCategoryList] = useState([]);
 const [toast, setToast] = React.useState(TOAST_STATE);
 const [state, setState] = useState(DEFAULT_STATE);
 const [loading, setLoading] = useState(false);
 const [visible, setVisible] = useState(false);
 const [chosenCategory, setChosenCategory] = useState("");
 const [value, setValue] = React.useState("");
 const containerRef = useRef(null);

 //   FUNCTIONS
 const handleChange = (event) => setValue(event.target.value);

 const handleSearchInput = (searchValue) => {
  const hasSearchValue = categoryList.filter((item) =>
   item.toLowerCase().includes(searchValue.toLowerCase())
  );
  setFilteredCategoryList(hasSearchValue);
 };

 const reset = () => {
  setVisible(false);
  setState(DEFAULT_STATE);
  setChosenCategory("");
  setValue("");
 };

 const setActionType = (type, category) => {
  setState({ ...state, action: type });
  setVisible(true);
  setChosenCategory(category);
 };

 const deleteCategory = (item) => {
  const newList = categoryList.filter(
   (category) => category.toLowerCase() !== item.toLowerCase()
  );
  reset();
  setCategoryList(newList);
  setToast({
   ...toast,
   state: true,
   text: "Item Deleted Successfully",
   bgColor: "lightgreen",
   textColor: colors.primaryWhite,
  });
  updateFirebaseDb(newList);
 };

 const createCategory = (item) => {
  const itemExists = categoryList.find(
   (category) => category.toLowerCase() === item.toLowerCase()
  );
  if (!item) {
   setToast({
    ...toast,
    state: true,
    text: "Input is empty",
    bgColor: colors.pastelRed,
    textColor: colors.primaryWhite,
   });
   return;
  }
  if (itemExists) {
   setToast({
    ...toast,
    state: true,
    text: "Category already exists",
    bgColor: colors.pastelRed,
    textColor: colors.primaryWhite,
   });
   return;
  }
  const newItem =
   item.slice(0, 1).toUpperCase() + item.slice(1, item.length).toLowerCase();
  const newList = [...categoryList, newItem].sort();
  reset();
  setCategoryList(newList);
  setToast({
   ...toast,
   state: true,
   text: "Item Added Successfully",
   bgColor: "lightgreen",
   textColor: colors.primaryWhite,
  });
  updateFirebaseDb(newList);
 };

 const updateCategory = () => {
  if (!value) {
   setToast({
    ...toast,
    state: true,
    text: "Input is empty",
    bgColor: colors.pastelRed,
    textColor: colors.primaryWhite,
   });
   return;
  }
  const newItem =
   value.slice(0, 1).toUpperCase() + value.slice(1, value.length).toLowerCase();
  const newList = categoryList.map((item) =>
   item.toLowerCase() === chosenCategory.toLowerCase() ? newItem : item
  );
  setCategoryList(newList);
  reset();
  setToast({
   ...toast,
   state: true,
   text: "Item Updated Successfully",
   bgColor: "lightgreen",
   textColor: colors.primaryWhite,
  });
  updateFirebaseDb(newList);
 };

 const updateFirebaseDb = (newList) => {
  set(categoriesDB, newList);
 };

 //   EFFECTS
 useEffect(() => {
  setLoading(true);
  const unsubscribe = onValue(categoriesDB, (snapshot) => {
   const categories = snapshot.val();
   setCategoryList(categories);
   if (categories?.length > 0) {
    setLoading(false);
   }
  });
  return () => {
   unsubscribe();
  };
 }, []);

 useEffect(() => {
  setFilteredCategoryList(categoryList);
 }, [categoryList]);

 //   JSX
 const renderSearchAndAddCategoryArea = useMemo(
  () => (
   <>
    <Box display="flex" justifyContent="space-between" marginBottom={"54px"}>
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => handleSearchInput(e.target.value)}
      />
     </InputGroup>

     <Button
      animateOnTap
      animateOnHover
      buttonColor="#252046"
      css={css`
       width: fit-content;

       font-weight: 500;
       font-family: "Creato Display";
       display: flex;
       gap: 15px;

       @media screen and (max-width: 768px) {
        border-radius: 100px;
       }
      `}
      onClick={() => setActionType(ACTIONS.ADD_CATEGORY, "")}
     >
      <AddIcon />{" "}
      <Text display={{ base: "none", md: "inline" }}>Add Category</Text>
     </Button>
    </Box>
   </>
  ),
  [categoryList, filteredCategoryList, handleSearchInput]
 );

 const renderCategories = useMemo(
  () => (
   <CategoriesTable>
    {filteredCategoryList?.length > 0 &&
     filteredCategoryList?.map((category) => (
      <JustifyBetween key={category}>
       <p>{category}</p>
       <ActionsWrapper>
        <EditIcon
         whileTap={{ scale: 0.7 }}
         whileHover={{ scale: 1.09 }}
         onClick={() => {
          setValue(category);
          setActionType(ACTIONS.EDIT_CATEGORY, category);
         }}
        >
         <AiOutlineEdit />
        </EditIcon>

        {/* <SwitchIcon>
                  <Switch size={{ base: 'sm', lg: 'md' }} isChecked />
                </SwitchIcon> */}
        <DeleteIcon
         whileTap={{ scale: 0.7 }}
         whileHover={{ scale: 1.09 }}
         onClick={() => {
          setActionType(ACTIONS.DELETE_CATEGORY, category);
         }}
        >
         <Img src={TrashIcon} />
        </DeleteIcon>
       </ActionsWrapper>{" "}
      </JustifyBetween>
     ))}
   </CategoriesTable>
  ),
  [loading, filteredCategoryList]
 );

 const renderAddEditInput = useMemo(
  () => (
   <Flex {...addInputFlexStyle}>
    <Text {...addInputTextStyle}>Name</Text>
    <Input {...addInputStyle} value={value} onChange={handleChange} />
   </Flex>
  ),
  [value, setValue, handleChange]
 );

 const renderModal = useMemo(
  () => (
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.5,
    }}
   >
    <AnimatePresence>
     <Card
      initial={{
       scale: 0,
       translateX: 350,
       translateY: 350,
      }}
      animate={{ scale: 1, translateX: 0, translateY: 0 }}
      exit={{ opacity: 0, scale: 0, translateX: 350, translateY: 350 }}
      transition={{
       type: "spring",
       stiffness: 160,
       damping: 20,
      }}
      ref={containerRef}
      state={state.action}
     >
      {state.action === ACTIONS.DELETE_CATEGORY ? (
       <>
        <CardHeader
         css={css`
          align-items: center;
          flex-direction: column;
          gap: 31px;
         `}
        >
         <img src={UnnecessaryWarningImage}></img>
         Delete Category?
        </CardHeader>
        <CardInBetween
         css={css`
          padding-block: 11px 31px;
          max-width: 237px;
          margin: auto;
         `}
        >
         Are you sure you want to delete this category?
        </CardInBetween>
        {/* THE BUTTONS */}
        <Flex
         justifyContent={"center"}
         gap="9px"
         alignItems="center"
         paddingBottom="32px"
         flexDirection="column-reverse"
        >
         <Button
          animateOnTap
          animateOnHover
          css={css`
           background: white;
           color: #252046;
           font-family: "Creato Display";
           font-style: normal;
           font-weight: 700;
           height: auto;
           border: 1px solid #252046;
          `}
          onClick={reset}
         >
          Cancel
         </Button>
         <Button
          animateOnTap
          animateOnHover
          css={css`
           background: ${colors.primaryPurple};
           font-weight: 500;
           transition: 250ms;
           margin-bottom: 0px;
           height: auto;

           &:hover {
            opacity: 0.8;
           }
          `}
          onClick={() => {
           deleteCategory(chosenCategory);
          }}
         >
          Yes, Delete
         </Button>
        </Flex>
       </>
      ) : (
       <>
        {/* THE HEADER */}
        <CardHeader>
         {state?.action?.charAt(0) + state?.action?.substring(1)?.toLowerCase()}{" "}
         <img
          src={CloseIconOutline}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={reset}
         />
        </CardHeader>
        {/* THE IN-BETWEEN */}

        {state.action === ACTIONS.DELETE_CATEGORY ? (
         <CardInBetween>
          {" "}
          {"Are you sure you would like to delete this category?"}{" "}
         </CardInBetween>
        ) : (
         renderAddEditInput
        )}

        {/* THE BUTTONS */}
        <Flex
         justifyContent={"center"}
         alignItems="center"
         paddingBottom="40px"
        >
         {/* <Button
                css={css`
                  background: #eef3f7;
                  width: fit-content;
                  height: auto;
                  color: ${colors.faintAsh};
                `}
                onClick={reset}
              >
                {state.action === ACTIONS.DELETE_CATEGORY
                  ? 'Cancel'
                  : 'Discard'}
              </Button> */}
         <Button
          animateOnTap
          animateOnHover
          css={css`
           background: ${state.action === ACTIONS.DELETE_CATEGORY
            ? "red"
            : colors.primaryPurple};
           font-weight: 500;
          `}
          onClick={() => {
           state.action === ACTIONS.DELETE_CATEGORY
            ? deleteCategory(chosenCategory)
            : state.action === ACTIONS.ADD_CATEGORY
            ? createCategory(value)
            : updateCategory();
          }}
         >
          {state.action === ACTIONS.DELETE_CATEGORY
           ? "Delete"
           : state.action === ACTIONS.EDIT_CATEGORY
           ? "Edit"
           : "Add category"}
         </Button>
        </Flex>
       </>
      )}
     </Card>
    </AnimatePresence>
   </Modal>
  ),
  [visible, setVisible, renderAddEditInput]
 );

 const renderToast = React.useMemo(
  () => (
   <Toast
    visible={toast.state}
    setVisible={setToast}
    text={toast.text}
    textStyle={{ color: toast.textColor }}
    style={{ backgroundColor: toast.bgColor }}
   />
  ),
  [toast]
 );

 if (loading) {
  return <Loader />;
 }

 return (
  <Container>
   {renderSearchAndAddCategoryArea}
   <Wrapper>
    <CategoryHeader>
     <p>Category name</p>
     <p>Actions</p>
    </CategoryHeader>
    {renderCategories}
   </Wrapper>
   {renderModal}
   {renderToast}
  </Container>
 );
};

export default Categories;
