import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 List,
 ListItem,
 SimpleGrid,
 Flex,
 Tabs,
 TabList,
 TabPanels,
 Tab,
 TabPanel,
 Image,
 Switch,
 Button,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import {
 Button as CustomButton,
 EmptyState,
 MobileAddBtn,
} from "../../../../components";
import { AuthContext } from "../../../../context/AuthContext";
import { Link } from "react-router-dom";
import {
 getDatabase,
 ref as dbRef,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { storage, db } from "../../../../firebase";
import { NoCollections } from "../../../Experiences/styles";
import { colors } from "../../../../assets/styles/config";
import {
 Card,
 CloseBtn,
 Container,
 addExpBtn,
 addExpBtnMb,
 tabListStyles,
 tabStyles,
} from "../../Dashboard/styles";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
} from "../../../../components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Warning from "../../../../assets/images/warning-icon.svg";
import toast from "react-hot-toast";
import {
 sendPartnerPushNotification,
 sendPushNotification,
} from "../../../../api/user";
import {
 deleteBtnStyles,
 listItemStyles,
 listItemTxtStyles1,
 listItemTxtStyles2,
} from "./styles";

const ManageDeals = () => {
 const navigate = useNavigate();
 const { user } = useContext(AuthContext);
 const [loading, setLoading] = useState(false);
 const [partnerCoupon, setPartnerCoupon] = useState([]);
 const [coupons, setCoupons] = useState([]);
 const [modalType, setModalType] = useState("");
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [selectedPartner, setSelectedPartner] = useState(null);
 const [selectedExp, setSelectedExp] = useState(null);

 const fetchDatabase = async () => {
  setLoading(true);
  //get user experience
  const db = await get(child(dbRef(getDatabase()), `users/partner`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     const myObj = snapshot.val();
     const uid = user?.partnerUid ?? user?.uid;
     setPartnerCoupon(
      Object.keys(myObj)
       .map((key) => {
        return myObj[key];
       })
       .filter((item) => item?.uid === uid && item?.enabled && item.coupon)[0]
     );
     setCoupons(
      Object.keys(myObj)
       .map((key) => {
        return myObj[key];
       })
       .filter((item) => item?.uid === uid && item?.enabled && item.coupon)
       .map((item) => item.coupon)
     );
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });

  return db;
 };

 const onChangeHandle = async (e, item) => {
  toast.loading("loading");
  const db = getDatabase();
  const uid = user?.partnerUid ?? user?.uid;
  const updates = {};
  updates["users/partner/" + uid] = {
   ...partnerCoupon,
   coupon: partnerCoupon?.coupon.map((coup) => {
    if (coup.experiences?.id === item.experiences?.id) {
     return {
      ...item,
      active: !item?.active,
     };
    }
    return item;
   }),
  };

  await update(dbRef(db), updates)
   .then(async () => {
    toast.dismiss();
    toast.success("Successfully updated deal");
    fetchDatabase();
    if (user) {
     await sendPushNotification({
      title: "admin",
      body: `${user?.businessName} updated deal`,
      data: user,
     });
    }
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 const handleDelete = async () => {
  toast.loading("loading");
  const db = getDatabase();
  const updates = {};

  console.log(
   selectedExp,
   partnerCoupon.coupon?.filter(
    (itm) => Number(itm?.experiences?.id) !== Number(selectedExp)
   )
  );

  updates["users/partner/" + selectedPartner + "/coupon"] =
   partnerCoupon.coupon?.filter(
    (itm) => Number(itm?.experiences?.id) !== Number(selectedExp)
   );
  await update(dbRef(db), updates)
   .then(async () => {
    toast.dismiss();
    toast.success("Successfully deleted deal");
    setVisible(!visible);
    setModalType("");
    setSelectedPartner(null);
    setSelectedExp(null);
    fetchDatabase();
    if (user) {
     let name = user?.firstName
      ? `${user?.firstName} ${user?.lastName}`
      : user?.name;
     await sendPartnerPushNotification({
      title: "partner",
      body: `${name} deleted  deal`,
      data: {
       uid: user?.partnerUid ?? user?.uid,
       route: `/partner/manage-deals`,
       name: name,
       photoURL: user?.partnerPhotoURL ?? user?.photoURL,
       push_time: Date.now(),
      },
     });
    }
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 useEffect(() => {
  fetchDatabase();
 }, []);

 return (
  <Box
   height="100%"
   padding={{ base: "0px 10px", md: "20px" }}
   background="#F5F8FA"
  >
   <Box borderRadius="6px" minHeight="80vh" position="relative">
    <Tabs variant="soft-rounded">
     <Flex
      background={{ md: "#FFFFFF" }}
      alignItems="flex-start"
      flexDir="row"
      justifyContent="space-between"
      padding={{ md: "20px" }}
      borderRadius={{ md: "16px" }}
     >
      <TabList {...tabListStyles} className="tabs">
       <Tab {...tabStyles}>Coupons</Tab>
       <Tab {...tabStyles}>Gift cards</Tab>
      </TabList>

      <Box display={{ base: "none", md: "flex" }}>
       <Link to="/partner/manage-deals/add">
        <CustomButton
         animateOnTap
         animateOnHover
         buttonColor="#252046"
         css={css`
          width: fit-content;
          padding: 0.5rem 2rem;
          height: auto;
          font-weight: 500;
          font-family: "Creato Display";
          display: flex;
          gap: 15px;

          @media screen and (max-width: 768px) {
           border-radius: 10px;
          }
         `}
        >
         <AddIcon /> Add Deals
        </CustomButton>
       </Link>
      </Box>
     </Flex>

     <TabPanels>
      <TabPanel>
       {loading ? (
        <Box
         display="flex"
         alignItems="center"
         justifyContent="center"
         mt="40px"
        >
         <LoadingIndicator />
        </Box>
       ) : (
        <Box mt={{ md: "30px" }} marginBottom="60px">
         <Link to="/partner/manage-deals/add">
          <MobileAddBtn style={{ bottom: 60 }} />
         </Link>
         {partnerCoupon?.coupon?.length > 0 ? (
          <SimpleGrid
           columns={{ base: 1, lg: 2, xl: 3 }}
           alignItems="center"
           spacing={10}
           width="100%"
          >
           {partnerCoupon?.coupon?.map((item, idx) => (
            <Box minWidth={{ base: "100%", md: "344px" }}>
             <Box position="relative">
              <Image
               src={user?.coverURL}
               borderRadius="16px 16px 0px 0px"
               width="100%"
               height="181px"
              />
              <Text
               position="absolute"
               bottom="10px"
               left="10px"
               color="#ffffff"
               fontSize="16px"
               lineHeight="14px"
              >
               {user?.businessName}
              </Text>
             </Box>
             <Box
              padding="20px 10px"
              background="#FFFFFF"
              borderBottomRadius="16px"
             >
              <List width="100%">
               <ListItem {...listItemStyles}>
                <Text
                 background={item?.active ? "#ECFDF5" : "#f36740"}
                 padding="5px"
                 borderRadius="24px"
                 color={item?.active ? "#15803D" : "#eefff4"}
                 fontFamily="Satoshi"
                 fontWeight="500"
                 fontSize="12px"
                >
                 {item?.active ? "Active" : "Deactivated"}
                </Text>
                <Switch
                 colorScheme="green"
                 isChecked={item?.active}
                 onChange={(e) => {
                  // console.log("item", item);
                  onChangeHandle(e, item);
                 }}
                 //
                />
               </ListItem>
               <ListItem {...listItemStyles}>
                <Text {...listItemTxtStyles1}>Coupon type</Text>
                <Text {...listItemTxtStyles2}>{item?.couponType}</Text>
               </ListItem>
               <ListItem {...listItemStyles}>
                <Text {...listItemTxtStyles1}>Coupon code</Text>
                <Text {...listItemTxtStyles2}>{item?.couponCode}</Text>
               </ListItem>
               <ListItem {...listItemStyles}>
                <Text {...listItemTxtStyles1}>Usage</Text>
                <Text {...listItemTxtStyles2}>{item?.currentUsage}</Text>
               </ListItem>
               <ListItem
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="10px 0"
               >
                <Text {...listItemTxtStyles1}>Expires date</Text>
                <Text {...listItemTxtStyles2}>
                 {dayjs(item?.expiryDate)?.format("DD-MM-YYYY")}
                </Text>
               </ListItem>
              </List>
              <Box
               display="flex"
               alignItems="center"
               justifyContent="space-between"
               gap="20px"
               marginTop="20px"
              >
               <Button
                color={colors.primaryWhite}
                bg={colors.primaryPurple}
                borderRadius={28}
                fontWeight={500}
                fontSize={{ base: 14, md: 18 }}
                p={{ base: "20px", md: "24px 20px" }}
                width="100%"
                onClick={() =>
                 navigate(`/partner/manage-deals/edit/${item?.experiences?.id}`)
                }
                //   type="submit"
               >
                View details
               </Button>
               <Button
                {...deleteBtnStyles}
                onClick={() => {
                 setVisible(!visible);
                 setModalType("delete");
                 setSelectedPartner(user.uid);
                 setSelectedExp(item?.experiences?.id);
                }}
               >
                Delete
               </Button>
              </Box>
             </Box>
            </Box>
           ))}
          </SimpleGrid>
         ) : (
          <EmptyState
           text={"No deals yet. Click the add button to add a new deal"}
          />
         )}
        </Box>
       )}
      </TabPanel>
      <TabPanel>
       <p>Coming soon...</p>
      </TabPanel>
     </TabPanels>
    </Tabs>
   </Box>{" "}
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
     //   style={{ marginTop: modalType === "add" && "300px" }}
    >
     {modalType === "delete" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Deal
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you want to delete this deal?
        </Text>
        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setModalType("");
          }}
         >
          Close
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default ManageDeals;
