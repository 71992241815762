import React from "react";
import Lottie from "../../assets/images/redeem-experiences-lottie.svg";
import { Box, Flex, Text } from "@chakra-ui/react";
import { svgFlexStyles } from "./styles";

const EmptyState = ({ text }) => {
 return (
  <div>
   {" "}
   <Box display="flex" justifyContent="center" alignItems="center">
    <Flex {...svgFlexStyles}>
     <img
      src={Lottie}
      alt=""
      style={{ marginTop: "40px", marginBottom: "-40px" }}
     />
     <Text>{text ? text : "There are no experiences at the moment."}</Text>
    </Flex>
   </Box>
  </div>
 );
};

export default EmptyState;
