import React, { useState, useEffect, createContext, useContext } from "react";
import {
 signIn,
 signUp,
 onboard,
 resetPass,
 fetchDatabase,
 sendPasswordReset,
 createAdmin,
 logOut,
 getCurrentUser,
 updateDatabase,
 removeAdmin,
 fetchRolePermission,
 updateAdminDatabase,
 updatePartnerDatabase,
 removePartner,
 sendPushNotification,
 createPartnerMember,
 updatePartnerMemberDatabase,
 removePartnerMember,
 fetchPartnerRolePermission,
 sendPartnerPushNotification,
} from "../api/user";
import { onAuthStateChanged } from "firebase/auth";
import { auth, cf } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";

export const AuthContext = createContext("null");

const AuthProvider = ({ children }) => {
 const navigate = useNavigate();
 const location = useLocation();
 const [role, setRole] = useState(null);
 const [user, setUser] = useState({});
 const [partner, setPartner] = useState(null);
 const [isLoading, setIsLoading] = useState(false);
 const [gettingUser, setGettingUser] = useState(true);
 const [error, setError] = useState(null);
 const isLoggedIn = localStorage.getItem("isLoggedIn");
 const onAuthStateChange = async (authUser) => {
  const database = authUser && (await fetchDatabase(authUser?.toJSON().uid));

  if (isLoggedIn) {
   setUser(database);
   setRole(database?.role);
   if (database?.role === "PARTNER") {
    setPartner(database);
   }
  }
  /*  if (authUser?.toJSON().emailVerified) {
    } */
 };

 // console.log("path", location.state?.from?.pathname);

 // useEffect(() => {
 //   if (user === {}) {
 //     setGettingUser(false);
 //   }
 // }, [user]);

 // console.log("user===", user);

 useEffect(() => {
  const subscriber = onAuthStateChanged(auth, onAuthStateChange);
  return () => {
   subscriber();
  };
 }, [isLoggedIn]);

 //user signup

 //welcome email cloud function
 const welcome = httpsCallable(cf, "welcomeEmail");
 const signup = (data) => {
  setIsLoading(true);
  toast.loading("loading");
  signUp(data)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Verify your account via email");
     localStorage.setItem("path", "/signin");
     welcome({
      name: data.firstName + " " + data.lastName,
      email: data.email,
     });
     navigate("/signin");
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //user sigin
 const signin = (data) => {
  setIsLoading(true);
  toast.loading("loading");
  signIn(data, true)
   .then(async (res) => {
    const database = await fetchDatabase(res?.user?.uid);
    toast.dismiss();

    if (database?.role === "USER") {
     localStorage.setItem("isLoggedIn", true);
     toast.success("logged in");
     const path = location.state?.from?.pathname;
     if (path === "/signup") {
      navigate(-3);
      // window.location.reload();
      // localStorage.removeItem("path");
      // window.location.reload();
     } else {
      navigate(-1);
      // window.location.reload();
     }

     // window.location.reload();
    } else if (database?.role === "PARTNER") {
     localStorage.setItem("isLoggedIn", false);
     toast.error("Please sign up as a User. That is a partner account");
    } else {
     localStorage.setItem("isLoggedIn", false);
     toast.error("Please sign up as a User");
    }
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
    /*  if (res?.user?.emailVerified) {
         
        } else {
          toast.error("Please verify account via email");
        } */
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setIsLoading(false);
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //partner sigin
 const partnerSignin = (data) => {
  setIsLoading(true);
  toast.loading("loading");
  signIn(data)
   .then(async (res) => {
    const database = await fetchDatabase(res?.user?.uid);

    toast.dismiss();
    if (database?.role === "PARTNER") {
     localStorage.setItem("isLoggedIn", true);
     toast.success("logged in");
     navigate("/partner/dashboard");
     //   window.location.reload();
    } else if (database?.partnerUid) {
     localStorage.setItem("isLoggedIn", true);
     const role = await fetchPartnerRolePermission(database?.partnerUid);

     const permission = JSON.parse(
      role?.filter((item) => item?.role === database?.role)[0]?.permission
     );
     localStorage.setItem(
      "access_point",
      JSON.stringify(
       Object.keys(
        filterObject(
         Object.keys(permission)
          .map((key) => permission[key])
          .reduce((r, c) => Object.assign(r, c), {})
        )
       )
      )
     );
     const roleArray = role?.map((item) => {
      return item?.role;
     });

     if (roleArray?.includes(database?.role)) {
      toast.success("logged in");
      const access_point = JSON.parse(localStorage.getItem("access_point"));
      if (access_point !== null) {
       switch (access_point[0]) {
        case "partner_dashboard":
         navigate("/partner/dashboard");
         break;
        case "partner_uploadExperiences":
         navigate("/partner/experience");
         break;
        case "partner_redeemExperiences":
         navigate("/partner/redeem-experiences");
         break;
        case "partner_manageDeals":
         navigate("/partner/manage-deals");
         break;
        case "partner_teamMembers":
         navigate("/partner/members");
         break;
        case "partner_roles":
         navigate("/partner/roles");
         break;
        case "partner_businessProfile":
         navigate("/partner/business-profile");
         break;
        case "partner_support":
         navigate("/partner/support");
         break;
        default:
         break;
       }
      }
     }
    } else if (database?.role === "USER") {
     localStorage.setItem("isLoggedIn", false);
     toast.error("Please sign up as a Partner. That is an user account");
    } else {
     localStorage.setItem("isLoggedIn", false);
     toast.error("Please sign up as a Partner");
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     setIsLoading(false);
    }, 3000);
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 const filterObject = (obj) =>
  Object.keys(obj).reduce(
   (acc, val, i) =>
    obj[val] === "yes"
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 //admin sigin
 const adminSignin = (data) => {
  setIsLoading(true);
  toast.loading("loading");
  signIn(data)
   .then(async (res) => {
    toast.dismiss();

    const database = await fetchDatabase(res?.user?.uid);
    const role = await fetchRolePermission();
    const permission = JSON.parse(
     role?.filter((item) => item?.role === database?.role)[0]?.permission
    );

    localStorage.setItem(
     "access_point",
     JSON.stringify(
      Object.keys(
       filterObject(
        Object.keys(permission)
         .map((key) => permission[key])
         .reduce((r, c) => Object.assign(r, c), {})
       )
      )
     )
    );
    const roleArray = role?.map((item) => {
     return item?.role;
    });

    const isAdmin =
     database?.role === "PARTNER" || database?.partnerUid ? false : true;

    if (isAdmin) {
     localStorage.setItem("isLoggedIn", true);
     if (roleArray?.includes(database?.role)) {
      toast.success("Logged in");
      const access_point = JSON.parse(localStorage.getItem("access_point"));
      if (access_point !== null) {
       switch (access_point[0]) {
        case "dashboard":
         navigate("/admin/dashboard");
         break;
        case "navigator":
         //navigate("/admin/dashboard");
         break;
        case "collections":
         navigate("/admin/collection");
         break;
        case "teamMembers":
         navigate("/admin/administrator");
         break;
        case "roles":
         navigate("/admin/roles");
         break;
        case "partnerReg":
         navigate("/admin/partner-registration");
         break;
        case "partnerExp":
         navigate("/admin/partner-experience");
         break;
        case "categoriesTags":
         navigate("/system-config/categories");
         break;
        default:
         break;
       }
      }
     }
    } else {
     localStorage.setItem("isLoggedIn", false);
     toast.error("Please sign up as a admin");
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setIsLoading(false);
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //sign out
 const signout = async () => {
  setIsLoading(true);
  toast.loading("loading");
  logOut()
   .then(() => {
    toast.dismiss();
    toast.success("Logged out");
    localStorage.removeItem("access_point");
    localStorage.removeItem("isLoggedIn");
    navigate("/");
    setRole(null);
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //partner onboard
 const partnerOnboard = async (data, isAdmin) => {
  if (!isAdmin) {
   toast.loading("loading");
  }
  setIsLoading(true);
  return await onboard(data)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     if (!isAdmin) {
      // toast.success("Your application has been sent to NAVI");
      //  setRole("SUPER-ADMIN");
      // navigate("/");
     } else {
      if (user) {
       await sendPushNotification({
        title: "admin",
        body: `${user?.name} onboarded ${data.businessName}`,
        data: {
         // ...user,
         route: `/admin/partner-registration/edit/${res.uid}`,
         name: user?.name,
         photoURL: user?.photoURL,
         push_time: Date.now(),
        },
       });
      }
      navigate(-1);
     }

     return res;
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
    return false;
   })
   .finally(() => {
    setIsLoading(false);
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //send password reset email
 const handlePasswordReset = async (values, type) => {
  toast.loading("loading");
  return sendPasswordReset(values)
   .then((res) => {
    if (res) {
     toast.dismiss();
     toast.success("Reset password via email and login");
     if (type === "partner") {
      navigate("/partner/signin");
     } else if (type === "admin") {
      navigate("/admin/signin");
     } else {
      navigate("/signin");
     }
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //reset password
 /* const resetPassword = (data, type) => {
    //  setisLoading(true);
    toast.loading("loading");
    console.log("data", data);
    resetPass(data.password)
      .then(async (res) => {
        console.log("res", res);
        if (res) {
          toast.dismiss();
          toast.success("Password reset successful");
          //  navigate("/");
          if (type === "partner") {
            navigate("/partner/signin");
          } else {
            navigate("/signin");
          }
        }
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(err.message);
      })
      .finally(() => {
        setTimeout(() => {
          toast.dismiss();
        }, 3000);
      });
  }; */

 //add admin
 const addAdmin = async (data) => {
  setIsLoading(true);
  toast.loading("loading");

  return await createAdmin(data)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Successful Added Admininstrator");

     if (user) {
      await sendPushNotification({
       title: "admin",
       body: `${user?.name} added ${data?.name} as an admininstrator`,
       data: {
        // ...user,
        route: "/admin/administrator",
        name: user?.name,
        photoURL: user?.photoURL,
        push_time: Date.now(),
       },
      });
     }
     //  navigate("/");
     return res;
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 // update Administrator
 const updateAdministrator = async (data) => {
  setIsLoading(true);
  //toast.loading("loading");
  // const user = getCurrentUser();
  return updateAdminDatabase(data?.uid, data)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Administrator successful updated");
     //  const database = await fetchDatabase(data?.uid);
     // setUser(database);

     if (user) {
      await sendPushNotification({
       title: "admin",
       body: `${user?.name} updated ${data?.name} profile`,
       data: {
        // ...user,
        route: `/admin/administrator/view/${data?.uid}`,
        name: user?.name,
        photoURL: user?.photoURL,
        push_time: Date.now(),
       },
      });
     }
     //  navigate("/");
     return res;
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 // update Partner
 const updatePartner = async (data, isApproved = false, isAdmin = false) => {
  setIsLoading(true);
  // toast.loading("loading");
  // const user = getCurrentUser();
  return updatePartnerDatabase(data?.uid, data)
   .then(async (res) => {
    if (res) {
     if (!isApproved) {
      toast.dismiss();
      toast.success("Partner successful updated");
     }
     //  const database = await fetchDatabase(data?.uid);
     // setUser(database);
     if (isAdmin) {
      await sendPushNotification({
       title: "admin",
       body: `${user?.name} updated ${data?.businessName} profile`,
       data: {
        // ...user,
        route: `/admin/partner-registration/edit/${data?.uid}`,
        name: user?.name,
        photoURL: user?.photoURL,
        push_time: Date.now(),
       },
      });
     } else {
     }
     //  navigate("/");
     return res;
    }
   })
   .catch((err) => {
    if (!isApproved) {
     toast.dismiss();
     toast.error(err.message);
    }
   })
   .finally(() => {
    if (!isApproved) {
     setTimeout(() => {
      toast.dismiss();
     }, 3000);
    }
   });
 };

 //delete Administrator
 const deleteAdministrator = async (uid) => {
  setIsLoading(true);
  toast.loading("loading");

  return await removeAdmin(uid)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Successful removed Admininstrator");
     //  navigate("/");

     if (user) {
      await sendPushNotification({
       title: "admin",
       body: `${user?.name} deleted an admininstrator`,
       data: {
        name: user?.name,
        photoURL: user?.photoURL,
        push_time: Date.now(),
       },
      });
     }

     return res;
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //delete Partner
 const deletePartner = async (payload) => {
  setIsLoading(true);
  toast.loading("loading");

  return await removePartner(payload?.uid)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Successful removed Partner");

     if (user) {
      await sendPushNotification({
       title: "admin",
       body: `${user?.name} deleted ${payload?.businessName} partner`,
       data: {
        name: user?.name,
        photoURL: user?.photoURL,
        push_time: Date.now(),
       },
      });
     }
     //  navigate("/");
     return res;
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //update current user
 const updateUser = (data) => {
  setIsLoading(true);
  // toast.loading('loading');
  const user = getCurrentUser();

  updateDatabase(user?.uid, data)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Profile successful updated");
     const database = await fetchDatabase(user?.uid);
     setUser(database);
     //  navigate("/");
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //add partner team member
 const addPartnerMember = async (data) => {
  setIsLoading(true);
  toast.loading("loading");

  return await createPartnerMember(data)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Successful Added Member");

     if (user) {
      let name = user?.firstName
       ? `${user?.firstName} ${user?.lastName}`
       : user?.name;
      await sendPartnerPushNotification({
       title: "partner",
       body: `${name} added ${data?.name} as an member`,
       data: {
        // ...user,
        uid: data?.partnerUid ?? data?.uid,
        route: "/partner/members",
        name: name,
        photoURL: user?.partnerPhotoURL ?? user?.photoURL,
        push_time: Date.now(),
       },
      });
     }
     //  navigate("/");
     return res;
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 // update Administrator
 const updatePartnerMember = async (data) => {
  setIsLoading(true);
  return updatePartnerMemberDatabase(data?.uid, data?.partnerUid, data)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Team member successful updated");

     if (user) {
      let name = user?.firstName
       ? `${user?.firstName} ${user?.lastName}`
       : user?.name;
      await sendPartnerPushNotification({
       title: "partner",
       body: `${name} updated ${data?.name} profile`,
       data: {
        // ...user,
        uid: data?.partnerUid ?? data?.uid,
        route: `/partner/members/view/${data?.uid}`,
        name: name,
        photoURL: user?.partnerPhotoURL ?? user?.photoURL,
        push_time: Date.now(),
       },
      });
     }
     //  navigate("/");
     return res;
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 //delete Administrator
 const deletePartnerMember = async (partnerUid, uid) => {
  setIsLoading(true);
  toast.loading("loading");

  return await removePartnerMember(partnerUid, uid)
   .then(async (res) => {
    if (res) {
     toast.dismiss();
     toast.success("Successful removed team member");
     //  navigate("/");

     if (user) {
      let name = user?.firstName
       ? `${user?.firstName} ${user?.lastName}`
       : user?.name;
      await sendPartnerPushNotification({
       title: "partner",
       body: `${name} deleted an admininstrator`,
       data: {
        uid: partnerUid,
        route: "/partner/members",
        name: name,
        photoURL: user?.partnerPhotoURL ?? user?.photoURL,
        push_time: Date.now(),
       },
      });
     }

     return res;
    }
   })
   .catch((err) => {
    toast.dismiss();
    toast.error(err.message);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 const value = {
  role,
  user,
  partner,
  isLoading,
  setisLoading: setIsLoading,
  gettingUser,
  signup,
  signin,
  partnerOnboard,
  handlePasswordReset,
  addAdmin,
  signout,
  partnerSignin,
  adminSignin,
  updateUser,
  updateAdministrator,
  deleteAdministrator,
  deletePartner,
  updatePartner,
  addPartnerMember,
  updatePartnerMember,
  deletePartnerMember,
 };
 return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
