import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { Icon } from "../../components";
// import Loader from '../../components/Loader';
import { Link, useParams } from "react-router-dom";
import {
 NoCollections,
 SearchInput,
 SearchLabel,
 SlideImg,
 SlideText,
 SlideWrapper,
 SplideSlideWrapper,
 TagButton,
 TitleSection,
 Heading,
 Title,
 TagButtons,
} from "./styles";
import { colors } from "../../assets/styles/config";
import SingleCollection from "./SingleCollection";
import { onValue, ref } from "firebase/database";
import { db } from "../../firebase";
import AnimatedArrowIndicator from "../../components/AnimatedArrowIndication";
const categoriesDB = ref(db, "cityExperience/categories");

const CollectionsList = ({ setLoading, currentCity, setCurrentCity }) => {
 const { cityId } = useParams();
 const [collections, setCollections] = useState([]);
 const [filter, setFilter] = useState("All");
 const [filters, setFilters] = useState([]);
 const [filteredCollections, setFilteredCollections] = useState([]);
 const [showSingleCollection, setShowSingleCollection] = useState(false);
 const [selectedCollection, setSelectedCollection] = useState({});

 // OTHER VARIABLES
 const cityExperienceDb = ref(db, "cityExperience/city");

 // EFFECTS
 useEffect(() => {
  const unsubscribe = onValue(cityExperienceDb, (snapshot) => {
   const data = snapshot.val();
   setCurrentCity(cityId);
   setCollections(data[cityId]?.collections);

   if (data[cityId]?.collections?.length > 0) {
    //console.log(data[cityId]?.collections[0]);
   }
  });

  return () => unsubscribe();
 }, [cityId]);

 useEffect(() => {
  // const tagsList = collections
  //   ?.map((collection) => collection.tag.join(','))
  //   .toString();
  // const tags = tagsList.split(',');
  // const filteredTags = Array.from(new Set(tags)).sort();
  const unsubscribe = onValue(categoriesDB, (snapshot) => {
   const categories = snapshot.val();
   setFilters(categories.sort());
   filterCollections(categories[0]);
  });

  return () => unsubscribe();
 }, [collections]);

 // FUNCTIONS
 const filterCollections = (tag) => {
  const newCollections = collections?.filter((collection) =>
   collection.tag.includes(tag)
  );
  setFilteredCollections(newCollections);
  setFilter(tag);
 };

 const handleSearchInput = (value) => {
  if (filter !== "All") {
   setFilter("All");
  }
  const newCollections = collections?.filter((collection) =>
   collection.title.toLowerCase().includes(value.toLowerCase())
  );
  setFilteredCollections(newCollections);
 };

 // JSX
 const renderSearchInput = useMemo(
  () => (
   <SearchLabel>
    {" "}
    <div className="icon" style={{ marginBottom: "-5px" }}>
     <Icon name="search" />
    </div>
    <SearchInput
     type="search"
     name="search collections"
     id=""
     placeholder="Search"
     onChange={(e) => handleSearchInput(e.target.value)}
    />
   </SearchLabel>
  ),
  [collections]
 );

 const renderCollections = useMemo(
  () =>
   !filteredCollections?.length < 1 ? (
    <SlideWrapper>
     {" "}
     {filteredCollections?.map((collection, index) => (
      <Link
       to={`/collections/${cityId}/${collection.title.split(" ").join("-")}`}
      >
       <SplideSlideWrapper
        key={index}
        onClick={() => {
         setShowSingleCollection(true);
         setSelectedCollection(collection);
        }}
        style={{ width: "100%", height: "200px" }}
       >
        <div className="img-bg">
         <SlideImg src={collection.img} alt={collection.title} />{" "}
        </div>
        <SlideText>{collection.title}</SlideText>
       </SplideSlideWrapper>
      </Link>
     ))}
    </SlideWrapper>
   ) : (
    <NoCollections>No collections found</NoCollections>
   ),
  [filteredCollections]
 );

 return (
  <div>
   <Flex display={{ base: "block", md: "none" }}>{renderSearchInput}</Flex>
   <Flex
    alignItems="center"
    justifyContent={"space-between"}
    // flexDir={{ base: "column-reverse", md: "row" }}
    overflow={"scroll"}
   >
    <Flex alignItems="center" gap="1rem" maxWidth={{ lg: "60%" }} mt="27px">
     <TagButtons gap={{ base: "12px", md: "16px" }} max-width="70%">
      {filters?.map((filterTag) => (
       <TagButton
        selected={filterTag === filter}
        key={filterTag}
        onClick={() => filterCollections(filterTag)}
       >
        {filterTag}
       </TagButton>
      ))}
     </TagButtons>{" "}
    </Flex>

    <Flex display={{ base: "none", md: "block" }}>{renderSearchInput}</Flex>
   </Flex>
   {renderCollections}
   {showSingleCollection && (
    <SingleCollection
     visible={showSingleCollection}
     setVisible={setShowSingleCollection}
     collection={selectedCollection}
    />
   )}
  </div>
 );
};

export default CollectionsList;
