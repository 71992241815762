import { Box, Img } from "@chakra-ui/react";
import React from "react";
import { bytesToSize } from "../../../../../../utils";

const ScrollMedia = ({ media, open }) => {
 return (
  <>
   <Box display="flex" alignItems="center" flexDir="column">
    <Box
     width="100%"
     height="100%"
     background="#F8FBFF"
     display="flex"
     justifyContent="center"
     alignItems="center"
     border="none"
    >
     <Img
      src={media[0]?.preview}
      alt="avatar"
      width="100%"
      height="153px"
      cursor="pointer"
      marginBottom="20px"
      onClick={open}
     />
    </Box>

    <div>
     {media?.map((file) => (
      <p key={file.path}>
       {file.name.substring(0, 6)}... ({bytesToSize(file.size)}
       ),
      </p>
     ))}
    </div>
   </Box>
  </>
 );
};

export default ScrollMedia;
