import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { EmptyState, Icon } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { MdLocalOffer } from "react-icons/md";
import {
 SearchInput,
 SearchLabel,
 SlideWrapper,
 SplideSlideWrapper,
 TagButton,
 Heading,
 Title,
 Container,
 ExperiencePrice,
 ExperienceInfo,
 ExperienceTitle,
 ExperienceAction,
 ExperiencePartner,
 BookNow,
 PaginationBox,
 TagButtons,
 ExperiencePriceWrapper,
} from "./styles";
import { colors } from "../../assets/styles/config";

import Pagination from "react-js-pagination";

import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { onValue, ref } from "firebase/database";
import { db } from "../../firebase";
import parse from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { cleanExperienceTitle } from "../../utils";

const categoriesDB = ref(db, "cityExperience/categories");

const ExperiencesList = ({ experiences }) => {
 const [filter, setFilter] = useState("All");
 const [filters, setFilters] = useState([]);
 const [filteredExperiences, setFilteredExperiences] = useState([]);
 const [activePage, setActivePage] = useState(1);
 const [currentVariation, setCurrentVariation] = useState("Filter by");
 const allExpsTitle = useRef(null);
 const NUMBER_OF_EXPERIENCES_PER_PAGE = 8;
 const pagBox = [];
 const tagButtonsRef = useRef(null);
 const tagArrowRef = useRef(null);
 const navigate = useNavigate();

 // VARIABLES
 const sortVariations = [
  { title: "All", value: "all" },
  { title: "Price (Lowest to highest)", value: "price-asc" },
  { title: "Price (Highest to lowest)", value: "price-desc" },
 ];

 // EFFECTS
 useEffect(() => {
  console.log("experiences", experiences);
  const unsubscribe = onValue(categoriesDB, (snapshot) => {
   const categories = snapshot.val();
   setFilters(categories?.sort());
   filterExperiences(categories[0]);
  });

  return () => unsubscribe();
 }, [experiences]);

 // FUNCTIONS
 const filterExperiences = (category) => {
  const newExperiences = experiences
   ?.filter((experience) => experience?.category?.includes(category))
   .slice(0, NUMBER_OF_EXPERIENCES_PER_PAGE);
  setFilteredExperiences(newExperiences);
  setFilter(category);
 };

 const sortExperiences = (e) => {
  const key = e.toLowerCase();
  switch (key) {
   case "all":
    setFilteredExperiences(experiences);
    setFilter("All");
    break;
   case "price-asc":
    setFilteredExperiences(
     experiences?.sort((a, b) => a.leastPrice - b.leastPrice)
    );
    break;
   case "price-desc":
    setFilteredExperiences(
     experiences?.sort((a, b) => b.leastPrice - a.leastPrice)
    );
    break;

   default:
    break;
  }
 };

 const handleSearchInput = (value) => {
  if (filter !== "All") {
   setFilter("All");
  }
  const newExperiences = experiences?.filter((experience) =>
   experience.title.toLowerCase().includes(value.toLowerCase())
  );
  setFilteredExperiences(newExperiences);
 };

 const scrollTagsLeft = () => {
  tagButtonsRef.current.style.scrollBehavior = "smooth";
  tagButtonsRef.current.scrollLeft += 180;
  tagArrowRef.current.style.display = "none";
  tagButtonsRef.current.classList.add("auto");
 };

 // PAGINATION FUNCTION
 const pagCount = [];
 const pagExpList = experiences?.length > 0 && [...experiences];
 for (
  let i = 0;
  i < experiences?.length / NUMBER_OF_EXPERIENCES_PER_PAGE;
  i++
 ) {
  const sliceExp = pagExpList.slice(0, NUMBER_OF_EXPERIENCES_PER_PAGE);
  pagBox.push(sliceExp);
  pagCount.push(i + 1);
  pagExpList.splice(0, NUMBER_OF_EXPERIENCES_PER_PAGE);
 }

 const handlePageChange = (pageNumber) => {
  setActivePage(pageNumber);
  setFilteredExperiences(pagBox[pageNumber - 1]);
  allExpsTitle.current.scrollIntoView();
 };

 // JSX
 const renderSearchInput = useMemo(
  () => (
   <SearchLabel>
    {" "}
    <div className="icon">
     <Icon name="search" />{" "}
    </div>
    <SearchInput
     type="search"
     name="search experiences"
     id=""
     placeholder="Search"
     onChange={(e) => handleSearchInput(e.target.value)}
    />
   </SearchLabel>
  ),
  [experiences]
 );

 const renderExperiences = useMemo(
  () =>
   !filteredExperiences?.length < 1 ? (
    <SlideWrapper>
     {filteredExperiences?.map((experience) => {
      // const encodedTitle = encodeURIComponent(
      //  experience?.title.split(" ").join("-")
      // ).replace(/%[0-9A-Fa-f]{2}/g, "");

      const encodedTitle = cleanExperienceTitle(experience?.title);

      const navigateToSingleExperience = () => {
       localStorage.setItem("experienceId", experience?.id);
       navigate(`/experiences/${encodedTitle}`);
      };

      return (
       <Link
        to={`/experiences/${encodedTitle}`}
        key={experience?.id}
        className="exp-card"
       >
        <SplideSlideWrapper>
         <ExperiencePriceWrapper>
          {experience.type === "packaged" ? (
           <ExperiencePrice>
            <MdLocalOffer />
           </ExperiencePrice>
          ) : (
           <Text />
          )}
          <ExperiencePrice>
           {
            <>
             <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
             >
              <path
               d="M3.33203 7.5H4.9987V2.5H6.66536L9.51536 7.5H13.332V2.5H14.9987V7.5H16.6654V9.16667H14.9987V10.8333H16.6654V12.5H14.9987V17.5H13.332L10.4737 12.5H6.66536V17.5H4.9987V12.5H3.33203V10.8333H4.9987V9.16667H3.33203V7.5ZM6.66536 7.5H7.60703L6.66536 5.85833V7.5ZM6.66536 9.16667V10.8333H9.51536L8.56536 9.16667H6.66536ZM13.332 14.1667V12.5H12.3737L13.332 14.1667ZM10.4654 9.16667L11.4237 10.8333H13.332V9.16667H10.4654Z"
               fill="#0F0931"
              />
             </svg>
             {experience?.leastPrice.toLocaleString()}
            </>
           }
          </ExperiencePrice>
         </ExperiencePriceWrapper>
         {/* <SlideImg
                    src={experience?.img}
                    alt={parse(experience.title)}
                  /> */}
         <LazyLoadImage
          src={experience?.img}
          alt={parse(experience.title)}
          effect="blur"
          width="100%"
          className="slide-img"
         />
         <ExperienceInfo>
          <ExperienceTitle>{parse(experience.title)}</ExperienceTitle>
          <Flex flexDirection={"column"}>
           <ExperiencePartner>
            By {" " + JSON.parse(experience.details).partner}
           </ExperiencePartner>
          </Flex>
         </ExperienceInfo>
         <ExperienceAction>
          <BookNow onClick={navigateToSingleExperience}>Book Now</BookNow>{" "}
         </ExperienceAction>
        </SplideSlideWrapper>
       </Link>
      );
     })}
    </SlideWrapper>
   ) : (
    <EmptyState text={"Sorry, there are no experiences by that value"} />
   ),
  [filteredExperiences, sortExperiences]
 );

 const renderPagination = useMemo(() => <></>, []);

 const RenderSortBy = () => (
  <>
   <Menu>
    <MenuButton
     fontSize={{ base: "14px", md: "15px", lg: "16px" }}
     fontWeight={400}
     background={"#F1F5F9"}
     rightIcon={<ChevronDownIcon />}
     as={Button}
     color="#252046"
     padding="0 21.5px"
     height={"47px"}
     letterSpacing="0.05em"
     border="1px solid #64748B"
     borderRadius={"34px"}
    >
     {currentVariation}
    </MenuButton>
    <MenuList
     background={"white"}
     borderRadius={{ base: "13px", md: "14px" }}
     boxShadow={" rgba(0, 0, 0, 0.16) 0px 1px 4px"}
     minW={"100%"}
     padding={{ base: "7px 25px", md: "10px 30px" }}
     zIndex={6}
    >
     {sortVariations?.map((variation) => (
      <MenuItem
       key={variation.value}
       color={colors.brown}
       fontSize={{ base: "14px", md: "15px", lg: "16px" }}
       transition={".3s ease-in"}
       cursor={"pointer"}
       minW="100%"
       _hover={{
        fontSize: { base: "13px", md: "14px", lg: "15px" },
       }}
       _focus={{ bg: "transparent" }}
       onClick={() => {
        setCurrentVariation(variation.title);
        sortExperiences(variation.value);
       }}
       paddingBlock="5px"
      >
       {variation.title}
      </MenuItem>
     ))}
    </MenuList>
   </Menu>
  </>
 );

 return (
  <div>
   <Container>
    <Heading ref={allExpsTitle}>
     <Title>All Experiences</Title> {renderSearchInput}
    </Heading>
    <Flex
     justifyContent={"space-between"}
     flexDirection={{ base: "column", lg: "row" }}
     alignItems={{ base: "start", lg: "center" }}
     gap={{ base: "2px", md: "26px", lg: "0" }}
    >
     <Flex alignItems="center" gap="1rem" maxWidth={"100%"}>
      <TagButtons
       gap={{ base: "12px", md: "16px" }}
       max-width="70%"
       ref={tagButtonsRef}
      >
       {filters?.map((filterTag) => (
        <TagButton
         selected={filterTag === filter}
         key={filterTag}
         onClick={() => filterExperiences(filterTag)}
        >
         {filterTag}
        </TagButton>
       ))}
      </TagButtons>
     </Flex>
     <RenderSortBy />
    </Flex>
    {renderExperiences} {renderPagination}
   </Container>
   <PaginationBox>
    <Pagination
     activePage={activePage}
     itemsCountPerPage={8}
     totalItemsCount={experiences?.length}
     pageRangeDisplayed={5}
     onChange={handlePageChange}
     disabledClass="navDisabled"
     itemClassFirst={"prevLi"}
     linkClassFirst={"white"}
     linkClassPrev={"white"}
     linkClassNext={"white"}
     linkClassLast={"white"}
    />
   </PaginationBox>
  </div>
 );
};

export default ExperiencesList;
