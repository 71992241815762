import React, { useContext, useState, useEffect, useRef } from "react";
import { Box, HStack, Image, Text } from "@chakra-ui/react";
import ProfileFrame from "../../../assets/images/Profile-frame.svg";
import ArrowLeft from "../../../assets/images/arrowleft2.svg";
import { Chart, EmptyState } from "../../../components";
import { Modal } from "../../../components";
import { colors } from "../../../assets/styles/config";
import {
 DashboardHero,
 PieChartWrapper,
 StatisticsCardsWrapper,
 statisticsCardWrapper,
 StatisticsImg,
 StatisticsTitle,
 StatisticsValue,
 RBEHeading,
 RBEHeadings,
 RBEInfo,
 RBESection,
 RBEWrapper,
} from "../../Admin/Dashboard/styles";
import { AuthContext } from "../../../context/AuthContext";
import { Loader } from "../../../components";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { db } from "../../../firebase";
import {
 TicketIcon,
 TickekDiscountIcon,
 Ticket2Icon,
 TicketReceiptIcon,
} from "../../../components/Icon/adminIcons";
import RecentlyBoughtExperiences from "../../Admin/Dashboard/RecentlyBoughtExperiences";
// import PieChartUpdate from "../../../components/Chart/PieChartUpdateDoc";
// import SplineAreaChart from "../../../components/Chart/SplineAreaChart";
import { LoadingIndicator } from "../../../components";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const Dashboard = () => {
 const naviagte = useNavigate();
 const { role, user } = useContext(AuthContext);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [qrscan, setQrscan] = useState("No result");
 const [users, setUsers] = useState([]);
 const [experiences, setExperience] = useState([]);
 const [loading, setLoading] = useState(false);
 const [partner, setPartner] = useState(null);
 const [boughtExperiences, setBoughtExperience] = useState([]);
 const [redeemed, setRedeemed] = useState([]);
 const [deals, setDeals] = useState([]);
 const uid = user?.partnerUid ?? user?.uid;

 const fetchPartner = async () => {
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `users/partner`)).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();

    setPartner(
     Object.keys(myObj)
      .map((key) => {
       return myObj[key];
      })
      ?.filter((item) => item?.uid === uid)[0]
    );

    // var array = Object.keys(myObj)
    //   .map((key) => {
    //     return myObj[key];
    //   })
    //   .filter((item) => item?.uid === uid && item?.experiences)
    //   .map((item) => item?.experiences)
    //   .reduce((r, c) => Object.assign(r, c), {});

    // let filterArray = Object.keys(array)
    //   .map((key) => {
    //     return array[key];
    //   })
    //   ?.filter((item) => item.status === "redeemed");

    // // console.log("filterArray", filterArray);
    // setRedeemed(filterArray);
    // setRedeemed(
    //   Object.keys(myObj)
    //     .map((key) => {
    //       return myObj[key];
    //     })
    //     .filter((item) => item?.uid === uid && item?.experiences)
    //     .map((item) => item?.experiences)?.filter((item) => item?.experiences?.status === "redeemed")
    //     .reduce((r, c) => Object.assign(r, c), {})
    // );

    setDeals(
     Object.keys(myObj)
      .map((key) => {
       return myObj[key];
      })
      .filter((item) => item?.uid === uid && item?.enabled && item.coupon)[0]
    );
   }
  });

  return db;
 };

 const fetchTicketDatabase = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  const db = await get(child(dbRef, `tickets`)).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();
    var array = Object.keys(myObj)
     .map((key) => {
      return myObj[key];
     })
     .filter((item) => item?.partnerId === uid)
     ?.filter((item) => item.status === "redeemed");

    setRedeemed(array);
    setLoading(false);
   }
  });

  return db;
 };

 const fetchUsers = async () => {
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `users/user`)).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();

    setUsers(
     Object.keys(myObj).map((key) => {
      return myObj[key];
     })
    );
   }
  });

  return db;
 };

 const filterObjectExperience = (obj) =>
  Object.keys(obj).reduce(
   (acc, val) =>
    obj[val]["partnerUid"] === uid
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 const fetchExperience = async () => {
  // setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `cityExperience/experiences/`)).then(
   (snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     let objExp = filterObjectExperience(snapshot.val());

     let array = Object.keys(objExp).map((key) => {
      return {
       ...objExp[key],
      };
     });

     setExperience(array);
    } else {
     //  console.log("No data available");
    }
   }
  );
  // .catch((error) => {
  //   // console.error(error);
  //   setLoading(false);
  // });
  return db;
 };

 const fetchBougthExperience = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `transactions/`))
   .then((snapshot) => {
    if (snapshot.exists()) {
     const data = Object.values(snapshot.val());
     var sortedData = data
      ?.map((obj) => {
       return {
        ...obj,
        transationDate: new Date(JSON.parse(obj.transationDate)),
       };
      })
      .sort((a, b) => b.transationDate - a.transationDate);
     setBoughtExperience(sortedData?.filter((item) => item?.partnerId === uid));
    }
   })
   .finally(() => {
    setLoading(false);
   });

  return db;
 };

 useEffect(() => {
  if (user) {
   fetchUsers();
   fetchPartner();
   fetchTicketDatabase();
   fetchBougthExperience();
   fetchExperience();
  }
 }, [user]);

 const statistics = [
  // {
  //   title: "Total Navigators",
  //   total: 300,
  //   icon: PeopleIcon,
  //   value: 0,
  //   background: "#EFF6FF",
  // },
  {
   title: "Total Experiences uploaded",
   total: 300,
   icon: TicketIcon,
   value: experiences?.length,
   background: "#ECFEFF",
  },
  {
   title: "Total Tickets Sold",
   total: 300,
   icon: TickekDiscountIcon,
   value: boughtExperiences?.reduce((acc, obj) => acc + obj.ticketQuantity, 0),
   background: "#EFF6FF",
  },
  {
   title: "Total Experiences redeemed",
   total: 300,
   icon: Ticket2Icon,
   value: redeemed?.length,
   background: "#F3E8FF",
  },
  {
   title: "Total Deals given",
   total: 300,
   icon: TicketReceiptIcon,
   value: deals?.coupon?.length ?? 0,
   background: "#FDF1E6",
  },
 ];

 return (
  <Box
   minHeight="100vh"
   height="100%"
   background="#F5F8FA"
   paddingRight="20px"
   paddingLeft={{ base: "20px", lg: 0 }}
   paddingBottom={"30px"}
  >
   {loading ? (
    <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
     <LoadingIndicator />
    </Box>
   ) : (
    <>
     <StatisticsCardsWrapper>
      {partner &&
       statistics.map((item, id) => (
        <Box key={id} {...statisticsCardWrapper}>
         <StatisticsImg background={item.background}>
          <img src={item.icon} />
         </StatisticsImg>
         <StatisticsTitle>{item?.title}</StatisticsTitle>
         <StatisticsValue>{item?.value}</StatisticsValue>
        </Box>
       ))}
     </StatisticsCardsWrapper>
     {boughtExperiences?.length > 0 ? (
      <DashboardHero>
       <div>
        <Box
         overflowX={"auto"}
         background="#fff"
         width="100%"
         border="1px solid #e2e8f0"
         borderRadius="16px"
         display={{ base: "none", md: "block" }}
        >
         <RBEHeading>Recently bought experiences</RBEHeading>
         <RBEWrapper>
          <thead>
           <RBEHeadings>
            <td>Date</td>
            <td>Experience Bought</td>
            <td>Amount</td>
            <td>Experience Date</td>
           </RBEHeadings>
          </thead>
          <RBESection>
           {boughtExperiences.slice(0, 8).map((experience) => {
            return (
             <>
              <RBEInfo>
               <td>{dayjs(experience.transationDate).format("MMMM D YYYY")}</td>
               <td>{experience.experienceName}</td>
               <td>{`NGN ${experience.total}`}</td>
               <td>{experience.timeChosen}</td>
              </RBEInfo>
             </>
            );
           })}
          </RBESection>
         </RBEWrapper>
        </Box>

        <Box display={{ base: "block", md: "none" }}>
         <RBEHeading style={{ fontSize: "18px", padding: "10px 0px" }}>
          Recently bought experiences
         </RBEHeading>
         <Box
          overflowX={"auto"}
          background="#fff"
          width="100%"
          border="1px solid #e2e8f0"
          borderRadius="16px"
          // marginTop="20px"
          padding="0px 10px"
         >
          {boughtExperiences.slice(0, 8)?.map((experience, index) => (
           <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            // justifyContent="space-between"
            padding="15px 10px"
            borderBottom={
             boughtExperiences.slice(0, 8)?.length - 1 !== index &&
             "1px solid #E2E8F0"
            }
            cursor="pointer"
            // onClick={() =>
            //   naviagte(`/partner/dashboard/${experience.id}`)
            // }
           >
            <Text
             color={colors.darkBlue}
             fontWeight="500"
             lineHeight="20px"
             marginBottom="10px"
             fontSize="18px"
            >
             {experience.experienceName}
            </Text>
            <Box display="flex" alignItems="center" gap="5px" flexWrap="wrap">
             <Text
              color={colors.darkBlue}
              fontWeight="400"
              lineHeight="15px"
              fontSize="14px"
             >
              {dayjs(experience.transationDate).format("MMMM D YYYY")}
             </Text>{" "}
             |{" "}
             <Text
              color={colors.darkBlue}
              fontWeight="400"
              lineHeight="15px"
              fontSize="14px"
             >{`NGN ${experience.total}`}</Text>
             |{" "}
             <Text
              color={colors.darkBlue}
              fontWeight="400"
              lineHeight="15px"
              fontSize="14px"
             >
              {experience.timeChosen}
             </Text>
            </Box>

            {/* <Image src={ArrowLeft} alt="arrow-left" /> */}
            <Box></Box>
           </Box>
          ))}
         </Box>
        </Box>
       </div>
      </DashboardHero>
     ) : (
      <EmptyState
       text={"There are no recently bought experiences at the moment."}
      />
     )}
    </>
   )}
  </Box>
 );
};

export default Dashboard;
