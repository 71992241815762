import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  InputGroup,
  Image,
  Input,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { Transition } from "@headlessui/react";
import { storedb } from "../../../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { ChatContext } from "../../../../context/ChatContext";
import { AuthContext } from "../../../../context/AuthContext";
import dayjs from "dayjs";
import "dayjs/plugin/relativeTime";
import { AiFillFile } from "react-icons/ai";

dayjs.extend(require("dayjs/plugin/relativeTime"));

const Message = ({ message, currentUser, data }) => {
  const ref = useRef();
  const uid = currentUser?.uid;
  const milliseconds =
    message?.date?.seconds * 1000 +
    Math.floor(message?.date?.nanoseconds / 1000000);
  const date = new Date(milliseconds);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  console.log("currentUser", currentUser);

  return (
    <>
      <Box
        ref={ref}
        width="100%"
        display="flex"
        marginBottom="20px"
        flexDir="column"
        // flexDirection={
        //   message?.senderId === currentUser?.uid ? "column-reverse" : "column"
        // }
        justifyContent={message?.senderId === uid ? "flex-end" : "flex-start"}
      >
        <Box
          display="flex"
          gap="10px"
          flexDirection={message?.senderId === uid ? "row-reverse" : "row"}
        >
          <Box display="flex" alignItems="center" flexDir="column">
            <Image
              src={
                message?.senderId === uid
                  ? currentUser?.partnerPhotoURL ?? currentUser?.photoURL
                  : data?.user?.photoURL
              }
              width="40px"
              height="40px"
              cursor="pointer"
              borderRadius="50%"
            />
            <Text
              color="#64748B"
              fontSize="12px"
              lineHeight="16px"
              marginTop="10px"
            >
              {dayjs(date)
                .fromNow()
                // .replace(/\s/g, "")
                .replace("ago", "")
                .replace("minutes", "min")
                .replace("hours", "hrs")
                .replace("seconds", "s")
                .replace("a few s", "1 sec")}
            </Text>
          </Box>

          <Box
            display="flex"
            alignItems={message?.senderId === uid ? "flex-end" : "flex-start"}
            gap="5px"
            flexDirection="column"
          >
            <Text
              color="#334155"
              fontSize="12px"
              lineHeight="16px"
              fontWeight="500"
              marginBottom="10px"
            >
              {message?.senderId === uid
                ? currentUser?.name ??
                  `${currentUser?.firstName} ${currentUser?.lastName}`
                : data.user.displayName}
            </Text>
            <Box
              borderRadius={
                message?.senderId === currentUser?.uid
                  ? "16px 16px 0px 16px"
                  : "16px 16px 16px 0px"
              }
              background={message?.senderId === uid ? "#EBF7FF" : "#F3F4F7"}
              padding="8px"
              width={{ base: "200px", md: "300px" }}
            >
              <Text color="#334155" fontSize="12px" fontWeight="500">
                {message.text}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={message?.senderId === uid ? "row-reverse" : "row"}
        >
          {message?.file?.map((file) => (
            <a href={file} target="_blank">
              <Box display="flex" alignItems="center" marginBottom="10px">
                <AiFillFile />{" "}
                <Text
                  textAlign="center"
                  color="#64748B"
                  fontFamily="Satoshi"
                  fontSize="16px"
                  lineHeight="135%"
                  marginLeft="10px"
                  // key={file.path}
                >
                  {file.substring(0, 6)}... ,
                </Text>
              </Box>
            </a>
          ))}
        </Box>
      </Box>
    </>
  );
};

const Messages = ({ currentUser, supports }) => {
  const [messages, setMessages] = useState([]);
  const { user } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const uid = user?.uid;

  useEffect(() => {
    if (supports !== null) {
      const chatId =
        supports[0]?.uid > uid
          ? supports[0]?.uid + uid
          : uid + supports[0]?.uid;

      const id = data.chatId ?? chatId;

      console.log("id", id);

      const unSub = onSnapshot(doc(storedb, "chats", id), (doc) => {
        doc.exists() && setMessages(doc.data().messages);
      });

      return () => {
        unSub();
      };
    }
  }, [data, supports, uid]);

  return (
    <Box>
      {messages?.map((m) => (
        <Message
          message={m}
          currentUser={currentUser}
          data={data}
          index={m.id}
        />
      ))}
    </Box>
  );
};

export default Messages;
