// http://localhost:4222/partner/experience/
import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 Button,
 List,
 ListItem,
 SimpleGrid,
 Input,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Image,
 InputGroup,
 InputLeftElement,
 Tabs,
 TabList,
 TabPanels,
 Tab,
 TabPanel,
} from "@chakra-ui/react";
import styles from "./index.module.css";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import { searchInputStyle, selectStyle } from "../../../Admin/Dashboard/styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { colors } from "../../../../assets/styles/config";
import { AuthContext } from "../../../../context/AuthContext";
import OptionIcon from "../../../../assets/images/option.svg";
import Warning from "../../../../assets/images/warning-icon.svg";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
 EmptyState,
 MobileAddBtn,
} from "../../../../components";
import { Card, CloseBtn, Container } from "../../styles";
import { useDownloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import ArrowRight from "../../../../assets/images/arrowleft2.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import isBetween from "dayjs/plugin/isBetween";
import { sendPartnerPushNotification } from "../../../../api/user";
import { svgFlexStyles } from "../../../Admin/PartnerManagement/RedeemExperiences/styles";
import Lottie from "../../../../assets/images/redeem-experiences-lottie.svg";
import { TabTdStyles, addExpBtn, addExpBtnMb, expTd, tableTh } from "./styles";
import { tabListStyles, tabStyles, tabTHStyles } from "../styles";
import { isEqual } from "lodash";

dayjs.extend(isBetween);

const PartnerExperiences = () => {
 const { user } = useContext(AuthContext);
 const toggle = useRef(null);
 const [loading, setLoading] = useState(false);
 const [experiences, setExperience] = useState([]);
 const [allExperiences, setAllExperiences] = useState([]);
 const [filterExperiences, setFilterExperiences] = useState([]);
 const [upcomingExperiences, setUpcomingExperiences] = useState([]);
 const [completedExperiences, setCompletedExperiences] = useState([]);
 const [search, setSearch] = useState("");
 const [partner, setPartner] = useState([]);
 const [selected, setSelected] = useState(null);
 const [selectedExp, setSelectedExp] = useState({});
 const [selectedExpId, setSelectedExpId] = useState();
 const [modalType, setModalType] = useState("");
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const tableRef = useRef(null);
 const navigate = useNavigate();
 const [recentExps, setRecentExps] = useState([]);
 const uid = user?.partnerUid ?? user?.uid;

 //  FUNCTIONS
 const fetchBoughtExp = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `transactions/`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     const data = Object.values(snapshot.val());
     setRecentExps(
      Object.keys(data).map((key) => {
       return {
        ...data[key],
       };
      })
     );
    }
   })
   .catch((error) => {
    setLoading(false);
   });
  return db;
 };

 const filterObjectExperience = (obj) =>
  Object.keys(obj).reduce(
   (acc, val) =>
    obj[val]["partnerUid"] === uid
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 const fetchDatabase = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `cityExperience/experiences/`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     setAllExperiences(snapshot.val());
     let objExp = filterObjectExperience(snapshot.val());

     let array = Object.keys(objExp).map((key) => {
      return {
       ...objExp[key],
      };
     });

     var sortedData = array
      ?.map((obj) => {
       return { ...obj, date: new Date(JSON.parse(obj.timestamp)) };
      })
      .sort((a, b) => a.date - b.date);

     setExperience(sortedData);
    } else {
     //  console.log("No data available");
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  return db;
 };

 const handleClickOutside = (event) => {
  // if (!toggle.current.contains(event.target)) {
  //   //  setSelected(null);
  // }
 };

 const searchFilter = (text) => {
  if (text) {
   setSearch(text);
   const newData = filterExperiences?.filter((item) => {
    const itemData = item.title
     ? item.title.replace(/<\/?[^>]+(>|$)/g, "").toUpperCase()
     : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setFilterExperiences(newData);
  } else {
   setSearch(text);

   const mappedData = experiences.reverse().map((item) => {
    const experience = JSON.parse(item.timeframes);
    return experience?.map((exp) => {
     return exp?.timeSlot.map((slot, index) => {
      return {
       ...item,
       date: exp.date,
       recurring: exp.recurring,
       custom_recurrence: exp.custom_recurrence,
       start: slot.start,
       end: slot.end,
       amount: slot.amount,
       maxTickets: slot.maxTickets,
       timeSlotId: slot.id,
       timeframesId: exp.id,
      };
     });
    });
    // return { city: item.city, experiences: mappedExperiences };
   });

   const flatData = [].concat(...mappedData.flat(Infinity));

   setFilterExperiences(flatData);
  }
 };

 const handleDelete = async () => {
  toast.loading("loading");
  let deleteExp = experiences?.filter((item) => item?.id === selectedExpId)[0];

  const timeframes = JSON.parse(deleteExp?.timeframes) || [];
  let updates = {};

  for (let j = 0; j < timeframes?.length; j++) {
   const timeSlot = timeframes[j]?.timeSlot || [];

   const timeSlotIndex = timeSlot?.findIndex(
    (slot) => JSON.stringify(slot) === JSON.stringify(selectedExp)
   );

   if (timeSlotIndex !== -1) {
    timeSlot.splice(timeSlotIndex, 1);
    deleteExp.timeframes = JSON.stringify(timeframes);

    if (Array.isArray(timeSlot) && timeSlot.length === 0) {
     timeframes.splice(j, 1);
     if (timeframes?.length > 0) {
      deleteExp.timeframes = JSON.stringify(timeframes);
      updates["cityExperience/experiences/" + selectedExpId] = deleteExp;
     } else {
      const filterExp = Object.keys(allExperiences).reduce(
       (acc, val) =>
        allExperiences[val]["id"] !== selectedExpId
         ? {
            ...acc,
            [val]: allExperiences[val],
           }
         : acc,
       {}
      );
      updates["cityExperience/experiences"] = filterExp;
     }
    } else {
     updates["cityExperience/experiences/" + selectedExpId] = deleteExp;
    }
    // break;
   }
  }

  const db = getDatabase();
  await update(ref(db), updates)
   .then(() => {
    toast.dismiss();
    toast.success("Successfully deleted experience");
    fetchDatabase();
    setVisible(!visible);
    setModalType("");
    if (user) {
     let name = user?.firstName
      ? `${user?.firstName} ${user?.lastName}`
      : user?.name;
     sendPartnerPushNotification({
      title: "partner",
      body: `${name} deleted an experience`,
      data: {
       uid: user?.partnerUid ?? user?.uid,
       route: `/partner/experience`,
       name: name,
       photoURL: user?.partnerPhotoURL ?? user?.photoURL,
       push_time: Date.now(),
      },
     });
    }
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 const ticketSold = (expDetail) => {
  let tickets, chosenTimeFrame;

  if (expDetail?.type !== "recurring") {
   chosenTimeFrame = {
    date: dayjs(expDetail?.date).format("YYYY-MM-DD"),
    recurring: "",
    timeSlot: {
     amount: expDetail?.amount,
     end: expDetail?.end,
     id: expDetail?.id,
     maxTickets: expDetail?.maxTickets,
     start: expDetail?.start,
     admitsHowMany: expDetail?.admitsHowMany,
    },
   };

   if (recentExps?.length) {
    // Get all of this partner's experiences bought
    const totalBought = recentExps
     ?.filter((item) => Number(item?.experienceId) === Number(expDetail?.expId))
     ?.filter((item) => isEqual(item?.chosenTimeFrame, chosenTimeFrame));
    //  get the total quantity bought

    const totalChosenQuantity = totalBought.reduce(
     (acc, item) => acc + Number(item?.ticketQuantity),
     0
    );
    tickets = totalChosenQuantity;
   }
  } else {
   let timeSlot = {
    amount: expDetail?.amount,
    end: expDetail?.end,
    id: expDetail?.id,
    maxTickets: expDetail?.maxTickets,
    start: expDetail?.start,
    admitsHowMany: expDetail?.admitsHowMany,
   };
   const totalBought = recentExps?.filter(
    (item) => Number(item?.experienceId) === Number(expDetail?.expId)
   );
   const totalChosen = totalBought.filter((item) =>
    isEqual(item.chosenTimeFrame.timeSlot, timeSlot)
   );
   const totalChosenQuantity = totalChosen.reduce((a, b) => {
    return a + b?.ticketQuantity;
   }, 0);
   tickets = totalChosenQuantity;
  }
  return tickets;
 };

 const goToSingleExpPage = (item) => {
  item?.type === "recurring"
   ? navigate(
      `/partner/experience/recurring/${item.id}/${item.timeframesId}/${item.timeSlotId}`
     )
   : navigate(`/partner/experience/bought/${item.id}`);
 };

 // EFFECTS
 useEffect(() => {
  fetchDatabase();
  fetchBoughtExp();
 }, [user]);

 useEffect(() => {
  const mappedData = experiences.reverse().map((item) => {
   const experience = JSON.parse(item.timeframes);
   return experience?.map((exp) => {
    return exp?.timeSlot.map((slot, index) => {
     return {
      ...item,
      date: exp.date,
      recurring: exp.recurring,
      start: slot.start,
      end: slot.end,
      amount: slot.amount,
      maxTickets: slot.maxTickets,
      timeSlotId: slot.id,
      timeframesId: exp.id,
      admitsHowMany: slot.admitsHowMany,
     };
    });
   });
   // return { city: item.city, experiences: mappedExperiences };
  });

  const flatData = [].concat(...mappedData.flat(Infinity));

  setFilterExperiences(flatData);
 }, [experiences]);

 useEffect(() => {
  const experiencesComing = filterExperiences
   ?.filter((exp) => exp.type !== "packaged")
   ?.filter(
    (exp) =>
     exp?.type === "recurring" ||
     dayjs(exp?.date).isAfter(dayjs(), "day") ||
     dayjs(exp?.date).isSame(dayjs(), "day")
   );
  const experiencesCompleted = filterExperiences
   ?.filter((exp) => exp.type !== "packaged")
   ?.filter(
    (exp) =>
     exp?.type !== "recurring" && dayjs(exp?.date).isBefore(dayjs(), "day")
   );
  setUpcomingExperiences(experiencesComing);
  setCompletedExperiences(experiencesCompleted);
 }, [filterExperiences]);

 useEffect(() => {
  document.addEventListener("click", handleClickOutside);

  return () => {
   document.removeEventListener("click", handleClickOutside);
  };
 }, []);

 return (
  <Box
   height="100%"
   padding={{ base: "0px 20px", md: "14px 20px" }}
   background="#F5F8FA"
  >
   <Box
    background={{ md: "#FFFFFF" }}
    borderRadius="6px"
    // mt="16px"
    minHeight="calc(100vh - 110px)"
    padding={{ md: "30px" }}
    position="relative"
   >
    <Flex
     alignItems="center"
     flexDir={{ base: "column", md: "row" }}
     justifyContent="space-between"
     display={{ base: "none", md: "flex" }}
    >
     <InputGroup borderRadius="28px" width="50%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>
     <Box display="flex" alignItems="center">
      <Link to={`/partner/experience/add`}>
       <Button {...addExpBtn} type="button">
        <AddIcon h="16px" w="16px" mr={{ lg: "20px" }} />
        <Text display={{ base: "none", lg: "inline-block" }}>
         Add New Experience
        </Text>
       </Button>
      </Link>{" "}
     </Box>
    </Flex>

    <Link to={`/partner/experience/add`}>
     <MobileAddBtn />
    </Link>

    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt={{ md: "30px" }}>
      <Tabs variant="soft-rounded">
       <TabList {...tabListStyles} className="tabs">
        <Tab {...tabStyles}>Upcoming </Tab>
        <Tab {...tabStyles}>Special Offers </Tab>
        <Tab {...tabStyles}>Completed </Tab>
       </TabList>

       <TabPanels>
        <TabPanel padding={{ base: "0px", md: "20px 0px" }}>
         {upcomingExperiences?.length > 0 ? (
          <>
           <TableContainer
            border="1px solid #E2E8F0"
            borderRadius="16px 16px 0px 0px"
            overflowX="initial"
            overflowY="visible"
            display={{ base: "none", md: "block" }}
           >
            <Table variant="simple" ref={tableRef}>
             <Thead>
              <Tr>
               {[
                "Experience",
                "Date",
                "Start time",
                "End time",
                "Status",
                "Total tx sold",
               ].map((th) => (
                <Th {...tableTh}>{th}</Th>
               ))}
               <Th></Th>
              </Tr>
             </Thead>
             <Tbody>
              {upcomingExperiences?.map((item, index) => (
               <Tr
                key={index}
                cursor="pointer"
                _hover={{
                 background: "#F1F5F9",
                }}
               >
                <Td onClick={() => goToSingleExpPage(item)} {...expTd}>
                 {item?.title}
                </Td>
                <Td onClick={() => goToSingleExpPage(item)} {...expTd}>
                 {item?.type === "recurring"
                  ? item?.recurring
                  : dayjs(item?.date).format("DD-MM-YYYY")}
                </Td>
                <Td onClick={() => goToSingleExpPage(item)} {...expTd}>
                 {item?.start}
                </Td>
                <Td onClick={() => goToSingleExpPage(item)} {...expTd}>
                 {item?.end}
                </Td>
                <Td onClick={() => goToSingleExpPage(item)} {...expTd}>
                 {item?.type === "recurring" ? (
                  <Text textAlign="center">Recurring</Text>
                 ) : dayjs(item?.date).isAfter(dayjs(), "day") ? (
                  <span className={[styles.pill, styles.pending].join(" ")}>
                   Pending
                  </span>
                 ) : dayjs(item?.date).isBefore(dayjs(), "day") ? (
                  <span className={[styles.pill, styles.arroved].join(" ")}>
                   Completed
                  </span>
                 ) : dayjs(item?.date).isSame(dayjs(), "day") &&
                   dayjs().isAfter(item?.start, "h:mm a") &&
                   dayjs().isBefore(item?.end, "h:mm a") ? (
                  <span className={[styles.pill, styles.disapproved].join(" ")}>
                   In progress
                  </span>
                 ) : (
                  <span className={[styles.pill, styles.pending].join(" ")}>
                   Pending
                  </span>
                 )}
                </Td>
                <Td onClick={() => goToSingleExpPage(item)} {...expTd}>
                 {ticketSold({
                  date: item.date,
                  id: item.timeSlotId,
                  start: item.start,
                  end: item.end,
                  amount: item.amount,
                  maxTickets: item.maxTickets,
                  type: item.type,
                  recurring: item.recurring,
                  expId: item.id,
                  admitsHowMany: item.admitsHowMany,
                 }) ?? 0}
                </Td>
                <Td
                 // padding="14px 20px"
                 display="flex"
                 alignItems="center"
                 // gap="20px"
                 position="relative"
                >
                 <Box
                  cursor="pointer"
                  onClick={() => {
                   setSelected(selected === index ? null : index);
                  }}
                  ref={toggle}
                 >
                  <Image src={OptionIcon} />{" "}
                  {selected === index && (
                   <List
                    position="absolute"
                    bottom="10px"
                    right="40px"
                    padding="10px 20px"
                    background="#FFFFFF"
                    boxShadow="0px 4px 24px #D4DEEC"
                    border="12px"
                    cursor="pointer"
                    borderRadius="12px"
                    width="200px"
                   >
                    <ListItem
                     borderBottom="1px solid #E2E8F0"
                     padding="10px 0px"
                     onClick={() =>
                      navigate(`/partner/experience/edit/${item.id}`)
                     }
                    >
                     Edit Experience
                    </ListItem>
                    <ListItem
                     // borderBottom="1px solid #E2E8F0"
                     padding="10px 0px"
                     onClick={() => {
                      setVisible(!visible);
                      setModalType("delete");
                      setSelectedExp({
                       id: item.timeSlotId,
                       start: item.start,
                       end: item.end,
                       amount: item.amount,
                       maxTickets: item.maxTickets,
                       admitsHowMany: item.admitsHowMany,
                      });
                      setSelectedExpId(item.id);
                     }}
                    >
                     Delete experience
                    </ListItem>
                   </List>
                  )}
                 </Box>
                </Td>
               </Tr>
              ))}
             </Tbody>
            </Table>
           </TableContainer>
           <Box display={{ base: "block", md: "none" }}>
            <Box
             overflowX={"auto"}
             background="#fff"
             width="100%"
             border="1px solid #e2e8f0"
             borderRadius="16px"
             marginTop="20px"
             padding="0px 10px"
            >
             {filterExperiences
              ?.filter(
               (exp) =>
                exp?.type === "recurring" ||
                dayjs(exp?.date).isAfter(dayjs(), "day") ||
                dayjs(exp?.date).isSame(dayjs(), "day")
              )
              ?.map((item, index) => (
               <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom={
                 filterExperiences?.filter(
                  (exp) =>
                   exp?.type === "recurring" ||
                   dayjs(exp?.date).isAfter(dayjs(), "day") ||
                   dayjs(exp?.date).isSame(dayjs(), "day")
                 )?.length -
                  1 !==
                  index && "1px solid #E2E8F0"
                }
                cursor="pointer"
                onClick={() =>
                 navigate(
                  `/partner/experience/${item.id}/${item.timeframesId}/${item.timeSlotId}`
                 )
                }
               >
                <Box
                 display="flex"
                 flexDirection="column"
                 alignItems="flex-start"
                >
                 <Text
                  color={colors.darkBlue}
                  fontWeight="500"
                  lineHeight="20px"
                  marginBottom="10px"
                  fontSize="18px"
                 >
                  {item.title}
                 </Text>
                 <Box
                  display="flex"
                  alignItems="center"
                  gap="10px"
                  flexWrap="wrap"
                 >
                  <Text
                   color={colors.darkBlue}
                   fontWeight="400"
                   lineHeight="15px"
                   fontSize="14px"
                  >
                   {item?.type === "recurring"
                    ? item?.recurring
                    : dayjs(item?.date).format("DD-MM-YYYY")}
                  </Text>{" "}
                  |{" "}
                  <Text
                   color={colors.darkBlue}
                   fontWeight="400"
                   lineHeight="15px"
                   fontSize="14px"
                  >
                   {item?.start} - {item?.end}
                  </Text>
                 </Box>
                </Box>
                <Image src={ArrowRight} alt="arrow-left" />
               </Box>
              ))}
            </Box>
           </Box>
          </>
         ) : (
          <EmptyState
           text={
            "No experiences yet. Click the add button to add new experiences"
           }
          />
         )}
        </TabPanel>
        <TabPanel padding={{ base: "0px", md: "20px 0px" }}>
         {filterExperiences?.filter((exp) => exp?.type === "packaged")?.length >
         0 ? (
          <>
           <TableContainer
            border="1px solid #E2E8F0"
            borderRadius="16px 16px 0px 0px"
            overflowX="initial"
            overflowY="visible"
            display={{ base: "none", md: "block" }}
           >
            <Table variant="simple" ref={tableRef}>
             <Thead>
              <Tr>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
                textTransform="capitalize"
               >
                Experience
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
                textTransform="capitalize"
               >
                Total tix sold
               </Th>
               <Th></Th>
              </Tr>
             </Thead>
             <Tbody>
              {filterExperiences
               ?.filter((exp) => exp?.type === "packaged")
               .map((item, index) => (
                <Tr
                 key={index}
                 cursor="pointer"
                 _hover={{
                  background: "#F1F5F9",
                 }}
                >
                 <Td
                  padding="14px 20px"
                  fontSize={{ base: "14px" }}
                  lineHeight="125%"
                  fontWeight="500"
                  fontFamily="Satoshi"
                  color="#334155"
                  cursor="pointer"
                  onClick={() => goToSingleExpPage(item)}
                 >
                  {item?.title}
                 </Td>
                 <Td
                  padding="14px 20px"
                  fontSize={{ base: "14px" }}
                  lineHeight="125%"
                  fontWeight="500"
                  fontFamily="Satoshi"
                  color="#334155"
                  cursor="pointer"
                  onClick={() => goToSingleExpPage(item)}
                 >
                  {ticketSold({
                   date: item.date,
                   id: item.timeSlotId,
                   start: item.start,
                   end: item.end,
                   amount: item.amount,
                   maxTickets: item.maxTickets,
                   type: item.type,
                   recurring: item.recurring,
                   expId: item.id,
                   admitsHowMany: item.admitsHowMany,
                  }) ?? 0}
                 </Td>
                 <Td
                  // padding="14px 20px"
                  display="flex"
                  alignItems="center"
                  // gap="20px"
                  position="relative"
                 >
                  <Box
                   cursor="pointer"
                   onClick={() => {
                    setSelected(selected === index ? null : index);
                   }}
                   ref={toggle}
                  >
                   <Image src={OptionIcon} />{" "}
                   {selected === index && (
                    <List
                     position="absolute"
                     bottom="10px"
                     right="40px"
                     padding="10px 20px"
                     background="#FFFFFF"
                     boxShadow="0px 4px 24px #D4DEEC"
                     border="12px"
                     cursor="pointer"
                     borderRadius="12px"
                     width="200px"
                    >
                     <ListItem
                      borderBottom="1px solid #E2E8F0"
                      padding="10px 0px"
                      onClick={() =>
                       navigate(`/partner/experience/edit/${item.id}`)
                      }
                     >
                      Edit Experience
                     </ListItem>
                     <ListItem
                      // borderBottom="1px solid #E2E8F0"
                      padding="10px 0px"
                      onClick={() => {
                       setVisible(!visible);
                       setModalType("delete");
                       setSelectedExp({
                        id: item.timeSlotId,
                        start: item.start,
                        end: item.end,
                        amount: item.amount,
                        maxTickets: item.maxTickets,
                        admitsHowMany: item.admitsHowMany,
                       });
                       setSelectedExpId(item.id);
                      }}
                     >
                      Delete experience
                     </ListItem>
                    </List>
                   )}
                  </Box>
                 </Td>
                </Tr>
               ))}
             </Tbody>
            </Table>
           </TableContainer>
           <Box display={{ base: "block", md: "none" }}>
            <Box
             overflowX={"auto"}
             background="#fff"
             width="100%"
             border="1px solid #e2e8f0"
             borderRadius="16px"
             marginTop="20px"
             padding="0px 10px"
            >
             {filterExperiences
              ?.filter(
               (exp) =>
                exp?.type === "recurring" ||
                dayjs(exp?.date).isAfter(dayjs(), "day") ||
                dayjs(exp?.date).isSame(dayjs(), "day")
              )
              ?.map((item, index) => (
               <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom={
                 filterExperiences?.filter(
                  (exp) =>
                   exp?.type === "recurring" ||
                   dayjs(exp?.date).isAfter(dayjs(), "day") ||
                   dayjs(exp?.date).isSame(dayjs(), "day")
                 )?.length -
                  1 !==
                  index && "1px solid #E2E8F0"
                }
                cursor="pointer"
                onClick={() =>
                 navigate(
                  `/partner/experience/${item.id}/${item.timeframesId}/${item.timeSlotId}`
                 )
                }
               >
                <Box
                 display="flex"
                 flexDirection="column"
                 alignItems="flex-start"
                >
                 <Text
                  color={colors.darkBlue}
                  fontWeight="500"
                  lineHeight="20px"
                  marginBottom="10px"
                  fontSize="18px"
                 >
                  {item.title}
                 </Text>
                 <Box
                  display="flex"
                  alignItems="center"
                  gap="10px"
                  flexWrap="wrap"
                 >
                  <Text
                   color={colors.darkBlue}
                   fontWeight="400"
                   lineHeight="15px"
                   fontSize="14px"
                  >
                   {item?.type === "recurring"
                    ? item?.recurring
                    : dayjs(item?.date).format("DD-MM-YYYY")}
                  </Text>{" "}
                  |{" "}
                  <Text
                   color={colors.darkBlue}
                   fontWeight="400"
                   lineHeight="15px"
                   fontSize="14px"
                  >
                   {item?.start} - {item?.end}
                  </Text>
                 </Box>
                </Box>
                <Image src={ArrowRight} alt="arrow-left" />
               </Box>
              ))}
            </Box>
           </Box>
          </>
         ) : (
          <EmptyState
           text={
            "No experiences yet. Click the add button to add new experiences"
           }
          />
         )}
        </TabPanel>
        <TabPanel padding={{ base: "0px", md: "20px 0px" }}>
         {completedExperiences?.length > 0 ? (
          <>
           <TableContainer
            border="1px solid #E2E8F0"
            borderRadius="16px 16px 0px 0px"
            overflowX="initial"
            overflowY="visible"
            display={{ base: "none", md: "block" }}
           >
            <Table variant="simple" ref={tableRef}>
             <Thead>
              <Tr>
               {[
                "Experience",
                "Date",
                "Start Time",
                "End Time",
                "Status",
                "Total Tix Sold",
               ].map((text, i) => (
                <Th key={i} {...tabTHStyles}>
                 {text}
                </Th>
               ))}
               <Th></Th>
              </Tr>
             </Thead>
             <Tbody>
              {completedExperiences.map((item, index) => (
               <Tr
                key={index}
                cursor="pointer"
                _hover={{
                 background: "#F1F5F9",
                }}
               >
                <Td {...TabTdStyles} onClick={() => goToSingleExpPage(item)}>
                 {item?.title}
                </Td>
                <Td
                 padding="14px 20px"
                 fontSize={{ base: "14px" }}
                 lineHeight="125%"
                 fontWeight="500"
                 fontFamily="Satoshi"
                 color="#334155"
                 cursor="pointer"
                 onClick={() => goToSingleExpPage(item)}
                >
                 {item?.type === "oneTime"
                  ? dayjs(item?.date).format("DD-MM-YYYY")
                  : item?.recurring}
                </Td>
                <Td
                 padding="14px 20px"
                 fontSize={{ base: "14px" }}
                 lineHeight="125%"
                 fontWeight="500"
                 fontFamily="Satoshi"
                 color="#334155"
                 cursor="pointer"
                 onClick={() => goToSingleExpPage(item)}
                >
                 {item?.start}
                </Td>
                <Td
                 padding="14px 20px"
                 fontSize={{ base: "14px" }}
                 lineHeight="125%"
                 fontWeight="500"
                 fontFamily="Satoshi"
                 color="#334155"
                 cursor="pointer"
                 onClick={() => goToSingleExpPage(item)}
                >
                 {item?.end}
                </Td>
                <Td
                 padding="14px 20px"
                 fontSize={{ base: "14px" }}
                 lineHeight="125%"
                 fontWeight="500"
                 fontFamily="Satoshi"
                 color="#334155"
                 cursor="pointer"
                 onClick={() => goToSingleExpPage(item)}
                >
                 {item?.type === "oneTime" ? (
                  dayjs(item?.date).isAfter(dayjs(), "day") ? (
                   <span className={[styles.pill, styles.pending].join(" ")}>
                    Pending
                   </span>
                  ) : dayjs(item?.date).isBefore(dayjs(), "day") ? (
                   <span className={[styles.pill, styles.arroved].join(" ")}>
                    Completed
                   </span>
                  ) : dayjs(item?.date).isSame(dayjs(), "day") &&
                    dayjs().isAfter(item?.start, "h:mm a") &&
                    dayjs().isBefore(item?.end, "h:mm a") ? (
                   <span
                    className={[styles.pill, styles.disapproved].join(" ")}
                   >
                    In progress
                   </span>
                  ) : (
                   <span className={[styles.pill, styles.pending].join(" ")}>
                    Pending
                   </span>
                  )
                 ) : (
                  <Text textAlign="center">Recurring</Text>
                 )}
                </Td>

                <Td
                 padding="14px 20px"
                 fontSize={{ base: "14px" }}
                 lineHeight="125%"
                 fontWeight="500"
                 fontFamily="Satoshi"
                 color="#334155"
                 cursor="pointer"
                 onClick={() => goToSingleExpPage(item)}
                >
                 {ticketSold({
                  date: item.date,
                  id: item.timeSlotId,
                  start: item.start,
                  end: item.end,
                  amount: item.amount,
                  maxTickets: item.maxTickets,
                  type: item.type,
                  recurring: item.recurring,
                  expId: item.id,
                  admitsHowMany: item.admitsHowMany,
                 }) ?? 0}
                </Td>
                <Td
                 // padding="14px 20px"
                 display="flex"
                 alignItems="center"
                 // gap="20px"
                 position="relative"
                >
                 <Box
                  cursor="pointer"
                  onClick={() => {
                   setSelected(selected === index ? null : index);
                  }}
                  ref={toggle}
                 >
                  <Image src={OptionIcon} />{" "}
                  {selected === index && (
                   <List
                    position="absolute"
                    bottom="10px"
                    right="40px"
                    padding="10px 20px"
                    background="#FFFFFF"
                    boxShadow="0px 4px 24px #D4DEEC"
                    border="12px"
                    cursor="pointer"
                    borderRadius="12px"
                    width="200px"
                   >
                    <ListItem
                     borderBottom="1px solid #E2E8F0"
                     padding="10px 0px"
                     onClick={() =>
                      navigate(`/partner/experience/edit/${item.id}`)
                     }
                    >
                     Edit Experience
                    </ListItem>
                    <ListItem
                     // borderBottom="1px solid #E2E8F0"
                     padding="10px 0px"
                     onClick={() => {
                      setVisible(!visible);
                      setModalType("delete");
                      setSelectedExp({
                       id: item.timeSlotId,
                       start: item.start,
                       end: item.end,
                       amount: item.amount,
                       maxTickets: item.maxTickets,
                       admitsHowMany: item.admitsHowMany,
                      });
                      setSelectedExpId(item.id);
                     }}
                    >
                     Delete experience
                    </ListItem>
                   </List>
                  )}
                 </Box>
                </Td>
               </Tr>
              ))}
             </Tbody>
            </Table>
           </TableContainer>
           <Box display={{ base: "block", md: "none" }}>
            <Box
             overflowX={"auto"}
             background="#fff"
             width="100%"
             border="1px solid #e2e8f0"
             borderRadius="16px"
             marginTop="20px"
             padding="0px 10px"
            >
             {filterExperiences
              ?.filter(
               (exp) =>
                exp?.type !== "recurring" &&
                dayjs(exp?.date).isBefore(dayjs(), "day")
              )
              .map((item, index) => (
               <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom={
                 filterExperiences?.filter(
                  (exp) =>
                   exp?.type !== "recurring" &&
                   dayjs(exp?.date).isBefore(dayjs(), "day")
                 )?.length -
                  1 !==
                  index && "1px solid #E2E8F0"
                }
                cursor="pointer"
                onClick={() =>
                 navigate(
                  `/partner/experience/${item.id}/${item.timeframesId}/${item.timeSlotId}`
                 )
                }
               >
                <Box
                 display="flex"
                 flexDirection="column"
                 alignItems="flex-start"
                >
                 <Text
                  color={colors.darkBlue}
                  fontWeight="500"
                  lineHeight="20px"
                  marginBottom="10px"
                  fontSize="18px"
                 >
                  {item.title}
                 </Text>
                 <Box
                  display="flex"
                  alignItems="center"
                  gap="10px"
                  flexWrap="wrap"
                 >
                  <Text
                   color={colors.darkBlue}
                   fontWeight="400"
                   lineHeight="15px"
                   fontSize="14px"
                  >
                   {item?.type === "oneTime"
                    ? dayjs(item?.date).format("DD-MM-YYYY")
                    : item?.recurring}
                  </Text>{" "}
                  |{" "}
                  <Text
                   color={colors.darkBlue}
                   fontWeight="400"
                   lineHeight="15px"
                   fontSize="14px"
                  >
                   {item?.start} - {item?.end}
                  </Text>
                 </Box>
                </Box>
                <Image src={ArrowRight} alt="arrow-left" />
               </Box>
              ))}
            </Box>
           </Box>
          </>
         ) : (
          <EmptyState
           text={"There are no completed experiences at the moment."}
          />
         )}
        </TabPanel>
       </TabPanels>
      </Tabs>
     </Box>
    )}
   </Box>
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
     //   style={{ marginTop: modalType === "add" && "300px" }}
    >
     {modalType === "delete" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Experience
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you want to delete this experience?
        </Text>
        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setModalType("");
          }}
         >
          Close
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default PartnerExperiences;
