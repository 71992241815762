import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Text, Button, Image } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref, set, child, get, update } from "firebase/database";
import dayjs from "dayjs";
import { AuthContext } from "../../../../context/AuthContext";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import {
  LoadingIndicator,
  Modal,
  Icon as CloseIconWrapper,
} from "../../../../components";
import { Card, Container } from "../../styles";
import Warning from "../../../../assets/images/warning-icon.svg";
import { colors } from "../../../../assets/styles/config";
import toast from "react-hot-toast";

const Experience = () => {
  const { user } = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const expId = params?.expId;
  const timeframesId = params?.timeframesId;
  const timeSlotId = params?.timeSlotId;
  const [allExperiences, setAllExperiences] = useState([]);
  const [filterExperiences, setFilterExperiences] = useState([]);
  const [experiences, setExperience] = useState([]);
  const [recentExps, setRecentExps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const containerRef = useRef(null);

  const fetchBoughtExp = async () => {
    setLoading(true);
    const dbRef = ref(getDatabase());
    //get user experience
    const db = await get(child(dbRef, `transactions/`))
      .then((snapshot) => {
        setLoading(false);
        if (snapshot.exists()) {
          const data = Object.values(snapshot.val());
          setRecentExps(
            Object.keys(data).map((key) => {
              return {
                ...data[key],
              };
            })
          );
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    return db;
  };

  useEffect(() => {
    fetchBoughtExp();
  }, [user]);

  useEffect(() => {
    if (expId) {
      const fetchExperience = async () => {
        const dbRef = ref(getDatabase());
        //get user experience
        const db = await get(child(dbRef, `cityExperience/experiences/`)).then(
          (snapshot) => {
            if (snapshot.exists()) {
              setAllExperiences(snapshot.val());
              setExperience(snapshot.val()[expId]);
            }
          }
        );

        return db;
      };
      fetchExperience();
    }
  }, [expId]);

  const ticketSold = () => {
    let tickets, chosenTimeFrame;
    if (experiences?.type === "oneTime") {
      chosenTimeFrame = {
        date: dayjs(
          (JSON.parse(experiences.timeframes) || [])[timeframesId]?.date
        ).format("YYYY-MM-DD"),
        recurring: "",
        timeSlot: {
          amount:
            experiences?.timeframes &&
            JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[
              timeSlotId
            ]?.amount,
          end:
            experiences?.timeframes &&
            JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[
              timeSlotId
            ]?.end,
          id:
            experiences?.timeframes &&
            JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[
              timeSlotId
            ]?.id,
          maxTickets:
            experiences?.timeframes &&
            JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[
              timeSlotId
            ]?.maxTickets,
          start:
            experiences?.timeframes &&
            JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[
              timeSlotId
            ]?.start,
        },
      };

      if (recentExps?.length) {
        tickets = recentExps
          ?.filter((item) => Number(item?.experienceId) === Number(expId))
          ?.filter(
            (item) =>
              JSON.stringify(item?.chosenTimeFrame) ===
              JSON.stringify(chosenTimeFrame)
          )
          .reduce((a, b) => {
            return a + b?.ticketQuantity;
          }, 0);
      }
    }
    return tickets;
    // console.log("tickets", tickets);
  };

  const handleDelete = async () => {
    toast.loading("loading");
    let deleteExp = experiences;

    const timeframes = JSON.parse(deleteExp?.timeframes) || [];
    let updates = {};

    let selectedExp = {
      id: Number(timeSlotId),
      start:
        experiences?.timeframes &&
        JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[timeSlotId]
          ?.start,
      end:
        experiences?.timeframes &&
        JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[timeSlotId]
          ?.end,
      amount:
        experiences?.timeframes &&
        JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[timeSlotId]
          ?.amount,

      maxTickets:
        experiences?.timeframes &&
        JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[timeSlotId]
          ?.maxTickets,
    };

    for (let j = 0; j < timeframes?.length; j++) {
      const timeSlot = timeframes[j]?.timeSlot || [];

      const timeSlotIndex = timeSlot?.findIndex(
        (slot) => JSON.stringify(slot) === JSON.stringify(selectedExp)
      );

      if (timeSlotIndex !== -1) {
        timeSlot.splice(timeSlotIndex, 1);
        deleteExp.timeframes = JSON.stringify(timeframes);
        if (Array.isArray(timeSlot) && timeSlot.length === 0) {
          timeframes.splice(j, 1);
          if (timeframes?.length > 0) {
            deleteExp.timeframes = JSON.stringify(timeframes);
            updates["cityExperience/experiences/" + Number(expId)] = deleteExp;
          } else {
            const filterExp = Object.keys(allExperiences).reduce(
              (acc, val) =>
                allExperiences[val]["id"] !== Number(expId)
                  ? {
                      ...acc,
                      [val]: allExperiences[val],
                    }
                  : acc,
              {}
            );

            updates["cityExperience/experiences"] = filterExp;
          }
        } else {
          updates["cityExperience/experiences/" + Number(expId)] = deleteExp;
        }
      }
    }

    const db = getDatabase();
    await update(ref(db), updates)
      .then(() => {
        toast.dismiss();
        toast.success("Successfully deleted experience");
        navigate("/partner/experience");
        setVisible(!visible);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          toast.dismiss();
        }, 3000);
      });
  };

  return (
    <Box
      padding="0px 10px"
      minH="calc(100vh -  110px)"
      height="100%"
      background="#F5F8FA"
      position="relative"
    >
      <Box
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Box display="flex" gap="10px">
          <FaPencilAlt
            color="#632EFF"
            fontSize="20px"
            cursor="pointer"
            onClick={() => navigate(`/partner/experience/edit/${expId}`)}
          />
          <FaTrash
            color="red"
            size="20px"
            cursor="pointer"
            onClick={() => setVisible(!visible)}
          />
        </Box>
      </Box>
      <Box
        position="relative"
        background="#fff"
        width="100%"
        border="1px solid #e2e8f0"
        borderRadius="16px"
        padding="0px 10px"
        marginTop="10px"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="15px 10px"
          borderBottom="1px solid #E2E8F0"
        >
          <Text>Experience Date</Text>
          <Text fontSize="14px">
            {experiences?.type === "oneTime"
              ? dayjs(
                  (JSON.parse(experiences.timeframes) || [])[timeframesId]?.date
                ).format("DD-MM-YYYY")
              : experiences?.timeframes &&
                JSON.parse(experiences?.timeframes)[timeframesId]?.recurring}
          </Text>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="15px 10px"
          borderBottom="1px solid #E2E8F0"
        >
          <Text>Amount</Text>
          <Text fontSize="14px">
            {experiences?.timeframes &&
              JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[
                timeSlotId
              ]?.amount}
          </Text>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="15px 10px"
          borderBottom="1px solid #E2E8F0"
        >
          <Text>Ticket Sold</Text>
          <Text fontSize="14px">{ticketSold() ?? 0}</Text>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="15px 10px"
          borderBottom="1px solid #E2E8F0"
        >
          <Text>Event type</Text>
          <Text>{experiences?.type}</Text>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="15px 10px"
          borderBottom="1px solid #E2E8F0"
        >
          <Text>Status</Text>
          <Text fontSize="14px">
            {" "}
            {experiences?.type === "oneTime" ? (
              dayjs(
                (JSON.parse(experiences.timeframes) || [])[timeframesId]?.date
              ).isAfter(dayjs(), "day") ? (
                <span className={[styles.pill, styles.pending].join(" ")}>
                  Pending
                </span>
              ) : dayjs(
                  (JSON.parse(experiences.timeframes) || [])[timeframesId]?.date
                ).isBefore(dayjs(), "day") ? (
                <span className={[styles.pill, styles.arroved].join(" ")}>
                  Completed
                </span>
              ) : dayjs(
                  (JSON.parse(experiences.timeframes) || [])[timeframesId]?.date
                ).isSame(dayjs(), "day") &&
                dayjs().isAfter(
                  experiences?.timeframes &&
                    JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[
                      timeSlotId
                    ]?.start,
                  "h:mm a"
                ) &&
                dayjs().isBefore(
                  experiences?.timeframes &&
                    JSON.parse(experiences.timeframes)[timeframesId]?.timeSlot[
                      timeSlotId
                    ]?.end,
                  "h:mm a"
                ) ? (
                <span className={[styles.pill, styles.disapproved].join(" ")}>
                  In progress
                </span>
              ) : (
                <span className={[styles.pill, styles.pending].join(" ")}>
                  Pending
                </span>
              )
            ) : (
              <Text textAlign="center">Recurring</Text>
            )}
          </Text>
        </Box>
      </Box>
      <Box
        // position="absolute"
        // bottom="40px"
        // width="100%"
        // left="0"
        // right="0"
        marginTop="20px"
        display={{ base: "block", md: "none" }}
        padding="0px 20px"
      >
        {experiences?.type === "oneTime" ? (
          <Button
            onClick={() => navigate(`/partner/experience/bought/${expId}`)}
            color="#ffffff"
            bg="#632EFF"
            width="100%"
            borderRadius="54px"
            fontWeight={500}
            fontSize={18}
            marginRight="20px"
            p="25px 30px"
            type="submit"
          >
            View Navigators
          </Button>
        ) : (
          <Button
            onClick={() =>
              navigate(
                `/partner/experience/recurring/${expId}/${timeframesId}/${timeSlotId}`
              )
            }
            color="#ffffff"
            bg="#632EFF"
            width="100%"
            borderRadius="54px"
            fontWeight={500}
            fontSize={18}
            marginRight="20px"
            p="25px 30px"
            type="submit"
          >
            View event dates
          </Button>
        )}
      </Box>
      <Modal
        {...{
          visible,
          setVisible,
          childrenContainerRef: containerRef,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "auto",
        }}
        overlayLayer
        overlayLayerStyle={{
          backgroundColor: colors.black,
          opacity: 0.7,
        }}
      >
        <Container
          ref={containerRef}
          animate={{ scale: 1 }}
          initial={{ scale: 0.9 }}
          //   style={{ marginTop: modalType === "add" && "300px" }}
        >
          <Card
            animate={{ scale: 1 }}
            initial={{ scale: 0.9 }}
            style={{ width: "358px", height: "100%", padding: "2rem" }}
          >
            <Box
              display="flex"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              //  mt="40px"
              width="100%"
            >
              <Image src={Warning} />
              <Text
                fontFamily="Creato Display, Roboto, sans-serif;"
                color="#0F0931"
                fontSize={{ base: "14px", md: "24px" }}
                lineHeight="134%"
                fontWeight="700"
                marginTop="30px"
                textAlign="center"
              >
                Delete Experience
              </Text>
              <Text
                fontFamily="Satoshi"
                color="#878498"
                fontWeight="500"
                marginTop="20px"
                textAlign="center"
              >
                Are you sure you want to delete this experience?
              </Text>
              <Box
                mt="30px"
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap="20px"
                width="100%"
              >
                <Button
                  color="#ffffff"
                  bg={colors.primaryPurple}
                  borderRadius="54px"
                  fontWeight={500}
                  fontSize={18}
                  p="25px 20px"
                  width="100%"
                  onClick={handleDelete}
                >
                  Yes, Delete
                </Button>
                <Button
                  border="1px solid #252046"
                  bg="transparent"
                  borderRadius="54px"
                  fontWeight={500}
                  fontSize={18}
                  p="25px 20px"
                  width="100%"
                  type="submit"
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Card>
        </Container>
      </Modal>
    </Box>
  );
};

export default Experience;
