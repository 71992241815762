import React from "react";
import ReactDom from "react-dom";
import PropType from "prop-types";

function Modal({
  children,
  visible,
  style,
  overlayLayer,
  overlayLayerStyle,
  setVisible,
  childrenContainerRef,
  onClose,
  stopPropagation = false,
  handleCancel,
  openDate = false,
}) {
  const mainRef = React.useRef();
  const openedRef = React.useRef(false);

  React.useEffect(() => {
    const handleClick = (evt) => {
      if (childrenContainerRef?.current)
        if (!childrenContainerRef?.current?.contains(evt.target)) {
          onClose();
          handleCancel !== null
            ? handleCancel()
            : openDate
            ? setVisible(true)
            : setVisible(false);
        }
    };
    const handleKeyDown = (evt) => {
      if (evt?.key === "Escape") {
        setVisible(false);
      }
    };

    function removeListeners() {
      window.removeEventListener("click", handleClick, true);
      window.removeEventListener("keydown", handleKeyDown, true);
    }

    if (visible) {
      openedRef.current = true;
      window.addEventListener("click", handleClick, true);
      window.addEventListener("keydown", handleKeyDown, true);
    } else {
      removeListeners();
    }
    return () => {
      removeListeners();
    };
  }, [visible, setVisible, childrenContainerRef, handleCancel, openDate]);

  return ReactDom.createPortal(
    visible ? (
      <>
        <div
          ref={mainRef}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            overflow: "hidden ",
            outline: "none",
            ...style,
          }}
          onClick={(evt) => {
            stopPropagation && evt?.stopPropagation();
          }}
        >
          {children}
        </div>
        {overlayLayer ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden",
              zIndex: 500,
              ...overlayLayerStyle,
            }}
          />
        ) : null}
      </>
    ) : null,
    document.getElementById("portal")
  );
}

Modal.defaultProps = {
  children: null,
  visible: false,
  style: {},
  overlayLayer: false,
  overlayLayerStyle: {},
  childrenContainerRef: { current: undefined },
  setVisible: () => null,
  onClose: () => null,
  stopPropagation: false,
  closeDelay: 0,
  handleCancel: null,
};

Modal.propTypes = {
  children: PropType.oneOfType([
    PropType.func,
    PropType.node,
    PropType.arrayOf(PropType.node),
  ]),
  visible: PropType.bool,
  overlayLayer: PropType.bool,
  style: PropType.shape({}),
  overlayLayerStyle: PropType.shape({}),
  childrenContainerRef: PropType.shape({}),
  setVisible: PropType.func,
  onClose: PropType.func,
  stopPropagation: PropType.bool,
  closeDelay: PropType.number,
  handleCancel: PropType.func,
  openDate: PropType.bool,
};
export default React.memo(Modal);
