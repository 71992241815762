import styled, { keyframes } from "styled-components";
import { colors, fontWeight } from "../../../assets/styles/config";

const bookBtnAnimation = keyframes`
 0% {   background-color: #59c0fc; }
 30% {   background-color: ${colors.blue}; }
 100% {   background-color: #0597eb; }
 70% {   background-color: ##0477b9; }
`;

export const Container = styled.div`
  padding: 2rem;
  padding-bottom: 0;

  @media screen and (min-width: 1080px) {
    padding: 3rem 4rem;
    padding-bottom: 0;
  }
`;

export const Title = styled.h1`
  font-size: 25px;
  font-weight: ${fontWeight.semiBold};
  color: ${colors.darkBlue};
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 33px;
    line-height: 45px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 50px;
    line-height: 75px;
  }
`;

export const Heading = styled.h1`
  display: flex;
  // flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    font-size: 33px;
    line-height: 45px;
    gap: 1rem;
    // flex-direction: row;
    align-items: center;
    max-width: 90%;
  }

  ${(props) => props.css || ""}
`;

export const FaintBrownText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.faintBrown};

  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media screen and (min-width: 1080px) {
    font-size: 24px;
    line-height: 36px;
  }
  ${(props) => props.css || ""}
`;

export const CityChoiceText = styled.p`
  font-size: 15px;
  cursor: pointer;
  min-width: max-content;
  transition: 0.3s ease;

  &:hover {
    scale: 0.96;
    opacity: 0.9;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const SlideWrapper = styled.div`
  padding-bottom: 2rem;

  @media screen and (min-width: 1080px) {
    padding-bottom: 3rem;
  }
`;

export const SplideSlideWrapper = styled.div`
  border-radius: 13px;
  background: ${colors.primaryWhite};
  border: 0.5px solid #bdbdbd;
  transition: ease-in 0.2s;

  &:hover {
    box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    // rgba(99, 99, 99, 0.2) 0px 2px 8px 0px

    img {
      scale: 0.9;
    }
  }

  @media screen and (min-width: 768px) {
    border-radius: 23px;
  }
`;

export const SlideHack = styled.div`
  padding: 1rem;
`;

export const SlideImg = styled.img`
  width: 100%;
  object-fit: cover;
  height: 156px;
  border-radius: 13px;
  transition: ease-in 0.2s;

  @media screen and (min-width: 768px) {
    border-radius: 23px;
    height: 176px;
  }

  @media screen and (min-width: 1080px) {
    height: 206px;
  }
`;

export const ExperienceInfo = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  //   gap: 0.25rem;
`;

export const ExperienceTitle = styled.p`
  font-size: 15px;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    //line-height: 33px;
  }

  // @media screen and (min-width: 1080px) {
  //   font-size: 22px;
  //   line-height: 33px;
  // }
`;

export const ExperiencePartner = styled.p`
  display: flex;
  align-items: center;
  //   justify-content: center;
  gap: 5px;
  font-size: 14px;
  font-weight: ${fontWeight.light};
  color: ${colors.darkBlue};
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 15px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ExperienceAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BookNow = styled.button`
  color: ${colors.primaryWhite};
  border-radius: 18px;
  background-color: ${colors.blue};
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  padding: 0.3rem 0.75rem;
  transition: 0.5s ease;

  &:hover {
    transform: scaleY(0.95);
    background-color: ${colors.blueHover};
    opacity: 0.9;
    padding: 0.3rem 1.5rem;
    animation-name: ${bookBtnAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 18px;
    border-radius: 28px;
    line-height: 27px;
  }
`;

export const RatingText = styled.p`
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => (!props?.faded ? "#BDBDBD" : colors.blue)};
  gap: 5px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ExperiencePrice = styled.p`
  position: absolute;
  background: ${colors.primaryWhite};
  padding: 3px 16px;
  color: ${colors.blue};
  border-radius: 13px;
  right: 20px;
  top: 18px;
  display: flex;
  align-items: center;
  transition: ease-in 0.2s;
  z-index: 12;

  @media screen and (min-width: 768px) {
    border-radius: 23px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
