import { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';

const CityContext = createContext();
CityContext.displayName = 'CityContext';
const initialState = {
  currentCity: '',
  coverImage: '',
  collections: [],
  experience: [],
  usefulInfo: {},
  blog: [],
  orderHistory: [],
};

const CityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CityContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CityContext.Provider>
  );
};

export const useCityContext = () => {
  return useContext(CityContext);
};

export const getTabTitle = (title) => {
  switch (title) {
    case 'health&Safety':
      return 'Health & Safety';
      break;
    case 'moneyHandling':
      return 'Money Handling';
      break;
    case 'usefulApps':
      return 'Useful Apps';

    case 'livingCost':
      return 'Cost';

    default:
      return title;
      break;
  }
};

export { CityContext, CityProvider };
