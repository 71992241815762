import React, { useEffect, useMemo, useRef, useState } from "react";
import { db, storage } from "../../../../firebase";
import { onValue, ref } from "firebase/database";
import {
 ref as sRef,
 uploadBytesResumable,
 getDownloadURL,
} from "firebase/storage";
import {
 Button,
 EmptyState,
 Icon,
 Loader,
 Modal,
 Toast,
} from "../../../../components";
import {
 Container,
 SearchInput,
 SearchLabel,
 CategoryHeader,
 CategoriesTable,
 JustifyBetween,
 ActionsWrapper,
 EditIcon,
 DeleteIcon,
 SwitchIcon,
 Card,
 CardHeader,
 CardInBetween,
 addInputStyle,
 addInputFlexStyle,
 addInputTextStyle,
 searchInputStyle,
 docNameStyles,
 docFormLabelStyles,
 docFormTextStyles,
 docFormTextStyles2,
} from "./styles";
import {
 Box,
 CloseButton,
 Flex,
 FormLabel,
 IconButton,
 Img,
 Input,
 InputGroup,
 InputLeftElement,
 Switch,
 Text,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { colors } from "../../../../assets/styles/config";
import { set } from "firebase/database";
import { AnimatePresence } from "framer-motion";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { AddIcon } from "@chakra-ui/icons";
import TrashIcon from "../../../../assets/images/svg/trash-2-icon.svg";
import CloseIconOutline from "../../../../assets/images/svg/close-outline-icon.svg";
import UnnecessaryWarningImage from "../../../../assets/images/warning-icon.svg";

// CONSTANTS
const ACTIONS = {
 ADD_DOCUMENT: "ADD DOCUMENT",
 EDIT_DOCUMENT: "EDIT DOCUMENT",
 DELETE_DOCUMENT: "DELETE DOCUMENT?",
};
const DEFAULT_STATE = { status: false, action: null };
const TOAST_STATE = {
 state: false,
 text: "",
 bgColor: "lightgreen",
 textColor: colors.primaryWhite,
};
const legalDB = ref(db, "cityExperience/legal");

const Legal = () => {
 const [filteredLabelList, setFilteredLabelList] = useState([]);
 const [labelList, setLabelList] = useState([]);
 const [toast, setToast] = React.useState(TOAST_STATE);
 const [state, setState] = useState(DEFAULT_STATE);
 const [loading, setLoading] = useState(false);
 const [isUploading, setIsUploading] = useState(false);
 const [visible, setVisible] = useState(false);
 const [chosenDoc, setChosenDoc] = useState("");
 const [value, setValue] = React.useState("");
 const [doc, setDoc] = React.useState(null);
 const containerRef = useRef(null);

 //   FUNCTIONS
 const reset = () => {
  setVisible(false);
  setState(DEFAULT_STATE);
  setChosenDoc("");
  setValue("");
  setIsUploading(false);
  setDoc(null);
 };

 const handleChange = (event) => setValue(event.target.value);

 const handleDocumentChange = (event) => {
  setDoc(event.target.files[0]);
 };

 const handleUpload = async (value, file) => {
  setIsUploading(true);
  const storageRef = sRef(storage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
   "state_changed",
   (snapshot) => {},
   (err) => {},
   async () => {
    // download url
    await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
     const newLabelList = [...labelList, { name: value, fileUrl: url }];
     setLabelList(newLabelList);
     reset();
     setToast({
      ...toast,
      state: true,
      text: "Item Added Successfully",
      bgColor: "lightgreen",
      textColor: colors.primaryWhite,
     });
     updateFirebaseDb(newLabelList);
    });
   }
  );
 };

 const handleSearchInput = (searchValue) => {
  const hasSearchValue = labelList.filter((item) =>
   item.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  setFilteredLabelList(hasSearchValue);
 };

 const setActionType = (type, doc) => {
  setState({ ...state, action: type });
  setVisible(true);
  setChosenDoc(doc);
 };

 const deleteDocument = (item) => {
  const newList = labelList.filter(
   (doc) => doc?.name?.toLowerCase() !== item?.name?.toLowerCase()
  );

  reset();
  setLabelList(newList);
  setToast({
   ...toast,
   state: true,
   text: "Item Deleted Successfully",
   bgColor: "lightgreen",
   textColor: colors.primaryWhite,
  });
  updateFirebaseDb(newList);
 };

 const createDocument = (value) => {
  const itemExists = labelList.find(
   (document) => document?.name?.toLowerCase() === value.toLowerCase()
  );
  if (!value || !doc) {
   setToast({
    ...toast,
    state: true,
    text: "Please fill all fields",
    bgColor: colors.pastelRed,
    textColor: colors.primaryWhite,
   });
   return;
  }
  if (itemExists) {
   setToast({
    ...toast,
    state: true,
    text: "Document already exists",
    bgColor: colors.pastelRed,
    textColor: colors.primaryWhite,
   });
   return;
  }
  const newItem =
   value.slice(0, 1).toUpperCase() + value.slice(1, value.length).toLowerCase();
  handleUpload(newItem, doc);
 };

 const updateFirebaseDb = (newList) => {
  set(legalDB, newList);
 };

 //   EFFECTS
 useEffect(() => {
  setLoading(true);
  const unsubscribe = onValue(legalDB, (snapshot) => {
   const documents = snapshot.val();

   setLabelList(documents);
   if (documents?.length > 0) {
    setLoading(false);
   } else {
    setTimeout(() => {
     setLoading(false);
    }, 2000);
   }
  });
  return () => {
   unsubscribe();
  };
 }, []);

 useEffect(() => {
  setFilteredLabelList(labelList);
 }, [labelList]);

 //   JSX
 const renderSearchAndAddDocumentArea = useMemo(
  () => (
   <>
    <Box display="flex" justifyContent="space-between" marginBottom={"54px"}>
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => handleSearchInput(e.target.value)}
      />
     </InputGroup>

     <Button
      animateOnTap
      animateOnHover
      buttonColor="#252046"
      css={css`
       width: fit-content;
       padding: 0.5rem 2rem;
       font-weight: 500;
       font-family: "Creato Display";
       display: flex;
       gap: 15px;

       @media screen and (max-width: 768px) {
        border-radius: 100px;
       }
      `}
      onClick={() => setActionType(ACTIONS.ADD_DOCUMENT, "")}
     >
      <AddIcon />
      <Text display={{ base: "none", md: "inline" }}>Add Document</Text>
     </Button>
    </Box>
   </>
  ),
  [labelList, filteredLabelList, handleSearchInput]
 );

 const renderDocuments = useMemo(
  () => (
   <CategoriesTable>
    {filteredLabelList?.length > 0 ? (
     filteredLabelList?.map((document) => (
      <JustifyBetween key={document}>
       <p>{document?.name}</p>
       <ActionsWrapper>
        {/* <SwitchIcon>
                  <Switch size={{ base: 'sm', lg: 'md' }} isChecked />
                </SwitchIcon> */}
        <DeleteIcon
         whileTap={{ scale: 0.7 }}
         whileHover={{ scale: 1.09 }}
         onClick={() => {
          setActionType(ACTIONS.DELETE_DOCUMENT, document);
         }}
        >
         <Img src={TrashIcon} />
        </DeleteIcon>
       </ActionsWrapper>{" "}
      </JustifyBetween>
     ))
    ) : (
     <EmptyState
      text={
       "There are no documents at the moment. Click the add button to add a document"
      }
     />
    )}
   </CategoriesTable>
  ),
  [loading, filteredLabelList]
 );

 const renderAddInput = useMemo(
  () => (
   <Flex {...addInputFlexStyle}>
    <FormLabel htmlFor="document name" {...addInputTextStyle}>
     Document name
    </FormLabel>
    <Input
     {...addInputStyle}
     value={value}
     onChange={handleChange}
     id="document name"
    />
    <Input
     type="file"
     accept=".pdf"
     onChange={handleDocumentChange}
     id="document"
     style={{ display: "none" }}
    />
    <FormLabel {...docFormLabelStyles}>
     <Flex flexDirection={"column"} gap="3px" marginBottom="16px">
      <Text {...docFormTextStyles}>{"Upload Document"}</Text>
      <Text {...docFormTextStyles2}>{".PDF, .Doc files are allowed"}</Text>
      <Text {...docFormTextStyles2}>{"Maximum size of 10mb"}</Text>
     </Flex>
     <Box {...docNameStyles}>
      {doc?.name || (
       <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path
         d="M22.2 27.6657C22.1867 27.6657 22.16 27.6657 22.1467 27.6657C21.6 27.6657 21.1467 27.2124 21.16 26.6524C21.16 26.1057 21.6133 25.6657 22.16 25.6657C22.1733 25.6657 22.2 25.6657 22.2133 25.6657C23.7333 25.6657 25.1867 25.1057 26.3067 24.0657C28.3733 22.2524 28.52 19.6657 27.96 17.839C27.4 16.0124 25.8533 13.9457 23.1467 13.599C22.7067 13.5457 22.36 13.2124 22.28 12.7724C21.7467 9.58571 20.0267 7.38571 17.44 6.58571C14.7467 5.75904 11.64 6.57238 9.69335 8.61238C7.80001 10.599 7.36001 13.359 8.45335 16.4124C8.64001 16.9324 8.37334 17.5057 7.85334 17.6924C7.33335 17.879 6.76001 17.6124 6.57335 17.0924C5.22668 13.3457 5.84001 9.75904 8.25335 7.23904C10.72 4.66571 14.64 3.63904 18.04 4.69238C21.1467 5.65238 23.3467 8.21238 24.1333 11.759C26.8533 12.3724 29.0267 14.4257 29.8933 17.2657C30.84 20.359 29.9733 23.5324 27.6667 25.559C26.1467 26.919 24.2133 27.6657 22.2 27.6657Z"
         fill="#94A3B8"
        />
        <path
         d="M7.42668 27.6667C7.40001 27.6667 7.37335 27.6667 7.36001 27.6667C3.52001 27.4 1.77335 24.3867 1.77335 21.7067C1.77335 19.0267 3.52001 16.0267 7.36001 15.7467C8.34668 15.7333 9.32001 15.96 10.1733 16.3866C10.6667 16.64 10.8533 17.24 10.6133 17.7333C10.36 18.2266 9.76001 18.4133 9.26668 18.1733C8.70668 17.88 8.06668 17.76 7.45335 17.7467C4.93335 17.9333 3.78668 19.8667 3.78668 21.7067C3.78668 23.5467 4.93335 25.48 7.52001 25.6666C8.06668 25.7066 8.48001 26.1866 8.44001 26.7333C8.38668 27.2666 7.94668 27.6667 7.42668 27.6667Z"
         fill="#94A3B8"
        />
        <path
         d="M21.0933 14.187C20.7333 14.187 20.3733 13.987 20.2 13.627C19.9467 13.1336 20.1467 12.5336 20.6533 12.2803C21.4667 11.867 22.3867 11.6403 23.2933 11.627C23.8133 11.6536 24.3067 12.0536 24.3067 12.6136C24.32 13.1603 23.88 13.627 23.32 13.627C22.7067 13.6403 22.0933 13.787 21.5333 14.067C21.4 14.1603 21.24 14.187 21.0933 14.187Z"
         fill="#94A3B8"
        />
        <path
         d="M17.2933 27.666H11.96C11.4133 27.666 10.96 27.2127 10.96 26.666C10.96 26.1193 11.4133 25.666 11.96 25.666H17.2933C17.84 25.666 18.2933 26.1193 18.2933 26.666C18.2933 27.2127 17.8533 27.666 17.2933 27.666Z"
         fill="#94A3B8"
        />
        <path
         d="M14.6267 30.3333C14.08 30.3333 13.6267 29.88 13.6267 29.3333V24C13.6267 23.4533 14.08 23 14.6267 23C15.1733 23 15.6267 23.4533 15.6267 24V29.3333C15.6267 29.88 15.1867 30.3333 14.6267 30.3333Z"
         fill="#94A3B8"
        />
       </svg>
      )}
     </Box>
    </FormLabel>
   </Flex>
  ),
  [value, setValue, handleChange, doc, setDoc, handleDocumentChange]
 );

 const renderModal = useMemo(
  () => (
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.5,
    }}
   >
    <AnimatePresence>
     <Card
      initial={{
       scale: 0,
       translateX: 350,
       translateY: 350,
      }}
      animate={{ scale: 1, translateX: 0, translateY: 0 }}
      exit={{ opacity: 0, scale: 0, translateX: 350, translateY: 350 }}
      transition={{
       type: "spring",
       stiffness: 160,
       damping: 20,
      }}
      ref={containerRef}
      state={state.action}
     >
      {state.action === ACTIONS.DELETE_DOCUMENT ? (
       <>
        <CardHeader
         css={css`
          align-items: center;
          flex-direction: column;
          gap: 31px;
         `}
        >
         <img src={UnnecessaryWarningImage}></img>
         Delete Document?
        </CardHeader>
        <CardInBetween
         css={css`
          padding-block: 11px 31px;
          max-width: 237px;
          margin: auto;
         `}
        >
         Are you sure you want to delete this document?
        </CardInBetween>
        {/* THE BUTTONS */}
        <Flex
         justifyContent={"center"}
         gap="9px"
         alignItems="center"
         paddingBottom="32px"
         flexDirection="column-reverse"
        >
         <Button
          css={css`
           background: white;
           color: #252046;
           font-family: "Creato Display";
           font-style: normal;
           font-weight: 700;
           height: auto;
           border: 1px solid #252046;
          `}
          onClick={reset}
         >
          Cancel
         </Button>
         <Button
          animateOnTap
          animateOnHover
          css={css`
           background: ${colors.primaryPurple};
           font-weight: 500;
           transition: 250ms;
           margin-bottom: 0px;
           height: auto;

           &:hover {
            opacity: 0.8;
           }
          `}
          isLoading={isUploading}
          onClick={() => {
           state.action === ACTIONS.DELETE_DOCUMENT
            ? deleteDocument(chosenDoc)
            : createDocument(value);
          }}
         >
          Yes, Delete
         </Button>
        </Flex>
       </>
      ) : (
       <>
        {/* THE HEADER */}
        <CardHeader>
         {state?.action?.charAt(0) + state?.action?.substring(1)?.toLowerCase()}{" "}
         <img
          src={CloseIconOutline}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={reset}
         />
        </CardHeader>
        {/* THE IN-BETWEEN */}

        {state.action === ACTIONS.DELETE_DOCUMENT ? (
         <CardInBetween>
          {" "}
          {"Are you sure you would like to delete this document?"}{" "}
         </CardInBetween>
        ) : (
         renderAddInput
        )}

        {/* THE BUTTONS */}
        <Flex
         justifyContent={"center"}
         gap="20px"
         alignItems="center"
         paddingBottom="20px"
        >
         {/* <Button
                css={css`
                  background: #eef3f7;
                  width: fit-content;
                  height: auto;
                  color: ${colors.faintAsh};
                `}
                onClick={reset}
              >
                {state.action === ACTIONS.DELETE_DOCUMENT
                  ? "Cancel"
                  : "Discard"}
              </Button> */}
         <Button
          animateOnTap
          animateOnHover
          css={css`
           background: ${state.action === ACTIONS.DELETE_DOCUMENT
            ? "red"
            : colors.primaryPurple};
           font-weight: 500;
           transition: 250ms;
           margin-bottom: 11px;

           &:hover {
            opacity: 0.8;
           }
          `}
          isLoading={isUploading}
          onClick={() => {
           state.action === ACTIONS.DELETE_DOCUMENT
            ? deleteDocument(chosenDoc)
            : createDocument(value);
          }}
         >
          {state.action === ACTIONS.DELETE_DOCUMENT ? "Delete" : "Add document"}
         </Button>
        </Flex>
       </>
      )}
     </Card>
    </AnimatePresence>
   </Modal>
  ),
  [visible, setVisible, renderAddInput]
 );

 const renderToast = React.useMemo(
  () => (
   <Toast
    visible={toast.state}
    setVisible={setToast}
    text={toast.text}
    textStyle={{ color: toast.textColor }}
    style={{ backgroundColor: toast.bgColor }}
   />
  ),
  [toast]
 );

 if (loading) {
  return <Loader />;
 }

 return (
  <Container>
   {renderSearchAndAddDocumentArea}
   <CategoryHeader>
    <p>Legal documents</p>
    <p>Actions</p>
   </CategoryHeader>
   {renderDocuments}
   {renderModal}
   {renderToast}
  </Container>
 );
};

export default Legal;
