import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Input,
  Checkbox,
  Switch,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  RadioGroup,
  Radio,
  Select,
  Flex,
} from "@chakra-ui/react";
import { colors } from "../../../assets/styles/config";
import { Formik, Field } from "formik";
import DateRange from "../../../components/DateRange";

const OpeningHours = ({ onSwitchStage, data }) => {
  const [sameTime, setSameTime] = useState(false);
  const time = ["8:00am - 10:00pm", "10:00am - 11:00pm"];
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <Box width="100%" height="100%">
      <Box
        borderBottom="1px solid #E2E8F0"
        py="20px"
        marginTop="20px"
        display={{ base: "none", md: "block" }}
      >
        <Text
          fontFamily="Creato Display, Roboto, sans-serif;"
          color={colors.darkBlue}
          fontWeight="400"
          lineHeight="135%"
          fontSize="24px"
        >
          4. Opening Hours
        </Text>
      </Box>
      <Box
        py="20px"
        marginTop="20px"
        display={{ base: "flex", md: "none" }}
        flexDir="column"
        alignItems="flex-start"
      >
        <Text color="#878498" fontFamily="Creato Display, Roboto, sans-serif;">
          Step 4 of 5
        </Text>
        <Text
          fontFamily="Creato Display, Roboto, sans-serif;"
          color={colors.darkBlue}
          fontWeight="400"
          lineHeight="135%"
          fontSize="24px"
          marginTop="20px"
        >
          Opening Hours
        </Text>
        <Text
          fontFamily="Satoshi"
          color={colors.darkBlue}
          fontWeight="500"
          lineHeight="20px"
          fontSize="16px"
          marginTop="10px"
        >
          Please provide your Opening hours
        </Text>
      </Box>
      <Box mt="30px" display="flex" alignItems="center">
        <Text
          fontFamily="Satoshi"
          fontWeight="500"
          fontSize="20px"
          marginRight="10px"
        >
          I have same opening and closing time for all my available days
        </Text>
        <Switch
          size="sm"
          colorScheme="green"
          isChecked={sameTime}
          onChange={() => setSameTime(!sameTime)}
        />
      </Box>
      <Box mt="30px">
        <Formik
          initialValues={{
            day: {
              monday: {
                start: "",
                end: "",
              },
              tuesday: {
                start: "",
                end: "",
              },
              wednesday: {
                start: "",
                end: "",
              },
              thursday: {
                start: "",
                end: "",
              },
              friday: {
                start: "",
                end: "",
              },
              saturday: {
                start: "",
                end: "",
              },
              sunday: {
                start: "",
                end: "",
              },
            },
          }}
          onSubmit={async (values) => {
            onSwitchStage({ ...values, isSameTime: sameTime });
          }}
          // validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched, values, setFieldValue }) => {
            useEffect(() => {
              if (sameTime) {
                let foundStart = "";
                let foundEnd = "";

                // Traverse the outer object
                for (const outerKey in values) {
                  if (values.hasOwnProperty(outerKey)) {
                    const innerObj = values[outerKey];

                    // Traverse the inner object
                    for (const key in innerObj) {
                      if (innerObj.hasOwnProperty(key)) {
                        const { start, end } = innerObj[key];

                        if (start !== "" && end !== "") {
                          foundStart = start;
                          foundEnd = end;
                          break;
                        }
                      }
                    }

                    if (foundStart !== "" && foundEnd !== "") {
                      break;
                    }
                  }
                }

                // days?.map((day) => {
                //   setFieldValue(`day.${day}.start`, foundStart);
                //   setFieldValue(`day.${day}.end`, foundEnd);
                // });

                for (const outerKey in values) {
                  if (values.hasOwnProperty(outerKey)) {
                    const innerObj = values[outerKey];

                    // Traverse the inner object
                    for (const key in innerObj) {
                      if (innerObj.hasOwnProperty(key)) {
                        const { start, end } = innerObj[key];

                        if (start === "") {
                          setFieldValue(`day.${key}.start`, foundStart);
                        }

                        if (end === "") {
                          setFieldValue(`day.${key}.end`, foundEnd);
                        }
                      }
                    }
                  }
                }
              }
            }, [sameTime]);
            return (
              <form onSubmit={handleSubmit}>
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  gap="30px"
                  marginBottom="40px"
                >
                  {days.map((day, index) => (
                    <FormControl
                      width="100%"
                      key={index}
                      id=""
                      // isInvalid={
                      //   !!errors.businessOwner && !!touched.businessOwner
                      // }
                    >
                      <FormLabel
                        htmlFor=""
                        color={colors.darkBlue}
                        fontFamily="Satoshi"
                        fontWeight="500"
                        fontSize="20px"
                        lineHeight="135%"
                        mb="10px"
                      >
                        {day}
                      </FormLabel>
                      <Text
                        fontFamily="Satoshi"
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="21px"
                        mb="10px"
                      >
                        Opening and closing time
                      </Text>
                      <DateRange
                        values={values}
                        setFieldValue={setFieldValue}
                        day={day?.toLowerCase()}
                      />
                      {/* <Select
                      width="100%"
                      onChange={(e) => {
                        setFieldValue(
                          `day.[${day.toLowerCase()}]`,
                          e.target.value
                        );
                      }}
                      background="#F1F5F9"
                      borderRadius="66px"
                      id=""
                      name=""
                    >
                      <option value="" selected hidden>
                        Select time
                      </option>
                      {time.map((item, idx) => (
                        <option value={item} key={idx}>
                          {item}
                        </option>
                      ))}
                    </Select> */}
                    </FormControl>
                  ))}
                </SimpleGrid>

                <Button
                  color={colors.primaryWhite}
                  bg={colors.primaryPurple}
                  borderRadius={28}
                  fontWeight={500}
                  fontSize={18}
                  marginTop={10}
                  width={{ base: "100%", md: "25%" }}
                  type="submit"
                >
                  Next
                </Button>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default OpeningHours;
