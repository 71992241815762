import { ref, update } from "firebase/database";
import { db } from "../../firebase";

export const updateMaxTickets = (experience, chosenTimeFrame, counter) => {
 console.log("updateMaxTickets function ran");
 // LOGIC TO UPDATE MAX TICKETS FOR THE EXPERIENCE
 const experienceDb = ref(db, `cityExperience/experiences/${experience.id}`);
 // @jeyi this won't work because the maxTickets aren't directly on the experience, they are dependent on the timeframes and the maxTickets of that experience

 const newTimeSlotUpdate = experience.timeframes[0].timeSlot.map((item) =>
  item.id === chosenTimeFrame.id
   ? { ...item, maxTickets: item.maxTickets - counter }
   : item
 );

 const newTimeFrames = experience.timeframes.map((item, i) =>
  i === 0 ? { ...item, timeSlot: newTimeSlotUpdate } : item
 );
 update(experienceDb, {
  timeframes: JSON.stringify(newTimeFrames),
 });
};
