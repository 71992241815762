import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useParams, Link } from "react-router-dom";
import deal from "../../assets/images/deal.png";
import call from "../../assets/images/Call.svg";
import location from "../../assets/images/Location.svg";
import www from "../../assets/images/iconoir_www.svg";
import check from "../../assets/images/tabler_discount-check.svg";
import tag from "../../assets/images/tag.svg";
import delivery from "../../assets/images/carbon_delivery.svg";
import styles from "./index.module.css";
import Navbar from "../Home/Navbar";
import { dealsDeal } from "../../mocks/index";
import { Footer } from "../../components";
import { Header, Loader } from "../../components";
import { colors } from "../../assets/styles/config";
import { TitleSection } from "../Experiences/styles";

const Deal = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const { id } = useParams();
  const deals = [
    {
      icon: check,
      title: "Buy 1 Get One Free",
      subTitle: "Buy 1 Get One Free on goods above 10k ",
    },
    {
      icon: tag,
      title: "Get Discounts on Orders ",
      subTitle: "High Discounts on goods above 10k ",
    },
    {
      icon: delivery,
      title: "Free Deliveries ",
      subTitle: "Free deliveries on every food pack",
    },
  ];

  const ref = useRef();

  // useEffect(() => {
  //   ref.current.scrollIntoView();
  // }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header bgColor={"#FFFFFF"} color="dark" />
      <Box
        mt="80px"
        padding={{ base: "2rem", md: "4rem" }}
        bgSize="cover"
        bgRepeat="no-repeat"
      >
        <TitleSection style={{ margin: "0", padding: "0" }}>
          <Flex textAlign={"center"} gap={"0.75rem"}>
            <Link to="/">
              <Text
                color={colors.darkBlue}
                fontWeight="400"
                fontSize={{ base: 15, md: 16, lg: 18 }}
              >
                {"Home >"}
              </Text>
            </Link>
            <Link to="/all-deals">
              <Text
                color={colors.darkBlue}
                fontWeight="400"
                fontSize={{ base: 15, md: 16, lg: 18 }}
              >
                {"All Deals >"}
              </Text>
            </Link>
            <Text
              color={colors.faintBrown}
              fontWeight="400"
              fontSize={{ base: 15, md: 16, lg: 18 }}
            >
              {dealsDeal[id].title}
            </Text>
          </Flex>
        </TitleSection>
        <SimpleGrid
          mt="20px"
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 14, lg: 10 }}
          alignItems="start"
        >
          <Box pos="relative" order={{ base: "9999", lg: "-9999" }}>
            <Text
              color="#0F0931"
              fontSize={22}
              lineHeight="35px"
              fontWeight="600"
            >
              {dealsDeal[id].title}
            </Text>
            <Text
              color="#0F0931"
              fontSize={14}
              lineHeight="24px"
              fontWeight="400"
              p="10px 0"
              textAlign="justify"
              width={{ lg: "80%" }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos.
            </Text>

            <Box mt="10px">
              <Text
                color="#0F0931"
                fontSize={22}
                lineHeight="35px"
                fontWeight="600"
              >
                Deals
              </Text>
              <Box mt="10px">
                {deals.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      bgColor="#FFFFFF"
                      w={{ base: "100%", md: "400px", xl: "500px" }}
                      p="20px"
                      borderRadius="12px"
                      boxShadow="0px 9px 46px rgba(0, 0, 0, 0.12)"
                      marginBottom="20px"
                    >
                      <Img src={item.icon} />
                      <Box ml="10px">
                        <Text
                          color="#000000"
                          fontSize={{ base: "20px", md: "22px" }}
                          lineHeight="28px"
                          fontWeight="600"
                        >
                          {item.title}
                        </Text>
                        <Text
                          color="#000000"
                          fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                          lineHeight="24px"
                          fontWeight="400"
                        >
                          {item.subTitle}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <HStack mb="10px">
                <Img src={location} />
                <Text fontSize="14px" lineHeight="24px" fontWeight="400">
                  Road 32, French Grussel street Victoria Island{" "}
                </Text>
              </HStack>
              <HStack mb="10px">
                <Img src={call} />
                <Text fontSize="14px" lineHeight="24px" fontWeight="400">
                  0674 937 9292002
                </Text>
              </HStack>
              <HStack mb="10px">
                <Img src={www} />
                <Text fontSize="14px" lineHeight="24px" fontWeight="400">
                  Grabbylagos@gmail.com
                </Text>
              </HStack>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Img
              src={dealsDeal[id].img}
              height={{ base: "350px", lg: "500px" }}
            />
          </Box>
        </SimpleGrid>
      </Box>

      {/* <Footer /> */}
    </>
  );
};

export default Deal;
