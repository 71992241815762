export const actionTypes = {
  SET_CITY_DATA: "SET_CITY_DATA",
  UPDATE_HISTORY: "UPDATE_HISTORY",
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CITY_DATA:
      localStorage.setItem("CITY_DATA", JSON.stringify(payload));
      return { ...state, ...payload };

    case actionTypes.UPDATE_HISTORY:
      return { ...state, orderHistory: [...state.orderHistory, payload] };

    default:
      return state;
  }
};
export default reducer;
