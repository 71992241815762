import { Flex, chakra, Text } from "@chakra-ui/react";
import { colors } from "../../../../assets/styles/config";

export const BoughtActiveText = chakra(Text, {
 baseStyle: {
  color: colors.darkBlue,
  fontWeight: "400",
  lineHeight: "15px",
 },
});

export const BoughtListWrapper = chakra(Flex, {
 baseStyle: {
  alignItems: "center",
  justifyContent: "space-between",
  padding: "15px 10px",
  cursor: "pointer",
 },
});

export const Column = chakra(Flex, {
 baseStyle: {
  flexDir: "column",
 },
});

export const Wrapper = chakra(Flex, {
 baseStyle: {
  flexDir: "column",
  height: "100%",
  minH: "calc(100vh - 85px)",
  padding: { base: "10px 20px", md: "14px 20px" },
  background: "#F5F8FA",
 },
});

export const FollowInnerWrapper = chakra(Flex, {
 baseStyle: {
  display: { base: "none", md: "flex" },
  alignItems: "center",
  cursor: "pointer",
 },
});
