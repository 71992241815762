import { css } from "@emotion/react";
import styled from "styled-components";
import { typography, colors, fontWeight } from "../../assets/styles/config";

export const Container = styled.div`
  // max-width: 1880px;
  margin: auto;
`;

export const navCartIconCss = `
  width: 13px;
  height: 13px;
  
  @media screen and (min-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

export const CartAmount = styled.p`
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: ${colors.blue};
  // padding: 6px 12px;
  width: 18px;
  height: 18px;
  font-weight: ${fontWeight.medium};
  font-size: 10px;
  line-height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.primaryWhite};

  @media screen and (min-width: 768px) {
    top: 5px;
    right: 5px;
  }
`;

export const Regular = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.darkBlue};
  cursor: pointer;

  @media screen and (min-width: 768px) {
    font-weight: ${fontWeight.medium};
    font-size: 18px;
    line-height: 27px;
  }
`;

export const NavOptions = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;

  @media screen and (min-width: 768px) {
    gap: 2rem;
  }
`;

export const NavOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  @media screen and (min-width: 768px) {
    gap: 0;
  }
`;

export const BlueButton = styled.div`
  background-color: ${colors.blue};
  border-radius: 6px;
  color: ${colors.primaryWhite};
  font-size: 14px;
  display: grid;
  place-items: center;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    border-radius: 28px;
    font-size: 18px;
    width: 103px;
    height: 45px;
    padding: 0;
  }
`;

export const MobileMenu = styled.div`
  position: fixed;
  padding: 30px;
  background: #ffffff;
  width: 250px;
  height: 100%;
  z-index: 50;
  right: 0;
  top: 0;
  filter: drop-shadow(0px 19px 16px rgba(85, 95, 103, 0.07));
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
`;
