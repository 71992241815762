import React, { useContext, useState } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Image,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Img1 from "../../assets/images/signup-phones.png";
import bgImg from "../../assets/images/Navi-Logo-Inversion.svg";
import styles from "./index.module.css";
import { colors } from "../../assets/styles/config";
import Logo from "../../assets/images/navi-logo-white.png";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ShowImg from "../../assets/images/Show.svg";
import HideImg from "../../assets/images/Hide.svg";
import "@splidejs/react-splide/css";
import { AuthContext } from "../../context/AuthContext";
import Header from "../../components/Header/index";
import NaviBg from "../../assets/images/lagos.jpeg";
import ArrowLeft from "../../assets/images/arrow-left-purple.svg";
import { useNavigate } from "react-router-dom";
import { inputStyle } from "../Admin/Dashboard/styles";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

const SignIn = () => {
  const navigate = useNavigate();
  // const images = [Img1, Img1, Img1];
  const [showPassword, setShowPassword] = useState(false);
  const { signin } = useContext(AuthContext);
  return (
    <>
      {/* <Header color="dark" p={{ base: "15px 10px", lg: "20px 60px" }} /> */}
      <Box
        position="relative"
        overflow={"hidden"}
        width="100%"
        height="100vh"
        backgroundColor="#F3F2F9"
      >
        {/* <Box className={styles.imgbg} display={{ base: "none", lg: "flex" }}>
          <Box className={styles.slide}>
            <Img src={Img1} alt="phones" h="700px" pt="70px" />
          </Box>
        </Box> */}
        <Box backgroundColor="#F3F2F9">
          {/* <Image
            src={NaviBg}
            objectFit="cover"
            width="100%"
            height="100vh"
            zIndex="0"
            mixBlendMode="multiply"
          /> */}
        </Box>
        <Box
          position="absolute"
          left="30px"
          top="5%"
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={() => navigate(-1)}
        >
          <Image src={ArrowLeft} />
          <Text color="#000" marginLeft="10px">
            Go Back
          </Text>
        </Box>
        <Box
          position="absolute"
          left="0"
          right="0"
          top="10%"
          zIndex="100"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          paddingInline={"25px"}
        >
          <Img
            src={Logo}
            h="60px"
            display={{ base: "block" }}
            marginBottom="20px"
          />

          {/*<Img src={Logo} h="50px" display={{ base: "block", lg: "none" }} /> */}
          <Box
            maxW="32rem"
            // mt={{ base: "50px", lg: "160px" }}
            mx="auto"
            w="100%"
            // w={{ base: "100%" }}
            padding={{ base: 5, xl: 10 }}
            h="100%"
            display="flex"
            flexDir={{ base: "column" }}
            alignItems={{ base: "flex-start", lg: "center" }}
            justifyContent="center"
            backgroundColor="#ffffff"
            borderRadius="2xl"
          >
            <Box w="100%">
              <Text
                color="#0F0931"
                fontSize={{ base: "24px" }}
                lineHeight="20px"
                fontWeight="700"
                textAlign="center"
                marginBottom="10px"
                fontFamily="Creato Display, Roboto, sans-serif;"
              >
                Welcome Back!
              </Text>
              <Box mt="20px" w="100%">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={async (values) => {
                    await signin(values);
                  }}
                  validationSchema={validationSchema}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                      {/* <FormControl
                    w="100%"
                    alignItems="center"
                    mb="20px"
                    isRequired
                    isInvalid={!!errors.firstName && touched.firstName}
                  >
                    <FormLabel
                      color="#1D1D1D"
                      fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                      lineHeight="140%"
                      fontWeight="400"
                      mb="10px"
                    >
                      First name
                    </FormLabel>
                    <Field
                      as={Input}
                      borderRadius="4px"
                      border="0.705598px solid #DFDDDD"
                      p="10px"
                      type="text"
                      bg="#FEFEFF"
                      id="firstName"
                      name="firstName"
                    />
                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                  </FormControl>
              */}
                      <FormControl
                        w="100%"
                        alignItems="center"
                        mb="20px"
                        isRequired
                        isInvalid={!!errors.email && touched.email}
                      >
                        <FormLabel
                          color="#1D1D1D"
                          fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                          lineHeight="140%"
                          fontWeight="400"
                          mb="10px"
                        >
                          Email
                        </FormLabel>
                        <Field
                          as={Input}
                          {...inputStyle}
                          type="email"
                          id="email"
                          name="email"
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>

                      <FormControl
                        w="100%"
                        alignItems="center"
                        mb="20px"
                        isInvalid={!!errors.password && touched.password}
                        isRequired
                      >
                        <FormLabel
                          color="#1D1D1D"
                          fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                          lineHeight="140%"
                          fontWeight="400"
                          mb="10px"
                        >
                          Password
                        </FormLabel>
                        <InputGroup>
                          <Field
                            as={Input}
                            {...inputStyle}
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                          />
                          <InputRightElement width="3rem">
                            <Box onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? (
                                <Img src={HideImg} alt="hide" />
                              ) : (
                                <Img src={ShowImg} alt="show" />
                              )}
                            </Box>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                      </FormControl>

                      <Box mt="20px" display="flex" justifyContent="flex-end">
                        <Link to="/forget-password">
                          <Text
                            fontSize={{ base: "12px", md: "13px", lg: "14px" }}
                            lineHeight="140%"
                            fontWeight="400"
                            // textColor={colors.blue}
                            cursor="pointer"
                          >
                            Forgot Password ?
                          </Text>
                        </Link>
                      </Box>
                      <Button
                        color={colors.primaryWhite}
                        bg={colors.primaryPurple}
                        borderRadius={28}
                        fontWeight={500}
                        fontSize={18}
                        marginTop={10}
                        p="24px 20px"
                        w="100%"
                        type="submit"
                      >
                        Sign In
                      </Button>
                      <Text
                        textAlign="center"
                        fontSize="14px"
                        lineHeight="140%"
                        fontWeight="400"
                        mt="20px"
                      >
                        No Account?{" "}
                        <Link to="/signup">
                          <span
                            style={{
                              color: colors.primaryPurple,
                              cursor: "pointer",
                            }}
                          >
                            Sign Up
                          </span>
                        </Link>
                      </Text>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SignIn;
