import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 List,
 ListItem,
 SimpleGrid,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Switch,
 InputLeftElement,
 InputRightElement,
 InputGroup,
 Image,
 Button,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import {
 Button as CustomButton,
 EmptyState,
 MobileAddBtn,
} from "../../../../components";
import {
 searchInputStyle,
 inputStyle,
 selectStyle,
} from "../../../Admin/Dashboard/styles";
import { colors } from "../../../../assets/styles/config";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { db } from "../../../../firebase";
import { NoCollections } from "../../../Experiences/styles";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
} from "../../../../components";
import { IoLocationOutline } from "react-icons/io5";
import { useDownloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import ArrowRight from "../../../../assets/images/arrowleft2.svg";
import dayjs from "dayjs";
import { Card, CloseBtn, Container, tabTHStyles, tabTRStyles } from "../styles";
import Select from "react-select";
import TrashIcon from "../../../../assets/images/trash-2.svg";
import { storage } from "../../../../firebase";
import { toast } from "react-hot-toast";
import { AiOutlineCloseCircle } from "react-icons/ai";
import MyCheckbox from "../../../../components/Checkbox/index";
import Warning from "../../../../assets/images/warning-icon.svg";
// import moment from "moment";

const validationSchema = Yup.object({
 name: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("Name is required"),
 email: Yup.string()
  .email("Invalid email address")
  .required("Email is required"),
});

const PartnerMembers = () => {
 const {
  user,
  addAdmin,
  updateAdministrator,
  deleteAdministrator,
  addPartnerMember,
  deletePartnerMember,
 } = useContext(AuthContext);
 const [loading, setLoading] = useState(false);
 const [administrator, setAdministrator] = useState([]);
 const [filterAdministrator, setFilterAdministrator] = useState([]);
 const tableRef = useRef(null);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [modalType, setModalType] = useState("");
 const [showPassword, setShowPassword] = useState(false);
 const [showConfirmPassword, setShowConfirmPassword] = useState(false);
 const [imageUrl, setImageUrl] = useState("");
 const [media, setMedia] = useState(null);
 const [error, setError] = useState("");
 const [search, setSearch] = useState("");
 const [deleteId, setDeleteId] = useState("");
 const [partnerOption, setPartnerOption] = useState([]);
 const [roles, setRoles] = useState(null);
 const uid = user?.partnerUid ?? user?.uid;
 const businessName = user?.partner ?? user?.businessName;
 const rolesDB = ref(db, `users/partner/${uid}/roles`);
 const navigate = useNavigate();

 const filterObjectPartner = (obj) =>
  Object.keys(obj).reduce(
   (acc, val) =>
    obj[val]["role"] === "PARTNER"
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 const fetchDatabase = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `users/partner/${uid}/members`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     let myObj = snapshot.val();

     var array = Object.keys(myObj).map((key) => {
      return myObj[key];
     });

     /*  var sortedData = array.sort(function (a, b) {
            return Number(a.id.slice(3)) - Number(b.id.slice(3));
          }); */

     var sortedData = array
      ?.map((obj) => {
       return { ...obj, date: new Date(JSON.parse(obj.created_at)) };
      })
      .sort((a, b) => b.date - a.date);

     setAdministrator(sortedData);

     const myObjPartner = filterObjectPartner(snapshot.val());
     setPartnerOption(
      Object.keys(myObjPartner)
       ?.map((key) => {
        return {
         label: myObjPartner[key].name,
         value: myObjPartner[key].name,
         status: myObjPartner[key].status,
        };
       })
       .filter((item) => item?.status === "APPROVED")
     );
    } else {
     //  console.log("No data available");
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  //get users order

  return db;
 };

 useEffect(() => {
  if (user) {
   fetchDatabase();
  }
 }, [user, roles, uid]);

 useEffect(() => {
  setFilterAdministrator(administrator);
 }, [administrator]);

 /*  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Orders table",
    sheet: "Orders",
  });

  const csvHeader = [
    { label: "Date", key: "date" },
    { label: "Experience Name", key: "experience" },
    { label: "Ticket Token", key: "ticket_token" },
    { label: "Status", key: "status" },
  ];

  const csvData = Object.keys(administrator || {})?.map((item) => {
    return {
      date: administrator[item]?.date,
      experience: administrator[item].experience,
      ticket_token: administrator[item].token,
      status: administrator[item].status,
    };
  }); */

 useEffect(() => {
  // setLoading(true);
  const unsubscribe = onValue(rolesDB, async (snapshot) => {
   const roles = snapshot.val();

   setRoles(
    roles?.map((item) => {
     return {
      value: item?.role,
      label: item?.role,
     };
    })
   );
  });
  return () => {
   unsubscribe();
  };
 }, []);

 //switch handler
 const onChangeHandle = async (e, item) => {
  // index === checkSelect && setisChecked(!isChecked);
  setAdministrator(
   administrator.map((itm) =>
    itm.uid === item.uid ? { ...item, enabled: e.target.checked } : itm
   )
  );
  toast.loading("loading");
  await updateAdministrator({
   ...item,
   enabled: e.target.checked,
  }).then((res) => {
   // toast.success("Profile successful updated");
  });
 };

 const searchFilter = (text) => {
  if (text) {
   setSearch(text);
   const newData = administrator.filter((item) => {
    const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setFilterAdministrator(newData);
  } else {
   setSearch(text);
   setFilterAdministrator(administrator);
  }
 };

 const handleDelete = async () => {
  //  console.log("handleDelete", deleteId);
  const res = await deletePartnerMember(uid, deleteId);
  if (res) {
   setVisible(!visible);
   setDeleteId("");
   fetchDatabase();
  }
 };

 const truncateString = (string, maxLength) =>
  string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

 const random = (length) => {
  const characters =
   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
  let password = "";

  for (let i = 0; i < length; i++) {
   const randomIndex = Math.floor(Math.random() * characters.length);
   password += characters.charAt(randomIndex);
  }

  return password;
 };

 return (
  <Box
   height="100%"
   minHeight="calc(100vh - 85px)"
   padding={{ base: "0px 20px", md: "20px" }}
   background="#F5F8FA"
   position="relative"
  >
   <MobileAddBtn
    onClick={() => {
     setVisible(!visible);
     setModalType("add");
    }}
   />

   <Box
    background={{ md: "#FFFFFF" }}
    borderRadius="6px"
    // mt="30px"
    minHeight={{ md: "80vh" }}
    padding={{ md: "30px" }}
   >
    <Flex
     alignItems="flex-start"
     flexDir={{ base: "column", md: "row" }}
     justifyContent="space-between"
     display={{ base: "none", md: "flex" }}
    >
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>

     <Box display="flex" alignItems="center">
      <CustomButton
       animateOnTap
       animateOnHover
       buttonColor="#632EFF"
       css={css`
        width: fit-content;
        padding: 0.5rem 2rem;
        height: auto;
        font-weight: 500;
        font-family: "Creato Display";
        display: flex;
        gap: 15px;

        @media screen and (max-width: 768px) {
         border-radius: 10px;
        }
       `}
       onClick={() => {
        setVisible(!visible);
        setModalType("add");
       }}
      >
       <AddIcon h="16px" w="16px" /> Add Team Member
      </CustomButton>
     </Box>
    </Flex>

    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt={{ md: "30px" }}>
      {filterAdministrator?.length > 0 ? (
       <>
        <TableContainer
         border="1px solid #E2E8F0"
         borderRadius="16px 16px 0px 0px"
         display={{ base: "none", md: "block" }}
        >
         <Table variant="simple" ref={tableRef}>
          <Thead>
           <Tr>
            {["Name", "Email Address", "Role", "Access to", ""].map(
             (text, i) => (
              <Th {...tabTHStyles}>{text}</Th>
             )
            )}
           </Tr>
          </Thead>
          <Tbody>
           {filterAdministrator?.map((item, index) => {
            let color = item.enabled ? "#334155" : "#64748B";
            return (
             <Tr
              key={index}
              cursor="pointer"
              _hover={{
               background: "#F1F5F9",
              }}
             >
              <Td
               {...tabTRStyles}
               color={color}
               onClick={() => navigate(`/partner/members/view/${item.uid}`)}
              >
               {item?.name}
              </Td>
              <Td
               {...tabTRStyles}
               color={color}
               onClick={() => navigate(`/partner/members/view/${item.uid}`)}
              >
               {item?.email}
              </Td>

              <Td
               {...tabTRStyles}
               color={color}
               onClick={() => navigate(`/partner/members/view/${item.uid}`)}
              >
               {item?.role}
              </Td>
              <Td {...tabTRStyles} color={color}>
               All access
              </Td>

              <Td
               padding="14px 20px"
               display="flex"
               alignItems="center"
               gap="20px"
              >
               <Box>
                <Switch
                 size="sm"
                 color="#25AEFC"
                 isChecked={item.enabled}
                 onChange={(e) => {
                  onChangeHandle(e, item);
                 }}
                 colorScheme="purple"
                />
               </Box>
               <Box
                cursor="pointer"
                onClick={() => {
                 setVisible(!visible);
                 setModalType("edit");
                 setDeleteId(item.uid);
                }}
               >
                <Image src={TrashIcon} />
               </Box>
              </Td>
             </Tr>
            );
           })}
          </Tbody>
         </Table>
        </TableContainer>
        <Box display={{ base: "block", md: "none" }}>
         <Box
          overflowX={"auto"}
          background="#fff"
          width="100%"
          border="1px solid #e2e8f0"
          borderRadius="16px"
          marginTop="20px"
          padding="0px 10px"
         >
          {filterAdministrator?.map((item, index) => (
           <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="15px 10px"
            borderBottom={
             filterAdministrator?.length - 1 !== index && "1px solid #E2E8F0"
            }
            cursor="pointer"
            onClick={() => navigate(`/partner/members/view/${item.uid}`)}
           >
            <Box display="flex" flexDirection="column" alignItems="flex-start">
             <Text
              color={colors.darkBlue}
              fontWeight="500"
              lineHeight="20px"
              marginBottom="10px"
              fontSize="18px"
             >
              {item?.name}
             </Text>
             <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
              <Text
               color={colors.darkBlue}
               fontWeight="400"
               lineHeight="15px"
               fontSize="14px"
              >
               {item?.email}
              </Text>{" "}
              |{" "}
              <Text
               color={colors.darkBlue}
               fontWeight="400"
               lineHeight="15px"
               fontSize="14px"
              >
               {item?.role}
              </Text>
             </Box>
            </Box>

            <Image src={ArrowRight} alt="arrow-left" />
           </Box>
          ))}
         </Box>
        </Box>
       </>
      ) : (
       <EmptyState
        text={
         "No team members yet. Click the add button to add new team members"
        }
       />
      )}
     </Box>
    )}
   </Box>
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
     // style={{ marginTop: modalType === "add" && "50px" }}
    >
     {modalType === "add" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "500px", height: "100%", padding: "20px" }}
      >
       <Box
        // borderBottom="1px solid #B8B8B8"
        padding="20px 0px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
       >
        <Text
         fontFamily="Satoshi"
         color="#0F0931"
         fontSize={{ base: "20px", lg: "28px" }}
         lineHeight="125%"
         fontWeight="700"
        >
         Add team member
        </Text>
        <Box style={{ cursor: "pointer" }} onClick={() => setVisible(false)}>
         <AiOutlineCloseCircle fontSize="30px" />
        </Box>
       </Box>
       <Box padding="20px 0px" display="flex" flexDir="column">
        <Formik
         initialValues={{
          name: "",
          // assignedPartner: "",
          email: "",
          // phoneNumber: "",
          role: "",
          // password: "",
          // access: "",
         }}
         onSubmit={async (values) => {
          // const lastId = administrator.pop()?.id.slice(3); `NAV${Number(lastId) + 1}
          // values.assignedPartner.map(
          //   (item) => item.value
          // ),
          const payload = {
           ...values,
           assignedPartner: "",
           role: values.role.value,
           enabled: true,
           created_at: JSON.stringify(dayjs(Date.now())),
           photoURL: "",
           phoneNumber: "",
           password: random(8),
           partnerUid: uid,
           partnerName: businessName,
          };

          const res = await addPartnerMember(payload);
          if (res) {
           setVisible(!visible);
           fetchDatabase();
          }
         }}
         validationSchema={validationSchema}
        >
         {({
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          values,
         }) => {
          return (
           <form onSubmit={handleSubmit}>
            <FormControl
             w="100%"
             display="flex"
             flexDir="column"
             alignItems="flex-start"
             mb="20px"
             isRequired
             isInvalid={!!errors.name && touched.name}
            >
             <FormLabel
              fontFamily="Satoshi"
              color="#0F0931"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="21px"
              fontWeight="700"
             >
              Name
             </FormLabel>
             <Field
              as={Input}
              {...inputStyle}
              type="text"
              placeholder="name"
              id="name"
              name="name"
             />
             <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl
             w="100%"
             display="flex"
             flexDir="column"
             alignItems="flex-start"
             mb="20px"
             isRequired
             isInvalid={!!errors.email && touched.email}
            >
             <FormLabel
              fontFamily="Satoshi"
              color="#0F0931"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="21px"
              fontWeight="700"
             >
              Email
             </FormLabel>
             <Field
              as={Input}
              {...inputStyle}
              type="email"
              placeholder="email"
              id="email"
              name="email"
             />
             <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl
             w="100%"
             display="flex"
             flexDir="column"
             alignItems="flex-start"
             mb="20px"
             isRequired
             isInvalid={!!errors.role && touched.role}
            >
             <FormLabel
              fontFamily="Satoshi"
              color="#0F0931"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="21px"
              fontWeight="700"
             >
              Role
             </FormLabel>
             <Select
              name="role"
              id="role"
              value={values.role}
              onChange={(option) => setFieldValue("role", option)}
              options={roles}
              onBlur={handleBlur}
              noOptionsMessage={() => (
               <Text>
                No roles added?{" "}
                <Link style={{ color: "blue" }} to={"/partner/roles"}>
                 Manage roles
                </Link>{" "}
               </Text>
              )}
              styles={{
               control: (provided, state) => ({
                ...provided,
                borderRadius: "66px",
                background: "#F1F5F9",
                autoFocus: true,
                "&:hover": {
                 background: "#F3F0FF",
                 border: "1px solid #AC98FE",
                },
                "&:focus": {
                 background: "#F3F0FF",
                },
                "&:focusVisible": {
                 border: "1px solid #AC98FE",
                },
               }),
              }}
             />
             <FormErrorMessage>{errors.role}</FormErrorMessage>
            </FormControl>

            <Box
             width="100%"
             marginTop={10}
             // gap="20px"
            >
             <Button
              color="#ffffff"
              bg={colors.primaryPurple}
              borderRadius={28}
              fontWeight={500}
              fontSize={18}
              p="24px 20px"
              type="submit"
              width="100%"
             >
              Invite staff
             </Button>
            </Box>
           </form>
          );
         }}
        </Formik>
       </Box>
      </Card>
     )}
     {modalType === "edit" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Team member?
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you would like to delete this administrator?
        </Text>

        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setDeleteId("");
          }}
         >
          Cancel
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default PartnerMembers;
