import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Box, HStack, Text, Button, Image } from "@chakra-ui/react";
import ArrowLeft from "../../../assets/images/arrow-left-dark.svg";
import check from "../../../assets/images/checkblue.svg";
import checkgrey from "../../../assets/images/checkgrey.svg";
import Successful from "../../../assets/images/successful.svg";
import { colors } from "../../../assets/styles/config";
import { AuthContext } from "../../../context/AuthContext";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import GeneralDetails from "./generalDetails";
import PersonalDetails from "./personalDetails";
import BusinessDetails from "./businessDetails";
import OpeningHours from "./openingHours";
import CategoriesTags from "./categoriesTags";
import Introduction from "./introduction";
import dayjs from "dayjs";
import { Modal } from "../../../components";
import { Card, CloseBtn, Container } from "../styles";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const navigate = useNavigate();
  const { partnerOnboard } = useContext(AuthContext);
  const [stage, setStage] = useState(1);
  const [dataForNextStage, setDataForNextStage] = useState({});
  const containerRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const random = (length) => {
    return Math.floor(
      Math.pow(10, length - 1) +
        Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
    );
  };

  const lastStage = useMemo(() => {
    const stage = dataForNextStage?.registeringAs === "Business" ? 6 : 5;
    return stage;
  }, [dataForNextStage]);

  const handleSwitchStage = useCallback(
    async (data = {}) => {
      if (stage === 1) {
        setStage((prevStage) => prevStage + 1);
        setDataForNextStage((prevData) => ({ ...prevData, ...data }));
      } else if (stage !== lastStage) {
        nextStep();
        setStage((prevStage) => prevStage + 1);
        setDataForNextStage((prevData) => ({ ...prevData, ...data }));
      } else {
        const values = { ...dataForNextStage, ...data };
        const payload = {
          registeringAs: values.registeringAs,
          businessOpen: values.businessOpen,
          establishmentType: values.establishmentType,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          address: values.businessAddress,
          businessName: values.businessName,
          businessEmail: values.businessEmail,
          businessPhoneNumber: values.businessPhoneNumber,
          bio: values.bio,
          // businessAddress: values.businessAddress,
          city: values.city,
          hours:
            dataForNextStage?.registeringAs === "Business"
              ? JSON.stringify(values.day)
              : "",
          isSameTime: values.isSameTime ? values.isSameTime : "",
          link: JSON.stringify({
            facebook: values.facebook,
            linkedin: values.linkedin,
            instagram: values.instagram,
            website: values.website,
            twitter: values.twitter,
            tiktok: values.tiktok,
          }),
          tags: ["All"].concat(values.tags),
          category: ["All"].concat(values.category),
          role: "PARTNER",
          subscribed: false,
          status: "PENDING",
          enabled: false,
          password: random(8).toString(),
          created_at: JSON.stringify(dayjs(Date.now())),
        };

        // console.log("payload===", payload);

        await partnerOnboard(payload, false).then((res) => {
          if (res) {
            setVisible(true);
          }
        });
      }
    },
    [stage, dataForNextStage]
  );

  const businessSteps = [
    {
      label: "General Details",
      sub_label: "Please provide some information for our team",
    },
    {
      label: "Personal Details",
      sub_label: "Please provide your name, email and phone number",
    },
    {
      label: "Business Details",
      sub_label: "Please provide your Business details",
    },
    {
      label: "Opening hours",
      sub_label: "Please provide your Opening hours",
    },
    {
      label: "Categories and tags",
      sub_label: "Select category that best describes your business",
    },
  ];

  const indiviualSteps = [
    {
      label: "General Details",
      sub_label: "Please provide some information for our team",
    },
    {
      label: "Personal Details",
      sub_label: "Please provide your name, email and phone number",
    },
    {
      label: "Business Details",
      sub_label: "Please provide your Business details",
    },

    {
      label: "Categories and tags",
      sub_label: "Select category that best describes your business",
    },
  ];

  const icon = () => {
    return <img src={check} />;
  };

  const uncheckicon = () => {
    return <img src={checkgrey} />;
  };

  return (
    <>
      {/* <Header color="dark" p={{ base: "15px 10px", lg: "20px 60px" }} /> */}
      {stage === 1 ? (
        <Introduction
          onSwitchStage={handleSwitchStage}
          data={dataForNextStage}
        />
      ) : (
        <Box
          // display={{ lg: "grid" }}
          // gridTemplateColumns="420px 1fr"
          display="flex"
          alignItems="start"
          height="100%"
          position="relative"
        >
          <Box
            padding="40px 30px"
            bgColor="#F1F5F9"
            position="fixed"
            top="0"
            left="0"
            height="100%"
            width="400px"
            display={{ base: "none", md: "block" }}
          >
            <Text
              color={colors.darkBlue}
              // fontFamily="Space Mono"
              fontWeight="700"
              fontSize="20px"
              lineHeight="135%"
              marginBottom="10px"
            >
              Complete your registration to get started
            </Text>
            <Text
              color="#64748B"
              fontFamily="Satoshi"
              fontWeight="500"
              fontSize="14px"
              lineHeight="16px"
            >
              Takes 5 minutes or less
            </Text>

            <Box mt="30px" className="steps">
              <Steps
                activeStep={activeStep}
                orientation="vertical"
                colorScheme="purple"
                sx={{
                  "& .cui-steps__step-icon-container": {
                    color: "white",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "30px",
                    height: "30px",
                    _active: {
                      background: "transparent",
                    },
                  },
                  "& .cui-steps__vertical-step": {
                    "&::after": {
                      top: "30px !important",
                      left: "14px",
                      bottom: "0px !important",
                      insetInline: "14px !important",
                      _active: {
                        background: "#632eff",
                      },
                    },
                  },
                }}
              >
                {dataForNextStage?.registeringAs === "Business"
                  ? businessSteps.map(({ label, sub_label }) => (
                      <Step
                        label={label}
                        key={label}
                        description={sub_label}
                        icon={uncheckicon}
                        checkIcon={icon}
                      ></Step>
                    ))
                  : indiviualSteps.map(({ label, sub_label }) => (
                      <Step
                        label={label}
                        key={label}
                        description={sub_label}
                        icon={uncheckicon}
                        checkIcon={icon}
                      ></Step>
                    ))}
              </Steps>
            </Box>
          </Box>

          <Box
            //position="relative"
            paddingLeft={{ md: "400px" }}
            height="100%"
            width="100%"

            //
          >
            <Box padding={{ base: "20px", md: "20px 50px" }}>
              {stage === 2 ? (
                <Box
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => {
                    setStage((prevStage) => prevStage - 1);
                  }}
                >
                  <Image src={ArrowLeft} />
                  <Text color="#252046" marginLeft="10px">
                    Go Back
                  </Text>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => {
                    setStage((prevStage) => prevStage - 1);
                    prevStep();
                  }}
                >
                  <Image src={ArrowLeft} />
                  <Text color="#252046" marginLeft="10px">
                    Go Back
                  </Text>
                </Box>
              )}

              {/* {businessSteps?.map((item, index) => (
                <Box key={index} display="flex" alignItems="flex-start">
                  <Text
                    fontFamily="Creato Display, Roboto, sans-serif;"
                    color={colors.darkBlue}
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="24px"
                  >
                    {item.label}
                  </Text>
                  <Text
                    fontFamily="Satoshi"
                    color={colors.darkBlue}
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="16px"
                    marginTop="10px"
                  >
                    {item.sub_label}
                  </Text>
                </Box>
              ))} */}
              {dataForNextStage?.registeringAs === "Business" ? (
                <>
                  {stage === 2 && (
                    <GeneralDetails
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}
                  {stage === 3 && (
                    <PersonalDetails
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}
                  {stage === 4 && (
                    <BusinessDetails
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}
                  {stage === 5 && (
                    <OpeningHours
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}
                  {stage === 6 && (
                    <CategoriesTags
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}
                </>
              ) : (
                <>
                  {stage === 2 && (
                    <GeneralDetails
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}
                  {stage === 3 && (
                    <PersonalDetails
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}
                  {stage === 4 && (
                    <BusinessDetails
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}

                  {stage === 5 && (
                    <CategoriesTags
                      onSwitchStage={handleSwitchStage}
                      data={dataForNextStage}
                    />
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Modal
        {...{
          visible,
          setVisible,
          childrenContainerRef: containerRef,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        overlayLayer
        overlayLayerStyle={{
          backgroundColor: colors.black,
          opacity: 0.7,
        }}
      >
        <Container
          ref={containerRef}
          animate={{ scale: 1 }}
          initial={{ scale: 0.9 }}
        >
          <Card animate={{ scale: 1 }} initial={{ scale: 0.9 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Image src={Successful} />
              <Text
                color={colors.darkBlue}
                //fontFamily="Creato Display"
                fontSize="24px"
                lineHeight="134%"
                marginTop="30px"
                marginBottom="20px"
                textAlign="center"
                fontWeight="700"
              >
                Registration Successful
              </Text>
              <Text fontFamily="Satoshi" fontSize="16px" textAlign="center">
                Thank you for registering as a partner with Navi, our team will
                review your application and get back to you shortly.
              </Text>
              <Button
                color={colors.primaryWhite}
                bg={colors.primaryPurple}
                borderRadius={28}
                fontWeight={500}
                fontSize={18}
                marginTop={10}
                p="24px 20px"
                width="100%"
                type="submit"
                onClick={() => navigate("/")}
              >
                Go Home
              </Button>
            </div>
          </Card>
        </Container>
      </Modal>
    </>
  );
};

export default Onboarding;
