import { colors } from "../../../../assets/styles/config";

export const TabTdStyles = {
 padding: "14px 20px",
 fontSize: { base: "14px" },
 lineHeight: "125%",
 fontWeight: "500",
 fontFamily: "Satoshi",
 color: "#334155",
 cursor: "pointer",
};

export const addExpBtn = {
 color: "#ffffff",
 bg: "#632EFF",
 borderRadius: "54px",
 fontWeight: 500,
 fontSize: 18,
 marginRight: "20px",
 p: "20px 30px",
};

export const addExpBtnMb = {
 display: { base: "flex", md: "none" },
 position: "absolute",
 bottom: 100,
 right: 0,
 width: 70,
 height: 70,
 borderRadius: "100%",
};

export const field = {
 background: colors.activeInputBg,
 outline: colors.activeBorder,
 borderRadius: 30,
 padding: "5px 5px",
 maxWidth: { md: "265px" },
 width: "100%",
};

export const expTd = {
 padding: "14px 20px",
 fontSize: { base: "14px" },
 lineHeight: "125%",
 fontWeight: "500",
 fontFamily: "Satoshi",
 color: "#334155",
 cursor: "pointer",
};

export const tableTh = {
 fontFamily: "Satoshi",
 color: "#252046",
 fontSize: { base: "12px", md: "14px" },
 lineHeight: "125%",
 fontWeight: "700",
 padding: "14px 20px",
 textTransform: "capitalize",
};
