import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 List,
 ListItem,
 SimpleGrid,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Switch,
 InputLeftElement,
 InputRightElement,
 InputGroup,
 Image,
 Button,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import { Button as CustomButton } from "../../../../components";
import { searchInputStyle, inputStyle, selectStyle } from "../styles";
import styles from "./index.module.css";
import { colors } from "../../../../assets/styles/config";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { db } from "../../../../firebase";
import { NoCollections } from "../../../Experiences/styles";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
} from "../../../../components";
import { IoLocationOutline } from "react-icons/io5";
import { useDownloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { Card, CloseBtn, Container } from "../styles";
import Select from "react-select";
import TrashIcon from "../../../../assets/images/trash-2.svg";
import { useDropzone } from "react-dropzone";
import {
 uploadBytesResumable,
 getDownloadURL,
 ref as storeRef,
} from "firebase/storage";
import { storage } from "../../../../firebase";
import { toast } from "react-hot-toast";
import { FaTrash } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import MyCheckbox from "../../../../components/Checkbox/index";
import Warning from "../../../../assets/images/warning-icon.svg";
// import moment from "moment";

const validationSchema = Yup.object({
 name: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("Name is required"),
 email: Yup.string()
  .email("Invalid email address")
  .required("Email is required"),
 // phoneNumber: Yup.string()
 //   .matches(
 //     /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
 //     "Invalid phone number"
 //   )
 //   .required("Phone number is Required"),
 // password: Yup.string()
 //   .min(8, "Password must be at least 8 characters")
 //   .required("Password is required"),
 // confirmPassword: Yup.string()
 //   .oneOf([Yup.ref("password"), null], "Password does not match!")
 //   .required("Password is required"),
});

const Administrator = () => {
 const { user, addAdmin, updateAdministrator, deleteAdministrator } =
  useContext(AuthContext);
 const [loading, setLoading] = useState(false);
 const [administrator, setAdministrator] = useState([]);
 const [filterAdministrator, setFilterAdministrator] = useState([]);
 const tableRef = useRef(null);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [isChecked, setisChecked] = useState(true);
 const [checkSelect, setCheckSelect] = useState("");
 const [modalType, setModalType] = useState("");
 const [showPassword, setShowPassword] = useState(false);
 const [showConfirmPassword, setShowConfirmPassword] = useState(false);
 const [imageUrl, setImageUrl] = useState("");
 const [media, setMedia] = useState(null);
 const [error, setError] = useState("");
 const [search, setSearch] = useState("");
 const [deleteId, setDeleteId] = useState("");
 const [partnerOption, setPartnerOption] = useState([]);
 const [roles, setRoles] = useState(null);
 const rolesDB = ref(db, "cityExperience/roles");
 const navigate = useNavigate();

 // const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
 //   useDropzone({
 //     onDrop: (acceptedFiles) => {
 //       setMedia(
 //         acceptedFiles.map((file) =>
 //           Object.assign(file, {
 //             preview: URL.createObjectURL(file),
 //           })
 //         )
 //       );
 //     },
 //     accept: "image/jpeg, image/png, video/gif, video/mp4",
 //     // Disable click and keydown behavior
 //     noClick: true,
 //     noKeyboard: true,
 //     maxSize: 10000000,
 //     minSize: 0,
 //   });

 // const filterObject = (obj) =>
 //   Object.keys(obj).item?.experiencesitem?.experiencesitem?.experiences(
 //     (acc, val) =>
 //       obj[val]["role"] === "SUPER-ADMIN"
 //         ? {
 //             ...acc,
 //             [val]: obj[val],
 //           }
 //         : obj[val]["role"] === "ADMIN"
 //         ? {
 //             ...acc,
 //             [val]: obj[val],
 //           }
 //         : acc,
 //     {}
 //   );
 // const filterObject = (roles) =>
 //   Object.keys(obj).reduce(
 //     (acc, val) =>
 //       obj[val]["role"] === roles
 //         ? {
 //             ...acc,
 //             [val]: obj[val],
 //           }
 //         : acc,

 //     {}
 //   );

 const filterObjectPartner = (obj) =>
  Object.keys(obj).reduce(
   (acc, val) =>
    obj[val]["role"] === "PARTNER"
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 const fetchDatabase = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `users/admin`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     let myObj = snapshot.val();

     var array = Object.keys(myObj).map((key) => {
      return myObj[key];
     });

     /*  var sortedData = array.sort(function (a, b) {
            return Number(a.id.slice(3)) - Number(b.id.slice(3));
          }); */
     var sortedData = array
      ?.map((obj) => {
       return { ...obj, date: new Date(JSON.parse(obj.created_at)) };
      })
      .sort((a, b) => b.date - a.date);

     setAdministrator(sortedData);

     const myObjPartner = filterObjectPartner(snapshot.val());
     setPartnerOption(
      Object.keys(myObjPartner)
       ?.map((key) => {
        return {
         label: myObjPartner[key].name,
         value: myObjPartner[key].name,
         status: myObjPartner[key].status,
        };
       })
       .filter((item) => item?.status === "APPROVED")
     );
    } else {
     //  console.log("No data available");
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  //get users order

  return db;
 };

 useEffect(() => {
  if (user) {
   fetchDatabase();
  }
 }, [user, roles]);

 useEffect(() => {
  setFilterAdministrator(administrator);
 }, [administrator]);

 /*  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Orders table",
    sheet: "Orders",
  });

  const csvHeader = [
    { label: "Date", key: "date" },
    { label: "Experience Name", key: "experience" },
    { label: "Ticket Token", key: "ticket_token" },
    { label: "Status", key: "status" },
  ];

  const csvData = Object.keys(administrator || {})?.map((item) => {
    return {
      date: administrator[item]?.date,
      experience: administrator[item].experience,
      ticket_token: administrator[item].token,
      status: administrator[item].status,
    };
  }); */

 // console.log("experience", Object.keys(orders));

 const partnerOptions = [
  { value: "koi", label: "koi" },
  { value: "Bature Brewery", label: "Bature Brewery" },
 ];

 // const roles = [
 //   { value: "SUPER-ADMIN", label: "Super-Admin" },
 //   { value: "ADMIN", label: "Admin" },
 //   { value: "WRITER", label: "Writer" },
 // ];
 useEffect(() => {
  // setLoading(true);
  const unsubscribe = onValue(rolesDB, async (snapshot) => {
   const roles = snapshot.val();

   setRoles(
    roles?.map((item) => {
     return {
      value: item?.role,
      label: item?.role,
     };
    })
   );
  });
  return () => {
   unsubscribe();
  };
 }, []);

 // const access = [
 //   { value: "Dashboard", label: "Dashboard" },
 //   { value: "Nav Mgt", label: "Nav Mgt" },
 //   { value: "Collections", label: "Collections" },
 //   { value: "Navi Journal", label: "Navi Journal" },
 //   { value: "Advertising", label: "Advertising" },
 //   { value: "Partners reg", label: "Partners reg" },
 //   { value: "Access mgt", label: "Access mgt" },
 //   { value: "Partners exp", label: "Partners exp" },
 //   { value: "Deals", label: "Deals" },
 //   { value: "Reviews", label: "Reviews" },
 // ];

 const handleUpload = async (file, data) => {
  toast.loading("loading");

  const storageRef = storeRef(storage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
   "state_changed",
   (snapshot) => {},
   (err) => {},
   async () => {
    // download url
    await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
     const payload = {
      ...data,
      photoURL: url,
     };

     const res = await addAdmin(payload);
     if (res) {
      setVisible(!visible);
      fetchDatabase();
     }
    });
   }
  );
 };

 //switch handler
 const onChangeHandle = async (e, item) => {
  // index === checkSelect && setisChecked(!isChecked);
  setAdministrator(
   administrator.map((itm) =>
    itm.uid === item.uid ? { ...item, enabled: e.target.checked } : itm
   )
  );
  toast.loading("loading");
  await updateAdministrator({
   ...item,
   enabled: e.target.checked,
  }).then((res) => {
   // toast.success("Profile successful updated");
  });
 };

 const searchFilter = (text) => {
  if (text) {
   setSearch(text);
   const newData = administrator.filter((item) => {
    const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setFilterAdministrator(newData);
  } else {
   setSearch(text);
   setFilterAdministrator(administrator);
  }
 };

 const handleDelete = async () => {
  //  console.log("handleDelete", deleteId);
  const res = await deleteAdministrator(deleteId);
  if (res) {
   setVisible(!visible);
   setDeleteId("");
   fetchDatabase();
  }
 };

 const truncateString = (string, maxLength) =>
  string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

 const random = (length) => {
  const characters =
   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
  let password = "";

  for (let i = 0; i < length; i++) {
   const randomIndex = Math.floor(Math.random() * characters.length);
   password += characters.charAt(randomIndex);
  }

  return password;
 };

 return (
  <Box height="100%" padding="20px" background="#F5F8FA">
   {/* <Text
        color="#6C6B6B"
        fontSize={{ base: "18px", md: "20px", lg: "24px" }}
        lineHeight="32px"
        fontWeight="600"
      >
        Administrator
      </Text> */}

   <Box
    background="#FFFFFF"
    borderRadius="6px"
    // mt="30px"
    minHeight="80vh"
    padding="30px"
   >
    {/* <Text
          color="#334155"
          fontSize={{ base: "18px", md: "20px" }}
          lineHeight="24px"
          fontWeight="700"
          marginBottom="20px"
        >
          Team members
        </Text> */}
    <Flex
     alignItems="flex-start"
     flexDir={"row"}
     justifyContent="space-between"
    >
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>

     <Box display={{ base: "none", md: "flex" }} alignItems="center">
      <CustomButton
       animateOnTap
       animateOnHover
       buttonColor="#632EFF"
       css={css`
        width: fit-content;
        padding: 0.5rem 2rem;
        height: auto;
        font-weight: 500;
        font-family: "Creato Display";
        display: flex;
        gap: 15px;

        @media screen and (max-width: 768px) {
         border-radius: 10px;
        }
       `}
       onClick={() => {
        setVisible(!visible);
        setModalType("add");
       }}
      >
       <AddIcon h="16px" w="16px" /> Add Team Member
      </CustomButton>
     </Box>
     <Box display={{ base: "flex", md: "none" }} alignItems="center">
      <CustomButton
       animateOnTap
       animateOnHover
       buttonColor="#632EFF"
       css={css`
        height: auto;
        font-weight: 500;
        font-family: "Creato Display";
        border-radius: 100px;
       `}
       onClick={() => {
        setVisible(!visible);
        setModalType("add");
       }}
      >
       <AddIcon h="16px" w="16px" />
      </CustomButton>
     </Box>
    </Flex>
    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt="30px">
      <TableContainer
       border="1px solid #E2E8F0"
       borderRadius="16px 16px 0px 0px"
      >
       <Table variant="simple" ref={tableRef}>
        <Thead>
         <Tr>
          {/* <Th
                      fontFamily="Satoshi"
                      color="#252046"
                      fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                      lineHeight="125%"
                      fontWeight="700"
                      padding="10px 20px"
                    >
                      ADMIN ID
                    </Th> */}
          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          >
           Name
          </Th>
          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          >
           Email Address
          </Th>
          {/* <Th
                      fontFamily="Satoshi"
                      color="#252046"
                      fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                      lineHeight="125%"
                      fontWeight="700"
                      padding="14px 20px"
                    >
                      PHONE
                    </Th> */}
          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          >
           Role
          </Th>
          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          >
           Access to
          </Th>
          {/* <Th
                      color="#6C6B6B"
                      fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                      lineHeight="140%"
                      fontWeight="500"
                      padding="10px"
                    >
                      LAST LOGIN
                    </Th> */}
          <Th
           fontFamily="Satoshi"
           color="#252046"
           fontSize={{ base: "12px", md: "14px", lg: "16px" }}
           lineHeight="125%"
           fontWeight="700"
           padding="14px 20px"
           textTransform="capitalize"
          ></Th>
         </Tr>
        </Thead>
        <Tbody>
         {filterAdministrator?.map((item, index) => (
          <Tr
           key={index}
           cursor="pointer"
           _hover={{
            background: "#F1F5F9",
           }}
          >
           {/* <Td
                        padding="10px"
                        fontSize={{ base: "12px" }}
                        lineHeight="20px"
                        fontWeight="400"
                        color="#6C6B6B"
                      >
                        {truncateString(item?.uid, 8)}
                      </Td> */}
           <Td
            padding="14px 20px"
            fontSize={{ base: "14px" }}
            lineHeight="125%"
            fontWeight="500"
            fontFamily="Satoshi"
            color={item.enabled ? "#334155" : "#64748B"}
            onClick={() => navigate(`/admin/administrator/view/${item.uid}`)}
           >
            {item?.name}
           </Td>
           <Td
            padding="14px 20px"
            fontSize={{ base: "14px" }}
            lineHeight="125%"
            fontWeight="500"
            fontFamily="Satoshi"
            color={item.enabled ? "#334155" : "#64748B"}
            onClick={() => navigate(`/admin/administrator/view/${item.uid}`)}
           >
            {item?.email}
           </Td>
           {/* <Td
                        padding="10px"
                        fontSize={{ base: "12px" }}
                        lineHeight="20px"
                        fontWeight="400"
                        color="#B5B5B5"
                      >
                        {item?.phoneNumber}
                      </Td> */}
           <Td
            padding="14px 20px"
            fontSize={{ base: "14px" }}
            lineHeight="125%"
            fontWeight="500"
            fontFamily="Satoshi"
            color={item.enabled ? "#334155" : "#64748B"}
            onClick={() => navigate(`/admin/administrator/view/${item.uid}`)}
           >
            {item?.role}
           </Td>
           <Td
            padding="14px 20px"
            fontSize={{ base: "14px" }}
            lineHeight="125%"
            fontWeight="500"
            fontFamily="Satoshi"
            color={item.enabled ? "#334155" : "#64748B"}
           >
            All access
           </Td>
           {/* <Td
                        padding="10px"
                        fontSize={{ base: "12px" }}
                        lineHeight="20px"
                        fontWeight="400"
                        color="#B5B5B5"
                      >
                        {dayjs().format("DD/MM/YYYY")}
                      </Td> */}
           <Td
            padding="14px 20px"
            display="flex"
            alignItems="center"
            gap="20px"
           >
            {/* <Link to={`/admin/administrator/view/${item.uid}`}>
                          <Box
                            bg="#EBF7FF"
                            borderRadius="6px"
                            p="8px"
                            cursor="pointer"
                          >
                            <AiFillEye color="#25AEFC" />
                          </Box>
                        </Link>
                        <Link to={`/admin/administrator/edit/${item.uid}`}>
                          <Box
                            bg="#D0F1D7"
                            borderRadius="6px"
                            p="8px"
                            cursor="pointer"
                          >
                            <MdModeEditOutline color="#1AAB65" />
                          </Box>
                        </Link> */}

            <Box
            // bg="#EBF7FF"
            // borderRadius="6px"
            // p="8px"
            // cursor="pointer"
            >
             <Switch
              size="sm"
              color="#25AEFC"
              isChecked={item.enabled}
              onChange={(e) => {
               onChangeHandle(e, item);
              }}
              colorScheme="purple"
             />
            </Box>
            <Box
             // bg="#F2B8B5"
             // borderRadius="6px"
             // p="8px"
             cursor="pointer"
             onClick={() => {
              setVisible(!visible);
              setModalType("edit");
              setDeleteId(item.uid);
             }}
            >
             <Image src={TrashIcon} />
            </Box>
           </Td>
          </Tr>
         ))}
        </Tbody>
       </Table>
      </TableContainer>
     </Box>
    )}
   </Box>
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
     // style={{ marginTop: modalType === "add" && "50px" }}
    >
     {modalType === "add" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ height: "100%", padding: "2rem" }}
      >
       <Box
        // borderBottom="1px solid #B8B8B8"
        padding="20px 0px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
       >
        <Text
         fontFamily="Satoshi"
         color="#0F0931"
         fontSize={{ base: "14px", md: "20px", lg: "28px" }}
         lineHeight="125%"
         fontWeight="700"
        >
         Add team member
        </Text>
        <Box style={{ cursor: "pointer" }} onClick={() => setVisible(false)}>
         <AiOutlineCloseCircle fontSize="30px" />
        </Box>
       </Box>
       <Box padding="20px 0px" display="flex" flexDir="column">
        <Formik
         initialValues={{
          name: "",
          // assignedPartner: "",
          email: "",
          // phoneNumber: "",
          role: "",
          // password: "",
          // access: "",
         }}
         onSubmit={async (values) => {
          // const lastId = administrator.pop()?.id.slice(3); `NAV${Number(lastId) + 1}
          // values.assignedPartner.map(
          //   (item) => item.value
          // ),
          const payload = {
           ...values,
           assignedPartner: "",
           role: values.role.value,
           enabled: true,
           created_at: JSON.stringify(dayjs(Date.now())),
           photoURL: "",
           phoneNumber: "",
           password: random(8),
          };

          const res = await addAdmin(payload);
          if (res) {
           setVisible(!visible);
           fetchDatabase();
          }
          // if (media !== null) {
          //   await handleUpload(media[0], payload);
          // } else {
          //   setError("Display picture is required");
          //   setTimeout(() => {
          //     setError("");
          //   }, 3000);
          // }
         }}
         validationSchema={validationSchema}
        >
         {({
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          values,
         }) => {
          return (
           <form onSubmit={handleSubmit}>
            {/* <Box>
                          <Text
                            color={colors.darkBlue}
                            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                            lineHeight="24px"
                            fontWeight="500"
                          >
                            Avatar
                          </Text>
                          <Box
                            position="relative"
                            {...getRootProps()}
                            alignItems="flex"
                            display="flex"
                            justifyContent="center"
                          >
                            <input {...getInputProps()} />
                            <Img
                              src={media ? media[0]?.preview : usericon}
                              alt="avatar"
                              height="100px"
                              width="100px"
                              cursor="pointer"
                              borderRadius="100%"
                            />
                            <Img
                              src={EditImgIcon}
                              position="absolute"
                              top="0"
                              right="170px"
                              cursor="pointer"
                              onClick={open}
                            />
                          </Box>
                          {error && (
                            <p
                              style={{
                                color: "#E53E3E",
                                marginTop: "0.5rem",
                                fontSize: "0.8rem",
                              }}
                            >
                              {error}
                            </p>
                          )}
                        </Box> */}
            <FormControl
             w="100%"
             display="flex"
             flexDir="column"
             alignItems="flex-start"
             mb="20px"
             isRequired
             isInvalid={!!errors.name && touched.name}
            >
             <FormLabel
              fontFamily="Satoshi"
              color="#0F0931"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="21px"
              fontWeight="700"
             >
              Name
             </FormLabel>
             <Field
              as={Input}
              {...inputStyle}
              type="text"
              placeholder="name"
              id="name"
              name="name"
             />
             <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            {/* <FormControl
                          w="100%"
                          display="flex"
                          flexDir="column"
                          alignItems="flex-start"
                          mb="20px"
                          isRequired
                          isInvalid={
                            !!errors.assignedPartner && touched.assignedPartner
                          }
                        >
                          <FormLabel
                            color={colors.darkBlue}
                            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                            lineHeight="24px"
                            fontWeight="500"
                          >
                            Assigned Partners
                          </FormLabel>
                          <Select
                            isMulti
                            name="assignedPartner"
                            id="assignedPartner"
                            value={values.assignedPartner}
                            onChange={(option) =>
                              setFieldValue("assignedPartner", option)
                            }
                            options={partnerOption}
                            onBlur={handleBlur}
                            // defaultValue={experiences[0]?.experienceArray}
                          />
                          <FormErrorMessage>
                            {errors.assignedPartner}
                          </FormErrorMessage>
                        </FormControl> */}
            <FormControl
             w="100%"
             display="flex"
             flexDir="column"
             alignItems="flex-start"
             mb="20px"
             isRequired
             isInvalid={!!errors.email && touched.email}
            >
             <FormLabel
              fontFamily="Satoshi"
              color="#0F0931"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="21px"
              fontWeight="700"
             >
              Email
             </FormLabel>
             <Field
              as={Input}
              {...inputStyle}
              type="email"
              placeholder="email"
              id="email"
              name="email"
             />
             <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            {/* <FormControl
                          w="100%"
                          alignItems="center"
                          mb="10px"
                          isRequired
                          isInvalid={
                            !!errors.phoneNumber && touched.phoneNumber
                          }
                        >
                          <FormLabel
                            fontFamily="Satoshi"
                            color="#0F0931"
                            fontSize={{ base: "12px", md: "14px" }}
                            lineHeight="21px"
                            fontWeight="700"
                          >
                            Phone Number
                          </FormLabel>
                          <Field
                            as={Input}
                            {...inputStyle}
                            type="number"
                            id="phoneNumber"
                            name="phoneNumber"
                          />
                          <FormErrorMessage>
                            {errors.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          w="100%"
                          alignItems="center"
                          mb="10px"
                          isInvalid={!!errors.password && touched.password}
                          isRequired
                        >
                          <FormLabel
                            fontFamily="Satoshi"
                            color="#0F0931"
                            fontSize={{ base: "12px", md: "14px" }}
                            lineHeight="21px"
                            fontWeight="700"
                            mb="10px"
                          >
                            Password
                          </FormLabel>
                          <InputGroup>
                            <Field
                              as={Input}
                              {...inputStyle}
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                            />
                            <InputRightElement width="3rem">
                              <Box
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <Img src={HideImg} alt="hide" />
                                ) : (
                                  <Img src={ShowImg} alt="show" />
                                )}
                              </Box>
                            </InputRightElement>
                          </InputGroup>

                          <FormErrorMessage>{errors.password}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                          w="100%"
                          alignItems="center"
                          mb="10px"
                          isInvalid={
                            !!errors.confirmPassword && touched.confirmPassword
                          }
                          isRequired
                        >
                          <FormLabel
                            fontFamily="Satoshi"
                            color="#0F0931"
                            fontSize={{ base: "12px", md: "14px" }}
                            lineHeight="21px"
                            fontWeight="700"
                            mb="10px"
                          >
                            Confirm Password
                          </FormLabel>

                          <InputGroup>
                            <Field
                              as={Input}
                              {...inputStyle}
                              type={showConfirmPassword ? "text" : "password"}
                              id="confirmPassword"
                              name="confirmPassword"
                            />
                            <InputRightElement width="3rem">
                              <Box
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              >
                                {showConfirmPassword ? (
                                  <Img src={HideImg} alt="hide" />
                                ) : (
                                  <Img src={ShowImg} alt="show" />
                                )}
                              </Box>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {errors.confirmPassword}
                          </FormErrorMessage>
                        </FormControl> */}
            <FormControl
             w="100%"
             display="flex"
             flexDir="column"
             alignItems="flex-start"
             mb="20px"
             isRequired
             isInvalid={!!errors.role && touched.role}
            >
             <FormLabel
              fontFamily="Satoshi"
              color="#0F0931"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="21px"
              fontWeight="700"
             >
              Role
             </FormLabel>
             <Select
              name="role"
              id="role"
              value={values.role}
              onChange={(option) => setFieldValue("role", option)}
              options={roles}
              onBlur={handleBlur}
              styles={{
               control: (provided, state) => ({
                ...provided,
                borderRadius: "66px",
                background: "#F1F5F9",
                autoFocus: true,
                "&:hover": {
                 background: "#F3F0FF",
                 border: "1px solid #AC98FE",
                },
                "&:focus": {
                 background: "#F3F0FF",
                },
                "&:focusVisible": {
                 border: "1px solid #AC98FE",
                },
               }),
              }}
             />
             <FormErrorMessage>{errors.role}</FormErrorMessage>
            </FormControl>
            {/* <FormControl
                          w="100%"
                          display="flex"
                          flexDir="column"
                          alignItems="flex-start"
                          mb="20px"
                        >
                          <FormLabel
                            fontFamily="Satoshi"
                            color="#0F0931"
                            fontSize={{ base: "12px", md: "14px" }}
                            lineHeight="21px"
                            fontWeight="700"
                          >
                            Access to
                          </FormLabel>
                          <SimpleGrid
                            background="#F1F5F9"
                            borderRadius="32px "
                            padding="28px"
                            // display="flex"
                            // flexDir="column"
                            //role="group"
                            // aria-labelledby="checkbox-group"
                            columns={{ base: 1, md: 3, lg: 4 }}
                            gap="20px"
                          >
                            {access?.map((item) => (
                              <Field
                                component={MyCheckbox}
                                name="access"
                                value={item.value}
                                label={item.label}
                              />
                            ))}
                          </SimpleGrid>
                        </FormControl> */}
            <Box
             width="100%"
             marginTop={10}
             // gap="20px"
            >
             {/* <Button
                            color="#979797"
                            bg="#EEF3F7"
                            borderRadius={28}
                            fontWeight={500}
                            fontSize={18}
                            p="20px"
                            onClick={() => {
                              setVisible(false);
                            }}
                          >
                            Discard
                          </Button> */}
             <Button
              color="#ffffff"
              bg={colors.primaryPurple}
              borderRadius={28}
              fontWeight={500}
              fontSize={18}
              p="24px 20px"
              type="submit"
              width="100%"
             >
              Invite staff
             </Button>
            </Box>
           </form>
          );
         }}
        </Formik>
       </Box>
      </Card>
     )}
     {modalType === "edit" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Team member?
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you would like to delete this administrator?
        </Text>

        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setDeleteId("");
          }}
         >
          Cancel
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default Administrator;
