import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 Text,
 Input,
 Table,
 Thead,
 Tr,
 Tbody,
 Image,
 Center,
} from "@chakra-ui/react";
import { searchInputStyle } from "../../../Admin/Dashboard/styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { AuthContext } from "../../../../context/AuthContext";
import ExportIcon from "../../../../assets/images/export1.svg";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import { getDatabase, ref, child, get } from "firebase/database";
import { LoadingIndicator, EmptyState } from "../../../../components";
import { CSVLink } from "react-csv";
import { TbCurrencyNaira } from "react-icons/tb";
import {
 BoughtInputGroup,
 BoughtInputLeftElement,
 BoughtTableContainer,
 ExportButton,
 InnerWrapper,
 ItemsCenter,
 SearchWrapper,
 TD,
 TH,
} from "../../../Admin/Dashboard/Partner/experience/elements";
import {
 BoughtActiveText,
 BoughtListWrapper,
 Column,
 FollowInnerWrapper,
 Wrapper,
} from "./elements";

const PartnerBoughtExperiences = () => {
 const { user } = useContext(AuthContext);
 const params = useParams();
 const expId = params?.expId;
 const [boughtList, setBoughtList] = useState([]);
 const tableRef = useRef(null);
 const [loading, setLoading] = useState(false);
 const navigate = useNavigate();

 const fetchTicketDatabase = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  const db = await get(child(dbRef, `tickets`)).then((snapshot) => {
   if (snapshot.exists()) {
    const uid = user?.partnerUid ?? user?.uid;
    const myObj = snapshot.val();
    var array = Object.keys(myObj)
     .map((key) => {
      return myObj[key];
     })
     .filter((item) => item?.partnerId === uid);

    let filterArray = Object.keys(array)
     .map((key) => {
      return array[key];
     })
     ?.filter((item) => Number(item?.experienceId) === Number(expId));

    const groupedItems = {};

    for (const item of filterArray) {
     if (
      Object.prototype.hasOwnProperty.call(groupedItems, item?.user?.email)
     ) {
      // groupedItems[item?.user.uid].total += item.total;
      groupedItems[item?.user?.email].count++;
     } else {
      groupedItems[item?.user?.email] = {
       ...item,
       count: 1,
      };
     }
    }

    const result = Object.values(groupedItems);
    setBoughtList(result);
    setLoading(false);
   }
  });

  return db;
 };

 const truncateString = (string, maxLength) =>
  string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

 useEffect(() => {
  fetchTicketDatabase();
 }, [expId, user]);

 const searchFilter = (_text) => {};

 const csvData = boughtList?.map((item) => {
  return {
   uid: item.user.uid,
   name: item.user.name,
   email: item.user.email,
   bought: item.count,
   amount: item.total,
  };
 });

 const csvHeader = [
  { label: "Navi Id", key: "uid" },
  { label: "Name", key: "name" },
  { label: "Email address", key: "email" },
  { label: "Tickets bought", key: "bought" },
  { label: "Amount", key: "amount" },
 ];

 return (
  <Wrapper>
   <FollowInnerWrapper onClick={() => navigate(-1)}>
    <Image src={ArrowLeft} />
    <Text color="#252046" marginLeft="10px">
     Go Back
    </Text>
   </FollowInnerWrapper>
   <InnerWrapper padding={{ base: "10px", md: "30px" }}>
    {/* <Text
          fontFamily="Satoshi"
          color="#252046"
          fontSize="18px"
          lineHeight="125%"
          fontWeight="700"
          marginBottom="20px"
        >
          {user?.businessName}
        </Text> */}
    <SearchWrapper display={{ base: "none", md: "flex" }}>
     <BoughtInputGroup>
      <BoughtInputLeftElement>
       <SearchIcon color="gray.300" />
      </BoughtInputLeftElement>
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </BoughtInputGroup>
     <ItemsCenter>
      <CSVLink data={csvData} headers={csvHeader}>
       <ExportButton>
        <Image src={ExportIcon} /> Export
       </ExportButton>
      </CSVLink>
     </ItemsCenter>
    </SearchWrapper>

    {loading ? (
     <Center mt="40px">
      <LoadingIndicator />
     </Center>
    ) : (
     <Box mt={{ md: "30px" }}>
      {boughtList?.length > 0 ? (
       <>
        <BoughtTableContainer display={{ base: "none", md: "block" }}>
         <Table variant="simple" ref={tableRef}>
          <Thead>
           <Tr>
            {[
             "Navi Id",
             "Name",
             "Email address",
             "Phone Number",
             "Tickets bought",
             "Amount",
            ].map((i) => (
             <TH key={i}>{i}</TH>
            ))}
           </Tr>
          </Thead>
          <Tbody>
           {boughtList?.map((item, index) => {
            const { user } = item;
            const items = [
             truncateString(item?.user?.uid, 8) ?? "Guest",
             user.name,
             user.email,
             "0" + user.phoneNumber,
             item.count,
            ];
            return (
             <Tr
              key={index}
              cursor="pointer"
              _hover={{
               background: "#F1F5F9",
              }}
             >
              {items.map((i) => (
               <TD key={i}>{i}</TD>
              ))}
              <TD display="flex" gap="5px">
               <TbCurrencyNaira />
               {item.total}
              </TD>
             </Tr>
            );
           })}
          </Tbody>
         </Table>
        </BoughtTableContainer>
        <Box display={{ base: "block", md: "none" }}>
         {boughtList?.map((item, index) => (
          <BoughtListWrapper
           key={index}
           borderBottom={
            boughtList?.length - 1 !== index && "1px solid #E2E8F0"
           }
          >
           <Column alignItems="flex-start">
            <BoughtActiveText marginBottom="10px">
             {item.user.name}
            </BoughtActiveText>
            <ItemsCenter gap="10px">
             <BoughtActiveText>{item.user.email}</BoughtActiveText> |{" "}
             <BoughtActiveText>{item.count} tickets</BoughtActiveText>|{" "}
             <BoughtActiveText display="flex">
              <TbCurrencyNaira />
              {item.total}
             </BoughtActiveText>
            </ItemsCenter>
           </Column>
          </BoughtListWrapper>
         ))}
        </Box>
       </>
      ) : (
       <EmptyState text={"No experiences have been bought."} />
      )}
     </Box>
    )}
   </InnerWrapper>
  </Wrapper>
 );
};

export default PartnerBoughtExperiences;
