import Indicator from "../LoadingIndicator";

const Loader = () => {
  return (
    <div
      data-animation="preloader"
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Indicator />
    </div>
  );
};

export default Loader;
