export const collections = [
  {
    text: '5 Hangout Spots in Lagos',
    img: 'collections1.jpeg',
  },
  {
    text: 'A weekend in Lagos',
    img: 'collections2.jpeg',
  },
  {
    text: 'Sushi Spots in Lagos',
    img: 'collections3.jpeg',
  },
  {
    text: '5 Cafés in Lagos',
    img: 'collections4.jpeg',
  },
  {
    text: '5 Hangout Spots in Lagos',
    img: 'collections5.jpeg',
  },
  {
    text: 'A weekend in Lagos',
    img: 'collections6.jpeg',
  },
  {
    text: 'Sushi Spots in Lagos',
    img: 'collections7.jpeg',
  },
  {
    text: '5 Cafés in Lagos',
    img: 'collections8.jpeg',
  },
  {
    text: '5 Cafés in Lagos',
    img: 'collections9.jpeg',
  },
  {
    text: '5 Cafés in Lagos',
    img: 'collections10.jpeg',
  },
  {
    text: '5 Cafés in Lagos',
    img: 'collections11.jpeg',
  },
  {
    text: '5 Cafés in Lagos',
    img: 'collections12.jpeg',
  },
];

export const navigation = [
  { name: 'useful info', url: '#useful-info' },
  { name: 'collections', url: '#collections' },
  { name: 'experiences', url: '#experience' },
  { name: 'the blog', url: '#the-blog' },
];

export const faq = [
  {
    title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit ?',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod impedit cupiditate asperiores recusandae ipsa? Beatae in deleniti repudiandae incidunt laborum aliquam officiis similique officia explicabo magni nostrum laboriosam asperiores, aut nobis et voluptate fugit facere praesentium exercitationem fuga vero. Expedita aliquid voluptate nisi eum alias itaque praesentium, et a nesciunt!',
  },
  {
    title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit ?',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod impedit cupiditate asperiores recusandae ipsa? Beatae in deleniti repudiandae incidunt laborum aliquam officiis similique officia explicabo magni nostrum laboriosam asperiores, aut nobis et voluptate fugit facere praesentium exercitationem fuga vero. Expedita aliquid voluptate nisi eum alias itaque praesentium, et a nesciunt!',
  },
  {
    title: 'Lorem, ipsum dolor sit amet consectetur ?',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod impedit cupiditate asperiores recusandae ipsa? Beatae in deleniti repudiandae incidunt laborum aliquam officiis similique officia explicabo magni nostrum laboriosam asperiores, aut nobis et voluptate fugit facere praesentium exercitationem fuga vero. Expedita aliquid voluptate nisi eum alias itaque praesentium, et a nesciunt!',
  },
];

export const socialMediaLinks = [
  {
    name: 'facebook',
    icon: 'facebookIcon',
    url: 'https://www.facebook.com/naviafrica',
  },
  {
    name: 'twitter',
    icon: 'twitterIcon',
    url: 'https://twitter.com/thenaviapp',
  },
  {
    name: 'instagram',
    icon: 'instagramIcon',
    url: 'https://www.instagram.com/thenaviapp/',
  },
  {
    name: 'linkedin',
    icon: 'linkedinIcon',
    url: 'https://www.linkedin.com/company/thenaviapp',
  },
  {
    name: 'youtube',
    icon: 'youtubeIcon',
    url: 'https://www.youtube.com/channel/UCsboYztshW5KiiNTNM0BPHQ',
  },
];
