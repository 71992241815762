import { css } from '@emotion/react';
import styled from 'styled-components';
import { colors, fontWeight } from '../../assets/styles/config';

export const Heading = styled.h1`
  font-weight: ${fontWeight.semiBold};
  color: ${colors.darkBlue};
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 50px;
    line-height: 75px;
    margin-bottom: 30px;
  }
  ${(props) => props.css || ''}
`;

export const ExpandBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: ${fontWeight.medium};
  color: ${colors.blue};
  font-size: 18px;
  line-height: 33px;
  text-transform: capitalize;
  transition: 0.2s ease;
  width: fit-content;

  &:hover {
    scale: 0.95;
    opacity: 0.8;
  }

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 33px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 22px;
    line-height: 33px;
  }

  ${(props) => props.css || ''}
`;

export const FaintBrownText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: ${colors.faintBrown};

  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media screen and (min-width: 1080px) {
    font-size: 24px;
    line-height: 36px;
  }
  ${(props) => props.css || ''}
`;

export const Main = styled.div`
  background-image: url(${(props) => props.bgImage || ''});
  background-color: ${colors.skyBlue};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
