import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 Button,
 List,
 ListItem,
 SimpleGrid,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Textarea,
 Image,
 RadioGroup,
 Radio,
 Flex,
 Switch,
 Checkbox,
 useCheckbox,
 useCheckboxGroup,
 chakra,
 InputGroup,
 InputLeftElement,
} from "@chakra-ui/react";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { inputStyle, searchInputStyle } from "../../../Admin/Dashboard/styles";
// import { Button } from "../../../../components";
import { colors } from "../../../../assets/styles/config";
import Cloud from "../../../../assets/images/cloud-plus.svg";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import usericon from "../../../../assets/images/user.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { bytesToSize } from "../../../../utils/index";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import {
 getDatabase,
 set,
 ref as databaseRef,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { db } from "../../../../firebase";
import toast from "react-hot-toast";
import { cf } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import DateRange from "../../../../components/DateRange";
import { CustomCheckbox } from "../../../Partner/Onbording/categoriesTags";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { SliderArrowPath } from "../../../../assets/styles";
import { AiFillFile } from "react-icons/ai";
import dayjs from "dayjs";
import { sendPartnerPushNotification } from "../../../../api/user";

const validationSchema = Yup.object({
 firstName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("first name is required"),
 lastName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("last name is required"),
 phoneNumber: Yup.string()
  .matches(
   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
   "Invalid phone number"
  )
  .required("Phone number is Required"),
 email: Yup.string()
  .email("Invalid email address")
  .required("Email is required"),

 businessName: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("Business name is required"),
 businessAddress: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("Business address is required"),
 city: Yup.string()
  .min(2, "Too short!")
  .max(50, "Too Long!")
  .required("city is required"),
 businessPhoneNumber: Yup.string()
  .matches(
   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
   "Invalid phone number"
  )
  .required("Business phone number is Required"),
 businessEmail: Yup.string()
  .email("Invalid email address")
  .required("Business email is required"),
});

const Profile = () => {
 const { user, partnerOnboard, updatePartner } = useContext(AuthContext);
 const [imageUrl, setImageUrl] = useState("");
 const [coverUrl, setCoverUrl] = useState("");
 const [filesUrl, setfilesUrl] = useState("");
 const [loading, setLoading] = useState(false);
 const [media, setMedia] = useState(null);
 const [coverMedia, setCoverMedia] = useState(null);
 const [files, setFiles] = useState(null);
 const [error, setError] = useState("");
 const categoryDb = databaseRef(db, "cityExperience/categories");
 const tagDb = databaseRef(db, "cityExperience/tags");
 const [category, setCategory] = useState([]);
 const [tags, setTags] = useState([]);
 const [search, setSearch] = useState("");
 const [searchTag, setSearchTag] = useState([]);
 const navigate = useNavigate();
 const [sameTime, setSameTime] = useState(false);
 const [partner, setPartner] = useState(null);
 const { getCheckboxProps } = useCheckboxGroup({
  defaultValue: [""],
 });

 const fetchPartner = async () => {
  const dbRef = databaseRef(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `users/partner`)).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = snapshot.val();

    const uid = user?.partnerUid ?? user?.uid;
    setPartner(
     Object.keys(myObj)
      .map((key) => {
       return myObj[key];
      })
      ?.filter((item) => item?.uid === uid)[0]
    );
   }
  });

  return db;
 };

 useEffect(() => {
  if (user) {
   fetchPartner();
  }
 }, [user]);

 const searchFilter = (text) => {
  if (text) {
   setSearch(text);
   const newData = tags.filter((item) => {
    const itemData = item ? item.toUpperCase() : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setSearchTag(newData);
  } else {
   setSearch(text);
   setSearchTag(tags);
  }
 };

 const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
 ];
 //var rteObj;

 //console.log("id====", id);
 useEffect(() => {
  if (location.pathname.includes("edit")) {
   setIsEdit(true);
  }
  if (location.pathname.includes("view")) {
   setIsView(true);
  }
 }, [location]);

 useEffect(() => {
  const unsubscribe = onValue(categoryDb, (snapshot) => {
   const data = snapshot.val();
   data.shift();
   setCategory(data);
  });

  return () => unsubscribe();
 }, []);
 useEffect(() => {
  const unsubscribe = onValue(tagDb, (snapshot) => {
   const data = snapshot.val();
   // data.shift();
   setTags(data);
  });

  return () => unsubscribe();
 }, []);

 useEffect(() => {
  if (tags) {
   setSearchTag(tags);
  }
 }, [tags]);

 const random = (length) => {
  return Math.floor(
   Math.pow(10, length - 1) +
    Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
  );
 };

 // const filterObject = (obj) =>
 //   Object.keys(obj).reduce(
 //     (acc, val) =>
 //       obj[val]["role"] === "PARTNER"
 //         ? {
 //             ...acc,
 //             [val]: obj[val],
 //           }
 //         : acc,
 //     {}
 //   );

 // const { setFieldValue, setValues } = useFormik();

 const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
  useDropzone({
   onDrop: (acceptedFiles) => {
    setMedia(
     acceptedFiles.map((file) =>
      Object.assign(file, {
       preview: URL.createObjectURL(file),
      })
     )
    );
   },
   accept: "image/jpeg, image/png, video/gif, video/mp4",
   // Disable click and keydown behavior
   noClick: true,
   noKeyboard: true,
   maxSize: 10000000,
   minSize: 0,
   maxFiles: 6,
  });
 const {
  getRootProps: getRootCoverProps,
  getInputProps: getInputCoverProps,
  isDragActive: isDragActiveCover,
  open: openCover,
  fileRejections: fileRejectionsCover,
 } = useDropzone({
  onDrop: (acceptedFiles) => {
   setCoverMedia(
    acceptedFiles.map((file) =>
     Object.assign(file, {
      preview: URL.createObjectURL(file),
     })
    )
   );
  },
  accept: "image/jpeg, image/png, video/gif, video/mp4",
  // Disable click and keydown behavior
  noClick: true,
  noKeyboard: true,
  maxSize: 10000000,
  minSize: 0,
  maxFiles: 1,
 });

 const {
  getRootProps: getRootFileProps,
  getInputProps: getInputFileProps,
  isDragActive: isDragActiveFile,
  open: openFile,
  fileRejections: fileRejectionsFile,
 } = useDropzone({
  onDrop: (acceptedFiles) => {
   setFiles(
    acceptedFiles.map((file) =>
     Object.assign(file, {
      preview: URL.createObjectURL(file),
     })
    )
   );
  },
  accept:
   "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // Disable click and keydown behavior
  noClick: true,
  noKeyboard: true,
  maxSize: 10000000,
  minSize: 0,
  maxFiles: 6,
 });

 const handleMediaUpload = async (file) => {
  //  toast.loading("loading");
  const storageRef = ref(storage, `/files/${file.name}`);
  await uploadBytesResumable(storageRef, file);
  return await getDownloadURL(storageRef);
 };

 return (
  <Box
   height="100%"
   padding={{ base: "0px 20px", md: "14px 20px" }}
   background="#F5F8FA"
  >
   <Box background={{ md: "#FFFFFF" }} borderRadius="6px" mt={{ md: "14px" }}>
    <Box padding={{ md: "30px" }} mt={{ md: "20px" }}>
     <Formik
      initialValues={{
       businessOpen: "",
       registeringAs: "business",
       establishmentType: "",
       firstName: "",
       lastName: "",
       email: "",
       bio: "",
       phoneNumber: "",
       category: "",
       tags: "",
       businessName: "",
       businessEmail: "",
       businessPhoneNumber: "",
       businessAddress: "",
       city: "",
       instagram: "",
       twitter: "",
       facebook: "",
       tiktok: "",
       linkedin: "",
       website: "",
       day: {
        monday: {
         start: "",
         end: "",
        },
        tuesday: {
         start: "",
         end: "",
        },
        wednesday: {
         start: "",
         end: "",
        },
        thursday: {
         start: "",
         end: "",
        },
        friday: {
         start: "",
         end: "",
        },
        saturday: {
         start: "",
         end: "",
        },
        sunday: {
         start: "",
         end: "",
        },
       },
      }}
      onSubmit={async (values) => {
       toast.loading("loading");
       const coverURL = coverMedia && (await handleMediaUpload(coverMedia[0]));
       let mediaUrls = [];
       var keys = media && Object.keys(media);
       for (var i = 0; i < keys?.length; i++) {
        var key = keys[i];
        const img = media && (await handleMediaUpload(media[key]));
        mediaUrls.push(img);
       }
       let fileUrl = [];
       var keys__ = files && Object.keys(files);
       for (var i = 0; i < keys__?.length; i++) {
        var key = keys__[i];
        const img = files && (await handleMediaUpload(files[key]));
        fileUrl.push(img);
       }

       if (media === null && imageUrl === "") {
        toast.dismiss();
        toast.error("Please upload one image");
       } else if (coverMedia === null && coverUrl === "") {
        toast.dismiss();
        toast.error("Please upload a cover image");
       } else {
        const payload = {
         ...values,
         address: values.businessAddress,
         role: partner?.role,
         category: ["All"].concat(values.category),
         tags: ["All"].concat(values.tags),
         hours:
          values?.registeringAs === "business"
           ? JSON.stringify(values.day)
           : "",
         isSameTime: sameTime,
         link: JSON.stringify({
          facebook: values.facebook,
          linkedin: values.linkedin,
          instagram: values.instagram,
          website: values.website,
          twitter: values.twitter,
          tiktok: values.tiktok,
         }),
         photoURL: mediaUrls?.length > 0 ? mediaUrls : imageUrl,
         coverURL: coverURL ? coverURL : coverUrl,
         fileURL: fileUrl?.length ? fileUrl : filesUrl,
         subscribed: partner?.subscribed,
         status: partner?.status,
         created_at: partner?.created_at,
         enabled: partner?.enabled,
         ...partner,
        };

        await updatePartner({
         ...payload,
         fileURL: payload.fileURL ? payload.fileURL : "",
         updated_at: JSON.stringify(dayjs(Date.now())),
         uid: partner?.uid,
        }).then(async (res) => {
         if (res) {
          let name = user?.firstName
           ? `${user?.firstName} ${user?.lastName}`
           : user?.name;
          await sendPartnerPushNotification({
           title: "partner",
           body: `${name} updated business profile`,
           data: {
            // ...user,
            uid: user?.partnerUid ?? user?.uid,
            route: "/partner/business-profile",
            name: name,
            photoURL: user?.partnerPhotoURL ?? user?.photoURL,
            push_time: Date.now(),
           },
          });
          // fetchDatabase();
         }
        });
       }
      }}
      validationSchema={validationSchema}
     >
      {({
       handleSubmit,
       errors,
       touched,
       setFieldValue,
       handleBlur,
       values,
      }) => {
       useEffect(() => {
        async function setInitialValues() {
         const fields = [
          "firstName",
          "lastName",
          "email",
          "phoneNumber",
          "bio",
          "businessName",
          "businessPhoneNumber",
          "businessEmail",
          "city",
         ];

         if (partner) {
          setFieldValue("registeringAs", "business", false);
          setFieldValue("businessAddress", partner?.address, false);
          setSameTime(partner?.isSameTime);
          fields.forEach((field) => {
           setFieldValue(field, partner[field], false);
          });

          setFieldValue("tags", partner?.tags, false);
          setFieldValue("category", partner?.category, false);
          setFieldValue("facebook", JSON.parse(partner.link).facebook, false);
          setFieldValue("linkedin", JSON.parse(partner.link).linkedin, false);
          setFieldValue("instagram", JSON.parse(partner.link).instagram, false);
          setFieldValue("website", JSON.parse(partner.link).website, false);
          setFieldValue("twitter", JSON.parse(partner.link).twitter, false);
          setFieldValue("tiktok", JSON.parse(partner.link).tiktok, false);

          setImageUrl(partner?.photoURL);
          setCoverUrl(partner?.coverURL);
          setfilesUrl(partner?.fileURL);

          JSON.parse(partner?.hours) &&
           Object.keys(JSON.parse(partner?.hours))?.map((day) => {
            setFieldValue(
             `day.${day}.start`,
             JSON.parse(partner?.hours)[day]?.start
            );
            setFieldValue(
             `day.${day}.end`,
             JSON.parse(partner?.hours)[day]?.end
            );
           });
         }
        }
        setInitialValues();
       }, [partner]);

       useEffect(() => {
        if (sameTime) {
         let foundStart = "";
         let foundEnd = "";

         // Traverse the outer object
         for (const outerKey in values?.day) {
          if (values?.day.hasOwnProperty(outerKey)) {
           const innerObj = values?.day[outerKey];
           const { start, end } = innerObj;

           if (start !== "" && end !== "") {
            foundStart = start;
            foundEnd = end;
            break;
           }

           if (foundStart !== "" && foundEnd !== "") {
            break;
           }
          }
         }

         for (const outerKey in values?.day) {
          if (values?.day.hasOwnProperty(outerKey)) {
           const innerObj = values?.day[outerKey];

           const { start, end } = innerObj;

           if (start === "") {
            setFieldValue(`day.${outerKey}.start`, foundStart);
           }

           if (end === "") {
            setFieldValue(`day.${outerKey}.end`, foundEnd);
           }
          }
         }
        }
       }, [sameTime]);

       return (
        <form onSubmit={handleSubmit}>
         <Field name="registeringAs">
          {({ field, form }) => {
           const { onChange, ...rest } = field;
           return (
            <FormControl
             id="registeringAs"
             isRequired
             isInvalid={!!errors.registeringAs && !!touched.registeringAs}
            >
             <FormLabel
              fontFamily="Satoshi"
              color="#0F0931"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="21px"
              fontWeight="700"
             >
              Type of partner
             </FormLabel>
             <RadioGroup
              {...rest}
              id="registeringAs"
              backgroundColor="#F1F5F9"
              borderRadius="24px"
              padding="16px 24px"
              width={{ base: "100%", md: "400px" }}
              display="flex"
              gap="20px"
             >
              {["business", "individual"].map((value) => (
               <Radio onChange={onChange} value={value} colorScheme="purple">
                {value.toUpperCase()}
               </Radio>
              ))}
             </RadioGroup>
             <FormErrorMessage>{errors.registeringAs}</FormErrorMessage>
            </FormControl>
           );
          }}
         </Field>

         <Box
          display="grid"
          gridTemplateColumns={{ base: "1fr", md: "400px 1fr" }}
          alignItems="center"
          gap="20px"
          marginTop="30px"
          marginBottom={{ base: "20px", md: "30px" }}
         >
          {/* upload cover image */}
          <Box display="flex" height="100%" flexDir="column">
           <Box
            width="100%"
            borderRadius="9px"
            height="100%"
            display="flex"
            flexDirection="column"
            background="#F1F5F9"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            padding="20px"
            {...getRootCoverProps()}
           >
            <Box
             marginBottom="20px"
             display="flex"
             alignItems="center"
             justifyContent="center"
             flexDirection="column"
            >
             <Text
              color="#0F0931"
              fontFamily="Creato Display, Roboto, sans-serif;"
              fontSize="16px"
              lineHeight="135%"
             >
              Upload Logo
             </Text>
             <Text
              width="60%"
              textAlign="center"
              color="#64748B"
              fontFamily="Satoshi"
              fontSize="12px"
              lineHeight="135%"
             >
              JPG, .PNG images are allowed Maximum size of 10mb
             </Text>
            </Box>
            <Box position="relative" width="100%">
             <input {...getInputCoverProps()} />
             {!isDragActiveCover && (
              <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               flexDirection="column"
              >
               {coverMedia ? (
                <Box
                 border="none"
                 width="100%"
                 height="153px"
                 borderRadius="5px"
                 background="#F8FBFF"
                >
                 {" "}
                 <Img
                  src={coverMedia && coverMedia[0]?.preview}
                  alt="avatar"
                  objectFit="cover"
                  width="100%"
                  borderRadius="5px"
                  height="153px"
                  onClick={openCover}
                 />
                </Box>
               ) : (
                <>
                 {coverUrl ? (
                  <Box
                   border="1px dashed #D5DCE3"
                   width="100%"
                   height="153px"
                   borderRadius="5px"
                   background="#F8FBFF"
                  >
                   <Img
                    src={coverUrl}
                    alt="avatar"
                    objectFit="cover"
                    width="100%"
                    borderRadius="5px"
                    height="153px"
                    onClick={openCover}
                   />
                  </Box>
                 ) : (
                  <Box
                   border="1px dashed #D5DCE3"
                   width="100%"
                   height="153px"
                   borderRadius="5px"
                   background="#F8FBFF"
                   display="flex"
                   justifyContent="center"
                   alignItems="center"
                  >
                   <Img src={Cloud} alt="avatar" onClick={openCover} />
                  </Box>
                 )}
                </>
               )}{" "}
               <div>
                {coverMedia?.map((file) => (
                 <p key={file.path}>
                  {file.name.substring(0, 6)}... ({bytesToSize(file.size)}),
                 </p>
                ))}
               </div>
               {/* {!isView && (
                              <Img
                                src={EditIcon}
                                position="absolute"
                                top="0"
                                left="20"
                                cursor="pointer"
                                onClick={open}
                              />
                            )} */}
              </Box>
             )}
            </Box>
            {error && (
             <p
              style={{
               color: "#E53E3E",
               marginTop: "0.5rem",
               fontSize: "0.8rem",
              }}
             >
              {error}
             </p>
            )}
           </Box>
          </Box>
          {/* additional images */}
          <Box
           display="flex"
           height="100%"
           alignItems="center"
           flexDir="column"
          >
           <Box
            width="100%"
            borderRadius="9px"
            height="100%"
            display="flex"
            flexDirection="column"
            background="#F1F5F9"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            padding="20px"
            {...getRootProps()}
           >
            <Box
             marginBottom="20px"
             display="flex"
             alignItems="center"
             justifyContent="center"
             flexDirection="column"
            >
             <Text
              color="#0F0931"
              fontFamily="Creato Display, Roboto, sans-serif;"
              fontSize="16px"
              lineHeight="135%"
             >
              Upload Additional Images
             </Text>
             <Text
              width="60%"
              textAlign="center"
              color="#64748B"
              fontFamily="Satoshi"
              fontSize="12px"
              lineHeight="135%"
             >
              JPG, .PNG images are allowed Maximum size of 10mb
             </Text>
            </Box>

            <input {...getInputProps()} />
            {!isDragActive && (
             <>
              {media ? (
               <>
                {media?.length > 1 ? (
                 <Box position="relative">
                  <Splide
                   className={{
                    gridAutoFlow: "1fr",
                    justifyItems: "center",
                   }}
                   aria-labelledby="Blog"
                   options={{
                    rewind: true,
                    perPage: 1,
                    // gap: "2rem",
                    classes: {
                     arrows: "splide__arrows splide-navigation-arrows",
                     arrow:
                      "splide__arrow splide__arrow__img splide-navigation-arrow",
                     prev: "splide__arrow--prev splide-img-prev",
                     next: "splide__arrow--next splide-img-next",
                    },
                    arrowPath: SliderArrowPath,
                   }}
                  >
                   {media?.map((item, index) => (
                    <SplideSlide key={index}>
                     <Box display="flex" alignItems="center" flexDir="column">
                      <Box
                       width="100%"
                       height="125px"
                       borderRadius="9px"
                       background="#F8FBFF"
                       display="flex"
                       justifyContent="center"
                       alignItems="center"
                       border="none"
                      >
                       <Img
                        src={item?.preview}
                        alt="image when you're using drag and drop"
                        width="100%"
                        height="125px"
                        cursor="pointer"
                        borderRadius="9px"
                        onClick={open}
                       />
                      </Box>

                      <div>
                       <p key={item.path}>
                        {item.name.substring(0, 6)}... ({bytesToSize(item.size)}
                        ),
                       </p>
                      </div>
                     </Box>
                    </SplideSlide>
                   ))}
                  </Splide>
                 </Box>
                ) : (
                 <Box display="flex" alignItems="center" flexDir="column">
                  <Box
                   width="100%"
                   height="125px"
                   borderRadius="9px"
                   background="#F8FBFF"
                   display="flex"
                   justifyContent="center"
                   alignItems="center"
                   border="none"
                  >
                   <Img
                    src={media[0]?.preview}
                    alt="image newly uploaded with drag and drop"
                    width="100%"
                    height="125px"
                    cursor="pointer"
                    borderRadius="100%"
                    marginBottom="20px"
                    onClick={open}
                   />
                  </Box>

                  <div>
                   {media?.map((file) => (
                    <p key={file.path}>
                     {file.name.substring(0, 6)}... ({bytesToSize(file.size)}),
                    </p>
                   ))}
                  </div>
                 </Box>
                )}
               </>
              ) : imageUrl ? (
               <>
                {imageUrl?.length > 0 ? (
                 <Box position="relative">
                  <Splide
                   className={{
                    gridAutoFlow: "1fr",
                    justifyItems: "center",
                   }}
                   aria-labelledby="Blog"
                   options={{
                    rewind: true,
                    perPage: 1,
                    // gap: "2rem",
                    classes: {
                     arrows: "splide__arrows splide-navigation-arrows",
                     arrow:
                      "splide__arrow splide__arrow__img splide-navigation-arrow",
                     prev: "splide__arrow--prev splide-img-prev",
                     next: "splide__arrow--next splide-img-next",
                    },
                    arrowPath: SliderArrowPath,
                   }}
                  >
                   {imageUrl?.map((item, index) => (
                    <SplideSlide key={index}>
                     <Box display="flex" alignItems="center" flexDir="column">
                      <Box
                       width="100%"
                       height="125px"
                       borderRadius="9px"
                       background="#F8FBFF"
                       display="flex"
                       justifyContent="center"
                       alignItems="center"
                       border="none"
                      >
                       <Img
                        src={item}
                        alt="image when viewing page that already has images"
                        width="100%"
                        // height="125px"
                        cursor="pointer"
                        objectFit="contain"
                        borderRadius="9px"
                        onClick={open}
                       />
                      </Box>
                     </Box>
                    </SplideSlide>
                   ))}
                  </Splide>
                 </Box>
                ) : (
                 <Box
                  width="125px"
                  height="125px"
                  borderRadius="100%"
                  background="#F8FBFF"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border="none"
                 >
                  <Img
                   src={imageUrl[0]}
                   alt="image when viewing page that already has one image"
                   width="100%"
                   height="125px"
                   cursor="pointer"
                   borderRadius="100%"
                   onClick={open}
                  />
                 </Box>
                )}
               </>
              ) : (
               <Box
                width="125px"
                height="125px"
                borderRadius="100%"
                background="#F8FBFF"
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1.24003px dashed #D5DCE3"
               >
                {" "}
                <Img
                 src={Cloud}
                 alt="image when there's no image"
                 width="32px"
                 height="32px"
                 cursor="pointer"
                 onClick={open}
                />
               </Box>
              )}
             </>
            )}

            <Text
             marginTop="20px"
             textAlign="center"
             color="#64748B"
             fontFamily="Satoshi"
             fontSize="12px"
             lineHeight="135%"
            >
             You can upload up to 5 images
            </Text>
           </Box>
          </Box>
         </Box>
         <Box marginBottom={{ base: "20px", md: "30px" }}>
          <Text
           fontFamily="Creato Display, Roboto, sans-serif;"
           color={colors.darkBlue}
           fontWeight="500"
           lineHeight="135%"
           fontSize="20px"
           mb="30px"
          >
           Personal Details
          </Text>
          <Flex
           gap="30px"
           marginBottom={{ base: "20px", md: "30px" }}
           flexDirection={{ base: "column", md: "row" }}
          >
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.firstName && touched.firstName}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             First name
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="firstName"
             name="firstName"
            />
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
           </FormControl>
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.lastName && touched.lastName}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Last name
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="lastName"
             name="lastName"
            />
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
           </FormControl>
          </Flex>
          <Flex
           gap="30px"
           marginBottom={{ base: "20px", md: "30px" }}
           flexDirection={{ base: "column", md: "row" }}
          >
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.email && touched.email}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Email
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="email"
             id="email"
             name="email"
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
           </FormControl>
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.phoneNumber && touched.phoneNumber}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Phone Number
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="phoneNumber"
             name="phoneNumber"
            />
            <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
           </FormControl>
          </Flex>
         </Box>
         <Box marginBottom={{ base: "20px", md: "30px" }}>
          <Text
           fontFamily="Creato Display, Roboto, sans-serif;"
           color={colors.darkBlue}
           fontWeight="500"
           lineHeight="135%"
           fontSize="20px"
           mb="30px"
          >
           Business Details
          </Text>
          <Flex
           gap="30px"
           marginBottom={{ base: "20px", md: "30px" }}
           flexDirection={{ base: "column", md: "row" }}
          >
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.businessName && touched.businessName}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Business name
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="businessName"
             name="businessName"
            />
            <FormErrorMessage>{errors.businessName}</FormErrorMessage>
           </FormControl>
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.businessEmail && touched.businessEmail}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Official email address
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="email"
             id="businessEmail"
             name="businessEmail"
            />
            <FormErrorMessage>{errors.businessEmail}</FormErrorMessage>
           </FormControl>
          </Flex>
          <Flex
           gap="30px"
           marginBottom={{ base: "20px", md: "30px" }}
           flexDirection={{ base: "column", md: "row" }}
          >
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={
             !!errors.businessPhoneNumber && touched.businessPhoneNumber
            }
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Official phone number
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="number"
             id="businessPhoneNumber"
             name="businessPhoneNumber"
            />
            <FormErrorMessage>{errors.businessPhoneNumber}</FormErrorMessage>
           </FormControl>
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.businessAddress && touched.businessAddress}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Business Address
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="businessAddress"
             name="businessAddress"
            />
            <FormErrorMessage>{errors.businessAddress}</FormErrorMessage>
           </FormControl>
          </Flex>
          <Flex
           gap="30px"
           marginBottom={{ base: "20px", md: "30px" }}
           flexDirection={{ base: "column", md: "row" }}
          >
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.city && touched.city}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             City
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="city"
             name="city"
            />
            <FormErrorMessage>{errors.city}</FormErrorMessage>
           </FormControl>
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isRequired
            isInvalid={!!errors.bio && touched.bio}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Bio
            </FormLabel>
            <Field
             as={Textarea}
             {...inputStyle}
             borderRadius="16px"
             height="120px"
             type="text"
             id="bio"
             name="bio"
            />
            <FormErrorMessage>{errors.bio}</FormErrorMessage>
           </FormControl>
          </Flex>
         </Box>
         <Box marginBottom={{ base: "20px", md: "30px" }}>
          <Text
           fontFamily="Creato Display, Roboto, sans-serif;"
           color={colors.darkBlue}
           fontWeight="500"
           lineHeight="135%"
           fontSize="20px"
           marginBottom="20px"
          >
           Social Links
          </Text>
          <Flex
           gap="30px"
           marginBottom={{ base: "20px", md: "30px" }}
           flexDirection={{ base: "column", md: "row" }}
          >
           <FormControl
            w="100%"
            alignItems="center"
            mb={{ md: "10px" }}
            isInvalid={!!errors.website && touched.website}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Website
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="website"
             name="website"
            />
            <FormErrorMessage>{errors.website}</FormErrorMessage>
           </FormControl>
           <FormControl
            w="100%"
            alignItems="center"
            mb="10px"
            isInvalid={!!errors.linkedin && touched.linkedin}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Linkedin
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="linkedin"
             name="linkedin"
            />
            <FormErrorMessage>{errors.linkedin}</FormErrorMessage>
           </FormControl>
          </Flex>
          <Flex
           gap="30px"
           marginBottom={{ base: "20px", md: "30px" }}
           flexDirection={{ base: "column", md: "row" }}
          >
           <FormControl
            w="100%"
            alignItems="center"
            mb="10px"
            isInvalid={!!errors.instagram && touched.instagram}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Instagram handle
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="instagram"
             name="instagram"
            />
            <FormErrorMessage>{errors.instagram}</FormErrorMessage>
           </FormControl>
           <FormControl
            w="100%"
            alignItems="center"
            mb="10px"
            isInvalid={!!errors.twitter && touched.twitter}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Twitter handle
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="twitter"
             name="twitter"
            />
            <FormErrorMessage>{errors.twitter}</FormErrorMessage>
           </FormControl>
          </Flex>
          <Flex
           gap="30px"
           marginBottom={{ base: "20px", md: "30px" }}
           flexDirection={{ base: "column", md: "row" }}
          >
           <FormControl
            w="100%"
            alignItems="center"
            mb="10px"
            isInvalid={!!errors.facebook && touched.facebook}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Facebook
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="facebook"
             name="facebook"
            />
            <FormErrorMessage>{errors.facebook}</FormErrorMessage>
           </FormControl>
           <FormControl
            w="100%"
            alignItems="center"
            mb="10px"
            isInvalid={!!errors.tiktok && touched.tiktok}
           >
            <FormLabel
             color={colors.darkBlue}
             fontFamily="Satoshi"
             fontWeight="700"
             fontSize="14px"
             lineHeight="21px"
             mb="10px"
            >
             Tiktok
            </FormLabel>
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             id="tiktok"
             name="tiktok"
            />
            <FormErrorMessage>{errors.tiktok}</FormErrorMessage>
           </FormControl>
          </Flex>
         </Box>
         {values.registeringAs === "business" && (
          <Box marginBottom={{ base: "20px", md: "30px" }}>
           <Text
            fontFamily="Creato Display, Roboto, sans-serif;"
            color={colors.darkBlue}
            fontWeight="500"
            lineHeight="135%"
            fontSize={{ base: "18px", md: "20px" }}
            // mb="30px"
           >
            Opening Hours
           </Text>
           <Box mt="20px" display="flex" alignItems="center">
            <Text
             fontFamily="Satoshi"
             fontWeight="500"
             fontSize={{ base: "16px", md: "20px" }}
             marginRight="10px"
            >
             I have same opening and closing time for all my available days
            </Text>
            <Switch
             size="sm"
             colorScheme="green"
             isChecked={sameTime}
             onChange={() => setSameTime(!sameTime)}
            />
           </Box>
           <SimpleGrid
            columns={{ base: 2 }}
            gap="30px"
            marginTop="20px"
            marginBottom="40px"
           >
            {days.map((day, index) => (
             <FormControl width="100%" key={index} id="">
              <FormLabel
               htmlFor=""
               color={colors.darkBlue}
               fontFamily="Satoshi"
               fontWeight="500"
               fontSize={{ base: "14px", md: "20px" }}
               lineHeight="135%"
               mb="10px"
              >
               {day}
              </FormLabel>
              <Text
               fontFamily="Satoshi"
               fontWeight="500"
               fontSize={{ base: "14px", md: "20px" }}
               lineHeight="21px"
               mb="10px"
              >
               Opening and closing time
              </Text>
              <DateRange
               values={values}
               setFieldValue={setFieldValue}
               day={day?.toLowerCase()}
              />
              {/* <Checkbox
                              // isChecked={featured}
                              // onChange={(e) => setFeatured(e.target.checked)}
                              colorScheme="purple"
                              color="#64748B"
                              marginTop="10px"
                              fontSize="14px"
                              fontFamily="Satoshi"
                              fontWeight="500"
                            >
                              Open 24 hours today
                            </Checkbox> */}
             </FormControl>
            ))}
           </SimpleGrid>
          </Box>
         )}

         <Box
          display="flex"
          height="100%"
          flexDir="column"
          width={{ base: "100%", md: "50%" }}
          marginBottom={{ base: "20px", md: "30px" }}
         >
          <Box
           width="100%"
           borderRadius="9px"
           height="100%"
           display="flex"
           flexDirection="column"
           background="#F1F5F9"
           justifyContent="center"
           alignItems="center"
           cursor="pointer"
           padding="20px"
           {...getRootFileProps()}
          >
           <Box
            marginBottom="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
           >
            <Text
             color="#0F0931"
             fontFamily="Creato Display, Roboto, sans-serif;"
             fontSize="16px"
             lineHeight="135%"
            >
             Upload rate card or menu
            </Text>
            <Text
             width="60%"
             textAlign="center"
             color="#64748B"
             fontFamily="Satoshi"
             fontSize="12px"
             lineHeight="135%"
            >
             JPG, .PNG images are allowed Maximum size of 10mb
            </Text>
           </Box>
           <Box
            border={media ? "none" : "1px dashed #D5DCE3"}
            width="100%"
            height="153px"
            borderRadius="5px"
            background="#F8FBFF"
           >
            <input {...getInputFileProps()} />
            <Box display="flex" alignItems="center" justifyContent="center">
             {files ? (
              <Box
               marginTop="30px"
               display="flex"
               flexDir="column"
               // onClick={openFile}
              >
               {files?.map((file) => (
                <a href={file.preview} target="_blank">
                 <Box display="flex" alignItems="center" marginBottom="10px">
                  <AiFillFile />{" "}
                  <Text
                   textAlign="center"
                   color="#64748B"
                   fontFamily="Satoshi"
                   fontSize="16px"
                   lineHeight="135%"
                   marginLeft="10px"
                   key={file.path}
                  >
                   {file.name.substring(0, 6)}... ({bytesToSize(file.size)}),
                  </Text>
                 </Box>
                </a>
               ))}
               <Button onClick={openFile}>Upload</Button>
              </Box>
             ) : (
              <>
               {filesUrl ? (
                <Box
                 marginTop="30px"
                 display="flex"
                 flexDir="column"
                 //onClick={openFile}
                >
                 {filesUrl?.map((file) => (
                  <a href={file} target="_blank">
                   <Box display="flex" alignItems="center" marginBottom="10px">
                    <AiFillFile />{" "}
                    <Text
                     textAlign="center"
                     color="#64748B"
                     fontFamily="Satoshi"
                     fontSize="16px"
                     lineHeight="135%"
                     marginLeft="10px"
                     key={file.path}
                    >
                     {file.substring(0, 6)}... ,
                    </Text>
                   </Box>
                  </a>
                 ))}
                 <Button onClick={openFile}>Upload</Button>
                </Box>
               ) : (
                <Img
                 src={Cloud}
                 alt="avatar"
                 marginTop="50px"
                 onClick={openFile}
                />
               )}
              </>
             )}

             {/* {!isView && (
                              <Img
                                src={EditIcon}
                                position="absolute"
                                top="0"
                                left="20"
                                cursor="pointer"
                                onClick={open}
                              />
                            )} */}
            </Box>
           </Box>

           {/* {error && (
                        <p
                          style={{
                            color: "#E53E3E",
                            marginTop: "0.5rem",
                            fontSize: "0.8rem",
                          }}
                        >
                          {error}
                        </p>
                      )} */}
          </Box>
         </Box>
         <Box marginBottom={{ base: "20px", md: "30px" }}>
          <Text
           fontFamily="Creato Display, Roboto, sans-serif;"
           color={colors.darkBlue}
           fontWeight="500"
           lineHeight="135%"
           fontSize="20px"
           // mb="30px"
          >
           Categories & tags
          </Text>
          <FormControl id="category" marginTop="30px">
           <FormLabel
            htmlFor="category"
            color={colors.darkBlue}
            fontFamily="Satoshi"
            fontWeight="500"
            fontSize="20px"
            lineHeight="135%"
            mb="10px"
           >
            Categories
           </FormLabel>

           <SimpleGrid columns={{ base: 1, md: 3 }} mt="30px" gap="20px">
            {category?.map((item, index) => (
             <Field
              component={CustomCheckbox}
              {...getCheckboxProps({
               value: item,
               name: "category",
              })}
              value={item}
              name="category"
             />
            ))}
           </SimpleGrid>
          </FormControl>
         </Box>
         <Box marginBottom="40px">
          <FormControl id="tags">
           <FormLabel
            htmlFor="tags"
            color={colors.darkBlue}
            fontFamily="Satoshi"
            fontWeight="500"
            fontSize="20px"
            lineHeight="135%"
            mb="10px"
           >
            Add additional tags that best describe this business
           </FormLabel>
           <Text fontFamily="Satoshi" fontWeight="500" fontSize="16px">
            Enter search tags you feel Navigators will use when looking for your
            business.
           </Text>
           <Box width="100%" marginTop="20px">
            <InputGroup borderRadius="28px" width="60%">
             <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
              paddingBlock="24px"
              paddingLeft={"25px"}
             />
             <Input
              {...searchInputStyle}
              onChange={(e) => searchFilter(e.target.value)}
             />
            </InputGroup>
            <Text
             marginTop="10px"
             fontFamily="Satoshi"
             fontSize="14px"
             color="#878498"
            >
             Select a minimum of 5 tags
            </Text>
            <SimpleGrid columns={{ base: 1, md: 4 }} mt="30px" gap="20px">
             {searchTag.map((item, index) => (
              <Field
               component={CustomCheckbox}
               {...getCheckboxProps({
                value: item,
                name: "tags",
               })}
               value={item}
               name="tags"
               // name="category"
               // value={item}
               // label={item}
              />
             ))}
            </SimpleGrid>
           </Box>
          </FormControl>
         </Box>

         <Box marginTop={10} marginBottom={{ base: 20, md: 0 }}>
          <Button
           color={colors.primaryWhite}
           bg={colors.primaryPurple}
           borderRadius={28}
           fontWeight={500}
           fontSize={18}
           p="24px 20px"
           width={{ base: "100%", md: "25%" }}
           type="submit"
           pointerEvents={loading ? "none" : "initial"}
          >
           Update
          </Button>
         </Box>
        </form>
       );
      }}
     </Formik>
    </Box>
   </Box>
  </Box>
 );
};

export default Profile;
