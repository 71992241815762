import React, { useContext, useState, useEffect } from "react";
import { Box, HStack, Text, SimpleGrid, Img, Input } from "@chakra-ui/react";
import Navigation from "./Navigation";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const resize = window.innerWidth > 767;
    if (resize) {
      setIsMobile(false);
    } else setIsMobile(true);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 767) {
        setIsMobile(false);
        setIsOpen(false);
      } else setIsMobile(true);
    });
  }, []);

  // console.log("isOpen", isOpen);

  const handleOpenMenu = () => {
    // console.log("isMobile===", isMobile);
    setIsMobile(!isMobile);
    setIsOpen(!isOpen);
  };

  return (
    <Box
      backgroundSize="auto"
      height="100%"
      minHeight="100vh"
      background="#E9EAFA"
    >
      <Navigation
        isMobile={isMobile}
        handleOpenMenu={handleOpenMenu}
        isOpen={isOpen}
      />
      {!isMobile && (
        <Sidebar
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
        />
      )}

      <Box
        background="#F5F8FA"
        paddingTop="110px"
        paddingLeft={{ base: "0px", md: "320px" }}
        overflowY="scroll"
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {children}
      </Box>
      {isOpen && (
        <div
          onClick={handleOpenMenu}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 200,
          }}
        />
      )}
    </Box>
  );
};

export default Layout;
