import { css } from "@emotion/react";
import styled from "styled-components";
import { colors, fontWeight } from "../../assets/styles/config";

export const Container = styled.div`
  background-color: ${colors.skyBlue};
  padding: 2rem 1rem;
  width: 100%;
  height: 100vh;
  margin-top: 100px;

  @media screen and (min-width: 768px) {
    padding: 4rem;
    margin-top: 100px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  gap: 3rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: 3.5fr 1.5fr;
  }
`;

export const Card = styled.div`
  background-color: ${colors.primaryWhite};
  padding: 2rem;
  box-shadow: 7px 18px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  ${(props) => props?.css || ""}

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`;

export const Title = styled.p`
  font-weight: ${fontWeight.semiBold};
  font-size: 16.5px;
  padding: 15px 20px 10px;
  border-bottom: solid 1px #e5e3e3;
  background-color: ${colors.primaryWhite};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  ${(props) => props?.css || ""}

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ProductText = styled.p`
  font-weight: ${fontWeight.medium};
  font-size: 16.5px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  ${(props) => props?.css || ""}
`;

export const FaintText = styled.p`
  font-size: 14px;
  color: #e5e3e3;

  @media screen and (min-width: 768px) {
    font-size: 15px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SingleProductContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;

  @media screen and (max-width: 768px) {
    gap: 1.5rem;
  }
`;

export const SingleProduct = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e5e3e3;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const ImgContainer = styled.div`
  cursor: pointer;
  min-width: 100px;
  min-height: 100px;
  background: ${colors.skyBlue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    max-width: 100px;
    max-height: 100px;
  }
`;

export const ProductImg = styled.img`
  object-fit: scale-down;
`;

export const CounterIcon = styled.button`
  background: #c8c8c8;
  font-weight: ${fontWeight.black};
  border-radius: 50%;
  font-size: 20px;
  width: 24px;
  height: 24px;
  font-weight: ${fontWeight.medium};
  color: ${colors.primaryWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.2s;

  &:hover {
    scale: 1.15;
  }

  &:active {
    scale: 0.9;
  }

  @media screen and (min-width: 768px) {
    font-size: 22px;
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 26px;
    line-height: 38px;
    width: 34px;
    height: 34px;
  }
`;

export const CounterText = styled.p`
  font-weight: ${fontWeight.medium};
  color: ${colors.brown};
  font-size: 20px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const CheckoutBtn = styled.button`
  padding: 16px 32px;
  background: ${colors.blue};
  border-radius: 28px;
  font-weight: ${fontWeight.medium};
  font-size: 18px;
  line-height: 27px;
  color: ${colors.primaryWhite};
`;

export const ProductDetails = styled.div``;
