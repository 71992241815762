export const colors = Object.freeze({
 primaryWhite: "#ffffff",
 babyBlue: "#82D2FF",
 blue: "#25AEFC",
 blueHover: "#0479bd",
 darkBlue: "#0F0931",
 faintBlue: "#C8E9FF",
 fadedNavyBlue: "#261F49",
 fadedNavyBlueHover: "#372D68",
 skyBlue: "#EBF7FF",
 faintAsh: "#979797",
 brown: "#828282",
 darkBrown: " #4F4F4F",
 faintBrown: "#64748B",
 placeholderBrown: "#bdbdbd",
 darkGrey: "#828282",
 darkGrey: "#6C6B6B",
 deepRed: "#C30203",
 pastelRed: "#FF6863",
 primaryPurple: "#632EFF",
 black: "#000000",
 secondaryBlack: "#111827",
 activeBorder: "1px solid #632eff",
 activeInputBg: "#f1ecff",
 // crescentBlue: '#C8E9FF',
});

export const typography = {
 fontWeight: {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  black: 900,
 },
};

export const fontWeight = Object.freeze({
 light: 300,
 regular: 400,
 medium: 500,
 semiBold: 600,
 bold: 700,
 black: 900,
});
