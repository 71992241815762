import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import { Box } from "@chakra-ui/react";
import Navbar from "../Home/Navbar";
import AllDeals from "./AllDeals";
import { Footer, Loader, Header } from "../../components";

const Deals = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <Box>
      <Header bgColor="#ffffff" color="dark" />
      <Hero />
      <AllDeals />
      <Footer />
    </Box>
  );
};

export default Deals;
