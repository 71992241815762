export const SearchIcon = () => {
 return (
  <div>
   <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   >
    <path
     d="M9.58342 18.1253C4.87508 18.1253 1.04175 14.292 1.04175 9.58366C1.04175 4.87533 4.87508 1.04199 9.58342 1.04199C14.2917 1.04199 18.1251 4.87533 18.1251 9.58366C18.1251 14.292 14.2917 18.1253 9.58342 18.1253ZM9.58342 2.29199C5.55841 2.29199 2.29175 5.56699 2.29175 9.58366C2.29175 13.6003 5.55841 16.8753 9.58342 16.8753C13.6084 16.8753 16.8751 13.6003 16.8751 9.58366C16.8751 5.56699 13.6084 2.29199 9.58342 2.29199Z"
     fill="#64748B"
    />
    <path
     d="M18.3333 18.9585C18.175 18.9585 18.0166 18.9002 17.8916 18.7752L16.225 17.1085C15.9833 16.8669 15.9833 16.4669 16.225 16.2252C16.4666 15.9835 16.8666 15.9835 17.1083 16.2252L18.775 17.8919C19.0166 18.1335 19.0166 18.5335 18.775 18.7752C18.65 18.9002 18.4916 18.9585 18.3333 18.9585Z"
     fill="#64748B"
    />
   </svg>
  </div>
 );
};

export const CallIcon = () => (
 <svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style={{ minWidth: "25px" }}
 >
  <path
   d="M12.5 22.75C6.57 22.75 1.75 17.93 1.75 12C1.75 6.07 6.57 1.25 12.5 1.25C18.43 1.25 23.25 6.07 23.25 12C23.25 17.93 18.43 22.75 12.5 22.75ZM12.5 2.75C7.4 2.75 3.25 6.9 3.25 12C3.25 17.1 7.4 21.25 12.5 21.25C17.6 21.25 21.75 17.1 21.75 12C21.75 6.9 17.6 2.75 12.5 2.75Z"
   fill="#632EFF"
  />
  <path
   d="M16.2106 15.93C16.0806 15.93 15.9506 15.9 15.8306 15.82L12.7306 13.97C11.9606 13.51 11.3906 12.5 11.3906 11.61V7.51001C11.3906 7.10001 11.7306 6.76001 12.1406 6.76001C12.5506 6.76001 12.8906 7.10001 12.8906 7.51001V11.61C12.8906 11.97 13.1906 12.5 13.5006 12.68L16.6006 14.53C16.9606 14.74 17.0706 15.2 16.8606 15.56C16.7106 15.8 16.4606 15.93 16.2106 15.93Z"
   fill="#632EFF"
  />
 </svg>
);
