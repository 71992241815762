import { Box, Popover, PopoverContent, Text } from "@chakra-ui/react";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";

const RecurringSection = ({
 index,
 disclosures1,
 values,
 setDisclosures1,
 recurringOption,
 setVisible,
 setSelectedModal,
 setFieldValue,
}) => {
 return (
  <Box
   position="relative"
   width={"100%"}
   // marginTop="10px"
  >
   <Text
    fontFamily="Satoshi"
    color="#0F0931"
    fontSize={{
     base: "12px",
     md: "14px",
    }}
    lineHeight="21px"
    fontWeight="700"
    mb="10px"
   >
    Recurring
   </Text>
   <Box
    //width="200px"
    background="#F1F5F9"
    borderRadius="66px"
    padding="10px 24px"
    onClick={disclosures1[index]?.onToggle}
    cursor="pointer"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    _hover={{
     background: "#F3F0FF",
     border: "1px solid #AC98FE",
    }}
    _focus={{
     background: "#F3F0FF",
    }}
    _focusVisible={{
     background: "#F3F0FF",
    }}
   >
    <Text
     fontFamily="Satoshi"
     color="#64748B"
     fontWeight="700"
     fontSize={{
      base: "12px",
      md: "14px",
     }}
    >
     {values?.experiences[index]?.recurring}
    </Text>
    <IoIosArrowDown />
   </Box>
   <Popover
    isOpen={disclosures1[index]?.isOpen}
    onClose={() =>
     setDisclosures1((prevDisclosures) =>
      prevDisclosures.map((disclosure, i) =>
       i === index
        ? {
           ...disclosure,
           isOpen: false,
          }
        : disclosure
      )
     )
    }
    placement="bottom"
   >
    <PopoverContent
     bg="#FFFFFF"
     borderRadius="12px"
     boxShadow="0px 4px 24px #D4DEEC"
     // position="absolute"
     padding="10px 24px"
     outline="none"
     width="100%"
    >
     {recurringOption?.map((item) => (
      <Text
       padding="10px 0px"
       borderBottom="1px solid #E2E8F0"
       _last={{
        borderBottom: "none",
       }}
       key={item}
       fontWeight="500"
       fontFamily="Satoshi"
       cursor="pointer"
       onClick={() => {
        setDisclosures1((prevDisclosures) =>
         prevDisclosures.map((disclosure, index) => {
          return {
           ...disclosure,
           isOpen: !disclosure.isOpen,
          };
         })
        );
        if (item === "Custom") {
         setVisible((prevVisible) => !prevVisible);
         setSelectedModal(index);
        } else {
         setFieldValue(`experiences[${index}].recurring`, item);
        }
       }}
      >
       {item}
      </Text>
     ))}
    </PopoverContent>
   </Popover>
  </Box>
 );
};

export default RecurringSection;
