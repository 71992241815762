import { css } from '@emotion/react';
import styled from 'styled-components';
import { colors, fontWeight } from '../../../assets/styles/config';

export const Heading = styled.h1`
  font-size: 25px;
  margin-bottom: 20px;
  line-height: 35px;

  font-weight: ${fontWeight.semiBold};
  color: ${colors.darkBlue};
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 33px;
    line-height: 45px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 50px;
    line-height: 75px;
    margin-bottom: 40px;
  }
  ${(props) => props.css || ''}
`;

export const AccordionQuestion = styled.p`
  font-size: 15px;
  color: ${colors.black};

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1080px) {
    padding: 0.5rem 0;
    font-size: 18px;
    line-height: 27px;
  }
`;

export const AccordionAnswer = styled.p`
  font-weight: ${fontWeight.light};
  font-size: 15px;
  color: ${colors.black};
  line-height: 27px;
  padding: 0.5rem 0.25rem;
  padding-left: 3rem;

  @media screen and (min-width: 768px) {
    padding: 0.5rem 1rem;
    padding-left: 3.25rem;
    font-size: 16px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 18px;
    line-height: 32px;
  }
`;
