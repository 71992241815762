// localhost:4222/admin/partner-experience/add/registering/Business
import React, {
 useState,
 useEffect,
 useContext,
 useRef,
 useCallback,
} from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 Button,
 List,
 ListItem,
 ListIcon,
 SimpleGrid,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Grid,
 GridItem,
 Checkbox,
 Textarea,
 Flex,
 Icon,
 Select as ChakraSelect,
 Image,
 useDisclosure,
 Popover,
 PopoverContent,
 Switch,
 useCheckbox,
 useCheckboxGroup,
 chakra,
} from "@chakra-ui/react";
import {
 inputStyle,
 searchInputStyle,
 selectStyle,
 dateStyle,
 radioStyle,
} from "../../styles";
import { colors } from "../../../../../assets/styles/config";
import Avatar from "../../../../../assets/images/Avatar.png";
import EditIcon from "../../../../../assets/images/edit.svg";
import DelectIcon from "../../../../../assets/images/Delete.svg";
import AddIcon from "../../../../../assets/images/Add.svg";
import { AuthContext } from "../../../../../context/AuthContext";
import { Formik, Field, useFormik, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { Modal, Icon as CloseIconWrapper } from "../../../../../components";
import { Card, CloseBtn, Container } from "../../styles";
import { useDropzone } from "react-dropzone";
import { bytesToSize, flatten } from "../../../../../utils/index";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import {
 getDatabase,
 ref as dbRef,
 set,
 onValue,
 update,
 remove,
 child,
 get,
} from "firebase/database";
import { storage, db } from "../../../../../firebase";
import styles from "../index.module.css";
import Select from "react-select";
//import { TimePicker, DatePicker } from "formik-material-ui-pickers";
import {
 MuiPickersUtilsProvider,
 KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { MdClose, MdOutlineClear } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { removeObjectWithId } from "../../../../../utils/index";
import { useParams, useNavigate } from "react-router-dom";
import { TimePicker } from "antd";
import { TextEditor } from "../../../../../components/TextEditor";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { SliderArrowPath } from "../../../../../assets/styles";
import ArrowLeft from "../../../../../assets/images/arrow-left-dark.svg";
import Cloud from "../../../../../assets/images/cloud-plus.svg";
import DateRange from "../../../../../components/DateRange";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { sendPushNotification } from "../../../../../api/user";
import Media from "./addExperienceComponents/Media";
import RecurringSection from "./addExperienceComponents/RecurringSection";
import TimeSection from "./addExperienceComponents/TimeSection";
import DateSection from "./addExperienceComponents/DateSection";
import TicketSection from "./addExperienceComponents/TicketSection";
import SubscribedPartner from "./addExperienceComponents/SubscribedPartner";
import ModalSubscription from "./addExperienceComponents/ModalSubscription";
import ScrollMedia from "./addExperienceComponents/ScrollMedia";
import { fieldStyle } from "../styles";
import { eventTypes } from "../../../../../utils/constants";

const AdminAddExperience = () => {
 const { user } = useContext(AuthContext);
 const [media, setMedia] = useState(null);
 const [editableMedia, setEditableMedia] = useState("");
 const [allExperiences, setAllExperiences] = useState([]);
 const [experiences, setExperiences] = useState([]);
 const [address, setAddress] = useState("");
 const [included, setIncluded] = useState("");
 const [list, setList] = useState([]);
 const experienceDb = dbRef(db, "cityExperience/experiences");
 const [isEdit, setIsEdit] = useState(false);
 // const [experienceType, setExperienceType] = useState("oneTime");
 const params = useParams();
 const navigate = useNavigate();
 const [city, setCity] = useState([]);
 const id = params?.id;
 const expId = params?.expId;
 const registeringAs = params?.registering;
 const cityExperienceDb = dbRef(db, "cityExperience/city");
 const categoryDb = dbRef(db, "cityExperience/categories");
 const [category, setCategory] = useState([]);
 const [loading, setLoading] = useState(false);
 const [partner, setPartner] = useState([]);
 const [allPartner, setAllPartner] = useState([]);
 const [partnerOption, setPartnerOption] = useState([]);
 // const [selectedPartner, setSelectedPartner] = useState({});
 // const { isOpen, onToggle, onClose } = useDisclosure();
 const [disclosures, setDisclosures] = useState({
  0: [{ isOpen: false, onToggle: () => {} }],
 });
 const [disclosures1, setDisclosures1] = useState([
  { isOpen: false, onToggle: () => {} },
 ]);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [openDate, setOpenDate] = useState(false);
 const [isNewDate, setIsNewDate] = useState(false);
 const [isNewTime, setIsNewTime] = useState(false);
 const [selectedModal, setSelectedModal] = useState(null);

 const [startDate, setStartDate] = useState("");
 const [endDate, setEndDate] = useState("");
 const [startTime, setStartTime] = useState("AM");
 const [endTime, setEndTime] = useState("AM");

 const { getCheckboxProps } = useCheckboxGroup({
  defaultValue: [""],
 });
 const initialValues = {
  city: "lagos",
  experienceHost: "",
  experience: "oneTime",
  title: "",
  included: "",
  additionInfo: "",
  experiences: [
   {
    id: 0,
    date: Date.now(),
    recurring: "Daily",
    timeSlot: [
     {
      id: 0,
      start: "",
      end: "",
      amount: "",
      maxTickets: "",
      admitsHowMany: 1,
     },
    ],
    custom_recurrence: {
     repeat_every: {
      no: "",
      every: "",
     },
     repeat_on: [],
     end: "",
     end_on: Date.now(),
    },
   },
  ],
  tags: "",
  coupon_code: "",
 };

 useEffect(() => {
  var keys = Object.keys(disclosures);
  for (var i = 0; i < keys.length; i++) {
   var key = keys[i];
   setDisclosures((prevDisclosures) => ({
    ...prevDisclosures,
    [key]: prevDisclosures[key]?.map((disclosure, index) => ({
     isOpen: disclosure.isOpen,
     onToggle: () =>
      setDisclosures((prevDisclosures) => ({
       ...prevDisclosures,
       [key]: prevDisclosures[key]?.map((d, i) =>
        i === index ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false }
       ),
      })),
    })),
   }));
  }

  setDisclosures1((prevDisclosures) =>
   prevDisclosures.map((disclosure, index) => {
    return {
     isOpen: disclosure.isOpen,
     onToggle: () =>
      setDisclosures1((prevDisclosures) =>
       prevDisclosures.map((d, i) =>
        i === index ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false }
       )
      ),
    };
   })
  );
 }, [isNewDate, isNewTime]);

 // const {
 //   isOpen: isOpen1,
 //   onToggle: onToggle1,
 //   onClose: onClose1,
 // } = useDisclosure();

 const random = (length) => {
  return Math.floor(
   Math.pow(10, length - 1) +
    Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
  );
 };

 useEffect(() => {
  if (location.pathname.includes("edit")) {
   setIsEdit(true);
  }
 }, []);

 useEffect(() => {
  const unsubscribe = onValue(cityExperienceDb, (snapshot) => {
   const data = snapshot.val();
   // console.log("city", Object.keys(data));
   setCity(Object.keys(data));
  });

  return () => unsubscribe();
 }, []);

 useEffect(() => {
  const unsubscribe = onValue(categoryDb, (snapshot) => {
   const data = snapshot.val();
   data.shift();
   setCategory(data);
  });

  return () => unsubscribe();
 }, []);

 useEffect(() => {
  const unsubscribe = onValue(cityExperienceDb, (snapshot) => {
   const data = snapshot.val();
   // console.log("city", Object.keys(data));
   setCity(Object.keys(data));
  });

  return () => unsubscribe();
 }, []);

 // console.log("selected", selectedPartner);

 useEffect(() => {
  const fetchDatabase = async () => {
   setLoading(true);
   //get user experience
   const db = await get(child(dbRef(getDatabase()), `users/partner`))
    .then((snapshot) => {
     setLoading(false);
     if (snapshot.exists()) {
      const myObj = snapshot.val();

      if (!registeringAs) {
       setPartner(
        Object.keys(myObj)
         .map((key) => {
          return myObj[key];
         })
         .filter((item) => item?.uid === id)[0]
       );
      } else {
       setAllPartner(
        Object.keys(myObj).map((key) => {
         return myObj[key];
        })
       );
       setPartnerOption(
        Object.keys(myObj)
         .map((key) => {
          return myObj[key];
         })
         .filter(
          (item) =>
           item?.registeringAs?.toLowerCase() ===
            registeringAs?.toLowerCase() && item?.enabled
         )
         ?.map((item) => {
          return {
           value: item?.uid,
           label: item?.businessName,
          };
         })
       );
      }
     }
    })
    .catch((error) => {
     // console.error(error);
     setLoading(false);
    });
   //get users order

   return db;
  };

  fetchDatabase();
 }, [id, registeringAs]);

 const filterObjectExperience = (obj) =>
  obj &&
  Object.keys(obj)?.reduce(
   (acc, val) =>
    Number(obj[val]["id"]) === Number(expId)
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 useEffect(() => {
  const unsubscribe = onValue(experienceDb, (snapshot) => {
   //set all experience
   // setAllExperiences(snapshot.val());
   //get user experience
   let objExp = filterObjectExperience(snapshot.val());

   if (objExp) {
    setExperiences(
     Object.keys(objExp).map((key) => {
      return {
       ...objExp[key],
      };
     })
    );
   }
  });

  return () => unsubscribe();
 }, [user, isEdit]);

 //set editable address
 const editableAddress =
  isEdit &&
  experiences[0]?.details &&
  JSON.parse(experiences[0]?.details)?.location;

 //check if it's edit

 // function handleChange(event) {
 //   setIncluded(event.target.value);
 // }

 // //handle adding what's included
 // function handleAdd() {
 //   if (included) {
 //     const newList = list.concat({ include: included, id: list.length });

 //     setList(newList);
 //     setIncluded("");
 //   }
 // }

 // //handle delete for what's included
 // const handleDelete = (id) => {
 //   setList((item) =>
 //     item.filter((itm) => {
 //       return itm.id !== id;
 //     })
 //   );
 // };

 const expOption = [
  { value: "oneTime", label: "One Time" },
  { value: "recurring", label: "Recurring" },
 ];
 // { value: [1, 2, 3, 4, 5, 6, 7], label: "Every day" },
 const days = [
  { value: [2], label: "Mon" },
  { value: [3], label: "Tue" },
  { value: [4], label: "Wed" },
  { value: [5], label: "Thur" },
  { value: [6], label: "Fri" },
  { value: [7], label: "Sat" },
  { value: [1], label: "Sun" },
  // { value: [2, 3, 4, 5, 6], label: "Every weekday" },
  // { value: [1, 7], label: "Every weekend" },
 ];

 // const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

 const recurringOption = ["Daily", "Custom"];

 const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
  useDropzone({
   accept: {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "video/gif": [".gif"],
    "video/mp4": [".mp4"],
   },
   onDrop: (acceptedFiles) => {
    setMedia(
     acceptedFiles.map((file) =>
      Object.assign(file, {
       preview: URL.createObjectURL(file),
      })
     )
    );
   },
   //  accept: "image/jpeg, image/png, video/gif, video/mp4",
   // Disable click and keydown behavior
   noClick: true,
   noKeyboard: true,
   maxSize: 10000000,
   minSize: 0,
  });

 /* useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    media?.forEach((file) => URL.revokeObjectURL(file.preview));

    if (fileRejections) {
      fileRejections.map(({ file }) => {
        toast.error(`${file.name} is too large`);
      });
    }
  }, [media]); */

 //console.log("media", media);

 const handleMediaUpload = async (file) => {
  //  toast.loading("loading");
  const storageRef = ref(storage, `/files/${file.name}`);
  await uploadBytesResumable(storageRef, file);
  return await getDownloadURL(storageRef);
 };

 /*  const handleSave = async (file, values) => {
    toast.loading("loading");
    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // update progress
        // setPercent(percent);
      },
      (err) => console.log(err),
      async () => {
        // download url

        // length of experience
        /*  const length =
            parseFloat(experiences[experiences?.length - 1]?.id) +
            parseFloat("1"); 

        //download pic url
        await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {});
      }
    );
  }; */

 //handle delete experience
 const handleDeleteExperience = async () => {
  // toast.loading("loading");
  // const db = getDatabase();
  // const updates = {};
  // updates["cityExperience/experiences"] = allExperiences?.filter(
  //   (item) => item.id !== experiences[0]?.id
  // );
  // await update(dbRef(db), updates)
  //   .then(() => {
  //     toast.dismiss();
  //     navigate("/admin/partner-experience");
  //     toast.success("Successfully deleted experience");
  //   })
  //   .catch((error) => {
  //     toast.dismiss();
  //     toast.error(err);
  //   })
  //   .finally(() => {
  //     setTimeout(() => {
  //       toast.dismiss();
  //     }, 3000);
  //   });
  // toast.loading("loading");
  /* const tasksRef = dbRef(
        db,
        `cityExperience/experiences/${experiences[0]?.id}`
      );
  
      remove(tasksRef)
        .then(() => {
          toast.dismiss();
          navigate("/experience");
          toast.success("Successfully deleted experience");
        })
        .catch((error) => {
          toast.dismiss();
          toast.error(err);
        })
        .finally(() => {
          setTimeout(() => {
            toast.dismiss();
          }, 3000);
        }); */
 };

 //const inputRef = useRef();

 const [registeredAddress, setRegisteredAddress] = useState(false);
 const [featured, setFeatured] = useState(false);

 const handlePartner = (value, setFieldValue) => {
  setFieldValue(`experienceHost`, value);
  // setSelectedPartner(
  //   partner?.filter((item) => item?.name === value.value)[0]
  // );
  setPartner(allPartner.filter((item) => item?.uid === value.value)[0]);
 };

 const handleTimeInputChange = (event) => {
  const input = event.target;
  const value = input.value.replace(/[^0-9]/g, "");

  if (value.length > 2) {
   input.value = value.slice(0, 2) + ":" + value.slice(2);
  }
 };

 return (
  <Box height="100%" padding="14px 20px" background="#F5F8FA">
   <Box
    display="flex"
    alignItems="center"
    cursor="pointer"
    onClick={() => navigate(-1)}
   >
    <Image src={ArrowLeft} />
    <Text color="#252046" marginLeft="10px">
     Go Back
    </Text>
   </Box>
   <Formik
    initialValues={{
     city: "lagos",
     experienceHost: "",
     experience: "oneTime",
     title: "",
     included: "",
     additionInfo: "",
     experiences: [
      {
       id: 0,
       date: Date.now(),
       recurring: "Daily",
       timeSlot: [
        {
         id: 0,
         start: "",
         end: "",
         amount: "",
         maxTickets: "",
         admitsHowMany: 1,
        },
       ],
       custom_recurrence: {
        repeat_every: {
         no: "",
         every: "",
        },
        repeat_on: [],
        end: "",
        end_on: Date.now(),
       },
      },
     ],
     tags: "",
     coupon_code: "",
    }}
    onSubmit={async (values) => {
     console.log("is submiting");
     if (!partner?.subscribed && media?.length > 1) {
      toast.error("Please upload one image");
     } else if (partner?.subscribed && media?.length > 6) {
      toast.error("Please upload at least six image");
     } else if (values?.city === "" || undefined) {
      toast.error("Please select a city");
     } else {
      console.log("passed validations");

      if (!isEdit) {
       if (media !== null) {
        toast.loading("loading");
        const randomId = random(20);
        let expImages = [];

        for (var i = 0; i < media.length; i++) {
         const img = await handleMediaUpload(media[i]);
         expImages.push(img);
        }

        // await handleSave(media[0], values);

        const db = getDatabase();
        const payload = {
         city: values.city,
         email: partner?.email,
         details: {
          description: values?.description,
          phone: partner?.phoneNumber,
          website: partner?.link,
          email: partner?.email,
          location: registeredAddress ? partner?.address : address?.label,
          //maxTickets: values?.maxTickets,
          partner: !registeringAs
           ? values.experienceHost
           : values.experienceHost?.label,
          pic: partner?.photoURL,
          bio: partner?.bio,
          // time: `${values.start_time} - ${values.end_time}`,
          whatsIncluded: values.included,
          additionInfo: values.additionInfo,
         },
         featured: featured,
         img: expImages,
         // price: values?.amount,
         tag: partner?.tags,
         category: partner?.category,
         type: values.experience,
         timeframes: JSON.stringify(values.experiences),
         id: randomId,
         timestamp: JSON.stringify(new Date()),
         title: values?.title,
         bank: partner?.bank ?? "",
         partnerUid: partner?.uid,
         subscribed: partner?.subscribed,
         //  coupon_code: values?.coupon_code,
        };

        // console.log("payload", payload);

        //add exp with media

        set(dbRef(db, "cityExperience/experiences/" + randomId), {
         ...payload,
         details: JSON.stringify(payload.details),
        })
         .then(async (res) => {
          toast.dismiss();
          toast.success("Successfully saved experience");
          navigate(`/admin/partner-experience/${partner?.uid}`);
          if (user) {
           await sendPushNotification({
            title: "admin",
            body: `${user?.name} added a new experience`,
            data: {
             // ...user,
             route: `/admin/partner-experience/${partner?.uid}`,
             name: user?.name,
             photoURL: user?.photoURL,
             push_time: Date.now(),
            },
           });
          }
         })
         .catch((err) => {
          toast.dismiss();
          toast.error(err);
         })
         .finally(() => {
          setTimeout(() => {
           toast.dismiss();
          }, 3000);
         });
       } else if (address === "" && !registeredAddress) {
        toast.error("Please select an address");
       } else {
        toast.error("Please add an experience banner image");
       }
      }

      if (isEdit) {
       toast.loading("loading");
       const data = {
        city: values.city,
        details: {
         description: values?.description,
         phone: partner?.phoneNumber,
         website: partner?.link,
         email: partner?.email,
         location: registeredAddress
          ? partner?.address
          : address?.label
          ? address?.label
          : editableAddress,
         partner: !registeringAs
          ? values.experienceHost
          : values.experienceHost?.label,
         pic: partner?.photoURL,
         bio: partner?.bio,
         whatsIncluded: values.included,
         additionInfo: values.additionInfo,
        },
        email: partner?.email,
        feature: featured,
        img: experiences[0]?.img,
        //  price: values?.amount,
        category: partner?.category,
        tag: partner?.tags,
        type: values.experience,
        timeframes: JSON.stringify(values.experiences),
        // oneTime:
        //   experienceType === "oneTime"
        //     ? JSON.stringify(values.oneTime)
        //     : "",
        // recurring:
        //   experienceType === "recurring"
        //     ? JSON.stringify(values.recurring)
        //     : "",
        id: experiences[0]?.id,
        timestamp: JSON.stringify(new Date()),
        title: values?.title,
        bank: partner?.bank ?? "",
        partnerUid: partner?.uid,
        subscribed: partner?.subscribed,
        // coupon_code: values?.coupon_code,
       };
       if (media === null) {
        // toast.loading("loading");
        const payload = {
         ...data,
         details: JSON.stringify(data.details),
        };
        const db = getDatabase();
        const updates = {};
        // const findIndex = allExperiences.findIndex(
        //   (item) => item?.id === experiences[0]?.id
        // );
        updates["cityExperience/experiences/" + experiences[0]?.id] = payload;
        await update(dbRef(db), updates)
         .then(async () => {
          toast.dismiss();
          toast.success("Successfully updated experience");
          if (user) {
           await sendPushNotification({
            title: "admin",
            body: `${user?.name} updated ${data?.details?.partner}'s ${data?.title} experience`,
            data: {
             // ...user,
             route: `/admin/partner-experience/edit/${partner?.uid}/${experiences[0]?.id}`,
             name: user?.name,
             photoURL: user?.photoURL,
             push_time: Date.now(),
            },
           });
          }
          // return true;
         })
         .catch((error) => {
          // console.error(error.message);
          toast.dismiss();
          toast.error(err);
         })
         .finally(() => {
          setTimeout(() => {
           toast.dismiss();
          }, 3000);
         });
       } else {
        // console.log("media[0]", media[0]);
        //  await handleSave(media[0], values);

        let expImages = [];

        for (var i = 0; i < media.length; i++) {
         const img = await handleMediaUpload(media[i]);
         expImages.push(img);
        }
        const payload = {
         ...data,
         details: JSON.stringify(data.details),
         img: expImages,
        };

        const db = getDatabase();
        const updates = {};
        // const findIndex = allExperiences.findIndex(
        //   (item) => item?.id === experiences[0]?.id
        // );
        updates["cityExperience/experiences/" + experiences[0]?.id] = payload;
        return await update(dbRef(db), updates)
         .then(async () => {
          toast.dismiss();
          toast.success("Successfully updated experience");
          if (user) {
           await sendPushNotification({
            title: "admin",
            body: `${user?.name} updated ${data?.details?.partner}'s ${data?.title} experience`,
            data: {
             // ...user,
             route: `/admin/partner-experience/edit/${partner?.uid}/${experiences[0]?.id}`,
             name: user?.name,
             photoURL: user?.photoURL,
             push_time: Date.now(),
            },
           });
          }
         })
         .catch((error) => {
          toast.dismiss();
          toast.error(err);
         })
         .finally(() => {
          setTimeout(() => {
           toast.dismiss();
          }, 3000);
         });
       }
      }
     }
    }}
    //  validationSchema={validationSchema}
   >
    {({
     handleSubmit,
     errors,
     touched,
     setFieldValue,
     handleBlur,
     values,
     resetForm,
    }) => {
     useEffect(() => {
      if (!registeringAs) {
       setFieldValue("experienceHost", partner?.businessName);
      }

      if (isEdit && experiences.length > 0) {
       const editableExperiences = experiences[0];
       setFieldValue("experience", editableExperiences?.type, false);

       JSON.parse(editableExperiences?.timeframes)?.map((item, index) => {
        setFieldValue(`experiences[${index}]['id']`, index);
        setFieldValue(
         `experiences[${index}]['date']`,
         JSON.parse(editableExperiences?.timeframes)[index]?.date
        );

        JSON.parse(editableExperiences?.timeframes)[index]?.timeSlot?.map(
         (itm, id) => {
          setFieldValue(
           `experiences[${index}]['timeSlot'][${id}]['start']`,
           JSON.parse(editableExperiences?.timeframes)[index]?.timeSlot[id]
            ?.start
          );
          setFieldValue(
           `experiences[${index}]['timeSlot'][${id}]['end']`,
           JSON.parse(editableExperiences?.timeframes)[index]?.timeSlot[id]?.end
          );
          setFieldValue(
           `experiences[${index}]['timeSlot'][${id}]['amount']`,
           JSON.parse(editableExperiences?.timeframes)[index]?.timeSlot[id]
            ?.amount
          );
          setFieldValue(
           `experiences[${index}]['timeSlot'][${id}]['maxTickets']`,
           JSON.parse(editableExperiences?.timeframes)[index]?.timeSlot[id]
            ?.maxTickets
          );
          setFieldValue(
           `experiences[${index}]['timeSlot'][${id}]['admitsHowMany']`,
           JSON.parse(editableExperiences?.timeframes)[index]?.timeSlot[id]
            ?.admitsHowMany
          );
         }
        );

        setFieldValue(
         `experiences[${index}]['recurring']`,
         JSON.parse(editableExperiences?.timeframes)[index]?.recurring
        );

        if (
         JSON.parse(editableExperiences?.timeframes)[index]?.recurring ===
         "Custom"
        ) {
         setFieldValue(
          `experiences[${index}].custom_recurrence.repeat_every.no`,
          JSON.parse(editableExperiences?.timeframes)[index]?.custom_recurrence
           ?.repeat_every?.no
         );
         setFieldValue(
          `experiences[${index}].custom_recurrence.repeat_every.every`,
          JSON.parse(editableExperiences?.timeframes)[index]?.custom_recurrence
           ?.repeat_every?.every
         );
         setFieldValue(
          `experiences[${index}].custom_recurrence.end`,
          JSON.parse(editableExperiences?.timeframes)[index]?.custom_recurrence
           ?.end
         );
         const filteredDays = days.filter((day) =>
          JSON.parse(editableExperiences?.timeframes)
           [index]?.custom_recurrence?.repeat_on?.flat()
           .includes(day.value[0])
         );

         setFieldValue(
          `experiences[${index}].custom_recurrence.repeat_on`,
          JSON.parse(editableExperiences?.timeframes)[index]?.custom_recurrence
           ?.repeat_on
         );
        }
        setIsNewDate(!isNewDate);
        setDisclosures(
         JSON.parse(editableExperiences?.timeframes)?.forEach((timeframe) => {
          return timeframe.timeSlot.map(() => ({
           isOpen: false,
           onToggle: () => {},
          }));
         })
        );

        setDisclosures((prevState) => {
         const newDisclosures = JSON.parse(
          editableExperiences?.timeframes
         )?.map((timeframe) =>
          timeframe.timeSlot.map(() => ({
           isOpen: false,
           onToggle: () => {},
          }))
         );

         // map over the new array of arrays and convert it to an object with numbered keys
         const newDisclosuresObj = newDisclosures.reduce(
          (acc, curr, index) => ({
           ...acc,
           [index]: curr,
          }),
          {}
         );

         return { ...prevState, ...newDisclosuresObj };
        });

        const obj = { isOpen: false, onToggle: () => {} };
        const numRepetitions = index + 1;
        const array = [];
        for (let i = 0; i < numRepetitions; i++) {
         array.push(obj);
        }
        // setDisclosures(array);
        setDisclosures1(array);
       });

       if (partnerOption) {
        // setFieldValue(
        //   "experienceHost",
        //   partnerOption?.filter(
        //     (item) =>
        //       item.value ===
        //       JSON.parse(editableExperiences?.details)?.partner
        //   ),
        //   false
        // );
        // setSelectedPartner(
        //   partner.filter(
        //     (item) =>
        //       item.name ===
        //       JSON.parse(editableExperiences?.details)?.partner
        //   )[0]
        // );
       }

       setFieldValue("city", editableExperiences?.city, false);
       setFieldValue("title", editableExperiences?.title, false);
       setFieldValue(
        "description",
        JSON.parse(editableExperiences?.details)?.description,
        false
       );

       setFieldValue(
        "included",
        JSON.parse(editableExperiences?.details)?.whatsIncluded,
        false
       );
       setFieldValue(
        "additionInfo",
        JSON.parse(editableExperiences?.details)?.additionInfo,
        false
       );

       setFieldValue("coupon_code", editableExperiences?.coupon_code, false);

       setEditableMedia(editableExperiences?.img);
      }
     }, [isEdit, experiences, partner]);

     return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
       <form onSubmit={handleSubmit}>
        <Box
         background="#FFFFFF"
         borderRadius="6px"
         padding={{ base: "20px", md: "30px" }}
         width="100%"
         mt={{ md: "16px" }}
        >
         <Text
          fontFamily="Creato Display"
          fontSize="20px"
          fontWeight="700"
          color="#334155"
          display={{ base: "none", md: "flex" }}
         >
          {isEdit ? "Update Experience" : " Add new Experience"}
         </Text>
         <Box mt="30px" w={{ md: "478px" }}>
          <Box
           mb="30px"
           display="flex"
           alignItems="center"
           justifyContent="space-between"
          >
           <FormControl display="flex" alignItems="center" gap="10px">
            <FormLabel
             fontFamily="Satoshi"
             color="#0F0931"
             fontSize={{ base: "12px", md: "14px" }}
             lineHeight="21px"
             fontWeight="700"
             margin={0}
            >
             Location:
            </FormLabel>{" "}
            <ChakraSelect
             width={{ base: "40%", md: "20%" }}
             onChange={(e) => setFieldValue("city", e.target.value)}
             {...selectStyle}
             id="city"
             name="city"
            >
             <option value="lagos">lagos</option>
            </ChakraSelect>
           </FormControl>
          </Box>
          <Box display="flex" alignItems="flex-start" mb="30px" width="100%">
           <Box
            display="flex"
            alignItems="center"
            flexDir="column"
            width="100%"
           >
            <Box
             width="100%"
             borderRadius="9px"
             height="100%"
             display="flex"
             flexDirection="column"
             background="#F1F5F9"
             justifyContent="center"
             alignItems="center"
             cursor="pointer"
             padding="20px"
             {...getRootProps()}
            >
             <Box
              marginBottom="20px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
             >
              <Text
               color="#0F0931"
               fontFamily="Creato Display, Roboto, sans-serif;"
               fontSize="16px"
               lineHeight="135%"
              >
               Upload Cover Image
              </Text>
              <Text
               width="60%"
               textAlign="center"
               color="#64748B"
               fontFamily="Satoshi"
               fontSize="12px"
               lineHeight="135%"
              >
               JPG, .PNG images are allowed Maximum size of 10mb
              </Text>
             </Box>
             <Box
              border={media ? "none" : "1px dashed #D5DCE3"}
              width="100%"
              height="153px"
              borderRadius="5px"
              background="#F8FBFF"
             >
              <input {...getInputProps()} />
              {!isDragActive && (
               <>
                {media ? (
                 <>
                  {media?.length > 1 ? (
                   <Media media={media} open={open} />
                  ) : (
                   <ScrollMedia media={media} open={open} />
                  )}
                 </>
                ) : editableMedia ? (
                 <>
                  {editableMedia?.length > 0 ? (
                   <Box position="relative">
                    <Splide
                     className={{
                      gridAutoFlow: "1fr",
                      justifyItems: "center",
                     }}
                     aria-labelledby="Blog"
                     options={{
                      rewind: true,
                      perPage: 1,
                      // gap: "2rem",
                      classes: {
                       arrows: "splide__arrows splide-navigation-arrows",
                       arrow:
                        "splide__arrow splide__arrow__img splide-navigation-arrow",
                       prev: "splide__arrow--prev splide-img-prev",
                       next: "splide__arrow--next splide-img-next",
                      },
                      arrowPath: SliderArrowPath,
                     }}
                    >
                     {editableMedia?.map((item, index) => (
                      <SplideSlide key={index}>
                       <Box display="flex" alignItems="center" flexDir="column">
                        <Box
                         width="100%"
                         height="100%"
                         background="#F8FBFF"
                         display="flex"
                         justifyContent="center"
                         alignItems="center"
                         border="none"
                        >
                         <Img
                          src={item}
                          alt="avatar"
                          width="100%"
                          height="153px"
                          cursor="pointer"
                          onClick={open}
                          objectFit={"cover"}
                         />
                        </Box>
                       </Box>
                      </SplideSlide>
                     ))}
                    </Splide>
                   </Box>
                  ) : (
                   <Box
                    width="100%"
                    height="153px"
                    borderRadius="100%"
                    background="#F8FBFF"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="none"
                   >
                    <Img
                     src={editableMedia[0]}
                     alt="avatar"
                     width="100%"
                     height="153px"
                     cursor="pointer"
                     objectFit={"cover"}
                     onClick={open}
                    />
                   </Box>
                  )}
                 </>
                ) : (
                 <Box
                  background="#F8FBFF"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // marginTop="30px"
                  width="100%"
                  height="153px"
                  onClick={open}
                 >
                  <Img
                   src={Cloud}
                   alt="avatar"
                   marginTop="20px"
                   width="32px"
                   height="32px"
                   cursor="pointer"
                   //  onClick={open}
                  />
                 </Box>
                )}
               </>
              )}
             </Box>
            </Box>
           </Box>
          </Box>
          {/* <FormControl mb="20px">
                      <Checkbox
                        isChecked={featured}
                        onChange={(e) => setFeatured(e.target.checked)}
                      >
                        Featured
                      </Checkbox>
                    </FormControl> */}
          <FormControl
           w="100%"
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="40px"
           isRequired
           isInvalid={!!errors.experienceHost && touched.experienceHost}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Experience Host
           </FormLabel>
           {!registeringAs ? (
            <Field
             as={Input}
             {...inputStyle}
             type="text"
             placeholder=""
             id="experienceHost"
             name="experienceHost"
             pointerEvents="none"
            />
           ) : (
            <Select
             name="experienceHost"
             id="experienceHost"
             value={values.experienceHost}
             onChange={(option) => handlePartner(option, setFieldValue)}
             options={partnerOption}
             onBlur={handleBlur}
             styles={{
              control: (provided, state) => ({
               ...provided,
               borderRadius: "66px",
               background: "#F1F5F9",
               autoFocus: true,
               "&:hover": {
                background: "#F3F0FF",
                border: "1px solid #AC98FE",
               },
               "&:focus": {
                background: "#F3F0FF",
               },
               "&:focusVisible": {
                border: "1px solid #AC98FE",
               },
              }),
             }}
            />
           )}

           <FormErrorMessage>{errors.experienceHost}</FormErrorMessage>
          </FormControl>
          <FormControl
           w="100%"
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="40px"
           isRequired
           isInvalid={!!errors.title && touched.title}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Name of experience
           </FormLabel>

           <Field
            as={Input}
            {...inputStyle}
            type="text"
            placeholder="name"
            id="title"
            name="title"
           />
           <FormErrorMessage>{errors.title}</FormErrorMessage>
          </FormControl>
          <FormControl
           w="100%"
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="40px"
           isRequired
           isInvalid={!!errors.description && touched.description}
          >
           <FormLabel
            color={colors.darkBlue}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="24px"
            fontWeight="500"
           >
            Description
           </FormLabel>

           <TextEditor
            setFieldValue={(val) => setFieldValue("description", val)}
            value={values.description}
            placeholder="Description"
            isEdit={isEdit}
           />
           <FormErrorMessage>{errors.description}</FormErrorMessage>
          </FormControl>
          <FormControl
           w="100%"
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="40px"
           isRequired
           isInvalid={!!errors.included && touched.included}
          >
           <FormLabel
            color={colors.darkBlue}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="24px"
            fontWeight="500"
           >
            Included
           </FormLabel>
           <TextEditor
            setFieldValue={(val) => setFieldValue("included", val)}
            value={values.included}
            placeholder="What’s Included"
            isEdit={isEdit}
           />
           <FormErrorMessage>{errors.included}</FormErrorMessage>
          </FormControl>
          <FormControl
           w="100%"
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="40px"
           isInvalid={!!errors.additionInfo && touched.additionInfo}
          >
           <FormLabel
            color={colors.darkBlue}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="24px"
            fontWeight="500"
           >
            Additional Information
           </FormLabel>
           <TextEditor
            setFieldValue={(val) => setFieldValue("additionInfo", val)}
            value={values.additionInfo}
            placeholder="Additional Information"
            isEdit={isEdit}
           />
           <FormErrorMessage>{errors.additionInfo}</FormErrorMessage>
          </FormControl>
          <Box marginBottom="20px" marginTop="30px">
           <Flex alignItems={"center"} gap="20px">
            <Text
             color={colors.darkBlue}
             fontSize={{ base: "14px", lg: "16px" }}
             lineHeight="24px"
             fontWeight="500"
             minW={"max-content"}
            >
             What type of event is this?
            </Text>

            <Field style={fieldStyle} name={`experience`} padding={30}>
             {({ field, form }) => (
              <select
               style={fieldStyle}
               name={field.name}
               value={field.value}
               onChange={(event) => {
                // Your custom logic here
                resetForm({
                 values: {
                  ...values,
                  experiences: initialValues.experiences,
                 },
                });

                // Formik's default onChange handling
                form.handleChange(event);
               }}
              >
               {/* options here */}
               <option value={eventTypes.ONE_TIME}>
                One-day Event (one date and one time)
               </option>
               {/* <option value={eventTypes.MULTIPLE_TIMES}>
                One-day, multiple time slots event
               </option> */}
               {/* <option value={eventTypes.MULTIPLE_DATES_TIMES}>
              Multiple Occurrences Event (multiple dates and times)
             </option> */}
               <option value={eventTypes.RECURRING}>
                Recurring Event (happens daily, weekly, or monthly)
               </option>
               <option value={eventTypes.PACKAGED}>
                Special Offer (a packaged deal not tied to a date)
               </option>
              </select>
             )}
            </Field>
           </Flex>
           <Box>
            <FieldArray
             name="experiences"
             render={({ insert, remove, push }) => (
              <>
               {values?.experiences.length > 0 &&
                values?.experiences.map((value, index) => (
                 <>
                  <FieldArray
                   name={`experiences.${index}.timeSlot`}
                   render={({ insert, remove: removeTime, push: pushTime }) => (
                    <>
                     {value?.timeSlot?.length > 0
                      ? value.timeSlot?.map((item, id) => (
                         <Box marginTop="20px" marginBottom="20px" key={index}>
                          <FormControl
                           display="flex"
                           flexDir="column"
                           gap="10px"
                           alignItems="flex-start"
                           mb="10px"
                          >
                           {values.experience !== "packaged" && (
                            <Box
                             display="flex"
                             alignItems="start"
                             gap="20px"
                             mb="20px"
                             width="100%"
                            >
                             {values?.experience === "recurring" ? (
                              <RecurringSection
                               index={index}
                               disclosures1={disclosures1}
                               values={values}
                               setDisclosures1={setDisclosures1}
                               recurringOption={recurringOption}
                               setVisible={setVisible}
                               setSelectedModal={setSelectedModal}
                               setFieldValue={setFieldValue}
                              />
                             ) : id === 0 ? (
                              <DateSection
                               index={index}
                               values={values}
                               setFieldValue={setFieldValue}
                              />
                             ) : null}

                             <TimeSection
                              index={index}
                              id={id}
                              values={values}
                              disclosures={disclosures}
                              setDisclosures={setDisclosures}
                              setFieldValue={setFieldValue}
                              startDate={startDate}
                              setStartDate={setStartDate}
                              endDate={endDate}
                              setEndDate={setEndDate}
                              startTime={startTime}
                              setStartTime={setStartTime}
                              endTime={endTime}
                              setEndTime={setEndTime}
                              handleTimeInputChange={handleTimeInputChange}
                             />
                            </Box>
                           )}
                           {/* this shouldn't be under any condition */}

                           <TicketSection
                            index={index}
                            errors={errors}
                            id={id}
                           />

                           {/* beginning of subscribed partner */}
                           <SubscribedPartner
                            index={index}
                            id={id}
                            isNewTime={isNewTime}
                            partner={partner}
                            setIsNewTime={setIsNewTime}
                            setDisclosures={setDisclosures}
                            pushTime={pushTime}
                            removeTime={removeTime}
                            values={values}
                            value={value}
                            item={item}
                           />
                          </FormControl>
                         </Box>
                        ))
                      : null}
                    </>
                   )}
                  />

                  <ModalSubscription
                   getCheckboxProps={getCheckboxProps}
                   selectedModal={selectedModal}
                   value={value}
                   values={values}
                   containerRef={containerRef}
                   visible={visible}
                   setVisible={setVisible}
                   openDate={openDate}
                   days={days}
                   setFieldValue={setFieldValue}
                   push={push}
                   setIsNewDate={setIsNewDate}
                   setDisclosures={setDisclosures}
                   setOpenDate={setOpenDate}
                   getIn={getIn}
                   partner={partner}
                   setDisclosures1={setDisclosures1}
                   index={index}
                   isNewDate={isNewDate}
                  />
                 </>
                ))}
              </>
             )}
            />
           </Box>
          </Box>
          <FormControl
           w="100%"
           display="flex"
           flexDir="column"
           alignItems="flex-start"
           mb="20px"
           isRequired
           isInvalid={!!errors.location && touched.location}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Location
           </FormLabel>

           {experiences[0] && isEdit && (
            <Box w="100%" position="relative">
             <GooglePlacesAutocomplete
              apiKey="AIzaSyCYtSA1cCxQF6kELTV7ZXfYQEqMktTklWg"
              debounce={1000}
              selectProps={{
               defaultInputValue: editableAddress,
               onChange: setAddress,
              }}
              apiOptions={{ region: "NG" }}
              autocompletionRequest={{
               componentRestrictions: {
                country: "NG",
               },
              }}
              onLoadFailed={(error) => {}}
             />
            </Box>
           )}
           {registeredAddress && (
            <Box w="100%" position="relative">
             <GooglePlacesAutocomplete
              apiKey="AIzaSyCYtSA1cCxQF6kELTV7ZXfYQEqMktTklWg"
              debounce={1000}
              selectProps={{
               defaultInputValue: partner?.address,
               onChange: setAddress,
               styles: {
                input: (provided) => ({
                 ...provided,
                 pointerEvents: "none",
                }),
               },
              }}
              apiOptions={{ region: "NG" }}
              autocompletionRequest={{
               componentRestrictions: {
                country: "NG",
               },
              }}
              // onLoadFailed={(error) =>
              //   console.warn("errr", error)
              // }
             />
            </Box>
           )}
           {!isEdit && !registeredAddress && (
            <Box w="100%" position="relative">
             <GooglePlacesAutocomplete
              apiKey="AIzaSyCYtSA1cCxQF6kELTV7ZXfYQEqMktTklWg"
              debounce={1000}
              selectProps={{
               onChange: setAddress,
               styles: {
                singleValue: (provided) => ({
                 ...provided,
                 display: !address && "none",
                }),
               },
               required: true,
              }}
              apiOptions={{ region: "NG" }}
              autocompletionRequest={{
               componentRestrictions: {
                country: "NG",
               },
              }}
              onLoadFailed={(error) => {}}
             />
             {address && (
              <Icon
               as={MdOutlineClear}
               position="absolute"
               right="50px"
               top="10px"
               cursor="pointer"
               onClick={() => setAddress("")}
              />
             )}
            </Box>
           )}
          </FormControl>
          <FormControl mb="20px" display="flex" alignItems="center">
           <Text
            fontFamily="Satoshi"
            fontWeight="500"
            fontSize="14px"
            marginRight="10px"
           >
            Same as registered address
           </Text>
           <Switch
            size="sm"
            colorScheme="purple"
            isChecked={registeredAddress}
            onChange={(e) => setRegisteredAddress(e.target.checked)}
           />
          </FormControl>

          <Button
           color={colors.primaryWhite}
           bg={colors.primaryPurple}
           borderRadius={28}
           fontWeight={500}
           fontSize={18}
           p="24px 20px"
           width={{ base: "100%", md: "40%" }}
           type="submit"
          >
           {isEdit ? "Update" : "Save"}
          </Button>
         </Box>
         <Box></Box>
        </Box>
       </form>
      </MuiPickersUtilsProvider>
     );
    }}
   </Formik>{" "}
  </Box>
 );
};

export default AdminAddExperience;
