import { Box, Button, Popover, PopoverContent, Text } from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { colors } from "../../../../../../assets/styles/config";

const TimeSection = ({
 index,
 id,
 values,
 disclosures,
 setDisclosures,
 setFieldValue,
 startDate,
 setStartDate,
 endDate,
 setEndDate,
 startTime,
 setStartTime,
 endTime,
 setEndTime,
 handleTimeInputChange,
}) => (
 <Box width="100%">
  <Text
   fontFamily="Satoshi"
   color="#0F0931"
   fontSize={{
    base: "12px",
    md: "14px",
   }}
   lineHeight="21px"
   fontWeight="700"
   mb="10px"
  >
   Time
  </Text>
  {/* <DateRange /> */}
  <Box position="relative" width="100%">
   <Box
    background="#F1F5F9"
    width="100%"
    borderRadius="66px"
    padding="10px 24px"
    //onClick={onToggle}
    onClick={disclosures[index][id]?.onToggle}
    cursor="pointer"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    _hover={{
     background: "#F3F0FF",
     border: "1px solid #AC98FE",
    }}
    _focus={{
     background: "#F3F0FF",
    }}
    _focusVisible={{
     background: "#F3F0FF",
    }}
   >
    <Box>
     {values?.experiences[index]?.timeSlot[id].start} -{" "}
     {values?.experiences[index]?.timeSlot[id].end}
    </Box>
    {disclosures[index][id]?.isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
   </Box>
   <Popover
    isOpen={disclosures[index][id]?.isOpen}
    onClose={() =>
     //  deleted comment from here

     setDisclosures((prevDisclosures) => {
      const updatedDisclosures = {
       ...prevDisclosures,
      };
      updatedDisclosures[index] = prevDisclosures[index].map((disclosure, i) =>
       i === id
        ? {
           ...disclosure,
           isOpen: false,
          }
        : disclosure
      );
      return updatedDisclosures;
     })
    }
    placement="bottom"
   >
    <PopoverContent
     bg="#FFFFFF"
     borderRadius="20px"
     boxShadow="0px 4px 24px #D4DEEC"
     // position="absolute"
     padding="20px"
     outline="none"
     width="100%"
    >
     <Box display="flex" alignItems="center" gap="20px" width="100%">
      <Box
       className="timeinput"
       display="flex"
       alignItems="center"
       gap="10px"
       width="100%"
      >
       <input
        type="text"
        onChange={(event) => setStartDate(event.target.value)}
        value={startDate}
        maxLength="5"
        onInput={handleTimeInputChange}
        placeholder="hh:mm"
        // maxlength="5"
       />
       {/* deleted comment section from here */}
       <select
        name="startTime"
        value={startTime}
        onChange={(event) => setStartTime(event.target.value)}
       >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
       </select>
      </Box>

      <Box
       className="timeinput"
       display="flex"
       alignItems="center"
       gap="10px"
       width="100%"
      >
       <input
        type="text"
        onChange={(event) => setEndDate(event.target.value)}
        value={endDate}
        maxLength="5"
        onInput={handleTimeInputChange}
        placeholder="hh:mm"
        // maxlength="5"
       />
       {/* deleted comment section from here */}
       <select
        name="endTime"
        value={endTime}
        onChange={(event) => setEndTime(event.target.value)}
       >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
       </select>
      </Box>

      {/* delete comment section from here */}
     </Box>
     <Box
      marginTop="20px"
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-end"
     >
      <Button
       color={colors.primaryWhite}
       bg={colors.primaryPurple}
       borderRadius={28}
       fontWeight={500}
       fontSize={18}
       p="24px 30px"
       onClick={() => {
        if (startDate && endDate) {
         setFieldValue(
          `experiences[${index}].timeSlot[${id}].start`,
          `${startDate} ${startTime}`
         );
         setFieldValue(
          `experiences[${index}].timeSlot[${id}].end`,
          `${endDate} ${endTime}`
         );
         setStartDate("");
         setEndDate("");
         setStartTime("AM");
         setEndTime("AM");

         setDisclosures((prevDisclosures) => {
          const updatedDisclosures = {
           ...prevDisclosures,
          };
          updatedDisclosures[index] = prevDisclosures[index].map(
           (disclosure, i) =>
            i === id
             ? {
                ...disclosure,
                isOpen: false,
               }
             : disclosure
          );
          return updatedDisclosures;
         });
        }
       }}
      >
       Apply
      </Button>
     </Box>
    </PopoverContent>
   </Popover>
  </Box>
 </Box>
);

export default TimeSection;
