import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 Button,
 List,
 ListItem,
 SimpleGrid,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Switch,
 InputRightElement,
 InputGroup,
 Image,
 RadioGroup,
 Radio,
} from "@chakra-ui/react";
import { Formik, Field, useFormik } from "formik";
import { Icon, Loader, Modal, Toast } from "../../../../components";
import { colors } from "../../../../assets/styles/config";
import { db } from "../../../../firebase";
import { onValue, ref, set, update, getDatabase } from "firebase/database";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import { inputStyle, selectStyle } from "../styles";
import { sendPushNotification } from "../../../../api/user";

const AddRole = () => {
 const { user } = useContext(AuthContext);
 const options = ["yes", "no"];
 const rolesDB = ref(db, "cityExperience/roles");
 const [roleList, setRoleList] = useState([]);
 const [loading, setLoading] = useState(false);
 const navigate = useNavigate();
 const params = useParams();
 const location = useLocation();
 const role = params?.role;
 const [isEdit, setIsEdit] = useState(false);
 const [editableRole, setEditableRole] = useState({});

 useEffect(() => {
  if (location.pathname.includes("edit")) {
   setIsEdit(true);
  }
 }, [location]);

 // const { resetForm } = useFormik();
 const home = [
  { value: "dashboard", label: "Can access Dashboard" },
  { value: "navigator", label: "Can access navigator management" },
  { value: "collections", label: "Can access Collections" },
  { value: "journal", label: "Can access Navi Journal" },
  { value: "advertising", label: "Can access Advertising" },
  { value: "support", label: "Can access Support" },
 ];

 const administration = [
  { value: "teamMembers", label: "Can access Team members" },
  { value: "roles", label: "Can manage roles" },
 ];

 const partnerManagement = [
  { value: "partnerReg", label: "Can access partner registration" },
  { value: "partnerBank", label: "Can access partner settlement bank" },
  // { value: "access", label: "Can manage access" },
  { value: "partnerExp", label: "Can access partners experience" },
  { value: "redeemExp", label: "Can redeem experiences" },
  { value: "manageDeals", label: "Can manage deals" },
  { value: "manageReviews", label: "Can manage reviews" },
 ];

 const systemConfig = [
  {
   value: "cityGuides",
   label: "Can access cities and guides",
  },
  {
   value: "categoriesTags",
   label: "Can access categories and tags",
  },
  {
   value: "legalDoc",
   label: "Can access legal documents",
  },
 ];

 useEffect(() => {
  setLoading(true);
  const unsubscribe = onValue(rolesDB, (snapshot) => {
   const roles = snapshot.val();
   setRoleList(roles);
   setEditableRole(roles?.filter((item) => item?.role === role)[0]);
   if (roles?.length > 0) {
    setLoading(false);
   }
  });
  return () => {
   unsubscribe();
  };
 }, []);

 //   if (loading) {
 //     return <Loader />;
 //   }

 return (
  <Box height="100%" padding="14px 20px" background="#F5F8FA">
   {" "}
   <Box
    display="flex"
    alignItems="center"
    cursor="pointer"
    onClick={() => navigate(-1)}
   >
    <Image src={ArrowLeft} />
    <Text color="#252046" marginLeft="10px">
     Go Back
    </Text>
   </Box>
   <Box
    background="#FFFFFF"
    borderRadius="6px"
    mt="16px"
    minHeight="80vh"
    padding="30px"
   >
    <Box width={{ md: "538px" }}>
     <Formik
      initialValues={{
       roleName: "",
       home: {
        dashboard: "no",
        navigator: "no",
        collections: "no",
        journal: "no",
        advertising: "no",
        support: "no",
       },
       administration: {
        teamMembers: "no",
        roles: "no",
       },
       partnerManagement: {
        partnerReg: "no",
        partnerBank: "no",
        access: "no",
        partnerExp: "no",
        redeemExp: "no",
        manageDeals: "no",
        manageReviews: "no",
       },
       systemConfig: {
        cityGuides: "no",
        categoriesTags: "no",
        legalDoc: "no",
       },
      }}
      onSubmit={async (values) => {
       if (!isEdit) {
        const itemExists = roleList.find(
         (item) => item?.role.toLowerCase() === values?.roleName.toLowerCase()
        );

        if (itemExists) {
         toast.error("Role already exists");
        } else {
         toast.loading("loading");
         const payload = {
          role: values?.roleName.toUpperCase(),
          permission: JSON.stringify({
           home: values?.home,
           administration: values?.administration,
           partnerManagement: values.partnerManagement,
           systemConfig: values.systemConfig,
          }),
          createdBy: user?.role,
          type: "ADMIN",
         };

         // onSwitchStage(values);
         await set(rolesDB, [...roleList, payload])
          .then(async () => {
           toast.dismiss();
           toast.success("Successfully added role");
           navigate("/admin/roles");

           if (user) {
            await sendPushNotification({
             title: "admin",
             body: `${user?.name} added ${values?.roleName} as a new role`,
             data: {
              // ...user,
              route: `/admin/roles/edit/${values?.roleName.toUpperCase()}`,
              name: user?.name,
              photoURL: user?.photoURL,
              push_time: Date.now(),
             },
            });
           }
           // resetForm();
          })
          .catch((err) => {
           toast.dismiss();
           toast.error(err.message);
          })
          .finally(() => {
           setTimeout(() => {
            toast.dismiss();
           }, 3000);
          });
        }
       } else {
        toast.loading("loading");
        //const db = getDatabase();
        const updates = {};
        const findIndex = roleList.findIndex(
         (item) => item?.role === editableRole?.role
        );
        // console.log("findIndex", findIndex);
        const payload = {
         role: values?.roleName.toUpperCase(),
         permission: JSON.stringify({
          home: values?.home,
          administration: values?.administration,
          partnerManagement: values.partnerManagement,
          systemConfig: values.systemConfig,
         }),
         createdBy: user?.role,
         type: "ADMIN",
        };
        updates["cityExperience/roles/" + findIndex] = payload;

        await update(ref(getDatabase()), updates)
         .then(async () => {
          toast.dismiss();
          toast.success("Successfully updated role");

          if (user) {
           await sendPushNotification({
            title: "admin",
            body: `${user?.name} updated ${values?.roleName} role`,
            data: {
             // ...user,
             route: `/admin/roles/edit/${values?.roleName.toUpperCase()}`,
             name: user?.name,
             photoURL: user?.photoURL,
             push_time: Date.now(),
            },
           });
          }
          // return true;
         })
         .catch((error) => {
          // console.error(error.message);
          toast.dismiss();
          toast.error(err);
         })
         .finally(() => {
          setTimeout(() => {
           toast.dismiss();
          }, 3000);
         });
       }
      }}
     >
      {({
       handleSubmit,
       errors,
       touched,
       setFieldValue,
       handleBlur,
       values,
       // resetForm,
      }) => {
       useEffect(() => {
        if (isEdit && editableRole) {
         setFieldValue("roleName", editableRole.role?.toLowerCase());
         const permission =
          editableRole?.permission && JSON.parse(editableRole?.permission);
         if (permission) {
          Object.keys(permission)?.map((item) => {
           Object.keys(permission[item])?.map((perm) => {
            setFieldValue(`${item}.${perm}`, permission[item][perm]);
           });
          });
         }
        }
       }, [isEdit, editableRole]);
       return (
        <form onSubmit={handleSubmit}>
         <FormControl
          w="100%"
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          mb="20px"
          isRequired
          isInvalid={!!errors.roleName && touched.roleName}
         >
          <FormLabel
           fontFamily="Satoshi"
           color="#0F0931"
           fontSize={{ base: "12px", md: "14px" }}
           lineHeight="21px"
           fontWeight="700"
          >
           Role name
          </FormLabel>
          <Field
           as={Input}
           {...inputStyle}
           type="text"
           placeholder=""
           id="roleName"
           name="roleName"
          />
          <FormErrorMessage>{errors.roleName}</FormErrorMessage>
         </FormControl>
         <Box marginTop="30px">
          <Text
           fontFamily="Satoshi"
           color="#0F0931"
           fontSize={{ base: "12px", md: "14px" }}
           lineHeight="21px"
           fontWeight="700"
           marginBottom="10px"
          >
           Home
          </Text>
          <Box width="100%">
           {home?.map((item) => (
            <Field name={`home.${item.value}`}>
             {({ field, form }) => {
              const { onChange, ...rest } = field;
              return (
               <FormControl
                id={`home.${item.value}`}
                backgroundColor="#F1F5F9"
                borderRadius="24px"
                padding="16px 24px"
                // width="200px"
                width="100%"
                marginBottom="20px"
               >
                <FormLabel
                 htmlFor={`home.${item.value}`}
                 marginBottom="20px"
                 color="#475569"
                 fontFamily="Satoshi"
                >
                 {item.label}
                </FormLabel>
                <RadioGroup
                 {...rest}
                 id={`home.${item.value}`}
                 display="flex"
                 gap="20px"
                >
                 {options.map((value) => (
                  <Radio
                   onChange={onChange}
                   value={value}
                   colorScheme="purple"
                   // color="#334155"
                   // fontWeight="500"
                   // fontSize="14px"
                   // fontFamily="Satoshi"
                  >
                   {value.toUpperCase()}
                  </Radio>
                 ))}
                </RadioGroup>
               </FormControl>
              );
             }}
            </Field>
           ))}
          </Box>
         </Box>
         <Box marginTop="30px">
          <Text
           fontFamily="Satoshi"
           color="#0F0931"
           fontSize={{ base: "12px", md: "14px" }}
           lineHeight="21px"
           fontWeight="700"
           marginBottom="10px"
          >
           Administration
          </Text>
          <Box width="100%">
           {administration?.map((item) => (
            <Field name={`administration.${item.value}`}>
             {({ field, form }) => {
              const { onChange, ...rest } = field;
              return (
               <FormControl
                id={`administration.${item.value}`}
                backgroundColor="#F1F5F9"
                borderRadius="24px"
                padding="16px 24px"
                // width="200px"
                width="100%"
                marginBottom="20px"
               >
                <FormLabel
                 htmlFor={`administration.${item.value}`}
                 marginBottom="20px"
                 color="#475569"
                 fontFamily="Satoshi"
                >
                 {item.label}
                </FormLabel>
                <RadioGroup
                 {...rest}
                 id={`administration.${item.value}`}
                 display="flex"
                 gap="20px"
                >
                 {options.map((value) => (
                  <Radio
                   onChange={onChange}
                   value={value}
                   colorScheme="purple"
                   // color="#334155"
                   // fontWeight="500"
                   // fontSize="14px"
                   // fontFamily="Satoshi"
                  >
                   {value.toUpperCase()}
                  </Radio>
                 ))}
                </RadioGroup>
               </FormControl>
              );
             }}
            </Field>
           ))}
          </Box>
         </Box>
         <Box marginTop="30px">
          <Text
           fontFamily="Satoshi"
           color="#0F0931"
           fontSize={{ base: "12px", md: "14px" }}
           lineHeight="21px"
           fontWeight="700"
           marginBottom="10px"
          >
           Partner management
          </Text>
          <Box width="100%">
           {partnerManagement?.map((item) => (
            <Field name={`partnerManagement.${item.value}`}>
             {({ field, form }) => {
              const { onChange, ...rest } = field;
              return (
               <FormControl
                id={`partnerManagement.${item.value}`}
                backgroundColor="#F1F5F9"
                borderRadius="24px"
                padding="16px 24px"
                // width="200px"
                width="100%"
                marginBottom="20px"
               >
                <FormLabel
                 htmlFor={`partnerManagement.${item.value}`}
                 marginBottom="20px"
                 color="#475569"
                 fontFamily="Satoshi"
                >
                 {item.label}
                </FormLabel>
                <RadioGroup
                 {...rest}
                 id={`partnerManagement.${item.value}`}
                 display="flex"
                 gap="20px"
                >
                 {options.map((value) => (
                  <Radio
                   onChange={onChange}
                   value={value}
                   colorScheme="purple"
                   // color="#334155"
                   // fontWeight="500"
                   // fontSize="14px"
                   // fontFamily="Satoshi"
                  >
                   {value.toUpperCase()}
                  </Radio>
                 ))}
                </RadioGroup>
               </FormControl>
              );
             }}
            </Field>
           ))}
          </Box>
         </Box>
         <Box marginTop="30px">
          <Text
           fontFamily="Satoshi"
           color="#0F0931"
           fontSize={{ base: "12px", md: "14px" }}
           lineHeight="21px"
           fontWeight="700"
           marginBottom="10px"
          >
           System configuration
          </Text>
          <Box width="100%">
           {systemConfig?.map((item) => (
            <Field name={`systemConfig.${item.value}`}>
             {({ field, form }) => {
              const { onChange, ...rest } = field;
              return (
               <FormControl
                id={`systemConfig.${item.value}`}
                backgroundColor="#F1F5F9"
                borderRadius="24px"
                padding="16px 24px"
                // width="200px"
                width="100%"
                marginBottom="20px"
               >
                <FormLabel
                 htmlFor={`systemConfig.${item.value}`}
                 marginBottom="20px"
                 color="#475569"
                 fontFamily="Satoshi"
                >
                 {item.label}
                </FormLabel>
                <RadioGroup
                 {...rest}
                 id={`systemConfig.${item.value}`}
                 display="flex"
                 gap="20px"
                >
                 {options.map((value) => (
                  <Radio
                   onChange={onChange}
                   value={value}
                   colorScheme="purple"
                   // color="#334155"
                   // fontWeight="500"
                   // fontSize="14px"
                   // fontFamily="Satoshi"
                  >
                   {value.toUpperCase()}
                  </Radio>
                 ))}
                </RadioGroup>
               </FormControl>
              );
             }}
            </Field>
           ))}
          </Box>
         </Box>
         <Button
          color={colors.primaryWhite}
          bg={colors.primaryPurple}
          borderRadius={28}
          fontWeight={500}
          fontSize={18}
          marginTop={10}
          p="24px 20px"
          width={{ base: "100%", md: "25%" }}
          type="submit"
         >
          Done
         </Button>
        </form>
       );
      }}
     </Formik>
    </Box>
   </Box>
  </Box>
 );
};

export default AddRole;
