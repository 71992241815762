import { css } from '@emotion/react';
import styled from 'styled-components';
import { colors, fontWeight } from '../../../assets/styles/config';

export const Container = styled.div`
  padding: 0 2rem;

  @media screen and (min-width: 1080px) {
    padding: 0 4rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

export const Heading = styled.h1`
  font-size: 25px;
  line-height: 25px;

  font-weight: ${fontWeight.semiBold};
  color: ${colors.darkBlue};
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 33px;
    line-height: 45px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 50px;
    line-height: 75px;
  }
  ${(props) => props.css || ''}
`;

export const SlideWrapper = styled.div`
  margin-block: 2rem;
`;
export const SplideSlideWrapper = styled.div`
  position: relative;
  // width: 355px;
  height: 208px;
  cursor: pointer;
  transition: ease-in 0.2s;
  border-radius: 37px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }

  @media screen and (min-width: 768px) {
    height: 278px;
  }

  @media screen and (min-width: 1080px) {
    height: 328px;
  }
`;

export const SlideImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 37px;
`;

export const SlideTitle = styled.p`
  font-weight: ${fontWeight.semiBold};
  font-size: 22px;
  line-height: 35px;
  height: 77px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    font-size: 28px;
    line-height: 40px;
    height: 85px;
  }
  @media screen and (min-width: 1080px) {
    font-size: 32px;
    line-height: 48px;
    height: 100px;
  }
`;

export const SlideText = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  height: 70px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1080px) {
    font-size: 16px;
  }
`;

export const SlideDetails = styled.div`
  color: ${colors.darkBlue};
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  // height: 220px;
`;
