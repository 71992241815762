import React, { useContext, useState, useEffect } from "react";
import {
 Box,
 HStack,
 Text,
 SimpleGrid,
 Img,
 Input,
 List,
 ListItem,
 IconButton,
 UnorderedList,
 Flex,
 Avatar,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import Dropdown from "../../../assets/images/svg/dropdown2.svg";
import NaviLogo from "../../../assets/images/purpleLogoWhiteText.png";
import Logo from "../../../assets/images/Navi-Logo.png";
import roleLinks from "../../../assets/strings/roleSidebarLinks.js.js";
import { colors } from "../../../assets/styles/config";
import SideButtons from "./sidebarButton";
import {
 primaryDropdownText,
 subLinksListItem,
 subLinksText,
 username,
 userRole,
} from "./styles";
import {
 DashboardIcon,
 PartnersExpIcon,
 SupportIcon,
 RedeemExpIcon,
 ManageDealsIcon,
 DocumentUploadIcon,
 TeamMembersIcon,
 ManageRolesIcon,
 BankIcon,
} from "../../../components/Icon/sidebarIcons";
import { Dropdownsidebar, PartnerDropdownsidebar } from "./sidebarButton";
import Logout from "../../../assets/images/logout.svg";
import { Link } from "react-router-dom";

const Sidebar = ({ handleOpenMenu, isMobile, isOpen }) => {
 const { user, role, isLoading, signout } = useContext(AuthContext);
 const location = useLocation();
 const navigate = useNavigate();
 const [openPartner, setOpenPartner] = useState(false);
 const [openSubLink, setOpenSubLink] = useState(0);
 const [access_point, setAccessPoint] = useState([]);

 useEffect(() => {
  setAccessPoint(JSON.parse(localStorage.getItem("access_point")));
 }, [location]);

 const openLinks = (id) => {
  if (id === openSubLink) {
   setOpenSubLink(0);
  } else {
   setOpenSubLink(id);
  }
 };

 return (
  <Box
   backgroundColor="#0F0931"
   width={{ base: "250px", md: "300px" }}
   bottom="0"
   marginTop="0"
   position="fixed"
   top="0"
   paddingTop="75px"
   zIndex="350"
   overflowY={"scroll"}
   sx={{
    "::-webkit-scrollbar": {
     display: "none",
    },
   }}
  >
   {/* AVATAR ABOVE */}
   <Link
    to={
     role === "PARTNER" || user?.partnerUid
      ? "/partner/dashboard/profile"
      : "/admin/dashboard/profile"
    }
    onClick={() => handleOpenMenu()}
   >
    <Flex gap="1.25rem" padding="5px 30px" alignItems="center">
     <Avatar name={user?.name} src={user?.partnerPhotoURL ?? user?.photoURL} />
     <Flex flexDirection="column" gap={"0.5rem"}>
      <Text
       {...username}
       color={isMobile ? colors.darkGrey : colors.primaryWhite}
      >
       {role === "PARTNER"
        ? `${
           user?.firstName ? `${user?.firstName} ${user?.lastName}` : user?.name
          }`
        : user?.name}
      </Text>
      <Text {...userRole}>
       {user?.partner
        ? `${user?.partner}`
        : role === "PARTNER" && `${user?.businessName} `}
       {role !== "PARTNER" &&
        !user?.partner &&
        user?.role?.replace(/-/g, " ").toLowerCase()}
      </Text>
     </Flex>
    </Flex>
   </Link>
   <Box
    marginTop="1.5rem"
    width="100%"
    height="100%"
    display="flex"
    flexDir="column"
    // justifyContent="center"
    alignItems="flex-start"
    position="relative"
    flexGrow="1"
    // paddingTop="20px"
   >
    <List
     display="flex"
     flexDir="column"
     justifyContent="space-between"
     alignItems="flex-start"
     width="100%"
     // padding='5px 20px'
    >
     {role !== "PARTNER" ? (
      <SideButtons
       handleOpenMenu={handleOpenMenu}
       isMobile={isMobile}
       openLinks={openLinks}
       openSubLink={openSubLink}
       setOpenSubLink={setOpenSubLink}
       access_point={access_point}
      />
     ) : (
      <>
       <ListItem
        color="#fff"
        _hover={{ opacity: "0.9" }}
        textTransform="capitalize"
        justifyContent="flex-start"
        // padding='10px'
        width="100%"
        display="flex"
        fontSize="20px"
        fontWeight="400"
        lineHeight="140%"
        cursor="pointer"
        flexDir="column"
       >
        <PartnerDropdownsidebar
         name="dashboard"
         path="/partner/dashboard"
         icon={DashboardIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
        />
        <PartnerDropdownsidebar
         name="Upload Experiences"
         path="/partner/experience"
         icon={DocumentUploadIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
        />
        <PartnerDropdownsidebar
         name="redeem experiences"
         path="/partner/redeem-experiences"
         icon={RedeemExpIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
        />
        <PartnerDropdownsidebar
         name="manage deals"
         path="/partner/manage-deals"
         icon={ManageDealsIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
         premium={true}
        />
        <PartnerDropdownsidebar
         name="team members"
         path="/partner/members"
         icon={TeamMembersIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
         premium={true}
        />
        <PartnerDropdownsidebar
         name="manage roles"
         path="/partner/roles"
         icon={ManageRolesIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
         premium={true}
        />
        <PartnerDropdownsidebar
         name="business profile"
         path="/partner/business-profile"
         icon={TeamMembersIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
        />
        <PartnerDropdownsidebar
         name="Bank Details"
         path="/partner/bank"
         icon={BankIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
        />
        <PartnerDropdownsidebar
         name="support"
         path="/partner/support"
         icon={SupportIcon}
         handleOpenMenu={handleOpenMenu}
         isMobile={isMobile}
         isOpen={isOpen}
        />
       </ListItem>
      </>
      // roleLinks[role]?.map((item, id) => (
      //   <ListItem
      //     key={id}
      //     color="#fff"
      //     background={location.pathname.includes(item.path) && "#252046"}
      //     _hover={{ opacity: "0.9" }}
      //     textTransform="capitalize"
      //     justifyContent="flex-start"
      //     // padding='10px'
      //     width="100%"
      //     display="flex"
      //     fontSize="20px"
      //     fontWeight="400"
      //     lineHeight="140%"
      //     cursor="pointer"
      //     flexDir="column"
      //     marginBottom="20px"
      //     onClick={() => {
      //       isMobile && handleOpenMenu();
      //     }}
      //   >
      //     <Box
      //       display="flex"
      //       flexDirection="row"
      //       alignItems="center"
      //       justifyContent="space-between"
      //       onClick={() =>
      //         item.sub ? openLinks(item.subId) : navigate(item.path)
      //       }
      //       padding={"5px 30px"}
      //     >
      //       <Box display="flex" flexDirection="row" alignItems="center">
      //         {item.icon && (
      //           <Img src={item.icon} alt="icon" marginRight="10px" />
      //         )}

      //         <Text {...primaryDropdownText}>{item.name}</Text>
      //       </Box>

      //       {item?.sub && (
      //         <Img
      //           src={Dropdown}
      //           alt="drop"
      //           style={{ width: "14px" }}
      //           transform={
      //             openSubLink === item?.subId && "rotate(-90deg)"
      //           }
      //           transition="all .5s"
      //         />
      //       )}
      //     </Box>

      //     {item?.sub && (
      //       <List
      //         transform={
      //           openSubLink === item?.subId
      //             ? "translateX(0)"
      //             : "translateX(-250px)"
      //         }
      //         opacity={openSubLink === item?.subId ? "1" : "0"}
      //         height={openSubLink === item?.subId ? "fit-content" : "0px"}
      //         // overflow='hidden'
      //         transition={"all ease .7s"}
      //         marginTop={"10px"}
      //       >
      //         {item?.sub?.map((itm, id) => (
      //           <ListItem
      //             key={id}
      //             padding={"5px 30px"}
      //             color={
      //               location.pathname.includes(itm.path)
      //                 ? colors.primaryWhite
      //                 : "#8F8F8F"
      //             }
      //             background={
      //               itm.path.length > 1 &&
      //               location.pathname.includes(itm.path)
      //                 ? colors.fadedNavyBlue
      //                 : "transparent"
      //             }
      //             pointerEvents={itm.path.length < 2 ? "none" : "auto"}
      //             {...subLinksListItem}
      //             onClick={() => {
      //               setOpenSubLink(item.subId);
      //               navigate(itm.path);
      //               isMobile && handleOpenMenu();
      //             }}
      //             _hover={{ background: colors.fadedNavyBlueHover }}
      //           >
      //             {itm.icon && (
      //               <Img src={itm.icon} alt="icon" marginRight="10px" />
      //             )}
      //             <Text {...subLinksText}>{itm.name}</Text>
      //           </ListItem>
      //         ))}
      //       </List>
      //     )}
      //   </ListItem>
      // ))
     )}

     {user?.partnerUid !== undefined && (
      <>
       <ListItem
        color="#fff"
        _hover={{ opacity: "0.9" }}
        textTransform="capitalize"
        justifyContent="flex-start"
        // padding='10px'
        width="100%"
        display="flex"
        fontSize="20px"
        fontWeight="400"
        lineHeight="140%"
        cursor="pointer"
        flexDir="column"
       >
        {access_point?.includes("partner_dashboard") && (
         <PartnerDropdownsidebar
          name="dashboard"
          path="/partner/dashboard"
          icon={DashboardIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
        {access_point?.includes("partner_uploadExperiences") && (
         <PartnerDropdownsidebar
          name="Upload Experiences"
          path="/partner/experience"
          icon={DocumentUploadIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
        {access_point?.includes("partner_redeemExperiences") && (
         <PartnerDropdownsidebar
          name="redeem experiences"
          path="/partner/redeem-experiences"
          icon={RedeemExpIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
        {access_point?.includes("partner_manageDeals") && (
         <PartnerDropdownsidebar
          name="manage deals"
          path="/partner/manage-deals"
          icon={ManageDealsIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
        {access_point?.includes("partner_teamMembers") && (
         <PartnerDropdownsidebar
          name="team members"
          path="/partner/members"
          icon={TeamMembersIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
        {access_point?.includes("partner_roles") && (
         <PartnerDropdownsidebar
          name="manage roles"
          path="/partner/roles"
          icon={ManageRolesIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
        {access_point?.includes("partner_businessProfile") && (
         <PartnerDropdownsidebar
          name="business profile"
          path="/partner/business-profile"
          icon={TeamMembersIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
        {access_point?.includes("partner_bank") && (
         <PartnerDropdownsidebar
          name="Bank Details"
          path="/partner/bank"
          icon={BankIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
        {access_point?.includes("partner_support") && (
         <PartnerDropdownsidebar
          name="support"
          path="/partner/support"
          icon={SupportIcon}
          handleOpenMenu={handleOpenMenu}
          isMobile={isMobile}
          isOpen={isOpen}
         />
        )}
       </ListItem>
      </>
     )}
    </List>

    <List
     flexDir="column"
     justifyContent="space-between"
     alignItems="flex-start"
     width="100%"
     mt="20px"
     display={{ base: "flex", md: "none" }}
    >
     <ListItem
      display="flex"
      gap="10px"
      alignItems="center"
      padding={"5px 30px"}
      cursor="pointer"
      onClick={() => {
       signout();
      }}
     >
      <Img src={Logout} alt="logout" />
      <Text color="#B8B8B8">Logout</Text>
     </ListItem>
    </List>
    <Img
     src={isMobile ? Logo : NaviLogo}
     alt="logo"
     marginLeft="30px"
     marginTop={
      role === "PARTNER"
       ? "50px"
       : openSubLink === 0
       ? "70%"
       : openSubLink === 2
       ? "50%"
       : role !== "PARTNER"
       ? "20px"
       : ""
     }
    />
   </Box>
  </Box>
 );
};

export default Sidebar;
