import React from 'react';
import PropType from 'prop-types';
import LoadingIndicator from '../LoadingIndicator';
import { Icon, IconContainer } from './styles';
import registry from './registry';
import { colors } from '../../assets/styles/config';

const _Icon = React.forwardRef(
  (
    {
      id,
      className,
      name,
      size,
      opacity,
      style,
      onClick,
      alt,
      containerSize,
      border,
      backgroundColor,
      noContainer,
      pointer,
      borderColor,
      animateOnTap,
      animateOnHover,
      onMouseEnter,
      onMouseLeave,
      pointerImage,
      onKeyDown,
      css,
      isLoading,
      loadingIndicatorColor,
      loadingIndicatorSize,
      loadingIndicatorContainerCSS,
      withContainer,
    },
    ref
  ) => {
    let altName;
    if (!alt) {
      altName = `${name}-icon`;
    } else {
      altName = alt;
    }

    return !noContainer ? (
      <IconContainer
        withContainer={withContainer}
        className={className}
        ref={ref}
        id={id}
        whileTap={animateOnTap ? { scale: 1.05 } : {}}
        whileHover={animateOnHover ? { scale: 1.05 } : {}}
        onClick={onClick}
        {...{ containerSize, border, backgroundColor, borderColor }}
        style={{ ...style, cursor: pointer ? 'pointer' : 'default' }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...(pointer
          ? {
              role: 'button',
              tabIndex: 0,
              onKeyDown:
                onKeyDown !== null
                  ? onKeyDown
                  : (evt) => (evt?.key === 'Enter' ? onClick() : null),
            }
          : {})}
        css={css}
      >
        {!isLoading ? (
          <Icon
            className={className}
            width={size}
            height={size}
            src={registry[name]?.default}
            {...{ size, opacity, border }}
            alt={altName}
            style={pointerImage ? { cursor: 'pointer' } : {}}
          />
        ) : (
          <LoadingIndicator
            dotsColor={loadingIndicatorColor}
            size={loadingIndicatorSize}
            css={loadingIndicatorContainerCSS}
          />
        )}
      </IconContainer>
    ) : (
      <Icon
        ref={ref}
        id={id}
        src={registry[name]?.default}
        {...{ size, opacity, border, pointer }}
        alt={altName}
        style={style}
        onClick={onClick}
        whileTap={animateOnTap ? { scale: 1.05 } : {}}
        whileHover={animateOnHover ? { scale: 1.05 } : {}}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...(pointer
          ? {
              role: 'button',
              tabIndex: 0,
              onKeyDown:
                onKeyDown !== null
                  ? onKeyDown
                  : (evt) => (evt?.key === 'Enter' ? onClick() : null),
            }
          : {})}
        css={css}
      />
    );
  }
);

_Icon.defaultProps = {
  id: '',
  name: '',
  size: 20,
  opacity: 1,
  style: {},
  onClick: () => null,
  alt: '',
  containerSize: 40,
  border: false,
  backgroundColor: 'transparent',
  noContainer: false,
  pointer: false,
  borderColor: colors.primaryBlack,
  animateOnTap: true,
  animateOnHover: false,
  pointerImage: false,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
  onKeyDown: null,
  css: '',
  iconCSS: '',
  className: '',
  isLoading: false,
  loadingIndicatorColor: colors.primaryGreen,
  loadingIndicatorSize: 20,
  loadingIndicatorContainerCSS: '',
};
_Icon.propTypes = {
  id: PropType.string,
  name: PropType.string,
  size: PropType.number,
  opacity: PropType.number,
  style: PropType.shape({}),
  onClick: PropType.func,
  alt: PropType.string,
  containerSize: PropType.number,
  border: PropType.bool,
  backgroundColor: PropType.string,
  noContainer: PropType.bool,
  pointer: PropType.bool,
  borderColor: PropType.string,
  animateOnTap: PropType.bool,
  animateOnHover: PropType.bool,
  pointerImage: PropType.bool,
  onMouseEnter: PropType.func,
  onMouseLeave: PropType.func,
  onKeyDown: PropType.func,
  css: PropType.any,
  className: PropType.string,
  isLoading: PropType.bool,
  loadingIndicatorColor: PropType.string,
  loadingIndicatorSize: PropType.number,
  loadingIndicatorContainerCSS: PropType.any,
};

export default React.memo(_Icon);
