import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, fontWeight } from "../../../assets/styles/config";

export const Container = styled(motion.div)`
  background-color: ${colors.primaryWhite};
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
  overflow: scroll;
  z-index: 20;

  @media screen and (min-width: 768px) {
    padding: 4rem;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Title = styled.p`
  font-weight: ${fontWeight.semiBold};
  font-size: 24px;
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 45px;
    line-height: 75px;
  }
`;

export const Subtitle = styled.p`
  font-weight: ${fontWeight.semiBold};
  font-size: 18px;
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 32px;
    line-height: 54px;
  }
`;

export const CollectionsWrapper = styled.div`
  margin-top: 70px;
  display: grid;
  gap: 2rem;

  @media screen and (min-width: 820px) {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 4rem;
  }
`;

export const WrapCollection = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 1rem;

  img {
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
  }

  @media screen and (min-width: 768px) {
    height: 200px;
    grid-template-columns: 200px 1fr;
    gap: 2rem;
  }
`;

export const Detail = styled.p`
  display: flex;
  font-size: 14px;
  gap: 6px;
  cursor: pointer;
  transition: 0.2s ease;
  text-transform: capitalize;

  &:hover {
    color: ${colors.blue};
    opacity: 0.8;
    scale: 0.995;
  }

  .md-icons {
    font-size: 20px;
  }

  md-icons-2 {
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: 768px) {
    font-size: 15px;

    .md-icons {
      font-size: 24px;
    }

    .md-icons-2 {
      font-size: 26px;
    }
  }

  @media screen and (min-width: 1080px) {
    font-size: 16px;
    gap: 1.1rem;
    line-height: 24px;
  }
`;

export const AnchorTag = styled.a`
  color: ${colors.blue};
  font-weight: ${fontWeight.medium};
  font-size: 20px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const CloseBtn = styled.button`
  @media screen and (max-width: 768px) {
    justify-self: end;
    align-self: end;
  }
`;
