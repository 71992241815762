import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Input,
  Button,
  List,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
} from "@chakra-ui/react";
import ProfileFrame from "../../../assets/images/Profile-frame.svg";
import { Chart } from "../../../components";
import { Modal } from "../../../components";
import { colors } from "../../../assets/styles/config";
import {
  AreaChartHeader,
  AreaChartHeading,
  AreaChartWrapper,
  Card,
  CloseBtn,
  Container,
  DashboardHero,
  PieChartWrapper,
  StatisticsCardsWrapper,
  statisticsCardWrapper,
  StatisticsImg,
  StatisticsTitle,
  StatisticsValue,
} from "./styles";
import { Icon as CloseIconWrapper } from "../../../components";
import QrScan from "react-qr-reader";
import { AuthContext } from "../../../context/AuthContext";
import { Loader } from "../../../components";
import {
  getDatabase,
  ref,
  set,
  child,
  get,
  onValue,
  update,
} from "firebase/database";
import { db } from "../../../firebase";
import toast from "react-hot-toast";
import {
  MedalStarIcon,
  PeopleIcon,
  ProfileAddIcon,
  TicketIcon,
} from "../../../components/Icon/adminIcons";
import RecentlyBoughtExperiences from "./RecentlyBoughtExperiences";
import PieChartUpdate from "../../../components/Chart/PieChartUpdateDoc";
import SplineAreaChart from "../../../components/Chart/SplineAreaChart";
import { LoadingIndicator } from "../../../components";

const Dashboard = () => {
  const { role, user } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const containerRef = useRef(null);
  const [qrscan, setQrscan] = useState("No result");
  const [partners, setPartners] = useState([]);
  const [users, setUsers] = useState([]);
  const [experiences, setExperience] = useState([]);
  const [recentExperiences, setRecentExperience] = useState([]);
  const [loading, setLoading] = useState(false);

  // const filterObject = (obj) =>
  //   Object.keys(obj).reduce(
  //     (acc, val) =>
  //       obj[val]["role"] === "PARTNER"
  //         ? {
  //             ...acc,
  //             [val]: obj[val],
  //           }
  //         : acc,
  //     {}
  //   );

  // const filterObjectUser = (obj) =>
  //   Object.keys(obj).reduce(
  //     (acc, val) =>
  //       obj[val]["role"] === "USER"
  //         ? {
  //             ...acc,
  //             [val]: obj[val],
  //           }
  //         : acc,
  //     {}
  //   );

  const fetchPartner = async () => {
    const dbRef = ref(getDatabase());
    //get user experience
    const db = await get(child(dbRef, `users/partner`)).then((snapshot) => {
      if (snapshot.exists()) {
        const myObj = snapshot.val();

        setPartners(
          Object.keys(myObj).map((key) => {
            return myObj[key];
          })
        );
      }
    });

    return db;
  };

  const fetchUsers = async () => {
    const dbRef = ref(getDatabase());
    //get user experience
    const db = await get(child(dbRef, `users/user`)).then((snapshot) => {
      if (snapshot.exists()) {
        const myObj = snapshot.val();

        setUsers(
          Object.keys(myObj).map((key) => {
            return myObj[key];
          })
        );
      }
    });

    return db;
  };

  const fetchRecentExperience = async () => {
    setLoading(true);
    const dbRef = ref(getDatabase());
    //get user experience
    const db = await get(child(dbRef, `transactions/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = Object.values(snapshot.val());
          var sortedData = data
            ?.map((obj) => {
              return {
                ...obj,
                transationDate: new Date(JSON.parse(obj.transationDate)),
              };
            })
            .sort((a, b) => b.transationDate - a.transationDate);
          setRecentExperience(sortedData);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return db;
  };

  const fetchExperience = async () => {
    setLoading(true);
    const dbRef = ref(getDatabase());
    //get user experience
    const db = await get(child(dbRef, `cityExperience/experiences/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = Object.values(snapshot.val());

          // console.log("data", data);
          setExperience(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return db;
  };

  useEffect(() => {
    if (user) {
      fetchPartner();
      fetchUsers();
      fetchExperience();
      fetchRecentExperience();
    }
  }, []);

  const statistics = [
    {
      title: "Total Registered Partners",
      total: 0,
      icon: ProfileAddIcon,
      value: partners?.length,
      background: "#EFF6FF",
    },
    {
      title: "Total Registered Users",
      total: 0,
      icon: PeopleIcon,
      value: users?.length,
      background: "#F3E8FF",
    },
    {
      title: "Total Experiences",
      total: 0,
      icon: TicketIcon,
      value: experiences?.length,
      background: "#ECFEFF",
    },
    {
      title: "Total Subscriptions",
      total: 0,
      icon: MedalStarIcon,
      value: partners?.filter((item) => item.subscribed === true)?.length,
      background: "#FDF1E6",
    },
  ];

  const fetchDatabase = async (id) => {
    const dbRef = ref(getDatabase());
    const db = await get(child(dbRef, `users/user`)).then((snapshot) => {
      if (snapshot.exists()) {
        const myObj = snapshot.val();
        return Object.keys(myObj)
          .map((key) => {
            return myObj[key];
          })
          .filter((item) => item?.experiences)
          .filter((item) => item?.experiences[id]?.ticketTokens === id)[0];
      }
    });

    return db;
  };

  // const handleScan = async (data) => {
  //   if (data) {
  //     setQrscan(data);
  //     const partner = await fetchDatabase(data);
  //     const status = partner?.experiences[data]?.status;

  //     /* const partnerRef = ref(
  //       db,
  //       "users/" + user?.uid + "/experiences/" + data + "key"
  //     );
  //     console.log("partnerRef", partnerRef); */

  //     if (status === "unredeemed") {
  //       update(ref(db, "users/" + partner?.uid + "/experiences/" + data), {
  //         status: "redeemed",
  //       })
  //         .then(() => {
  //           toast.success(`ticker ${data} successfully redeemed`);
  //           setVisible(!visible);
  //         })
  //         .catch((error) => {
  //           //  console.error(error.message);
  //           toast.error(error.message);
  //           setVisible(!visible);
  //         })
  //         .finally(() => {
  //           setTimeout(() => {
  //             toast.dismiss();
  //           }, 3000);
  //         });
  //     } else {
  //       toast.success(`ticker ${data} already redeemed`);
  //       setVisible(!visible);
  //       setTimeout(() => {
  //         toast.dismiss();
  //       }, 2000);
  //     }

  //     //  set(partnerRef, "redeemed");

  //     // alert("token redeemed");
  //   }
  // };

  const handleError = (err) => {
    // console.error(err);
  };
  // const handleError = (err) => {
  //   console.error(err);
  // };

  return (
    <Box
      minHeight="100vh"
      height="100%"
      background="#F5F8FA"
      paddingRight="20px"
      paddingLeft={{ base: "20px", lg: 0 }}
      paddingBottom={"30px"}
    >
      {/* <Button
          background='#25AEFC'
          padding='10px'
          borderRadius='6px'
          color='#ffffff'
          onClick={() => setVisible(!visible)}
        >
          Redeem
        </Button> */}
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="40px"
        >
          <LoadingIndicator />
        </Box>
      ) : (
        <>
          <StatisticsCardsWrapper>
            {statistics.map((item, id) => (
              <Flex key={id} {...statisticsCardWrapper}>
                <StatisticsImg background={item.background}>
                  <img src={item.icon} />
                </StatisticsImg>
                <StatisticsTitle>{item.title}</StatisticsTitle>
                <StatisticsValue>{item.value || item.total}</StatisticsValue>
              </Flex>
            ))}
          </StatisticsCardsWrapper>
          {recentExperiences?.length > 0 ? (
            <DashboardHero>
              <RecentlyBoughtExperiences experiences={recentExperiences} />
              {/* <div>
<AreaChartWrapper>
 <AreaChartHeader>
   <AreaChartHeading>Total Revenue</AreaChartHeading>
 </AreaChartHeader>
 <SplineAreaChart />
</AreaChartWrapper>
<PieChartWrapper>
 {" "}
 <AreaChartHeading>Most Visited Experiences</AreaChartHeading>
 <PieChartUpdate />
</PieChartWrapper>
</div> */}
            </DashboardHero>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="30px"
            >
              <Text
                fontSize={{ base: "20px" }}
                lineHeight="125%"
                fontWeight="700"
                fontFamily="Satoshi"
                color="#334155"
              >
                No Recent Experience bought
              </Text>
            </Box>
          )}
        </>
      )}
      {/* <Modal
        {...{
          visible,
          setVisible,
          childrenContainerRef: containerRef,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        overlayLayer
        overlayLayerStyle={{
          backgroundColor: colors.black,
          opacity: 0.7,
        }}
      >
        <Container
          ref={containerRef}
          animate={{ scale: 1 }}
          initial={{ scale: 0.9 }}
        >
          <Card animate={{ scale: 1 }} initial={{ scale: 0.9 }}>
            <div>
              <CloseBtn
                style={{ cursor: "pointer" }}
                onClick={() => setVisible(false)}
              >
                <CloseIconWrapper name="close" animateOnTap pointer />
              </CloseBtn>
              <Text
                textAlign={"center"}
                fontSize={20}
                fontWeight={600}
                pt="3"
                pb="5"
              >
                {" "}
                Redeem a ticket
              </Text>
              <Box w="100%">
                <QrScan
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ height: 240, width: 320 }}
                />
              </Box>
            </div>
          </Card>
        </Container>
      </Modal> */}
    </Box>
  );
};

export default Dashboard;
