import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { colors } from "../../assets/styles/config";

export const CalfCardsWrapper = styled.div`
  margin-top: -10px;
  padding: 0px 32px 32px;
  margin-bottom: 33px;

  .splide__arrow svg {
    margin-top: 0 !important;
  }

  @media (min-width: 540px) {
    margin-top: initial;
    padding: 32px;
  }

  @media (min-width: 768px) {
    padding: 52px;
  }

  @media (min-width: 1240px) {
    padding: 0;
    margin-bottom: 194px;
  }
`;

export const SingleCard = styled.div`
  flex-basis: 334px;
  border-radius: 16px;
  position: relative;
`;

export const SingleCardImg = styled.img`
  border-radius: 16px;
  height: 387px;
  width: 100%;
  object-fit: cover;
`;

export const SingleCardText = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 121px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 32px;
  padding-top: 21px;
  padding-left: 17px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  line-height: 32px;

  @media (min-width: 768px) {
    line-height: 37px;
  }
`;

// TESTIMONIAL'S WRAPPER
export const TestimonialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 52px;
  padding-bottom: 102px;

  @media (min-width: 768px) {
    padding-block: 102px;
  }

  @media (min-width: 1080px) {
    padding-block: 172px;
  }
`;

export const TestimonialSlides = styled.div`
  display: flex;
  flex-direction: column;
  gap: 71px;
`;

export const TestimonialHeading = styled.p`
  color: #0f0931;
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  line-height: 56px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 44px;
    margin-bottom: 32px;
    line-height: 66px;
  }

  @media (min-width: 1080px) {
    font-size: 54px;
    line-height: 76px;
  }

  @media (min-width: 1240px) {
    font-size: 64px;
    line-height: 96px;
  }
`;

export const TestimonialCard = styled.div`
  background-color: #f1f5f9;
  padding: 16px;
  display: flex;
  gap: 12px;
  border-radius: 20px;
  height: 100%;
`;

export const TestimonialImg = styled.div`
  img {
    min-width: 40px;
    max-height: 40px;
    border-radius: 100px;
    object-fit: cover;
    width: 100%;
    border-radius: 100px;
    height: 100%;
  }
`;

export const TestimonialInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0f0931;
  }

  p {
    color: #334155;
  }
`;

// ACTIONS SLIDER WRAPPER
export const ActionsSliderWrapper = styled.div`
  // padding-inline: 30px;
  // padding-block: 30px;
  // gap: 23.85px;

  // @media (min-width: 540px) {
  //   padding-inline: 50px;
  // }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding-inline: 65px;
    padding-block: 37px;
    gap: 33.85px;
    min-height: 700px;
  }

  @media (min-width: 1080px) {
    padding-inline: 75px;
    padding-block: 47px;
    gap: 43.85px;
  }

  @media (min-width: 1240px) {
    padding-inline: 85px;
    padding-block: 67px;
    gap: 53.85px;
    max-height: 770px;
    overflow: hidden;
  }
`;

export const ActionsSliderLeftSide = styled(motion.div)`
  display: flex;
  flex-direction: column-reverse;
  gap: 18px;
  align-items: center;
  justify-content: center;

  img {
    height: 317px;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    flex-direction: column;

    img {
      border-radius: 26px;
      // max-width: 382px;
      height: 417px;
    }

    a {
      display: block;
    }
  }

  @media (min-width: 1080px) {
    img {
      // max-width: 512px;
      height: 517px;
    }
  }

  @media (min-width: 1240px) {
    img {
      // max-width: 712px;
      height: 617px;
    }
  }
`;

export const ActionsImages = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    max-height: 417px;
  }

  @media (max-width: 768px) {
    max-height: 317px;
  }
`;

export const ActionsMovingLine = styled(motion.div)`
  position: absolute;
  background-color: ${colors.primaryPurple};
  height: 200px;
  width: 2px;
  left: -2px;
`;

export const ActionsSliderLeftSideText = styled.div`
  display: flex;
  background: #ede7ff;
  height: 53px;
  padding: 15.5px 10px 15.5px 10px;
  border-radius: 35px;
  gap: 10px;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: ${colors.primaryPurple};
      font-family: Satoshi;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    svg {
      width: 17px;
    }
  }

  @media (min-width: 768px) {
    p {
      span {
        font-size: 16px;
      }

      svg {
        width: 26px;
      }
    }
  }
`;

export const ActionsSliderRightSide = styled.div`
  position: relative;
  display: none;
  flex-direction: column;
  gap: 62px;
  border-left: 2px solid #cbd5e1;
  padding-left: 26.15px;

  @media (min-width: 768px) {
    display: flex;
    gap: 60px;
  }

  @media (min-width: 1080px) {
    gap: 100px;
  }

  @media (min-width: 1240px) {
    gap: 132px;
  }
`;

export const ActionsSliderInfoWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ActionsSliderRightHeading = styled.p`
  font-size: 24px;
  line-height: 28px;
`;

export const ActionsSliderRightSubtext = styled.p`
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.03em;
  max-width: 501px;
`;
