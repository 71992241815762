import { colors } from "../../../../assets/styles/config";

export const fieldStyle = {
 background: colors.activeInputBg,
 outline: colors.activeBorder,
 borderRadius: 30,
 padding: "5px 5px",
 maxWidth: "265px",
};

export const tabListStyles = {
 padding: { md: "5px" },
 borderRadius: "32px",
 background: { base: "#FFFFFF", md: "#F1F5F9" },
 width: { base: "100%", md: "500px" },
 justifyContent: "center",
};

export const tabStyles = {
 fontSize: { base: "14px", md: "14px" },
 paddingTop: "10px",
 paddingBottom: "10px",
 width: { base: "100%", md: "250px" },
 letterSpacing: "1px",
};

export const tabTHStyles = {
 fontFamily: "Satoshi",
 color: "#252046",
 fontSize: { base: "12px", md: "14px" },
 lineHeight: "125%",
 fontWeight: "700",
 padding: "14px 20px",
 textTransform: "capitalize",
};

export const tabTRStyles = {
 padding: "14px 20px",
 fontSize: { base: "14px" },
 lineHeight: "125%",
 fontWeight: "500",
 fontFamily: "Satoshi",
};

export const tableTh = {
 fontFamily: "Satoshi",
 color: "#252046",
 fontSize: { base: "12px", md: "14px" },
 lineHeight: "125%",
 fontWeight: "700",
 padding: "14px 20px",
 textTransform: "capitalize",
};

export const tableTd = {
 padding: "14px 20px",
 fontSize: { base: "14px" },
 lineHeight: "125%",
 fontWeight: "500",
 fontFamily: "Satoshi",
 color: "#334155",
 cursor: "pointer",
};
