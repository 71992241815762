import { css } from '@emotion/react';
import styled from 'styled-components';
import { colors, fontWeight } from '../../../assets/styles/config';

export const Container = styled.div`
  padding: 2rem;

  @media screen and (min-width: 1080px) {
    padding: 4rem;
    padding-bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    justify-content: start;
    gap: 3rem;
  }
`;

export const Heading = styled.h1`
  font-size: 25px;
  line-height: 25px;

  font-weight: ${fontWeight.semiBold};
  color: ${colors.darkBlue};
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 33px;
    line-height: 45px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 50px;
    line-height: 75px;
  }
  ${(props) => props.css || ''}
`;

export const SlideWrapper = styled.div`
  margin-block: 2rem;
`;
export const SplideSlideWrapper = styled.div`
  position: relative;
  // width: 355px;
  height: 200px;
  cursor: pointer;
  transition: ease-in 0.2s;
  border-radius: 10px;
  background: #b3b1b1;

  @media screen and (min-width: 1080px) {
    &:hover {
      // box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      //   rgba(0, 0, 0, 0.23) 0px 6px 6px;
      // transform: translateY(-15px);
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    // width: 345px;
    height: 180px;
  }

  @media screen and (min-width: 1660px) {
    height: 250px;
  }
`;

export const SlideImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  mix-blend-mode: multiply;
`;

export const SlideText = styled.p`
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primaryWhite};
  letter-spacing: -0.0041em;
  bottom: 20px;
  left: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and {
    font-size: 20px;
  }
`;

export const SlideDivHack = styled.div`
  @media screen and (min-width: 1080px) {
    padding: 2rem 0;
    display: block;
  }
`;
