import React from "react";
import PropType from "prop-types";

import Indicator from "../LoadingIndicator";
import Icon from "../Icon";
import { colors } from "../../assets/styles/config";
import { PrimaryButton, ButtonText, ButtonSubtitle } from "./styles";

function Button({
  title,
  subtitle,
  buttonColor,
  textColor,
  padding,
  iconName,
  textSize,
  onClick,
  isLoading,
  iconSize,
  textStyle,
  style,
  animateOnTap,
  animateOnHover,
  type,
  disabled,
  children,
  indicatorColor,
  iconPosition,
  css,
  subtitleComponent,
  subtitleCSS,
  titleCSS,
  enableShadow,
  ...otherProps
}) {
  const handleClick = React.useCallback(
    (evt) => {
      evt.stopPropagation();
      onClick();
    },
    [onClick]
  );
  return (
    <PrimaryButton
      whileHover={{
        scale: animateOnHover ? 1.05 : 1,
      }}
      whileTap={{ scale: animateOnTap ? 0.98 : 1 }}
      onClick={handleClick}
      {...{
        buttonColor,
        padding,
        style,
        enableShadow,
        ...otherProps,
      }}
      type={type}
      disabled={disabled}
      height={style?.height || "54px"}
      css={css}
    >
      {!isLoading ? (
        children || (
          <>
            {iconName && iconPosition === "left" ? (
              <div className={title?.length ? "mr-2 icon" : ""}>
                <Icon name={iconName} noContainer size={iconSize} />
              </div>
            ) : null}
            <ButtonText
              textColor={textColor}
              {...{ textSize }}
              style={textStyle}
              css={titleCSS}
            >
              {title}
              {subtitle?.length ? (
                <ButtonSubtitle css={subtitleCSS} style={{ display: "block" }}>
                  {subtitle}
                </ButtonSubtitle>
              ) : null}
              {subtitleComponent}
            </ButtonText>
            {iconName && iconPosition === "right" ? (
              <div className={title?.length ? "ml-2 icon" : ""}>
                <Icon name={iconName} noContainer size={iconSize} />
              </div>
            ) : null}
          </>
        )
      ) : (
        <Indicator dotscolor={indicatorColor} />
      )}
    </PrimaryButton>
  );
}

Button.defaultProps = {
  title: "",
  subtitle: "",
  buttonColor: colors.primaryGreen,
  textColor: colors.primaryBlack,
  padding: "16px 24px",
  iconName: "",
  textSize: 14,
  onClick: () => null,
  isLoading: false,
  iconSize: 15,
  textStyle: {},
  style: {},
  animateOnTap: true,
  animateOnHover: false,
  type: "",
  disabled: false,
  children: null,
  indicatorColor: colors.primaryWhite,
  iconPosition: "left",
  css: "",
  titleCSS: "",
  subtitleComponent: null,
  enableShadow: false,
};

Button.propTypes = {
  title: PropType.oneOfType([PropType.string, PropType.number]),
  subtitle: PropType.oneOfType([PropType.string, PropType.number]),
  buttonColor: PropType.string,
  textColor: PropType.string,
  padding: PropType.string,
  iconName: PropType.string,
  textSize: PropType.number,
  onClick: PropType.func,
  isLoading: PropType.bool,
  iconSize: PropType.number,
  textStyle: PropType.shape({}),
  style: PropType.shape({ height: PropType.string }),
  animateOnTap: PropType.bool,
  animateOnHover: PropType.bool,
  type: PropType.string,
  disabled: PropType.bool,
  children: PropType.oneOfType([
    PropType.node,
    PropType.arrayOf(PropType.node),
    PropType.func,
  ]),
  indicatorColor: PropType.string,
  iconPosition: PropType.oneOf(["left", "right", "top", "bottom"]),
  css: PropType.any,
  subtitleCSS: PropType.any,
  titleCSS: PropType.any,
  subtitleComponent: PropType.oneOfType([
    PropType.node,
    PropType.arrayOf(PropType.node),
    PropType.func,
  ]),
  enableShadow: PropType.bool,
};

export default React.memo(Button);
