import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 Img,
 Text,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Checkbox,
 Select as ChakraSelect,
 Image,
 InputGroup,
 InputRightElement,
} from "@chakra-ui/react";
import { inputStyle, selectStyle } from "../styles";
import { css } from "styled-components";
import { Button } from "../../../../components";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import {
 getDatabase,
 ref as dbRef,
 set,
 onValue,
 update,
 remove,
 child,
 get,
} from "firebase/database";
import Select from "react-select";
import { httpsCallable } from "firebase/functions";
import { cf } from "../../../../firebase";
import axios from "axios";
import toast from "react-hot-toast";

const list_banks = httpsCallable(cf, "list_banks");

const AddBank = () => {
 const { user, updatePartner } = useContext(AuthContext);
 const params = useParams();
 const id = params?.id;
 const navigate = useNavigate();
 const [partners, setPartners] = useState("");
 const [partner, setPartner] = useState("");
 const [partnerOption, setPartnerOption] = useState([]);
 const [loading, setLoading] = useState(false);
 const [isEdit, setIsEdit] = useState(false);
 const [banks, setBanks] = useState([]);
 const [account, setAccount] = useState({});
 const paystackUrl = "https://api.paystack.co";
 const secretKey = process.env.REACT_APP_PAYSTACK_SECRET_KEY;
 //  PRODUCTION
 //  const secretKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;

 useEffect(() => {
  if (location.pathname.includes("edit")) {
   setIsEdit(true);
  }
 }, []);

 const fetchDatabase = async () => {
  setLoading(true);
  const db = await get(child(dbRef(getDatabase()), `users/partner`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     const myObj = snapshot.val();

     setPartners(
      Object.keys(myObj)
       .map((key) => {
        return myObj[key];
       })
       .filter((item) => item?.enabled)
     );
     setPartnerOption(
      Object.keys(myObj)
       .map((key) => {
        return myObj[key];
       })
       .filter((item) => item?.enabled)
       .filter((item) => !item?.bank)
       ?.map((item) => {
        return {
         value: item?.uid,
         label: item?.businessName,
        };
       })
     );
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  //get users order

  return db;
 };

 const fetchBanks = async () => {
  // await list_banks().then((res) => {
  //   console.log("res===", res);
  // });

  const paystackResponse = await axios.get(`${paystackUrl}/bank`, {
   headers: {
    Authorization: `Bearer ${secretKey}`,
   },
  });

  if (paystackResponse?.data?.status === true) {
   setBanks(
    paystackResponse?.data?.data?.map((item) => ({
     value: item?.code.toString(),
     label: item.name,
    }))
   );
  }
 };

 useEffect(() => {
  if (user) {
   fetchDatabase();
   fetchBanks();
  }
 }, [user]);

 const fetchSubAccount = async (id) => {
  const paystackResponse = await axios.get(`${paystackUrl}/subaccount/${id}`, {
   headers: {
    Authorization: `Bearer ${secretKey}`,
   },
  });

  if (paystackResponse?.data?.status === true) {
   setAccount(paystackResponse?.data?.data);
  }
 };

 useEffect(() => {
  if (isEdit) {
   fetchSubAccount(id);
  }
 }, [isEdit]);

 const handleSelectPartner = (setFieldValue, option) => {
  setFieldValue(`experienceHost`, option);
  const filterPartner = partners?.filter(
   (item) => item?.uid === option?.value
  )[0];

  setPartner(filterPartner);
 };

 return (
  <Box height="100%" padding="14px 20px" background="#F5F8FA">
   <Box
    display="flex"
    alignItems="center"
    cursor="pointer"
    onClick={() => navigate(-1)}
   >
    <Image src={ArrowLeft} />
    <Text color="#252046" marginLeft="10px">
     Go Back
    </Text>
   </Box>

   <Formik
    initialValues={{
     experienceHost: "",
     bankName: "",
     bankNumber: "",
     commission: "",
    }}
    onSubmit={async (values) => {
     toast.loading("loading");

     const payload = {
      business_name: values?.experienceHost?.label,
      settlement_bank: values?.bankName?.value,
      account_number: values?.bankNumber.toString(),
      percentage_charge: parseFloat(values?.commission),
     };

     var data = JSON.stringify(payload);
     let config;

     if (!isEdit) {
      config = {
       method: "post",
       url: `${paystackUrl}/subaccount`,
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${secretKey}`,
       },
       data: data,
      };
     } else {
      config = {
       method: "put",
       url: `${paystackUrl}/subaccount/${id}`,
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${secretKey}`,
       },
       data: data,
      };
     }

     await axios(config)
      .then(async (res) => {
       if (res?.data?.status === true) {
        await updatePartner({
         ...partner,
         bank: JSON.stringify(res?.data?.data),
        }).then(() => {
         toast.dismiss();
         if (!isEdit) {
          toast.success("Successfully added bank");
         } else toast.success("Successfully updated bank");

         navigate("/admin/bank");
        });
       }
      })
      .catch((error) => {
       toast.dismiss();
       toast.error(error?.response?.data?.message);
      })
      .finally(() => {
       setTimeout(() => {
        toast.dismiss();
       }, 3000);
      });
    }}
   >
    {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => {
     useEffect(() => {
      if (partner) {
       setFieldValue("commission", partner?.subscribed ? 2 : 5);
      }
     }, [partner]);

     useEffect(() => {
      if (isEdit && account && partners?.length) {
       const filterPartner = partners?.filter(
        (item) =>
         item?.bank &&
         JSON.parse(item?.bank)?.account_number === account?.account_number
       )[0];
       setPartner(filterPartner);
       setFieldValue("experienceHost", {
        value: filterPartner?.uid,
        label: filterPartner?.businessName,
       });
       setFieldValue("bankName", {
        value: account?.bank,
        label: account?.settlement_bank,
       });
       setFieldValue("bankNumber", account?.account_number);
       // setFieldValue("commission", {
       //   value: account?.percentage_charge,
       //   label: `${(account?.percentage_charge * 100).toString()}%`,
       // });
       setFieldValue("commission", parseFloat(account?.percentage_charge));
      }
     }, [isEdit, account, partners]);
     return (
      <form onSubmit={handleSubmit}>
       <Box
        background="#FFFFFF"
        borderRadius="6px"
        padding="30px"
        width="100%"
        mt="16px"
        marginX="auto"
       >
        <Box mt="20px" w="478px">
         <Box mb="30px">
          <Text
           color="#0F0931"
           fontFamily="Creato Display, Roboto, sans-serif;"
           fontSize="20px"
           fontWeight="700"
           marginBottom="20px"
          >
           Select Partner
          </Text>
          <FormControl>
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Experience host
           </FormLabel>
           <Select
            name="experienceHost"
            id="experienceHost"
            value={values.experienceHost}
            onChange={(option) => {
             handleSelectPartner(setFieldValue, option);
             // setFieldValue(`experienceHost`, option);
            }}
            options={partnerOption}
            onBlur={handleBlur}
            styles={{
             control: (provided, state) => ({
              ...provided,
              borderRadius: "66px",
              background: "#F1F5F9",
              autoFocus: true,
              "&:hover": {
               background: "#F3F0FF",
               border: "1px solid #AC98FE",
              },
              "&:focus": {
               background: "#F3F0FF",
              },
              "&:focusVisible": {
               border: "1px solid #AC98FE",
              },
              pointerEvents: isEdit && "none",
             }),
            }}
           />
          </FormControl>
         </Box>

         <Box>
          <Text
           color="#0F0931"
           fontFamily="Creato Display, Roboto, sans-serif;"
           fontSize="20px"
           fontWeight="700"
           marginBottom="20px"
          >
           Add new bank
          </Text>
          <FormControl
           w="100%"
           mb="20px"
           isRequired
           isInvalid={!!errors.bankName && touched.bankName}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Bank name
           </FormLabel>
           {/* <Field
                        as={Input}
                        {...inputStyle}
                        type="text"
                        placeholder=""
                        id="bankName"
                        name="bankName"
                      /> */}
           <Select
            name="bankName"
            id="bankName"
            value={values.bankName}
            onChange={(option) => {
             setFieldValue(`bankName`, option);
            }}
            options={banks}
            onBlur={handleBlur}
            styles={{
             control: (provided, state) => ({
              ...provided,
              borderRadius: "66px",
              background: "#F1F5F9",
              autoFocus: true,
              "&:hover": {
               background: "#F3F0FF",
               border: "1px solid #AC98FE",
              },
              "&:focus": {
               background: "#F3F0FF",
              },
              "&:focusVisible": {
               border: "1px solid #AC98FE",
              },
             }),
            }}
           />
           <FormErrorMessage>{errors.bankName}</FormErrorMessage>
          </FormControl>

          <FormControl
           w="100%"
           mb="20px"
           isRequired
           isInvalid={!!errors.bankNumber && touched.bankNumber}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Account number
           </FormLabel>
           <Field
            as={Input}
            {...inputStyle}
            type="number"
            placeholder=""
            id="bankNumber"
            name="bankNumber"
           />
           <FormErrorMessage>{errors.bankNumber}</FormErrorMessage>
          </FormControl>
         </Box>

         <Box marginBottom="30px">
          <Text
           color="#0F0931"
           fontFamily="Creato Display, Roboto, sans-serif;"
           fontSize="20px"
           fontWeight="700"
           marginBottom="20px"
          >
           Commission value
          </Text>

          <FormControl>
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Select commission value
           </FormLabel>
           <Field
            component={PercentageInput}
            {...inputStyle}
            type="number"
            placeholder=""
            id="commission"
            name="commission"
           />
           {/* <Select
                        name="commission"
                        id="commission"
                        value={values.commission}
                        onChange={(option) => {
                          setFieldValue(`commission`, option);
                        }}
                        options={commissionOption}
                        onBlur={handleBlur}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderRadius: "66px",
                            background: "#F1F5F9",
                            autoFocus: true,
                            "&:hover": {
                              background: "#F3F0FF",
                              border: "1px solid #AC98FE",
                            },
                            "&:focus": {
                              background: "#F3F0FF",
                            },
                            "&:focusVisible": {
                              border: "1px solid #AC98FE",
                            },
                          }),
                        }}
                      /> */}
          </FormControl>
         </Box>
         <Button
          animateOnTap
          animateOnHover
          buttonColor="#632EFF"
          css={css`
           width: fit-content;
           padding: 0.5rem 2rem;
           height: auto;
           font-weight: 500;
           font-family: "Creato Display";
           display: flex;
           gap: 15px;

           @media screen and (max-width: 768px) {
            border-radius: 10px;
           }
          `}
          type="submit"
         >
          {isEdit ? "Update Bank" : "Add Bank"}
         </Button>
        </Box>
       </Box>
      </form>
     );
    }}
   </Formik>
  </Box>
 );
};

export default AddBank;

function PercentageInput({ field, form, ...props }) {
 const { name, value } = field;
 const handleChange = (e) => {
  const newValue = e.target.value;
  form.setFieldValue(name, newValue);
 };

 return (
  <InputGroup>
   <Field name={name}>
    {({ field }) => (
     <Input
      {...props}
      {...field}
      type="number"
      placeholder="Enter commission"
      value={value}
      onChange={handleChange}
     />
    )}
   </Field>
   <InputRightElement children={<Text>%</Text>} />
  </InputGroup>
 );
}
