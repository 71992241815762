import { useCityContext } from '../../../context';
import Tabs from './Tabs';
import { Box } from '@chakra-ui/react';
import { Heading, SubTitle } from './styles';
import { headerbg as bg } from '../../../assets/strings/imageExports';
import { css } from 'styled-components';

const Hero = () => {
  const { currentCity } = useCityContext();
  const heroBoxStyles = {
    p: {
      base: '40px 30px 20px',
      md: '80px 30px 30px',
      lg: '60px 60px 30px',
    },
    // bg: `url(${bg})`,
    // bgSize: 'cover',
    // bgRepeat: 'no-repeat',
    // bgPosition: 'center -50px',
  };

  return (
    <Box
      {...heroBoxStyles}
      // marginTop={{ base: '50px', lg: '100px' }}
    >
      <Heading>{currentCity} Guide</Heading>
      <SubTitle
        css={css`
          @media screen and (max-width: 1080px) {
            display: none;
          }
        `}
      >
        Description
      </SubTitle>
      <Tabs />
    </Box>
  );
};

export default Hero;
