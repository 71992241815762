import { Box, Flex, Input, Text } from "@chakra-ui/react";
import React from "react";
import { useContext } from "react";
import { TbCurrencyNaira } from "react-icons/tb";
import { css } from "styled-components";
import { Button } from "../../../components";
import { AuthContext } from "../../../context/AuthContext";
import { Card, JustifyBetween, addInputStyle } from "../styles";
import parse from "html-react-parser";
import { useState } from "react";
import { onValue, ref } from "firebase/database";
import { db } from "../../../firebase";
import { colors } from "../../../assets/styles/config";
import { MdArrowBack } from "react-icons/md";

const OrderSummary = ({
 isPaying,
 experience,
 setExperience,
 pay,
 user,
 toast,
 setToast,
 //  setGuestIsUpdating,
}) => {
 const [orderExperience, setOrderExperience] = useState(experience);
 const [couponCode, setCouponCode] = useState("");

 const {
  chosenPrice,
  chosenQuantity,
  title,
  subscribed,
  percentage,
  fees,
  total,
 } = experience;

 const applyCouponCode = () => {
  const partnerRef = ref(db, "users/partner/" + experience?.partnerUid);

  onValue(partnerRef, (snapshot) => {
   const partnerData = snapshot.val();
   // CHECKING IF THE PARTNER THAT OWNS THAT EXPERIENCE HAS THE COUPON THAT THE USER ENTERED
   const hasCoupon = partnerData?.coupon?.find(
    (coupon) => coupon.couponCode === couponCode
   );

   // CHECKING IF THE COUPON EXISTS, IF THE COUPON IS ACTIVE, AND IF THE COUPON IS FOR THE EXPERIENCE THE USER IS BUYING.
   if (
    hasCoupon &&
    hasCoupon?.active &&
    hasCoupon?.experiences?.id === experience?.id
   ) {
    const newChosenPrice = (
     orderExperience.chosenPrice -
     (hasCoupon?.percentageOff / 100) * orderExperience.chosenPrice
    ).toFixed(2);
    const newTotal = Number(+newChosenPrice + +fees).toFixed(2);
    const newExperienceDetails = {
     ...experience,
     usedCoupon: true,
     chosenPrice: newChosenPrice,
     total: newTotal,
     coupon: hasCoupon,
    };

    setExperience(newExperienceDetails);
    setToast({
     ...toast,
     state: true,
     text: "Coupon applied successfully",
     bgColor: "lightgreen",
    });
    return;
   } else {
    setToast({
     ...toast,
     state: true,
     text: "Invalid Coupon",
     bgColor: "red",
    });
    // console.log("INVALID COUPON CODE ");
   }
  });
 };

 return (
  <Box width={{ base: "100%", md: "80%", lg: "40%" }}>
   <Flex alignItems={"center"} justifyContent={"center"} gap={4}>
    {/* {!user?.uid && user?.firstName ? (
     <button onClick={() => setGuestIsUpdating(true)}>
      <MdArrowBack size={25} />
     </button>
    ) : null} */}
    {user?.uid || user?.firstName ? (
     <Text
      paddingBlock="20px"
      fontWeight={"500"}
      fontSize={"20px"}
      fontFamily="Poppins"
     >
      <span
       style={{
        textTransform: "capitalize",
        fontWeight: 500,
        fontSize: 20,
        fontFamily: "Poppins",
       }}
      >{`Hello ${user?.name?.split(" ")[0] ?? `${user?.firstName}`}`}</span>
      , here's your order summary
     </Text>
    ) : null}
   </Flex>
   <Card
    css={css`
     width: 100%;
     max-height: 610px;

     @media (min-width: 1080px) {
      max-height: 570px;
     }
    `}
   >
    <Text
     fontWeight={500}
     fontSize={{ base: 18, md: 20, lg: 22, xl: 24 }}
     padding="1rem"
     paddingBlock={0}
     textTransform="capitalize"
    >
     {/* {parse(title)} */}
     Order Summary
    </Text>
    <JustifyBetween
     css={css`
      @media (max-width: 540px) {
       p:first-of-type {
        max-width: 60%;
       }
      }
     `}
    >
     <p style={{ display: "flex", gap: "8px" }}>
      {chosenQuantity}x {parse(title)} tickets
     </p>
     <Text display="flex" alignItems={"center"}>
      <TbCurrencyNaira /> {chosenPrice?.toLocaleString()}
     </Text>
    </JustifyBetween>
    <JustifyBetween>
     <p>Fees</p>
     <Text display="flex" alignItems={"center"}>
      <TbCurrencyNaira /> {fees?.toLocaleString()}
     </Text>
    </JustifyBetween>
    <JustifyBetween>
     <p>SubTotal</p>
     <Text display="flex" alignItems={"center"}>
      <TbCurrencyNaira /> {total?.toLocaleString()}
     </Text>
    </JustifyBetween>
    <JustifyBetween
     css={css`
      font-weight: 600;
     `}
    >
     <Text fontSize={{ base: 16, md: 18, lg: 20 }}>Total</Text>
     <Text display="flex" alignItems={"center"}>
      <TbCurrencyNaira /> {total?.toLocaleString()}
     </Text>
    </JustifyBetween>

    <Flex
     justifyContent="space-between"
     marginTop="26px"
     gap="12px"
     flexDirection={{ base: "column", md: "row" }}
    >
     <Input
      {...addInputStyle}
      value={couponCode}
      onChange={(e) => setCouponCode(e.target.value)}
     />

     <Button
      css={css`
       max-width: 146px;
       background: transparent;
       border: 2px solid ${colors.primaryPurple};
       color: ${colors.primaryPurple};
       max-height: 54px;

       &:disabled {
        opacity: 0.4;
       }

       @media (max-width: 768px) {
        min-width: 100%;
       }
      `}
      animateOnHover={true}
      animateOnTap
      title={"Apply"}
      onClick={() => applyCouponCode()}
      disabled={!couponCode}
     />
    </Flex>

    <Button
     css={css`
      margin-block: 50px;
      max-width: 100%;
      margin-inline: auto;
      margin-top: 35px;
      display: ${!user?.email ? "none" : ""};
     `}
     titleCSS={css`
      font-weight: 500;
      letter-spacing: 0.03em;
     `}
     buttonColor={colors.primaryPurple}
     animateOnHover={true}
     animateOnTap
     title={"Continue"}
     isLoading={isPaying}
     onClick={() => pay()}
     disabled={!user?.email}
    />
   </Card>
  </Box>
 );
};

export default OrderSummary;
