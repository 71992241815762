// localhost:4222/admin/partner-experience
import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 Text,
 Button,
 Input,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Image,
 Select as ChakraSelect,
 InputGroup,
 InputLeftElement,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { EmptyState } from "../../../../../components";
import { searchInputStyle, selectStyle } from "../../styles";
import { SearchIcon } from "../../../../../components/Icon/otherIcons";

import { colors } from "../../../../../assets/styles/config";
import TrashIcon from "../../../../../assets/images/trash-2.svg";
import { AuthContext } from "../../../../../context/AuthContext";
import Warning from "../../../../../assets/images/warning-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { getDatabase, ref, child, get, update } from "firebase/database";
import { LoadingIndicator, Modal } from "../../../../../components";
import { Card } from "../../styles";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import toast from "react-hot-toast";

dayjs.extend(advancedFormat);

const Experiences = () => {
 const { user } = useContext(AuthContext);
 const navigate = useNavigate();
 const [loading, setLoading] = useState(false);
 const [search, setSearch] = useState("");
 const [partnerExperience, setPartnerExperience] = useState([]);
 const [allExperiences, setAllExperiences] = useState([]);
 const [partner, setPartner] = useState([]);
 const [filterPartner, setFilterPartner] = useState([]);
 // const [orders, setOrders] = useState([]);
 const tableRef = useRef(null);
 const [registeringAs, setRegisteringAs] = useState("All");
 const options = ["All", "Business", "Individual"];
 const [modalType, setModalType] = useState("");
 const [selected, setSelected] = useState(null);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [experiencePartner, setExperiencePartner] = useState([]);

 // const filterObjectExperience = (obj) =>
 //   Object.keys(obj).reduce(
 //     (acc, val) =>
 //       obj[val]["partnerUid"] === id
 //         ? {
 //             ...acc,
 //             [val]: obj[val],
 //           }
 //         : acc,
 //     {}
 //   );

 const fetchDatabase = async (id) => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `cityExperience/experiences/`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     let objExp = snapshot.val();
     setAllExperiences(objExp);
     let arr = Object.keys(objExp)
      .map((key) => {
       return {
        ...objExp[key],
       };
      })
      ?.map((item) => item?.partnerUid);

     //remove duplicate
     setExperiencePartner(
      arr.filter((value, index) => arr.indexOf(value) === index)
     );
    } else {
     //  console.log("No data available");
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  return db;
 };

 const fetchPartner = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get partner
  const db = await get(child(dbRef, `users/partner`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     const myObj = snapshot.val();
     var array = Object.keys(myObj)
      .map((key) => {
       return myObj[key];
      })
      ?.filter((item) => experiencePartner?.includes(item?.uid));

     var sortedData = array
      ?.map((obj) => {
       return { ...obj, date: new Date(JSON.parse(obj.created_at)) };
      })
      .sort((a, b) => b.date - a.date);

     setPartner(sortedData);
    } else {
     //  console.log("No data available");
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  return db;
 };

 useEffect(() => {
  fetchDatabase();
 }, [user]);

 useEffect(() => {
  fetchPartner();
 }, [user, experiencePartner]);

 useEffect(() => {
  const filteredPartners =
   registeringAs === options[0]
    ? partner
    : partner?.filter(
       (item) =>
        item?.registeringAs?.toLowerCase() === registeringAs?.toLowerCase()
      );
  setFilterPartner(filteredPartners);
 }, [partner, registeringAs]);

 const lastDate = (id) => {
  const exp = Object.keys(allExperiences).reduce(
   (acc, val) =>
    allExperiences[val]["partnerUid"] === id
     ? {
        ...acc,
        [val]: allExperiences[val],
       }
     : acc,
   {}
  );
  const dates = Object.keys(exp)
   ?.map((key) => {
    return exp[key];
   })
   ?.map((item) => item?.timestamp && JSON.parse(item?.timestamp))
   .sort(function (a, b) {
    return new Date(b) - new Date(a);
   });

  return dayjs(dates[0]).format("DD-MM-YYYY hh:mm a");
 };

 const experiences = (id) => {
  const exp = Object.keys(allExperiences).reduce(
   (acc, val) =>
    allExperiences[val]["partnerUid"] === id
     ? {
        ...acc,
        [val]: allExperiences[val],
       }
     : acc,
   {}
  );

  return Object.keys(exp)?.map((key) => {
   return exp[key];
  })?.length;
 };

 const searchFilter = (text) => {
  if (text) {
   setSearch(text);
   const newData = partner.filter((item) => {
    const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setFilterPartner(newData);
  } else {
   setSearch(text);
   setFilterPartner(partner);
  }
 };

 // const truncateString = (string, maxLength) =>
 //   string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

 // console.log("experience", Object.keys(orders));

 const filterObjectExperience = (obj) =>
  Object.keys(obj).reduce(
   (acc, val) =>
    obj[val]["partnerUid"] !== selected
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 const handleDelete = async () => {
  toast.loading("loading");
  const db = getDatabase();
  const updates = {};

  const filterExp = filterObjectExperience(allExperiences);
  updates["cityExperience/experiences"] = filterExp;

  await update(ref(db), updates)
   .then(() => {
    toast.dismiss();
    toast.success("Successfully deleted partner experience");
    setVisible(!visible);
    setModalType("");
    setSelected(null);
    fetchDatabase();
    fetchPartner();
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 return (
  <Box height="100%" padding="20px" background="#F5F8FA">
   <Box
    background="#FFFFFF"
    borderRadius="6px"
    // mt="30px"
    minHeight="80vh"
    padding={{ base: "20px", md: "30px" }}
   >
    <Flex
     alignItems="center"
     flexDir={"row"}
     justifyContent="space-between"
     flexWrap={"wrap"}
    >
     <InputGroup
      borderRadius="28px"
      width={{ base: "50%", md: "50%" }}
      minW={100}
     >
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>

     <Box display="flex" flexDir={"row"} alignItems="center" gap="10px">
      <ChakraSelect
       width="100%"
       minW={120}
       onChange={(e) => {
        // setFieldValue("city", e.target.value);
        setRegisteringAs(e.target.value);
       }}
       id="city"
       name="city"
       {...selectStyle}
      >
       {options?.map((item, idx) => (
        <option value={item} key={idx}>
         {item}
        </option>
       ))}
      </ChakraSelect>

      <Link to={`/admin/partner-experience/add/registering/${registeringAs}`}>
       <Button
        color="#ffffff"
        bg="#632EFF"
        borderRadius="54px"
        fontWeight={500}
        fontSize={18}
        marginLeft="20px"
        type="button"
        display={{ base: "none", md: "inline-block" }}
       >
        <AddIcon h="16px" w="16px" mr="20px" /> Add Partner Experience
       </Button>
      </Link>

      <Link to={`/admin/partner-experience/add/registering/${registeringAs}`}>
       <Button
        color="#ffffff"
        bg="#632EFF"
        borderRadius="54px"
        fontWeight={500}
        fontSize={18}
        type="button"
        display={{ base: "inline-block", md: "none" }}
       >
        <AddIcon h="16px" w="16px" />
       </Button>
      </Link>
     </Box>
    </Flex>

    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt="30px">
      {filterPartner?.length > 0 ? (
       <TableContainer
        border="1px solid #E2E8F0"
        borderRadius="16px 16px 0px 0px"
       >
        <Table variant="simple" ref={tableRef}>
         <Thead>
          <Tr>
           {/* <Th
                      fontFamily="Satoshi"
                      color="#252046"
                      fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                      lineHeight="125%"
                      fontWeight="700"
                      padding="14px 20px"
                    >
                      Partner Id
                    </Th> */}
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Partner
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Email address
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Experiences
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Last Experience Date
           </Th>
           <Th></Th>
          </Tr>
         </Thead>
         <Tbody>
          {filterPartner?.map((item, index) => (
           <Tr
            key={index}
            cursor="pointer"
            _hover={{
             background: "#F1F5F9",
            }}
           >
            {/* <Td
                        padding="14px 20px"
                        fontSize={{ base: "14px" }}
                        lineHeight="125%"
                        fontWeight="500"
                        fontFamily="Satoshi"
                        color={item.enabled ? "#334155" : "#64748B"}
                        onClick={() =>
                          navigate(`/admin/partner-experience/${item.uid}`)
                        }
                      >
                        {truncateString(item?.uid, 8)}
                      </Td> */}
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color={item.enabled ? "#334155" : "#64748B"}
             onClick={() => navigate(`/admin/partner-experience/${item.uid}`)}
             pointerEvents={item.enabled ? "initial" : "none"}
            >
             {item?.businessName}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color={item.enabled ? "#334155" : "#64748B"}
             onClick={() => navigate(`/admin/partner-experience/${item.uid}`)}
             pointerEvents={item.enabled ? "initial" : "none"}
            >
             {item?.email}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color={item.enabled ? "#334155" : "#64748B"}
             onClick={() => navigate(`/admin/partner-experience/${item.uid}`)}
             pointerEvents={item.enabled ? "initial" : "none"}
             textAlign="flex-start"
            >
             {/* {item?.experiences &&
                        Object.keys(item?.experiences).length > 0
                          ? Object.keys(item?.experiences).length
                          : 0} */}
             {experiences(item.uid)}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color={item.enabled ? "#334155" : "#64748B"}
             onClick={() => navigate(`/admin/partner-experience/${item.uid}`)}
             pointerEvents={item.enabled ? "initial" : "none"}
             textAlign="flex-start"
            >
             {lastDate(item.uid)}
            </Td>
            <Td
             padding="14px 20px"
             pointerEvents={item.enabled ? "initial" : "none"}
            >
             <Box
              cursor="pointer"
              width="80px"
              onClick={() => {
               setVisible(!visible);
               setModalType("delete");
               setSelected(item.uid);
              }}
             >
              <Image src={TrashIcon} height="20px" />
             </Box>
            </Td>
           </Tr>
          ))}
         </Tbody>
        </Table>
       </TableContainer>
      ) : (
       <EmptyState text={"No partners' experience."} />
      )}
     </Box>
    )}
   </Box>
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
     //   style={{ marginTop: modalType === "add" && "300px" }}
    >
     {modalType === "delete" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Experience
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you want to delete this partner experience?
        </Text>
        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setModalType("");
          }}
         >
          Close
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default Experiences;
