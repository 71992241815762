export const listItemStyles = {
 display: "flex",
 alignItems: "center",
 justifyContent: "space-between",
 borderBottom: "0.5px solid #E2E8F0",
 padding: "10px 0",
};

export const listItemTxtStyles1 = {
 fontFamily: "Satoshi",
 fontWeight: "500",
 fontSize: "12px",
 color: "#64748B",
};

export const listItemTxtStyles2 = {
 fontFamily: "Satoshi",
 fontWeight: "500",
 fontSize: "14px",
 color: "#0F0931",
};

export const deleteBtnStyles = {
 color: "#0F0931",
 bg: "transparent",
 border: "1px solid #252046",
 borderRadius: 28,
 fontWeight: 500,
 fontSize: { base: 14, md: 18 },
 p: { base: "20px", md: "24px 20px" },
 width: "100%",
 type: "button",
};
