import { Box, Text } from "@chakra-ui/react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getIn } from "formik";
import React from "react";

const DateSection = ({ index, values, setFieldValue }) => (
 <Box width={"100%"}>
  <Text
   fontFamily="Satoshi"
   color="#0F0931"
   fontSize={{
    base: "12px",
    md: "14px",
   }}
   lineHeight="21px"
   fontWeight="700"
   mb="10px"
  >
   Date
  </Text>
  <KeyboardDatePicker
   id="date-picker-dialog"
   label="Date picker dialog"
   inputVariant="outlined"
   format="MM/dd/yyyy"
   value={getIn(values, `experiences[${index}]['date']`)}
   onChange={(value) => setFieldValue(`experiences[${index}]['date']`, value)}
   KeyboardButtonProps={{
    "aria-label": "change date",
   }}
   disablePast
  />
 </Box>
);

export default DateSection;
