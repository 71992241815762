import React, { useContext, useState } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Image,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Img1 from "../../assets/images/signup-phones.png";
import bgImg from "../../assets/images/Navi-Logo-Inversion.svg";
import styles from "./index.module.css";
import { colors } from "../../assets/styles/config";
import Logo from "../../assets/images/navi-logo-white.png";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ShowImg from "../../assets/images/Show.svg";
import HideImg from "../../assets/images/Hide.svg";
import "@splidejs/react-splide/css";
import { AuthContext } from "../../context/AuthContext";
import Header from "../../components/Header/index";
import { useNavigate } from "react-router-dom";
import NaviBg from "../../assets/images/lagos.jpeg";
import ArrowLeft from "../../assets/images/arrow-left.svg";
import { inputStyle } from "../Admin/Dashboard/styles";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgetPassword = () => {
  const navigate = useNavigate();
  const images = [Img1, Img1, Img1];
  const { handlePasswordReset } = useContext(AuthContext);
  return (
    <>
      {/* <Header color="dark" p={{ base: "15px 10px", lg: "20px 60px" }} /> */}
      <Box position="relative" overflow={"hidden"} width="100%" height="100vh">
        <Box backgroundColor="rgba(0, 0, 0, 0.58)">
          <Image
            src={NaviBg}
            objectFit="cover"
            width="100%"
            height="100vh"
            zIndex="0"
            mixBlendMode="multiply"
          />
        </Box>
        <Box
          position="absolute"
          left="30px"
          top="5%"
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={() => navigate(-1)}
        >
          <Image src={ArrowLeft} />
          <Text color="#ffffff" marginLeft="10px">
            Go Back
          </Text>
        </Box>
        <Box
          position="absolute"
          left="0"
          right="0"
          top="10%"
          zIndex="100"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Img
            src={Logo}
            h="60px"
            display={{ base: "block" }}
            marginBottom="20px"
          />
          <Box
            maxW="32rem"
            // mt={{ base: "50px", lg: "160px" }}
            mx="auto"
            w="100%"
            // w={{ base: "100%" }}
            padding={{ base: 5, xl: 10 }}
            h="100%"
            display="flex"
            flexDir={{ base: "column" }}
            alignItems={{ base: "flex-start", lg: "center" }}
            justifyContent="center"
            backgroundColor="#ffffff"
            borderRadius="2xl"
          >
            <Box w="100%">
              <Text
                color="#0F0931"
                fontSize={{ base: "24px" }}
                lineHeight="20px"
                fontWeight="700"
                textAlign="center"
                marginBottom="10px"
                fontFamily="Creato Display, Roboto, sans-serif;"
              >
                Forgot Password ?
              </Text>
              <Text
                color="#979797"
                fontSize={{ base: "18px" }}
                lineHeight="28px"
                fontWeight="400"
                textAlign="center"
                fontFamily="Creato Display, Roboto, sans-serif;"
                mt="10px"
              >
                Enter your email to reset your password
              </Text>
              <Box mt="20px" w="100%">
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  onSubmit={async (values) => {
                    await handlePasswordReset(values.email, "");
                  }}
                  validationSchema={validationSchema}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                      <FormControl
                        w="100%"
                        alignItems="center"
                        mb="20px"
                        isRequired
                        isInvalid={!!errors.email && touched.email}
                      >
                        <FormLabel
                          color="#1D1D1D"
                          fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                          lineHeight="140%"
                          fontWeight="400"
                          mb="10px"
                        >
                          Email
                        </FormLabel>
                        <Field
                          as={Input}
                          {...inputStyle}
                          type="email"
                          id="email"
                          name="email"
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>

                      <Button
                        color="#ffffff"
                        bg={colors.primaryPurple}
                        borderRadius={28}
                        fontWeight={500}
                        fontSize={18}
                        marginTop={10}
                        p="24px 20px"
                        w="100%"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgetPassword;
