import React, { useContext, useState } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Image,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ArrowLeft from "../../assets/images/arrow-left.svg";
import Img1 from "../../assets/images/Navi-Logo-Inversion 1.svg";
import bgImg1 from "../../assets/images/Navi-Logo-Inversion 2.svg";
import bgImg2 from "../../assets/images/Navi-Logo-Inversion 3.svg";
import styles from "./index.module.css";
import { colors } from "../../assets/styles/config";
import Logo from "../../assets/images/navi-logo-white.png";
import { Link } from "react-router-dom";
import ShowImg from "../../assets/images/Show.svg";
import HideImg from "../../assets/images/Hide.svg";
import { AuthContext } from "../../context/AuthContext";
import Header from "../../components/Header/index";
import NaviBg from "../../assets/images/lagos.jpeg";
import { useNavigate } from "react-router-dom";
import { inputStyle } from "./Dashboard/styles";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});
const ForgetPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { handlePasswordReset } = useContext(AuthContext);
  return (
    <>
      {/* <Header color="dark" p={{ base: "15px 10px", lg: "20px 60px" }} /> */}
      <Box position="relative" overflow={"hidden"} width="100%" height="100vh">
        <Box backgroundColor="rgba(0, 0, 0, 0.58)">
          <Image
            src={NaviBg}
            objectFit="cover"
            width="100%"
            height="100vh"
            zIndex="0"
            mixBlendMode="multiply"
          />
        </Box>
        <Box
          position="absolute"
          left="30px"
          top="5%"
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={() => navigate(-1)}
        >
          <Image src={ArrowLeft} />
          <Text color="#ffffff" marginLeft="10px">
            Go Back
          </Text>
        </Box>
        <Box
          position="absolute"
          left="0"
          right="0"
          top="10%"
          zIndex="100"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Img
            src={Logo}
            h="60px"
            display={{ base: "block" }}
            marginBottom="20px"
          />
          <Box
            maxW="32rem"
            // mt={{ base: "50px", lg: "160px" }}
            mx="auto"
            w="100%"
            // w={{ base: "100%" }}
            padding={{ base: 5, xl: 10 }}
            h="100%"
            display="flex"
            flexDir={{ base: "column" }}
            alignItems={{ base: "flex-start", lg: "center" }}
            justifyContent="center"
            backgroundColor="#ffffff"
            borderRadius="2xl"
          >
            <Box w="100%">
              <Text
                color="#0F0931"
                fontSize={{ base: "24px" }}
                lineHeight="20px"
                fontWeight="700"
                textAlign="center"
                marginBottom="10px"
                fontFamily="Creato Display, Roboto, sans-serif;"
              >
                Forgot Password ?
              </Text>
              <Text
                color="#878498"
                letterSpacing="0.1px"
                fontSize={{ base: "16px" }}
                lineHeight="20px"
                fontWeight="500"
                textAlign="center"
                marginBottom="20px"
                fontFamily="Satoshi"
              >
                Enter your email to reset your password
              </Text>
              <Box mt="40px" w="100%">
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  onSubmit={async (values) => {
                    const type = "admin";
                    await handlePasswordReset(values.email, type);
                  }}
                  validationSchema={validationSchema}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                      <FormControl
                        w="100%"
                        alignItems="center"
                        mb="24px"
                        isRequired
                        isInvalid={!!errors.email && touched.email}
                      >
                        <FormLabel
                          color={colors.darkBlue}
                          fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                          lineHeight="21px"
                          fontWeight="700"
                          mb="10px"
                          fontFamily="Satoshi"
                        >
                          Email
                        </FormLabel>
                        <Field
                          as={Input}
                          // borderRadius='10px'
                          // border='0.705598px solid #DFDDDD'
                          placeholder="email"
                          // p='10px 24px'
                          type="email"
                          // bg='#FEFEFF'
                          {...inputStyle}
                          id="email"
                          name="email"
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>

                      <Button
                        color={colors.primaryWhite}
                        bg={colors.primaryPurple}
                        borderRadius={28}
                        fontWeight={500}
                        fontSize={18}
                        marginTop={10}
                        p="24px 20px"
                        w="100%"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box
          className={styles.imgbg}
          display={{ base: "none", lg: "flex" }}
          position="relative"
        >
          <Img src={Img1} alt="navi-icon" height="180px" />
          <Img
            src={bgImg1}
            alt="navi-icon"
            height="200px"
            position="absolute"
            top="0"
            right="0"
          />
          <Img
            src={bgImg2}
            alt="navi-icon"
            height="200px"
            position="absolute"
            bottom="0"
            left="0"
          />
        </Box> */}
      </Box>
    </>
  );
};

export default ForgetPassword;
