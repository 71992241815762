import { Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout';
import RouteList from '../../../utils/RouteList';

const SystemConfig = () => {
  const navigate = useNavigate();
  useEffect(() => {
    //navigate(RouteList.SYSTEM_CONFIGS.TAGS);
  }, []);
  return (
    <Layout>
      {/* <Text
        as="h1"
        color="#6C6B6B"
        fontSize={{ base: 18, md: 21, lg: 24 }}
        padding={{ base: "2rem 2rem 0", md: "2rem 0", lg: "20px 0" }}
        fontWeight={600}
      >
        System Configuration
      </Text> */}
      <Outlet />
      <Text padding='20px' />
    </Layout>
  );
};

export default SystemConfig;
