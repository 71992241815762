import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  HStack,
  Img,
  Text,
  Button,
  List,
  ListItem,
  SimpleGrid,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Switch,
  InputRightElement,
  InputGroup,
  Image,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { Formik, Field, useFormik } from "formik";
import { colors } from "../../../../assets/styles/config";
import { db } from "../../../../firebase";
import { onValue, ref, set, update, getDatabase } from "firebase/database";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import { inputStyle } from "../../../Admin/Dashboard/styles";
import {
  sendPartnerPushNotification,
  sendPushNotification,
} from "../../../../api/user";

const AddPartnerRole = () => {
  const { user } = useContext(AuthContext);
  const options = ["yes", "no"];
  const uid = user?.partnerUid ?? user?.uid;
  const rolesDB = ref(db, `users/partner/${uid}/roles`);
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const role = params?.role;
  const [isEdit, setIsEdit] = useState(false);
  const [editableRole, setEditableRole] = useState({});

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEdit(true);
    }
  }, [location]);

  // const { resetForm } = useFormik();
  const roles = [
    { value: "partner_dashboard", label: "Can access Dashboard" },
    {
      value: "partner_uploadExperiences",
      label: "Can access upload experience",
    },
    {
      value: "partner_redeemExperiences",
      label: "Can access Redeem experiences",
    },
    { value: "partner_manageDeals", label: "Can manage deals" },
    { value: "partner_teamMembers", label: "Can access Team members" },
    { value: "partner_roles", label: "Can manage roles" },
    { value: "partner_businessProfile", label: "Can access Business Profile" },
    { value: "partner_bank", label: "Can access Bank Details" },
    { value: "partner_support", label: "Can access Support" },
  ];

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onValue(rolesDB, (snapshot) => {
      const roles = snapshot.val();
      setRoleList(roles);
      setEditableRole(roles?.filter((item) => item?.role === role)[0]);
      if (roles?.length > 0) {
        setLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  //   if (loading) {
  //     return <Loader />;
  //   }

  return (
    <Box
      height="100%"
      padding={{ base: "0px 20px", md: "14px 20px" }}
      background="#F5F8FA"
    >
      {" "}
      <Box
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        cursor="pointer"
        onClick={() => navigate(-1)}
      >
        <Image src={ArrowLeft} />
        <Text color="#252046" marginLeft="10px">
          Go Back
        </Text>
      </Box>
      <Box
        background="#FFFFFF"
        borderRadius="6px"
        mt="16px"
        minHeight="80vh"
        padding="30px"
      >
        <Box width={{ base: "100%", md: "538px" }}>
          <Formik
            initialValues={{
              roleName: "",
              roles: {
                partner_dashboard: "no",
                partner_uploadExperiences: "no",
                partner_redeemExperiences: "no",
                partner_manageDeals: "no",
                partner_teamMembers: "no",
                partner_roles: "no",
                partner_businessProfile: "no",
                partner_bank: "no",
                partner_support: "no",
              },
            }}
            onSubmit={async (values) => {
              if (!isEdit) {
                const itemExists = roleList?.find(
                  (item) =>
                    item?.role.toLowerCase() === values?.roleName.toLowerCase()
                );

                if (itemExists) {
                  toast.error("Role already exists");
                } else {
                  toast.loading("loading");
                  const payload = {
                    role: values?.roleName.toUpperCase(),
                    permission: JSON.stringify({
                      roles: values?.roles,
                    }),
                    createdBy: user?.role,
                    type: "PARTNER",
                  };

                  // onSwitchStage(values);
                  await set(
                    rolesDB,
                    roleList !== null ? [...roleList, payload] : [payload]
                  )
                    .then(async () => {
                      toast.dismiss();
                      toast.success("Successfully added role");
                      navigate("/partner/roles");

                      if (user) {
                        let name = user?.firstName
                          ? `${user?.firstName} ${user?.lastName}`
                          : user?.name;
                        await sendPartnerPushNotification({
                          title: "partner",
                          body: `${name} added ${values?.roleName} as a new role`,
                          data: {
                            // ...user,
                            uid: user?.partnerUid ?? user?.uid,
                            route: `/partner/roles`,
                            name: name,
                            photoURL: user?.partnerPhotoURL ?? user?.photoURL,
                            push_time: Date.now(),
                          },
                        });
                      }
                      // resetForm();
                    })
                    .catch((err) => {
                      toast.dismiss();
                      toast.error(err.message);
                    })
                    .finally(() => {
                      setTimeout(() => {
                        toast.dismiss();
                      }, 3000);
                    });
                }
              } else {
                toast.loading("loading");
                //const db = getDatabase();
                const updates = {};
                const findIndex = roleList?.findIndex(
                  (item) => item?.role === editableRole?.role
                );
                // console.log("findIndex", findIndex);
                const payload = {
                  role: values?.roleName.toUpperCase(),
                  permission: JSON.stringify({
                    roles: values?.roles,
                  }),
                  createdBy: user?.role,
                  type: "PARTNER",
                };
                updates[`users/partner/${uid}/roles/` + findIndex] = payload;

                await update(ref(getDatabase()), updates)
                  .then(async () => {
                    toast.dismiss();
                    toast.success("Successfully updated role");

                    if (user) {
                      let name = user?.firstName
                        ? `${user?.firstName} ${user?.lastName}`
                        : user?.name;
                      await sendPartnerPushNotification({
                        title: "partner",
                        body: `${name} updated ${values?.roleName} role`,
                        data: {
                          // ...user,
                          uid: user?.partnerUid ?? user?.uid,
                          route: `/partner/roles/edit/${values?.roleName.toUpperCase()}`,
                          name: name,
                          photoURL: user?.partnerPhotoURL ?? user?.photoURL,
                          push_time: Date.now(),
                        },
                      });
                    }
                    // return true;
                  })
                  .catch((error) => {
                    // console.error(error.message);
                    toast.dismiss();
                    toast.error(err);
                  })
                  .finally(() => {
                    setTimeout(() => {
                      toast.dismiss();
                    }, 3000);
                  });
              }
            }}
          >
            {({
              handleSubmit,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              values,
              // resetForm,
            }) => {
              useEffect(() => {
                if (isEdit && editableRole) {
                  setFieldValue("roleName", editableRole.role?.toLowerCase());
                  const permission =
                    editableRole?.permission &&
                    JSON.parse(editableRole?.permission);
                  if (permission) {
                    Object.keys(permission)?.map((item) => {
                      Object.keys(permission[item])?.map((perm) => {
                        setFieldValue(
                          `${item}.${perm}`,
                          permission[item][perm]
                        );
                      });
                    });
                  }
                }
              }, [isEdit, editableRole]);
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    w="100%"
                    display="flex"
                    flexDir="column"
                    alignItems="flex-start"
                    mb="20px"
                    isRequired
                    isInvalid={!!errors.roleName && touched.roleName}
                  >
                    <FormLabel
                      fontFamily="Satoshi"
                      color="#0F0931"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight="21px"
                      fontWeight="700"
                    >
                      Role name
                    </FormLabel>
                    <Field
                      as={Input}
                      {...inputStyle}
                      type="text"
                      placeholder=""
                      id="roleName"
                      name="roleName"
                    />
                    <FormErrorMessage>{errors.roleName}</FormErrorMessage>
                  </FormControl>
                  <Box marginTop="30px">
                    <Text
                      fontFamily="Satoshi"
                      color="#0F0931"
                      fontSize={{ base: "12px", md: "14px" }}
                      lineHeight="21px"
                      fontWeight="700"
                      marginBottom="10px"
                    >
                      Roles
                    </Text>
                    <Box width="100%">
                      {roles?.map((item) => (
                        <Field name={`roles.${item.value}`}>
                          {({ field, form }) => {
                            const { onChange, ...rest } = field;
                            return (
                              <FormControl
                                id={`roles.${item.value}`}
                                backgroundColor="#F1F5F9"
                                borderRadius="24px"
                                padding="16px 24px"
                                // width="200px"
                                width="100%"
                                marginBottom="20px"
                              >
                                <FormLabel
                                  htmlFor={`roles.${item.value}`}
                                  marginBottom="20px"
                                  color="#475569"
                                  fontFamily="Satoshi"
                                >
                                  {item.label}
                                </FormLabel>
                                <RadioGroup
                                  {...rest}
                                  id={`roles.${item.value}`}
                                  display="flex"
                                  gap="20px"
                                >
                                  {options.map((value) => (
                                    <Radio
                                      onChange={onChange}
                                      value={value}
                                      colorScheme="purple"
                                    >
                                      {value.toUpperCase()}
                                    </Radio>
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            );
                          }}
                        </Field>
                      ))}
                    </Box>
                  </Box>

                  <Button
                    color={colors.primaryWhite}
                    bg={colors.primaryPurple}
                    borderRadius={28}
                    fontWeight={500}
                    fontSize={18}
                    marginTop={10}
                    p="24px 20px"
                    width="25%"
                    type="submit"
                  >
                    Done
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPartnerRole;
