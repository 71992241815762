import {
  AdminIcon,
  AdvertisingIcon,
  CategoriesTagsIcon,
  CityGuidesIcon,
  CollectionIcon,
  DashboardIcon,
  DealIcon,
  LegalIcon,
  ManageAccessIcon,
  ManageDealsIcon,
  ManageReviewsIcon,
  ManageRolesIcon,
  NaviJournalIcon,
  NaviManagementIcon,
  PartnersExpIcon,
  PartnersRegIcon,
  RedeemExpIcon,
  SupportIcon,
  TeamMembersIcon,
} from "../../components/Icon/sidebarIcons";
import RouteList from "../../utils/RouteList";

const roleLinks = Object.freeze({
  "SUPER-ADMIN": [
    {
      name: "home",
      // path: '/',
      subId: 1,
      sub: [
        {
          name: "dashboard",
          path: "/admin/dashboard",
          icon: DashboardIcon,
        },
        {
          name: "navigator management",
          path: "/admin/navigator-management",
          icon: NaviManagementIcon,
        },
        {
          name: "collections",
          path: "/admin/collection",
          icon: CollectionIcon,
        },
        {
          name: "navi journal",
          path: "/",
          icon: NaviJournalIcon,
        },
        {
          name: "advertising",
          path: "/",
          icon: AdvertisingIcon,
        },
        {
          name: "support",
          path: "/",
          icon: SupportIcon,
        },
      ],
    },
    {
      name: "Administration",
      // path: '/admin/administrator',
      subId: 2,
      sub: [
        {
          name: "team members",
          path: "/admin/administrator",
          icon: TeamMembersIcon,
        },
        {
          name: "manage roles",
          path: "/admin/roles",
          icon: ManageRolesIcon,
        },
      ],
    },
    {
      name: "partner management",
      subId: 3,
      sub: [
        {
          name: "partners' registration",
          path: "/admin/partner-registration",
          icon: PartnersRegIcon,
        },
        // {
        //   name: 'manage access',
        //   path: '/',
        //   icon: ManageAccessIcon,
        // },
        {
          name: "partners' experience",
          path: "/admin/partner-experience",
          icon: PartnersExpIcon,
        },
        {
          name: "redeem experiences",
          path: "/redeem-experiences",
          icon: RedeemExpIcon,
        },
        {
          name: "manage deals",
          path: "/admin/partner-deals",
          icon: ManageDealsIcon,
        },
        {
          name: "manage reviews",
          path: "/",
          icon: ManageReviewsIcon,
        },
      ],
    },
    {
      name: "system configuration",
      subId: 4,
      sub: [
        {
          name: "Categories",
          path: RouteList.SYSTEM_CONFIGS.CATEGORIES,
          icon: CategoriesTagsIcon,
        },
        {
          name: "Tags",
          path: RouteList.SYSTEM_CONFIGS.TAGS,
          icon: CategoriesTagsIcon,
        },
        {
          name: "Cities and guides",
          path: RouteList.SYSTEM_CONFIGS.ROLES,
          icon: CityGuidesIcon,
        },
        // {
        //   name: 'Roles',
        //   path: RouteList.SYSTEM_CONFIGS.ROLES,
        //   icon: CityGuidesIcon,
        // },
        {
          name: "Legal",
          path: RouteList.SYSTEM_CONFIGS.LEGAL,
          icon: LegalIcon,
        },
      ],
    },
  ],
  PARTNER: [
    {
      name: "dashboard",
      path: "/dashboard",
    },
    {
      name: "experiences",
      path: "/experience",
    },
  ],
});

export default roleLinks;
