import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  HStack,
  Img,
  Text,
  Button,
  List,
  ListItem,
  SimpleGrid,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import EditIcon from "../../../../assets/images/edit.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { bytesToSize } from "../../../../utils/index";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../../firebase";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import Cloud from "../../../../assets/images/cloud-plus.svg";
import {
  profileFormControlStyles,
  profileFormFieldStyles,
  profileFormLabelStyles,
} from "../../../Admin/Dashboard/styles";

const profileValidationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  phoneNumber: Yup.string().required("Phone number is Required"),
});

const Profile = () => {
  const { user, updateUser, updatePartner, updatePartnerMember } =
    useContext(AuthContext);
  const [imageUrl, setImageUrl] = useState("");
  const [media, setMedia] = useState(null);
  //console.log("imageUrl", media);
  const navigate = useNavigate();
  // const { setFieldValue, setValues } = useFormik();

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setMedia(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      accept: "image/jpeg, image/png, video/gif, video/mp4",
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      maxSize: 10000000,
      minSize: 0,
      maxFiles: 1,
    });

  const handleUpload = async (file, data) => {
    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (err) => {},
      async () => {
        // download url
        await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          let payload;

          if (!user?.partnerUid) {
            let name = data?.name.split(" ");
            payload = {
              ...user,
              firstName: name[0],
              lastname: name[name.length - 1],
              phoneNumber: data?.phoneNumber,
              partnerPhotoURL: [url],
            };
            await updatePartner(payload).then(() => {
              window.location.reload();
            });
          } else {
            payload = {
              ...user,
              name: data?.name,
              phoneNumber: data?.phoneNumber,
              partnerPhotoURL: [url],
            };
            await updatePartnerMember(payload).then(() => {
              window.location.reload();
            });
          }
        });
      }
    );
  };

  return (
    <Box
      height="100%"
      padding={{ base: "0px", md: "14px 20px" }}
      background="#F5F8FA"
    >
      <Box
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        cursor="pointer"
        onClick={() => navigate(-1)}
      >
        <Img src={ArrowLeft} />
        <Text color="#252046" marginLeft="10px">
          Go Back
        </Text>
      </Box>
      <Box background="#FFFFFF" borderRadius="16px" mt="16px">
        <Box padding="20px 30px">
          <Text
            color="#334155"
            fontSize={{ base: "18px", md: "20px" }}
            lineHeight="32px"
            fontWeight="700"
            mb="20px"
            fontFamily="Creato Display, Roboto, sans-serif;"
          >
            Profile
          </Text>
          <Formik
            initialValues={{
              name: "",
              phoneNumber: "",
            }}
            onSubmit={async (values) => {
              toast.loading("loading");
              let payload;

              if (media !== null) {
                await handleUpload(media[0], values);
              } else {
                if (!user?.partnerUid) {
                  let name = values?.name.split(" ");
                  payload = {
                    ...user,
                    firstName: name[0],
                    lastName: name[name.length - 1],
                    phoneNumber: values?.phoneNumber,
                  };
                  await updatePartner(payload);
                } else {
                  payload = {
                    ...user,
                    name: values?.name,
                    phoneNumber: values?.phoneNumber,
                  };
                  await updatePartnerMember(payload);
                }
              }
            }}
            validationSchema={profileValidationSchema}
          >
            {({ handleSubmit, errors, touched, setFieldValue }) => {
              useEffect(() => {
                async function setInitialValues() {
                  const fields = ["phoneNumber", "address", "email"];

                  if (!user?.partnerUid) {
                    setFieldValue(
                      "name",
                      `${user?.firstName} ${user?.lastName}`,
                      false
                    );
                    setFieldValue("role", "OWNER", false);
                  } else {
                    setFieldValue("name", user?.name, false);
                    setFieldValue("role", user?.role, false);
                  }

                  if (user) {
                    fields.forEach((field) => {
                      setFieldValue(field, user[field], false);
                    });
                    setImageUrl(user?.partnerPhotoURL);
                  }
                }
                setInitialValues();
              }, [user]);
              return (
                <form onSubmit={handleSubmit}>
                  <Box w={{ base: "100%", md: "478px" }}>
                    <Box alignItems="center" marginBottom="30px">
                      <Box display="flex" alignItems="center" flexDir="column">
                        <Box
                          width={{ base: "100%", md: "478px" }}
                          borderRadius="9px"
                          height="100%"
                          display="flex"
                          flexDirection="column"
                          background="#F1F5F9"
                          justifyContent="center"
                          alignItems="center"
                          cursor="pointer"
                          padding="20px"
                          {...getRootProps()}
                        >
                          <Box
                            marginBottom="20px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            <Text
                              color="#0F0931"
                              fontFamily="Creato Display, Roboto, sans-serif;"
                              fontSize="16px"
                              lineHeight="135%"
                            >
                              Upload profile Image
                            </Text>
                            <Text
                              width="60%"
                              textAlign="center"
                              color="#64748B"
                              fontFamily="Satoshi"
                              fontSize="12px"
                              lineHeight="135%"
                            >
                              JPG, .PNG images are allowed Maximum size of 10mb
                            </Text>
                          </Box>
                          <Box
                            width="125px"
                            height="125px"
                            borderRadius="100%"
                            background="#F8FBFF"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <input {...getInputProps()} />
                            {media ? (
                              <Img
                                src={media[0]?.preview}
                                alt="avatar"
                                width="100%"
                                height="125px"
                                cursor="pointer"
                                borderRadius="100%"
                                onClick={open}
                              />
                            ) : imageUrl ? (
                              <Img
                                src={imageUrl}
                                alt="avatar"
                                width="100%"
                                height="125px"
                                cursor="pointer"
                                borderRadius="100%"
                                onClick={open}
                              />
                            ) : (
                              <Img
                                src={Cloud}
                                alt="avatar"
                                width="32px"
                                height="32px"
                                cursor="pointer"
                                onClick={open}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <FormControl
                      {...profileFormControlStyles}
                      isRequired
                      isInvalid={!!errors.name && touched.name}
                    >
                      <FormLabel {...profileFormLabelStyles}>Name</FormLabel>
                      <Field
                        as={Input}
                        placeholder="full name"
                        id="name"
                        name="name"
                        {...profileFormFieldStyles}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...profileFormControlStyles}
                      isRequired
                      isInvalid={!!errors.email && touched.email}
                    >
                      <FormLabel {...profileFormLabelStyles}>
                        Email Address
                      </FormLabel>
                      <Field
                        as={Input}
                        placeholder="example@gmail.com"
                        id="email"
                        name="email"
                        {...profileFormFieldStyles}
                        pointerEvents="none"
                      />
                    </FormControl>

                    <FormControl
                      {...profileFormControlStyles}
                      isRequired
                      isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                    >
                      <FormLabel {...profileFormLabelStyles}>
                        Phone number
                      </FormLabel>
                      <Field
                        as={Input}
                        placeholder="phone number"
                        id="phoneNumber"
                        name="phoneNumber"
                        {...profileFormFieldStyles}
                      />
                      <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isRequired
                      {...profileFormControlStyles}
                      isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                    >
                      <FormLabel {...profileFormLabelStyles}>Role</FormLabel>
                      <Field
                        as={Input}
                        id="role"
                        name="role"
                        readonly
                        {...profileFormFieldStyles}
                        pointerEvents="none"
                      />
                      <FormErrorMessage>{errors.role}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...profileFormControlStyles}
                      isInvalid={!!errors.address && touched.address}
                    >
                      <FormLabel {...profileFormLabelStyles}>
                        Date Joined
                      </FormLabel>
                      <Field
                        as={Input}
                        placeholder="10 - 03 - 2023"
                        id="dateJoined"
                        name="dateJoined"
                        {...profileFormFieldStyles}
                      />
                      <FormErrorMessage>{errors.dateJoined}</FormErrorMessage>
                    </FormControl>

                    <Box
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      display="flex"
                    >
                      <Text
                        color="#4A0DFF"
                        fontSize="16px"
                        fontWeight="400"
                        fontFamily="Creato Display, Roboto, sans-serif;"
                        cursor="pointer"
                        onClick={() => navigate("/admin/forget-password")}
                      >
                        Change password
                      </Text>
                    </Box>

                    <Box
                      // display="flex"
                      // alignItems=""
                      // justifyContent="flex-end"
                      marginTop={10}
                      width="100%"
                    >
                      <Button
                        color="#ffffff"
                        bg="#632EFF"
                        borderRadius={28}
                        fontWeight={500}
                        fontSize={18}
                        p="25px"
                        type="submit"
                        width="100%"
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
