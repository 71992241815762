import FAQ from "./FAQ";
import Hero from "./Hero";
import Collections from "./Collections";
import { Box } from "@chakra-ui/react";
import { Footer } from "../../components";
import { Header } from "../../components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCityContext } from "../../context";
import { actionTypes } from "../../reducer";
import { ref, onValue } from "firebase/database";
import { db } from "../../firebase";
import Loader from "../../components/Loader";
import Blog from "./Blog";
import { cityPageBg } from "../../assets/strings/imageExports";
import { Main } from "./styles";

const CityApp = () => {
  const [loading, setLoading] = useState(true);
  const { cityId } = useParams();
  const { dispatch } = useCityContext();
  const cityExperienceDb = ref(db, "cityExperience/city");

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onValue(cityExperienceDb, (snapshot) => {
      const data = snapshot.val();

      const { collections, coverImage, experience, usefulInfo, blog } =
        data[cityId.toString()];

      dispatch({
        type: actionTypes.SET_CITY_DATA,
        payload: {
          currentCity: cityId,
          blog,
          collections,
          coverImage,
          experience,
          usefulInfo,
        },
      });
      if (coverImage) {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [cityId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Main bgImage={cityPageBg}>
        <Header color="dark" bgColor={"transparent"} position="static" />
        <Hero />
      </Main>
      <Collections />
      <Blog />
      <FAQ />
      <Footer />
    </Box>
  );
};

export default CityApp;
