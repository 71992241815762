import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 List,
 ListItem,
 SimpleGrid,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Switch,
 InputRightElement,
 InputGroup,
 InputLeftElement,
 Image,
 Button,
 Icon,
} from "@chakra-ui/react";
import styles from "./index.module.css";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import { Button as CustomButton } from "../../../../components";
import { searchInputStyle } from "../styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { colors } from "../../../../assets/styles/config";
import FilterIcon from "../../../../assets/images/filter.svg";
import ExportIcon from "../../../../assets/images/export_.svg";
import TrashIcon from "../../../../assets/images/trash-2.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
} from "../../../../components";
import { Card, CloseBtn, Container } from "../styles";
// import moment from "moment";
import toast from "react-hot-toast";
import Warning from "../../../../assets/images/warning-icon.svg";
import { CSVLink } from "react-csv";
import { CiExport } from "react-icons/ci";
import { VscAdd } from "react-icons/vsc";

const PartnerRegister = () => {
 const { user, addAdmin, updateAdministrator, deletePartner, updatePartner } =
  useContext(AuthContext);
 const navigate = useNavigate();
 const [loading, setLoading] = useState(false);
 const [partner, setPartner] = useState([]);
 const [filterPartner, setFilterPartner] = useState([]);
 const tableRef = useRef(null);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [search, setSearch] = useState("");
 const [deleteId, setDeleteId] = useState(null);

 const fetchDatabase = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `users/partner`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     const myObj = snapshot.val();
     var array = Object.keys(myObj).map((key) => {
      return myObj[key];
     });

     var sortedData = array
      ?.map((obj) => {
       return { ...obj, date: new Date(JSON.parse(obj.created_at)) };
      })
      .sort((a, b) => b.date - a.date);

     setPartner(sortedData);
    } else {
     //  console.log("No data available");
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  //get users order

  return db;
 };

 useEffect(() => {
  if (user) {
   fetchDatabase();
  }
 }, [user]);

 useEffect(() => {
  setFilterPartner(partner);
 }, [partner]);

 //  const { onDownload } = useDownloadExcel({
 //   currentTableRef: tableRef.current,
 //   filename: "Orders table",
 //   sheet: "Orders",
 // });

 const csvHeader = [
  { label: "Partner Id", key: "uid" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Status", key: "status" },
 ];

 const csvData = filterPartner?.map((item) => {
  return {
   uid: item?.uid,
   name: `${item?.firstName}${item?.lastName}`,
   email: item.email,
   phone: item.phoneNumber,
   status: item.status,
  };
 });

 // console.log("experience", Object.keys(orders));

 //switch handler
 const onChangeHandle = async (e, item) => {
  setPartner(
   partner.map((itm) =>
    itm.uid === item.uid ? { ...item, enabled: e.target.checked } : itm
   )
  );
  toast.loading("loading");
  await updatePartner({
   ...item,
   enabled: e.target.checked,
  }).then((res) => {
   // toast.success("Profile successful updated");
  });
 };

 const searchFilter = (text) => {
  if (text) {
   setSearch(text);
   const newData = partner.filter((item) => {
    const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setFilterPartner(newData);
  } else {
   setSearch(text);
   setFilterPartner(partner);
  }
 };

 const handleDelete = async () => {
  // console.log("handleDelete", deleteId);
  const res = await deletePartner(deleteId);
  if (res) {
   setVisible(!visible);
   setDeleteId("");
   fetchDatabase();
  }
 };

 const truncateString = (string, maxLength) =>
  string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

 function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
 }

 return (
  <Box height="100%" padding="20px" background="#F5F8FA">
   {/* <Text
        color="#6C6B6B"
        fontSize={{ base: "18px", md: "20px", lg: "24px" }}
        lineHeight="32px"
        fontWeight="600"
      >
        Partner Management
      </Text> */}

   <Box
    background="#FFFFFF"
    borderRadius="6px"
    minHeight="80vh"
    padding={{ base: "20px 15px", md: "30px" }}
   >
    <Flex
     alignItems="flex-start"
     flexDir={"row"}
     justifyContent="space-between"
    >
     <InputGroup borderRadius="28px" width={{ base: "60%", md: "50%" }}>
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>
     <Box display={{ base: "none", md: "flex" }} alignItems="center">
      <CSVLink data={csvData} headers={csvHeader}>
       <CustomButton
        animateOnTap
        animateOnHover
        buttonColor="transparent"
        css={css`
         width: fit-content;
         padding: 0.5rem 2rem;
         height: auto;
         font-weight: 500;
         font-family: "Creato Display";
         display: flex;
         gap: 15px;
         border: 1px solid #252046;
         color: #252046;
         margin-right: 20px;

         @media screen and (max-width: 768px) {
          border-radius: 10px;
         }
        `}
       >
        <Img src={ExportIcon} />
        Export
       </CustomButton>
      </CSVLink>

      <Link to="/admin/partner-registration/add">
       <CustomButton
        animateOnTap
        animateOnHover
        buttonColor="#632EFF"
        css={css`
         width: fit-content;
         padding: 0.5rem 2rem;
         height: auto;
         font-weight: 500;
         font-family: "Creato Display";
         display: flex;
         gap: 15px;

         @media screen and (max-width: 768px) {
          border-radius: 10px;
         }
        `}
       >
        <AddIcon h="16px" w="16px" /> Add Partner
       </CustomButton>
      </Link>
     </Box>
     <Box display={{ base: "flex", md: "none" }} gap={3} alignItems="center">
      <CSVLink data={csvData} headers={csvHeader}>
       <Button
        borderRadius={50}
        bg={"white"}
        border={"1px solid black"}
        padding={2}
       >
        <Icon as={CiExport} boxSize={5} />
       </Button>
      </CSVLink>

      <Link to="/admin/partner-registration/add">
       <Button borderRadius={100} background={colors.primaryPurple} padding={2}>
        <Icon as={VscAdd} boxSize={5} color={colors.primaryWhite} />
       </Button>
      </Link>
     </Box>
    </Flex>
    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt="30px">
      {filterPartner?.length > 0 ? (
       <TableContainer
        border="1px solid #E2E8F0"
        borderRadius="16px 16px 0px 0px"
       >
        <Table variant="simple" ref={tableRef}>
         <Thead>
          <Tr>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Partner id
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Name
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Email
           </Th>
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Phone
           </Th>
           {/* <Th
                      fontFamily="Satoshi"
                      color="#252046"
                      fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                      lineHeight="125%"
                      fontWeight="700"
                      padding="14px 20px"
                    >
                      ADDRESS
                    </Th> */}
           {/* <Th
                      fontFamily="Satoshi"
                      color="#252046"
                      fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                      lineHeight="125%"
                      fontWeight="700"
                      padding="14px 20px"
                    >
                      Date Joined
                    </Th> */}
           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Status
           </Th>

           <Th
            fontFamily="Satoshi"
            color="#252046"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            lineHeight="125%"
            fontWeight="700"
            padding="14px 20px"
            textTransform="capitalize"
           >
            Actions
           </Th>
          </Tr>
         </Thead>
         <Tbody>
          {filterPartner?.map((item, index) => (
           <Tr
            key={index}
            cursor="pointer"
            _hover={{
             background: "#F1F5F9",
            }}
           >
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color={item.enabled ? "#334155" : "#64748B"}
             onClick={() =>
              navigate(`/admin/partner-registration/edit/${item.uid}`)
             }
            >
             {truncateString(item?.uid, 8)}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             // width="50%"
             fontWeight="500"
             fontFamily="Satoshi"
             color={item.enabled ? "#334155" : "#64748B"}
             onClick={() =>
              navigate(`/admin/partner-registration/edit/${item.uid}`)
             }
            >
             {item?.businessName}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color={item.enabled ? "#334155" : "#64748B"}
             onClick={() =>
              navigate(`/admin/partner-registration/edit/${item.uid}`)
             }
            >
             {item?.email}
            </Td>
            <Td
             padding="14px 20px"
             fontSize={{ base: "14px" }}
             lineHeight="125%"
             fontWeight="500"
             fontFamily="Satoshi"
             color={item.enabled ? "#334155" : "#64748B"}
             onClick={() =>
              navigate(`/admin/partner-registration/edit/${item.uid}`)
             }
            >
             {item?.phoneNumber}
            </Td>
            {/* <Td
                        padding="14px 20px"
                        fontSize={{ base: "14px" }}
                        lineHeight="125%"
                        fontWeight="500"
                        fontFamily="Satoshi"
                        color={item.enabled ? "#334155" : "#64748B"}
                        onClick={() =>
                          navigate(`/admin/partner-registration/view/${item.uid}`)
                        }
                      >
                        {dayjs(item?.created_at).format("DD-MM-YYYY")}
                      </Td> */}
            {/* <Td
                        padding="14px 20px"
                        fontSize={{ base: "14px" }}
                        lineHeight="125%"
                        fontWeight="500"
                        fontFamily="Satoshi"
                        color={item.enabled ? "#334155" : "#64748B"}
                        onClick={() =>
                          navigate(`/admin/partner-registration/view/${item.uid}`)
                        }
                      >
                        {item?.address}
                      </Td> */}
            <Td>
             {item?.status === "PENDING" ? (
              <span className={[styles.pill, styles.pending].join(" ")}>
               {capitalizeFirstLetter(item?.status)}
              </span>
             ) : item?.status === "APPROVED" ? (
              <span className={[styles.pill, styles.arroved].join(" ")}>
               {capitalizeFirstLetter(item?.status)}
              </span>
             ) : (
              <span className={[styles.pill, styles.disapproved].join(" ")}>
               {capitalizeFirstLetter(item?.status)}
              </span>
             )}
            </Td>

            <Td padding="14px 20px">
             {/* <Link to={`/admin/partner-registration/view/${item.uid}`}>
                          <Box
                            bg="#EBF7FF"
                            borderRadius="6px"
                            p="8px"
                            cursor="pointer"
                          >
                            <AiFillEye color="#25AEFC" />
                          </Box>
                        </Link>
                        <Link to={`/admin/partner-registration/edit/${item.uid}`}>
                          <Box
                            bg="#D0F1D7"
                            borderRadius="6px"
                            p="8px"
                            cursor="pointer"
                          >
                            <MdModeEditOutline color="#1AAB65" />
                          </Box>
                        </Link> */}
             <Box display="flex" alignItems="center" gap="10px">
              <Switch
               size="sm"
               color="#25AEFC"
               isChecked={item.enabled}
               onChange={(e) => {
                onChangeHandle(e, item);
               }}
               colorScheme="purple"
              />
              <Box
               // bg="#F2B8B5"
               // borderRadius="6px"
               // p="8px"
               cursor="pointer"
               onClick={() => {
                setVisible(!visible);
                setDeleteId(item);
               }}
              >
               <Image src={TrashIcon} />
              </Box>
             </Box>
            </Td>
           </Tr>
          ))}
         </Tbody>
        </Table>
       </TableContainer>
      ) : (
       <Box display="flex" justifyContent="center" alignItems="center">
        <Text
         fontSize={{ base: "20px" }}
         lineHeight="125%"
         fontWeight="700"
         fontFamily="Satoshi"
         color="#334155"
        >
         No Partner, Add new Partner
        </Text>
       </Box>
      )}
     </Box>
    )}
   </Box>
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
    >
     <Card
      animate={{ scale: 1 }}
      initial={{ scale: 0.9 }}
      style={{ width: "358px", height: "100%", padding: "2rem" }}
     >
      <Box
       display="flex"
       flexDir="column"
       justifyContent="center"
       alignItems="center"
       //  mt="40px"
       width="100%"
      >
       <Image src={Warning} />

       <Text
        fontFamily="Creato Display, Roboto, sans-serif;"
        color="#0F0931"
        fontSize={{ base: "14px", md: "24px" }}
        lineHeight="134%"
        fontWeight="700"
        marginTop="30px"
        textAlign="center"
       >
        Delete Partner?
       </Text>
       <Text
        fontFamily="Satoshi"
        color="#878498"
        fontWeight="500"
        marginTop="20px"
        textAlign="center"
       >
        Are you sure you want to delete this partner?
       </Text>
       <Box
        mt="30px"
        display="flex"
        alignItems="center"
        flexDirection="column"
        gap="20px"
        width="100%"
       >
        {" "}
        <Button
         color="#ffffff"
         bg={colors.primaryPurple}
         borderRadius="54px"
         fontWeight={500}
         fontSize={18}
         p="25px 20px"
         width="100%"
         onClick={handleDelete}
        >
         Yes, Delete
        </Button>
        <Button
         border="1px solid #252046"
         bg="transparent"
         borderRadius="54px"
         fontWeight={500}
         fontSize={18}
         p="25px 20px"
         width="100%"
         type="submit"
         onClick={() => {
          setVisible(!visible);
          setDeleteId("");
         }}
        >
         Cancel
        </Button>
       </Box>
      </Box>
     </Card>
    </Container>
   </Modal>
  </Box>
 );
};

export default PartnerRegister;
