import deal1 from "../assets/images/deals/deal1.png";
import deal2 from "../assets/images/deals/deal2.png";
import deal3 from "../assets/images/deals/deal3.png";
import deal4 from "../assets/images/deals/deal4.png";
import deal5 from "../assets/images/deals/deal5.png";
import deal6 from "../assets/images/deals/deal6.png";
import deal7 from "../assets/images/deals/deal7.png";
import deal8 from "../assets/images/deals/deal8.png";

export const dealsDeal = [
  {
    title: "The Kort Place",
    img: deal1,
  },
  {
    title: "The League Beach",
    img: deal2,
  },
  {
    title: "Beam Shawarma",
    img: deal3,
  },
  {
    title: "Pizza Abode",
    img: deal4,
  },
  {
    title: "Gusg Hotel",
    img: deal5,
  },
  {
    title: "Fresh Feeds",
    img: deal6,
  },
  {
    title: "Sushi House",
    img: deal7,
  },
  {
    title: "Art Attack",
    img: deal8,
  },
  {
    title: "The Kort Place",
    img: deal1,
  },
  {
    title: "The League Beach",
    img: deal2,
  },
  {
    title: "Beam Shawarma",
    img: deal3,
  },
  {
    title: "Pizza Abode",
    img: deal4,
  },
];

export const options = ["Buy 1 Get 1 Free", "Percentage Off", "Free Perks"];
export const offer1 = [
  {
    offer: "Buy 1 Get 1 Free",
    amount: "367",
  },
  {
    offer: "Free Perks",
    amount: "37",
  },
  {
    offer: "Percentage off",
    amount: "67",
  },
];

export const offer2 = [
  {
    offer: "Dining",
    amount: "367",
  },
  {
    offer: "Recreational",
    amount: "37",
  },
  {
    offer: "Entertainment",
    amount: "67",
  },
];
