import { Box, Text, Img } from "@chakra-ui/react";
import React from "react";
import DeleteIcon from "../../../../../assets/images/Delete.svg";
import { eventTypes } from "../../../../../utils/constants";

const SubscribedPartner = ({
 id,
 partner,
 isNewTime,
 setIsNewTime,
 setDisclosures,
 pushTime,
 removeTime,
 index,
 values,
 value,
 item,
}) => {
 const showTime =
  (value.timeSlot.length > 1 && item.id === value.timeSlot.length - 1) ||
  value.timeSlot.length === 1;
 const show = partner?.subscribed && values.experience !== eventTypes.ONE_TIME;
 console.log(value.timeSlot, partner);
 return (
  <>
   {values.experience !== eventTypes.PACKAGED && show ? (
    <Box display="flex" alignItems="center">
     {showTime ? (
      <Text
       color="#64748B"
       fontFamily="Creato Display, Roboto, sans-serif;"
       fontWeight={500}
       fontSize="16px"
       cursor="pointer"
       marginRight="20px"
       onClick={() => {
        console.log("isNewTime", isNewTime);
        setIsNewTime(!isNewTime);
        setDisclosures((prevDisclosures) => {
         return {
          ...prevDisclosures,
          [index]: [
           ...prevDisclosures[index],
           {
            isOpen: false,
            onToggle: () => {},
           },
          ],
         };
        });
        pushTime({
         id: id + 1,
         start: "",
         end: "",
         amount: "",
         maxTickets: "",
        });
       }}
      >
       + Add Additional Time Slots
      </Text>
     ) : null}
     {id > 0 && (
      <Img
       src={DeleteIcon}
       alt="delete"
       cursor="pointer"
       fontSize="10px"
       onClick={() => removeTime(id)}
      />
     )}
    </Box>
   ) : null}
  </>
 );
};

export default SubscribedPartner;
