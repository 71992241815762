// IMPORTS
import { Flex, Img, Text } from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../../assets/styles/config";
import { Header, Loader, Toast } from "../../components";
import { AuthContext } from "../../context/AuthContext";
import GuestModal from "./GuestModal";
import OrderSummary from "./OrderSummary";
import {
 ConfirmationWrapper,
 Container,
 FaintBrownImg,
 FaintBrownText,
 Heading,
 Title,
 TitleSection,
 Wrapper,
} from "./styles";
import BlueCheckCircleLg from "../../assets/images/icons/blue-check-circle-lg.png";

// IMPORTS FOR PAYMENT FUNCTIONALITY
import { onValue, ref, set } from "firebase/database";
import { uploadBytesResumable, ref as storeRef } from "firebase/storage";
import { db, cf, storage } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { usePaystackPayment } from "react-paystack";
import { v4 as uuidv4 } from "uuid";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import dayjs from "dayjs";
import generateImage from "../../assets/strings/generateImage";
import { RWebShare } from "react-web-share";
import { setDoc, doc } from "firebase/firestore";
import { storedb } from "../../firebase";
import { updateMaxTickets } from "./utils";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const fonts = {
 Roboto: {
  normal: "../../../assets/fonts/Roboto-Regular.ttf",
  bold: "../../../assets/fonts/Roboto-Medium.ttf",
  bolditalics: "../../../assets/fonts/Roboto-MediumItalic.ttf",
  italics: "../../../assets/fonts/Roboto-Italic (1).ttf",
 },
};
// Toast status
const TOAST_STATE = {
 state: false,
 text: "",
 bgColor: "lightgreen",
 textColor: colors.primaryWhite,
};
//welcome email cloud function
// const scheduleCheckAndSendReminder = httpsCallable(
//   cf,
//   "scheduleCheckAndSendReminder"
// );
const confirm = httpsCallable(cf, "experienceConfirmation");
const confirmPartner = httpsCallable(cf, "experienceConfirmationPartner");

// END OF THIS SCOPE FOR IMPORTS FOR PAYMENT FUNCTIONALITY

const Checkout = () => {
 const { user: directUser } = useContext(AuthContext);
 const isLoggedIn = localStorage.getItem("isLoggedIn");
 const navigate = useNavigate();
 const headerRef = useRef(null);
 const [user, setUser] = useState(null);
 const [loading, setLoading] = useState(true);
 const [isPaying, setIsPaying] = useState(false);
 const [recentExps, setRecentExps] = useState([]);
 const [toast, setToast] = useState(TOAST_STATE);
 const [paymentSuccess, setPaymentSuccess] = useState(false);
 const [numberOfTicketsBought, setNumberOfTicketsBought] = useState(0);
 const orderDetails = JSON.parse(localStorage.getItem("orderDetails"));
 const experienceDetails = JSON.parse(atob(orderDetails));
 const [experience, setExperience] = useState(experienceDetails);
 const [guestIsUpdating, setGuestIsUpdating] = useState(false);
 const orderSummaryTitleRef = useRef(null);
 // console.log("experience", experience);

 const {
  calendarValue,
  total: totalPrice,
  chosenQuantity: counter,
  activeTime,
  activeDate,
  chosenTimeFrame,
  coupon,
  usedCoupon,
 } = experience; // The properties in the experience we get come from the pay function, line 308 SingleExperience component

 // console.log("new Date()", dayjs(activeDate).format());

 // FUNCTIONS
 // console.log("_____USER", directUser);
 // ---------- PAYMENT FUNCTIONALITY ---------
 //tokens for each ticket
 const tokens = [];
 var dataArray = []; // data array for all the pdfs
 const experienceValueDb = ref(db, "cityExperience/experienceValue");
 const recentExpsDb = ref(db, "cityExperience/recentlyBoughtExperiences");
 const partnerDb = ref(db, "users/partner/" + experience?.partnerUid);

 useEffect(() => {
  const unsubscribe = onValue(experienceValueDb, (snapshot) => {
   const totalNumberOfTicketsBought = snapshot.val();
   setNumberOfTicketsBought(totalNumberOfTicketsBought);
  });
  const unsubscribe2 = onValue(recentExpsDb, (snapshot) => {
   const rec =
    snapshot.val()?.length > 0 ? snapshot.val()?.filter((exp) => exp) : [];
   setRecentExps(rec);
  });
  return () => {
   unsubscribe();
   unsubscribe2();
  };
 }, []);

 const testConfig = {
  public_key: "",
  tx_ref: "navi-experience-nuid-" + Date.now() + uuidv4(),
  amount: totalPrice,
  currency: "NGN",
  payment_options: "card, banktransfer, ussd",
  customer: {
   email: user?.email,
   phone_number: user?.phoneNumber,
   name: user?.name ?? `${user?.firstName} ${user?.lastName}`,
  },
  customizations: {
   title: "Navi City Experience",
   description: "Payment for an experience ticket with NAVI",
   logo:
    "https://firebasestorage.googleapis.com/v0/b/navi-d146f.appspot.com/o/flutterwaveLogo.png?alt=media&token=887be456-484c-4aee-98f7-1acfef69fca9",
  },
 };

 const config = {
  reference: new Date().getTime().toString(),
  email: user?.email,
  amount: totalPrice * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
  publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  // PRODUCTION_ENV
  //  PRODUCTION
  // publicKey: process.env.REACT_APP_PAYSTACK_LIVE_PUBLIC_KEY,
  subaccount: experience.bank
   ? (JSON.parse(experience?.bank) || {})?.subaccount_code
   : {},
 };
 // FUNCTIONS
 const initializePayment = usePaystackPayment(config);
 const packaged = experience?.type === "packaged";

 //format date into yy/mm/dd
 function formatDate(date) {
  var d = new Date(date),
   month = "" + (d.getMonth() + 1),
   day = "" + d.getDate(),
   year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
 }

 // generate new image, email for experience
 const generate = async (key) => {
  const timeChosen =
   chosenTimeFrame.timeSlot.start + "-" + chosenTimeFrame.timeSlot.end;
  for (let i = 0; i < tokens.length; i++) {
   var dd = {
    content: [
     {
      image: generateImage,
      fit: [100, 100],
     },
     {
      text: "\n" + experience?.title,
      style: "header",
     },
     packaged
      ? "\n Here are your experience details: "
      : "\nThis ticket admits one. Here are your experience details: ",
     "\nLocation: " + experience?.details?.location,
     packaged
      ? "\nDate & Time: Please contact" +
        experience?.details?.partner +
        "at" +
        experience?.details?.phone +
        "to reserve a time for date and time"
      : "\nTime: " + timeChosen,
     packaged ? "" : "\nDate: " + dayjs(activeDate).format("DD/MM/YYYY") + "\n",
     "\n" + "Ticket ID: " + tokens[i] + "\n\n",
     { qr: tokens[i], style: "qrCode" },
     "\n\nThis city experience is brought to you courtesy of Navi.\n\n",
    ],
    styles: {
     header: {
      fontSize: 29,
      bold: true,
      margin: [0, 0, 0, 10],
     },
     subheader: {
      fontSize: 16,
      bold: true,
      margin: [0, 10, 0, 5],
     },
     qrCode: {
      alignment: "center",
      marginTop: 10,
     },
    },
   };

   var data;

   for (let i = 0; i < tokens.length; i++) {
    let file_name = `${tokens[i]}.pdf`;

    pdfMake.createPdf(dd).getBlob(async (blob) => {
     const storageRef = storeRef(storage, `/tickets/${file_name}`);
     const uploadTask = uploadBytesResumable(storageRef, blob);

     uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (err) => {},
      async () => {}
     );
    });
   }

   pdfMake.createPdf(dd).getBase64(async function (encodedString) {
    data = encodedString;
    dataArray.push(data);

    // console.log("emailAttachments", emailAttachments);

    if (i === tokens.length - 1) {
     confirm({
      email: user?.email,
      name: user?.name ?? `${user?.firstName} ${user?.lastName}`,
      experience: experience?.title.replace(/<\/?[^>]+(>|$)/g, ""),
      partner: experience?.details?.partner,
      date: dayjs(activeDate).format("dddd, MMMM D YYYY"),
      token: key,
      tel: experience?.details?.phone,
      count: counter,
      tickets: tokens.length,
      time: timeChosen,
      location: experience?.details?.location,
      tokens: tokens,
      dataArray: dataArray,
      total: experience.total,
      packaged,
     });
     confirmPartner({
      email: experience?.details?.email,
      name: user?.name ?? `${user?.firstName} ${user?.lastName}`,
      experience: experience?.title.replace(/<\/?[^>]+(>|$)/g, ""),
      partner: experience?.details?.partner,
      date: dayjs(activeDate).format("dddd, MMMM D YYYY"),
      token: key,
      count: counter,
      tickets: tokens.length,
      time: timeChosen,
      location: experience?.details?.location,
      tokens: tokens,
      dataArray: dataArray,
      total: experience.total,
      packaged,
     });
     // scheduledExperienceConfirmation({
     //   email: experience?.details?.email,
     //   name: user?.name ?? `${user?.firstName} ${user?.lastName}`,
     //   experience: experience?.title.replace(/<\/?[^>]+(>|$)/g, ""),
     //   partner: experience?.details?.partner,
     //   date: dayjs(activeDate).format("dddd, MMMM D YYYY"),
     //   activeDate: activeDate,
     //   token: key,
     //   count: counter,
     //   tickets: tokens.length,
     //   time: timeChosen,
     //   location: experience?.details?.location,
     //   tokens: tokens,
     //   dataArray: dataArray,
     //   total: experience.total,
     // });

     await setDoc(doc(storedb, "events", key), {
      eventDate: dayjs(activeDate).format(),
      date: dayjs(activeDate).format("dddd, MMMM D YYYY"),
      partner: experience?.details?.partner,
      email: experience?.details?.email,
      name: user?.name ?? `${user?.firstName} ${user?.lastName}`,
      experience: experience?.title.replace(/<\/?[^>]+(>|$)/g, ""),
      time: timeChosen,
      location: experience?.details?.location,
      reminderSent: false,
     });
     // scheduleCheckAndSendReminder();
    }
   });
  }
 };

 const generateTokens = () => {
  for (let i = 0; i < counter; i++) {
   let myuuid = uuidv4();
   tokens.push(myuuid);
  }
 };

 const onSuccess = (reference) => {
  // Implementation for whatever you want to do with reference and after success call.
  const timeChosen =
   chosenTimeFrame.timeSlot.start + "-" + chosenTimeFrame.timeSlot.end;
  setIsPaying(false);
  setPaymentSuccess(true);
  if (reference.status === "success") {
   generateTokens();
   // TRANSACTION AND TICKET NODE FUNCTIONALITY
   const transationId = "NV" + uuidv4();
   const transationInfo = {
    navId: user.uid || "GUEST_ID",
    transationId: transationId,
    paymentId: reference.reference,
    transationDate: JSON.stringify(new Date()),
    transationTime: JSON.stringify(new Date().getTime()),
    experienceId: experience.id,
    experienceName: experience.title,
    timeChosen: timeChosen,
    chosenTimeFrame: chosenTimeFrame,
    partnerId: experience.partnerUid,
    username: user?.name || user.firstName + " " + user.lastName,
    email: user?.email,
    phone: user?.phoneNumber || "",
    role: user.role || "GUEST",
    ticketQuantity: experience.chosenQuantity,
    ticketAmount: experience.chosenPrice / experience.chosenQuantity,
    fee: experience.fees,
    total: experience.total,
   };
   const transactionsRef = ref(db, `transactions/${transationId}`);
   set(transactionsRef, transationInfo);

   tokens.map((i) => {
    const ticketInfo = {
     token: i,
     transationId: transationId,
     transationDate: JSON.stringify(new Date()),
     transationTime: JSON.stringify(new Date().getTime()),
     experienceName: experience.title,
     experienceId: experience.id,
     ticketAmount: experience.chosenPrice / experience.chosenQuantity,
     partnerId: experience.partnerUid,
     chosenTimeFrame: chosenTimeFrame,
     timeChosen: timeChosen,
     status: "unredeemed",
     user: user,
     total: experience.total,
    };
    console.log("RUN TOKENS", ticketInfo);

    const ticketsRef = ref(db, `tickets/${i}`);
    // const ticketsDbC = push(ticketsRef);
    set(ticketsRef, ticketInfo);
   });

   updateMaxTickets(experience, chosenTimeFrame, counter);

   set(transactionsRef, transationInfo);

   setPaymentSuccess(true);
   setToast({
    ...toast,
    state: true,
    text:
     "Payment complete. Your tickets and receipt will be sent to your email shortly.",
    bgColor: "lightgreen",
   });

   generate(transationId);

   setPaymentSuccess(true);
  } else {
   setToast({
    ...toast,
    state: true,
    text: "Something went wrong, please try again shortly. ",
    bgColor: "red",
   });
  }
 };

 const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  setIsPaying(false);
 };

 // initiate payment route
 const pay = async () => {
  setIsPaying(true);
  if (!user || !user.email) {
   setTimeout(() => {
    setIsPaying(false);
   }, [1000]);

   return;
  } else {
   await initializePayment(onSuccess, onClose);
  }
 };
 // ------- END OF PAYMENT FUNCTIONALITY ------

 // EFFECTS
 useEffect(() => {
  headerRef?.current?.scrollIntoView();
 }, [orderDetails, user]);

 useEffect(() => {
  if (directUser?.uid) {
   setUser(directUser);
  }
 }, [directUser]);

 useEffect(() => {
  console.log("Payment", user);
  if (!isLoggedIn) {
   setLoading(false);
  }

  if (user?.uid) {
   setLoading(false);
  }
 }, [user]);

 // JSX
 const renderToast = React.useMemo(
  () => (
   <Toast
    visible={toast.state}
    setVisible={setToast}
    text={toast.text}
    textStyle={{ color: toast.textColor }}
    style={{ backgroundColor: toast.bgColor }}
   />
  ),
  [toast]
 );

 if (loading) {
  return <Loader />;
 }

 return (
  <div>
   {" "}
   <Header bgColor={"white"} color="dark" />
   <TitleSection>
    <Text
     color={"#0F0931"}
     fontWeight="400"
     textDecoration="underline"
     lineHeight={"16px"}
     fontSize={{ base: 14, md: 15, lg: 16 }}
     onClick={() => navigate(`/experiences/${experience.id}`)}
     cursor="pointer"
    >
     {"Go Back "}
    </Text>
    <Heading>
     {" "}
     <Title>{experience?.title}</Title>
     <Flex alignItems={"center"} gap="8px">
      <FaintBrownImg src={experience.details?.pic[0]} />
      <FaintBrownText>
       By {experience?.details?.partner || "A Partner"}
      </FaintBrownText>
     </Flex>
     <Flex gap="6px" alignItem="center" marginTop="-6.5px">
      <div>
       <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path
         d="M8.6663 7.83341C8.53964 7.83341 8.41297 7.78674 8.31297 7.68674C8.11964 7.49341 8.11964 7.17341 8.31297 6.98008L13.7796 1.51341C13.973 1.32007 14.293 1.32007 14.4863 1.51341C14.6796 1.70674 14.6796 2.02674 14.4863 2.22007L9.01964 7.68674C8.91964 7.78674 8.79297 7.83341 8.6663 7.83341Z"
         fill="#292D32"
        />
        <path
         d="M14.6648 5.03325C14.3915 5.03325 14.1648 4.80659 14.1648 4.53325V1.83325H11.4648C11.1915 1.83325 10.9648 1.60659 10.9648 1.33325C10.9648 1.05992 11.1915 0.833252 11.4648 0.833252H14.6648C14.9382 0.833252 15.1648 1.05992 15.1648 1.33325V4.53325C15.1648 4.80659 14.9382 5.03325 14.6648 5.03325Z"
         fill="#292D32"
        />
        <path
         d="M9.9987 15.1666H5.9987C2.3787 15.1666 0.832031 13.6199 0.832031 9.99992V5.99992C0.832031 2.37992 2.3787 0.833252 5.9987 0.833252H7.33203C7.60536 0.833252 7.83203 1.05992 7.83203 1.33325C7.83203 1.60659 7.60536 1.83325 7.33203 1.83325H5.9987C2.92536 1.83325 1.83203 2.92659 1.83203 5.99992V9.99992C1.83203 13.0733 2.92536 14.1666 5.9987 14.1666H9.9987C13.072 14.1666 14.1654 13.0733 14.1654 9.99992V8.66659C14.1654 8.39325 14.392 8.16659 14.6654 8.16659C14.9387 8.16659 15.1654 8.39325 15.1654 8.66659V9.99992C15.1654 13.6199 13.6187 15.1666 9.9987 15.1666Z"
         fill="#292D32"
        />
       </svg>
      </div>
      <RWebShare
       data={{
        text: "Share this experience with your friends",
        url: `https://navi-d146f.web.app/experiences/${experience?.id}`,
        title: "Navi Share",
       }}
      >
       <Text
        color={"#0F0931"}
        fontWeight="500"
        textDecoration="underline"
        lineHeight={"16px"}
        fontSize="14"
        cursor="pointer"
       >
        Share
       </Text>
      </RWebShare>
     </Flex>
    </Heading>
   </TitleSection>
   <Container>
    {!paymentSuccess ? (
     <Wrapper>
      {user?.uid || user?.email ? null : (
       <GuestModal setUser={setUser} user={user} />
      )}
      <OrderSummary
       experience={experience}
       isPaying={isPaying}
       pay={pay}
       user={user}
       setExperience={setExperience}
       setToast={setToast}
       toast={toast}
       //  setGuestIsUpdating={setGuestIsUpdating}
      />
     </Wrapper>
    ) : (
     <ConfirmationWrapper>
      <p>Purchase Successful!</p>
      <Img src={BlueCheckCircleLg} width={{ base: "30%", lg: "auto" }} />
      <p>
       {" "}
       Thank you for your order! Your experience tickets have been sent to your
       email <br />
       <a href={`mailto:${user?.email}`}> {user?.email}</a>
      </p>
      <p>
       If you have not received your tickets, please email us at
       <a href="mailto:support@thenaviapp.com">{" support@thenaviapp.com "}</a>
      </p>
     </ConfirmationWrapper>
    )}
   </Container>
   {renderToast}
  </div>
 );
};
export default Checkout;
