import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  HStack,
  Text,
  Flex,
  SimpleGrid,
  Img,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  RadioGroup,
  Radio,
  Select,
  useCheckbox,
  useCheckboxGroup,
  chakra,
} from "@chakra-ui/react";
import { AiOutlineCheck } from "react-icons/ai";
import { colors } from "../../../assets/styles/config";
import styles from "../index.module.css";
import { Formik, Field } from "formik";
import {
  getDatabase,
  ref as dbRef,
  set,
  onValue,
  update,
  remove,
} from "firebase/database";
import { storage, db } from "../../../firebase";

export function CustomCheckbox(props) {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props);

  const { ...rest } = props;
  const { name, value: formikValue } = props.field;
  const { setFieldValue } = props.form;

  const handleChange = (event) => {
    const values = formikValue || [];
    const index = values.indexOf(rest.value);
    if (index === -1) {
      // props.setTags(props.tags.splice(index, 1));
      values.push(rest.value);
    } else {
      values.splice(index, 1);
      // props.setTags(props.tags.push(rest.value));
    }
    setFieldValue(name, values);
  };

  //   console.log("props===", props);

  return (
    <chakra.label
      border="1px solid #E2E8F0"
      borderRadius="11px"
      padding={name !== "tags" ? "20px 24px" : "10px 14px"}
      cursor="pointer"
      display="flex"
      alignItems="center"
      backgroundColor={formikValue?.indexOf(rest.value) !== -1 && "#F5F3FF"}
      gap="20px"
      {...htmlProps}
    >
      <input
        {...getInputProps()}
        hidden
        onChange={handleChange}
        // checked={formikValue?.indexOf(rest.value) !== -1}
      />
      <Flex
        alignItems="center"
        justifyContent="center"
        border="1px solid #E2E8F0"
        background={formikValue?.indexOf(rest.value) !== -1 && "#632EFF"}
        borderRadius="6px"
        w={5}
        h={5}
        {...getCheckboxProps()}
      >
        {formikValue?.indexOf(rest.value) !== -1 && (
          <AiOutlineCheck color="white" />
        )}
      </Flex>
      <Text color="gray.700" {...getLabelProps()}>
        {props.value.toUpperCase()}
      </Text>
    </chakra.label>
  );
}

const CategoriesTags = ({ onSwitchStage, data }) => {
  const categoryDb = dbRef(db, "cityExperience/categories");
  const tagDb = dbRef(db, "cityExperience/tags");
  const [category, setCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTag, setSearchTag] = useState([]);

  // const category = [
  //   "bar",
  //   "Restaurant",
  //   "Lounge",
  //   "Cafe",
  //   "Hotel",
  //   "Restaurant",
  // ];

  // const [tags, setTags] = useState([
  //   "Drinks",
  //   "Karaoke",
  //   "Grills",
  //   "Italian",
  //   "Disco night",
  // ]);

  const searchFilter = (text) => {
    if (text) {
      setSearch(text);
      const newData = tags.filter((item) => {
        const itemData = item ? item.toUpperCase() : "".toUpperCase();
        const input = text.toUpperCase();
        return itemData.indexOf(input) > -1;
      });
      setSearchTag(newData);
    } else {
      setSearch(text);
      setSearchTag(tags);
      // setSearchTag(tags);
    }
  };

  useEffect(() => {
    const unsubscribe = onValue(categoryDb, (snapshot) => {
      const data = snapshot.val();
      data.shift();
      setCategory(data);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onValue(tagDb, (snapshot) => {
      const data = snapshot.val();
      // data.shift();
      setTags(data);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (tags) {
      setSearchTag(tags);
    }
  }, [tags]);

  const { value, getCheckboxProps } = useCheckboxGroup({
    defaultValue: [""],
  });

  return (
    <Box width="100%" height="100%">
      <Box
        borderBottom="1px solid #E2E8F0"
        py="20px"
        marginTop="20px"
        display={{ base: "none", md: "block" }}
      >
        <Text
          fontFamily="Creato Display, Roboto, sans-serif;"
          color={colors.darkBlue}
          fontWeight="400"
          lineHeight="135%"
          fontSize="24px"
        >
          5. Categories and tags
        </Text>
      </Box>
      <Box
        py="20px"
        marginTop="20px"
        display={{ base: "flex", md: "none" }}
        flexDir="column"
        alignItems="flex-start"
      >
        <Text color="#878498" fontFamily="Creato Display, Roboto, sans-serif;">
          {data.registeringAs === "Business" ? "Step 5 of 5" : "Step 4 of 4"}
        </Text>
        <Text
          fontFamily="Creato Display, Roboto, sans-serif;"
          color={colors.darkBlue}
          fontWeight="400"
          lineHeight="135%"
          fontSize="24px"
          marginTop="20px"
        >
          Categories and tags
        </Text>
        <Text
          fontFamily="Satoshi"
          color={colors.darkBlue}
          fontWeight="500"
          lineHeight="20px"
          fontSize="16px"
          marginTop="10px"
        >
          Select category that best describes your business
        </Text>
      </Box>
      <Box mt="30px">
        <Formik
          initialValues={{
            category: "",
            tags: "",
          }}
          onSubmit={async (values) => {
            onSwitchStage(values);
          }}
          // validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched, values, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box marginBottom="40px">
                  <FormControl id="category">
                    <FormLabel
                      htmlFor="category"
                      color={colors.darkBlue}
                      fontFamily="Satoshi"
                      fontWeight="500"
                      fontSize="20px"
                      lineHeight="135%"
                      mb="10px"
                    >
                      What is the category that best describes this business?
                    </FormLabel>
                    <Text fontFamily="Satoshi" fontWeight="500" fontSize="16px">
                      Select all that applies
                    </Text>

                    <SimpleGrid
                      columns={{ base: 1, md: 3 }}
                      mt="30px"
                      gap="20px"
                    >
                      {category.map((item, index) => (
                        <Field
                          component={CustomCheckbox}
                          {...getCheckboxProps({
                            value: item,
                            name: "category",
                          })}
                          value={item}
                          name="category"
                          // name="category"
                          // value={item}
                          // label={item}
                        />
                      ))}
                    </SimpleGrid>
                  </FormControl>
                </Box>
                <Box marginBottom="40px">
                  <FormControl id="tags">
                    <FormLabel
                      htmlFor="tags"
                      color={colors.darkBlue}
                      fontFamily="Satoshi"
                      fontWeight="500"
                      fontSize="20px"
                      lineHeight="135%"
                      mb="10px"
                    >
                      Add additional tags that best describe this business
                    </FormLabel>
                    <Text fontFamily="Satoshi" fontWeight="500" fontSize="16px">
                      Enter search tags you feel Navigators will use when
                      looking for your business.
                    </Text>
                    <Box
                      className={styles.search}
                      width="100%"
                      marginTop="20px"
                    >
                      <input
                        type="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => searchFilter(e.target.value)}
                      />
                      <Text
                        marginTop="10px"
                        fontFamily="Satoshi"
                        fontSize="14px"
                        color="#878498"
                      >
                        Select a minimum of 5 tags
                      </Text>
                      <SimpleGrid
                        columns={{ base: 1, md: 4 }}
                        mt="30px"
                        gap="20px"
                      >
                        {searchTag.map((item, index) => (
                          <Field
                            component={CustomCheckbox}
                            {...getCheckboxProps({
                              value: item,
                              tags: tags,
                              setTags: setTags,
                            })}
                            value={item}
                            name="tags"
                            // name="category"
                            // value={item}
                            // label={item}
                          />
                        ))}
                      </SimpleGrid>
                    </Box>
                  </FormControl>
                </Box>
                <Button
                  color={colors.primaryWhite}
                  bg={colors.primaryPurple}
                  borderRadius={28}
                  fontWeight={500}
                  fontSize={18}
                  // marginTop={10}
                  p="24px 20px"
                  // width="25%"
                  width={{ base: "100%", md: "25%" }}
                  type="submit"
                >
                  Complete registration
                </Button>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default CategoriesTags;
