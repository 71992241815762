import React from 'react';
import Modal from '../Modal';
import { Button, Card, Container, IconWrapper, Text } from './styles';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';
import { PropTypes } from 'prop-types';
import { useRef } from 'react';
import { colors } from '../../assets/styles/config';
import { Spinner } from '@chakra-ui/react';

const ToastModal = ({
  visible,
  setVisible,
  text,
  style,
  textStyle,
  children,
  status,
  iconColor,
  buttonText,
  buttonColor,
}) => {
  const containerRef = useRef(null);

  return (
    <Modal
      {...{
        visible,
        setVisible,
        childrenContainerRef: containerRef,
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      overlayLayer
      overlayLayerStyle={{
        backgroundColor: colors.black,
        opacity: 0.7,
      }}
    >
      {children ? (
        children
      ) : (
        <Container ref={containerRef} style={style}>
          <Card>
            <IconWrapper iconColor={iconColor}>
              {status === 'completed' ? (
                <CheckIcon width={'150%'} height={'150%'} />
              ) : status === 'warning' ? (
                <CloseIcon />
              ) : (
                <Spinner />
              )}
            </IconWrapper>
            <Text>{text}</Text>
            <Button onClick={() => setVisible(false)} buttonColor={buttonColor}>
              {buttonText}
            </Button>
          </Card>
        </Container>
      )}
    </Modal>
  );
};

ToastModal.defaultProps = {
  visible: false,
  setVisible: () => null,
  text: '',
  style: {},
  textStyle: {},
  children: null,
  status: '',
  iconColor: '',
};

ToastModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  text: PropTypes.string,
  style: PropTypes.shape({}),
  textStyle: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  status: PropTypes.string,
  iconColor: PropTypes.string,
};

export default ToastModal;
