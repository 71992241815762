import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  RadioGroup,
  Radio,
  Select,
  Flex,
} from "@chakra-ui/react";
import { colors } from "../../../assets/styles/config";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { inputStyle } from "../../Admin/Dashboard/styles";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),
  phoneNumber: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Invalid phone number"
    )
    .required("Phone number is Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const PersonalDetails = ({ onSwitchStage, data }) => {
  return (
    <Box width="100%" height="100%">
      <Box
        borderBottom="1px solid #E2E8F0"
        py="20px"
        marginTop="20px"
        display={{ base: "none", md: "block" }}
      >
        <Text
          fontFamily="Creato Display, Roboto, sans-serif;"
          color={colors.darkBlue}
          fontWeight="400"
          lineHeight="135%"
          fontSize="24px"
        >
          2. Personal Details
        </Text>
      </Box>
      <Box
        py="20px"
        marginTop="20px"
        display={{ base: "flex", md: "none" }}
        flexDir="column"
        alignItems="flex-start"
      >
        <Text color="#878498" fontFamily="Creato Display, Roboto, sans-serif;">
          Step 2 of 5
        </Text>
        <Text
          fontFamily="Creato Display, Roboto, sans-serif;"
          color={colors.darkBlue}
          fontWeight="400"
          lineHeight="135%"
          fontSize="24px"
          marginTop="20px"
        >
          Personal Details
        </Text>
        <Text
          fontFamily="Satoshi"
          color={colors.darkBlue}
          fontWeight="500"
          lineHeight="20px"
          fontSize="16px"
          marginTop="10px"
        >
          Please provide your name, email and phone number
        </Text>
      </Box>
      <Box mt="30px">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          }}
          onSubmit={async (values) => {
            onSwitchStage(values);
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Flex
                gap="30px"
                marginBottom="30px"
                flexDir={{ base: "column", md: "row" }}
              >
                <FormControl
                  w="100%"
                  alignItems="center"
                  mb="10px"
                  isRequired
                  isInvalid={!!errors.firstName && touched.firstName}
                >
                  <FormLabel
                    color={colors.darkBlue}
                    fontFamily="Satoshi"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="21px"
                    mb="10px"
                  >
                    First name
                  </FormLabel>
                  <Field
                    as={Input}
                    {...inputStyle}
                    type="text"
                    id="firstName"
                    name="firstName"
                  />
                  <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                </FormControl>
                <FormControl
                  w="100%"
                  alignItems="center"
                  mb="10px"
                  isRequired
                  isInvalid={!!errors.lastName && touched.lastName}
                >
                  <FormLabel
                    color={colors.darkBlue}
                    fontFamily="Satoshi"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="21px"
                    mb="10px"
                  >
                    Last name
                  </FormLabel>
                  <Field
                    as={Input}
                    {...inputStyle}
                    type="text"
                    id="lastName"
                    name="lastName"
                  />
                  <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex gap="30px" flexDir={{ base: "column", md: "row" }}>
                <FormControl
                  w="100%"
                  alignItems="center"
                  mb="10px"
                  isRequired
                  isInvalid={!!errors.email && touched.email}
                >
                  <FormLabel
                    color={colors.darkBlue}
                    fontFamily="Satoshi"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="21px"
                    mb="10px"
                  >
                    Email
                  </FormLabel>
                  <Field
                    as={Input}
                    {...inputStyle}
                    type="email"
                    id="email"
                    name="email"
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl
                  w="100%"
                  alignItems="center"
                  mb="10px"
                  isRequired
                  isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                >
                  <FormLabel
                    color={colors.darkBlue}
                    fontFamily="Satoshi"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="21px"
                    mb="10px"
                  >
                    Phone Number
                  </FormLabel>
                  <Field
                    as={Input}
                    {...inputStyle}
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                  />
                  <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Button
                color={colors.primaryWhite}
                bg={colors.primaryPurple}
                borderRadius={28}
                fontWeight={500}
                fontSize={18}
                marginTop={10}
                p="24px 20px"
                width={{ base: "100%", md: "25%" }}
                type="submit"
              >
                Next
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default PersonalDetails;
