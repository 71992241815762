import React, { useState, useEffect } from "react";
import { naviLogoWhite } from "../../assets/strings/imageExports";
import Icon from "../Icon";
import {
  Contact,
  ContactInformation,
  ContactWrapper,
  Container,
  CopyrightText,
  FooterNavLinks,
  Heading,
  Media,
  MediaContainer,
  MediaWrapper,
  Navigation,
  SocialIcons,
  TouchTitle,
  Wrapper,
} from "./styles";
import { socialMediaLinks } from "../../assets/strings/data";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import { db } from "../../firebase";
import { onValue, ref } from "firebase/database";
import { Flex } from "@chakra-ui/react";

const legalDb = ref(db, "cityExperience/legal");

const Footer = () => {
  const [legal, setLegal] = useState(null);

  useEffect(() => {
    const unsubscribe = onValue(legalDb, (snapshot) => {
      const legalData = snapshot.val();
      setLegal(legalData);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const SocialMediaIcons = () => (
    <SocialIcons>
      {socialMediaLinks.map((link) => (
        <a key={link.name} href={link.url} target={"_blank"}>
          <Icon
            pointer
            animateOnTap
            size={{ base: "25px", md: "33px", lg: "50px" }}
            name={link.icon}
          />
        </a>
      ))}
    </SocialIcons>
  );

  return (
    <Container>
      <Wrapper>
        <Contact>
          <ContactWrapper>
            <TouchTitle>Get In Touch</TouchTitle>
            <Heading>
              Contact Us{" "}
              <Icon
                name="angleLeft"
                size={55}
                css={css`
                  width: 20px;
                  height: 20px;

                  @media screen and (min-width: 768px) {
                    width: 25px;
                    height: 25px;
                  }

                  @media screen and (min-width: 1080px) {
                    width: 30px;
                    height: 30px;
                  }
                `}
              />
            </Heading>
            <ContactInformation>
              {/*<div className="location">
                <Icon name="location" size={30} />
                {"Ikoyi, Lagos State, Nigeria"}
                </div> */}

              <a href="mailto:hello@thenaviapp.com">
                <Icon
                  css={css`
                    margin-right: 5px;
                  `}
                  name="mail"
                  size={30}
                />
                {"hello@thenaviapp.com"}
              </a>
            </ContactInformation>
          </ContactWrapper>
        </Contact>
        <Media>
          <MediaContainer>
            <MediaWrapper>
              <img src={naviLogoWhite} alt="navi-logo" className="logo" />
              <SocialMediaIcons />
            </MediaWrapper>
            <Flex
              justifyContent="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap="1rem"
            >
              <CopyrightText>
                {"Copyright 2022 - @NAVI. All Rights Reserved."}
              </CopyrightText>
              <Flex gap="1rem">
                {legal?.map((legalDoc) => (
                  <a href={legalDoc.fileUrl}>{legalDoc.name}</a>
                ))}
              </Flex>
            </Flex>
          </MediaContainer>
        </Media>
        <Navigation>
          <FooterNavLinks>
            {/*   <Link to="/">Home</Link>
            <Link to="/">About Us</Link>
            <Link to="/">NAVI Journal</Link>{" "} */}
          </FooterNavLinks>
        </Navigation>
      </Wrapper>
    </Container>
  );
};

export default Footer;
