import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  InputGroup,
  Image,
  Input,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { searchInputStyle } from "../Dashboard/styles";
import { SearchIcon } from "../../../components/Icon/otherIcons";
import {
  getDatabase,
  ref,
  set,
  child,
  get,
  onValue,
  update,
  remove,
} from "firebase/database";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { storedb } from "../../../firebase";

const Search = ({ currentUser }) => {
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [err, setErr] = useState(false);

  const handleKey = (e) => {
    e.code === "Enter" && handleSearch();
  };

  const handleSearch = async () => {
    const dbRef = ref(getDatabase());
    await get(child(dbRef, `users/admin`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let myObj = snapshot.val();

          const foundUser = Object.keys(myObj)
            .map((key) => {
              return myObj[key];
            })
            ?.filter((item) => item?.name.includes(username));
          // console.log("foundUser", foundUser);
          setUser(foundUser[0]);
        }
      })
      .catch((err) => {
        setErr(true);
      });
  };

  const handleSelect = async () => {
    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;
    try {
      const res = await getDoc(doc(storedb, "chats", combinedId));
      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(storedb, "chats", combinedId), { messages: [] });
        //create user chats
        const userDoc = await getDoc(
          doc(storedb, "userChats", currentUser.uid)
        );

        if (userDoc.exists()) {
          const fetchUserDoc = userDoc.data();
          fetchUserDoc[combinedId] = {
            userInfo: {
              uid: user.uid,
              displayName: user.name,
              photoURL: user.photoURL,
              email: user?.email,
            },
            date: serverTimestamp(),
            status: "Pending",
          };
          await setDoc(
            doc(storedb, "userChats", currentUser.uid),
            fetchUserDoc
          );
        } else {
          await setDoc(doc(storedb, "userChats", currentUser.uid), {
            [combinedId]: {
              userInfo: {
                uid: user.uid,
                displayName: user.name,
                photoURL: user.photoURL,
                email: user?.email,
              },
              date: serverTimestamp(),
              status: "Pending",
            },
          });
        }

        const receiverDoc = await getDoc(doc(storedb, "userChats", user.uid));

        if (receiverDoc.exists()) {
          const fetchReceiverDoc = receiverDoc.data();
          fetchReceiverDoc[combinedId] = {
            userInfo: {
              uid: currentUser.uid,
              displayName: currentUser.name,
              photoURL: currentUser.photoURL,
              email: currentUser?.email,
            },
            date: serverTimestamp(),
            status: "Pending",
          };
          await setDoc(doc(storedb, "userChats", user.uid), fetchReceiverDoc);
        } else {
          await setDoc(doc(storedb, "userChats", user.uid), {
            [combinedId]: {
              userInfo: {
                uid: currentUser.uid,
                displayName: currentUser.name,
                photoURL: currentUser.photoURL,
                email: currentUser?.email,
              },
              date: serverTimestamp(),
              status: "Pending",
            },
          });
        }
      }
    } catch (error) {
      // console.log("error===", error);
    }

    setUser(null);
    setUsername("");
  };

  return (
    <Box marginBottom="20px">
      <InputGroup borderRadius="28px" width="100%">
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
          paddingBlock="24px"
          paddingLeft={"25px"}
        />
        <Input
          {...searchInputStyle}
          onKeyDown={handleKey}
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          //   onChange={(e) => search(e.target.value)}
        />
      </InputGroup>
      {err && <span>User not found!</span>}
      {user && (
        <Box
          display="flex"
          marginTop="10px"
          width="100%"
          gap="20px"
          alignItems="center"
          cursor="pointer"
          background="#F1F5F9"
          padding="10px"
          borderRadius="10px"
          onClick={handleSelect}
        >
          <Image
            src={user?.photoURL}
            width="40px"
            height="40px"
            borderRadius="50%"
          />
          <Text>{user?.name}</Text>
        </Box>
      )}
    </Box>
  );
};

export default Search;
