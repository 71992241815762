import { Button, Flex } from '@chakra-ui/react';
import { useRef } from 'react';
import { css } from 'styled-components';
import { ticketPlaceholderLg } from '../../../assets/strings/imageExports';
import { colors } from '../../../assets/styles/config';
import { Icon, Modal } from '../../../components';
import {
  Card,
  CloseBtn,
  CounterIcon,
  CounterText,
  ProductImg,
  ProductImgContainer,
  ProductText,
  SingleProductContainer,
  SingleProductWrapper,
  Title,
} from './styles';

const SingleCard = ({ visible, setVisible, product }) => {
  const containerRef = useRef(null);

  // JSX
  const RenderCounter = ({
    subtract,
    subtractValue,
    add,
    addValue,
    count = 1,
  }) => (
    <Flex gap='1rem' alignSelf={'end'}>
      <CounterIcon onClick={() => subtract(subtractValue - 1)}>-</CounterIcon>
      <CounterText>{count}</CounterText>
      <CounterIcon onClick={() => add(addValue + 1)}>+</CounterIcon>
    </Flex>
  );

  return (
    <Modal
      {...{
        visible,
        setVisible,
        childrenContainerRef: containerRef,
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      overlayLayer
      overlayLayerStyle={{
        backgroundColor: colors.black,
        opacity: 0.5,
      }}
    >
      <Card ref={containerRef} animate={{ scale: 1 }} initial={{ scale: 0.9 }}>
        <SingleProductContainer>
          <ProductImgContainer>
            <ProductImg src={ticketPlaceholderLg} />
          </ProductImgContainer>
          <Flex flexDirection={'column'}>
            <Title>Meal Ticket</Title>
            <SingleProductWrapper>
              <Flex gap='2rem'>
                <ProductText
                  css={css`
                    align-self: start;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                  `}
                >
                  <Icon
                    name='naira'
                    css={css`
                      width: 20px;
                      margin-right: 3px;

                      @media screen and (min-width: 768px) {
                        width: 40px;
                        margin-right: -5px;
                      }
                    `}
                    animateOnTap
                  />
                  4000.00
                </ProductText>
                <RenderCounter />
              </Flex>
            </SingleProductWrapper>
            <ProductText>Description</ProductText>
          </Flex>
        </SingleProductContainer>
        <CloseBtn onClick={() => setVisible(!visible)}>x</CloseBtn>
      </Card>
    </Modal>
  );
};

export default SingleCard;
