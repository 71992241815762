import React, { useEffect, useRef, useState } from "react";
import {
  ActionsImages,
  ActionsMovingLine,
  ActionsSliderInfoWrapper,
  ActionsSliderLeftSide,
  ActionsSliderLeftSideText,
  ActionsSliderRightHeading,
  ActionsSliderRightSide,
  ActionsSliderRightSubtext,
  ActionsSliderWrapper,
} from "./styles";
import naviExpPhoneImg from "../../assets/images/homepage-experiences-phone-image-prev.png";
import naviExpPhoneImg2 from "../../assets/images/homepage-experiences-phone-image-2.png";
import NaviTeamHero from "../../assets/images/navi-team-hero.jpg";

import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";

const content = [
  {
    link: "/signup",
    subtitle: "Sign up now!",
    title: "Become a navigator ",
    image: naviExpPhoneImg2,
  },
  {
    link: "/experiences",
    subtitle: "New",
    title: "Checkout newly added experiences",
    image: naviExpPhoneImg,
  },
  {
    link: "/collections/lagos",
    subtitle: "New",
    title: "Checkout newly added collections",
    image: NaviTeamHero,
  },
];

const ActionsSlider = () => {
  const [current, setCurrent] = useState(0);
  const { scrollYProgress } = useScroll();
  const targetDivRef = useRef();
  const scale = useTransform(scrollYProgress, [0, 1], [0.2, 2]);
  const [isDivVisible, setIsDivVisible] = useState(false);

  setTimeout(() => {
    setCurrent(current == 2 ? 0 : current + 1);
  }, [4500]);

  useEffect(() => {
    const handleScroll = () => {
      // console.log("handling");

      // Get the position and dimensions of the target div relative to the viewport
      const targetDivRect = targetDivRef.current.getBoundingClientRect();

      // Check if the top of the target div is visible within the viewport
      const isVisible =
        targetDivRect.top >= 0 && targetDivRect.top <= window.innerHeight;

      // Update the state based on the visibility status
      setIsDivVisible(isVisible);
    };

    // Add the event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // console.log("changing");

    if (isDivVisible) {
      // console.log("It is there now");
    }
  }, [isDivVisible]);

  return (
    <ActionsSliderWrapper ref={targetDivRef}>
      {/* <motion.div style={{ scale }}> */}
      <ActionsSliderLeftSide>
        <Link to={content[current].link}>
          <ActionsSliderLeftSideText>
            <Button
              color="#fff"
              bg="#632EFF"
              borderRadius="54px"
              fontWeight={500}
              fontSize={{ base: 14, md: 16, lg: 18 }}
              display={"flex"}
              gap="8px"
              transition={".2s ease"}
              _hover={{
                border: "1.5px solid #fff",
              }}
            >
              {content[current].subtitle}
            </Button>
            <p>
              <span>{content[current].title} </span>
              <svg
                width="26"
                height="15"
                viewBox="0 0 26 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.7071 8.20711C26.0976 7.81658 26.0976 7.18342 25.7071 6.79289L19.3431 0.428932C18.9526 0.0384078 18.3195 0.0384078 17.9289 0.428932C17.5384 0.819457 17.5384 1.45262 17.9289 1.84315L23.5858 7.5L17.9289 13.1569C17.5384 13.5474 17.5384 14.1805 17.9289 14.5711C18.3195 14.9616 18.9526 14.9616 19.3431 14.5711L25.7071 8.20711ZM0 8.5L25 8.5V6.5L0 6.5L0 8.5Z"
                  fill="#632EFF"
                />
              </svg>
            </p>
          </ActionsSliderLeftSideText>
        </Link>
        <ActionsImages>
          <AnimatePresence>
            {current === 0 && (
              <motion.img
                initial={{ opacity: 0, y: "15%" }}
                animate={{ opacity: 1, y: "0%" }}
                exit={{ opacity: 0, y: "-15%" }}
                transition={{ duration: 0.4 }}
                src={content[current].image}
                alt=""
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {current === 1 && (
              <motion.img
                initial={{ opacity: 0, y: "15%" }}
                animate={{ opacity: 1, y: "0%" }}
                exit={{ opacity: 0, y: "-15%" }}
                transition={{ duration: 0.4 }}
                src={content[current].image}
                alt=""
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {current === 2 && (
              <motion.img
                initial={{ opacity: 0, y: "15%" }}
                animate={{ opacity: 1, y: "0%" }}
                exit={{ opacity: 0, y: "-15%" }}
                transition={{ duration: 0.4 }}
                src={content[current].image}
                alt=""
              />
            )}
          </AnimatePresence>
        </ActionsImages>
      </ActionsSliderLeftSide>
      {/* </motion.div> */}

      <ActionsSliderRightSide>
        {/* <AnimatePresence> */}
        <ActionsMovingLine
          initial={{ y: "0%" }}
          animate={{
            y: current === 0 ? "0%" : current === 1 ? "115%" : "240%",
          }}
          transition={{ duration: 0.5 }}
        />
        {/* </AnimatePresence> */}
        <AnimatePresence>
          <ActionsSliderInfoWrapper
            initial={{ opacity: 0, y: "15%" }}
            animate={{ opacity: 1, y: "0%" }}
            exit={{ opacity: 0, y: "-15%" }}
            transition={{ duration: 0.5 }}
          >
            <ActionsSliderRightHeading>
              Take advantage of Navi’s collections
            </ActionsSliderRightHeading>

            <ActionsSliderRightSubtext>
              Introducing our curated collections of must-visit places and
              must-do experiences. Discover the hidden treasures and top
              recommendations in Lagos and Beyond.
            </ActionsSliderRightSubtext>
          </ActionsSliderInfoWrapper>
        </AnimatePresence>
        <AnimatePresence>
          {current > 0 && (
            <ActionsSliderInfoWrapper
              initial={{ opacity: 0, y: "15%" }}
              animate={{ opacity: 1, y: "0%" }}
              exit={{ opacity: 0, y: "-15%" }}
              transition={{ duration: 0.5 }}
            >
              <ActionsSliderRightHeading>
                Discover our experiences
              </ActionsSliderRightHeading>
              <ActionsSliderRightSubtext>
                Our team of Navigators has scoured the city to bring you the
                most unforgettable experiences. Explore a curated selection of
                experiences, including tours, workshops, and adventure
                activities, all carefully chosen to provide unique and memorable
                moments in your city.
              </ActionsSliderRightSubtext>
            </ActionsSliderInfoWrapper>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {current > 1 && (
            <ActionsSliderInfoWrapper
              initial={{ opacity: 0, y: "15%" }}
              animate={{ opacity: 1, y: "0%" }}
              exit={{ opacity: 0, y: "-15%" }}
              transition={{ duration: 0.5 }}
            >
              <ActionsSliderRightHeading>
                Become a Navigator
              </ActionsSliderRightHeading>
              <ActionsSliderRightSubtext>
                Join us as we uncover the secrets of our vibrant city and
                inspire you to embark on unforgettable adventures. Let us be
                your trusted source for discovering the extraordinary!
                #Unlocknavi #thenaviapp
              </ActionsSliderRightSubtext>
            </ActionsSliderInfoWrapper>
          )}
        </AnimatePresence>
      </ActionsSliderRightSide>
    </ActionsSliderWrapper>
  );
};

export default ActionsSlider;
