import React, { useState, useEffect } from "react";
import { Box, HStack, Text, SimpleGrid, Img, Button } from "@chakra-ui/react";
import bg from "../../../assets/images/blog-bg.png";
import styles from "../index.module.css";
import { useNavigate } from "react-router-dom";
import { TabNavItem, TabContent } from "./tab";
import circle from "../../../assets/images/deals/circle.svg";
import circle1 from "../../../assets/images/deals/circle1.svg";
import { dealsDeal } from "../../../mocks/index";
import filterIcon from "../../../assets/images/filter-lines.svg";

const options = ["Buy 1 Get 1 Free", "Percentage Off", "Free Perks"];
const AllDeals = ({ city }) => {
  const [activeTab, setActiveTab] = useState("all");
  const navigate = useNavigate();
  const [isFilter, setIsFilter] = useState(false);
  useEffect(() => {
    const resize = window.innerWidth > 589;
    if (resize) {
      setIsFilter(false);
    } else setIsFilter(true);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 589) {
        setIsFilter(false);
      } else setIsFilter(true);
    });
  }, []);

  const showFilter = () => {
    setIsFilter(!isFilter);
  };

  return (
    <Box
      mt="30px"
      padding={{ base: "2rem", md: "4rem" }}
      bgSize="cover"
      bgRepeat="no-repeat"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Text
          color="#0F0931"
          fontSize={{ base: "25px", md: "33px", lg: "50px" }}
          lineHeight="59px"
          fontWeight="600"
        >
          All Deals
        </Text>
        <Img
          src={filterIcon}
          alt="filter"
          cursor="pointer"
          className={styles.filter}
          onClick={showFilter}
        />
      </Box>

      <Box
        className={styles.tabs}
        display={isFilter ? "none" : "flex"}
        w="100%"
        justifyContent="center"
        alignItems="center"
      >
        <ul>
          <TabNavItem
            title="All"
            id="all"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabNavItem
            title="Dining"
            id="dining"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabNavItem
            title="Entertainment"
            id="entertainment"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabNavItem
            title="Recreational"
            id="recreational"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </ul>
      </Box>
      <Box>
        <TabContent id="all" activeTab={activeTab}>
          <Box position="relative">
            <Img src={circle} alt="circle" position="absolute" />
            <Img src={circle1} alt="circle" position="absolute" right="40" />
            <SimpleGrid
              mt="20px"
              columns={{ base: 1, sm: 2, lg: 3, xl: 4 }}
              justifyItems="center"
              gridAutoFlow="1fr"
              spacing={5}
              zIndex="20"
            >
              {dealsDeal.slice(0, -4).map((item, i) => {
                return (
                  <Box
                    key={i}
                    w="100%"
                    _hover={{
                      boxShadow: "xl",
                      transform: "translateY(-5px)",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    borderRadius={"24px"}
                    position="relative"
                    display="grid"
                    gridTemplateRows="3"
                    gap="10px"
                    p={"20px"}
                    bgColor="#FFFFFF"
                    boxShadow="0px 9px 46px rgba(0, 0, 0, 0.12)"
                    cursor="pointer"
                    onClick={() => {
                      navigate(`/deal/${i}`);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Img src={item.img} className={styles.images} />

                    <Text
                      color="#000000"
                      fontSize={{ base: "20px", md: "26px", lg: "28px" }}
                      lineHeight="38px"
                      fontWeight="600"
                    >
                      {item.title}
                    </Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      spacing="10px"
                    >
                      {options.map((item, index) => {
                        return (
                          <Text
                            key={i}
                            p="5px"
                            fontSize="12px"
                            lineHeight="20px"
                            color="#25AEFC"
                            border="1px solid #25AEFC"
                            borderRadius="21px"
                            mr="10px"
                            mb="10px"
                          >
                            {item}
                          </Text>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
        </TabContent>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={14}
      >
        <Button
          onClick={() => {
            navigate(`/all-deals`);
            window.scrollTo(0, 0);
          }}
          color="#ffffff"
          bg="#25AEFC"
          borderRadius={28}
          fontWeight={500}
          fontSize={18}
        >
          See More
        </Button>
      </Box>
    </Box>
  );
};

export default AllDeals;
