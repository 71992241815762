import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  Text,
  Button,
  Image,
  useDisclosure,
  Popover,
  PopoverContent,
  Input,
  Select,
} from "@chakra-ui/react";
import { TimePicker } from "antd";
import { colors } from "../../assets/styles/config";
import dayjs from "dayjs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useMemo } from "react";
import { Formik, Field, useFormik, FieldArray, getIn } from "formik";
import { inputStyle, dateStyle } from "../../pages/Admin/Dashboard/styles";

const DateRange = ({ values, setFieldValue, day }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("AM");
  const [endTime, setEndTime] = useState("AM");
  const { isOpen, onToggle, onClose } = useDisclosure();
  // useEffect(() => {
  //   if (defaultTime) {
  //     Object.keys(defaultTime)?.map((item) => {
  //       const timeParts = defaultTime && defaultTime[day].split(" ");
  //       const startTime = timeParts.slice(0, timeParts?.length - 3)?.join(" ");
  //       const endTime = timeParts.slice(3)?.join(" ");
  //       if (item === day) {
  //         setStartDate(startTime);
  //         setEndDate(endTime);
  //       }
  //     });
  //   }
  // }, [defaultTime]);

  // useEffect(() => {
  //   const timeInput1 = document.getElementById("timeInput1");
  //   const handleInput = () => {
  //     let value = timeInput1.value.replace(/[^0-9]/g, "");
  //     if (value.length > 2) {
  //       value = value.slice(0, 2) + ":" + value.slice(2);
  //     }
  //     console.log("value", value);
  //     timeInput1.value = value;
  //     setStartDate(value);
  //   };
  //   timeInput1.addEventListener("input", handleInput);

  //   return () => {
  //     timeInput1.removeEventListener("input", handleInput);
  //   };
  // }, []);

  // useEffect(() => {
  //   const timeInput2 = document.getElementById("timeInput2");
  //   const handleInput = () => {
  //     let value = timeInput2.value.replace(/[^0-9]/g, "");
  //     if (value.length > 2) {
  //       value = value.slice(0, 2) + ":" + value.slice(2);
  //     }
  //     timeInput2.value = value;
  //     setEndDate(value);
  //   };
  //   timeInput2.addEventListener("input", handleInput);

  //   return () => {
  //     timeInput2.removeEventListener("input", handleInput);
  //   };
  // }, []);

  const handleTimeInputChange = (event) => {
    const input = event.target;
    const value = input.value.replace(/[^0-9]/g, "");

    if (value.length > 2) {
      input.value = value.slice(0, 2) + ":" + value.slice(2);
    }
  };

  const handleConfirmDate = () => {
    // setFieldValue(`day.[${day}]`, `${startDate} - ${endDate}`);
    setFieldValue(`day.${day}.start`, `${startDate} ${startTime}`);
    setFieldValue(`day.${day}.end`, `${endDate} ${endTime}`);
    onClose();
  };

  // const defaultStartTime = useMemo(() => {
  //   if (defaultTime?.monday) {
  //     return Object.keys(defaultTime)?.forEach((item) => {
  //       const timeParts = defaultTime && defaultTime[day].split(" ");
  //       const startTime = timeParts.slice(0, timeParts?.length - 3)?.join(" ");
  //       let time;
  //       if (item === day) {
  //         time = dayjs(startTime, "h:mm a");
  //       } else {
  //         time = dayjs("2:00 am", "h:mm a");
  //       }
  //       return time;
  //     });
  //   } else {
  //     return dayjs("12:00 am", "h:mm a");
  //   }
  // }, [defaultTime]);

  // console.log("startDate", defaultTime, defaultStartTime);

  return (
    <Box position="relative">
      <Box
        background="#F1F5F9"
        width="100%"
        borderRadius="66px"
        padding={{ base: "10px", md: "10px 24px" }}
        onClick={onToggle}
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        _hover={{
          background: "#F3F0FF",
          border: "1px solid #AC98FE",
        }}
        _focus={{
          background: "#F3F0FF",
        }}
        _focusVisible={{
          background: "#F3F0FF",
        }}
      >
        <Text fontSize={{ base: "12px", md: "20px" }}>
          {values?.day[day]?.start} - {values?.day[day]?.end}
        </Text>
        {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </Box>
      <Popover isOpen={isOpen} onClose={onClose} placement="bottom">
        <PopoverContent
          bg="#FFFFFF"
          borderRadius="20px"
          boxShadow="0px 4px 24px #D4DEEC"
          // position="absolute"
          padding="20px"
          outline="none"
          width="100%"
        >
          <Box display="flex" alignItems="center" gap="20px" width="100%">
            <Box
              className="timeinput"
              display="flex"
              alignItems="center"
              gap="10px"
              width="100%"
            >
              <input
                type="text"
                onChange={(event) => setStartDate(event.target.value)}
                value={startDate}
                maxLength="5"
                placeholder="hh:mm"
                onInput={handleTimeInputChange}

                // maxlength="5"
              />
              {/* <Field
                component={TimeInput}
                value={startDate}
                name="startDate"
                onChange={setStartDate}
                placeholder="hh:mm"
                {...dateStyle}
                padding="20px 30px"
                width="150px"
              /> */}
              <select
                name="startTime"
                value={startTime}
                onChange={(event) => setStartTime(event.target.value)}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </Box>

            <Box
              className="timeinput"
              display="flex"
              alignItems="center"
              gap="10px"
              width="100%"
            >
              <input
                type="text"
                onChange={(event) => setEndDate(event.target.value)}
                value={endDate}
                onInput={handleTimeInputChange}
                placeholder="hh:mm"
                maxlength="5"
                // style={{width: "100px"}}
              />
              {/* <Field
                as={Input}
                value={endDate}
                name="endDate"
                onChange={(event) => setEndDate(event.target.value)}
                placeholder="hh:mm"
                {...dateStyle}
                padding="20px 30px"
                width="150px"
                type="time"
              /> */}
              <select
                name="endTime"
                value={endTime}
                onChange={(event) => setEndTime(event.target.value)}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </Box>
            {/* <Field
              component={TimePicker}
              name={`day.${day}.start`}
              defaultOpenValue={dayjs("0:00 am", "h:mm a")}
              onChange={(value) =>
                setFieldValue(`day.${day}.start`, dayjs(value).format("h:mm a"))
              }
              placeholder="time"
              format="h:mm a"
              isRequired
            />
            <Field
              component={TimePicker}
              name={`day.${day}.end`}
              defaultOpenValue={dayjs("0:00 am", "h:mm a")}
              onChange={(value) =>
                setFieldValue(`day.${day}.end`, dayjs(value).format("h:mm a"))
              }
              placeholder="time"
              format="h:mm a"
              isRequired
            /> */}
            {/* <TimePicker
              placeholder="time"
              format="h:mm a"
              defaultValue={defaultStartTime}
              onChange={(value, dateString) => {
                //console.log("Time", value, dateString);
                setStartDate(dateString);
              }}
            />
            <TimePicker
              placeholder="time"
              format="h:mm a"
              defaultValue={dayjs("12:00 am", "h:mm a")}
              onChange={(value, dateString) => {
                //  console.log("Time", value, dateString);
                setEndDate(dateString);
              }}
            /> */}
          </Box>
          <Box
            marginTop="20px"
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Button
              color={colors.primaryWhite}
              bg={colors.primaryPurple}
              borderRadius={28}
              fontWeight={500}
              fontSize={18}
              p="24px 30px"
              onClick={() => {
                if (startDate && endDate) {
                  handleConfirmDate();
                }
              }}
            >
              Apply
            </Button>
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default DateRange;
