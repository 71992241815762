import React from "react";
import { addExpBtn, addExpBtnMb } from "./styles";
import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

const MobileAddBtn = ({ onClick, Icon, style }) => {
 return (
  <Button
   {...addExpBtn}
   {...addExpBtnMb}
   type="button"
   onClick={onClick}
   style={style}
  >
   {Icon ? Icon : <AddIcon />}
  </Button>
 );
};

export default MobileAddBtn;
