import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '../../assets/styles/config';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  padding: 3rem 5rem;
  // width: 70%;
  min-height: 100px;
  border-radius: 12px;
  max-width: 500px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  background-color: ${colors.primaryWhite};
`;

// export const IconWrapper = styled(motion.div)`
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 54.17px;
  height: 54.17px;
  border-radius: 50%;
  background: ${(props) => props.iconColor || colors.blue};
  color: white;
`;

export const Text = styled.p`
  letter-spacing: 0.25px;
  color: #6c6b6b;
  text-align: center;

  @media screen and (min-width: 1080px) {
    font-size: 20px;
    // line-height: 20px;
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.buttonColor || colors.blue};
  // min-width: fit-content;
  // max-width: max-content;
  color: white;
  border-radius: 12px;
  padding: 1rem;
  min-width: 120px;
  margin-top: 30px;
`;
