import React, { useState, useEffect } from "react";
import Router from "./Router";
import { useLocation } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { CartProvider } from "./cartContext";
import { CityProvider } from "./context";
import { StepsTheme as Steps } from "chakra-ui-steps";

const CustomSteps = {
  ...Steps,
  baseStyle: (props) => {
    return {
      ...Steps.baseStyle(props),
      stepContainer: {
        ...Steps.baseStyle(props).stepContainer,
      },
      // label: {
      //   ...Steps.baseStyle(props).label,
      //   // fontFamily: "Creato Display",
      //   fontWeight: "500",
      //   color: "#0F0931",
      // },
      // description: {
      //   ...Steps.baseStyle(props).description,
      //   textAlign: "center",
      //   marginTop: "10px",
      //   color: "#878498",
      //   fontWeight: "400",
      //   // fontFamily: "Satoshi",
      // },
    };
  },
};

const theme = extendTheme({
  components: {
    Steps: CustomSteps,
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CityProvider>
        <CartProvider>
          <Router />
        </CartProvider>
      </CityProvider>
    </ChakraProvider>
  );
}

export default App;
