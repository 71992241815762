import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, fontWeight } from "../../../assets/styles/config";

export const Wrapper = styled.div``;

export const Container = styled.div`
 max-width: 1540px;
 margin: auto;
`;

export const DetailsContainer = styled.div`
 padding: 3rem 1rem;

 @media (min-width: 768px) {
  padding: 3rem;
 }

 @media (min-width: 951px) {
  padding: 60px 2rem 4rem;
 }

 @media (min-width: 1080px) {
  padding: 72px 2.5rem 4rem;
 }

 @media (min-width: 1240px) {
  padding: 82px 6rem 4rem;
 }
`;

export const TitleSection = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 gap: 40px;
 padding-inline: 2rem;
 margin-top: 85px;
 margin-bottom: 40px;

 @media (min-width: 768px) {
  margin-bottom: 74px;
  gap: 61px;
  margin-top: 90px;
 }

 @media (min-width: 991px) {
  margin-top: 110px;
 }

 @media (min-width: 1080px) {
  padding: 3rem 6rem;
  padding-bottom: 0;
  margin-top: 150px;
 }
`;

export const Title = styled.h1`
 font-size: 25px;
 font-weight: ${fontWeight.bold};
 color: ${colors.darkBlue};
 text-transform: capitalize;
 letter-spacing: 0.03em;

 @media (min-width: 768px) {
  font-size: 33px;
  line-height: 45px;
 }

 @media (min-width: 1080px) {
  font-size: 48px;
  line-height: 48px;
 }
`;

export const Heading = styled.h1`
 display: flex;
 flex-direction: column;
 text-transform: capitalize;
 gap: 18.5px;

 ${(props) => props.css || ""}
`;

export const SlideWrapper = styled.div`
 display: grid;
 grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
 margin-block: 2rem;
 gap: 2rem;

 @media (min-width: 768px) {
  grid-template-columns: repeat(auto-fill, minmax(253px, 1fr));
 }

 @media (min-width: 1080px) {
  grid-template-columns: repeat(auto-fill, minmax(353px, 1fr));
 }
`;
export const SplideSlideWrapper = styled.div`
 position: relative;
 //   width: 200px;
 height: 299px;
 cursor: pointer;
 transition: ease-in 0.2s;
 border-radius: 26px;

 &:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 }

 @media (min-width: 768px) {
  // width: 253px;
  height: 350px;
 }

 @media (min-width: 1080px) {
  // width: 353px;
  height: 499px;
 }
`;

export const SlideImg = styled.img`
 width: 100%;
 height: 100%;
 object-fit: cover;
 border-radius: 26px;
 mix-blend-mode: multiply;
`;

export const SlideText = styled.p`
 position: absolute;
 font-size: 16px;
 line-height: 24px;
 color: ${colors.primaryWhite};
 letter-spacing: -0.0041em;
 bottom: 20px;
 left: 20px;
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

 @media (min-width: 768px) {
  font-size: 18px;
 }

 @media {
  font-size: 20px;
 }
`;

export const ExpandBtn = styled.button`
 display: flex;
 align-items: center;
 justify-content: center;
 text-align: center;
 font-weight: ${fontWeight.medium};
 color: ${colors.blue};
 font-size: 18px;
 line-height: 33px;
 text-transform: capitalize;
 transition: 0.2s ease;
 width: fit-content;

 &:hover {
  scale: 0.95;
  opacity: 0.8;
 }

 @media (min-width: 768px) {
  font-size: 20px;
  line-height: 33px;
 }

 @media (min-width: 1080px) {
  font-size: 22px;
  line-height: 33px;
 }

 ${(props) => props.css || ""}
`;

export const FaintBrownImg = styled.img`
 height: 35px;
 width: 35px;
 border-radius: 25px;
 object-fit: cover;
`;

export const FaintBrownText = styled.p`
 font-style: normal;
 font-weight: 400;
 font-size: 17px;
 line-height: 25.8px;

 color: ${colors.faintBrown};

 @media (min-width: 768px) {
  font-size: 18px;
 }
 @media (min-width: 1080px) {
  font-size: 20px;
 }
`;

export const TagButton = styled(motion.button)`
 font-size: 16px;
 line-height: 24px;
 font-weight: ${fontWeight.medium};
 color: ${(props) => (props.selected ? colors.primaryPurple : colors.brown)};
 border-bottom: ${(props) =>
  props.selected ? `3px solid ${colors.primaryPurple}` : ""};

 @media (min-width: 768px) {
  font-size: 18px;
  line-height: 28px;
 }
 @media (min-width: 1080px) {
  font-size: 20px;
  line-height: 30px;
 }
`;

export const SearchLabel = styled.label`
 position: relative;

 .icon {
  position: absolute;
  left: 5px;
 }

 @media (min-width: 768px) {
  .icon {
   top: 0.5rem;
  }
 }
`;

export const SearchInput = styled.input`
 background: ${colors.skyBlue};
 border-radius: 10px;
 width: 100%;
 height: 38px;
 font-size: 14px;
 padding: 10px 12px 10px 40px;
 color: ${colors.brown};

 @media (min-width: 768px) {
  font-size: 15.5px;
  width: 480px;
  border-radius: 20px;
 }

 @media (min-width: 1080px) {
  font-size: 17px;
  height: 42px;
 }

 &:focus {
  outline: 1px solid ${colors.blue};
 }

 &::placeholder {
  color: ${colors.placeholderBrown};
 }

 &:-ms-input-placeholder {
  color: ${colors.placeholderBrown};
 }

 &::-ms-input-placeholder {
  color: ${colors.placeholderBrown};
 }
`;

export const NoCollections = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
 min-width: 300px;
 min-height: 300px;
 font-size: 18px;
 font-weight: ${fontWeight.medium};
 color: ${colors.brown};
 line-height: 24px;

 @media (min-width: 768px) {
  font-size: 22px;
  line-height: 28px;
 }
 @media (min-width: 1080px) {
  font-size: 24px;
  line-height: 30px;
 }
`;

export const ExperienceImg = styled.img`
 object-fit: cover;
 width: 100%;
 height: 100%;
 border-radius: 25px;

 @media (max-width: 768px) {
  max-height: 276px;
 }

 @media (min-width: 1080px) {
  max-height: 545px;
  border-radius: 30px;
 }

 @media (min-width: 1240px) {
  border-radius: 46px;
  max-height: 545px;
 }
 ${(props) => props.css || ""}
`;

export const ExpImgWrapper = styled.div`
 height: 276px;
 padding: 0 15px;

 @media (min-width: 768px) {
  height: 330px;
 }
 @media (min-width: 1080px) {
  padding: 0 6rem;
  height: 450px;
 }
`;

export const Details = styled.div`
 // display: flex;
 // flex-wrap: wrap;
 gap: 4rem;

 @media (min-width: 768px) {
  display: grid;
  // place-items: center;
 }

 @media (min-width: 951px) {
  gap: 2rem;
  grid-template-columns: 1fr 400px;
 }

 @media (min-width: 1240px) {
  padding: 0 2rem;
  gap: 4rem;
  grid-template-columns: 1fr 471px;
 }
`;

export const TicketArea = styled.div`
 background: #ffffff;
 box-shadow: 0px 4px 24px 0px #d4deec;
 border-radius: 16px;
 padding: 23px;

 @media (min-width: 1080px) {
  padding: 30px;
 }

 @media (min-width: 1240px) {
  padding: 43px;
 }
`;

export const TicketAreaTitle = styled.p`
 font-weight: ${fontWeight.medium};
 font-size: 18px;
 letter-spacing: 0.03em;

 @media (min-width: 768px) {
  font-size: 20px;
 }

 @media (min-width: 1080px) {
  font-size: 22px;
  line-height: 24px;
 }

 @media (min-width: 1240px) {
  font-size: 24px;
  line-height: 24px;
 }
 ${(props) => props.css || ""}
`;

export const TicketAreaTitle2 = styled.p`
 font-weight: ${fontWeight.medium};
 font-size: 18px;

 @media (min-width: 768px) {
  font-size: 20px;
 }

 @media (min-width: 1080px) {
  font-size: 22px;
  line-height: 27px;
 }

 @media (min-width: 1240px) {
  font-size: 24px;
  line-height: 27px;
 }
 ${(props) => props.css || ""}
`;

export const TicketAreaSubtitle = styled.p`
 color: ${colors.faintBrown};
 font-size: 14px;
 margin-top: 6px;

 @media (min-width: 768px) {
  margin-top: 12px;
  font-size: 15px;
 }

 @media (min-width: 1080px) {
  font-size: 16px;
  line-height: 21px;
 }
 ${(props) => props.css || ""}
`;

export const TicketAreaTotal = styled.p`
 font-size: 14px;
 letter-spacing: 0.05em;
 text-transform: uppercase;
 color: #64748b;
`;

export const DetailWrapper = styled.p`
 display: flex;
 gap: 0.5rem;
 flex-direction: column;
 margin-bottom: 20px;

 @media (min-width: 1080px) {
  max-width: 323px;
 }
`;

export const DetailText = styled.p`
 display: flex;
 align-items: center;
 // align-items: flex-start;
 gap: 0.75rem;
 font-size: 14px;
 line-height: 21px;
 color: #828282;
 text-transform: capitalize;

 &.location,
 &.money {
  font-size: 14px;
  color: #334155;
 }

 svg {
  font-size: 22px;
 }

 svg.single {
  font-size: 22px;
 }
 ${(props) => props.css || ""}
`;

export const CounterIcon = styled.button`
 border-radius: 50%;
 font-size: 20px;
 line-height: 30px;
 width: 30px;
 height: 30px;
 display: flex;
 align-items: center;
 justify-content: center;
 transition: ease 0.2s;
 user-select: none;
 cursor: pointer;

 &:hover {
  scale: 1.15;
 }

 &:active {
  scale: 0.9;
 }
`;

export const CounterText = styled.p`
 font-weight: ${fontWeight.medium};
 color: ${colors.darkBlue};
 font-size: 16px;
 line-height: 27px;
 user-select: none;

 @media (min-width: 768px) {
  font-size: 18px;
 }

 @media (min-width: 1080px) {
  font-size: 19px;
 }

 @media (min-width: 1240px) {
  font-size: 20px;
 }
`;

export const AddToBtn = styled.button`
 background: ${(props) => (props.disabled ? "#C8C8C8" : colors.blue)};
 color: ${colors.primaryWhite};
 width: 169px;
 height: 49px;
 border-radius: 28px;
 font-weight: 500;
 font-size: 15px;
 line-height: 27px;

 @media (min-width: 768px) {
  font-size: 16px;
 }
 @media (min-width: 1080px) {
  height: 59px;
  font-size: 18px;
 }
`;
export const AddToBtn2 = styled.div`
 // height: 59px;
 // width: 80%;
 font-weight: 500;
 font-size: 15px;
 border-radius: 12px;
 line-height: 27px;

 @media (min-width: 768px) {
  // width: 169px;
  border-radius: 28px;
  font-size: 16px;
 }
 @media (min-width: 1080px) {
  // height: 30px;
  font-size: 18px;
 }
`;

export const addBtnCss = css`
 width: 169px;
 height: 49px;
 border-radius: 28px;
 font-weight: 500;
 font-size: 15px;
 line-height: 27px;

 @media (min-width: 768px) {
  font-size: 16px;
 }
 @media (min-width: 1080px) {
  height: 59px;
  font-size: 18px;
 }
`;

// REACT CALENDAR STYLING
export const WrapCalendar = styled.div`
 margin-block: 0.75rem;

 @media (min-width: 768px) {
  margin-block: 1.5rem;
 }

 @media (min-width: 1240px) {
  margin-block: 3rem;
 }

 .react-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
 }

 .react-calendar__navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }

 .react-calendar__navigation__label {
  color: #0f2552;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Creato Display", sans-serif;
  font-size: 14px;
  line-height: 21px;
 }

 .react-calendar__navigation__next-button {
  font-size: 30px;
  color: #848a95;
 }

 .react-calendar__navigation__prev-button {
  font-size: 30px;
  color: #848a95;
 }

 .react-calendar__navigation__next2-button {
  display: none;
 }

 .react-calendar__navigation__prev2-button {
  display: none;
 }

 .react-calendar__month-view__weekdays {
  text-align: center;
  color: ${colors.darkBlue};
  font-weight: 500;
  padding-bottom: 1rem;
 }

 .react-calendar__month-view__days {
  text-align: center;
  row-gap: 8px;
  color: ${colors.darkBlue};
 }

 .react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.6;
 }

 .react-calendar__tile.react-calendar__month-view__days__day abbr {
  font-family: "Creato Display", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #0f2552;
  padding: 8px 13.35px;
 }

 .disable {
  pointer-events: none;
 }

 .disable abbr {
  color: #dedede !important;
 }

 .react-calendar__tile:disabled abbr {
  color: #dedede !important;
 }

 //  .active {
 //   flex: inherit !important;
 //  }

 .active abbr {
  border-radius: 9px;
  background: ${colors.primaryPurple};
  border: 0.5px solid ${colors.primaryPurple};
  color: ${colors.primaryWhite} !important;
 }

 .react-calendar__tile--now {
  // background: ${colors.skyBlue};
  // border: 1.5px solid ${colors.blue};
  border-radius: 12px;
  color: #dedede !important;
 }

 .react-calendar__tile.react-calendar__tile--active {
  // background: ${colors.blue};
  // border-radius: 12px;
  color: #dedede !important;
 }

 .highlight {
 }

 .react-calendar__tile.react-calendar__tile--active abbr {
  //  color: ${colors.primaryWhite} !important;
 }

 abbr[title] {
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  font-family: "Creato Display", sans-serif;
 }
`;

export const ListGroup = styled.ul`
 display: flex;
 flex-direction: column;
 gap: 0.23rem;
 padding-left: 1rem;
`;

export const ListItem = styled.li`
 text-transform: capitalize;
`;

export const Time = styled.div`
 display: flex;
 // flex-wrap: wrap;
 align-items: center;
 gap: 20px;
 overflow-x: scroll;
 scrollbar-width: none;

 &::-webkit-scrollbar {
  display: none;
 }
`;

export const TimeSlot = styled.div`
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 25px;
  min-width: max-content;
 border: 1px solid #CBD5E1
  font-weight: 500;
`;

// Meet Your Host Styling

export const FirstMeetYourHost = styled.div`
 @media (max-width: 951px) {
  display: none;
 }
`;

export const MeetYourHost = styled.div`
 margin-block: 4rem;

 @media (min-width: 951px) and (max-width: 1080px) {
  margin-left: 2rem;
 }

 @media (max-width: 951px) {
  margin-block: 2.5rem;
 }

 ${(props) => props.css || ""}
`;

export const MidMobileWrapper = styled.div`
 display: none;

 @media (max-width: 951px) {
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 2rem;
  justify-content: center;
 }

 @media (max-width: 786px) {
  margin-block: 3rem;
  display: flex;
  flex-direction: column;
 }
`;

export const HostProfile = styled.div`
 display: flex;
 align-items: center;
 gap: 8px;
`;

export const HostProfileDetails = styled.div``;

export const HostTitle = styled.p`
 font-size: 18px;
 font-weight: 500;
 text-transform: capitalize;

 @media (min-width: 768px) {
  font-size: 20px;
 }

 @media (min-width: 1080px) {
  font-size: 22px;
 }

 @media (min-width: 1240px) {
  font-size: 24px;
 }
`;

export const HostAvatar = styled.img`
 width: 38px;
 height: 38px;
 object-fit: cover;
 border-radius: 50%;
`;

export const HostContact = styled.a`
 display: flex;
 align-items: center;
 gap: 8px;
 font-size: 16px;
 line-height: 25px;

 @media (min-width: 1080px) {
  font-size: 18px;
 }

 @media (min-width: 1240px) {
  font-size: 20px;
 }

 ${(props) => props.css || ""}
`;

export const SlidesContainer = styled.div`
 padding: 0 30px;

 .splide__track--nav > .splide__list > .splide__slide.is-active {
  border: none;
 }

 @media (min-width: 1080px) {
  padding: 0 50px;
 }
`;

export const SlideHack = styled.div`
 padding: 20px 0;
`;
