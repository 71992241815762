import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 Img,
 Text,
 Input,
 FormControl,
 FormLabel,
 FormErrorMessage,
} from "@chakra-ui/react";
import { inputStyle } from "../../../Admin/Dashboard/styles";
import { css } from "styled-components";
import { Button } from "../../../../components";
import { AuthContext } from "../../../../context/AuthContext";
import { Formik, Field } from "formik";
import { getDatabase, ref as dbRef, child, get } from "firebase/database";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";

const AddBank = () => {
 const { user, updatePartner } = useContext(AuthContext);
 const [isEdit, setIsEdit] = useState(false);
 const [partner, setPartner] = useState("");
 const [loading, setLoading] = useState(false);
 const [banks, setBanks] = useState([]);
 const [account, setAccount] = useState({});
 const paystackUrl = "https://api.paystack.co";
 const secretKey = process.env.REACT_APP_PAYSTACK_SECRET_KEY;
 //  PRODUCTION
 //  const secretKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;
 const uid = user?.partnerUid ?? user?.uid;

 const fetchDatabase = async () => {
  setLoading(true);
  const db = await get(child(dbRef(getDatabase()), `users/partner`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     const myObj = snapshot.val();

     setPartner(
      Object.keys(myObj)
       .map((key) => {
        return myObj[key];
       })
       .filter((item) => item?.uid === uid)[0]
     );
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  //get users order

  return db;
 };

 const fetchSubAccount = async (id) => {
  const paystackResponse = await axios.get(`${paystackUrl}/subaccount/${id}`, {
   headers: {
    Authorization: `Bearer ${secretKey}`,
   },
  });

  if (paystackResponse?.data?.status === true) {
   setAccount(paystackResponse?.data?.data);
  }
 };

 useEffect(() => {
  if (partner?.bank) {
   setIsEdit(true);
   fetchSubAccount(partner?.bank && JSON.parse(partner?.bank)?.id);
  }
 }, [partner]);

 const fetchBanks = async () => {
  const paystackResponse = await axios.get(`${paystackUrl}/bank`, {
   headers: {
    Authorization: `Bearer ${secretKey}`,
   },
  });

  if (paystackResponse?.data?.status === true) {
   setBanks(
    paystackResponse?.data?.data?.map((item) => ({
     value: item?.code.toString(),
     label: item.name,
    }))
   );
  }
 };

 useEffect(() => {
  if (user) {
   fetchDatabase();
   fetchBanks();
  }
 }, [user]);

 //   useEffect(() => {
 //     if (isEdit) {
 //       fetchSubAccount(id);
 //     }
 //   }, [isEdit]);

 return (
  <Box height="100vh" padding="14px 20px" background="#F5F8FA">
   <Formik
    initialValues={{
     bankName: "",
     bankNumber: "",
    }}
    onSubmit={async (values) => {
     toast.loading("loading");

     const payload = {
      business_name: partner?.businessName,
      settlement_bank: values?.bankName?.value,
      account_number: values?.bankNumber.toString(),
      percentage_charge: partner?.bank
       ? JSON.parse(partner?.bank)?.percentage_charge
       : partner?.subscribed
       ? 2
       : 5,
     };

     var data = JSON.stringify(payload);
     let config;

     if (!partner?.bank) {
      config = {
       method: "post",
       url: `${paystackUrl}/subaccount`,
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${secretKey}`,
       },
       data: data,
      };
     } else {
      const accountId = partner?.bank && JSON.parse(partner?.bank)?.id;
      config = {
       method: "put",
       url: `${paystackUrl}/subaccount/${accountId}`,
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${secretKey}`,
       },
       data: data,
      };
     }

     await axios(config)
      .then(async (res) => {
       if (res?.data?.status === true) {
        await updatePartner({
         ...partner,
         bank: JSON.stringify(res?.data?.data),
        }).then(() => {
         toast.dismiss();
         if (!isEdit) {
          toast.success("Successfully added bank");
         } else toast.success("Successfully updated bank");
        });
       }
      })
      .catch((error) => {
       toast.dismiss();
       toast.error(error?.response?.data?.message);
      })
      .finally(() => {
       setTimeout(() => {
        toast.dismiss();
       }, 3000);
      });
    }}
   >
    {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => {
     useEffect(() => {
      if (isEdit && account) {
       setFieldValue("bankName", {
        value: account?.bank,
        label: account?.settlement_bank,
       });
       setFieldValue("bankNumber", account?.account_number);
      }
     }, [isEdit, account]);
     return (
      <form onSubmit={handleSubmit}>
       <Box
        background="#FFFFFF"
        borderRadius="6px"
        padding="30px"
        width="100%"
        // mt="16px"
        marginX="auto"
        height="80vh"
       >
        <Box w={{ base: "100%", md: "478px" }}>
         <Box marginBottom="30px">
          <Text
           color="#0F0931"
           fontFamily="Creato Display, Roboto, sans-serif;"
           fontSize="20px"
           fontWeight="700"
           marginBottom="20px"
          >
           Add bank details
          </Text>
          <FormControl
           w="100%"
           mb="20px"
           isRequired
           isInvalid={!!errors.bankName && touched.bankName}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Bank name
           </FormLabel>

           <Select
            name="bankName"
            id="bankName"
            value={values.bankName}
            onChange={(option) => {
             setFieldValue(`bankName`, option);
            }}
            options={banks}
            onBlur={handleBlur}
            styles={{
             control: (provided, state) => ({
              ...provided,
              borderRadius: "66px",
              background: "#F1F5F9",
              autoFocus: true,
              "&:hover": {
               background: "#F3F0FF",
               border: "1px solid #AC98FE",
              },
              "&:focus": {
               background: "#F3F0FF",
              },
              "&:focusVisible": {
               border: "1px solid #AC98FE",
              },
             }),
            }}
           />
           <FormErrorMessage>{errors.bankName}</FormErrorMessage>
          </FormControl>

          <FormControl
           w="100%"
           mb="20px"
           isRequired
           isInvalid={!!errors.bankNumber && touched.bankNumber}
          >
           <FormLabel
            fontFamily="Satoshi"
            color="#0F0931"
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="21px"
            fontWeight="700"
           >
            Account number
           </FormLabel>
           <Field
            as={Input}
            {...inputStyle}
            type="number"
            placeholder=""
            id="bankNumber"
            name="bankNumber"
           />
           <FormErrorMessage>{errors.bankNumber}</FormErrorMessage>
          </FormControl>
         </Box>

         <Button
          animateOnTap
          animateOnHover
          buttonColor="#632EFF"
          css={css`
           width: fit-content;
           padding: 0.5rem 2rem;
           height: auto;
           font-weight: 500;
           font-family: "Creato Display";
           display: flex;
           gap: 15px;

           @media screen and (max-width: 768px) {
            border-radius: 10px;
           }
          `}
          type="submit"
         >
          {isEdit ? "Update Bank" : "Add Bank"}
         </Button>
        </Box>
       </Box>
      </form>
     );
    }}
   </Formik>
  </Box>
 );
};

export default AddBank;
