import React from "react";
import {
  Box,
  Text,
  Img,
  Button,
  FormControl,
  Image,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import ArrowLeft from "../../../assets/images/arrow-left-dark.svg";
import ArrowLeftDark from "../../../assets/images/arrow-left-dark.svg";
import { colors } from "../../../assets/styles/config";
import Logo from "../../../assets/images/navi-logo-white.png";
import Logo1 from "../../../assets/images/Navi-Logo.png";
import styles from "../index.module.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import NaviBg from "../../../assets/images/lagos-night.jpeg";
import { useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import Check from "../../../assets/images/check-round.svg";
import ProfileCircle from "../../../assets/images/profile-circle.svg";
import Building from "../../../assets/images/buildings.svg";

const Introduction = ({ onSwitchStage, data }) => {
  const navigate = useNavigate();
  const options = [
    {
      icon: ProfileCircle,
      title: "Individual",
      text: "If you're hosting small-scale experiences or activities, usually limited to your personal expertise or interests. These events tend to have a more intimate and personalized feel. ",
    },
    {
      icon: Building,
      title: "Business",
      text: "Create a business account if you have a physical location such as a Hotel, Restaurant, Bar, Spa or Resort. Business accounts will be listed on our mobile app.",
    },
  ];
  return (
    <>
      {/* <Header color="dark" p={{ base: "15px 10px", lg: "20px 60px" }} /> */}

      <Box
        position="relative"
        width="100%"
        height={{ md: "100vh" }}
        display="flex"
        alignItems="start"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box
          position="absolute"
          left="30px"
          top={{ base: "3%", md: "5%" }}
          zIndex="200"
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={() => navigate(-1)}
          // marginBottom={{ base: "10px", md: "30px" }}
        >
          <Image src={ArrowLeft} />
          <Text marginLeft="10px">Go Back</Text>
        </Box>
        <Box
          padding="40px 30px"
          bgColor={{ md: "#F1F5F9" }}
          position={{ sm: "relative", md: "fixed" }}
          top="0"
          left="0"
          height="100%"
          width={{ md: "500px" }}

          // display={{ base: "none", md: "block" }}
        >
          <Text
            color={colors.darkBlue}
            // fontFamily="Space Mono"
            fontWeight="500"
            fontSize="20px"
            lineHeight="135%"
            marginBottom="40px"
            marginTop="40px"
          >
            Whether you are a business or an individual offering unique
            experiences, our platform is designed to help you showcase and sell
            your offerings to a wider audience.
          </Text>

          <Box display="flex" flexDir="column" alignItems="flex-start">
            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              marginBottom="20px"
            >
              <Img src={Check} alt="check" />
              <Box>
                <Text
                  color={colors.darkBlue}
                  fontWeight="500"
                  fontSize="18px"
                  lineHeight="126%"
                  marginBottom="5px"
                  letterSpacing="0.1px"
                >
                  Seamless Onboarding
                </Text>
                <Text
                  color="#64748B"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="126%"
                >
                  Getting started is a breeze. Our user-friendly interface makes
                  it simple for businesses and individuals to set up their
                  profiles
                </Text>
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              marginBottom="20px"
            >
              <Img src={Check} alt="check" />
              <Box>
                <Text
                  color={colors.darkBlue}
                  fontWeight="500"
                  fontSize="18px"
                  lineHeight="126%"
                  marginBottom="5px"
                  letterSpacing="0.1px"
                >
                  Amplify Your Reach
                </Text>
                <Text
                  color="#64748B"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="126%"
                >
                  By joining our platform, you gain access to a large customer
                  base actively seeking exciting tourism experiences and events.
                </Text>
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              marginBottom="20px"
            >
              <Img src={Check} alt="check" />
              <Box>
                <Text
                  color={colors.darkBlue}
                  fontWeight="500"
                  fontSize="18px"
                  lineHeight="126%"
                  marginBottom="5px"
                  letterSpacing="0.1px"
                >
                  Flexible Listing Options
                </Text>
                <Text
                  color="#64748B"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="126%"
                >
                  Our platform lets you showcase your offerings in the most
                  compelling way possible and let customers book with ease.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box
          backgroundColor="rgba(0, 0, 0, 0.58)"
          display={{ base: "none", md: "block" }}
        >
          <Image
            src={NaviBg}
            objectFit="cover"
            width="100%"
            height="100vh"
            zIndex="0"
            mixBlendMode="multiply"
          />
        </Box> */}

        {/* <Box
          padding="20px"
          // top="5%"
          cursor="pointer"
          onClick={() => navigate(-1)}
          display={{ base: "block", md: "none" }}
        >
          <Image src={ArrowLeftDark} />
        </Box> */}
        <Box
          // position={{ base: "relative", md: "absolute" }}
          // left={{ md: "40px" }}
          // top={{ md: "15%" }}
          paddingLeft={{ md: "500px" }}
          height="100%"
          width="100%"
          zIndex="100"
          display="flex"
          alignItems="center"
          justifyContent="center"
          // flexDirection="column"
          // padding={{ base: "20px", md: "0px" }}
          // marginTop={{ base: "30px", md: "40px" }}
        >
          {/* <Img
            src={Logo}
            h="60px"
            marginBottom="20px"
            display={{ base: "none", md: "block" }}
          />
          <Img
            src={Logo1}
            h="60px"
            display={{ base: "block", md: "none" }}
            marginBottom="20px"
          /> */}
          {/* <Text
              color={{ md: "#ffffff" }}
              mb="20px"
              fontFamily="Creato Display, Roboto, sans-serif;"
              fontSize={{ base: "18px", md: "24px" }}
              lineHeight="125%"
              fontWeight={{ base: "300", md: "500" }}
            >
              Hi Navigator!, we’re so excited to have you onboard with us and
              join us in our mission of changing the narrative of tourism in
              Africa. We have created an onboarding process for businesses and
              Individuals, please Identify if you are a business or an
              Individual to proceed.
            </Text>
            <Text
              color={{ md: "#ffffff" }}
              mb="20px"
              fontFamily="Creato Display, Roboto, sans-serif;"
              fontSize={{ base: "18px", md: "24px" }}
              lineHeight="125%"
              fontWeight={{ base: "300", md: "500" }}
            >
              Creating a business account is best if you have a physical
              location you would like to list on our mobile application.{" "}
            </Text>
            <Text
              color={{ md: "#ffffff" }}
              mb="20px"
              fontFamily="Creato Display, Roboto, sans-serif;"
              fontSize={{ base: "18px", md: "24px" }}
              lineHeight="125%"
              fontWeight={{ base: "300", md: "500" }}
            >
              Registering as an individual is best if you are hosting an
              experience or an event
            </Text> */}
          <Box
            padding={{ base: "0px 20px 40px", md: "0px 100px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Text
              marginBottom="30px"
              color={colors.darkBlue}
              fontWeight="500"
              fontSize={{ base: "20px", md: "32px" }}
              lineHeight="125%"
            >
              Choose an account type
            </Text>
            <Formik
              initialValues={{
                registeringAs: "Individual",
              }}
              onSubmit={async (values) => {
                onSwitchStage(values);
              }}
            >
              {({ handleSubmit, errors, touched, values }) => (
                <form onSubmit={handleSubmit} className={styles.radioform}>
                  <Field name="registeringAs">
                    {({ field, form }) => {
                      const { onChange, ...rest } = field;
                      return (
                        <FormControl
                          id="registeringAs"
                          isRequired
                          isInvalid={
                            !!errors.registeringAs && !!touched.registeringAs
                          }
                        >
                          <RadioGroup
                            {...rest}
                            id="registeringAs"
                            display="flex"
                            gap="20px"
                            flexDir={{ base: "column", md: "row" }}
                          >
                            {options.map((value, index) => (
                              <div
                                className={
                                  values.registeringAs === value.title
                                    ? [styles.radio, styles.radiochecked].join(
                                        " "
                                      )
                                    : [styles.radio].join(" ")
                                }
                                key={index}
                              >
                                <Radio
                                  onChange={onChange}
                                  value={value.title}
                                  colorScheme="purple"
                                  padding="16px 24px"
                                  className="registeringAs"
                                  // color="#ffffff"
                                >
                                  <Img
                                    src={value.icon}
                                    alt="icon"
                                    marginBottom="20px"
                                    marginX="auto"
                                  />
                                  <Text
                                    textAlign="center"
                                    color={colors.darkBlue}
                                    fontWeight="500"
                                    fontSize={{ base: "18px", md: "24px" }}
                                    lineHeight="125%"
                                    marginBottom="10px"
                                  >
                                    {value.title.toUpperCase()}
                                  </Text>
                                  <Text
                                    textAlign="center"
                                    color="#64748B"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="125%"
                                    letterSpacing="0.1px"
                                  >
                                    {value.text}
                                  </Text>
                                </Radio>
                              </div>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      );
                    }}
                  </Field>
                  <Button
                    color={colors.primaryWhite}
                    bg={colors.primaryPurple}
                    borderRadius={28}
                    fontWeight={500}
                    fontSize={18}
                    marginTop={30}
                    p="24px 20px"
                    width={{ base: "100%", md: "30%" }}
                    type="submit"
                    textAlign="center"
                  >
                    Continue
                  </Button>
                </form>
              )}
            </Formik>
          </Box>
          {/* <Img src={Logo} h="50px" display={{ base: "block", lg: "none" }} /> */}
        </Box>
      </Box>
    </>
  );
};

export default Introduction;
