import { Text } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../../components/Layout";

const AdminDashboardLayout = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default AdminDashboardLayout;
