import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  HStack,
  Text,
  SimpleGrid,
  Img,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  RadioGroup,
  Radio,
  Select,
} from "@chakra-ui/react";
import { colors } from "../../../assets/styles/config";
import { Formik, Field } from "formik";
import { selectStyle } from "../../Admin/Dashboard/styles";

const GeneralDetails = ({ onSwitchStage, data }) => {
  const options = ["yes", "no"];
  const establishmentOption = [
    "Restaurant",
    "Bar",
    "Cafe",
    "Cafeteria",
    "Hotel",
    "Serviced apartment / B&B",
    "Recreation centers",
    "Spa",
    "Nightclub",
    "Diner",
    "Delis",
    "Gaming center",
    "Casino",
    "Pub",
    "Art Gallery",
    "Park",
    "Amusement Park",
    "Beach House",
    "Beach Club",
    "Coworking Space",
    "Theater",
    "Zoo",
    "Museum",
    "Salon",
  ];
  return (
    <Box width="100%" height="100%">
      <Box
        borderBottom="1px solid #E2E8F0"
        py="20px"
        marginTop="20px"
        display={{ base: "none", md: "block" }}
      >
        <Text
          fontFamily="Creato Display, Roboto, sans-serif;"
          color={colors.darkBlue}
          fontWeight="400"
          lineHeight="135%"
          fontSize="24px"
        >
          1. General Details
        </Text>
      </Box>
      <Box
        py="20px"
        marginTop="20px"
        display={{ base: "flex", md: "none" }}
        flexDir="column"
        alignItems="flex-start"
      >
        <Text color="#878498" fontFamily="Creato Display, Roboto, sans-serif;">
          Step 1 of 5
        </Text>
        <Text
          fontFamily="Creato Display, Roboto, sans-serif;"
          color={colors.darkBlue}
          fontWeight="400"
          lineHeight="135%"
          fontSize="24px"
          marginTop="20px"
        >
          General Details
        </Text>
        <Text
          fontFamily="Satoshi"
          color={colors.darkBlue}
          fontWeight="500"
          lineHeight="20px"
          fontSize="16px"
          marginTop="10px"
        >
          Please provide some information for our team
        </Text>
      </Box>
      <Box mt="30px">
        <Formik
          initialValues={{
            businessOwner: "",
            registerBefore: "",
            businessOpen: "",
            establishmentType: "",
          }}
          onSubmit={async (values) => {
            onSwitchStage(values);
          }}
          // validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box marginBottom="40px">
                <Field name="businessOwner">
                  {({ field, form }) => {
                    const { onChange, ...rest } = field;
                    return (
                      <FormControl
                        id="businessOwner"
                        isRequired
                        isInvalid={
                          !!errors.businessOwner && !!touched.businessOwner
                        }
                      >
                        <FormLabel
                          htmlFor="businessOwner"
                          color={colors.darkBlue}
                          fontFamily="Satoshi"
                          fontWeight="500"
                          fontSize="20px"
                          lineHeight="135%"
                          mb="10px"
                        >
                          Are you the owner, employee or representative of this
                          business?
                        </FormLabel>
                        <RadioGroup
                          {...rest}
                          id="businessOwner"
                          backgroundColor="#F1F5F9"
                          borderRadius="24px"
                          padding="16px 24px"
                          width="200px"
                          display="flex"
                          gap="20px"
                        >
                          {options.map((value) => (
                            <Radio
                              onChange={onChange}
                              value={value}
                              colorScheme="purple"
                            >
                              {value.toUpperCase()}
                            </Radio>
                          ))}
                        </RadioGroup>
                        <FormErrorMessage>
                          {errors.businessOwner}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                {values.businessOwner === "no" && (
                  <Text color={colors.deepRed}>
                    Sorry you have to be either an employee, a representative or
                    the owner of the business to register this business.
                  </Text>
                )}
              </Box>

              {values.businessOwner === "yes" && (
                <Box marginBottom="40px">
                  <Field name="registerBefore">
                    {({ field, form }) => {
                      const { onChange, ...rest } = field;
                      return (
                        <FormControl
                          id="registerBefore"
                          isRequired
                          isInvalid={
                            !!errors.registerBefore && !!touched.registerBefore
                          }
                        >
                          <FormLabel
                            htmlFor="registerBefore"
                            color={colors.darkBlue}
                            fontFamily="Satoshi"
                            fontWeight="500"
                            fontSize="20px"
                            lineHeight="135%"
                            mb="10px"
                          >
                            Have you tried registering before?
                          </FormLabel>
                          <RadioGroup
                            {...rest}
                            id="registerBefore"
                            backgroundColor="#F1F5F9"
                            borderRadius="24px"
                            padding="16px 24px"
                            width="200px"
                            display="flex"
                            gap="20px"
                          >
                            {options.map((value) => (
                              <Radio
                                onChange={onChange}
                                value={value}
                                colorScheme="purple"
                              >
                                {value.toUpperCase()}
                              </Radio>
                            ))}
                          </RadioGroup>
                          <FormErrorMessage>
                            {errors.registerBefore}
                          </FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>
                  {values.registerBefore === "yes" && (
                    <Text color={colors.deepRed}>
                      Please contact support@thenaviapp.com to finish your
                      registration. Multiple registrations from the same
                      business will be considered as spam and will be denied
                      access.
                    </Text>
                  )}
                </Box>
              )}
              {values.registerBefore === "no" &&
                data?.registeringAs === "Business" && (
                  <>
                    <Box marginBottom="40px">
                      <Field name="businessOpen">
                        {({ field, form }) => {
                          const { onChange, ...rest } = field;
                          return (
                            <FormControl
                              // isRequired
                              id="businessOpen"
                              isInvalid={
                                !!errors.businessOpen && !!touched.businessOpen
                              }
                            >
                              <FormLabel
                                htmlFor="businessOpen"
                                color={colors.darkBlue}
                                fontFamily="Satoshi"
                                fontWeight="500"
                                fontSize="20px"
                                lineHeight="135%"
                                mb="10px"
                              >
                                Is this business open?
                              </FormLabel>
                              <RadioGroup
                                {...rest}
                                id="businessOpen"
                                backgroundColor="#F1F5F9"
                                borderRadius="24px"
                                padding="16px 24px"
                                width="200px"
                                display="flex"
                                gap="20px"
                              >
                                {options.map((value) => (
                                  <Radio
                                    onChange={onChange}
                                    value={value}
                                    colorScheme="purple"
                                  >
                                    {value.toUpperCase()}
                                  </Radio>
                                ))}
                              </RadioGroup>
                              <FormErrorMessage>
                                {errors.businessOpen}
                              </FormErrorMessage>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </Box>
                    <Box>
                      <FormControl
                        id="establishmentType"
                        isInvalid={
                          !!errors.establishmentType &&
                          !!touched.establishmentType
                        }
                        // isRequired
                      >
                        <FormLabel
                          htmlFor="establishmentType"
                          color={colors.darkBlue}
                          fontFamily="Satoshi"
                          fontWeight="500"
                          fontSize="20px"
                          lineHeight="135%"
                          mb="10px"
                        >
                          Type of establishment
                        </FormLabel>
                        <Select
                          width="40%"
                          onChange={(e) => {
                            setFieldValue("establishmentType", e.target.value);
                          }}
                          id="establishmentType"
                          name="establishmentType"
                          // required
                          {...selectStyle}
                        >
                          <option value="" selected hidden>
                            Select an establishment
                          </option>
                          {establishmentOption.map((item, idx) => (
                            <option value={item} key={idx}>
                              {item}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
              {values.registerBefore === "no" && (
                <Button
                  color={colors.primaryWhite}
                  bg={colors.primaryPurple}
                  borderRadius={28}
                  fontWeight={500}
                  fontSize={18}
                  marginTop={10}
                  p="24px 20px"
                  width={{ base: "100%", md: "25%" }}
                  type="submit"
                >
                  Next
                </Button>
              )}
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default GeneralDetails;
