import React from "react";
import PropType from "prop-types";
import { Dots, Container } from "./styles";
import { colors } from "../../assets/styles/config";

const containerVariant = {
  start: {
    transition: { staggerChildren: 0.2 },
  },
  end: {
    transition: { staggerChildren: 0.2 },
  },
};

const dotsVariants = {
  start: {
    y: "0%",
  },
  end: {
    y: "100%",
  },
};

const loadingTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: "easeInOut",
};

export default function Indicator({ dotscolor, size, css }) {
  return (
    <Container
      variants={containerVariant}
      initial="start"
      animate="end"
      css={css}
    >
      <Dots
        variants={dotsVariants}
        transition={loadingTransition}
        dotscolor={dotscolor}
        size={size}
      />
      <Dots
        variants={dotsVariants}
        transition={loadingTransition}
        dotscolor={dotscolor}
        size={size}
      />
      <Dots
        variants={dotsVariants}
        transition={loadingTransition}
        dotscolor={dotscolor}
        size={size}
      />
    </Container>
  );
}

Indicator.defaultProps = {
  dotscolor: colors.primaryBlack,
  size: 9,
  css: "",
};

Indicator.propTypes = {
  dotscolor: PropType.string,
  size: PropType.number,
  css: PropType.any,
};
