import React, { useContext, useState, useEffect } from "react";
import {
 Box,
 HStack,
 Text,
 SimpleGrid,
 Img,
 Input,
 List,
 ListItem,
 IconButton,
 UnorderedList,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import {
 AdminIcon,
 AdvertisingIcon,
 CategoriesTagsIcon,
 CityGuidesIcon,
 CollectionIcon,
 DashboardIcon,
 DealIcon,
 LegalIcon,
 ManageAccessIcon,
 ManageDealsIcon,
 ManageReviewsIcon,
 ManageRolesIcon,
 NaviJournalIcon,
 NaviManagementIcon,
 PartnersExpIcon,
 PartnersRegIcon,
 RedeemExpIcon,
 SupportIcon,
 TeamMembersIcon,
 BankIcon,
} from "../../../components/Icon/sidebarIcons";
import { primaryDropdownText, subLinksListItem, subLinksText } from "./styles";
import Dropdown from "../../../assets/images/svg/dropdown2.svg";
import { colors } from "../../../assets/styles/config";
import { AuthContext } from "../../../context/AuthContext";
import { MdOutlineStar } from "react-icons/md";

export const Dropdownsidebar = ({
 name,
 path,
 subId,
 openSubLink,
 setOpenSubLink,
 icon,
}) => {
 const location = useLocation();
 const navigate = useNavigate();

 return (
  <>
   <List
    transform={openSubLink === subId ? "translateY(0)" : "translateY(-50px)"}
    opacity={openSubLink === subId ? "1" : "0"}
    height={openSubLink === subId ? "fit-content" : "0px"}
    transition={"all ease .2s"}
    marginTop={openSubLink === subId && "10px"}
   >
    <ListItem
     padding={"5px 30px"}
     color={location.pathname.includes(path) ? colors.primaryWhite : "#8F8F8F"}
     background={
      path.length > 1 && location.pathname.includes(path)
       ? colors.fadedNavyBlue
       : "transparent"
     }
     pointerEvents={path.length < 2 ? "none" : "auto"}
     {...subLinksListItem}
     onClick={() => {
      setOpenSubLink(subId);
      navigate(path);
      // isMobile && handleOpenMenu();
     }}
     _hover={{ background: colors.fadedNavyBlueHover }}
    >
     <Img src={icon} alt="icon" marginRight="10px" />
     <Text {...subLinksText}>{name}</Text>
    </ListItem>
   </List>
  </>
 );
};

export const PartnerDropdownsidebar = ({
 name,
 path,
 icon,
 handleOpenMenu,
 isMobile,
 isOpen,
 premium,
}) => {
 const location = useLocation();
 const navigate = useNavigate();
 const { partner } = useContext(AuthContext);
 console.log("PARTNER JEYI LOG", partner);

 return (
  <>
   <List height="fit-content" transition={"all ease .2s"} marginTop="10px">
    <ListItem
     alignItems="center"
     padding={"5px 30px"}
     color={location.pathname.includes(path) ? colors.primaryWhite : "#8F8F8F"}
     background={
      path.length > 1 && location.pathname.includes(path)
       ? colors.fadedNavyBlue
       : "transparent"
     }
     pointerEvents={path.length < 2 ? "none" : "auto"}
     {...subLinksListItem}
     onClick={() => {
      isOpen && handleOpenMenu();
      if (!partner.subscribed && premium) return;
      else {
       navigate(path);
      }
     }}
     _hover={{ background: colors.fadedNavyBlueHover }}
    >
     <Img src={icon} alt="icon" marginRight="10px" />
     <Text {...subLinksText} marginRight={2}>
      {name}
     </Text>
     {!partner.subscribed && premium ? (
      <span style={{ fontSize: 10, color: "#fad948", letterSpacing: "0.05em" }}>
       ⭐️ Subscribe
      </span>
     ) : (
      ""
     )}
    </ListItem>
   </List>
  </>
 );
};

const SideButtons = ({
 handleOpenMenu,
 isMobile,
 openLinks,
 openSubLink,
 setOpenSubLink,
 access_point,
}) => {
 const home = [
  "dashboard",
  "navigator",
  "collections",
  "journal",
  "advertising",
  "support",
 ];

 const administration = ["teamMembers", "roles"];
 const partnerManagement = [
  "partnerReg",
  "partnerBank",
  "access",
  "partnerExp",
  "redeemExp",
  "manageDeals",
  "manageReviews",
 ];
 const systemConfig = ["cityGuides", "categoriesTags", "legalDoc"];

 return (
  <>
   {access_point?.some((r) => home.includes(r)) && (
    <ListItem
     color="#fff"
     _hover={{ opacity: "0.9" }}
     textTransform="capitalize"
     justifyContent="flex-start"
     // padding='10px'
     width="100%"
     display="flex"
     fontSize="20px"
     fontWeight="400"
     lineHeight="140%"
     cursor="pointer"
     flexDir="column"
     marginBottom="20px"
     onClick={() => {
      isMobile && handleOpenMenu();
     }}
    >
     <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => openLinks(1)}
      padding={"5px 30px"}
     >
      <Box display="flex" flexDirection="row" alignItems="center">
       <Text {...primaryDropdownText}>home</Text>
      </Box>

      <Img
       src={Dropdown}
       alt="drop"
       style={{ width: "14px" }}
       transform={openSubLink === 1 && "rotate(90deg)"}
       transition="all .5s"
      />
     </Box>
     {openSubLink === 1 && (
      <>
       {access_point?.includes("dashboard") && (
        <Dropdownsidebar
         name="dashboard"
         path="/admin/dashboard"
         subId={1}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={DashboardIcon}
        />
       )}
       {access_point?.includes("navigator") && (
        <Dropdownsidebar
         name="navigator management"
         path="/admin/navigator-management"
         subId={1}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={NaviManagementIcon}
        />
       )}
       {access_point?.includes("collections") && (
        <Dropdownsidebar
         name="collections"
         path="/admin/collection"
         subId={1}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={CollectionIcon}
        />
       )}
       {access_point?.includes("journal") && (
        <Dropdownsidebar
         name="navi journal"
         path="/"
         subId={1}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={NaviJournalIcon}
        />
       )}
       {access_point?.includes("advertising") && (
        <Dropdownsidebar
         name="advertising"
         path="/"
         subId={1}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={AdvertisingIcon}
        />
       )}
       {access_point?.includes("support") && (
        <Dropdownsidebar
         name="support"
         path="/admin/support"
         subId={1}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={SupportIcon}
        />
       )}
      </>
     )}
    </ListItem>
   )}
   {access_point?.some((r) => administration.includes(r)) && (
    <ListItem
     color="#fff"
     _hover={{ opacity: "0.9" }}
     textTransform="capitalize"
     justifyContent="flex-start"
     // padding='10px'
     width="100%"
     display="flex"
     fontSize="20px"
     fontWeight="400"
     lineHeight="140%"
     cursor="pointer"
     flexDir="column"
     marginBottom="20px"
     onClick={() => {
      isMobile && handleOpenMenu();
     }}
    >
     <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => openLinks(2)}
      padding={"5px 30px"}
     >
      <Box display="flex" flexDirection="row" alignItems="center">
       <Text {...primaryDropdownText}>Administration</Text>
      </Box>

      <Img
       src={Dropdown}
       alt="drop"
       style={{ width: "14px" }}
       transform={openSubLink === 2 && "rotate(90deg)"}
       transition="all .5s"
      />
     </Box>
     {openSubLink === 2 && (
      <>
       {access_point?.includes("teamMembers") && (
        <Dropdownsidebar
         name="team members"
         path="/admin/administrator"
         subId={2}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={TeamMembersIcon}
        />
       )}
       {access_point?.includes("roles") && (
        <Dropdownsidebar
         name="manage roles"
         path="/admin/roles"
         subId={2}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={ManageRolesIcon}
        />
       )}
      </>
     )}
    </ListItem>
   )}
   {access_point?.some((r) => partnerManagement.includes(r)) && (
    <ListItem
     color="#fff"
     _hover={{ opacity: "0.9" }}
     textTransform="capitalize"
     justifyContent="flex-start"
     // padding='10px'
     width="100%"
     display="flex"
     fontSize="20px"
     fontWeight="400"
     lineHeight="140%"
     cursor="pointer"
     flexDir="column"
     marginBottom="20px"
     onClick={() => {
      isMobile && handleOpenMenu();
     }}
    >
     <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => openLinks(3)}
      padding={"5px 30px"}
     >
      <Box display="flex" flexDirection="row" alignItems="center">
       <Text {...primaryDropdownText}>partner management</Text>
      </Box>

      <Img
       src={Dropdown}
       alt="drop"
       style={{ width: "14px" }}
       transform={openSubLink === 3 && "rotate(90deg)"}
       transition="all .5s"
      />
     </Box>
     {openSubLink === 3 && (
      <>
       {access_point?.includes("partnerReg") && (
        <Dropdownsidebar
         name="partners' registration"
         path="/admin/partner-registration"
         subId={3}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={PartnersRegIcon}
        />
       )}
       {access_point?.includes("partnerBank") && (
        <Dropdownsidebar
         name="partners settlement Bank"
         path="/admin/bank"
         subId={3}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={BankIcon}
        />
       )}
       {access_point?.includes("partnerExp") && (
        <Dropdownsidebar
         name="partners' experience"
         path="/admin/partner-experience"
         subId={3}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={PartnersExpIcon}
        />
       )}
       {access_point?.includes("redeemExp") && (
        <Dropdownsidebar
         name="redeem experiences"
         path="/admin/redeem-experiences"
         subId={3}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={RedeemExpIcon}
        />
       )}
       {access_point?.includes("manageDeals") && (
        <Dropdownsidebar
         name="manage deals"
         path="/admin/manage-deals"
         subId={3}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={ManageDealsIcon}
        />
       )}
       {access_point?.includes("manageReviews") && (
        <Dropdownsidebar
         name="manage reviews"
         path="/"
         subId={3}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={ManageReviewsIcon}
        />
       )}
      </>
     )}
    </ListItem>
   )}
   {access_point?.some((r) => systemConfig.includes(r)) && (
    <ListItem
     color="#fff"
     _hover={{ opacity: "0.9" }}
     textTransform="capitalize"
     justifyContent="flex-start"
     // padding='10px'
     width="100%"
     display="flex"
     fontSize="20px"
     fontWeight="400"
     lineHeight="140%"
     cursor="pointer"
     flexDir="column"
     marginBottom="20px"
     onClick={() => {
      isMobile && handleOpenMenu();
     }}
    >
     <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => openLinks(4)}
      padding={"5px 30px"}
     >
      <Box display="flex" flexDirection="row" alignItems="center">
       <Text {...primaryDropdownText}>system configuration</Text>
      </Box>

      <Img
       src={Dropdown}
       alt="drop"
       style={{ width: "14px" }}
       transform={openSubLink === 4 && "rotate(90deg)"}
       transition="all .5s"
      />
     </Box>
     {openSubLink === 4 && (
      <>
       {access_point?.includes("categoriesTags") && (
        <Dropdownsidebar
         name="Categories"
         path="/system-config/categories"
         subId={4}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={CategoriesTagsIcon}
        />
       )}
       {access_point?.includes("categoriesTags") && (
        <Dropdownsidebar
         name="Tags"
         path="/system-config/tags"
         subId={4}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={CategoriesTagsIcon}
        />
       )}
       {/* {access_point?.includes("cityGuides") && (
                <Dropdownsidebar
                  name="Cities and guides"
                  path="/system-config/roles"
                  subId={4}
                  openSubLink={openSubLink}
                  setOpenSubLink={setOpenSubLink}
                  icon={CityGuidesIcon}
                />
              )} */}
       {access_point?.includes("legalDoc") && (
        <Dropdownsidebar
         name="Legal"
         path="/system-config/legal"
         subId={4}
         openSubLink={openSubLink}
         setOpenSubLink={setOpenSubLink}
         icon={LegalIcon}
        />
       )}
      </>
     )}
    </ListItem>
   )}
  </>
 );
};

export default SideButtons;
