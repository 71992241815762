import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { colors, fontWeight } from '../../../../assets/styles/config';

export const Container = styled.section`
  background: white;
  padding: 2rem;
  border-radius: 10px;
`;

export const SearchLabel = styled.label`
  position: relative;

  .icon {
    position: absolute;
    left: 15px;
  }

  @media screen and (min-width: 768px) {
    .icon {
      top: 0.2rem;
    }
  }
`;

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6c6b6b;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 18px;
  }

  ${(props) => props.css || ''}
`;

export const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6c6b6b;
  padding: 20px 0;
  border-bottom: 1px lightgrey solid;
  ${(props) => props.css || ''}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const EditIcon = styled.button`
  padding: 0.15rem;
  background: #d0f1d7;
  border-radius: 6px;
  color: #1aab65;
  font-size: 18px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
    padding: 0.25rem;
  }

  @media screen and (min-width: 1080px) {
    font-size: 27px;
    padding: 0.35rem;
  }
`;

export const DeleteIcon = styled(motion.button)`
  padding: 0.15rem;
  font-size: 18px;
  background: #f2b8b5;
  border-radius: 6px;
  color: red;

  @media screen and (min-width: 768px) {
    font-size: 22px;
    padding: 0.25rem;
  }

  @media screen and (min-width: 1080px) {
    font-size: 27px;
    padding: 0.35rem;
  }
`;

export const SwitchIcon = styled(motion.button)`
  padding: 0.5rem 0.35rem;
  //   font-size: 27px;
  background: #ebf7ff;
  border-radius: 6px;
  //   color: red;
`;

export const CategoriesTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchInput = styled.input`
  background: ${colors.skyBlue};
  border-radius: 10px;
  width: 100%;
  height: 38px;
  font-size: 14px;
  padding: 7px 12px 7px 40px;
  color: ${colors.brown};

  @media screen and (min-width: 768px) {
    // font-size: 15.5px;
    border-radius: 20px;
  }

  @media screen and (min-width: 835px) {
    width: 300px;
  }

  @media screen and (min-width: 1080px) {
    // font-size: 17px;
    height: 42px;
  }

  &:focus {
    outline: 1px solid ${colors.blue};
  }

  &::placeholder {
    color: ${colors.placeholderBrown};
  }

  &:-ms-input-placeholder {
    color: ${colors.placeholderBrown};
  }

  &::-ms-input-placeholder {
    color: ${colors.placeholderBrown};
  }
`;

export const Card = styled(motion.div)`
  background: white;
  border-radius: 6px;
  width: 90%;

  @media screen and (min-width: 470px) {
    width: 80%;
  }

  @media screen and (min-width: 768px) {
    width: 70%;
  }

  @media screen and (min-width: 1080px) {
    width: 40%;
  }

  ${(props) => props.css || ''}
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.darkGrey};
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  padding: 40px;
  border-bottom: 1px solid lightgrey;

  @media screen and (min-width: 768px) {
    font-sie: 21px;
  }

  @media screen and (min-width: 1080px) {
    font-sie: 24px;
  }
`;

export const CardInBetween = styled.div`
  font-sie: 14px;
  display: flex;
  justify-content: center;
  padding-block: 2rem;

  @media screen and (min-width: 768px) {
    font-sie: 15px;
  }

  @media screen and (min-width: 1080px) {
    font-sie: 16px;
  }
`;
