import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 HStack,
 Img,
 Text,
 List,
 ListItem,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Switch,
 InputRightElement,
 InputGroup,
 Image,
 Input,
 InputLeftElement,
 Button,
} from "@chakra-ui/react";
import { searchInputStyle } from "../../../Admin/Dashboard/styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { css } from "styled-components";
import { AddIcon } from "@chakra-ui/icons";
import {
 Button as CustomButton,
 EmptyState,
 MobileAddBtn,
} from "../../../../components";
import { colors } from "../../../../assets/styles/config";
import { AuthContext } from "../../../../context/AuthContext";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import {
 LoadingIndicator,
 Modal,
 Icon as CloseIconWrapper,
} from "../../../../components";
import { Card, CloseBtn, Container } from "../styles";
import ArrowRight from "../../../../assets/images/arrowleft2.svg";
import { db } from "../../../../firebase";
import OptionIcon from "../../../../assets/images/option.svg";
import Warning from "../../../../assets/images/warning-icon.svg";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { async } from "@firebase/util";
import { sendPartnerPushNotification } from "../../../../api/user";

const PartnerRole = () => {
 const { user, addAdmin, updateAdministrator, deleteAdministrator } =
  useContext(AuthContext);
 const navigate = useNavigate();
 const toggle = useRef(null);
 const uid = user?.partnerUid ?? user?.uid;
 const rolesDB = ref(db, `users/partner/${uid}/roles`);
 const [loading, setLoading] = useState(false);
 const [loadingMembers, setLoadingMembers] = useState(false);
 const tableRef = useRef(null);
 const [visible, setVisible] = useState(false);
 const containerRef = useRef(null);
 const [isChecked, setisChecked] = useState(true);
 const [checkSelect, setCheckSelect] = useState("");
 const [modalType, setModalType] = useState("");
 const [search, setSearch] = useState("");
 const [selected, setSelected] = useState(null);
 const [roles, setRoles] = useState(null);
 const [selectedRole, setSelectedRole] = useState(null);
 const [team, setTeam] = useState([]);

 const filterObject = (obj, role) =>
  Object.keys(obj).reduce(
   (acc, val) =>
    obj[val]["role"] === role
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 const fetchMembers = async (role) => {
  setLoadingMembers(true);
  const db = await get(
   child(ref(getDatabase()), `users/partner/${uid}/members`)
  )
   .then((snapshot) => {
    setLoadingMembers(false);
    if (snapshot.exists()) {
     const myObj = filterObject(snapshot.val(), role);

     setTeam(
      Object.keys(myObj).map((key) => {
       return myObj[key];
      })
     );
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoadingMembers(false);
   });
  return db;
 };

 const fetchTeamMates = async (role) => {
  return await get(
   child(ref(getDatabase()), `users/partner/${uid}/members`)
  ).then((snapshot) => {
   if (snapshot.exists()) {
    const myObj = filterObject(snapshot.val(), role);

    return Object.keys(myObj).map((key) => {
     return myObj[key];
    })?.length;
   }
  });
 };

 const handleClickOutside = (event) => {
  //console.log("toggle===", toggle.current, event.target);
  // if (!toggle.current.contains(event.target)) {
  //   //   console.log("event===", event);
  //   // setSelected(null);
  // }
 };

 const searchFilter = (text) => {
  setSearch(text);
 };

 const handleDelete = async () => {
  toast.loading("loading");
  const db = getDatabase();
  const updates = {};
  updates[`users/partner/${uid}/roles`] = roles?.filter(
   (item) => item.role !== selectedRole
  );

  await update(ref(db), updates)
   .then(async () => {
    toast.dismiss();
    toast.success("Successfully deleted experience");

    if (user) {
     let name = user?.firstName
      ? `${user?.firstName} ${user?.lastName}`
      : user?.name;
     await sendPartnerPushNotification({
      title: "partner",
      body: `${name} deleted ${selectedRole?.toLowerCase()} role`,
      data: {
       // ...user,
       uid: user?.partnerUid ?? user?.uid,
       route: "/partner/roles",
       name: name,
       photoURL: user?.partnerPhotoURL ?? user?.photoURL,
       push_time: Date.now(),
      },
     });
    }
    setVisible(!visible);
    setModalType("");
    setSelectedRole(null);
   })
   .catch((error) => {
    toast.dismiss();
    toast.error(err);
   })
   .finally(() => {
    setTimeout(() => {
     toast.dismiss();
    }, 3000);
   });
 };

 const mapRevers = (reverse) => {
  let reversed = reverse.map(
   (num, index, reverse) => reverse[reverse.length - 1 - index]
  );
  return reversed;
 };

 useEffect(() => {
  document.addEventListener("click", handleClickOutside);

  return () => {
   document.removeEventListener("click", handleClickOutside);
  };
 }, []);

 useEffect(() => {
  setLoading(true);
  const unsubscribe = onValue(rolesDB, async (snapshot) => {
   const dbRoles = snapshot.val();
   for (let i in dbRoles) {
    const member = await fetchTeamMates(dbRoles[i]?.role);
    dbRoles[i]["member"] = member;
    setRoles(dbRoles);
   }
   setRoles(dbRoles);
   // if (dbRoles?.length > 0) {
   //   setLoading(false);
   // }
   setLoading(false);
  });
  return () => {
   unsubscribe();
  };
 }, []);

 return (
  <Box
   height="100%"
   minHeight="calc(100vh - 85px)"
   padding={{ base: "0px 20px", md: "20px" }}
   background="#F5F8FA"
  >
   <MobileAddBtn
    onClick={() => {
     navigate("/partner/roles/add");
    }}
   />

   <Box
    background={{ md: "#FFFFFF" }}
    borderRadius="6px"
    // mt="30px"
    minHeight={{ md: "80vh" }}
    padding={{ md: "30px" }}
   >
    <Flex
     alignItems="flex-start"
     flexDir={{ base: "column", md: "row" }}
     justifyContent="space-between"
     display={{ base: "none", md: "flex" }}
    >
     <InputGroup borderRadius="28px" width="60%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>

     <Box display="flex" alignItems="center">
      <CustomButton
       animateOnTap
       animateOnHover
       buttonColor="#632EFF"
       css={css`
        width: fit-content;
        padding: 0.5rem 2rem;
        height: auto;
        font-weight: 500;
        font-family: "Creato Display";
        display: flex;
        gap: 15px;

        @media screen and (max-width: 768px) {
         border-radius: 10px;
        }
       `}
       onClick={() => {
        navigate("/partner/roles/add");
        // setVisible(!visible);
        // setModalType("add");
       }}
      >
       <AddIcon h="16px" w="16px" /> Add new role
      </CustomButton>
     </Box>
    </Flex>
    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt={{ md: "30px" }}>
      {roles?.length > 0 ? (
       <>
        <TableContainer
         border="1px solid #E2E8F0"
         borderRadius="16px 16px 0px 0px"
         overflowX="initial"
         overflowY="visible"
         display={{ base: "none", md: "block" }}
        >
         <Table variant="simple" ref={tableRef}>
          <Thead>
           <Tr>
            <Th
             fontFamily="Satoshi"
             color="#252046"
             fontSize={{ base: "12px", md: "14px", lg: "16px" }}
             lineHeight="125%"
             fontWeight="700"
             padding="14px 20px"
             textTransform="capitalize"
            >
             Role Name
            </Th>
            <Th
             fontFamily="Satoshi"
             color="#252046"
             fontSize={{ base: "12px", md: "14px", lg: "16px" }}
             lineHeight="125%"
             fontWeight="700"
             padding="14px 20px"
             textTransform="capitalize"
            >
             Type
            </Th>

            <Th
             fontFamily="Satoshi"
             color="#252046"
             fontSize={{ base: "12px", md: "14px", lg: "16px" }}
             lineHeight="125%"
             fontWeight="700"
             padding="14px 20px"
             textTransform="capitalize"
            >
             Created by
            </Th>
            <Th
             fontFamily="Satoshi"
             color="#252046"
             fontSize={{ base: "12px", md: "14px", lg: "16px" }}
             lineHeight="125%"
             fontWeight="700"
             padding="14px 20px"
             textTransform="capitalize"
            >
             Team mates
            </Th>

            <Th
             fontFamily="Satoshi"
             color="#252046"
             fontSize={{ base: "12px", md: "14px", lg: "16px" }}
             lineHeight="125%"
             fontWeight="700"
             padding="14px 20px"
            ></Th>
           </Tr>
          </Thead>
          <Tbody>
           {roles &&
            mapRevers(roles)?.map((item, index) => (
             <Tr key={index} overflowX="initial">
              <Td
               padding="14px 20px"
               fontSize={{ base: "14px" }}
               lineHeight="125%"
               fontWeight="500"
               fontFamily="Satoshi"
               color="#334155"
               cursor="pointer"
              >
               {item?.role}
              </Td>
              <Td
               padding="14px 20px"
               fontSize={{ base: "14px" }}
               lineHeight="125%"
               fontWeight="500"
               fontFamily="Satoshi"
               color="#334155"
              >
               {item?.type}
              </Td>

              <Td
               padding="14px 20px"
               fontSize={{ base: "14px" }}
               lineHeight="125%"
               fontWeight="500"
               fontFamily="Satoshi"
               color="#334155"
              >
               {item?.createdBy}
              </Td>
              <Td
               padding="14px 20px"
               fontSize={{ base: "14px" }}
               lineHeight="125%"
               fontWeight="500"
               fontFamily="Satoshi"
               color="#334155"
              >
               {item?.member}
              </Td>

              <Td
               padding="14px 20px"
               display="flex"
               alignItems="center"
               gap="20px"
               position="relative"
               ref={toggle}
              >
               <Box
                cursor="pointer"
                onClick={() => {
                 setSelected(selected === index ? null : index);
                }}
               >
                <Image src={OptionIcon} />
               </Box>
               {selected === index && (
                <List
                 position="absolute"
                 top="3rem"
                 right="50px"
                 padding="10px 20px"
                 background="#FFFFFF"
                 boxShadow="0px 4px 24px #D4DEEC"
                 border="12px"
                 zIndex="20"
                 cursor="pointer"
                 borderRadius="12px"
                 width="200px"
                >
                 <ListItem
                  borderBottom="1px solid #E2E8F0"
                  padding="10px 0px"
                  onClick={() => navigate(`/partner/roles/edit/${item.role}`)}
                 >
                  Edit Roles
                 </ListItem>
                 <ListItem
                  borderBottom="1px solid #E2E8F0"
                  padding="10px 0px"
                  onClick={() => {
                   setVisible(!visible);
                   setModalType("view");
                   setSelected(null);
                   fetchMembers(item.role);
                  }}
                 >
                  View Team Mates
                 </ListItem>
                 <ListItem
                  //   borderBottom="1px solid #E2E8F0"
                  padding="10px 0px"
                  onClick={() => {
                   setVisible(!visible);
                   setModalType("delete");
                   setSelected(null);
                   setSelectedRole(item.role);
                  }}
                 >
                  Delete Role
                 </ListItem>
                </List>
               )}
              </Td>
             </Tr>
            ))}
          </Tbody>
         </Table>
        </TableContainer>
        <Box display={{ base: "block", md: "none" }}>
         <Box
          // overflowX={"auto"}
          background="#fff"
          width="100%"
          border="1px solid #e2e8f0"
          borderRadius="16px"
          marginTop="20px"
          padding="0px 10px"
         >
          {roles &&
           mapRevers(roles)?.map((item, index) => (
            <Box
             key={index}
             display="flex"
             alignItems="center"
             justifyContent="space-between"
             padding="15px 10px"
             borderBottom={
              mapRevers(roles)?.length - 1 !== index && "1px solid #E2E8F0"
             }
             cursor="pointer"
            >
             <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Text
               color={colors.darkBlue}
               fontWeight="500"
               lineHeight="20px"
               marginBottom="10px"
               fontSize="18px"
              >
               {item?.role}
              </Text>
              <Box
               display="flex"
               alignItems="center"
               gap="10px"
               flexWrap="wrap"
              >
               <Text
                color={colors.darkBlue}
                fontWeight="400"
                lineHeight="15px"
                fontSize="14px"
               >
                {item?.type}
               </Text>{" "}
               |{" "}
               <Text
                color={colors.darkBlue}
                fontWeight="400"
                lineHeight="15px"
                fontSize="14px"
               >
                {item?.member} Team mates
               </Text>
              </Box>
             </Box>

             <Box position="relative">
              <Box
               cursor="pointer"
               onClick={() => {
                setSelected(selected === index ? null : index);
               }}
              >
               <Image src={OptionIcon} />
              </Box>
              {selected === index && (
               <List
                position="absolute"
                top="0"
                right="20px"
                padding="10px 20px"
                background="#FFFFFF"
                boxShadow="0px 4px 24px #D4DEEC"
                border="12px"
                zIndex="20"
                cursor="pointer"
                borderRadius="12px"
                width="200px"
               >
                <ListItem
                 borderBottom="1px solid #E2E8F0"
                 padding="10px 0px"
                 onClick={() => navigate(`/partner/roles/edit/${item.role}`)}
                >
                 Edit Roles
                </ListItem>
                <ListItem
                 borderBottom="1px solid #E2E8F0"
                 padding="10px 0px"
                 onClick={() => {
                  setVisible(!visible);
                  setModalType("view");
                  setSelected(null);
                  fetchMembers(item.role);
                 }}
                >
                 View Team Mates
                </ListItem>
                <ListItem
                 //   borderBottom="1px solid #E2E8F0"
                 padding="10px 0px"
                 onClick={() => {
                  setVisible(!visible);
                  setModalType("delete");
                  setSelected(null);
                  setSelectedRole(item.role);
                 }}
                >
                 Delete Role
                </ListItem>
               </List>
              )}
             </Box>
             {/* <Image src={ArrowRight} alt="arrow-left" /> */}
            </Box>
           ))}
         </Box>
        </Box>
       </>
      ) : (
       <EmptyState
        text={
         "No roles have been added. Click the add button to add new roles."
        }
       />
      )}
     </Box>
    )}
   </Box>
   <Modal
    {...{
     visible,
     setVisible,
     childrenContainerRef: containerRef,
    }}
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     overflowY: "auto",
    }}
    overlayLayer
    overlayLayerStyle={{
     backgroundColor: colors.black,
     opacity: 0.7,
    }}
   >
    <Container
     ref={containerRef}
     animate={{ scale: 1 }}
     initial={{ scale: 0.9 }}
     //   style={{ marginTop: modalType === "add" && "300px" }}
    >
     {modalType === "view" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "456px", height: "100%", padding: "2rem" }}
      >
       <Box
        // borderBottom="1px solid #B8B8B8"

        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
       >
        <Text
         fontFamily="Satoshi"
         color="#0F0931"
         fontSize={{ base: "14px", md: "20px", lg: "32px" }}
         lineHeight="125%"
         fontWeight="700"
        >
         Team Mates
        </Text>
        <Box style={{ cursor: "pointer" }} onClick={() => setVisible(false)}>
         <AiOutlineCloseCircle fontSize="30px" />
        </Box>
       </Box>
       <Text
        fontFamily="Satoshi"
        color="#252046"
        fontSize="16px"
        fontWeight="700"
        marginY="20px"
       >
        {team?.length} Team Mates
       </Text>
       <List>
        {team?.map((item, index) => (
         <ListItem
          key={index}
          borderBottom="1px solid #E2E8F0"
          padding="10px 0px"
         >
          {item?.name}
         </ListItem>
        ))}
       </List>
      </Card>
     )}
     {modalType === "delete" && (
      <Card
       animate={{ scale: 1 }}
       initial={{ scale: 0.9 }}
       style={{ width: "358px", height: "100%", padding: "2rem" }}
      >
       <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        //  mt="40px"
        width="100%"
       >
        <Image src={Warning} />
        <Text
         fontFamily="Creato Display, Roboto, sans-serif;"
         color="#0F0931"
         fontSize={{ base: "14px", md: "24px" }}
         lineHeight="134%"
         fontWeight="700"
         marginTop="30px"
         textAlign="center"
        >
         Delete Role?
        </Text>
        <Text
         fontFamily="Satoshi"
         color="#878498"
         fontWeight="500"
         marginTop="20px"
         textAlign="center"
        >
         Are you sure you want to delete this role?
        </Text>
        <Box
         mt="30px"
         display="flex"
         alignItems="center"
         flexDirection="column"
         gap="20px"
         width="100%"
        >
         <Button
          color="#ffffff"
          bg={colors.primaryPurple}
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          onClick={handleDelete}
         >
          Yes, Delete
         </Button>
         <Button
          border="1px solid #252046"
          bg="transparent"
          borderRadius="54px"
          fontWeight={500}
          fontSize={18}
          p="25px 20px"
          width="100%"
          type="submit"
          onClick={() => {
           setVisible(!visible);
           setModalType("");
           setSelectedRole(null);
          }}
         >
          Close
         </Button>
        </Box>
       </Box>
      </Card>
     )}
    </Container>
   </Modal>
  </Box>
 );
};

export default PartnerRole;
