import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '../../assets/styles/config';

export const Icon = styled(motion.img)`
  width: ${(props) => `${props?.size}px` || '20px'};
  height: ${(props) => `${props?.size}px` || '20px'};
  opacity: ${(props) => props?.opacity || 1};
  user-select: none;
  ${(props) => props?.css || ''}
`;
export const IconContainer = styled(motion.div)`
  width: ${(props) =>
    `${props?.withContainer ? props?.containerSize + 'px' || '40px' : ''}`};
  height: ${(props) => `${props?.containerSize}px` || '40px'};
  background-color: ${(props) => props?.backgroundColor || 'transparent'};
  ${(props) =>
    props?.border
      ? `border:2px solid ${props?.borderColor || colors.primaryBlack}`
      : ''};
  border-radius: ${(props) => props?.containerSize || '40px'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props?.pointer ? 'pointer' : 'default')};
  user-select: none;
  ${(props) => props?.css || ''}
`;
