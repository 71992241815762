import { css } from "@emotion/react";
import styled from "styled-components";
import { colors, fontWeight } from "../../assets/styles/config";

export const Container = styled.footer``;

export const Wrapper = styled.div`
  color: ${colors.primaryWhite};
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(8, 1fr);
    width: 100%;
    height: 660px;
  }
`;

export const Contact = styled.div`
  background: ${colors.primaryPurple};
  // border-radius: 0px 35px 35px 0px;
  grid-column: 1 / span 3;
  grid-row: 1 / span 4;
  display: grid;
  justify-items: center;
  align-content: center;
  padding: 2.5rem 0;
  z-index: 5;

  @media (min-width: 768px) {
    border-radius: 0px 35px 35px 0px;
    padding: 0;
  }

  @media (min-width: 1880px) {
    padding-right: 20%;
    padding-left: 20%;
  }
`;

export const Media = styled.div`
  width: 100%;
  background: ${colors.darkBlue};
  grid-column: 1 / span 4;
  grid-row: 3 / span 7;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-bottom: 4rem;

  @media (max-width: 767px) {
    order: 3;
  }

  @media (min-width: 1880px) {
    padding-right: 20%;
    padding-left: 20%;
  }
`;

export const Navigation = styled.div`
  display: grid;
  background: ${colors.darkBlue};
  justify-items: center;
  align-content: space-between;
  grid-column: 4;
  grid-row: 4;

  p {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 767px) {
    justify-items: start;
    order: 2;
    padding: 2rem;
  }
`;

export const FooterNavLinks = styled.div`
  display: flex;
  gap: 2rem;

  @media (min-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;

export const Heading = styled.p`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: ${fontWeight.medium};
  font-size: 28.5px;

  @media (min-width: 768px) {
    font-size: 35.5px;
    line-height: 57px;
  }

  @media (min-width: 1080px) {
    font-size: 58px;
    line-height: 87px;
  }
`;

export const TouchTitle = styled.p`
  font-weight: ${fontWeight.medium};
  font-size: 20px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 36px;
  }

  @media (min-width: 1080px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ContactInformation = styled.div`
  display: flex;
  align-self: center;
  //width: 95%;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;

  a:hover {
    opacity: 0.8;
    scale: 0.9;
  }

  & .location,
  & a {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
    gap: 0.25rem;
    transition: ease-in-out 0.3s;
    letter-spacing: 0.075em;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    @media (min-width: 1080px) {
      font-size: 20px;
    }
  }

  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
  }

  @media (min-width: 1080px) {
    // width: 85%;
  }
`;

export const SocialIcons = styled.div`
  display: flex;

  a:hover {
    opacity: 0.7;
    scale: 1.1;
  }

  @media (min-width: 768px) {
    gap: 1rem;
  }
  @media (min-width: 1080px) {
    gap: 2rem;
  }
`;

export const MediaContainer = styled.div`
  width: 85%;
  display: grid;
  align-items: center;
  gap: 2rem;
`;

export const MediaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 3px solid ${colors.primaryWhite};

  @media (max-width: 768px) {
    margin-top: 30px;

    img {
      width: 70%;
    }

    img.logo {
      width: 40%;
    }
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    img.logo {
      width: 30%;
    }
  }
`;

export const CopyrightText = styled.p`
  align-self: start;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
