import React, { useEffect, useMemo, useRef, useState } from "react";
import { db } from "../../../../firebase";
import { onValue, ref } from "firebase/database";
import { Button, Icon, Loader, Modal, Toast } from "../../../../components";
import {
  Container,
  SearchInput,
  SearchLabel,
  CategoryHeader,
  CategoriesTable,
  JustifyBetween,
  ActionsWrapper,
  EditIcon,
  DeleteIcon,
  SwitchIcon,
  Card,
  CardHeader,
  CardInBetween,
} from "./styles";
import {
  CloseButton,
  Flex,
  IconButton,
  Input,
  Switch,
  Text,
} from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button as CHButton,
} from "@chakra-ui/react";
import { css } from "styled-components";
import { MdEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { colors } from "../../../../assets/styles/config";
import { set } from "firebase/database";
import { AnimatePresence } from "framer-motion";
import { ChevronDownIcon } from "@chakra-ui/icons";

// CONSTANTS
const ACTIONS = {
  ADD_ROLE: "ADD ROLE",
  EDIT_ROLE: "EDIT ROLE",
  DELETE_ROLE: "DELETE ROLE?",
};
const DEFAULT_STATE = { status: false, action: null };
const TOAST_STATE = {
  state: false,
  text: "",
  bgColor: "lightgreen",
  textColor: colors.primaryWhite,
};
const roles = ["Administrator", "Writer", "Manager"];
const rolesDB = ref(db, "cityExperience/roles");

const Roles = () => {
  const [filteredRoleList, setFilteredRoleList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [toast, setToast] = React.useState(TOAST_STATE);
  const [state, setState] = useState(DEFAULT_STATE);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [readOnly, setReadOnly] = useState("Administrator");
  const [viewOnly, setViewOnly] = useState("Administrator");
  const [chosenRole, setChosenRole] = useState("");
  const [value, setValue] = React.useState("");
  const containerRef = useRef(null);
  const cardHeaderRef = useRef(null);

  //   FUNCTIONS
  const handleChange = (event) => setValue(event.target.value);

  const handleSearchInput = (searchValue) => {
    const hasSearchValue = roleList.filter((item) =>
      item?.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredRoleList(hasSearchValue);
  };

  const reset = () => {
    setVisible(false);
    setState(DEFAULT_STATE);
    setChosenRole("");
    setValue("");
  };

  const setActionType = (type, role) => {
    setState({ ...state, action: type });
    setVisible(true);
    setChosenRole(role);
  };

  const deleteRole = (item) => {
    const newList = roleList.filter(
      (role) => role?.name?.toLowerCase() !== item.toLowerCase()
    );

    setRoleList(newList);
    reset();
    setToast({
      ...toast,
      state: true,
      text: "Item Deleted Successfully",
      bgColor: "lightgreen",
      textColor: colors.primaryWhite,
    });
    updateFirebaseDb(newList);
  };

  const createRole = (item) => {
    const itemExists = roleList.find(
      (role) => role?.name?.toLowerCase() === item.toLowerCase()
    );
    if (!item) {
      setToast({
        ...toast,
        state: true,
        text: "Input is empty",
        bgColor: colors.pastelRed,
        textColor: colors.primaryWhite,
      });
      return;
    }
    if (itemExists) {
      setToast({
        ...toast,
        state: true,
        text: "Role already exists",
        bgColor: colors.pastelRed,
        textColor: colors.primaryWhite,
      });
      return;
    }
    const newItem =
      item.slice(0, 1).toUpperCase() + item.slice(1, item.length).toLowerCase();
    const newRole = { name: newItem, viewOnly: viewOnly, readOnly: readOnly };
    const newList = [...roleList, newRole];
    reset();
    setRoleList(newList);
    setToast({
      ...toast,
      state: true,
      text: "Item Added Successfully",
      bgColor: "lightgreen",
      textColor: colors.primaryWhite,
    });
    updateFirebaseDb(newList);
  };

  const updateRole = () => {
    if (!value) {
      setToast({
        ...toast,
        state: true,
        text: "Input is empty",
        bgColor: colors.pastelRed,
        textColor: colors.primaryWhite,
      });
      return;
    }
    const newItem =
      value.slice(0, 1).toUpperCase() +
      value.slice(1, value.length).toLowerCase();
    const newRole = {
      name: newItem,
      viewOnly: viewOnly,
      readOnly: readOnly,
    };
    const newList = roleList.map((item) =>
      item?.name?.toLowerCase() === chosenRole?.name?.toLowerCase()
        ? newRole
        : item
    );
    setRoleList(newList);
    reset();
    setToast({
      ...toast,
      state: true,
      text: "Item Updated Successfully",
      bgColor: "lightgreen",
      textColor: colors.primaryWhite,
    });
    updateFirebaseDb(newList);
  };

  const updateFirebaseDb = (newList) => {
    set(rolesDB, newList);
  };

  //   EFFECTS
  useEffect(() => {
    setLoading(true);
    const unsubscribe = onValue(rolesDB, (snapshot) => {
      const roles = snapshot.val();
      setRoleList(roles);
      if (roles?.length > 0) {
        setLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setFilteredRoleList(roleList);
  }, [roleList]);

  useEffect(() => {
    containerRef?.current?.scroll(0, 0);
  }, [visible]);

  //   JSX
  const renderSearchAndAddRoleArea = useMemo(
    () => (
      <Flex
        justifyContent={"space-between"}
        alignItems={{ md: "center" }}
        flexDirection={{ base: "column-reverse", md: "row" }}
        gap={{ base: "1rem", md: "auto" }}
      >
        <SearchLabel>
          {" "}
          <div className="icon">
            <Icon name="search" />{" "}
          </div>
          <SearchInput
            type="search"
            name="search roles"
            id=""
            placeholder="Search"
            onChange={(e) => handleSearchInput(e.target.value)}
          />
        </SearchLabel>
        <Button
          title="Add Role"
          animateOnTap
          animateOnHover
          css={css`
            width: fit-content;
            padding: 0.5rem 1rem;
            height: auto;

            @media screen and (max-width: 768px) {
              border-radius: 10px;
            }
          `}
          onClick={() => setActionType(ACTIONS.ADD_ROLE, "")}
        />
      </Flex>
    ),
    [roleList, filteredRoleList, handleSearchInput]
  );

  const renderRoles = useMemo(
    () => (
      <CategoriesTable>
        {filteredRoleList?.length > 0 &&
          filteredRoleList?.map((role) => (
            <JustifyBetween key={role.name}>
              <p>{role.name}</p>
              <ActionsWrapper>
                <EditIcon
                  whileTap={{ scale: 0.7 }}
                  whileHover={{ scale: 1.09 }}
                  onClick={() => {
                    setValue(role.name);
                    setReadOnly(role.readOnly);
                    setViewOnly(role.viewOnly);
                    setActionType(ACTIONS.EDIT_ROLE, role);
                  }}
                >
                  <MdEdit />
                </EditIcon>

                {/* <SwitchIcon>
                  <Switch size={{ base: 'sm', lg: 'md' }} isChecked />
                </SwitchIcon> */}
                <DeleteIcon
                  whileTap={{ scale: 0.7 }}
                  whileHover={{ scale: 1.09 }}
                  onClick={() => {
                    setActionType(ACTIONS.DELETE_ROLE, role);
                  }}
                >
                  <IoMdTrash />
                </DeleteIcon>
              </ActionsWrapper>{" "}
            </JustifyBetween>
          ))}
      </CategoriesTable>
    ),
    [loading, filteredRoleList]
  );

  const renderAddEditInput = useMemo(
    () => (
      <Flex
        flexDirection="column"
        alignSelf={"start"}
        gap="10px"
        padding="2rem 3rem"
        // paddingTop='3rem'
      >
        <Text color="#979797">
          Role Name <span style={{ color: "red" }}>*</span>
        </Text>
        <Input
          background={colors.skyBlue}
          borderRadius="20px"
          value={value}
          onChange={handleChange}
          height="3.7em"
          fontSize={"14px"}
          autoFocus
        />
        <Text color="#979797" mt="20px">
          Permissions{" "}
          <span style={{ fontWeight: "thin", fontSize: 13 }}>
            {"(view only)"}
          </span>
        </Text>
        <Menu>
          <MenuButton
            fontWeight={400}
            color="#6C6B6B"
            textAlign={"left"}
            as={CHButton}
            rightIcon={<ChevronDownIcon />}
            fontSize={{ lg: 14 }}
            background={colors.skyBlue}
            borderRadius="20px"
            height="3.7em"
          >
            {viewOnly}
          </MenuButton>
          <MenuList>
            {roles.map((role) => (
              <MenuItem onClick={() => setViewOnly(role)}>{role}</MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Text color="#979797" mt="20px">
          Permissions{" "}
          <span style={{ fontWeight: "thin", fontSize: 13 }}>
            {"(read only)"}
          </span>
        </Text>
        <Menu>
          <MenuButton
            fontWeight={400}
            color="#6C6B6B"
            textAlign={"left"}
            as={CHButton}
            rightIcon={<ChevronDownIcon />}
            fontSize={{ lg: 14 }}
            background={colors.skyBlue}
            borderRadius="20px"
            height="3.7em"
          >
            {readOnly}
          </MenuButton>
          <MenuList>
            {roles.map((role) => (
              <MenuItem onClick={() => setReadOnly(role)}>{role}</MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    ),
    [value, setValue, handleChange, viewOnly, readOnly]
  );

  const renderModal = useMemo(
    () => (
      <Modal
        {...{
          visible,
          setVisible,
          childrenContainerRef: containerRef,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        overlayLayer
        overlayLayerStyle={{
          backgroundColor: colors.black,
          opacity: 0.5,
        }}
      >
        <AnimatePresence>
          <Card
            initial={{
              scale: 0,
              translateX: 350,
              translateY: 350,
            }}
            animate={{ scale: 1, translateX: 0, translateY: 0 }}
            exit={{ opacity: 0, scale: 0, translateX: 350, translateY: 350 }}
            transition={{
              type: "spring",
              stiffness: 160,
              damping: 20,
            }}
            ref={containerRef}
            css={
              state.action !== ACTIONS.DELETE_ROLE
                ? css`
                    overflow-y: scroll;
                    max-height: 80%;
                    &::-webkit-scrollbar {
                      transition: ease 0.2s;
                      width: 0.5rem;
                      background-color: ${colors.skyBlue};
                      border-radius: 0.5rem;
                    }

                    &::-webkit-scrollbar-thumb {
                      background-color: ${colors.faintBlue};
                      border-radius: 0.5rem;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                      background-color: #a6d7f8;
                    }
                  `
                : null
            }
          >
            {/* THE HEADER */}
            <CardHeader ref={cardHeaderRef}>
              {state?.action?.toLowerCase()} <CloseButton onClick={reset} />
            </CardHeader>
            {/* THE IN-BETWEEN */}

            {state.action === ACTIONS.DELETE_ROLE ? (
              <CardInBetween>
                {" "}
                {"Are you sure you would like to delete this role?"}{" "}
              </CardInBetween>
            ) : (
              renderAddEditInput
            )}

            {/* THE BUTTONS */}
            <Flex
              justifyContent={"center"}
              gap="20px"
              alignItems="center"
              paddingTop="20px"
              paddingBottom="40px"
            >
              <Button
                css={css`
                  background: #eef3f7;
                  width: fit-content;
                  height: auto;
                  color: ${colors.faintAsh};
                `}
                onClick={reset}
              >
                {state.action === ACTIONS.DELETE_ROLE ? "Cancel" : "Discard"}
              </Button>
              <Button
                css={css`
                  background: ${state.action === ACTIONS.DELETE_ROLE
                    ? "red"
                    : colors.blue};
                  font-weight: ${state.action === ACTIONS.DELETE_ROLE && 500};
                  width: fit-content;
                  height: auto;
                `}
                onClick={() => {
                  state.action === ACTIONS.DELETE_ROLE
                    ? deleteRole(chosenRole)
                    : state.action === ACTIONS.ADD_ROLE
                    ? createRole(value)
                    : updateRole();
                }}
              >
                {state.action === ACTIONS.DELETE_ROLE
                  ? "Delete"
                  : state.action === ACTIONS.EDIT_ROLE
                  ? "Edit"
                  : "Submit"}
              </Button>
            </Flex>
          </Card>
        </AnimatePresence>
      </Modal>
    ),
    [visible, setVisible, renderAddEditInput]
  );

  const renderToast = React.useMemo(
    () => (
      <Toast
        visible={toast.state}
        setVisible={setToast}
        text={toast.text}
        textStyle={{ color: toast.textColor }}
        style={{ backgroundColor: toast.bgColor }}
      />
    ),
    [toast]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      {renderSearchAndAddRoleArea}
      <CategoryHeader>
        <p>Roles</p>
        <p>Actions</p>
      </CategoryHeader>
      {renderRoles}
      {renderModal}
      {renderToast}
    </Container>
  );
};

export default Roles;
