// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// FIREBASE CONFIG FOR PRODUCTION
// const firebaseConfig = {
//  apiKey: process.env.REACT_APP_PROD_API_KEY || " ",
//  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
//  databaseURL: process.env.REACT_APP_PROD_DB_URL,
//  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
//  storageBucket: process.env.REACT_APP_PROD_STORAGE,
//  messagingSenderId: process.env.REACT_APP_PROD_SENDER_ID,
//  appId: process.env.REACT_APP_PROD_API_ID,
// };

// FIREBASE CONFIG FOR DEVELOPMENT
const firebaseConfig = {
 apiKey: process.env.REACT_APP_API_KEY || " ",
 authDomain: process.env.REACT_APP_AUTH_DOMAIN,
 databaseURL: process.env.REACT_APP_DB_URL,
 projectId: process.env.REACT_APP_PROJECT_ID,
 storageBucket: process.env.REACT_APP_STORAGE,
 messagingSenderId: process.env.REACT_APP_SENDER_ID,
 appId: process.env.REACT_APP_API_ID,
 measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
// Initialize Realtime Database and get a reference to the service
export const db = getDatabase(app);

// Initialize Cloud Functions and get a reference to the service
export const cf = getFunctions(app);
// const analytics = getAnalytics(app);

export const storedb = getFirestore(app);

const firebaseCloudMessaging = {
 tokenInlocalStorage: async () => {
  const token = localStorage.getItem("fcm_token");
  // console.log("fcm_token", token);
  return token;
 },
 // onMessage: async () => {
 //   const messaging = getMessaging(app);
 //   new Promise((resolve) => {
 //     onMessage(messaging, (payload) => {
 //       console.log("Message received. ", payload);
 //       //alert("Notificacion");
 //       resolve(payload?.notification);
 //     });
 //   });
 // },
 init: async function () {
  try {
   const messaging = getMessaging(app);
   await Notification.requestPermission();
   return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FCM_TOKEN,
   })
    .then((currentToken) => {
     if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // save the token in your database
      localStorage.setItem("fcm_token", currentToken);
      console.log("fcm_token", currentToken);
      return currentToken;
     } else {
      // Show permission request UI
      // console.log(
      //   "NOTIFICACION, No registration token available. Request permission to generate one."
      // );
      // ...
     }
    })
    .catch((err) => {
     // console.log(
     //   "NOTIFICACIONAn error occurred while retrieving token . "
     // );
    });
  } catch (error) {
   // console.error(error);
  }
 },
};

export { firebaseCloudMessaging };
