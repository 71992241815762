import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors, fontWeight } from '../../../assets/styles/config';

export const Container = styled.div`
  display: grid;
  // place-items: center;
  padding-inline: 1.5rem;
  width: 100vw;
  //   min-height: calc(100vh - 200px);
  height: 100%;
  background-color: ${colors.skyBlue};
  padding-top: 50px;
  padding-bottom: 100px;

  @media screen and (min-width: 1080px) {
    padding-inline: 4rem;
    padding-top: 100px;
  }
`;

export const Wrap = styled.section`
  display: grid;
  gap: 3rem;

  @media screen and (min-width: 1080px) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

export const PrimaryCardWrapper = styled.section`
  width: 100%;
  min-height: 94px;
  background: #ffffff;
  box-shadow: 7px 14px 25px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  padding: 1.75rem;
  // @media screen and (min-width: 768px) {
  //   width: 100%;
  // }

  // @media screen and (min-width: 1080px) {
  //   width: 100%;
  // }
  ${(props) => props.css || ''}
`;

export const SecondaryCardWrapper = styled.section`
  width: 100%;
  min-height: 94px;
  background: #ffffff;
  box-shadow: 7px 14px 25px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  padding: 1.75rem;

  @media screen and (min-width: 768px) {
    width: 90%;
  }

  // @media screen and (min0width: 1080px) {
  //   width: 60%;
  // }
  ${(props) => props.css || ''}
`;

export const ShowNotification = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 7px 14px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 1rem;
  min-height: 30px;
  height: 55px;
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #219653;
  transform: translateX(100px;);

  // @media screen and (min-width: 768px) {
  //   width: 70%;
  // }

  // @media screen and (min0width: 1080px) {
  //   width: 60%;
  // }
  ${(props) => props.css || ''}
`;

export const TagHeader = styled.div`
  // border-radius: 12px 12px 0 0;
  // background: ${colors.faintBlue};
  border-bottom: 1px solid black;
  font-weight: 600;
  font-size: 19px;
  padding-bottom: 0.75rem;
  font-family: 'Rubik', 'sans-serif';

  @media screen and (min-width: 768px) {
    font-size: 21px;
  }
  @media screen and (min-width: 1080px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const Main = styled.main`
  // margin: 2rem 0;
`;

export const HelloUser = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const UserProfileText = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const OrdersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  gap: 1rem;
`;

export const OrderWrapper = styled.div`
  border: 0.5px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  padding: 1.5rem;
  gap: 1rem;
`;

export const OrderQuery = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  // font-weight: 600;
  // color: ${colors.faintBrown};
  font-size: 14px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1080px) {
    font-size: 18px;
    line-height: 48px;
  }
`;

export const WrapAvatar = styled.div`
  position: relative;
  // justify-self: center;
  cursor: pointer;
  // margin: 0 auto;
`;

export const AvatarBtn = styled.button`
  background: #979797;
  border-radius: 50%;
  // width: 40px;
  padding: 0.25rem 0.5rem;
  // height: 40px;
  display: grid;
  place-items: center;
  position: absolute;
  z-index: 2;
  right: 0;
  cursor: pointer;
  transition: 0.2s;

  & label {
    cursor: pointer;
  }

  &:hover {
    scale: 0.9;
    background: #979793;
  }
`;
