import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, fontWeight } from "../../../assets/styles/config";

export const Wrapper = styled.div``;

export const Container = styled(motion.div)`
 padding: 2rem;
 display: flex;
 align-items: center;
 justify-content: center;

 @media screen and (min-width: 1080px) {
  padding: 3rem 4rem;
 }
`;

export const Card = styled(motion.div)`
 background-color: ${colors.primaryWhite};
 padding: 2rem;
 padding: 3rem 4rem;
 position: relative;
 border-radius: 28px;
 height: 500px;
 width: 300px;

 @media screen and (min-width: 1080px) {
  min-width: 300px;
  width: 500px;
  height: 500px;
  border-radius: 28px;
  padding: 3rem 4rem;
 }
`;

export const CloseBtn = styled.button`
 position: absolute;
 right: 20px;
 top: 20px;
`;

export const StatisticsImg = styled.div`
 width: 40px;
 height: 40px;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: ${(props) => props.background};
`;

export const StatisticsValue = styled.p`
 font-family: "Satoshi";
 font-style: normal;
 font-weight: 700;
 font-size: 32px;
 line-height: 43px;

 color: ${colors.secondaryBlack};
`;

export const StatisticsTitle = styled.p`
 font-family: "Satoshi";
 font-style: normal;
 font-weight: 500;
 font-size: 16px;
 line-height: 22px;
 /* identical to box height */

 /* Slate 5 */

 color: #64748b;
`;

export const StatisticsCardsWrapper = styled.div`
 display: flex;
 justify-content: space-between;
 gap: 24px;

 @media (max-width: 767px) {
  overflow-x: scroll;
  display: grid;
  grid-template-columns: repeat(4, 300px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
   display: none;
  }
 }

 // @media (max-width: 1024px) {
 //   flex-wrap: wrap;
 // }
`;

export const RBEHeading = styled.h3`
 font-family: "Creato Display";
 font-style: normal;
 font-weight: 500;
 font-size: 20px;
 line-height: 125%;
 /* identical to box height, or 25px */

 /* Slate 8 */

 color: #334155;
 // margin-bottom: 16px;
 padding: 20.5px 24px;
 padding-bottom: 0px;
`;

export const RBESection = styled.tbody``;

export const RBEWrapper = styled.table`
 // background: #ffffff;
 // /* Slate 3 */
 width: 100%;
 // border: 1px solid #e2e8f0;
 // border-radius: 16px;
`;

export const RBEHeadings = styled.tr`
 // display: flex;
 // justify-content: space-between;

 font-family: "Satoshi";
 font-style: normal;
 font-weight: 700;
 font-size: 14px;
 line-height: 125%;
 /* identical to box height, or 18px */

 color: #334155;

 td {
  padding: 20.5px 24px;
  border-bottom: 1px solid #e2e8f0;
 }
`;

export const RBEInfo = styled.tr`
 font-family: "Satoshi";
 // display: flex;
 // justify-content: space-between;

 font-style: normal;
 font-weight: 500;
 font-size: 14px;
 line-height: 125%;
 /* identical to box height, or 18px */

 color: #334155;

 td {
  padding: 17px 24px;
  border-bottom: 1px solid #e2e8f0;
 }
`;

export const DashboardHero = styled.div`
 display: flex;
 flex-direction: column;
 margin-top: 10px;
 gap: 24px;
 width: 100%;

 @media (min-width: 1080px) {
  margin-top: 32px;
  // display: grid;
  // grid-template-columns: 1fr minmax(400px, 410px);
 }

 @media (min-width: 1540px) {
  // grid-template-columns: 1fr minmax(400px, 550px);
 }
`;

// PLAIN OBJECT STYLES
export const statisticsCardWrapper = {
 padding: "10px",
 backgroundColor: colors.primaryWhite,
 flexDirection: "column",
 borderRadius: "18px",
 border: "1px solid #E2E8F0",
 flexBasis: "260px",
 justifyContent: "space-between",
 padding: "24px",
 gap: "7px",
 flexGrow: 1,
};

export const AreaChartWrapper = styled.div`
 display: flex;
 flex-direction: column;

 background: #ffffff;
 /* Slate 3 */

 border: 1px solid #e2e8f0;
 border-radius: 16px;
 padding: 29.5px 12.25px;

 * {
  font-family: "Satoshi";
 }

 #chart .toolbar,
 #chart .apexcharts-toolbar {
  display: none;
 }
`;

export const AreaChartHeader = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding-inline: 14.25px;
`;

export const AreaChartHeading = styled.p`
 /* Heading 3 Medium */

 font-family: "Creato Display";
 font-style: normal;
 font-weight: 500;
 font-size: 16px;
 line-height: 125%;
 /* identical to box height, or 20px */

 /* Slate 8 */

 color: #334155;
`;

export const PieChartWrapper = styled.div`
 margin-top: 17px;
 background: #ffffff;
 /* Slate 3 */

 border: 1px solid #e2e8f0;
 border-radius: 16px;
 padding: 22.5px 25px;
 display: flex;
 flex-direction: column;
 gap: 16px;
`;

export const profileFormFieldStyles = {
 _placeholder: {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#475569",
  opacity: "0.45",
 },
 _focus: { background: "#F3F0FF" },
 _focusVisible: { border: "1px solid #AC98FE" },
 background: "#F1F5F9",
 p: "25px 20px",
 borderRadius: "66px",
};

export const profileFormLabelStyles = {
 fontFamily: "Satoshi",
 color: "#0F0931",
 fontSize: { base: "12px", md: "14px" },
 lineHeight: "21px",
 fontWeight: "700",
};

export const profileFormControlStyles = {
 display: "flex",
 flexDir: "column",
 alignItems: "flex-start",
 mb: "20px",
};

export const selectStyle = {
 fontFamily: "Satoshi",
 //placeholder: "Dining",
 // height: "3.7em",
 fontSize: "14px",
 //  autoFocus: true,
 background: { md: "#F1F5F9" },
 borderRadius: { base: "20px", md: "66px" },
 _placeholder: {
  fontFamily: "Satoshi",
  fontWeight: 500,
  fontSize: "14px",
  color: "#475569",
  opacity: "0.45",
 },
 _focus: {
  background: "#F3F0FF",
 },
 _focusVisible: { border: "1px solid #AC98FE" },
};

export const inputStyle = {
 fontFamily: "Satoshi",
 // placeholder: "Dining",
 // height: "3.7em",
 padding: "20px",
 fontSize: "14px",
 // autoFocus: true,
 background: "#F1F5F9",
 borderRadius: "66px",
 _placeholder: {
  fontFamily: "Satoshi",
  fontWeight: 500,
  fontSize: "14px",
  color: "#475569",
  opacity: "0.45",
 },
 //  width: { base: "auto", md: "100%" },
 _focus: {
  background: "#F3F0FF",
 },
 _focusVisible: { border: "1px solid #AC98FE" },
};

export const dateStyle = {
 fontFamily: "Satoshi",
 // placeholder: "Dining",
 // height: "3.7em",
 padding: "20px",
 fontSize: "14px",
 // autoFocus: true,
 background: "#F1F5F9",
 borderRadius: "66px",
 _placeholder: {
  fontFamily: "Satoshi",
  fontWeight: 500,
  fontSize: "14px",
  color: "#475569",
  opacity: "0.45",
 },
 _focus: {
  background: "#F3F0FF",
 },
 _focusVisible: { border: "1px solid #AC98FE" },
};

export const searchInputStyle = {
 borderRadius: "28px",
 height: { base: "50px", md: "48px" },
 type: "search",
 placeholder: "Search",
 paddingLeft: "52px",
 background: "#F8FAFC",
 _focus: {
  background: "#F3F0FF",
 },
 _focusVisible: { border: "1px solid #AC98FE" },
};

export const radioStyle = {
 accentColor: "#632EFF",
};
