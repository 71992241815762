import styled from "styled-components";
import { motion } from "framer-motion";
import { colors } from "../../assets/styles/config";

export const TextInsideButton = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2%;
`;

export const PrimaryButton = styled(motion.button)`
  background-color: ${(props) =>
    props?.buttonColor ? props?.buttonColor : colors.blue};
  background: ${(props) =>
    props.disabled
      ? "#C8C8C8"
      : props?.buttonColor
      ? props?.buttonColor
      : colors.blue};

  border-width: 0px;
  color: ${(props) =>
    props?.textColor ? props?.textColor : colors.primaryWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 18px;
  padding: ${(props) => props?.padding || "16px 24px"};
  user-select: none;
  border-radius: 28px;
  height: ${(props) => props?.height || "54px"};
  ${(props) =>
    props?.enableShadow
      ? `box-shadow: 5px 1px 10px 0px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 5px 1px 10px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 5px 1px 10px 0px rgba(0, 0, 0, 0.3);`
      : ``}
  ${(props) => props?.css || ""};
`;

export const GreenYellowButton = styled(motion.button)`
  background-color: ${(props) =>
    props?.buttonColor ? props?.buttonColor : colors.skyBlue};
  color: ${(props) => (props?.textColor ? props?.textColor : colors.white)};
  border-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 184.87px;
  height: 52.24px;
  // padding: 1.2rem 3rem;
  border-radius: 0px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    padding: 2.5rem;
    font-size: 18px;
  }
  ${(props) => props?.css || ""};
`;

export const ButtonText = styled(TextInsideButton)`
  margin: 0;
  color: ${(props) =>
    props?.textColor ? props?.textColor : colors.primaryBlack};
  white-space: nowrap;
  user-select: none;
  @media screen and (max-width: 350px) {
    font-size: 14px;
  }
  ${(props) => props?.css || ""};
`;

export const ButtonSubtitle = styled.span`
  ${(props) => props?.css || ""}
`;
