import React from 'react';
import { ExpandBtn, FaintBrownText } from '../styles';
import {
  Container,
  Header,
  Heading,
  SlideDetails,
  SlideImg,
  SlideText,
  SlideTitle,
  SlideWrapper,
  SplideSlideWrapper,
} from './styles';
import Icon from '../../../components/Icon';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useCityContext } from '../../../context';
// Default theme
import '@splidejs/react-splide/css';
import { css } from 'styled-components';
import { SliderArrowPath } from '../../../assets/styles';

const Blog = () => {
  const { currentCity, blog } = useCityContext();

  return (
    <Container>
      <div>
        <Header>
          <Heading
            as={'a'}
            href='https://thenaviapp.com/navi-journal/'
            target='_blank'
          >
            {currentCity} Blog
          </Heading>
        </Header>
      </div>
      <SlideWrapper>
        <Splide
          aria-labelledby='Blog'
          options={{
            rewind: true,
            perPage: 4,
            gap: '2rem',
            breakpoints: {
              640: {
                perPage: 1,
              },
              768: {
                perPage: 2,
              },
              1080: {
                perPage: 3,
              },
              1660: {
                perPage: 4,
              },
            },
            classes: {
              arrows: 'splide__arrows splide-navigation-arrows',
              arrow: 'splide__arrow splide-navigation-arrow splide-blog',
              prev: 'splide__arrow--prev splide-nav-prev',
              next: 'splide__arrow--next splide-nav-next',
              pagination: 'splide__pagination blog-pagination',
              page: 'splide__pagination__page blog-pagination-page',
            },
            arrowPath: SliderArrowPath,
          }}
        >
          {blog?.map((blog, index) => (
            <SplideSlide key={index}>
              <a href={blog.url} target='_blank' rel='noopener noreferrer'>
                <SplideSlideWrapper>
                  <SlideImg src={blog.coverImage} alt={blog.title} />
                </SplideSlideWrapper>{' '}
              </a>
              <SlideDetails>
                <SlideTitle>{blog.title}</SlideTitle>
                <SlideText>{blog.snippet}</SlideText>
                <ExpandBtn
                  as={'a'}
                  href={blog.url}
                  css={css`
                    font-weight: 400;
                    margin-top: 10px;
                    text-align: left;
                    justify-content: start;
                  `}
                  target={'_blank'}
                >
                  Read More
                </ExpandBtn>
              </SlideDetails>
            </SplideSlide>
          ))}
        </Splide>
      </SlideWrapper>
    </Container>
  );
};

export default Blog;
