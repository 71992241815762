import { css } from '@emotion/react';
import styled from 'styled-components';
import { colors, fontWeight } from '../../../assets/styles/config';

export const Heading = styled.h1`
  font-weight: ${fontWeight.semiBold};
  color: ${colors.darkBlue};
  text-transform: capitalize;
  font-size: 28px;
  font-family: 'Rubik', sans-serif;

  @media screen and (min-width: 768px) {
    font-size: 35px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 57px;
    line-height: 70px;
    margin-bottom: 10px;
  }
  ${(props) => props.css || ''}
`;

export const TabTitle = styled.p`
  text-transform: capitalize;
  font-size: 15px;
  font-weight: ${fontWeight.medium};

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 18px;
  }
`;

export const DescriptionText = styled.p`
  font-size: 14px;
  color: ${colors.darkBlue};

  @media screen and (min-width: 768px) {
    line-height: 24px;
    font-size: 15px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 16px;
  }
`;

export const Wrapper = styled.div`
  padding: 20px 10px;
  ${(props) => props.css || ''}
`;

export const SubTitle = styled.p`
  color: #afaeae;
  font-size: 18px;

  @media screen and (min-width: 1080px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 24px;
    line-height: 36px;
  }

  ${(props) => props.css || ''}
`;

export const UsefulApp = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: ease 0.3s;

  &:hover {
    scale: 0.95;
    opacity: 0.8;
  }

  @media screen and (min-width: 1080px) {
    width: 75px;
    height: 75px;
  }
`;

export const UsefulAppLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const Degree = styled.p`
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 28px;
  display: flex;

  svg {
    // font-size: 125px;
    width: 80px;
    height: 80px;
    margin-top: -8px;
    margin-bottom: -20px;
    margin-left: -25px;
  }

  @media screen and (min-width: 768px) {
    font-size: 38px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 48px;
    line-height: 57px;
    margin-top: 125px !important;
    margin-left: -10px !important;
  }
`;

export const UsefulAppsHeading = styled.p`
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    font-size: 28px;
  }

  @media screen and (min-width: 1080px) {
    font-weight: 500;
    font-size: 36px;
    line-height: 70px;
  }
`;

export const WeatherImage = styled.img`
  width: 237px;
  height: 226.69px;
  object-fit: cover;
`;

export const CityEnvelope = styled.div`
  @media screen and (min-width: 1080px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    // justify-content: center;
    // align-items: center;
  }
`;

export const HideOnSm = styled.div`
  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const HideOnMd = styled.div`
  @media screen and (min-width: 1080px) {
    display: none;
  }
`;
