import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import {
 Box,
 Text,
 Flex,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Image,
 Input,
 InputGroup,
 InputLeftElement,
 Tabs,
 TabList,
 TabPanels,
 Tab,
 TabPanel,
 Button,
} from "@chakra-ui/react";
import styles from "./index.module.css";
import { searchInputStyle, selectStyle } from "../../../Admin/Dashboard/styles";
import { SearchIcon } from "../../../../components/Icon/otherIcons";
import { AuthContext } from "../../../../context/AuthContext";
import OptionIcon from "../../../../assets/images/option.svg";
import {
 getDatabase,
 ref,
 set,
 child,
 get,
 onValue,
 update,
} from "firebase/database";
import { db } from "../../../../firebase";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { EmptyState, LoadingIndicator } from "../../../../components";
import dayjs from "dayjs";
import { flatten } from "../../../../utils";

const RecurringPartnerExperiences = () => {
 const { user } = useContext(AuthContext);
 const [loading, setLoading] = useState(false);
 const [experiences, setExperience] = useState([]);
 const [filterExperiences, setFilterExperiences] = useState([]);
 const [search, setSearch] = useState("");
 // const [orders, setOrders] = useState([]);
 const tableRef = useRef(null);
 const navigate = useNavigate();
 const params = useParams();
 const expId = params?.expId;
 const experienceDb = ref(db, "cityExperience/experiences");
 const timeframesId = params?.timeframesId;
 const timeSlotId = params?.timeSlotId;
 const [recentExps, setRecentExps] = useState([]);

 const getDateArray = (recurring, custom_recurrence, experiences) => {
  console.log("experiences", experiences);
  let array, start, end;
  switch (recurring) {
   case "Daily":
    start = dayjs(JSON.parse(experiences?.timestamp || {}));
    end = start.add(30, "day");

    const datesArray = [];

    for (
     let date = start;
     date.isBefore(end) || date.isSame(end, "day");
     date = date.add(1, "day")
    ) {
     datesArray.push(date);
    }

    array = datesArray;
    return array;

   case "Weekly on Mondays":
    start = dayjs(JSON.parse(experiences?.timestamp || {}));
    end = start.add(2, "month").endOf("month");
    // end = start.add(2, "month").endOf("month");
    const result = [];

    for (let date = start; date.isBefore(end); date = date.add(1, "day")) {
     if (date.day() === 1) {
      // 1 for Monday
      result.push(date);
     }
    }
    array = result;
    return array;
   case "Custom":
    const flatArr = flatten([...custom_recurrence.repeat_on]);
    start = dayjs(JSON.parse(experiences?.timestamp || {}));
    end =
     custom_recurrence?.end === "never"
      ? start.endOf("month")
      : dayjs(custom_recurrence?.end_on);

    const custom_result = [];

    for (
     let date = start;
     date.isBefore(end) || date.isSame(end, "day");
     date = date.add(1, "day")
    ) {
     const weekday = date.day();
     if (flatArr.includes(weekday)) {
      custom_result.push(date);
     }
    }
    array = custom_result;
    return array;

   default:
    return array;
  }
 };

 const filterObjectExperience = (obj) =>
  Object.keys(obj).reduce(
   (acc, val) =>
    Number(obj[val]["id"]) === Number(expId)
     ? {
        ...acc,
        [val]: obj[val],
       }
     : acc,
   {}
  );

 const fetchBoughtExp = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `transactions/`))
   .then((snapshot) => {
    setLoading(false);
    if (snapshot.exists()) {
     const data = Object.values(snapshot.val());
     setRecentExps(
      Object.keys(data).map((key) => {
       return {
        ...data[key],
       };
      })
     );
    }
   })
   .catch((error) => {
    setLoading(false);
   });
  return db;
 };

 const fetchDatabase = async () => {
  setLoading(true);
  const dbRef = ref(getDatabase());
  //get user experience
  const db = await get(child(dbRef, `cityExperience/experiences/`))
   .then((snapshot) => {
    setLoading(false);
    //get user experience
    let objExp = filterObjectExperience(snapshot.val());

    // console.log("objExp", objExp);
    if (objExp) {
     setExperience(
      Object.keys(objExp).map((key) => {
       return {
        ...objExp[key],
       };
      })[0]
     );
    }
   })
   .catch((error) => {
    // console.error(error);
    setLoading(false);
   });
  return db;
 };

 useEffect(() => {
  fetchDatabase();
  fetchBoughtExp();
 }, [user]);

 useEffect(() => {
  const timeFrameExp =
   experiences.timeframes && JSON.parse(experiences.timeframes)[timeframesId];

  const timeSlot = timeFrameExp?.timeSlot[timeSlotId];
  const custom_recurrence = timeFrameExp?.custom_recurrence;
  const dateArray = getDateArray(
   timeFrameExp?.recurring,
   custom_recurrence,
   experiences
  );
  const mappedData = dateArray?.map((date) => {
   console.log(timeFrameExp, timeSlot, "timeFrameExp");
   return {
    ...experiences,
    date: date,
    recurring: timeFrameExp.recurring,
    custom_recurrence: timeFrameExp.custom_recurrence,
    start: timeSlot.start,
    end: timeSlot.end,
    amount: timeSlot.amount,
    maxTickets: timeSlot.maxTickets,
    timeSlotId: timeSlot.id,
    timeframesId: timeFrameExp.id,
    admitsHowMany: timeSlot.admitsHowMany,
   };
  });

  setFilterExperiences(mappedData);
 }, [experiences]);

 const searchFilter = (text) => {
  if (text) {
   setSearch(text);
   const newData = experiences?.filter((item) => {
    const itemData = item.title
     ? item.title.replace(/<\/?[^>]+(>|$)/g, "").toUpperCase()
     : "".toUpperCase();
    const input = text.toUpperCase();
    return itemData.indexOf(input) > -1;
   });
   setFilterExperiences(newData);
  } else {
   setSearch(text);
   setFilterExperiences(experiences);
  }
 };

 // console.log("experience", Object.keys(orders));

 const ticketSold = (expDetail) => {
  const timeSlot = {
   amount: expDetail?.amount,
   end: expDetail?.end,
   id: expDetail?.id,
   maxTickets: expDetail?.maxTickets,
   start: expDetail?.start,
  };

  let tickets = recentExps
   ?.filter((item) => Number(item?.experienceId) === Number(expId))
   ?.filter(
    (item) =>
     JSON.stringify(item?.chosenTimeFrame.timeSlot) === JSON.stringify(timeSlot)
   )
   ?.filter(
    (item) =>
     dayjs(JSON.parse(item.transationDate || {})).format("YYYY-MM-DD") ===
     dayjs(expDetail?.date).format("YYYY-MM-DD")
   )
   .reduce((a, b) => {
    return a + b?.ticketQuantity;
   }, 0);
  // console.log("tickets==", tickets);
  return tickets;
 };

 const renderUpcomingDaily = useMemo(
  () => (
   <>
    {filterExperiences
     ?.filter(
      (exp) =>
       dayjs(exp?.date).isAfter(dayjs(), "day") ||
       dayjs(exp?.date).isSame(dayjs(), "day")
     )
     ?.map((item, index) => (
      <Tr
       key={index}
       cursor="pointer"
       _hover={{
        background: "#F1F5F9",
       }}
      >
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {item?.title}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {dayjs(item?.date).format("DD-MM-YYYY")}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {item?.start}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {item?.end}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {dayjs(item?.date).isAfter(dayjs(), "day") ? (
         <span className={[styles.pill, styles.pending].join(" ")}>
          Pending
         </span>
        ) : dayjs(item?.date).isBefore(dayjs(), "day") ? (
         <span className={[styles.pill, styles.arroved].join(" ")}>
          Complete
         </span>
        ) : (
         dayjs(item?.date).isSame(dayjs(), "day") && (
          <span className={[styles.pill, styles.disapproved].join(" ")}>
           In progress
          </span>
         )
        )}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {ticketSold({
         date: item.date,
         id: item.timeSlotId,
         start: item.start,
         end: item.end,
         amount: item.amount,
         maxTickets: item.maxTickets,
         type: item.type,
         recurring: item.recurring,
        })}
       </Td>
      </Tr>
     ))}
   </>
  ),
  [filterExperiences]
 );

 const renderCompletedDaily = useMemo(
  () => (
   <>
    {filterExperiences
     ?.filter((exp) => dayjs(exp?.date).isBefore(dayjs(), "day"))
     ?.map((item, index) => (
      <Tr
       key={index}
       cursor="pointer"
       _hover={{
        background: "#F1F5F9",
       }}
      >
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {item?.title}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {dayjs(item?.date).format("DD-MM-YYYY")}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {item?.start}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {item?.end}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {dayjs(item?.date).isAfter(dayjs(), "day") ? (
         <span className={[styles.pill, styles.pending].join(" ")}>
          Pending
         </span>
        ) : dayjs(item?.date).isBefore(dayjs(), "day") ? (
         <span className={[styles.pill, styles.arroved].join(" ")}>
          Complete
         </span>
        ) : (
         dayjs(item?.date).isSame(dayjs(), "day") && (
          <span className={[styles.pill, styles.disapproved].join(" ")}>
           In progress
          </span>
         )
        )}
       </Td>
       <Td
        padding="14px 20px"
        fontSize={{ base: "14px" }}
        lineHeight="125%"
        fontWeight="500"
        fontFamily="Satoshi"
        color="#334155"
        cursor="pointer"
        onClick={() => navigate(`/partner/experience/bought/${item.id}`)}
       >
        {ticketSold({
         date: item.date,
         id: item.timeSlotId,
         start: item.start,
         end: item.end,
         amount: item.amount,
         maxTickets: item.maxTickets,
         type: item.type,
         recurring: item.recurring,
        })}
       </Td>
      </Tr>
     ))}
   </>
  ),
  [filterExperiences]
 );

 return (
  <Box
   height="100%"
   minH="calc(100vh - 85px)"
   padding={{ base: "0px 10px", md: "14px 20px" }}
   background="#F5F8FA"
   position="relative"
  >
   <Box
    display={{ base: "none", md: "flex" }}
    alignItems="center"
    cursor="pointer"
    onClick={() => navigate(-1)}
   >
    <Image src={ArrowLeft} />
    <Text color="#252046" marginLeft="10px">
     Go Back
    </Text>
   </Box>
   <Box
    background={{ md: "#FFFFFF" }}
    borderRadius={{ md: "6px" }}
    mt={{ md: "16px" }}
    minHeight={{ md: "80vh" }}
    padding={{ md: "30px" }}
   >
    <Flex
     alignItems="flex-start"
     flexDir={{ base: "column", md: "row" }}
     justifyContent="space-between"
     display={{ base: "none", md: "flex" }}
    >
     <InputGroup borderRadius="28px" width="50%">
      <InputLeftElement
       pointerEvents="none"
       children={<SearchIcon color="gray.300" />}
       paddingBlock="24px"
       paddingLeft={"25px"}
      />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </InputGroup>
    </Flex>
    {loading ? (
     <Box display="flex" alignItems="center" justifyContent="center" mt="40px">
      <LoadingIndicator />
     </Box>
    ) : (
     <Box mt={{ md: "30px" }}>
      <Tabs variant="soft-rounded">
       <TabList
        padding={{ md: "5px" }}
        borderRadius="32px"
        background={{ base: "#FFFFFF", md: "#F1F5F9" }}
        width={{ base: "100%", md: "500px" }}
        justifyContent="center"
        className="tabs"
       >
        <Tab
         fontSize={{ base: "12px", md: "14px" }}
         paddingTop="10px"
         paddingBottom="10px"
         width={{ base: "100%", md: "250px" }}
        >
         Upcoming experiences
        </Tab>
        <Tab
         fontSize={{ base: "12px", md: "14px" }}
         paddingTop="10px"
         paddingBottom="10px"
         width={{ base: "100%", md: "250px" }}
        >
         Completed experiences
        </Tab>
       </TabList>

       <TabPanels>
        <TabPanel>
         {filterExperiences?.filter(
          (exp) =>
           dayjs(exp?.date).isAfter(dayjs(), "day") ||
           dayjs(exp?.date).isSame(dayjs(), "day")
         )?.length > 0 ? (
          <>
           <TableContainer
            border="1px solid #E2E8F0"
            borderRadius="16px 16px 0px 0px"
            display={{ base: "none", md: "block" }}
           >
            <Table variant="simple" ref={tableRef}>
             <Thead>
              <Tr>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Experience
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Date
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Start time
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                End time
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Status
               </Th>

               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Total tix sold
               </Th>
              </Tr>
             </Thead>
             <Tbody>{renderUpcomingDaily}</Tbody>
            </Table>
           </TableContainer>
           <Box display={{ base: "block", md: "none" }}>
            {filterExperiences
             ?.filter(
              (exp) =>
               dayjs(exp?.date).isAfter(dayjs(), "day") ||
               dayjs(exp?.date).isSame(dayjs(), "day")
             )
             ?.map((item, index) => (
              <Box
               position="relative"
               background="#fff"
               width="100%"
               border="1px solid #e2e8f0"
               borderRadius="16px"
               padding="0px 10px"
               key={index}
               marginBottom="20px"
              >
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom="1px solid #E2E8F0"
               >
                <Text>Experience Date</Text>
                <Text>{dayjs(item?.date).format("DD-MM-YYYY")}</Text>
               </Box>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom="1px solid #E2E8F0"
               >
                <Text>Start time</Text>
                <Text>{item?.start}</Text>
               </Box>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom="1px solid #E2E8F0"
               >
                <Text>End time</Text>
                <Text>{item?.end}</Text>
               </Box>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom="1px solid #E2E8F0"
               >
                <Text>Ticket Sold</Text>
                <Text>
                 {" "}
                 {ticketSold({
                  date: item.date,
                  id: item.timeSlotId,
                  start: item.start,
                  end: item.end,
                  amount: item.amount,
                  maxTickets: item.maxTickets,
                  type: item.type,
                  recurring: item.recurring,
                 }) ?? 0}
                </Text>
               </Box>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                // borderBottom="1px solid #E2E8F0"
               >
                <Text>Status</Text>
                <Text>
                 {dayjs(item?.date).isAfter(dayjs(), "day") ? (
                  <span className={[styles.pill, styles.pending].join(" ")}>
                   Pending
                  </span>
                 ) : dayjs(item?.date).isBefore(dayjs(), "day") ? (
                  <span className={[styles.pill, styles.arroved].join(" ")}>
                   Complete
                  </span>
                 ) : (
                  dayjs(item?.date).isSame(dayjs(), "day") && (
                   <span
                    className={[styles.pill, styles.disapproved].join(" ")}
                   >
                    In progress
                   </span>
                  )
                 )}
                </Text>
               </Box>
               <Button
                onClick={() => navigate(`/partner/experience/bought/${expId}`)}
                color="#ffffff"
                bg="#632EFF"
                width="100%"
                borderRadius="54px"
                fontWeight={500}
                fontSize={18}
                // marginRight="20px"
                p="10px 30px"
                margin="10px 0px"
                type="submit"
               >
                View Navigators
               </Button>
              </Box>
             ))}
           </Box>
          </>
         ) : (
          <EmptyState />
         )}
        </TabPanel>
        <TabPanel>
         {filterExperiences?.filter((exp) =>
          dayjs(exp?.date).isBefore(dayjs(), "day")
         )?.length > 0 ? (
          <>
           <TableContainer
            border="1px solid #E2E8F0"
            borderRadius="16px 16px 0px 0px"
            display={{ base: "none", md: "block" }}
           >
            <Table variant="simple" ref={tableRef}>
             <Thead>
              <Tr>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Experience
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Date
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Start time
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                End time
               </Th>
               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Status
               </Th>

               <Th
                fontFamily="Satoshi"
                color="#252046"
                fontSize={{ base: "12px", md: "14px" }}
                lineHeight="125%"
                fontWeight="700"
                padding="14px 20px"
               >
                Total tix sold
               </Th>
              </Tr>
             </Thead>
             <Tbody>{renderCompletedDaily}</Tbody>
            </Table>
           </TableContainer>
           <Box display={{ base: "block", md: "none" }}>
            {filterExperiences
             ?.filter((exp) => dayjs(exp?.date).isBefore(dayjs(), "day"))
             ?.map((item, index) => (
              <Box
               position="relative"
               background="#fff"
               width="100%"
               border="1px solid #e2e8f0"
               borderRadius="16px"
               padding="0px 10px"
               key={index}
               marginBottom="20px"
              >
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom="1px solid #E2E8F0"
               >
                <Text>Experience Date</Text>
                <Text>{dayjs(item?.date).format("DD-MM-YYYY")}</Text>
               </Box>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom="1px solid #E2E8F0"
               >
                <Text>Start time</Text>
                <Text>{item?.start}</Text>
               </Box>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom="1px solid #E2E8F0"
               >
                <Text>End time</Text>
                <Text>{item?.end}</Text>
               </Box>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                borderBottom="1px solid #E2E8F0"
               >
                <Text>Ticket Sold</Text>
                <Text>
                 {" "}
                 {ticketSold({
                  date: item.date,
                  id: item.timeSlotId,
                  start: item.start,
                  end: item.end,
                  amount: item.amount,
                  maxTickets: item.maxTickets,
                  type: item.type,
                  recurring: item.recurring,
                 }) ?? 0}
                </Text>
               </Box>
               <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="15px 10px"
                // borderBottom="1px solid #E2E8F0"
               >
                <Text>Status</Text>
                <Text>
                 {dayjs(item?.date).isAfter(dayjs(), "day") ? (
                  <span className={[styles.pill, styles.pending].join(" ")}>
                   Pending
                  </span>
                 ) : dayjs(item?.date).isBefore(dayjs(), "day") ? (
                  <span className={[styles.pill, styles.arroved].join(" ")}>
                   Complete
                  </span>
                 ) : (
                  dayjs(item?.date).isSame(dayjs(), "day") && (
                   <span
                    className={[styles.pill, styles.disapproved].join(" ")}
                   >
                    In progress
                   </span>
                  )
                 )}
                </Text>
               </Box>
               <Button
                onClick={() => navigate(`/partner/experience/bought/${expId}`)}
                color="#ffffff"
                bg="#632EFF"
                width="100%"
                borderRadius="54px"
                fontWeight={500}
                fontSize={18}
                // marginRight="20px"
                p="10px 30px"
                margin="10px 0px"
                type="submit"
               >
                View Navigators
               </Button>
              </Box>
             ))}
           </Box>
          </>
         ) : (
          <EmptyState />
         )}
        </TabPanel>
       </TabPanels>
      </Tabs>
     </Box>
    )}
   </Box>
   {/* <Box
        position="absolute"
        bottom="20px"
        width="100%"
        display={{ base: "block", md: "none" }}
        padding="0px 20px"
      >

      </Box> */}
  </Box>
 );
};

export default RecurringPartnerExperiences;
