import {
 chakra,
 Td,
 Th,
 Button,
 Text,
 Flex,
 Box,
 InputGroup,
 InputLeftElement,
 TableContainer,
} from "@chakra-ui/react";

export const TD = chakra(Td, {
 baseStyle: {
  padding: "14px 20px",
  fontSize: "14px",
  lineHeight: "125%",
  fontWeight: "500",
  fontFamily: "Satoshi",
  color: "#334155",
 },
});

export const TH = chakra(Th, {
 baseStyle: {
  padding: "14px 20px",
  fontSize: { base: "12px", md: "14px", lg: "16px" },
  lineHeight: "125%",
  fontWeight: "700",
  fontFamily: "Satoshi",
  color: "#252046",
 },
});

export const ExportButton = chakra(Button, {
 baseStyle: {
  color: "#ffffff",
  bg: "#632EFF",
  // width:"192px",
  borderRadius: "54px",
  fontWeight: 500,
  fontSize: 18,
  marginRight: "20px",
  p: "20px 30px",
  type: "submit",
  gap: "5px",
 },
});

export const BusinessNameText = chakra(Text, {
 baseStyle: {
  fontFamily: "Satoshi",
  color: "#252046",
  fontSize: "18px",
  lineHeight: "125%",
  fontWeight: "700",
  marginBottom: "20px",
 },
});

export const SearchWrapper = chakra(Flex, {
 baseStyle: {
  alignItems: "flex-start",
  flexDir: { base: "column", md: "row" },
  justifyContent: "space-between",
 },
});

export const OuterWrapper = chakra(Box, {
 baseStyle: {
  height: "100%",
  padding: "14px 20px",
  background: "#F5F8FA",
 },
});

export const InnerWrapper = chakra(Box, {
 baseStyle: {
  background: "#FFFFFF",
  borderRadius: "6px",
  mt: "16px",
  minHeight: "80vh",
  padding: "30px",
 },
});

export const ItemsCenter = chakra(Flex, {
 baseStyle: {
  alignItems: "center",
 },
});

export const BoughtTableContainer = chakra(TableContainer, {
 baseStyle: {
  border: "1px solid #E2E8F0",
  borderRadius: "16px 16px 0px 0px",
 },
});

export const BoughtInputLeftElement = chakra(InputLeftElement, {
 baseStyle: {
  pointerEvents: "none",
  paddingBlock: "24px",
  paddingLeft: "25px",
 },
});

export const BoughtInputGroup = chakra(InputGroup, {
 baseStyle: {
  borderRadius: "28px",
  width: "50%",
 },
});
