// localhost:4222/admin/partner-experience/bought/:id
import React, { useState, useEffect, useContext, useRef } from "react";
import {
 Box,
 Text,
 Input,
 Table,
 Thead,
 Tr,
 Tbody,
 Image,
 Center,
} from "@chakra-ui/react";

import { searchInputStyle } from "../../styles";
import { SearchIcon } from "../../../../../components/Icon/otherIcons";

import { AuthContext } from "../../../../../context/AuthContext";
import ExportIcon from "../../../../../assets/images/export1.svg";

import { useNavigate, useParams } from "react-router-dom";

import { getDatabase, ref, child, get } from "firebase/database";
import { LoadingIndicator, EmptyState } from "../../../../../components";

import { CSVLink } from "react-csv";
import { TbCurrencyNaira } from "react-icons/tb";
import ArrowLeft from "../../../../../assets/images/arrow-left-dark.svg";
import {
 BoughtInputGroup,
 BoughtInputLeftElement,
 BoughtTableContainer,
 BusinessNameText,
 ExportButton,
 InnerWrapper,
 ItemsCenter,
 OuterWrapper,
 SearchWrapper,
 TD,
 TH,
} from "./elements";

const BoughtExperiences = () => {
 const { user } = useContext(AuthContext);
 const params = useParams();
 const id = params?.id;
 const expId = params?.expId;
 const [boughtList, setBoughtList] = useState([]);
 const tableRef = useRef(null);
 const [loading, setLoading] = useState(false);
 const navigate = useNavigate();

 const fetchTicketDatabase = async () => {
  // setLoading(true);
  const dbRef = ref(getDatabase());
  const db = await get(child(dbRef, `tickets`)).then((snapshot) => {
   if (snapshot.exists()) {
    // const uid = user?.partnerUid ?? user?.uid;
    const myObj = snapshot.val();
    var array = Object.keys(myObj)
     .map((key) => {
      return myObj[key];
     })
     .filter((item) => item?.partnerId === id);

    let filterArray = Object.keys(array)
     .map((key) => {
      return array[key];
     })
     ?.filter((item) => Number(item?.experienceId) === Number(expId));

    const groupedItems = {};

    for (const item of filterArray) {
     if (groupedItems.hasOwnProperty(item?.user.email)) {
      // groupedItems[item?.user.uid].total += item.total;
      groupedItems[item?.user.email].count++;
     } else {
      groupedItems[item?.user.email] = {
       ...item,
       count: 1,
      };
     }
    }

    const result = Object.values(groupedItems);
    setBoughtList(result);
    // setLoading(false);
   }
  });

  return db;
 };

 const truncateString = (string, maxLength) =>
  string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

 useEffect(() => {
  fetchTicketDatabase();
 }, [expId, user]);

 const searchFilter = (text) => {};

 const csvData = boughtList?.map((item) => {
  return {
   uid: item.user.uid,
   name: item.user.name,
   email: item.user.email,
   bought: item.count,
   amount: item.total,
  };
 });

 const csvHeader = [
  { label: "Navi Id", key: "uid" },
  { label: "Name", key: "name" },
  { label: "Email address", key: "email" },
  { label: "Phone Number", key: "" },
  { label: "Tickets bought", key: "bought" },
  { label: "Amount", key: "amount" },
 ];

 return (
  <OuterWrapper>
   <ItemsCenter cursor="pointer" onClick={() => navigate(-1)}>
    <Image src={ArrowLeft} />
    <Text color="#252046" marginLeft="10px">
     Go Back
    </Text>
   </ItemsCenter>
   <InnerWrapper>
    <BusinessNameText>{user?.businessName}</BusinessNameText>
    <SearchWrapper>
     <BoughtInputGroup>
      <BoughtInputLeftElement children={<SearchIcon color="gray.300" />} />
      <Input
       {...searchInputStyle}
       onChange={(e) => searchFilter(e.target.value)}
      />
     </BoughtInputGroup>
     <Box display="flex" alignItems="center">
      <CSVLink data={csvData} headers={csvHeader}>
       <ExportButton>
        <Image src={ExportIcon} /> Export
       </ExportButton>
      </CSVLink>
     </Box>
    </SearchWrapper>

    {loading ? (
     <Center mt="40px">
      <LoadingIndicator />
     </Center>
    ) : (
     <Box mt="30px">
      {boughtList?.length > 0 ? (
       <BoughtTableContainer>
        <Table variant="simple" ref={tableRef}>
         <Thead>
          <Tr>
           {[
            "Navi Id",
            "Name",
            "Email address",
            "Phone Number",
            "Tickets bought",
            "Amount",
           ].map((i) => (
            <TH>{i}</TH>
           ))}
          </Tr>
         </Thead>
         <Tbody>
          {boughtList?.map((item, index) => {
           const { user } = item;
           const items = [
            truncateString(item?.user?.uid, 8) ?? "Guest",
            user.name,
            user.email,
            "0" + user.phoneNumber,
            item.count,
           ];
           return (
            <Tr
             key={index}
             cursor="pointer"
             _hover={{
              background: "#F1F5F9",
             }}
            >
             {items.map((i) => (
              <TD>{i}</TD>
             ))}
             <TD display="flex" gap="5px">
              <TbCurrencyNaira />
              {item.total}
             </TD>
            </Tr>
           );
          })}
         </Tbody>
        </Table>
       </BoughtTableContainer>
      ) : (
       <Center>
        <EmptyState text={"No experiences have been bought"} />
       </Center>
      )}
     </Box>
    )}
   </InnerWrapper>
  </OuterWrapper>
 );
};

export default BoughtExperiences;
