import deviceType from "./deviceType";
import eventEmitter from "./eventEmitter";
import flattenObj from "./flattenObject";

export function bytesToSize(bytes) {
 const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
 if (bytes === 0) return "n/a";
 // @ts-ignore
 const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
 if (i === 0) return `${bytes} ${sizes[i]})`;
 return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function flatten(arr) {
 return arr.reduce(function (flat, toFlatten) {
  return flat
   .concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
   .sort()
   .filter(function (item, pos, ary) {
    return !pos || item != ary[pos - 1];
   });
 }, []);
}

export function removeObjectWithId(arr, id) {
 const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

 if (objWithIdIndex > -1) {
  arr.splice(objWithIdIndex, 1);
 }

 return arr;
}

export { deviceType, eventEmitter, flattenObj };

export const handleFirebaseError = (error) => {
 switch (error.code) {
  case "auth/invalid-email":
   return "Invalid email address";
   break;
  case "auth/user-not-found":
   return "User not found";
   break;
  case "auth/wrong-password":
   return "Incorrect password";
   break;
  case "auth/user-disabled":
   return "User account is disabled";
   break;
  case "auth/too-many-requests":
   return "Too many unsuccessful login attempts. Please try again later.";
   break;
  case "auth/email-already-in-use":
   return "Email already in use";
   break;
  default:
   return "An error occurred";
   break;
 }
};

export const cleanExperienceTitle = (title) =>
 title.toLowerCase().replace(/[^a-z0-9]+/g, "-");
