import {
 Box,
 FormControl,
 FormErrorMessage,
 FormLabel,
 Input,
 Text,
} from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import { inputStyle } from "../../../../../Admin/Dashboard/styles";

const TicketSection = ({ index, id, errors }) => (
 <>
  <Box
   display="flex"
   alignItems="start"
   flexDirection={{
    base: "column",
    md: "row",
   }}
   gap="20px"
   width="100%"
  >
   <Box width="100%">
    <Text
     fontFamily="Satoshi"
     color="#0F0931"
     fontSize={{
      base: "12px",
      md: "14px",
     }}
     lineHeight="21px"
     fontWeight="700"
    >
     Ticket Quantity
    </Text>{" "}
    <Field
     as={Input}
     marginTop="10px"
     width="100%"
     type="number"
     placeholder="0"
     id={`maxTickets-${index}-${id}`}
     name={`experiences.${index}.timeSlot[${id}].maxTickets`}
     isRequired
    />
   </Box>
   <Box width="100%">
    <Text
     fontFamily="Satoshi"
     color="#0F0931"
     fontSize={{
      base: "12px",
      md: "14px",
     }}
     lineHeight="21px"
     fontWeight="700"
    >
     Ticket Amount
    </Text>
    <Field
     as={Input}
     width="100%"
     marginTop="10px"
     type="number"
     placeholder="NGN"
     id={`amount-${index}-${id}`}
     name={`experiences.${index}.timeSlot[${id}].amount`}
     isRequired
    />
   </Box>
  </Box>
  <FormControl
   w="100%"
   display="flex"
   flexDir="column"
   alignItems="flex-start"
   mt="20px"
   isRequired
   isInvalid={!!errors.admitsHowMany && touched.admitsHowMany}
  >
   <FormLabel
    fontFamily="Satoshi"
    color="#0F0931"
    fontSize={{ base: "14px" }}
    lineHeight="21px"
    fontWeight="700"
   >
    Admits how many minimum?
   </FormLabel>

   <Field
    as={Input}
    {...inputStyle}
    type="number"
    placeholder="How many people per ticket?"
    id="admitsHowMany"
    name={`experiences.${index}.timeSlot[${id}].admitsHowMany`}
    min={1}
   />
   <FormErrorMessage>{errors.admitsHowMany}</FormErrorMessage>
  </FormControl>
 </>
);

export default TicketSection;
