import React, { useContext, useEffect } from "react";
import { Box, HStack, Img, Text, Button } from "@chakra-ui/react";
import { AuthContext } from "../../context/AuthContext";
import Logo from "../../assets/images/Navi-Logo.png";
import { MdOutlineLock } from "react-icons/md";
import style from "./index.module.css";
import NaviTeamHero from "../../assets/images/navi-team-hero.jpg";
import adventure from "../../assets/images/adventure.png";
import NaviBG from "../../assets/images/hero-exp-girls.jpeg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Hero = () => {
 const { user: directUser } = useContext(AuthContext);
 const { ref, inView } = useInView({
  threshold: 0.3,
 });
 const animation = useAnimation();
 const isLoggedIn = localStorage.getItem("isLoggedIn");

 useEffect(() => {
  return () => {
   if (inView) {
    animation.start({
     scale: [0.8, 0.8],
     transition: {
      type: "spring",
      duration: 1,
     },
    });
   }
   if (!inView) {
    animation.start({
     scale: [0.8, 1],
    });
   }
  };
 }, [inView]);

 return (
  <Box
   position="relative"
   overflow={"hidden"}
   width="100%"
   height={{ base: "500px", md: "650px", xl: "800px" }}
  >
   <div style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
    <LazyLoadImage
     alt={"heroimage"}
     src={adventure} // use normal <img> attributes as props
     width={"100%"}
     height={"100%"}
     className={style.image}
     // effect="blur"
     style={{ objectFit: "cover" }}
    />
   </div>

   {/* <video autoPlay loop muted playsInline className={style.image}>
        <source src="https://res.cloudinary.com/dynfklp8t/video/upload/v1667224105/mixkit-empty-restaurant-with-nice-lighting-29050-large_trvhp3.mp4" />
  </video> */}
   <Box className={style.hero}>
    {isLoggedIn ? (
     <Text
      color="#ffffff"
      fontSize={{ base: "25px", md: "37px", lg: "47px", xl: "52px" }}
      lineHeight={{ base: "30px", md: "105%" }}
      fontWeight="700"
      data-animation="heroTitle"
      maxWidth={"680px"}
     >
      Welcome{" "}
      <span style={{ textTransform: "capitalize", display: "inline-block" }}>
       {directUser?.name?.split(" ")[0]}
      </span>
      , let's make your trip a memorable one!
     </Text>
    ) : (
     <Text
      color="#ffffff"
      fontSize={{ base: "25px", md: "54px", lg: "67px", xl: "70px" }}
      lineHeight={{ base: "30px", md: "105%" }}
      fontWeight="700"
      data-animation="heroTitle"
      maxWidth={"880px"}
     >
      Let's make your trip a memorable one
     </Text>
    )}
    <Text
     color="#ffffff"
     fontSize={{ base: "14px", md: "18px", lg: "18px", xl: "20px" }}
     pt="15px"
     lineHeight={{ base: "20px", md: "25px", lg: "30px" }}
     maxWidth={"644px"}
     data-animation="heroDesc"
     letterSpacing={"0.05em"}
    >
     NAVI's innovative approach to tourism helps travelers feel more connected
     to the places they visit and provides a personalized and meaningful travel
     experience
    </Text>
    {isLoggedIn ? (
     <Link to="/experiences">
      <Button
       data-animation="heroButtons"
       color="#fff"
       bg="#632EFF"
       borderRadius={{ base: "30px", lg: "34px", xl: "54px" }}
       width={{ base: "200px", md: "220px", lg: "240px", xl: "252px" }}
       height={{ base: "45px", md: "55px", lg: "60px", xl: "64px" }}
       fontWeight={500}
       fontSize={{ base: 14, md: 15, lg: 15.5, xl: 16 }}
       display={"flex"}
       gap="8px"
       marginTop={{ base: "20px", md: "25px", lg: "36px" }}
       transition={".2s ease"}
       _hover={{
        border: "1.5px solid #fff",
        marginTop: "34px",
       }}
       padding={{ base: "0 1rem", lg: 0 }}
      >
       Explore Our Experiences{" "}
       <svg
        width="29"
        height="28"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       >
        <circle cx="14.5" cy="14" r="14" fill="white" />
        <path
         d="M16.5253 19.6834C16.3669 19.6834 16.2086 19.6251 16.0836 19.5001C15.8419 19.2584 15.8419 18.8584 16.0836 18.6168L20.7003 14.0001L16.0836 9.38343C15.8419 9.14176 15.8419 8.74176 16.0836 8.5001C16.3253 8.25843 16.7253 8.25843 16.9669 8.5001L22.0253 13.5584C22.2669 13.8001 22.2669 14.2001 22.0253 14.4418L16.9669 19.5001C16.8419 19.6251 16.6836 19.6834 16.5253 19.6834Z"
         fill="#0F0931"
        />
        <path
         d="M21.443 14.625H7.41797C7.0763 14.625 6.79297 14.3417 6.79297 14C6.79297 13.6583 7.0763 13.375 7.41797 13.375H21.443C21.7846 13.375 22.068 13.6583 22.068 14C22.068 14.3417 21.7846 14.625 21.443 14.625Z"
         fill="#0F0931"
        />
       </svg>
      </Button>
     </Link>
    ) : (
     <Link to="/signup">
      <Button
       data-animation="heroButtons"
       color="#fff"
       bg="#632EFF"
       borderRadius={{ base: "30px", lg: "34px", xl: "54px" }}
       width={{ base: "200px", md: "220px", lg: "240px", xl: "252px" }}
       height={{ base: "45px", md: "55px", lg: "60px", xl: "64px" }}
       fontWeight={500}
       fontSize={{ base: 14, md: 15, lg: 15.5, xl: 16 }}
       display={"flex"}
       gap="8px"
       marginTop={{ base: "20px", md: "25px", lg: "36px" }}
       transition={".2s ease"}
       _hover={{
        border: "1.5px solid #fff",
        marginTop: "34px",
       }}
       padding={{ base: "0 1rem", lg: 0 }}
      >
       Become a Navigator{" "}
       <svg
        width="29"
        height="28"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       >
        <circle cx="14.5" cy="14" r="14" fill="white" />
        <path
         d="M16.5253 19.6834C16.3669 19.6834 16.2086 19.6251 16.0836 19.5001C15.8419 19.2584 15.8419 18.8584 16.0836 18.6168L20.7003 14.0001L16.0836 9.38343C15.8419 9.14176 15.8419 8.74176 16.0836 8.5001C16.3253 8.25843 16.7253 8.25843 16.9669 8.5001L22.0253 13.5584C22.2669 13.8001 22.2669 14.2001 22.0253 14.4418L16.9669 19.5001C16.8419 19.6251 16.6836 19.6834 16.5253 19.6834Z"
         fill="#0F0931"
        />
        <path
         d="M21.443 14.625H7.41797C7.0763 14.625 6.79297 14.3417 6.79297 14C6.79297 13.6583 7.0763 13.375 7.41797 13.375H21.443C21.7846 13.375 22.068 13.6583 22.068 14C22.068 14.3417 21.7846 14.625 21.443 14.625Z"
         fill="#0F0931"
        />
       </svg>
      </Button>
     </Link>
    )}
   </Box>
  </Box>
 );
};

export default Hero;
