import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Text,
  Button,
  Image,
  Select as ChakraSelect,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
} from "@chakra-ui/react";
import { AuthContext } from "../../../../context/AuthContext";
import { ChatContext } from "../../../../context/ChatContext";
import EmojiIcon from "../../../../assets/images/svg/emoji_emotions.svg";
import AttachIcon from "../../../../assets/images/svg/attach_file.svg";
import Picker from "emoji-picker-react";
import { AiFillFile } from "react-icons/ai";
import { useDropzone } from "react-dropzone";
import { bytesToSize } from "../../../../utils/index";
import { Transition } from "@headlessui/react";
import { Formik, Field, useFormik } from "formik";
import { storedb, storage } from "../../../../firebase";
import {
  arrayUnion,
  setDoc,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
} from "firebase/firestore";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { v4 as uuid } from "uuid";
import Search from "./search";
import Chats from "./charts";
import Messages from "./messages";
import { getDatabase, ref as databaseRef, child, get } from "firebase/database";
import { Avatar } from "antd";
import Icon from "../../../../components/Icon";
import { sendPushNotification } from "../../../../api/user";
import ArrowLeft from "../../../../assets/images/arrow-left-dark.svg";

const Supports = () => {
  const { user } = useContext(AuthContext);
  const { data, dispatch } = useContext(ChatContext);
  const [openEmoji, setOpenEmoji] = useState(false);
  const toggleHandler = useRef(null);
  const [files, setFiles] = useState(null);
  const [message, setMessage] = useState("");
  const [supports, setSupports] = useState(null);
  const uid = user?.uid;
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);

  // console.log("user", user);

  const getUserChats = async () => {
    const unsub = onSnapshot(doc(storedb, "userChats", uid), async (doc) => {
      if (doc.data()) {
        let myObj = doc.data();
        var array = Object.keys(myObj)
          .map((key) => {
            return myObj[key];
          })
          ?.filter((item) => item?.senderId);

        if (array?.length > 0) {
          // dispatch({ type: "CHANGE_USER", payload: array[0] });
          setChats(array);
        }
      }
    });

    return () => {
      unsub();
    };
  };

  useEffect(() => {
    getUserChats();
  }, [uid]);

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      accept:
        "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      noClick: true,
      noKeyboard: true,
      maxSize: 10000000,
      minSize: 0,
    });

  const handleClickOutside = (event) => {
    if (!toggleHandler?.current?.contains(event.target)) {
      setOpenEmoji(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleMediaUpload = async (file) => {
    const storageRef = ref(storage, `/files/${file.name}`);
    await uploadBytesResumable(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const fetchSuport = async () => {
    const dbRef = databaseRef(getDatabase());
    await get(child(dbRef, `users/admin`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let myObj = snapshot.val();

          const foundUser = Object.keys(myObj)
            .map((key) => {
              return myObj[key];
            })
            .filter((item) => item.role === "SUPPORT" || "SUPER-ADMIN");
          // console.log("foundUser", foundUser);
          setSupports(foundUser);
        }
      })
      .catch((err) => {
        setErr(true);
      });
  };

  useEffect(() => {
    fetchSuport();
  }, [user]);

  const handleCreateChat = async () => {
    for (var i = 0; i < supports?.length; i++) {
      const combinedId =
        supports[i]?.uid > uid
          ? supports[i]?.uid + uid
          : uid + supports[i]?.uid;

      try {
        const res = await getDoc(doc(storedb, "chats", combinedId));

        if (!res.exists()) {
          await setDoc(doc(storedb, "chats", combinedId), { messages: [] });

          const userDoc = await getDoc(doc(storedb, "userChats", uid));
          if (userDoc.exists()) {
            const fetchUserDoc = userDoc.data();
            fetchUserDoc[combinedId] = {
              userInfo: {
                uid: supports[i].uid,
                displayName: supports[i].name,
                photoURL: supports[i].photoURL,
                email: supports[i]?.email,
              },
              date: serverTimestamp(),
              status: "Pending",
            };
            await setDoc(doc(storedb, "userChats", uid), fetchUserDoc);
          } else {
            await setDoc(doc(storedb, "userChats", uid), {
              [combinedId]: {
                userInfo: {
                  uid: supports[i].uid,
                  displayName: supports[i].name,
                  photoURL: supports[i].photoURL,
                  email: supports[i]?.email,
                },
                date: serverTimestamp(),
                // status: "Pending",
              },
            });
          }

          const receiverDoc = await getDoc(
            doc(storedb, "userChats", supports[i].uid)
          );

          if (receiverDoc.exists()) {
            const fetchReceiverDoc = receiverDoc.data();
            fetchReceiverDoc[combinedId] = {
              userInfo: {
                uid: uid,
                displayName:
                  user?.name ?? `${user?.firstName} ${user?.lastName}`,
                photoURL: user.photoURL,
                email: user?.email,
              },
              date: serverTimestamp(),
              status: "Pending",
            };
            await setDoc(
              doc(storedb, "userChats", supports[i]?.uid),
              fetchReceiverDoc
            );
          } else {
            await setDoc(doc(storedb, "userChats", supports[i]?.uid), {
              [combinedId]: {
                userInfo: {
                  uid: uid,
                  displayName:
                    user?.name ?? `${user?.firstName} ${user?.lastName}`,
                  photoURL: user.photoURL,
                  email: user?.email,
                },
                date: serverTimestamp(),
                // status: "Pending",
              },
            });
          }
        }
      } catch (error) {}
    }
    // supports?.map(async function (support) {
    //   const combinedId =
    //     support?.uid > uid ? support?.uid + uid : uid + support?.uid;
    //   console.log("support", support?.uid);
    //   console.log("combinedId", combinedId);
    // });
  };

  useEffect(() => {
    if (supports?.length > 0 && uid) {
      handleCreateChat();
    }
  }, [supports, uid]);

  const [openChat, setOpenChat] = useState(false);

  return (
    // <Box
    //   width="100%"
    //   height="calc(100vh - 86px)"
    //   display="flex"
    //   alignItems="center"
    //   justifyContent="center"
    //   flexDir="column"
    // >
    //   <Text
    //     color="#0F0931"
    //     fontSize="20px"
    //     lineHeight="19px"
    //     fontWeight="500"
    //     marginBottom="10px"
    //   >
    //     Get In Touch on
    //   </Text>
    //   <a href="mailto:hello@thenaviapp.com">
    //     <Text
    //       display="flex"
    //       alignItems="center"
    //       textDecoration="underline"
    //       color="#334155"
    //       fontSize="18px"
    //       lineHeight="16px"
    //       fontWeight="400"
    //     >
    //       hello@thenaviapp.com
    //     </Text>
    //   </a>
    // </Box>

    <Box
      // height="100%"
      padding="14px 20px"
      display={{ base: "flex", md: "grid" }}
      gridTemplateColumns={chats?.length > 0 ? "400px 1fr" : "600px"}
      gridTemplateRows="1fr"
      alignItems="flex-start"
      gap="20px"
      background="#F5F8FA"
    >
      {chats?.length > 0 && (
        <Box
          background="#FFFFFF"
          borderRadius="16px"
          padding="20px 10px"
          width="100%"
          // position="fixed"
          height="calc(100vh - 140px)"
          top="120px"
          position="sticky"
          display={{ base: !openChat ? "block" : "none", md: "block" }}
        >
          {/* <Search currentUser={user} /> */}
          <Chats currentUser={user} chats={chats} setOpenChat={setOpenChat} />
        </Box>
      )}
      <Box
        // display="flex"
        flexDirection="column"
        width="100%"
        display={{
          base: chats?.length > 0 ? (openChat ? "flex" : "none") : "block",
          md: "block",
        }}
      >
        <Box
          display={{ base: openChat ? "flex" : "none", md: "none" }}
          alignItems="center"
          cursor="pointer"
          onClick={() => setOpenChat(false)}
          marginBottom="20px"
        >
          <Image src={ArrowLeft} />
          <Text color="#252046" marginLeft="10px">
            Go Back
          </Text>
        </Box>
        <Box
          background="#FFFFFF"
          borderRadius="16px"
          width="100%"
          // height="100vh"
          height="calc(100vh - 140px)"
        >
          <Box
            padding="20px 30px"
            alignItems="flex-start"
            display="flex"
            borderBottom="1px solid #EBEEF2"
            gap="20px"
          >
            {/* <Image
              src={data?.user?.photoURL}
              width="40px"
              height="40px"
              borderRadius="50%"
            /> */}
            {data?.user?.photoURL ? (
              <Avatar
                name={data?.user?.displayName}
                src={data?.user?.photoURL}
              />
            ) : (
              <Avatar name="SUPPORT" />
            )}
            <Box>
              <Text
                color="#0F0931"
                fontSize="14px"
                lineHeight="19px"
                fontWeight="500"
                marginBottom="10px"
              >
                {data?.user?.displayName ?? "Navi Customer Support"}
              </Text>
              <Text
                color="#334155"
                fontSize="12px"
                lineHeight="16px"
                fontWeight="400"
              >
                {data?.user?.email ?? "support@thenaviapp"}
              </Text>
            </Box>
          </Box>
          <Box padding="20px 30px" display="flex">
            <Box width="100%" padding="0" height="100%">
              <Formik
                initialValues={{
                  message: "",
                }}
                // onSubmit={async (values) => {
                //   submit(values);
                // }}
              >
                {({
                  handleSubmit,
                  errors,
                  touched,
                  setFieldValue,
                  handleBlur,
                  values,
                  resetForm,
                }) => {
                  const onEmojiClick = (event, emojiObject) => {
                    const textAreaElement = document.getElementById("message");
                    // console.log("textAreaElement", emojiObject.emoji);
                    setFieldValue(
                      "message",
                      values.message.substr(0, textAreaElement.selectionStart) +
                        emojiObject.emoji +
                        values.message.substr(textAreaElement.selectionEnd)
                    );
                  };

                  const submit = async () => {
                    try {
                      setOpenEmoji(false);

                      let fileUrl = [];
                      var keys = files && Object.keys(files);
                      for (var i = 0; i < keys?.length; i++) {
                        var key = keys[i];
                        const img =
                          files && (await handleMediaUpload(files[key]));
                        fileUrl.push(img);
                      }

                      // console.log("fileUrl", fileUrl);

                      supports?.map(async (support) => {
                        const chatId =
                          support?.uid > uid
                            ? support?.uid + uid
                            : uid + support?.uid;

                        await updateDoc(doc(storedb, "chats", chatId), {
                          messages: arrayUnion({
                            id: uuid(),
                            text: values.message,
                            senderId: uid,
                            date: Timestamp.now(),
                            file: fileUrl ? fileUrl : "",
                          }),
                        });

                        const userDoc = await getDoc(
                          doc(storedb, "userChats", uid)
                        );

                        if (userDoc.data()[chatId]?.senderId) {
                          await updateDoc(doc(storedb, "userChats", uid), {
                            [chatId]: {
                              userInfo: {
                                uid: support.uid,
                                displayName: support.name,
                                photoURL: support.photoURL,
                                email: support.email,
                              },

                              lastMessage: values.message,
                              date: serverTimestamp(),
                              status: "Pending",
                              senderId: userDoc.data()[chatId]?.senderId,
                            },
                          });
                        } else {
                          await updateDoc(doc(storedb, "userChats", uid), {
                            [chatId]: {
                              userInfo: {
                                uid: support.uid,
                                displayName: support.name,
                                photoURL: support.photoURL,
                                email: support.email,
                              },
                              lastMessage: values.message,
                              date: serverTimestamp(),
                              status: "Pending",
                            },
                          });
                        }

                        await updateDoc(
                          doc(storedb, "userChats", support.uid),
                          {
                            [chatId]: {
                              userInfo: {
                                uid: uid,
                                displayName:
                                  user?.name ??
                                  `${user?.firstName} ${user?.lastName}`,
                                photoURL: user.photoURL,
                                email: user?.email,
                              },
                              lastMessage: values.message,
                              date: serverTimestamp(),
                              status: "Pending",
                            },
                          }
                        );
                      });

                      if (user) {
                        let name = user?.firstName
                          ? `${user?.firstName} ${user?.lastName}`
                          : user?.name;
                        await sendPushNotification({
                          title: "admin",
                          body: `${name} from ${
                            user?.businessName ?? user?.partner
                          } sent a support message`,
                          data: {
                            route: `/admin/support`,
                            name: name,
                            photoURL: user?.partnerPhotoURL ?? user?.photoURL,
                            push_time: Date.now(),
                          },
                        });
                      }

                      for (var i = 0; i < supports?.length; i++) {
                        // const chatId =
                        //   supports[i]?.uid > uid
                        //     ? supports[i]?.uid + uid
                        //     : uid + supports[i]?.uid;
                        // await updateDoc(doc(storedb, "chats", chatId), {
                        //   messages: arrayUnion({
                        //     id: uuid(),
                        //     text: values.message,
                        //     senderId: uid,
                        //     date: Timestamp.now(),
                        //     file: fileUrl ? fileUrl : "",
                        //   }),
                        // });
                        // console.log(
                        //   "userDoc===",
                        //   userDoc.exists() && userDoc.data()[chatId]
                        // );
                        // if (userDoc.data()[chatId]?.senderId) {
                        //   await updateDoc(doc(storedb, "userChats", uid), {
                        //     [chatId]: {
                        //       userInfo: {
                        //         uid: supports[i].uid,
                        //         displayName: supports[i].name,
                        //         photoURL: supports[i].photoURL,
                        //         email: supports[i]?.email,
                        //       },
                        //       lastMessage: values.message,
                        //       date: serverTimestamp(),
                        //       status: "Pending",
                        //       // senderId: userDoc.data()[chatId]?.senderId,
                        //     },
                        //   });
                        // } else {
                        //   await updateDoc(doc(storedb, "userChats", uid), {
                        //     [chatId]: {
                        //       userInfo: {
                        //         uid: supports[i].uid,
                        //         displayName: supports[i].name,
                        //         photoURL: supports[i].photoURL,
                        //         email: supports[i]?.email,
                        //       },
                        //       lastMessage: values.message,
                        //       date: serverTimestamp(),
                        //       status: "Pending",
                        //     },
                        //   });
                        // }
                      }

                      // await updateDoc(doc(storedb, "chats", data.chatId), {
                      //   messages: arrayUnion({
                      //     id: uuid(),
                      //     text: values.message,
                      //     senderId: user.uid,
                      //     date: Timestamp.now(),
                      //     file: fileUrl ? fileUrl : "",
                      //   }),
                      // });

                      // await updateDoc(doc(storedb, "userChats", user.uid), {
                      //   [data.chatId]: {
                      //     userInfo: {
                      //       uid: data.user.uid,
                      //       displayName: data.user.displayName,
                      //       photoURL: data.user.photoURL,
                      //       email: data.user?.email,
                      //     },
                      //     lastMessage: values.message,
                      //     date: serverTimestamp(),
                      //     status: "Pending",
                      //   },
                      // });

                      // await updateDoc(doc(storedb, "userChats", data.user.uid), {
                      //   [data.chatId]: {
                      //     userInfo: {
                      //       uid: user.uid,
                      //       displayName: user.name,
                      //       photoURL: user.photoURL,
                      //       email: user?.email,
                      //     },
                      //     lastMessage: values.message,
                      //     date: serverTimestamp(),
                      //     status: "Pending",
                      //   },
                      // });
                      setFiles(null);
                      resetForm();
                    } catch (error) {}
                  };
                  return (
                    <form
                      onSubmit={handleSubmit}
                      style={{
                        width: "100%",
                        marginTop: "30px",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        overflowY="scroll"
                        width="100%"
                        height="30vh"
                        sx={{
                          "::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                      >
                        <Messages currentUser={user} supports={supports} />
                      </Box>
                      <Box
                        // {...getRootProps()}
                        marginTop="30px"
                        ref={toggleHandler}
                        display="flex"
                        alignItems="flex-end"
                      >
                        <Box
                          {...getRootProps()}
                          background="#F1F5F9"
                          border="1px solid #EBEEF2"
                          borderRadius="12px"
                          padding="18px 16px"
                          width="100%"
                          position="relative"
                        >
                          {openEmoji && (
                            <Box
                              position="absolute"
                              bottom="150px"
                              left="0px"
                              zIndex="300"
                            >
                              <Picker onEmojiClick={onEmojiClick} />
                            </Box>
                          )}
                          <input {...getInputProps()} />
                          <Field
                            as={Textarea}
                            placeholder="Type message"
                            background="transparent"
                            height="30px"
                            width="100%"
                            border="none"
                            outline="none"
                            _focusVisible={{
                              border: "none",
                              outline: "none",
                            }}
                            id="message"
                            name="message"
                          />
                          <Box
                            marginTop="10px"
                            display="flex"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" gap="20px">
                              <Image
                                src={EmojiIcon}
                                cursor="pointer"
                                onClick={() => setOpenEmoji(!openEmoji)}
                              />
                              <Image
                                src={AttachIcon}
                                cursor="pointer"
                                onClick={open}
                              />
                              {files?.map((file) => (
                                <a href={file.preview} target="_blank">
                                  <Box display="flex" alignItems="center">
                                    <AiFillFile />{" "}
                                    <Text
                                      textAlign="center"
                                      color="#64748B"
                                      fontFamily="Satoshi"
                                      fontSize="16px"
                                      lineHeight="135%"
                                      marginLeft="10px"
                                      key={file.path}
                                    >
                                      {file.name.substring(0, 6)}... (
                                      {bytesToSize(file.size)}),
                                    </Text>
                                  </Box>
                                </a>
                              ))}
                            </Box>
                            <Button
                              background="#632EFF"
                              borderRadius="54px"
                              padding="10px 30px"
                              color="#ffffff"
                              type="submit"
                              onClick={submit}
                            >
                              Send
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Supports;
